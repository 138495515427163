/*
 * 캘린더 컴포넌트
 * --
 * date: 2022-10-04
 * writer: Owen
 */
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import './css/Calendar02.style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ScheduleApi, FileApi } from 'api';
import {
  DOMAIN_URL,
  STORE_URL,
  getCookie,
  timeInputFormatter,
  HOST_URL,
  handleDiffTime,
} from 'utils';
import MessageAlert from 'utils/MessageAlert';
import { ModalLayout, Content, Button, Row, Col, Title } from 'components';
import {
  Input,
  Select,
  DatePicker,
  Card,
  Avatar,
  Button as Btn,
  List,
  Empty,
} from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { FileUploadManager } from 'utils/FileUploadManager';
import { useQueryClient } from 'react-query';
import UseDebounce from 'utils/useDebounce';
import { encrypt } from 'utils/Crypto';
import 'moment/locale/ko';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

dayjs.extend(customParseFormat);

// 캘린더 상단 툴바 설정
const Toolbar = (props) => {
  /* ===== PROPS ===== */
  const {
    date,
    onView,
    onNavigate,
    view,
    label,
    background,
    setYear,
    setMonth,
    screenSize,
  } = props;

  /* ===== STATE ===== */
  const [dependency, setDependency] = useState(true);

  /* ===== FUNCTION ===== */
  const navigate = (action) => {
    onNavigate(action);
    setDependency(true);
  };

  const viewer = (v) => {
    onView(v);
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    if (date && dependency) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;

      if (setYear) {
        setYear(year);
      }

      if (setMonth) {
        setMonth(month);
      }
      setDependency(false);
    }
  }, [date, setMonth, dependency, setYear]);

  /* ===== RENDER ===== */
  return (
    <div
      className="rbc-toolbar"
      style={{ paddingBottom: '17px', background: background }}
    >
      {screenSize >= 768 ? (
        <span className="rbc-btn-group">
          <button onClick={navigate.bind(null, 'TODAY')}>오늘</button>
        </span>
      ) : null}

      <span
        className="rbc-toolbar-label"
        style={{ fontSize: screenSize >= 768 ? 22 : 16 }}
      >
        <button type="button" onClick={navigate.bind(null, 'PREV')}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <span style={{ padding: '0 10px' }}>
          {view === 'month'
            ? `${date.getFullYear()}. ${date.getMonth() + 1}`
            : `${label}`}
        </span>
        <button type="button" onClick={navigate.bind(null, 'NEXT')}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </span>
      {screenSize >= 768 ? (
        <span className="rbc-btn-group">
          <button onClick={viewer.bind(null, 'month')}>월별</button>
          <button onClick={viewer.bind(null, 'week')}>주별</button>
        </span>
      ) : null}
    </div>
  );
};

// local 설정
// require('moment/locale/ko.js');
moment.tz.setDefault('Asia/Seoul');
const localizer = momentLocalizer(moment);

const scheduleType = [
  {
    label: '스케쥴',
    value: 'S',
  },
  {
    label: '강의 요청',
    value: 'R',
  },
];

const formats = {
  monthHeaderFormat: (date, culture, localizer) =>
    localizer.format(date, 'YYYY년 MMMM', culture),
};

const CalendarComp = ({
  dateTime,
  items,
  isHeader,
  height,
  background,
  add,
  detail,
  request,
  teacherId,
  update,
  setMonth,
  setYear,
  agencyView,
  confirm,
  onPopupMessage,
  profile,
  program,
  agencyDetail,
}) => {
  /* ===== ROUTE ===== */
  const navigation = useNavigate();

  /* ===== STATE ===== */
  const queryClient = useQueryClient();
  const agencies = queryClient.getQueryData('getAgencies')?.data;
  const address = queryClient.getQueryData('getAddressSplit')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  const programs = queryClient.getQueryData('getPrograms')?.data;
  const teachers = queryClient
    .getQueryData('getUsers')
    ?.data?.filter((item) => item?.teacher_check === 'Y');
  const [treeData, setTreeData] = useState([]);
  const [programItems, setProgramItems] = useState([]);
  const [programValue, setProgramValue] = useState({});
  // console.log('programValue', programValue);
  const [programModal, setProgramModal] = useState(false);
  const [majorDetailValue, setMajorDetailValue] = useState(0);
  const [majorDetailItems, setMajorDetailItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = UseDebounce(searchValue, 300);

  const [newAgencies, setNewAgencies] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [submitType, setSubmitType] = useState('create');
  const [requestModal, setRequestModal] = useState(false);
  const loginWho = useRecoilValue(loginUser);
  const [detailModal, setDetailModal] = useState(false);
  const [requestId, setRequestId] = useState(null);
  // TODO: 일자 상세보기 데이터 출력
  const [modalData, setModalData] = useState([]);
  const [clickDate, setClickDate] = useState('');
  const [inputData, setInputData] = useState({
    user_id: teacherId ? teacherId : undefined,
    type: 'S',
    agency_id: undefined,
    date: undefined,
    start_time: undefined,
    end_time: undefined,
    category_id: undefined,
    content: undefined,
  });
  const [file, setFile] = useState(undefined);
  // 스크린 크기
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [agencyInfo, setAgencyInfo] = useState({});
  // 프로필 모달
  const [profileModal, setProfileModal] = useState(false);
  const [profileData, setProfileData] = useState(null);

  /* ===== MUTATE ===== */
  const create = ScheduleApi.CreateSchedule({
    onSuccess: async (data) => {
      if (file) {
        const res =
          loginWho !== 'admin'
            ? await FileUploadManager('S', file)
            : await FileUploadManager('S', file, teacherId);

        if (res.status === 200) {
          createScheduleFile.mutate({
            schedule_id: data?.data?.schedule_id,
            file_name: res.file_name,
            file_url: res.name,
            type: res.type,
          });

          setFile(undefined);
        }
      }
    },
  });
  const updateSchedule = ScheduleApi.UpdateSchedule({
    onSuccess: async (data) => {
      if (file) {
        if (file?.size === 0) {
          return;
        }
        // 등록된 파일이 있는지 체크
        const checkFile = items?.filter(
          (item) => item.id === inputData?.schedule_id
        )[0];

        const res =
          loginWho !== 'admin'
            ? await FileUploadManager('S', file)
            : await FileUploadManager('S', file, teacherId);
        if (checkFile?.file) {
          // 등록된 파일이 있는 경우
          if (res.status === 200) {
            updateScheduleFile.mutate({
              schedule_file_id: checkFile?.file?.schedule_file_id,
              schedule_id: data?.data?.schedule_id,
              file_name: res.file_name,
              file_url: res.name,
              type: res.type,
            });

            const newModalData = modalData?.map((item) =>
              item?.id === data?.data?.schedule_id
                ? {
                    ...item,
                    file: {
                      schedule_file_id: checkFile?.file?.schedule_file_id,
                      schedule_id: data?.data?.schedule_id,
                      file_name: res.file_name,
                      file_url: res.name,
                      type: res.type,
                    },
                  }
                : item
            );
            setModalData(newModalData);

            setFile(undefined);
          }
        } else {
          // 등록된 파일이 없는 경우
          if (res.status === 200) {
            createScheduleFile.mutate({
              schedule_id: inputData?.schedule_id,
              file_name: res.file_name,
              file_url: res.name,
              type: res.type,
            });

            setFile(undefined);
          }
        }
      } else {
        // 등록된 파일이 있는지 체크
        const checkFile = items?.filter(
          (item) => item.id === inputData?.schedule_id
        )[0];
        if (checkFile?.file) {
          deleteScheduleFile.mutate({
            schedule_file_id: checkFile?.file?.schedule_file_id,
          });

          const newModalData = modalData?.map((item) =>
            item?.id === data?.data?.schedule_id
              ? {
                  ...item,
                  file: undefined,
                }
              : item
          );
          setModalData(newModalData);
        }
      }
    },
  });
  const deleteSchedule = ScheduleApi.DeleteSchedule({});
  const createScheduleFile = FileApi.CreateScheduleFile({
    onSuccess: (data) => {
      const newModalData = modalData?.map((item) =>
        item?.id === data?.data?.schedule_id
          ? {
              ...item,
              file: {
                schedule_file_id: data?.data?.schedule_file_id,
                schedule_id: data?.data?.schedule_id,
                file_name: data?.data?.file_name,
                file_url: data?.data?.file_url,
                type: data?.data?.type,
              },
            }
          : item
      );
      // console.log('update ===>', newModalData);
      setModalData(newModalData);
    },
  });
  const updateScheduleFile = FileApi.UpdateScheduleFile();
  const deleteScheduleFile = FileApi.DeleteScheduleFile();

  /* ===== FUNCTION ===== */
  /**
   * 일정 추가 모달 Open 함수
   * --
   * @param {*} e
   * @param {boolean} opened
   */
  const handleSelectEvent = (opened) => {
    setIsOpened(!opened);
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...inputData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setInputData(d);

    if (callback) callback(v);
  };

  /**
   * 일정 등록/수정 함수
   * --
   */
  const handleCreateSchedule = () => {
    const {
      type,
      agency_id,
      user_id,
      date,
      start_time,
      end_time,
      category_id,
    } = inputData;

    if (loginWho === 'teacher' && !agency_id) {
      MessageAlert.warning('기관을 선택해주세요!');
      return;
    }

    if (loginWho === 'agency' && !user_id) {
      MessageAlert.warning('강사를 선택해주세요!');
      return;
    }

    if (!date) {
      MessageAlert.warning('날짜를 선택해주세요!');
      return;
    }

    if (!start_time) {
      MessageAlert.warning('시작시간을 입력해주세요!');
      return;
    }
    if (!end_time) {
      MessageAlert.warning('종료시간을 입력해주세요!');
      return;
    }
    const reg = /^([0-9]{1,2}):([0-5][0-9])$/;
    if (!reg.test(start_time) || !reg.test(end_time)) {
      MessageAlert.warning('HH:mm 형태로 시간을 입력해주세요');
      return;
    }
    if (start_time > '24:00' || end_time > '24:00') {
      MessageAlert.warning('24:00시 이후로는 입력이 불가능합니다!');
      return;
    }
    if (start_time >= end_time) {
      MessageAlert.warning('종료시간은 시작시간 이후로만 입력가능합니다!');
      return;
    }
    // 두 시간 차이 계산
    const diffTime = handleDiffTime(start_time, end_time, 'h');
    if (diffTime < 1) {
      MessageAlert.warning('1시간 미만의 스케줄은 등록할 수 없습니다.');
      return;
    }

    if (!category_id) {
      MessageAlert.warning('프로그램을 선택해주세요!');
      return;
    }

    if (submitType === 'create') {
      const confirm = window.confirm('일정을 등록하시겠습니까?');

      if (confirm) {
        try {
          create.mutate({
            schedule_type: type,
            agency_id: loginWho === 'agency' ? getCookie('USER') : agency_id,
            category_id: category_id,
            user_id: loginWho !== 'teacher' ? user_id : getCookie('USER'),
            registrant:
              loginWho === 'agency'
                ? 'agency'
                : loginWho === 'admin'
                ? 'personal'
                : 'teacher',
            start_date: `${moment(date).format('YYYY-MM-DD')} ${start_time}`,
            end_date: `${moment(date).format('YYYY-MM-DD')} ${end_time}`,
            content: programValue,
            user_confirm: type === 'R' && loginWho === 'agency' ? 'C' : 'Y',
            agency_confirm: type === 'R' && loginWho === 'teacher' ? 'C' : 'Y',
          });

          MessageAlert.success('스케줄 등록에 성공했습니다');
          setInputData({
            user_id: teacherId ? teacherId : undefined,
            type: 'S',
            agency_id: undefined,
            date: undefined,
            start_time: undefined,
            end_time: undefined,
            category_id: undefined,
            content: undefined,
          });
          setProgramValue({});
          setIsOpened(false);
        } catch (e) {
          console.log(e);
          throw e;
        }
      }
    } else {
      const confirm = window.confirm('일정을 수정하시겠습니까?');

      if (confirm) {
        try {
          updateSchedule.mutate({
            schedule_type: type,
            schedule_id: inputData?.id,
            agency_id: loginWho === 'agency' ? getCookie('USER') : agency_id,
            category_id: category_id,
            user_id: loginWho !== 'teacher' ? user_id : getCookie('USER'),
            registrant:
              loginWho === 'agency'
                ? 'agency'
                : loginWho === 'admin'
                ? 'personal'
                : 'teacher',
            start_date: `${moment(date).format('YYYY-MM-DD')} ${start_time}`,
            end_date: `${moment(date).format('YYYY-MM-DD')} ${end_time}`,
            content: programValue,
          });

          MessageAlert.success('스케줄 수정에 성공했습니다');
          setInputData({
            user_id: teacherId ? teacherId : undefined,
            type: 'S',
            agency_id: undefined,
            date: undefined,
            start_time: undefined,
            end_time: undefined,
            category_id: undefined,
            content: undefined,
          });
          setProgramValue({});
          setIsOpened(false);
        } catch (e) {
          console.log(e);
          throw e;
        }
      }
    }
  };

  /**
   * 강의 요청 함수
   * --
   */
  const handleRequestSchedule = () => {
    const { date, start_time, end_time, category_id } = inputData;

    if (loginWho === 'admin' && !requestId) {
      MessageAlert.warning('강사에게 스케쥴을 요청할 기관을 선택해주세요');
      return;
    }

    if (!date) {
      MessageAlert.warning('날짜를 선택해주세요!');
      return;
    }

    if (!start_time) {
      MessageAlert.warning('시작시간을 입력해주세요!');
      return;
    }
    if (!end_time) {
      MessageAlert.warning('종료시간을 입력해주세요!');
      return;
    }
    const reg = /^([0-9]{1,2}):([0-5][0-9])$/;
    if (!reg.test(start_time) || !reg.test(end_time)) {
      MessageAlert.warning('HH:mm 형태로 시간을 입력해주세요');
      return;
    }
    if (start_time > '24:00' || end_time > '24:00') {
      MessageAlert.warning('24:00시 이후로는 입력이 불가능합니다!');
      return;
    }
    if (start_time >= end_time) {
      MessageAlert.warning('종료시간은 시작시간 이후로만 입력가능합니다!');
      return;
    }
    // 두 시간 차이 계산
    const diffTime = handleDiffTime(start_time, end_time, 'h');
    if (diffTime < 1) {
      MessageAlert.warning('1시간 미만의 스케줄은 등록할 수 없습니다.');
      return;
    }

    if (!category_id) {
      MessageAlert.warning('프로그램을 선택해주세요!');
      return;
    }

    const confirm = window.confirm(
      !inputData?.schedule_id
        ? '강의를 요청하시겠습니까?'
        : '강의 요청을 수정하시겠습니까?'
    );

    if (confirm) {
      try {
        if (inputData?.schedule_id) {
          updateSchedule.mutate({
            ...inputData,
            start_date: `${moment(date).format('YYYY-MM-DD')} ${start_time}`,
            end_date: `${moment(date).format('YYYY-MM-DD')} ${end_time}`,
          });

          MessageAlert.success('스케줄 요청이 수정되었습니다');
        } else {
          create.mutate({
            ...inputData,
            agency_id: loginWho === 'agency' ? getCookie('USER') : requestId,
            user_id: teacherId,
            start_date: `${moment(date).format('YYYY-MM-DD')} ${start_time}`,
            end_date: `${moment(date).format('YYYY-MM-DD')} ${end_time}`,
            schedule_type: 'R',
            registrant:
              loginWho === 'agency'
                ? 'agency'
                : loginWho === 'admin'
                ? 'personal'
                : 'teacher',
            user_confirm: loginWho !== 'teacher' ? 'C' : 'Y',
            agency_confirm: loginWho !== 'agency' ? 'C' : 'Y',
          });

          MessageAlert.success('스케줄을 요청하였습니다');
        }

        setInputData({
          user_id: teacherId ? teacherId : undefined,
          type: 'S',
          agency_id: undefined,
          date: undefined,
          start_time: undefined,
          end_time: undefined,
          category_id: undefined,
          content: undefined,
        });
        setRequestModal(false);
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  };

  /**
   * 이벤트 아이템 클릭 함수
   * --
   */
  const handleDayClick = (event) => {
    if (detail) {
      const date = moment(event.value).format('YYYY-MM-DD');
      handleChange('date', date);
      const filteredItems = items?.filter((item) => {
        const startDate = moment(item?.start).format('YYYY-MM-DD');
        const endDate = moment(item?.end).format('YYYY-MM-DD');
        return startDate <= date && date <= endDate;
      });

      setModalData(filteredItems);
      setClickDate(event.value);
    } else {
      return;
    }
  };

  /**
   * 강의 요청 수락/거절 함수
   * --
   * @param {*} type
   * @returns
   */
  const handleAcceptSchedule = (check) => {
    const confirm = window.confirm(
      `해당 스케쥴을 ${check === 'Y' ? '수락' : '거절'} 하시겠습니까?`
    );

    if (confirm) {
      try {
        const {
          user_confirm,
          agency_confirm,
          type,
          id,
          date,
          start_time,
          end_time,
          ...d
        } = inputData;
        const newData = {
          ...d,
          schedule_type: type,
        };

        if (loginWho === 'teacher') {
          updateSchedule.mutate({
            ...d,
            schedule_id: id,
            user_confirm: check,
            agency_confirm: agency_confirm,
            schedule_type: check === 'Y' && agency_confirm === 'Y' ? 'S' : 'R',
            start_date: `${moment(date).format('YYYY-MM-DD')} ${start_time}`,
            end_date: `${moment(date).format('YYYY-MM-DD')} ${end_time}`,
          });
        } else if (loginWho === 'agency') {
          updateSchedule.mutate({
            ...d,
            schedule_id: id,
            user_confirm: user_confirm,
            agency_confirm: check,
            schedule_type: check === 'Y' && user_confirm === 'Y' ? 'S' : 'R',
            start_date: `${moment(date).format('YYYY-MM-DD')} ${start_time}`,
            end_date: `${moment(date).format('YYYY-MM-DD')} ${end_time}`,
          });
        } else {
          updateSchedule.mutate({
            ...d,
            schedule_id: id,
            user_confirm: check,
            agency_confirm: check,
            schedule_type: 'S',
            start_date: `${moment(date).format('YYYY-MM-DD')} ${start_time}`,
            end_date: `${moment(date).format('YYYY-MM-DD')} ${end_time}`,
          });
        }

        const filteredItems = items?.filter((item) => {
          const startDate = moment(item?.start).format('YYYY-MM-DD');
          const endDate = moment(item?.end).format('YYYY-MM-DD');
          return (
            startDate <= moment(clickDate)?.format('YYYY-MM-DD') &&
            moment(clickDate)?.format('YYYY-MM-DD') <= endDate
          );
        });
        const newModalData = filteredItems?.map((item) =>
          item?.id === newData?.schedule_id
            ? {
                ...item,
                confirm_check: type,
              }
            : item
        );
        setModalData(newModalData);

        setInputData({
          user_id: teacherId ? teacherId : undefined,
          type: 'S',
          agency_id: undefined,
          date: undefined,
          start_time: undefined,
          end_time: undefined,
          category_id: undefined,
          content: undefined,
        });
        setRequestModal(false);

        MessageAlert.success(
          `해당 스케쥴을 ${check === 'Y' ? '수락' : '거절'}하였습니다`
        );
      } catch (e) {
        console.log(e);
        MessageAlert.error(
          `스케쥴을 ${check === 'Y' ? '수락' : '거절'}에 실패하였습니다`
        );
        throw e;
      }
    } else {
      return;
    }
  };

  /**
   * 강의 삭제 함수
   * --
   * @param {*} id
   */
  const handleDeleteSchedule = (id) => {
    const confirm = window.confirm('스케쥴을 삭제하시겠습니까?');

    if (confirm) {
      deleteSchedule.mutate({ schedule_id: id });
      MessageAlert.success('삭제되었습니다');

      const newData = modalData?.filter((item) => item?.id !== id);
      setModalData(newData);
    }
  };

  /**
   * 상세보기 아이템 클릭
   * --
   * @param {*} data
   */
  const handleScheduleClick = (data) => {
    // console.log('data', data);
    const { start, end, content, type, ...d } = data;

    const newData = {
      ...d,
      type: type,
      date: moment(start).format('YYYY-MM-DD'),
      start_time: moment(start)?.format('HH:mm'),
      end_time: moment(end)?.format('HH:mm'),
    };
    // console.log('newData', newData);

    setInputData(newData);
    setProgramValue(content);
    setSubmitType('update');

    if (loginWho === 'admin') {
      setRequestId(data?.agency_id);
    }

    if (type === 'R') {
      setRequestModal(true);
    } else {
      setIsOpened(true);
    }
  };

  /**
   * 교안 라우터 이동
   * --
   */
  const handleNavigateProgram = (e, data) => {
    if (loginWho === 'agency' && Object.keys(data?.content).length === 0) {
      MessageAlert.warning('교안이 등록되지 않았습니다');
      e.stopPropagation();
      return;
    }
    // A4 용지 크기 및 비율
    const a4Width = 595;
    const a4Height = 842;
    const aspectRatio = a4Width / a4Height;

    // 팝업 창 크기 계산 (1.5배 비율로)
    const popupWidth = a4Width * 1.5;
    const popupHeight = popupWidth / aspectRatio;

    // 중앙 정렬을 위한 창 위치 계산
    const left = (window.innerWidth - a4Width) / 2;
    const top = (window.innerHeight - a4Height) / 2;
    const domain = DOMAIN_URL;
    window.open(
      `${domain}/#/popup/program/${encodeURIComponent(encrypt(data?.id))}`,
      '_blank',
      `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
    );
    setIsPopup(true);
    e.stopPropagation();
  };

  /**
   * 스케쥴 확정
   * --
   */
  const handleScheduleCheck = (e, data) => {
    e.stopPropagation();
    const { id, end, user_check, agency_check } = data;
    const today = new Date();
    if (today < new Date(end))
      return MessageAlert.warning('일정이 종료된 이후 확정하실 수 있습니다!');

    const confirm = window.confirm(
      '스케쥴 확정 후 변경할 수 없습니다.\n스케쥴을 확정하시겠습니까?'
    );

    if (confirm) {
      try {
        updateSchedule.mutate({
          schedule_id: id,
          user_check: loginWho !== 'agency' ? today : user_check,
          agency_check: loginWho !== 'teacher' ? today : agency_check,
        });
        MessageAlert.success('스케쥴이 성공적으로 확정되었습니다');
      } catch (err) {
        MessageAlert.error('스케쥴 확정 실패!');
        console.log('스케쥴 확정 실패!', err);
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다');
    }
    // console.log('data', data);
  };

  /**
   * 프로필모달 핸들러
   * --
   */
  const handleProfileMoadl = (e, data) => {
    e.stopPropagation();
    setProfileModal(!profileModal);
    setProfileData(data);
  };

  /**
   * 첨부파일 다운로드
   * --
   */
  const handleFileDownload = async (url, name) => {
    try {
      const res = await axios.post(`${HOST_URL}/file/blob`, {
        key: url,
      });
      const blob = res.data.data.data;
      const uint8Array = new Uint8Array(blob);
      const fileData = new File([uint8Array], name);
      const downloadURL = window.URL.createObjectURL(fileData);
      const a = document.createElement('a');
      a.href = downloadURL;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadURL);
    } catch (err) {
      console.log('파일 다운로드 에러!', err);
      throw err;
    }
  };

  /**
   * 옵션필터 검색
   * --
   * @param {*} value
   * @param {*} label
   */
  const handleFilterOption = (value, label) => {
    const agencyName = label.props?.children[0]?.props?.children[0];
    return agencyName?.toLowerCase()?.includes(value?.toLowerCase());
  };

  /**
   * RangeDate 데이터 바인딩 함수
   * --
   * @param {*} dates
   * @param {*} dateStrings
   */
  // const dateChange = (dates, dateStrings) => {
  //   // console.log('dateStrings', dateStrings);
  //   const d = { ...inputData };
  //   d['start_date'] = dateStrings[0];
  //   d['end_date'] = dateStrings[1];

  //   setInputData(d);
  // };

  /* ===== HOOKS ===== */
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /**
   * 모달 입력 값 초기화
   * --
   */
  useEffect(() => {
    if (!isOpened) {
      setInputData({
        user_id: teacherId ? teacherId : undefined,
        type: 'S',
        agency_id: undefined,
        date: undefined,
        start_time: undefined,
        end_time: undefined,
        category_id: undefined,
        content: undefined,
      });
      setProgramValue({});
    }
  }, [isOpened, teacherId]);

  /**
   * 관리자 요청 모달 기관 정보 정제
   * --
   */
  useEffect(() => {
    if (loginWho === 'admin' && requestId) {
      const a = items?.filter(
        (item) => parseInt(item?.agency_id) === parseInt(requestId)
      )[0];

      setAgencyInfo(a);
    }
  }, [requestId, loginWho, items]);

  /**
   * 기관 select 아이템 정제
   * --
   */
  useEffect(() => {
    if (agencies && address) {
      const { local, city, district } = address;

      const newData = agencies?.map((item) => ({
        ...item,
        local_name: local
          ?.filter((subitem) => item?.local_id === subitem?.local_id)
          ?.pop()?.local_name,
        city_name: city
          ?.filter((subitem) => item?.city_id === subitem?.city_id)
          ?.pop()?.city_name,
        district_name: district
          ?.filter((subitem) => item?.district_id === subitem?.district_id)
          ?.pop()?.district_name,
      }));
      setNewAgencies(newData);
    }
  }, [agencies, address]);

  /**
   * 카테고리 Select 아이템정제
   * --
   */
  useEffect(() => {
    if (categories) {
      const majorDetail = categories?.filter((item) => item?.type === 'D');
      const d = [
        {
          label: '전체보기',
          value: 0,
        },
        ...majorDetail?.map((item) => ({
          label: item?.category_name,
          value: item?.category_id,
        })),
      ];
      setMajorDetailItems(d);

      const items = majorDetail?.map((item) => ({
        label: item?.category_name,
        value: item?.category_id,
      }));
      setTreeData(items);
    }
  }, [categories]);

  /**
   * 교안 아이템 정제
   * --
   */
  useEffect(() => {
    const p = programs?.map((item) => ({
      ...item,
      ...(categories &&
        categories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]),
    }));
    const newPrograms = p?.map((item) => ({
      ...item,
      parents_name: categories?.filter(
        (subitem) => subitem?.category_id === item?.parents_id
      )[0]?.category_name,
    }));
    setProgramItems(newPrograms);
  }, [programs, categories]);

  /**
   * 필터 값에 따른 교안 아이템 정제
   * --
   */
  useEffect(() => {
    const p = programs?.map((item) => ({
      ...item,
      ...(categories &&
        categories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]),
    }));
    const newPrograms = p?.map((item) => ({
      ...item,
      parents_name: categories?.filter(
        (subitem) => subitem?.category_id === item?.parents_id
      )[0]?.category_name,
    }));

    const newItem = newPrograms
      ?.filter((item) =>
        majorDetailValue === 0 ? item : item?.category_id === majorDetailValue
      )
      ?.filter((item) =>
        debouncedValue === ''
          ? item
          : item?.content?.title?.includes(debouncedValue)
      );

    setProgramItems(newItem);
  }, [majorDetailValue, debouncedValue, programs, categories]);

  /**
   * 팝업 메세지 감지
   * --
   */
  useEffect(() => {
    if (isPopup && onPopupMessage) {
      window.addEventListener('message', onPopupMessage);
    } else {
      window.removeEventListener('message', onPopupMessage);
      setIsPopup(false);
    }

    return () => {
      window.removeEventListener('message', onPopupMessage);
    };
    // eslint-disable-next-line
  }, [isPopup, onPopupMessage]);

  /**
   * 아이템 데이터 수정 시 렌더링 처리
   * --
   */
  useEffect(() => {
    if (detailModal && clickDate) {
      const filteredItems = items?.filter((item) => {
        const startDate = moment(item?.start).format('YYYY-MM-DD');
        const endDate = moment(item?.end).format('YYYY-MM-DD');
        return (
          startDate <= moment(clickDate)?.format('YYYY-MM-DD') &&
          moment(clickDate)?.format('YYYY-MM-DD') <= endDate
        );
      });

      setModalData(filteredItems);
    }
  }, [items, clickDate, detailModal]);

  /* ===== RENDER ====== */
  return (
    <>
      <Calendar
        date={dateTime}
        onNavigate={() => {}}
        localizer={localizer}
        events={items}
        startAccessor="start"
        endAccessor="end"
        style={{ height: `${height}px`, width: '100%' }}
        views={{
          month: true,
          week: true,
          day: true,
        }}
        defaultView={'month'}
        // view={view}
        formats={formats}
        components={{
          toolbar: (props) =>
            isHeader && (
              <Toolbar
                onView={props.onView}
                onNavigate={props.onNavigate}
                date={props.date}
                localizer={localizer}
                label={props.label}
                background={background}
                setYear={setYear}
                setMonth={setMonth}
                screenSize={screenSize}
              />
            ),
          // Toolbar,
          dateCellWrapper: function handleEvent(event) {
            return (
              <div
                className="rbc-day-bg"
                onClick={
                  detail
                    ? () => {
                        handleDayClick(event);
                        if (isOpened) {
                          setDetailModal(false);
                        } else {
                          setDetailModal(true);
                        }
                      }
                    : () => {}
                }
                style={{ cursor: detail ? 'pointer' : 'default' }}
              >
                {add ? (
                  <div className="rbc-day-btn">
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // 이벤트 전파 중단
                        handleDayClick(event);
                        handleSelectEvent(isOpened);
                        setSubmitType('create');
                      }}
                    >
                      +
                    </button>
                  </div>
                ) : request ? (
                  <div className="rbc-day-btn02">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDayClick(event);
                        setRequestModal(true);
                        setSubmitType('create');
                      }}
                    >
                      강의 요청하기
                    </button>
                  </div>
                ) : null}
              </div>
            );
          },
        }}
        eventPropGetter={(event) => {
          if (event.type === 'S') {
            const background = '#4B55F8';
            const border = '1px solid #4B55F8';
            return {
              style: { background, border },
            };
          } else if (event.type === 'H') {
            const background = '#FB4E4E';
            const border = '1px solid #FB4E4E';
            return {
              style: { background, border },
            };
          } else if (event.type === 'F') {
            const background = '#9EAAD9';
            const border = '1px solid #9EAAD9';
            return {
              style: { background, border },
            };
          } else if (event.type === 'N') {
            const background = '#BDBDBD';
            const border = '1px solid #BDBDBD';
            return {
              style: { background, border },
            };
          } else {
            const background = '#03A9F4';
            const border = '1px solid #03A9F4';
            return {
              style: { background, border },
            };
          }
        }}
      />

      {/* 이벤트 추가 모달 */}
      <ModalLayout
        title={submitType === 'create' ? '일정 등록' : '일정 수정'}
        type={screenSize >= 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'90%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={5500}
        footer={
          <Button
            style={{
              width: '100%',
              height: '56px',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '24px',
            }}
            onClick={() => handleCreateSchedule()}
          >
            {submitType === 'create' ? '등록' : '수정'}
          </Button>
        }
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24} style={{ marginTop: screenSize >= 768 ? 48 : 20 }}>
              {/* 스케쥴 타입 선택 */}
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Col x={24} md={5}>
                  <p
                    style={{
                      fontSize: screenSize >= 768 ? 18 : 14,
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    스케쥴타입
                  </p>
                </Col>
                <Col x={24} md={19}>
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 5600 }}
                    placeholder="스케쥴 타입를 선택해주세요"
                    options={scheduleType?.map((item) => ({
                      label: item?.label,
                      value: item?.value,
                    }))}
                    value={inputData?.type}
                    onChange={(e) => handleChange('type', e)}
                  />
                </Col>
              </Row>
              {/* 기관/강사 선택 */}
              {loginWho !== 'agency' && (
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '24px',
                  }}
                >
                  <Col x={24} md={5}>
                    <p
                      style={{
                        fontSize: screenSize >= 768 ? 18 : 14,
                        fontWeight: 600,
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                      }}
                    >
                      기관
                    </p>
                  </Col>
                  <Col x={24} md={19}>
                    <Select
                      size="large"
                      style={{ width: '100%' }}
                      dropdownStyle={{ zIndex: 5600 }}
                      placeholder="기관을 선택해주세요"
                      options={newAgencies
                        ?.filter(
                          (item) =>
                            item?.is_show === 'Y' && item?.is_enable === 'Y'
                        )
                        ?.map((item) => ({
                          label: (
                            <div>
                              <h4 style={{ fontSize: '16px' }}>
                                {item?.name}{' '}
                                <span
                                  style={{ fontSize: '12px', color: '#BDBDBD' }}
                                >
                                  {item?.type}
                                </span>
                              </h4>
                              <p>
                                {item?.address} {item?.address_detail}
                              </p>
                            </div>
                          ),
                          value: item?.agency_id,
                        }))}
                      value={inputData?.agency_id}
                      onChange={(e) => handleChange('agency_id', e)}
                      showSearch
                      filterOption={(e, option) =>
                        handleFilterOption(e, option?.label)
                      }
                      disabled={
                        submitType === 'update' &&
                        inputData?.repetition_schedule_id
                      }
                    />
                  </Col>
                </Row>
              )}
              {loginWho !== 'teacher' && (
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '24px',
                  }}
                >
                  <Col x={24} md={5}>
                    <p
                      style={{
                        fontSize: screenSize >= 768 ? 18 : 14,
                        fontWeight: 600,
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                      }}
                    >
                      강사
                    </p>
                  </Col>
                  <Col x={24} md={19}>
                    <Select
                      size="large"
                      style={{ width: '100%' }}
                      dropdownStyle={{ zIndex: 5600 }}
                      placeholder="강사를 선택해주세요"
                      options={teachers
                        ?.filter((item) => item?.is_show === 'Y')
                        ?.map((item) => ({
                          label: item?.name,
                          value: item?.user_id,
                        }))}
                      value={inputData?.user_id}
                      onChange={(e) => handleChange('user_id', e)}
                      showSearch
                      filterOption={(e, option) =>
                        (option?.label ?? '')
                          ?.toLowerCase()
                          ?.includes(e?.toLowerCase())
                      }
                      disabled={
                        submitType === 'update' &&
                        inputData?.repetition_schedule_id
                      }
                    />
                  </Col>
                </Row>
              )}
              {/* 날짜 */}
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Col x={24} md={5}>
                  <p
                    style={{
                      fontSize: screenSize >= 768 ? 18 : 14,
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    날짜
                  </p>
                </Col>
                <Col x={24} md={19}>
                  <DatePicker
                    size="large"
                    style={{ width: '100%' }}
                    value={
                      inputData?.date && moment(inputData?.date, 'YYYY-MM-DD')
                    }
                    format={'YYYY-MM-DD'}
                    onChange={(time, timeString) => {
                      handleChange('date', moment(timeString, 'YYYY-MM-DD'));
                    }}
                    popupStyle={{ zIndex: 5600 }}
                    inputReadOnly
                    placeholder="날짜를 선택해주세요"
                  />
                </Col>
                <Col x={0} md={5}></Col>
                <Col
                  x={24}
                  md={19}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: screenSize >= 768 ? 15 : 5,
                  }}
                >
                  <Input
                    size="large"
                    style={{ width: 150 }}
                    placeholder="시작시간"
                    value={inputData?.start_time}
                    onChange={(e) =>
                      handleChange(
                        'start_time',
                        timeInputFormatter(e.target.value)
                      )
                    }
                    maxLength={5}
                  />
                  <p style={{ padding: '0 10px' }}>~</p>
                  <Input
                    size="large"
                    style={{ width: 150 }}
                    placeholder="종료시간"
                    value={inputData?.end_time}
                    onChange={(e) =>
                      handleChange(
                        'end_time',
                        timeInputFormatter(e.target.value)
                      )
                    }
                    maxLength={5}
                  />
                </Col>
              </Row>
              {/* 프로그램 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={24} md={5}>
                  <p
                    style={{
                      fontSize: screenSize >= 768 ? 18 : 14,
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    프로그램
                  </p>
                </Col>
                <Col x={24} md={19}>
                  <Select
                    size="large"
                    options={treeData}
                    value={inputData?.category_id}
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 5700 }}
                    onChange={(e) => handleChange('category_id', e)}
                    placeholder="프로그램을 선택해주세요"
                  />
                </Col>
              </Row>
              {/* 교안 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={24} md={5}>
                  <p
                    style={{
                      fontSize: screenSize >= 768 ? 18 : 14,
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    교안
                  </p>
                </Col>
                <Col x={24} md={19} padding={0}>
                  {programValue?.category ? (
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontSize: screenSize >= 768 ? 18 : 16,
                          display: 'flex',
                          alignItems: 'center',
                          color: '#000000',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <span
                          style={{
                            fontSize: screenSize >= 768 ? 14 : 12,
                            marginRight: screenSize >= 768 ? 10 : 5,
                            display: 'inline-block',
                            padding: '3px 5px',
                            background: '#E3A4AF',
                            color: '#FFFFFF',
                            wordBreak: 'keep-all',
                          }}
                        >
                          {programValue?.category}
                        </span>
                        {programValue?.title}
                      </p>
                      <button
                        style={{
                          padding: '0px 5px',
                          cursor: 'pointer',
                          background: '#FFFFFF',
                          border: '1px solid #EEEEEE',
                        }}
                        onClick={() => setProgramValue({})}
                      >
                        x
                      </button>
                    </Content>
                  ) : (
                    <Content maxWidth={'100%'} padding={0}>
                      <Btn type="primary" onClick={() => setProgramModal(true)}>
                        교안검색
                      </Btn>
                    </Content>
                  )}
                </Col>
              </Row>
              {/* 교안 수정 */}
              {submitType === 'create' ? null : <Row></Row>}
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* 프로필 모달 */}
      <ModalLayout
        type={screenSize >= 768 ? 'modal' : 'drawer'}
        open={profileModal}
        onCancel={setProfileModal}
        placement={screenSize >= 768 ? 'right' : 'bottom'}
        width={screenSize >= 768 ? 700 : '100%'}
        height={'90%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={5500}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Content maxWidth={'100%'}>
                {/* 프로필 요약 정보 */}
                <Row>
                  <Col
                    x={24}
                    md={8}
                    style={{ position: 'relative', top: 0, left: 0 }}
                  >
                    <img
                      style={{ width: '100%', height: '100%' }}
                      src={`${STORE_URL}/users/${profileData?.user_id}/${profileData?.profile?.profileImage?.file_url}`}
                      alt="프로필 이미지"
                    />
                    {screenSize < 768 && (
                      <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                          onClick={() =>
                            navigation(
                              `/teachers/${encodeURIComponent(
                                encrypt(profileData?.user_id)
                              )}`
                            )
                          }
                        >
                          프로필 상세보기
                        </Button>
                      </div>
                    )}
                  </Col>
                  <Col
                    x={24}
                    md={16}
                    style={{ position: 'relative', top: 0, left: 0 }}
                  >
                    <ul style={{ paddingLeft: screenSize >= 768 ? 30 : 0 }}>
                      <li style={{ marginBottom: 5 }}>
                        <Title
                          size={screenSize >= 768 ? 4 : 5}
                          color={'#9E9E9E'}
                        >
                          이름
                        </Title>
                        <p
                          style={{
                            fontSize: screenSize > 768 ? 16 : 14,
                            fontWeight: 500,
                          }}
                        >
                          {profileData?.name}
                        </p>
                      </li>
                      <li style={{ marginBottom: 5 }}>
                        <Title
                          size={screenSize >= 768 ? 4 : 5}
                          color={'#9E9E9E'}
                        >
                          연락처
                        </Title>
                        <p
                          style={{
                            fontSize: screenSize > 768 ? 16 : 14,
                            fontWeight: 500,
                          }}
                        >
                          {profileData?.phone}
                        </p>
                      </li>
                      <li style={{ marginBottom: 5 }}>
                        <Title
                          size={screenSize >= 768 ? 4 : 5}
                          color={'#9E9E9E'}
                        >
                          이메일
                        </Title>
                        <p
                          style={{
                            fontSize: screenSize > 768 ? 16 : 14,
                            fontWeight: 500,
                          }}
                        >
                          {profileData?.email}
                        </p>
                      </li>
                    </ul>
                    {screenSize >= 768 && (
                      <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Button
                          onClick={() =>
                            navigation(
                              `/teachers/${encodeURIComponent(
                                encrypt(profileData?.user_id)
                              )}`
                            )
                          }
                        >
                          프로필 상세보기
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
                <div style={{ marginTop: screenSize >= 768 ? 30 : 10 }}>
                  <Title size={screenSize >= 768 ? 4 : 5}>자격증</Title>
                  {profileData?.profile?.certificateFiles?.length ? (
                    <ul
                      style={{
                        height: 200,
                        overflowY: 'scroll',
                        borderTop: '1px solid #EEEEEE',
                      }}
                    >
                      {profileData?.profile?.certificateFiles?.map(
                        (item, index) => (
                          <li
                            key={`file-${index}`}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              padding: 5,
                              borderBottom: '1px solid #EEEEEE',
                            }}
                          >
                            <p
                              style={{
                                fontSize: screenSize >= 768 ? 18 : 14,
                                width: '80%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                paddingRight: 10,
                              }}
                            >
                              {item?.file_name}
                            </p>
                            <Btn
                              style={{ width: '20%' }}
                              onClick={() =>
                                handleFileDownload(
                                  `brainheal/users/${item?.user_id}/${item?.file_url}`,
                                  item?.file_name
                                )
                              }
                            >
                              {screenSize >= 768 ? (
                                '다운로드'
                              ) : (
                                <DownloadOutlined />
                              )}
                            </Btn>
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </div>
                <div style={{ marginTop: screenSize >= 768 ? 30 : 10 }}>
                  <Title size={screenSize >= 768 ? 4 : 5}>기타 첨부파일</Title>
                  {profileData?.profile?.attachmentFiles?.length ? (
                    <ul
                      style={{
                        height: 200,
                        overflowY: 'scroll',
                        borderTop: '1px solid #EEEEEE',
                      }}
                    >
                      {profileData?.profile?.attachmentFiles?.map(
                        (item, index) => (
                          <li
                            key={`file-${index}`}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              padding: 5,
                              borderBottom: '1px solid #EEEEEE',
                            }}
                          >
                            <p
                              style={{
                                fontSize: screenSize >= 768 ? 18 : 14,
                                width: '80%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                paddingRight: 10,
                              }}
                            >
                              {item?.file_name}
                            </p>
                            <Btn
                              style={{ width: '20%' }}
                              onClick={() =>
                                handleFileDownload(
                                  `brainheal/users/${item?.user_id}/${item?.file_url}`,
                                  item?.file_name
                                )
                              }
                            >
                              {screenSize >= 768 ? (
                                '다운로드'
                              ) : (
                                <DownloadOutlined />
                              )}
                            </Btn>
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </div>
              </Content>
            </Col>
          </Row>
          <Button
            style={{
              width: '100%',
              height: '56px',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '24px',
              marginTop: '30px',
            }}
            onClick={() => setProfileModal(false)}
          >
            확인
          </Button>
        </Content>
      </ModalLayout>

      {/* 강의 요청 모달 */}
      <ModalLayout
        type={screenSize >= 768 ? 'modal' : 'drawer'}
        open={requestModal}
        onCancel={setRequestModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: '40px' }}
        footer={
          screenSize < 768 ? (
            loginWho === 'agency' || loginWho === 'admin' ? (
              <Button
                style={{
                  width: '100%',
                  height: '56px',
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  marginTop: '64px',
                }}
                onClick={() => handleRequestSchedule()}
              >
                요청하기
              </Button>
            ) : (
              loginWho === 'teacher' &&
              submitType === 'update' && (
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  height={56}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '64px',
                  }}
                >
                  <Button
                    layoutStyle={{
                      width: '49%',
                      height: '100%',
                    }}
                    style={{
                      width: '100%',
                      height: '100%',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                    onClick={() => handleAcceptSchedule('Y')}
                  >
                    수락하기
                  </Button>
                  <Button
                    color="secondary"
                    layoutStyle={{ width: '49%', height: '100%' }}
                    style={{
                      width: '100%',
                      height: '100%',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                    onClick={() => handleAcceptSchedule('N')}
                  >
                    거절하기
                  </Button>
                </Content>
              )
            )
          ) : null
        }
        footerStyle={{ padding: 0 }}
        zIndex={5500}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Title
                align={'center'}
                style={{
                  fontSize: '30px',
                  fontWeight: 700,
                  lineHeight: '36px',
                }}
              >
                강의 요청
              </Title>
            </Col>
            <Col x={24} style={{ marginTop: '48px' }}>
              {loginWho === 'admin' ? (
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '24px',
                  }}
                >
                  <Col x={5}>
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                      }}
                    >
                      기관
                    </p>
                  </Col>
                  <Col x={19}>
                    <Select
                      size="large"
                      style={{ width: '100%' }}
                      dropdownStyle={{ zIndex: 5600 }}
                      placeholder="기관을 선택해주세요"
                      options={newAgencies?.map((item) => ({
                        label: (
                          <div>
                            <h4 style={{ fontSize: '16px' }}>
                              {item?.name}{' '}
                              <span
                                style={{ fontSize: '12px', color: '#BDBDBD' }}
                              >
                                {item.type}
                              </span>
                            </h4>
                            <p>
                              {item?.address} {item?.address_detail}
                            </p>
                          </div>
                        ),
                        value: item?.agency_id,
                      }))}
                      value={requestId}
                      onChange={(e) => setRequestId(e)}
                    />
                  </Col>
                </Row>
              ) : null}
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Col x={24}>
                  {loginWho === 'admin' && requestId ? (
                    <Content
                      maxWidth={'100%'}
                      style={{ border: '1px solid #EEEEEE' }}
                    >
                      <Row>
                        <Col x={24}>
                          <Row>
                            <Col x={6}>
                              <Avatar size={64}>
                                {agencyInfo?.name && agencyInfo?.name[0]}
                              </Avatar>
                            </Col>
                            <Col x={18}>
                              <Row style={{ marginBottom: '10px' }}>
                                <Col x={4}>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 700,
                                    }}
                                  >
                                    대표
                                  </p>
                                </Col>
                                <Col x={20}>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    {agencyInfo?.chief_name}
                                  </p>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: '10px' }}>
                                <Col x={4}>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 700,
                                    }}
                                  >
                                    연락처
                                  </p>
                                </Col>
                                <Col x={20}>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    {agencyInfo?.phone}
                                  </p>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: '10px' }}>
                                <Col x={4}>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 700,
                                    }}
                                  >
                                    이메일
                                  </p>
                                </Col>
                                <Col x={20}>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    {agencyInfo?.email}
                                  </p>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: '10px' }}>
                                <Col x={4}>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 700,
                                    }}
                                  >
                                    주소
                                  </p>
                                </Col>
                                <Col x={20}>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    {`${
                                      agencyInfo?.address
                                        ? agencyInfo?.address
                                        : '-'
                                    }\n ${
                                      agencyInfo?.address_detail
                                        ? agencyInfo?.address_detail
                                        : ''
                                    }`}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Content>
                  ) : loginWho === 'admin' && !requestId ? (
                    <Content
                      maxWidth={'100%'}
                      style={{
                        border: '1px solid #EEEEEE',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 150,
                      }}
                    >
                      <Title style={{ fontSize: '16px' }}>
                        강사에게 강의를 요청할 기관을 선택해주세요
                      </Title>
                    </Content>
                  ) : (
                    loginWho !== 'agency' && (
                      <Content
                        maxWidth={'100%'}
                        style={{ border: '1px solid #EEEEEE' }}
                      >
                        <Row>
                          <Col x={24}>
                            <Row>
                              <Col x={6}>
                                <Avatar size={64}>
                                  {inputData?.name && inputData?.name[0]}
                                </Avatar>
                              </Col>
                              <Col x={18}>
                                <Row style={{ marginBottom: '10px' }}>
                                  <Col x={4}>
                                    <p
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 700,
                                      }}
                                    >
                                      대표
                                    </p>
                                  </Col>
                                  <Col x={20}>
                                    <p
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                      }}
                                    >
                                      {inputData?.chief_name}
                                    </p>
                                  </Col>
                                </Row>
                                <Row style={{ marginBottom: '10px' }}>
                                  <Col x={4}>
                                    <p
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 700,
                                      }}
                                    >
                                      연락처
                                    </p>
                                  </Col>
                                  <Col x={20}>
                                    <p
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                      }}
                                    >
                                      {inputData?.phone}
                                    </p>
                                  </Col>
                                </Row>
                                <Row style={{ marginBottom: '10px' }}>
                                  <Col x={4}>
                                    <p
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 700,
                                      }}
                                    >
                                      이메일
                                    </p>
                                  </Col>
                                  <Col x={20}>
                                    <p
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                      }}
                                    >
                                      {inputData?.email}
                                    </p>
                                  </Col>
                                </Row>
                                <Row style={{ marginBottom: '10px' }}>
                                  <Col x={4}>
                                    <p
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 700,
                                      }}
                                    >
                                      주소
                                    </p>
                                  </Col>
                                  <Col x={20}>
                                    <p
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                      }}
                                    >
                                      {`${
                                        inputData?.address
                                          ? inputData?.address
                                          : '-'
                                      }\n ${
                                        inputData?.address_detail
                                          ? inputData?.address_detail
                                          : ''
                                      }`}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Content>
                    )
                  )}
                </Col>
              </Row>
              {/* 날짜 */}
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Col x={5}>
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    날짜
                  </p>
                </Col>
                <Col x={19}>
                  <DatePicker
                    size="large"
                    style={{ width: '100%' }}
                    value={
                      inputData?.date && moment(inputData?.date, 'YYYY-MM-DD')
                    }
                    format={'YYYY-MM-DD'}
                    onChange={(time, timeString) => {
                      handleChange('date', moment(timeString, 'YYYY-MM-DD'));
                    }}
                    popupStyle={{ zIndex: 5600 }}
                    inputReadOnly
                    placeholder="날짜를 선택해주세요"
                  />
                </Col>
                <Col x={5}></Col>
                <Col
                  x={19}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 15,
                  }}
                >
                  <Input
                    size="large"
                    style={{ width: 150 }}
                    placeholder="시작시간"
                    value={inputData?.start_time}
                    onChange={(e) =>
                      handleChange(
                        'start_time',
                        timeInputFormatter(e.target.value)
                      )
                    }
                    maxLength={5}
                  />
                  <p style={{ padding: '0 10px' }}>~</p>
                  <Input
                    size="large"
                    style={{ width: 150 }}
                    placeholder="시작시간"
                    value={inputData?.end_time}
                    onChange={(e) =>
                      handleChange(
                        'end_time',
                        timeInputFormatter(e.target.value)
                      )
                    }
                    maxLength={5}
                  />
                </Col>
              </Row>
              {/* 프로그램 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>프로그램</Title>
                </Col>
                <Col x={19}>
                  <Select
                    size="large"
                    options={treeData}
                    value={inputData?.category_id}
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 5700 }}
                    onChange={(e) => handleChange('category_id', e)}
                    placeholder="프로그램을 선택해주세요"
                  />
                </Col>
              </Row>
              {/* 교안 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 15,
                }}
              >
                <Col x={5}>
                  <Title>교안</Title>
                </Col>
                <Col x={19} padding={0}>
                  {programValue?.category ? (
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 18,
                          display: 'flex',
                          alignItems: 'center',
                          color: '#000000',
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            marginRight: 10,
                            display: 'inline-block',
                            padding: '3px 5px',
                            background: '#E3A4AF',
                            color: '#FFFFFF',
                          }}
                        >
                          {programValue?.category}
                        </span>
                        {programValue?.title}
                      </p>
                      <button
                        style={{
                          padding: '0px 5px',
                          cursor: 'pointer',
                          background: '#FFFFFF',
                          border: '1px solid #EEEEEE',
                        }}
                        onClick={() => setProgramValue({})}
                      >
                        x
                      </button>
                    </Content>
                  ) : (
                    <Content maxWidth={'100%'} padding={0}>
                      <Btn type="primary" onClick={() => setProgramModal(true)}>
                        교안검색
                      </Btn>
                    </Content>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          {submitType === 'create' ? (
            <Button
              style={{
                width: '100%',
                height: '56px',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '24px',
                marginTop: '64px',
              }}
              onClick={() => handleRequestSchedule()}
            >
              요청하기
            </Button>
          ) : loginWho === 'agency' && inputData?.registrant === 'agency' ? (
            <Button
              style={{
                width: '100%',
                height: '56px',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '24px',
                marginTop: '64px',
              }}
              onClick={() => handleRequestSchedule()}
              disabled={inputData?.confirm_check === 'Y' ? true : false}
            >
              수정하기
            </Button>
          ) : loginWho === 'agency' && inputData?.registrant !== 'agency' ? (
            <Content
              maxWidth={'100%'}
              padding={0}
              height={56}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '64px',
              }}
            >
              <Button
                layoutStyle={{
                  width: '49%',
                  height: '100%',
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
                onClick={() => handleAcceptSchedule('Y')}
              >
                수락하기
              </Button>
              <Button
                color="secondary"
                layoutStyle={{ width: '49%', height: '100%' }}
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
                onClick={() => handleAcceptSchedule('N')}
              >
                거절하기
              </Button>
            </Content>
          ) : loginWho === 'teacher' && inputData?.registrant === 'teacher' ? (
            <Button
              style={{
                width: '100%',
                height: '56px',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '24px',
                marginTop: '64px',
              }}
              onClick={() => handleRequestSchedule()}
              disabled={inputData?.confirm_check === 'Y' ? true : false}
            >
              수정하기
            </Button>
          ) : loginWho === 'teacher' && inputData?.registrant !== 'teacher' ? (
            <Content
              maxWidth={'100%'}
              padding={0}
              height={56}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '64px',
              }}
            >
              <Button
                layoutStyle={{
                  width: '49%',
                  height: '100%',
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
                onClick={() => handleAcceptSchedule('Y')}
              >
                수락하기
              </Button>
              <Button
                color="secondary"
                layoutStyle={{ width: '49%', height: '100%' }}
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
                onClick={() => handleAcceptSchedule('N')}
              >
                거절하기
              </Button>
            </Content>
          ) : (
            loginWho === 'admin' && (
              <Content
                maxWidth={'100%'}
                padding={0}
                // height={56}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '64px',
                  flexDirection: 'column',
                }}
              >
                <Button
                  layoutStyle={{ width: '100%', marginBottom: '10px' }}
                  style={{
                    width: '100%',
                    height: '56px',
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    marginTop: '64px',
                  }}
                  disabled={inputData?.confirm_check === 'Y' ? true : false}
                  onClick={() => handleRequestSchedule()}
                >
                  수정하기
                </Button>
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    layoutStyle={{
                      width: '49%',
                      height: '56px',
                    }}
                    style={{
                      width: '100%',
                      height: '100%',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                    onClick={() => handleAcceptSchedule('Y')}
                  >
                    수락하기
                  </Button>
                  <Button
                    color="secondary"
                    layoutStyle={{ width: '49%', height: '100%' }}
                    style={{
                      width: '100%',
                      height: '56px',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                    onClick={() => handleAcceptSchedule('N')}
                  >
                    거절하기
                  </Button>
                </Content>
              </Content>
            )
          )}
          {/* {loginWho === 'agency' || loginWho === 'admin' ? (
            <Button
              style={{
                width: '100%',
                height: '56px',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '24px',
                marginTop: '64px',
              }}
              onClick={() => handleRequestSchedule()}
            >
              요청하기
            </Button>
          ) : (
            loginWho === 'teacher' &&
            submitType === 'update' && (
              <Content
                maxWidth={'100%'}
                padding={0}
                height={56}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '64px',
                }}
              >
                <Button
                  layoutStyle={{
                    width: '49%',
                    height: '100%',
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '24px',
                  }}
                  onClick={() => handleAcceptSchedule('Y')}
                >
                  수락하기
                </Button>
                <Button
                  color="secondary"
                  layoutStyle={{ width: '49%', height: '100%' }}
                  style={{
                    width: '100%',
                    height: '100%',
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '24px',
                  }}
                  onClick={() => handleAcceptSchedule('N')}
                >
                  거절하기
                </Button>
              </Content>
            )
          )} */}
        </Content>
      </ModalLayout>

      {/* 해당 일 상세모달 */}
      <ModalLayout
        type={'drawer'}
        title={moment(clickDate).format('YYYY년 MM월 DD일')}
        open={detailModal}
        onCancel={setDetailModal}
        placement={screenSize >= 768 ? 'right' : 'bottom'}
        width={screenSize >= 768 ? 400 : '100%'}
        height={'80%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={5000}
        footer={
          loginWho === 'agency' && request ? (
            <Content maxWidth={'100%'} height={60} padding={0}>
              <Button
                layoutStyle={{ width: '100%', height: '100%', borderRadius: 0 }}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 0,
                  fontSize: '18px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setRequestModal(true);
                  setSubmitType('create');
                }}
              >
                스케쥴 요청
              </Button>
            </Content>
          ) : loginWho === 'admin' &&
            !window.location.href.includes('agency-') ? (
            <Content
              maxWidth={'100%'}
              height={60}
              padding={0}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                layoutStyle={{ width: '50%', height: '100%', borderRadius: 0 }}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 0,
                  fontSize: '18px',
                }}
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setRequestModal(true);
                  setSubmitType('create');
                }}
              >
                스케쥴 요청
              </Button>
              <Button
                layoutStyle={{ width: '50%', height: '100%', borderRadius: 0 }}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 0,
                  fontSize: '18px',
                }}
                onClick={(e) => {
                  e.stopPropagation(); // 이벤트 전파 중단
                  handleSelectEvent(isOpened);
                  setSubmitType('create');
                }}
              >
                스케쥴 추가
              </Button>
            </Content>
          ) : loginWho === 'admin' &&
            window.location.href.includes('agency-') ? (
            <Content
              maxWidth={'100%'}
              height={60}
              padding={0}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                layoutStyle={{ width: '100%', height: '100%', borderRadius: 0 }}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 0,
                  fontSize: '18px',
                }}
                onClick={(e) => {
                  e.stopPropagation(); // 이벤트 전파 중단
                  handleSelectEvent(isOpened);
                  setSubmitType('create');
                }}
              >
                스케쥴 추가
              </Button>
            </Content>
          ) : loginWho === 'teacher' &&
            parseInt(getCookie('USER')) === teacherId ? (
            <Content maxWidth={'100%'} height={60} padding={0}>
              <Button
                layoutStyle={{ width: '100%', height: '100%', borderRadius: 0 }}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 0,
                  fontSize: '18px',
                }}
                onClick={(e) => {
                  e.stopPropagation(); // 이벤트 전파 중단
                  handleSelectEvent(isOpened);
                  setSubmitType('create');
                }}
              >
                스케쥴 추가
              </Button>
            </Content>
          ) : null
        }
        footerStyle={{
          padding: 0,
        }}
      >
        {modalData?.map((item) => (
          <Card
            key={`item-${item.id}`}
            hoverable={update ? true : false}
            style={{ marginBottom: '10px' }}
            onClick={() => {
              if (update) {
                handleScheduleClick(item);
              } else {
                return;
              }
            }}
          >
            <Content
              maxWidth={'100%'}
              padding={0}
              style={{ position: 'relative', top: 0, left: 0 }}
            >
              {item?.is_calculate === 'N' ? (
                update &&
                (loginWho === 'teacher' || loginWho === 'admin') &&
                item.type !== 'R' ? (
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSchedule(item?.id);
                      }}
                      style={{
                        fontSize: '18px',
                        fontWeight: 700,
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      <DeleteOutlined style={{ color: '#FB4E4E' }} />
                    </button>
                  </div>
                ) : update &&
                  (loginWho === 'agency' || loginWho === 'admin') ? (
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    <button
                      style={{
                        fontSize: '18px',
                        fontWeight: 700,
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSchedule(item?.id);
                      }}
                    >
                      <DeleteOutlined style={{ color: '#FB4E4E' }} />
                    </button>
                  </div>
                ) : null
              ) : null}

              <p
                style={{
                  fontWeight: 700,
                  color:
                    item?.type === 'S'
                      ? '#4B55F8'
                      : item?.type === 'H'
                      ? '#FE4279'
                      : item?.type === 'F'
                      ? '#AB5476'
                      : item?.type === 'N'
                      ? '#E0E0E0'
                      : '#8631F4',
                }}
              >
                {item?.category_name}
                <span
                  style={{
                    color: '#9E9E9E',
                    marginLeft: '5px',
                    fontWeight: 400,
                  }}
                >
                  {moment(item?.start).format('HH:mm')} ~{' '}
                  {moment(item?.end).format('HH:mm')}
                </span>
              </p>
              <h3 style={{ margin: '5px 0 0' }}>
                {profile ? (
                  <span>
                    {agencyDetail ? item?.name : `${item?.name[0]}***`}
                    <Btn
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={(e) => handleProfileMoadl(e, item)}
                    >
                      프로필
                    </Btn>
                  </span>
                ) : (
                  <>{agencyDetail ? item?.name : `${item?.name[0]}***`}</>
                )}{' '}
                {loginWho === 'agency' ? (
                  item?.user_confirm === 'C' ? (
                    <span
                      style={{
                        color: '#03A9F4',
                        marginLeft: '5px',
                        fontWeight: 700,
                        fontSize: 12,
                      }}
                    >
                      (확인중)
                    </span>
                  ) : (
                    item?.user_confirm === 'N' && (
                      <span
                        style={{
                          color: '#FB4E4E',
                          marginLeft: '5px',
                          fontWeight: 700,
                          fontSize: 12,
                        }}
                      >
                        (거절됨)
                      </span>
                    )
                  )
                ) : null}
                {loginWho === 'teacher' ? (
                  item?.agency_confirm === 'C' ? (
                    <span
                      style={{
                        color: '#03A9F4',
                        marginLeft: '5px',
                        fontWeight: 700,
                        fontSize: 12,
                      }}
                    >
                      (확인중)
                    </span>
                  ) : (
                    item?.agency_confirm === 'N' && (
                      <span
                        style={{
                          color: '#FB4E4E',
                          marginLeft: '5px',
                          fontWeight: 700,
                          fontSize: 12,
                        }}
                      >
                        (거절됨)
                      </span>
                    )
                  )
                ) : null}
              </h3>
              {agencyDetail && (
                <>
                  <p>
                    {loginWho === 'teacher' || !agencyView
                      ? `${item?.address}`
                      : (loginWho === 'agency' || agencyView) &&
                        `${item?.phone}`}
                  </p>
                  <p>
                    {loginWho === 'teacher' || !agencyView
                      ? `${item?.address_detail ? item?.address_detail : ''}`
                      : (loginWho === 'agency' || agencyView) &&
                        `${item?.email}`}
                  </p>
                </>
              )}

              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {program ? (
                  <p style={{ fontWeight: 700 }}>
                    교안{' | '}
                    {item?.content && item?.content.title ? (
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#81D4FA',
                          fontWeight: 400,
                        }}
                        onClick={(e) => handleNavigateProgram(e, item)}
                      >
                        {item?.content?.title}
                      </span>
                    ) : (
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#FB4E4E',
                          fontWeight: 400,
                        }}
                        onClick={(e) => handleNavigateProgram(e, item)}
                      >
                        미등록
                      </span>
                    )}
                  </p>
                ) : null}

                {confirm && item?.type === 'S' ? (
                  (loginWho === 'teacher' && item?.user_check) ||
                  (loginWho === 'agency' && item?.agency_check) ? (
                    <CheckCircleOutlined
                      style={{ color: '#2AC769', fontSize: 18 }}
                    />
                  ) : (
                    <Btn onClick={(e) => handleScheduleCheck(e, item)}>
                      확정
                    </Btn>
                  )
                ) : null}
              </div>
            </Content>
          </Card>
        ))}
      </ModalLayout>

      {/* 프로그램 선택 모달 */}
      <ModalLayout
        title={'프로그램'}
        type={screenSize >= 768 ? 'modal' : 'drawer'}
        open={programModal}
        onCancel={setProgramModal}
        placement={screenSize >= 768 ? 'right' : 'bottom'}
        width={screenSize >= 768 ? 768 : '100%'}
        height={'80%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={5700}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={12} md={7} padding={5}>
              <p>구분</p>
              <Select
                value={majorDetailValue}
                onChange={(e) => setMajorDetailValue(e)}
                style={{ width: '100%' }}
                size="large"
                options={majorDetailItems}
                dropdownStyle={{
                  zIndex: 5800,
                }}
              />
            </Col>
            <Col x={12} md={10} padding={5}>
              <p>프로그램</p>
              <Input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                style={{ width: '100%' }}
                size="large"
                placeholder="프로그램명을 검색해주세요"
              />
            </Col>
          </Row>
          <Row>
            <Col x={24} style={{ marginTop: 20, border: '1px solid #EEEEEE' }}>
              <Row>
                <Col
                  x={8}
                  padding={5}
                  style={{
                    borderRight: '1px solid #EEEEEE',
                    color: '#BDBDBD',
                    fontWeight: 700,
                  }}
                >
                  구분
                </Col>
                <Col
                  x={16}
                  padding={5}
                  style={{
                    color: '#BDBDBD',
                    fontWeight: 700,
                  }}
                >
                  프로그램명
                </Col>
              </Row>
            </Col>
            <Col
              x={24}
              style={{
                border: '1px solid #EEEEEE',
                borderTop: 'none',
                height: 500,
                overflowY: 'scroll',
              }}
            >
              {programItems && programItems?.length ? (
                <List>
                  {programItems?.map((item) => (
                    <List.Item
                      key={item?.program_id}
                      style={{
                        borderBottom: '1px solid #EEEEEE',
                        padding: 0,
                      }}
                    >
                      <Col
                        x={8}
                        padding={'10px 5px'}
                        style={{
                          borderRight: '1px solid #EEEEEE',
                          color: '#000000',
                          fontWeight: 700,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {item?.category_name}
                      </Col>
                      <Col
                        x={16}
                        padding={'10px 5px'}
                        style={{
                          color: '#000000',
                          fontWeight: 700,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {item?.content?.title}
                        <Btn
                          onClick={() => {
                            setProgramValue(item?.content);
                            setProgramModal(false);
                          }}
                        >
                          선택
                        </Btn>
                      </Col>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <Row
                  style={{
                    borderBottom: '1px solid #EEEEEE',
                    height: '100%',
                  }}
                >
                  <Col x={24}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{
                        height: '100%',
                        margin: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

CalendarComp.defaultProps = {
  isHeader: true,
  items: [],
  add: false,
  detail: false,
  request: false,
  update: false,
  agencyView: true,
  confirm: false,
  profile: false,
  program: false,
  agencyDetail: true,
};

export default CalendarComp;
