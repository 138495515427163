import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 정산 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetCalculates: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getCalculates', params] : 'getCalculates',
        async () => await $http.get(APIConstant.GET_CALCULATES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 정산 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetCalculate: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_CALCULATE, {
        calculate_id: idx,
      });
      const res = useQuery(
        params ? ['getCalculate', params] : 'getCalculate',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 정산 생성
   * --
   * @method POST
   * @returns
   */
  CreateCalculate: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_CALCULATE, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getCalculates');
            queryClient.invalidateQueries('getCalculate');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 정산 일괄 생성
   * --
   * @method POST
   * @returns
   */
  BulkCreateCalculate: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.BULK_CREATE_CALCULATE,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getCalculates');
            queryClient.invalidateQueries('getCalculate');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 정산 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateCalculate: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_CALCULATE, {
            calculate_id: data.calculate_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getCalculates');
            queryClient.invalidateQueries('getCalculate');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 정산 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteCalculate: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_CALCULATE, {
            calculate_id: data.calculate_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getCalculates');
            queryClient.invalidateQueries('getCalculate');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 정산 엑셀 파일
   * --
   * @method GET
   * @returns
   */
  GetCalculateExcel: async (params = null, options = {}) => {
    try {
      const res = await $http.get(APIConstant.GET_CALCULATE_EXCEL, params);

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
