import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './AgencySearch.style.css';
import MessageAlert from 'utils/MessageAlert';
import { AgencyStorageApi } from 'api';
import { Empty } from 'antd';
import { LoadingPage } from 'components/Pages';

const AgencySearch = () => {
  /* ===== STATE ===== */
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  /* ===== FUNCTION ===== */
  const handleSearch = async () => {
    if (!searchValue || searchValue === '')
      return MessageAlert.warning('기관명을 입력해주세요');

    try {
      setIsLoading(true);
      const { status, data } = await AgencyStorageApi.GetAgencyStorages({
        keyword: encodeURIComponent(searchValue),
      });

      if (status === 200) {
        setList(data);
      } else {
        setList([]);
      }
    } catch (err) {
      console.error('검색실패!', err);
      throw err;
    } finally {
      setTimeout(() => setIsLoading(false), 500);
    }
  };

  const handleClick = (item) => {
    localStorage.setItem('selectedValue', JSON.stringify(item));
    window.opener.parentCallback();
    window.close();
  };

  return (
    <div id="agency-search-popup">
      <div className="agency-search-box">
        <Input
          size="large"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="기관명을 입력해주세요"
          onPressEnter={() => handleSearch()}
          style={{ height: 60, borderRadius: 0 }}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          style={{ width: 60, height: 60 }}
          onClick={() => handleSearch()}
        />
      </div>
      <div className="agency-list-box">
        {isLoading ? (
          <LoadingPage text={'검색중입니다'} />
        ) : list?.length ? (
          <ul className="agency-list">
            {list?.map((item) => (
              <li key={`agency_storage_${item?.agency_storage_id}`}>
                <div>
                  <h4>
                    {item?.agency_name} <span>({item?.agency_type})</span>
                  </h4>
                  <p>{item?.agency_address}</p>
                </div>
                <button onClick={() => handleClick(item)}>선택</button>
              </li>
            ))}
          </ul>
        ) : (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AgencySearch;
