import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import './BoardLayout.style.css';
import { Content, Row, Col, Title, Button, ModalLayout } from 'components';
import { MenuLayout, BoardTable } from './components';
import { Select, Pagination, Button as Btn, Input } from 'antd';
import { decrypt } from 'utils/Crypto';
import { BoardApi, LectureApi, LectureOfUserApi, UserApi } from 'api';
import moment from 'moment';
import UseDebounce from 'utils/useDebounce';
import MessageAlert from 'utils/MessageAlert';
import { MenuOutlined } from '@ant-design/icons';

// 게시글 보기 카운트
const count = [
  {
    label: '10개씩 보기',
    value: 10,
  },
  {
    label: '30개씩 보기',
    value: 30,
  },
  {
    label: '50개씩 보기',
    value: 50,
  },
];

// 검색 옵션
const option = [
  {
    label: '제목',
    value: 'title',
  },
  {
    label: '글쓴이',
    value: 'name',
  },
];

const BoardLayout = (props) => {
  /* ===== PROPS ===== */
  const { navigation, loginWho, userInfoData } = props;

  /* ===== ROUTE ===== */
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('lecture');
  const type = searchParams.get('type');

  /* ===== MUTATE ===== */
  const deletBoard = BoardApi.DeleteBoard();

  /* ===== DATA ===== */
  const { data: board, refetch: boardRefetch } = BoardApi.GetBoards(
    {
      lecture_id: decrypt(lectureId),
    },
    {
      enabled: !!lectureId,
      select: (data) => data?.data,
    }
  );
  const { data: lecture, refetch: lectureRefetch } = LectureApi.GetLecture(
    decrypt(lectureId),
    null,
    {
      enabled: !!lectureId,
      select: (data) => data?.data,
    }
  );
  const { data: student, refetch: studentRefetch } =
    LectureOfUserApi.GetLectureOfUsers(
      {
        lecture_id: decrypt(lectureId),
      },
      {
        enabled: !!lectureId,
        select: (data) => data?.data,
      }
    );
  const { data: teacher, refetch: teacherRefetch } = UserApi.GetUser(
    lecture?.user_id,
    null,
    {
      enabled: !!lecture,
      select: (data) => data?.data,
    }
  );

  /* ===== STATE ===== */
  // 정제된 데이터
  const [data, setData] = useState({});
  const [countValue, setCountValue] = useState(10);
  // 접속 유저 게시판 권한
  const [boardAuth, setBoardAuth] = useState('READER');
  // 테이블 데이터
  const [tableData, setTableData] = useState({});
  // 페이지네이션
  const [page, setPage] = useState(1);
  // 검색
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = UseDebounce(searchValue, 300);
  const [searchOption, setSearchOption] = useState('title');
  // 체크박스
  const [checkAll, setCheckAll] = useState(false);
  const [checkList, setCheckList] = useState([]);
  const [renderCount, setRenderCount] = useState(0);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  // 반응형 메뉴
  const [isOpened, setIsOpened] = useState(false);

  /* ===== VARIDATE ===== */
  // 헤더 기본값
  const tableHeader = useMemo(
    () => [
      {
        title: '번호',
        key: 'index',
        width: screenSize >= 768 ? 5 : 8,
      },
      {
        title: '제목',
        key: 'title',
        width: screenSize >= 768 ? 65 : 55,
        render: (item, auth) => (
          <>
            {item['type'] === 'ASSIGNMENT' && (
              <span
                style={{
                  background:
                    item['task_status'] === 'DEFAULT' ? '#03A9F4' : '#2AC769',
                }}
              >
                {item['task_status'] === 'DEFAULT' ? '확인중' : '확인완료'}
              </span>
            )}
            {item['title']}
            {auth === 'WRITER' &&
              item['type'] === 'ASSIGNMENT' &&
              item['task_similarity_status'] === 'Y' && (
                <span className="task_similarity">모사답안 주의</span>
              )}
          </>
        ),
      },
      {
        title: '글쓴이',
        key: 'writer',
        width: screenSize >= 768 ? 10 : 17,
      },
      {
        title: '날짜',
        key: 'board_update_at',
        width: 15,
        render: (item) =>
          moment(item['board_update_at']).format(
            screenSize >= 768 ? 'YYYY-MM-DD HH:mm' : 'YY.MM.DD'
          ),
      },
    ],
    [screenSize]
  );

  // 게시판 메뉴 설정
  const boardMenu = useMemo(() => {
    const menu = [
      {
        key: 'INQUIRY',
        title: '문의',
        path: `/popup/board?lecture=${encodeURIComponent(
          lectureId
        )}&type=inquiry`,
      },
      {
        key: 'DISCUSSION',
        title: '토론',
        path: `/popup/board?lecture=${encodeURIComponent(
          lectureId
        )}&type=discussion`,
      },
      {
        key: 'ASSIGNMENT',
        title: '과제',
        path: `/popup/board?lecture=${encodeURIComponent(
          lectureId
        )}&type=assignment`,
      },
    ];

    return menu;
  }, [lectureId]);

  /**
   * 게시판 데이터 정제
   * --
   */
  const boardData = useMemo(() => {
    // 게시판 파일 정제
    const files = board?.reduce((acc, cur) => {
      let arr = acc;
      if (cur?.board_file?.length) {
        acc = [...arr, ...cur?.board_file];
        return acc;
      } else {
        acc = arr;
        return acc;
      }
    }, []);

    const newData = {
      teacher: teacher,
      board: board?.map((item) => {
        const { comment, ...d } = item;

        return {
          ...d,
          ...student?.find((user) => item?.user_id === user?.user_id),
          writer:
            item?.user_id === 0
              ? '관리자'
              : lecture?.user_id === item?.user_id
              ? `${teacher?.name} 강사님`
              : student?.find((user) => item?.user_id === user?.user_id)?.name,
          board_created_at: item?.created_at,
          board_update_at: item?.updated_at,
          comment: comment?.map((subitem) => ({
            ...subitem,
            writer:
              subitem?.user_id === 0
                ? '관리자'
                : lecture?.user_id === subitem?.user_id
                ? `${teacher?.name} 강사님`
                : student?.find((user) => subitem?.user_id === user?.user_id),
          })),
        };
      }),
      files: files,
      lecture: lecture,
      student: student,
    };

    return newData;
  }, [board, lecture, teacher, student]);

  /* ===== RENDER ===== */
  /**
   * 데이터 Refetch
   * --
   */
  useEffect(() => {
    if (lectureId) {
      boardRefetch();
      lectureRefetch();
      studentRefetch();
    }
  }, [lectureId, boardRefetch, lectureRefetch, studentRefetch]);

  /**
   * 강사 데이터 Refetch
   */
  useEffect(() => {
    if (lecture) {
      teacherRefetch();
    }
  }, [lecture, teacherRefetch]);

  /**
   * 데이터 값 렌더링
   * --
   */
  useEffect(() => {
    setData(boardData);
  }, [boardData]);

  /**
   * 게시판 타입별 데이터 정제
   * --
   */
  useEffect(() => {
    const tHead = [...tableHeader];
    const tTypeData = data?.board
      ?.filter((item) => item?.type?.toLocaleLowerCase() === type)
      ?.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
    const sortData = tTypeData
      ?.sort((a, b) => new Date(b?.index) - new Date(a?.index))
      ?.filter((item) => item[searchOption]?.includes(debouncedValue));

    // 렌더링 데이터 정제
    const tData = sortData?.reduce((acc, cur, idx) => {
      if ((page - 1) * countValue <= idx && idx < page * countValue) {
        acc.push(cur);
        return acc;
      } else {
        return acc;
      }
    }, []);

    setTableData({
      tHead: tHead,
      total: sortData?.length,
      data: tData,
    });
  }, [
    type,
    data?.board,
    boardAuth,
    debouncedValue,
    searchOption,
    page,
    countValue,
    tableHeader,
  ]);

  /**
   * 게시판 권한 체크
   * NOTE
   * WRITER: 수정, 삭제, 등록, 읽기 권한
   * READER: 등록, 읽기 권한
   * --
   */
  useEffect(() => {
    if (loginWho === 'admin') {
      setBoardAuth('WRITER');
    } else if (data?.teacher?.user_id === userInfoData?.user_id) {
      setBoardAuth('WRITER');
    } else {
      setBoardAuth('READER');
    }
  }, [loginWho, data, userInfoData]);

  /**
   * 페이지 초기화
   * --
   */
  useEffect(() => {
    setPage(1);
  }, [type]);

  /**
   * 렌더링된 아이템 수 계산
   * --
   */
  useEffect(() => {
    const dataLength = tableData?.data?.length;
    const rCount = page * countValue - dataLength;
    if (rCount < 0) {
      setRenderCount(countValue);
    } else {
      setRenderCount(countValue - rCount);
    }
  }, [page, countValue, tableData?.data]);

  /**
   * 전체 선택
   * --
   */
  useEffect(() => {
    if (checkList?.length && renderCount === checkList?.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [renderCount, checkList]);

  /**
   * 기관회원 + 비구매고객 접근제한
   * --
   */
  useEffect(() => {
    if (loginWho && userInfoData && student && lecture) {
      // 기관회원 접근 제한
      if (loginWho === 'agency') {
        MessageAlert.warning('접근 권한이 없습니다!');
        navigation('/');
      }

      // 등록한 강사인지 체크

      // 구매고객인지 체크
      const findStudent = student?.some(
        (item) => item?.user_id === userInfoData?.user_id
      );
      if (!findStudent && (loginWho === 'teacher' || loginWho === 'agency')) {
        if (lecture?.user_id === userInfoData?.user_id) {
          return;
        }
        MessageAlert.warning('접근 권한이 없습니다!');
        navigation('/');
      }
    }
  }, [loginWho, userInfoData, student, navigation, lecture]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== FUNCTION ===== */
  /**
   * 체크박스 전체선택 핸들러
   */
  const handleAllChecked = (check) => {
    if (check) {
      const ids = tableData?.data?.map((item) => item?.board_id);
      setCheckList(ids);
    } else {
      setCheckList([]);
    }
    setCheckAll(check);
  };

  /**
   * 체크박스 핸들러
   * --
   */
  const handleChecked = (id) => {
    let checked = [...checkList];

    const isChecked = checked?.findIndex((item) => item === id);
    if (isChecked === -1) {
      checked.push(id);
    } else {
      checked = checked?.filter((item) => item !== id);
    }
    setCheckList(checked);
  };

  /**
   * 선택 삭제
   * --
   */
  const handleDeleteBoard = () => {
    if (!checkList?.length) {
      MessageAlert.warning('삭제할 게시물을 선택해주세요!');
      return;
    }

    const confirm = window.confirm(
      `${checkList?.length}개의 게시물을 삭제하시겠습니까?`
    );
    if (confirm) {
      try {
        checkList?.forEach((item) => {
          deletBoard.mutate({
            board_id: item,
          });
        });
        setCheckAll(false);
        MessageAlert.success('삭제되었습니다.');
      } catch (err) {
        MessageAlert.error('삭제하는데 실패하였습니다!');
        console.log('ERROR!!', err);
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다.');
    }
  };

  return (
    <>
      <Content maxWidth={1500}>
        <Row style={{ marginBottom: 30 }}>
          <Col x={21} md={24}>
            <Title size={screenSize >= 768 ? 2 : 3}>
              {data?.lecture?.title} 강의 게시판
            </Title>
          </Col>
          <Col x={3} md={0}>
            <Btn
              onClick={() => setIsOpened(!isOpened)}
              style={{
                float: 'right',
                background: '#E3A4AF',
                border: '1px solid #E3A4AF',
                color: '#FFFFFF',
              }}
            >
              <MenuOutlined />
            </Btn>
          </Col>
        </Row>
        <Row>
          <Col x={0} md={4}>
            <MenuLayout menu={boardMenu} currentType={type} />
          </Col>
          <Col x={24} md={20}>
            <Title>
              {
                boardMenu?.find(
                  (item) => item?.key?.toLocaleLowerCase() === type
                )?.title
              }{' '}
              게시판
            </Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px 0',
              }}
            >
              <p style={{ color: '#9E9E9E' }}>
                총 {tableData?.data?.length}개의 게시물
              </p>
              <Select
                value={countValue}
                options={count}
                onChange={(e) => setCountValue(e)}
              />
            </div>

            {/* 게시판 */}
            <div>
              <BoardTable
                header={tableData?.tHead}
                data={tableData?.data}
                count={countValue}
                auth={boardAuth}
                page={page}
                checkAll={checkAll}
                checkList={checkList}
                onCheckAllChange={handleAllChecked}
                onCheckChange={(id) => handleChecked(id)}
                screenSize={screenSize}
              />
            </div>
            {/* /게시판 */}

            {/* 버튼영역 */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: screenSize >= 768 ? 'center' : 'flex-end',
                margin: '10px 0',
                flexDirection: screenSize >= 768 ? 'row' : 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '10px 0',
                }}
              >
                <Select
                  size="large"
                  value={searchOption}
                  options={option}
                  onChange={(e) => setSearchOption(e)}
                />
                <Input
                  size="large"
                  placeholder={`${
                    searchOption === 'title' ? '제목' : '이름'
                  }을 입력해주세요`}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: screenSize >= 768 ? '10px 0' : 0,
                }}
              >
                {boardAuth === 'WRITER' && (
                  <Btn
                    style={{ marginRight: 5 }}
                    danger
                    disabled={!checkList?.length}
                    onClick={() => handleDeleteBoard()}
                  >
                    선택 삭제
                  </Btn>
                )}
                <Button
                  onClick={() =>
                    navigation(
                      `/popup/board/write?lecture=${encodeURIComponent(
                        lectureId
                      )}&type=${type}`,
                      {
                        state: {
                          data: data,
                        },
                      }
                    )
                  }
                >
                  글쓰기
                </Button>
              </div>
            </div>
            {/* /버튼영역 */}

            {/* 페이지네이션 */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '10px 0',
              }}
            >
              <Pagination
                current={page}
                total={tableData?.total}
                pageSize={countValue}
                onChange={(p) => setPage(p)}
              />
            </div>
            {/* /페이지네이션 */}
          </Col>
        </Row>
      </Content>

      {/* 반응형 메뉴 모달 */}
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'right'}
        width={'60%'}
        height={'100%'}
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={10}>
          <Row>
            <Col x={24}>
              <MenuLayout menu={boardMenu} currentType={type} />
            </Col>
          </Row>
        </Content>
      </ModalLayout>
      {/* /반응형 메뉴 모달 */}
    </>
  );
};

export default BoardLayout;
