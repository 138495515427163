import React from 'react';
import EmailLogPresenter from './EmailLogPresenter';

const EmailLogContainer = ({ data }) => {
  /* ===== PROPS ===== */

  /* ===== STATE ===== */

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */

  /* ===== RENDER ===== */
  return (
    <EmailLogPresenter
      users={data?.users}
      agency={data?.agencies}
      teacher={data?.teachers}
    />
  );
};

export default EmailLogContainer;
