import React, { useState, useEffect } from 'react';
import PointOrdersPresenter from './PointOrdersPresenter';

const PointOrdersContainer = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { users, teachers, agencies, products } = data;
      if (users && teachers && products) {
        const newUsers = [...users, ...teachers, ...agencies];
        setNewData(newUsers);
      }
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <PointOrdersPresenter userList={newData} />;
};

PointOrdersContainer.defaultProps = {};

export default PointOrdersContainer;
