import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { stringToMoneyFormat } from 'utils';
import moment from 'moment';
import './LectureCard.style.css';

const LectureCard = ({
  thumbnail,
  event,
  eventColor,
  title,
  category,
  start,
  teacher,
  end,
  price,
  productCheck,
  isCash,
}) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== STYLES ===== */
  const styles = {
    thumbnail: {
      position: 'relative',
      top: 0,
      left: 0,
      width: '100%',
      height: '223px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    event: {
      position: 'absolute',
      bottom: '12px',
      right: '12px',
      width: '55px',
      height: '55px',
      background: '#FFFFFF',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // color: event === 'NEW' ? '#0B8EA9' : '#000',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '17px',
      textTransform: 'capitalize',
      ...eventColor,
    },
    category: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.02em',
      color: '#000000',
      marginTop: '12px',
    },
    title: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      height: '44px',
      letterSpacing: '0.02em',
      color: '#000000',
      margin: '6px 0 14px',
    },
    content: {
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.02em',
      color: '#000000',
    },
    subList: {
      display: 'flex',
    },
  };

  /* ===== RENDER ===== */
  return (
    <div className="lecture_card_layout">
      <div
        className="lecture_card_thumbnail"
        style={{
          ...styles.thumbnail,
          background: thumbnail
            ? `url(${thumbnail}) no-repeat center center/contain`
            : '#D9D9D9',
          border: thumbnail && '1px solid #EEEEEE',
        }}
      >
        {thumbnail ? null : (
          <FontAwesomeIcon style={{ fontSize: '50px' }} icon={faImage} />
        )}
        {event !== 'none' ? (
          <div className="lecture_card_event" style={{ ...styles.event }}>
            {event}
          </div>
        ) : null}
      </div>
      <ul>
        <li>
          <p style={{ ...styles.category }}>{category}</p>
        </li>
        <li>
          <p className="lecture_card_title" style={{ ...styles.title }}>
            {title}
          </p>
        </li>
        <li>
          <ul className="lecture_card_info" style={{ ...styles.subList }}>
            <li>
              <p style={{ ...styles.content }}>
                {start && moment(start).format('YYYY.MM.DD') + ' ~ '}
                {end && moment(end).format('YYYY.MM.DD')}
                {/* {price &&
                  stringToMoneyFormat(price, isCash === 'Y' ? '원' : 'P')} */}
                {price || typeof price === 'number'
                  ? price > 0
                    ? stringToMoneyFormat(price, isCash === 'Y' ? '원' : 'P')
                    : '무료'
                  : ''}
              </p>
            </li>
            <li style={{ padding: '0 3px' }}>
              <p style={{ ...styles.content }}>{teacher && '|'}</p>
            </li>
            <li>
              <p style={{ ...styles.content }}>{teacher}</p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

LectureCard.defaultProps = {
  thumbnail: undefined,
  event: 'none',
  category: '디지털미래트랜드',
  title: '디지털튜터 2급 자격증 과정',
  start: undefined,
  end: undefined,
  teacher: '김미경',
  productCheck: false,
  isCash: 'Y',
};

export default LectureCard;
