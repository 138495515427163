import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Title } from 'components';
import { Input } from 'antd';
import './TeacherBillLayout.style.css';
import { Content, Row, Col, Button } from 'components';
import { decrypt } from 'utils/Crypto';
import { EmailApi, CalculateApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import validateCheck from 'utils/Validation';
import { calculateFirstAndLastDay, stringToMoneyFormat } from 'utils';

const TeacherBillLayout = () => {
  /* ===== ROUTE ===== */
  const [searchParams] = useSearchParams({
    onSuccess: () => {
      window.opener.postMessage('refresh', '*');
    },
  });

  /* ===== MUTATE ===== */
  const updateCalculateApi = CalculateApi.UpdateCalculate();

  /* ===== STATE ===== */
  const [decodeValue, setDecodeValue] = useState({});
  const [teacherBillData, setTeacherBillData] = useState({
    program: '브레인힐 프로그램 이용료',
    name: null,
    date: null,
    calculation: null,
    price: null,
    year: null,
    month: null,
    day: null,
  });

  /* ===== FUNCTION ===== */
  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...teacherBillData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setTeacherBillData(d);

    if (callback) callback(v);
  };

  /**
   * 청구서 메일 전송
   * --
   */
  const handleSendMail = async () => {
    if (!validateCheck('number', teacherBillData?.price))
      return MessageAlert.warning('청구금액은 숫자로만 입력해주세요');
    const confirm = window.confirm('청구서 메일을 전송하시겠습니까?');
    const today = new Date();
    if (confirm && decodeValue.length > 0) {
      try {
        const res = await EmailApi.Sendmail({
          toEmail: decodeValue[0].user_email,
          type: 'teacher_bill',
          template_value: {
            user_id: decodeValue[0]?.user_id,
            ...teacherBillData,
          },
        });
        if (res.status === 200) {
          decodeValue?.forEach((item) => {
            item?.calculate?.forEach((subitem) => {
              updateCalculateApi.mutate({
                ...subitem,
                teacher_bill: { ...teacherBillData, send_date: today },
                teacher_bill_count: subitem?.teacher_bill_count + 1,
              });
            });
          });
        }
        MessageAlert.success('메일을 성공적으로 전송하였습니다');
      } catch (err) {
        console.log('이메일 전송 실패', err);
        MessageAlert.error('이메일을 전송하는데 실패하였습니다.');
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다');
    }
  };

  /* ===== HOOKS ===== */
  /**
   * 데이터 정제
   */
  useEffect(() => {
    const data = searchParams.get('data');
    const decodeData = JSON.parse(decrypt(data));
    if (decodeData) {
      const today = new Date();
      const y = today.getFullYear();
      const m = today.getMonth() + 1;
      const d = today.getDate();
      const decodeDataYear = decodeData ? decodeData[0].calculate[0].year : y;
      const decodeDataMonth = decodeData ? decodeData[0].calculate[0].month : m;
      // 교육기간
      const dateString = calculateFirstAndLastDay(
        decodeDataYear,
        decodeDataMonth
      );
      // 산출식
      let calculation = '';
      let total = 0;
      const agency_name =
        decodeData.length > 1
          ? `${decodeData[0].agency_name} 외 ${decodeData.length - 1}건`
          : `${decodeData[0].agency_name}`;
      decodeData?.forEach((item) => {
        calculation += `- ${item.agency_name} -\n`;
        item?.calculate?.forEach((subItem) => {
          calculation += `${
            subItem?.category_name
          } 프로그램: ${stringToMoneyFormat(subItem?.time_price, '원')} * ${
            subItem?.work_hour
          }H = ${(
            subItem?.time_price * subItem?.work_hour
          ).toLocaleString()}원\n`;
          total += subItem?.time_price * subItem?.work_hour;
        });
      });
      setTeacherBillData({
        program: '브레인힐 프로그램 이용료',
        name: agency_name,
        date: `${dateString?.firstDay} ~ ${dateString?.lastDay}`,
        calculation: calculation.slice(0, -1),
        price: total,
        year: y,
        month: m,
        day: d,
      });
      setDecodeValue(decodeData);
    }
    // eslint-disable-next-line
  }, []);

  /**
   * 팝업창 닫힘 메시지 전송
   * --
   */
  useEffect(() => {
    const handleClose = () => {
      window.opener.postMessage('closed', '*');
    };

    window.onbeforeunload = handleClose;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'}>
      <Row>
        <Col x={24}>
          <Content maxWidth={800} padding={0}>
            <Title size={3} align={'center'}>
              브레인힐 맞춤형 프로그램 정산서
            </Title>
            <div className="bill_container">
              <table className="bill-table">
                <tbody>
                  <tr>
                    <th>강좌명</th>
                    <td>
                      <Input
                        size="large"
                        placeholder="강좌명을 입력해주세요"
                        value={teacherBillData?.program}
                        onChange={(e) =>
                          handleChange('program', e.target.value)
                        }
                      />
                    </td>
                    <th>기관명</th>
                    <td>
                      <Input
                        size="large"
                        placeholder="기관명을 입력해주세요"
                        value={teacherBillData?.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>교육기간</th>
                    <td colSpan={3}>
                      <Input
                        size="large"
                        placeholder="교육기간을 입력해주세요"
                        value={teacherBillData?.date}
                        onChange={(e) => handleChange('date', e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>산출식</th>
                    <td colSpan={3}>
                      <Input.TextArea
                        size="large"
                        placeholder="산출식을 입력해주세요"
                        value={teacherBillData?.calculation}
                        onChange={(e) =>
                          handleChange('calculation', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>정산금액</th>
                    <td colSpan={3}>
                      <Input
                        prefix={'₩'}
                        suffix={'원'}
                        size="large"
                        placeholder="정산금액을 숫자 입력해주세요"
                        value={teacherBillData?.price}
                        onChange={(e) => handleChange('price', e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Title align={'center'} style={{ margin: 20 }}>
                {teacherBillData?.year}. {teacherBillData?.month}.{' '}
                {teacherBillData?.day}
              </Title>
              <div className="bill-info">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '20%',
                    margin: '0 auto',
                  }}
                >
                  <Title align={'center'}>대표 : </Title>
                  <p>정상원 ( 인 )</p>
                </div>
              </div>
            </div>
            <Button
              style={{ width: '60%', margin: '30px auto', height: 54 }}
              onClick={() => handleSendMail()}
            >
              메일 전송
            </Button>
          </Content>
        </Col>
      </Row>
    </Content>
  );
};

export default TeacherBillLayout;
