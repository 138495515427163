import React from 'react';
import { Content, Row, Col, Title } from 'components';
import './Privacy.style.css';

const Privacy = ({ screenSize }) => {
  return (
    <Content maxWidth={'100%'}>
      <Content
        maxWidth={1200}
        padding={screenSize >= 768 ? '120px 0' : '60px 0'}
      >
        <Row>
          <Col x={24}>
            <div id="privacy_layout">
              <Title size={screenSize >= 768 ? 1 : 3} align={'center'}>
                브레인힐 시니어콘텐츠랩
                <br
                  style={{ display: screenSize >= 768 ? 'none' : 'block' }}
                />{' '}
                개인정보처리방침
              </Title>
              <h4>
                브레인힐 시니어콘텐츠랩(이하 ‘회사’라 한다)는 개인정보 보호법
                제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을
                신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
                개인정보 처리지침을 수립·공개합니다.
              </h4>
              <ul>
                <li>
                  <h5>제1조(개인정보의 처리목적)</h5>
                  <p>
                    회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
                    있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며,
                    이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라
                    별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                  </p>

                  <h6>① 홈페이지 회원 가입 및 관리</h6>
                  <dl>
                    <dt>
                      <p>
                        • 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인
                        식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에
                        따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의
                        개인정보 처리 시 법정대리인의 동의여부 확인, 각종
                        고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.
                      </p>
                    </dt>
                  </dl>

                  <h6>② 재화 또는 서비스 제공</h6>
                  <dl>
                    <dt>
                      <p>
                        • 물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠
                        제공, 맞춤서비스 제공, 본인인증, 연령인증,
                        요금결제·정산, 채권추심 등을 목적으로 개인정보를
                        처리합니다.
                      </p>
                    </dt>
                  </dl>

                  <h6>③ 고충처리</h6>
                  <dl>
                    <dt>
                      <p>
                        • 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한
                        연락·통지, 처리결과 통보 등의 목적으로 개인정보를
                        처리합니다.
                      </p>
                    </dt>
                  </dl>
                </li>
                <li>
                  <h5>제2조(개인정보의 처리 및 보유기간)</h5>
                  <p>
                    ① 회사는 법령에 따른 개인정보 보유·이용기간 또는
                    정보주체로부터 개인정보를 수집 시에 동의받은 개인정보
                    보유·이용기간 내에서 개인정보를 처리·보유합니다.
                    <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                  </p>

                  <h6>
                    홈페이지 회원 가입 및 관리 :{' '}
                    <span>홈페이지 탈퇴 후 5일까지</span>
                  </h6>
                  <dl>
                    <dl>
                      <dt>
                        • 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료
                        시까지
                      </dt>
                      <dt>
                        • 관계 법령 위반에 따른 수사·조사 등이 진행 중인
                        경우에는 해당 수사·조사 종료 시까지
                      </dt>
                      <dt>
                        • 홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당
                        채권·채무관계 정산 시까지
                      </dt>
                    </dl>
                  </dl>

                  <h6>
                    재화 또는 서비스 제공 :{' '}
                    <span>
                      재화·서비스 공급 완료 및 요금 결제·정산 완료 시까지
                    </span>
                  </h6>
                  <dl>
                    <dt>
                      • 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료
                      시까지
                      <dl>
                        <dt>
                          • 「전자상거래 등에서의 소비자 보호에 관한 법률」에
                          따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록
                          <dl>
                            <dt>• 표시·광고에 관한 기록: 6개월</dt>
                            <dt>
                              • 계약 또는 청약철회, 대금결제, 재화 등의
                              공급기록: 5년
                            </dt>
                            <dt>
                              • 소비자 불만 또는 분쟁처리에 관한 기록: 3년
                            </dt>
                          </dl>
                        </dt>

                        <dt>
                          • 「통신비밀보호법」제41조에 따른 통신사실확인자료
                          보관
                          <dl>
                            <dt>
                              • 가입자 전기통신일시, 개시·종료시간, 상대방
                              가입자번호, 사용도수, 발신기지국 위치추적자료: 1년
                            </dt>
                            <dt>
                              • 컴퓨터통신, 인터넷 로그기록자료, 접속지
                              추적자료: 3개월
                            </dt>
                          </dl>
                        </dt>
                      </dl>
                    </dt>
                  </dl>
                </li>
                <li>
                  <h5>제3조(정보주체의 권리·의무 및 행사방법)</h5>
                  <p>
                    ① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호
                    관련 권리를 행사할 수 있습니다.
                    <dl>
                      <dt>1. 개인정보 열람 요구</dt>
                      <dt>2. 오류 등이 있을 경우 정정 요구</dt>
                      <dt>3. 삭제 요구</dt>
                      <dt>4. 처리정지 요구</dt>
                    </dl>
                  </p>
                  <p>
                    ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편,
                    모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해
                    지체 없이 조치하겠습니다.
                  </p>
                  <p>
                    ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를
                    요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해
                    개인정보를 이용하거나 제공하지 않습니다.
                  </p>
                  <p>
                    ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을
                    받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우
                    개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을
                    제출하셔야 합니다.
                  </p>
                  <p>
                    ⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가
                    처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을
                    침해하여서는 아니됩니다.
                  </p>
                  <p>
                    ⑥ 만 14세 미만 아동의 법정대리인의 법령 상의 권리를
                    보장합니다. (아동의 개인정보에 대한 열람, 정정, 삭제,
                    개인정보처리정지요구권)
                  </p>
                </li>
                <li>
                  <h5>제4조(처리하는 개인정보 항목)</h5>
                  <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>

                  <h6>① 홈페이지 회원 가입 및 관리</h6>
                  <dl>
                    <dt>
                      • 필수항목: 성명, 아이디, 비밀번호, 이메일, 생년월일, 성별
                    </dt>
                    <dt>• 선택항목: 주소, 전화번호, 아이핀번호</dt>
                  </dl>

                  <h6>② 재화 또는 서비스 제공</h6>
                  <dl>
                    <dt>
                      • 필수항목: 성명, 생년월일, 아이디, 비밀번호, 주소,
                      전화번호, 이메일주소, 아이핀번호, 신용카드번호,
                      은행계좌정보 등 결제정보
                    </dt>
                    <dt>• 선택항목: 관심분야, 과거 구매내역</dt>
                  </dl>

                  <h6>
                    인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로
                    생성되어 수집될 수 있습니다.
                  </h6>
                  <dl>
                    <dt>
                      • IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량
                      이용기록 등
                    </dt>
                  </dl>
                </li>
                <li>
                  <h5>제5조(개인정보의 파기)</h5>
                  <p>
                    ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등
                    개인정보가 불필요하게 되었을 때에는 지체 없이 해당
                    개인정보를 파기합니다.
                    <br />
                    ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나
                    처리목적이 달성되었음에도 불구하고 다른 법령에 따라
                    개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를
                    별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여
                    보존합니다.
                    <br />③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                  </p>

                  <h6>파기절차</h6>
                  <dl>
                    <dt>
                      • 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
                      개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                    </dt>
                  </dl>

                  <h6>파기방법</h6>
                  <dl>
                    <dt>
                      • 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을
                      재생할 수 없도록 로우레벨포맷(Low Level Format) 등의
                      방법을 이용하여 파기하며, 종이 문서에 기록·저장된
                      개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
                    </dt>
                  </dl>
                </li>
                <li>
                  <h5>제7조(개인정보 자동수집장치 이용여부)</h5>
                  <p>
                    회사는 쿠키(Cookie)를 설치, 운영하고 있고 이용자는 이를
                    거부할 수 있습니다.
                  </p>

                  <h6>쿠키란 무엇인가?</h6>
                  <dl>
                    <dt>
                      • 쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의
                      브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의
                      컴퓨터에 저장됩니다.
                    </dt>
                  </dl>

                  <h6>쿠키를 왜 사용하나?</h6>
                  <dl>
                    <dt>
                      • 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여
                      이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을
                      위해 서비스 개선에 활용합니다. 이를 통해 이용자는 보다
                      손쉽게 서비스를 이용할 수 있게 됩니다.
                    </dt>
                  </dl>

                  <h6>쿠키를 수집하지 못하게 거부하고 싶다면?</h6>
                  <dl>
                    <dt>
                      • 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.
                      따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든
                      쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,
                      모든 쿠키의 저장을 거부할 수도 있습니다. 다만 쿠키 설치를
                      거부할 경우 웹 사용이 불편해지며 로그인이 필요한 일부
                      서비스 이용에 어려움이 있을 수 있습니다.
                    </dt>
                  </dl>
                </li>
                <li>
                  <h5>제8조(개인정보의 안전성 확보조치)</h5>
                  <p>
                    회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를
                    취하고 있습니다.
                  </p>

                  <dl>
                    <dt>
                      • 관리적 조치:{' '}
                      <span>내부관리계획 수립·시행, 정기적 직원 교육 등</span>
                    </dt>
                    <dt>
                      • 기술적 조치:{' '}
                      <span>
                        개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
                        설치, 고유식별정보 등의 암호화, 보안프로그램 설치
                      </span>
                    </dt>
                    <dt>
                      • 물리적 조치:{' '}
                      <span>전산실, 자료보관실 등의 접근통제</span>
                    </dt>
                  </dl>
                </li>
                <li>
                  <h5>제9조(개인정보 보호책임자)</h5>
                  <p>
                    ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
                    개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을
                    위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다
                  </p>

                  <h6>▶ 개인정보 보호책임자</h6>
                  <dl>
                    <dt>• 성명: 정상원</dt>
                    <dt>• 직책: 대표이사</dt>
                    <dt>• 연락처: 070-7701-7077 </dt>
                    <dt>• 이메일 : brainheal@naver.com</dt>
                  </dl>

                  <h6>▶ 개인정보 보호 담당부서</h6>
                  <dl>
                    <dt>• 부서명: 시스템운영팀</dt>
                    <dt>• 담당자: 김요셉</dt>
                    <dt>• 연락처: 070-7701-7077 </dt>
                    <dt>• 이메일: ys4879@naver.com</dt>
                  </dl>

                  <p>
                    ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서
                    발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에
                    관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수
                    있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및
                    처리해드릴 것입니다.
                    <br />
                    제10조(개인정보 열람청구)
                    <br />
                    정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람
                    청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의
                    개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
                  </p>

                  <h6>▶ 개인정보 열람청구 접수·처리 부서</h6>
                  <dl>
                    <dt>• 부서명: 시스템운영팀</dt>
                    <dt>• 담당자: 김성훈</dt>
                    <dt>• 연락처: 070-7701-7077 </dt>
                  </dl>
                </li>
                <li>
                  <h5>제11조(권익침해 구제방법)</h5>
                  <p>
                    정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제,
                    상담 등을 문의하실 수 있습니다.
                    <br />
                    아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인
                    개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다
                    자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
                  </p>

                  <h6>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</h6>
                  <dl>
                    <dt>• 소관업무: 개인정보 침해사실 신고, 상담 신청</dt>
                    <dt>
                      • 홈페이지:{' '}
                      <a href="http://privacy.kisa.or.kr">privacy.kisa.or.kr</a>
                    </dt>
                    <dt>• 전화: (국번없이) 118</dt>
                    <dt>
                      • 주소: (138-950) 서울시 송파구 중대로 135
                      한국인터넷진흥원 개인정보침해신고센터
                    </dt>
                  </dl>

                  <h6>▶ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</h6>
                  <dl>
                    <dt>
                      • 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적
                      해결)
                    </dt>
                    <dt>
                      • 홈페이지:{' '}
                      <a href="http://privacy.kisa.or.kr">privacy.kisa.or.kr</a>
                    </dt>
                    <dt>• 전화: (국번없이) 118</dt>
                    <dt>
                      • 주소: (138-950) 서울시 송파구 중대로 135
                      한국인터넷진흥원 개인정보침해신고센터
                    </dt>
                  </dl>

                  <h6>▶ 대검찰청 사이버범죄수사단</h6>
                  <dl>
                    <dt>• 전화: 02-3480-3573</dt>
                    <dt>
                      • 홈페이지:{' '}
                      <a
                        href="http://www.spo.go.kr
"
                      >
                        www.spo.go.kr
                      </a>
                    </dt>
                  </dl>

                  <h6>▶ 경찰청 사이버테러대응센터</h6>
                  <dl>
                    <dt>• 전화: 1566-0112</dt>
                    <dt>
                      • 홈페이지:{' '}
                      <a
                        href="www.netan.go.kr

"
                      >
                        www.netan.go.kr
                      </a>
                    </dt>
                  </dl>
                </li>
                <li>
                  <h5>제12조(개인정보 처리방침 변경)</h5>
                  <p>① 이 개인정보 처리방침은 2023. 05. 01. 부터 적용됩니다.</p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default Privacy;
