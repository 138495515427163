/**
 * 회원가입 - 이용약관 페이지
 * --
 */
import React from 'react';
import { Content, Row, Col, Button } from 'components';
import { useNavigate, useLocation } from 'react-router-dom';
import { Checkbox } from 'antd';
import { useState } from 'react';
import MessageAlert from 'utils/MessageAlert';

const turm = [
  `
제 1 장 [총칙] 제 1조 목적 본 이용약관(이하’약관’)은 브레인힐(이하 ‘회사’)에서 운영하는 브레인힐 어플리케이션과 웹사이트 (brainheal-lms.com)을 이용함에 있어 회사와 회원간의 권리 의무 및 책임사항을 규정함을 목적으로 합니다. 

제 2조 용어의 정의 본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 
- 개인회원 : 회사가 정한 절차에 따라 본 약관에 동의하고 서비스 이용에 필요한 회원가입 신청서를 제출하고, 회사가 승낙함으로써, 서비스 이용계약이 체결 되어 서비스를 제공 받는 사람을 말합니다. 
- 회원ID : 회원이 브레인힐 서비스를 이용하기 위하여 회원이 정한 이메일 기반의 고유 식별 기호입니다. 
- 비밀번호 : 회원의 비밀 보호를 위해 회원이 선정하는 문자 또는 숫자입니다. 제 3조 약관의 효력과 수정 
1. 회사는 불가피한 경우 본 약관의 내용을 수정 할 수 있습니다. 
2. 수정 시에는 수정사항을 공지사항에 게재 또는 기타의 방법을 통해 기간을 정하여 공시하며, 공시즉시 효력을 지닙니다. 
3. 가입한 모든 회원은 최근 공지된 약관을 적용 받게 되므로, 회사의 공지사항 및 본 약관의 변경여부를 반드시 확인하셔야 합니다. 또한 서비스를 계속 이용하는 것은 약관의 내용을 수락하고 동의하는 것으로 간주합니다. 
    
제 4조 약관 외 준칙 본 약관에 명시되지 않은 사항은 전기통신사업법, 전자서명법, 정보통신망이용촉진에등에 관한 법률, 전자거래소비자보호지침등 기타 관계법령 규정에 준합니다. 

제 5조 회원가입 
1. 회원이 되고자 하는 자는 회사자 제공한 가입양식에 따라 회원정보를 입력 후 “약관에 동의 후” 입력 완료를 거쳐, 회원 가입을 신청합니다. 
2. 회원이 입력한 정보는 전부 사실로 간주합니다. 입력한 내용이 사실과 다를 경우(차명, 비실명, 허위정보 등)와 타인의 정보를 도용한 사실이 명백하게 확인 되는 경우, 회사는 회원에게 회원의 권한을 삭제하며 서비스의 전면적인 이용을 거부 할 수 있고, 이로 인해 발생하는 모든 불이익은 당사가 책임지지 않습니다. 또한 의심되는 경우 확인을 위하여 위하여 재증명을 요구할 수 있습니다. 
3. 회사는 회원의 상태와 이용기간, 이용횟수, 서비스 접근 방식 등에 따라 합리적인 기준을 통해 서비스 범위에 차등을 둘 수 있으며, 회원은 상기 내용에 따라 서비스 이용에 제약을 받을 수 있습니다. 
4. 회사는 위 1항목과 같은 방법으로 회원 가입을 신청한 회원이 아래의 각 호에 해당하는 조건을 충족 하지 못할 경우 회원가입 승낙을 유보 또는 거부 할 수 있습니다. 
A. 등록내용 허위게재 (타인명의 휴대폰 도용등) 사실이 있는 경우 
B. 가입 신청 회원이 브레인힐 약관 및 서비스 이용에 관한 관계법령을 위반하여 회원자격을 상실 된 자. 
C. 사회적 질서 및 미풍양속에 문란이 되는 행위자. 
D. 법률혼 및 사실혼 상태이거나 이에 준하는 자 
E. 영리 추구를 목적으로 브레인힐 서비스에 가입 하고자 하는 자 
F. 금치산자 및 그에 해당하는 자 H. 기타 회사의 여건상 이용승낙이 곤란하거나 가입결격 사유에 해당한 자. 
G. 회원가입 계약 성립 시점은, 회사의 가입 승낙이 가입 신청자에게 도달한 시점을 기준으로 합니다. 개정 "주민등록법"에 의해 타인의 주민등록번호를 부정 사용하는 자는 3년 이하의 징역 또는 1천만원. 이하의 벌금이 부과될 수 있습니다. 관련법률: 주민등록법 제37조(벌칙) 제9호(2009년04월01일 시행) 
          
제 6조 회원 승인 심사 
1. 브레인힐 서비스는 프로필정보를 등록를 완료한 회원들을 중심으로 서비스를 제공합니다. 회원 승인이란, 양질의 서비스제공을 위하여, 회원 가입을 완료하고 정상적인 서비스 이용을 위해 기본적으로 프로필정보(ex: 프로필사진, 프로필정보)을 심사하는 과정을 의미합니다. 
2. 브레인힐에서는 회원들의 편의를 위해 후승인 심사제도를 운영하며, 이 과정에서 승인 심사에 부적격하다고 판단되는 경우, 회원에게 프로필 수정요청을 할 수 있으며, 회원은 수정 후 브레인힐에서 제공하는 서비스를 이용하실 수 있습니다. 또한 브레인힐에서는 회원의 프로필 수정에 대한 모니터링이 이루어지며 프로필 관리 목적에 필요한 경우 심사 담당자 및 운영자에 의해 프로필의 작성내용이 수정 및 삭제될 수 있습니다. 그리고 회사에서 안정적인 서비스 제공을 위해 필요하다고 생각하는 경우에는 승인을 임의로 보류 및 탈퇴를 처리할 수 있습니다. 
    
제 7 조 서비스의 종류 및 범위 
1. 회원은 무료로 브레인힐 서비스에 가입하여 회원이 될 수 있습니다. 
2. 가입 후 회원은 결혼 또는 재혼을 원하는 회원의 프로필을 열람하실 수 있습니다. 
3. 유료아이템 
A. 브레인힐에 등록된 결혼 또는 재혼을 원하는 이성의 프로필을 확인 후 연락하고자 할 때에는 이용권을 구매하셔야 합니다.
B. 유료아이템의 구매에 있어서는 회원 가입과는 별도의 추가적인 결제과정(휴대폰,계좌이체,신용카드,구글 인앱결제 등)을 거치며 회원의 동의 하에 결제하도록 합니다. 
C. 구매한 이용권은 ‘이용권관리’에서 확인가능하며, 이용중에도 추가로 구매하실 수 있습니다. 아이템의 종류 및 가격은 회사의 운영 방침에 따라 추가, 수정, 보안, 중지,등의 조치를 취할 수 있습니다. 
D. 회사는 아이템의 사용기한 중 회사의 서비스 내용의 변경 및 아이템 정책에 따라 기존 아이템의 기능을 변경하거나, 사용이 불가능하도록 변경할 수 있습니다. 이 때 유료로 구매한 회원에 한해 기존 아이템의 잔여 기간을 산정한 뒤 등가의 신규 아이템이나 기존 아이템으로 보상합니다. 
4. 회사에서는 양질의 서비스 제공을 위해 회원들의 프로필 수정, 보안, 중지 등을 요청할 수 있으며 당사의 내부 규정에 따라 일부 서비스 이용을 제한 할 수 있습니다. 
5. 브레인힐에서 제공하는 서비스의 범위는 브레인힐의 웹사이트 및 모바일 어플리케이션 상에서의 회원이 입력한 프로필 상세정보를 제공하는 것을 끝으로 합니다. 
6. 브레인힐에서 회원들간 행위의 책임은 회원들에게 귀속됩니다. 따라서 실제 만남이나 연락은 신중히 결정하시고 안전한 장소와 시각에 만나시기 바랍니다. 회사에서는 신고된 회원의 행위가 추후 다른 회원에게 추가적인 피해를 입힐 수 있다고 판단되는 경우, 문제 회원을 홀딩 및 탈퇴 혹은 아이디 영구삭제, 등의 후속 조치를 취할 것입니다. 
    
제 8조 권리의 귀속 및 저작물의 이용 
1. 회원이 서비스 내에 게시한 게시물 등(이하 "게시물 등"이라 합니다)의 저작권은 해당 게시물의 저작자에게 귀속됩니다. 
2. 게시물 등은 검색결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 탈퇴 후에도 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, 회원은 서비스 이용 중 고객센터 또는 각 서비스 내 관리기능을 통해 해당 게시물 등에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다. 서비스 탈퇴 후에 이런 조치가 필요할 경우 고객센터로 요청해야 하며 회사는 이에 따라 즉시 조치를 취합니다. 
    
제 9조 결제, 결제취소 및 환불 
1. 회원은 회사가 제공하는 다양한 결제수단을 통해 유료서비스를 이용할 수 있으며, 결제가 비정상적으로 처리되어 정상처리를 요청할 경우 회사는 회원의 결제금액을 정상처리 할 의무를 가집니다. 
2. 회사는 부정한 방법 또는 회사가 금지한 방법을 통해 충전 및 결제된 금액에 대해서는 이를 취소하거나 환불을 제한할 수 있습니다. 
3. 회원은 다음 각 호의 사유가 있으면 아래의 4항의 규정에 따라서 회사로부터 결제 취소, 환불 및 보상받을 수 있습니다. 
A. 결제를 통해 사용할 수 있는 서비스가 전무하며 그에 대한 책임이 전적으로 회사에 있을 경우 (단, 시스템 정기 점검, 등 불가피한 경우는 제외) 
B. 회사 또는 결제대행사의 시스템의 오류로 인하여 결제기록이 중복으로 발생한 경우 
C. 서비스 중단 등의 회사의 잘못으로 인해 회사가 회원에게 해지를 통보하는 경우 
D. 기타 소비자 보호를 위하여 당사에서 따로 정하는 경우. 
4. 결제취소 또는 환불 절차는 다음 각 호와 같습니다. 
A. 환불을 원하는 회원은 회원 본인임을 인증하는 절차를 고객센터를 통해 접수하셔야 하며 이와 동시에 환불을 신청하여야 합니다. 
B. 회사는 환불 신청이 정당함을 심사한 후 정당한 이유가 있음으로 판명된 회원에게 환불합니다. 
C. 회사는 회원에게 환불되어야 할 금액을 회원에게 통보하고 난 후 회원이 결제한 방법으로 해당 환불 및 결제 취소 처리합니다. 
D. 환불금액은 결제서비스 제공자(구글 플레이스토어, 애플 앱스토어, 이동통신사 스토어)가 정한 결제수수료 30% 를 제외한 나머지 금액을 환불합니다. 
5. 회원이 이용약관 및 서비스 이용정책을 위반한 행위로 인한 사용정지 또는 강제탈퇴 되는 경우 환불 및 보상하지 않습니다. 
6. 회사는 회원이 유료 아이템을 이용하는 중이거나 이용한 후 해당 이용료를 환불하지 않습니다. 
7. 회사는 아이템 구매 창에 명시한 바와 마찬가지로, 회원이 아이템 구매 후 일방적인 회원의 변심으로 인한 환불은 불가능합니다. 
8. 회원이 자진탈퇴로 인해 계약이 해지되는 경우, 회원이 보유한 아이템은 자동으로 소멸되어 복구 및 환불이 불가능합니다. 
9. 신원 인증과정에서 회원의 등록정보가 허위 정보로 판명되거나 가입 조건에 부합되지 않는 점이 판명될 경우 회원 본인의 귀책사유로 인한 것은 강제 탈퇴 및 홀딩 처리가 가능하며 환불 및 보상이 불가능합니다. 
10. 회사와 이용자간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다. 
11. 회사는 아래 각 항목에 해당하는 경우 환불을 하지 않습니다. 
- 다른 이용자로부터 선물 받은 아이템의 경우 
- 이벤트 당첨 또는 참여로 적립 받은 아이템의 경우 
- 인터넷 서비스 내에서 활동 등으로 회사로부터 적립 받은 경우 
- 회원이 이용약관 및 정책을 위반하여 이용정지 및 강제탈퇴 되는 경우 
- 기타 이용자가 직접 결제하지 않은 경우 
      
제 10조 회사의 의무 
1. 브레인힐는 지속적이고, 안정적인 서비스 제공을 위해 최선을 다합니다. 
2. 브레인힐에서는 브레인힐가 정한 약관 및 운영 정책을 위반하는 불량 회원들을 강제 탈퇴 혹은 관계 법령에 따라 법적 절차를 진행 할 의무를 가집니다. 
3. 회사는 정보통신망이용촉진 등에 관한 법률 등 관계법령에 따라 회원이 회원 가입 신청 시 기록한 개인정보, 이후에 추가로 기록한 개인정보 및 서비스 이용 중 생성되는 개인정보를 보호하여야 합니다. 
4. 회사의 임직원은 서비스 제공과 관련, 회원의 개인정보를 브레인힐 회원 외에는 제3자에게 누설 또는 배포해서는 안되며, 이를 어길 시 직위해제의 사유가 될 수 있습니다. 
5. 브레인힐는 정기적으로 회원에게 당사의 서비스 내용 및 이용 안내에 대한 정기적인 서신을 보냅니다. (공지, 문자, e-mail 등) 
6. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약관련 절차 및 내용 등에 있어 이용고객에게 편의를 제공하도록 최선을 다합니다. 
    
제 11조 회원의 의무 
1. 회원 가입 시 작성되는 프로필(개인정보)은 사실이어야 합니다. 또한 일정기간이 경과한 뒤 변경되는 개인 정보는 지속적으로 본인이 정보를 갱신하여야 하며 중매회원 및 가족회원의 정보는 정보를 등록한 중매인과 가족 등이 갱신하여야 합니다 이를 성실히 이행하지 않은 상황에서의 불이익은 개인의 책임 입니다. 
2. 개인회원, 중매회원 및 가족회원 등이 입력 등록한 개인정보는 진실하여야 하며 만일 위 정보가 허위로 작성한 사실이 적발되어 다른 회원에게 정신적, 물질적 손해가 발생할 경우 위 문제의 책임은 정보를 등록한 회원에게 귀속됩니다. 
3. 회원의 거짓된 정보에 관련하여 타 회원의 피해가 발생할 경우, 민 형사상의 책임은 개인 정보를 허위로 게재한 회원에게 귀속 됩니다. 
4. 브레인힐에서는 회원 정보에 대한 보증을 하지 않습니다. 회원간의 만남에 발생 할 문제의 책임은 전적으로 회원 본인에게 주어짐으로, 신중히 검토한 후 서비스를 이용하시길 바랍니다. 
5. 회원은 항상 공지 내용을 숙지 하여야 합니다. 위 의무를 성실하게 이행하지 않은 상황에서의 문제는 회원의 책임에 귀속 됩니다. 
6. 회원은 아래 각 호에 해당하는 행위를 해서는 안됩니다. 
A 브레인힐 어플리케이션 및 사이트의 회원정보를 부정하게 취득하는 행위 
B 브레인힐 어플리케이션 및 사이트를 해킹 또는 유사 프로그램을 이용하여 정상적인 운영을 어렵게 하는 행위 (예: 해킹 또는 바이러스 프로그램) 
C 상대방의 ID와 비밀번호를 도용하는 행위 
D 개인 정보를 허위 또는 타인의 것으로 등록하는 행위 
E 상대방에 대한 비방의 글 또는 인격모독에 해당하는 글 
F 공공질서, 미풍양속에 저해하는 저작물을 등록 또는 유통시킨 자. 
G 사이트 내, 불법적으로 물건을 판매하거나 상행위를 하는 자 
H 음란물 및 동영상을 유포하는 자. 
I 프로필 및 개인 정보(키워드, 사진, 이름, 연락처, 등)을 외부로 유출하는 행위자. 
J 기타 브레인힐 이용정책 내용을 위반하는 자 위 6항 각 호에 해당 사항에 명시한 내용을 위반한 회원은 강제 탈퇴 처리 될 수 있으며, 민 형사상의 책임을 지게 됩니다. 
7. 브레인힐 회원의 권한은 제3자에게 양도 및 판매가 불가능합니다. 
8. 회원 본인이 프로필 내 또는 PR메시지에서 일체의 개인의 연락처(SNS계정, E-mail, 블로그, 휴대폰 번호 등)를 게재 할 경우 강제로 탈퇴 처리 할 수 있으며 이로 인한 피해는 책임지지 않습니다. 
9. 본 약관을 위반 하거나, 기타 대한민국 관계 법령에 위반하는 행위에 대해서는 경고 없이 회원의 권한이 박탈되며, 이로 인한 어떠한 보상도 회사는 회원에게 부여하지 않습니다. 
    
제 12조 계약의 종료 브레인힐는 다음과 같은 조건을 계약의 종료 조건으로 인정합니다. 
1. 회원의 자의에 의한 탈퇴 신청 (홈페이지 탈퇴 및 자신의 의사를 브레인힐에 알린 경우) 
2. 회원의 의무를 성실하게 이행하지 않거나, 약관에서 정한 회원의 의무 및 브레인힐 운영정책에 위반하는 행위를 한 회원은 사전 동의 없이 강제 탈퇴 처리할 수 있습니다. 
3. 기타 회원의 여건상 지속적인 계약을 이행하지 못한다고 판단될 경우 임의탈퇴 처리할 수 있습니다. (예: 사망 또는 행방불명, 2개월 이상 활동을 하지 않는 휴면계정 회원 등) 
    
제 13조 면책조항 
1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 
2. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 운영을 하지 못해 발생하는 경우 책임이 면제됩니다. 
3. 회사는 회원의 귀책사유로 인한 서비스 이용 장애에 대한 책임을 면제합니다. 
4. 회사는, 회원이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나, 서비스 자료에 대한 취소 선택 또는 이용으로 인해 발생하는 손해 등에 대해서는 책임이 면제됩니다. 
5. 회사는 회원이 제공한 회원의 개인 정보의 신뢰도 및 정확도에 대한 책임이 면제됩니다. 
6. 회사의 게시물에 회원이 게재한 글의 저작권은 회원 본인에게 있으며, 이 정보의 진실성 및 저작권에 위배되어 민 형사 상의 문제가 발생 시 책임은 회원 본인에게 있습니다. 
7. 회사는 회원의 서비스 이용에 필요한 서버의 보수로 인한 교체, 일시 정지, 리뉴얼 작업이 발생할 경우 책임이 면제 됩니다. 
8. 회원의 개인 정보에 부주의에 의하여 내부적인 공지 사항이 전달이 안된 경우에 그 책임은 회원 본인에게 있습니다. 
9. 회사는 회원 신원을 보증하지 않습니다. 또한 브레인힐 회원이 다른 브레인힐 회원에게 경제적, 정신적, 물질적 피해를 가해도 그 책임은 회원 본인에게 있습니다. 
10. 회사의 사이트에 게재된 제3의 사이트 주소에 관하여 회사는 어떠한 통제권도 없으며 이로 인하여 발생 되어지는 손해는 회원 본인에게 있습니다. 
11. 회사는 회원이 본 약관 규정을 위배하여 발생한 손실에 대해서는 책임을 지지 않습니다. 
12. 회원이 공개를 허락한 자료에 대해여 발생되는 모든 문제에 대해서는 회사의 책임이 면제 됩니다. 
13. 브레인힐의 동의 없이 제3자에게 ID 또는 회원의 권한을 판매 및 양도할 경우, 회원 자격 박탈 및 민, 형사상의 고발을 취할 수 있으며 이로 인한 모든 피해는 불법으로 회원권한을 판매/양도한 회원에게 귀속됩니다. 
    
부칙 
1. 이 약관은 2019년 4월 23일부터 적용됩니다.
`,
  `
브레인힐 시니어콘텐츠랩 개인정보처리방침
브레인힐 시니어콘텐츠랩(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립·공개합니다.
제1조(개인정보의 처리목적)
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

① 홈페이지 회원 가입 및 관리
• 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.

② 재화 또는 서비스 제공
• 물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.

③ 고충처리
• 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.

제2조(개인정보의 처리 및 보유기간)
① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.

홈페이지 회원 가입 및 관리 : 홈페이지 탈퇴 후 5일까지
• 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
• 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지
• 홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지
재화 또는 서비스 제공 : 재화·서비스 공급 완료 및 요금 결제·정산 완료 시까지
• 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지
• 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록
• 표시·광고에 관한 기록: 6개월
• 계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년
• 소비자 불만 또는 분쟁처리에 관한 기록: 3년
• 「통신비밀보호법」제41조에 따른 통신사실확인자료 보관
• 가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료: 1년
• 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료: 3개월
제3조(정보주체의 권리·의무 및 행사방법)
① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
1. 개인정보 열람 요구
2. 오류 등이 있을 경우 정정 요구
3. 삭제 요구
4. 처리정지 요구

② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.

③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.

④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.

⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.

⑥ 만 14세 미만 아동의 법정대리인의 법령 상의 권리를 보장합니다. (아동의 개인정보에 대한 열람, 정정, 삭제, 개인정보처리정지요구권)

제4조(처리하는 개인정보 항목)
회사는 다음의 개인정보 항목을 처리하고 있습니다.

① 홈페이지 회원 가입 및 관리
• 필수항목: 성명, 아이디, 비밀번호, 이메일, 생년월일, 성별
• 선택항목: 주소, 전화번호, 아이핀번호
② 재화 또는 서비스 제공
• 필수항목: 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 이메일주소, 아이핀번호, 신용카드번호, 은행계좌정보 등 결제정보
• 선택항목: 관심분야, 과거 구매내역
인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
• IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
제5조(개인정보의 파기)
① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.

파기절차
• 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
파기방법
• 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 로우레벨포맷(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
제7조(개인정보 자동수집장치 이용여부)
회사는 쿠키(Cookie)를 설치, 운영하고 있고 이용자는 이를 거부할 수 있습니다.

쿠키란 무엇인가?
• 쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장됩니다.
쿠키를 왜 사용하나?
• 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다. 이를 통해 이용자는 보다 손쉽게 서비스를 이용할 수 있게 됩니다.
쿠키를 수집하지 못하게 거부하고 싶다면?
• 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만 쿠키 설치를 거부할 경우 웹 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
제8조(개인정보의 안전성 확보조치)
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.

• 관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육 등
• 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
• 물리적 조치: 전산실, 자료보관실 등의 접근통제
제9조(개인정보 보호책임자)
① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다

▶ 개인정보 보호책임자
• 성명: 정상원
• 직책: 대표이사
• 연락처: 070-7701-7077
• 이메일 : brainheal@naver.com
▶ 개인정보 보호 담당부서
• 부서명: 시스템운영팀
• 담당자: 김요셉
• 연락처: 070-7701-7077
• 이메일: ys4879@naver.com
② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
제10조(개인정보 열람청구)
정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.

▶ 개인정보 열람청구 접수·처리 부서
• 부서명: 시스템운영팀
• 담당자: 김성훈
• 연락처: 070-7701-7077
제11조(권익침해 구제방법)
정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.

▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
• 소관업무: 개인정보 침해사실 신고, 상담 신청
• 홈페이지: privacy.kisa.or.kr
• 전화: (국번없이) 118
• 주소: (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
▶ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
• 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
• 홈페이지: privacy.kisa.or.kr
• 전화: (국번없이) 118
• 주소: (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터
▶ 대검찰청 사이버범죄수사단
• 전화: 02-3480-3573
• 홈페이지: www.spo.go.kr
▶ 경찰청 사이버테러대응센터
• 전화: 1566-0112
• 홈페이지: www.netan.go.kr
제12조(개인정보 처리방침 변경)
① 이 개인정보 처리방침은 2023. 05. 01. 부터 적용됩니다.
`,
];

function Join01(props) {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();
  const location = useLocation();

  /* ===== STATE ===== */
  const plainOptions = [
    '이용약관 (필수)',
    '개인정보 수집/이용 (필수)',
    // '기타 정보 동의 여부 (필수)',
  ];
  const defaultCheckedList = [];

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [checkAll, setCheckAll] = useState(false);
  /* ===== FUNCTION ===== */

  const onChange = (item) => {
    const list = checkedList.includes(item)
      ? checkedList.filter((e) => e !== item)
      : [...checkedList, item];

    setCheckedList(list);
    setCheckAll(list.length === plainOptions.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setCheckAll(e.target.checked);
  };
  const MoveToNext = () => {
    //TODO: 필수 항목 따라 조건 변경
    checkedList.length === plainOptions.length
      ? navigate('/join/2', { state: { from: location } }) //이전 페이지 전달 : url 접근 제어
      : MessageAlert.warning('필수 동의항목을 체크해주세요.');
  };

  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'} padding={0} backgroundColor={'#FAFAFA'}>
      <Row>
        <Col x={24}>
          <Checkbox
            style={{
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px',
              letterSpacing: '0.02em',
            }}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            이용약관/개인정보 처리방침 전체 동의
          </Checkbox>
        </Col>
        <Col x={24}>
          <p
            style={{
              fontWeight: 400,
              fontSize: '20px',
              lineHeight: '24px',
              letterSpacing: '0.02em',
              margin: '24px 0 48px',
            }}
          >
            브레인힐 이용약관, 개인정보 수집/이용에 모두 동의합니다.
          </p>
        </Col>
        <Col x={24}>
          {plainOptions?.map((item, index) => (
            <div key={index}>
              <Checkbox
                style={{
                  fontWeight: 600,
                  fontSize: '20px',
                  lineHeight: '24px',
                  letterSpacing: '0.02em',
                }}
                onChange={() => onChange(item)}
                checked={checkedList.includes(item) ? true : false}
              >
                {item}
              </Checkbox>
              <Content
                maxWidth={'100%'}
                padding={'24px 24px 7px'}
                style={{
                  height: '240px',
                  border: '1px solid #9E9E9E',
                  borderRadius: '4px',
                  overflowY: 'scroll',
                  margin: '24px 0 50px',
                }}
              >
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                    overflow: 'auto',
                  }}
                >
                  {turm[index]}
                </pre>
              </Content>
            </div>
          ))}
        </Col>
        <Col x={24}>
          <Content
            maxWidth={'100%'}
            padding={0}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            backgroundColor={'#FAFAFA'}
          >
            <Button
              style={{
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '22px',
                // padding: '16px 151px',
                // marginTop: '48px',
                width: '100%',
                height: '100%',
              }}
              layoutStyle={{
                width: '100%',
                height: 64,
              }}
              onClick={() => MoveToNext()}
            >
              다음 단계로
            </Button>
          </Content>
        </Col>
      </Row>
    </Content>
  );
}

Join01.defaultProps = {};

export default Join01;
