import React, { useEffect, useState } from 'react';
import { Button, Collapse, Empty, Pagination } from 'antd';
import { Title, Content, Col, Row, Card, ModalLayout } from 'components';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import MessageAlert from 'utils/MessageAlert';
import { PurchaseApi } from 'api';
import { encrypt } from 'utils/Crypto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import {
  HOST_URL,
  dateFormatter,
  getFirstImageFromContent,
  stringToMoneyFormat,
} from 'utils';
import { getCookie } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import ProgramLayout from '../../PopupRoot/ProgramLayout';
import axios from 'axios';

const pageSize = 10;

const rentState = {
  P: '배송전',
  C: '배송완료',
  R: '대여중',
  G: '반납완료',
  O: '연체',
};

const PurchasePresenter = ({
  products,
  productFiles,
  purchases,
  productCategories,
  idx,
  programs,
}) => {
  /* ===== Props ===== */

  /* ===== Mutate ===== */
  const updatePurchase = PurchaseApi.UpdatePurchase({
    onSuccess: (data) => {
      if (data?.status === 200) {
        MessageAlert.success('환불요청이 성공적으로 완료되었습니다');
      } else {
        MessageAlert.error('환불요청에 실패하였습니다');
      }
    },
  });

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== State ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const today = new Date();
  const [currentPage, setCurrentPage] = useState(1);
  const [programModal, setProgramModal] = useState(false);
  const [programValue, setProgramValue] = useState({});
  //상품데이터 정제
  const NewProducts = products?.map((item) => ({
    ...item,
    // thumbnail: getFirstImageFromContent(item.content),
    productFile:
      productFiles &&
      productFiles
        ?.filter((subitem) => item.product_id === subitem.product_id)
        ?.pop(),
    ...productCategories
      ?.filter(
        (subitem) => item.product_category_id === subitem.product_category_id
      )
      ?.pop(),
    productProgram:
      programs &&
      programs
        ?.filter((subitem) => item?.program_id === subitem?.program_id)
        ?.pop(),
  }));
  const NewOrderList = purchases?.map((subitem) => ({
    ...subitem,
    purchase_created_at: subitem.created_at,
    cart_num: subitem.num,
    ...(
      NewProducts &&
      NewProducts?.filter(
        (lowitem) => subitem.product_id === lowitem.product_id
      )
    )?.pop(),
  }));
  // console.log('NewOrderList', NewOrderList);

  let productIdList = [];
  purchases?.map((item) => productIdList.push(item.product_id));

  let categoryNameSet = { parent: '', child: '' };

  /* ===== Hooks ===== */
  /**
   * 비회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }
  }, [token, uid, loginWho, navigate]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== Functions ===== */
  /**
   * 파일다운로드
   * --
   */
  const handleFileDownload = async (item) => {
    if (item) {
      const url = `brainheal/product/${item?.file_url}`;

      try {
        const res = await axios.post(`${HOST_URL}/file/blob`, {
          key: url,
        });
        const blob = res.data.data.data;
        const uint8Array = new Uint8Array(blob);
        const fileData = new File([uint8Array], item.file_name);
        const downloadURL = window.URL.createObjectURL(fileData);
        const a = document.createElement('a');
        a.href = downloadURL;
        a.download = item.file_name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadURL);
      } catch (err) {
        console.log('파일 다운로드 에러!', err);
        throw err;
      }
    } else {
      MessageAlert.error('다운로드 실패! 관리자에게 문의해주세요!');
    }
  };

  /**
   * 원화 콤마
   * --
   * @param {*} inputValue
   */
  const handleComma = (v) => {
    return [v].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /**
   * 상품상세보기 이동
   * --
   */
  const handleDetail = (value) => {
    if (window.confirm('상품상세보기로 이동 하시겠습니까?')) {
      navigate(`/shop/detail/${encodeURIComponent(encrypt(value))}`);
    } else {
      return;
    }
  };

  /**
   * 취소/환불 요청
   * --
   */
  const handleCancle = (id) => {
    if (window.confirm('취소/환불 요청 진행하시겠습니까?')) {
      const idx = parseInt(id);
      try {
        updatePurchase.mutate({
          purchase_id: idx,
          purchase_state: 'R',
        });
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('환불요청에 실패하였습니다');
        throw e;
      }
    }
  };

  /* ===== Style ===== */
  const styles = {
    addressCardP: {
      fontSize: screenSize >= 768 ? 16 : 14,
    },
  };

  /* ===== Render ===== */
  return (
    <>
      <Content maxWidth={1200} padding={0}>
        <Row>
          <Col x={24} padding={8}>
            <Title
              size={screenSize >= 768 ? 2 : 4}
              padding={8}
              style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
            >
              구매내역
            </Title>
          </Col>

          <Col x={24}>
            <Content maxWidth={'100%'} padding={'0px'}>
              <Col x={24}>
                {/* 구매내역 리스트 */}
                <Content maxWidth={'100%'} padding={'20px 10px 0 10px'}>
                  {NewOrderList?.length < 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: '400px',
                        alignItems: 'center',
                        border: '1px solid #f0f0f0',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    NewOrderList?.sort((a, b) => {
                      return (
                        new Date(b.purchase_created_at) -
                        new Date(a.purchase_created_at)
                      );
                    })
                      ?.map((item) => ({
                        ...item,
                        thumbnail: getFirstImageFromContent(item.content),
                      }))
                      ?.map(
                        (item, index) =>
                          index >= 0 + pageSize * (currentPage - 1) &&
                          index <= 9 + pageSize * (currentPage - 1) && (
                            <Row key={`purchase_list_item_${index}`}>
                              <Col x={24} padding={0}>
                                <Card
                                  style={{ border: 'none', marginBottom: 25 }}
                                  bodyStyle={{
                                    padding: 10,
                                  }}
                                  hoverable={false}
                                >
                                  <Title
                                    size={5}
                                    style={
                                      screenSize >= 768
                                        ? { marginBottom: 10 }
                                        : { justifyContent: 'flex-start' }
                                    }
                                  >
                                    {item?.product_id === 0
                                      ? '충전완료'
                                      : '구매완료'}
                                    {screenSize < 768 && (
                                      <span
                                        style={{
                                          paddingLeft: 10,
                                          fontWeight: 500,
                                          color: '#9E9E9E',
                                          fontSize: 14,
                                        }}
                                      >
                                        {item?.product_id !== 0
                                          ? moment(
                                              item.purchase_created_at
                                            ).format('YY.MM.DD 결제')
                                          : moment(
                                              item.purchase_created_at
                                            ).format('YY.MM.DD 충전')}
                                      </span>
                                    )}
                                  </Title>
                                  <Collapse ghost expandIconPosition={'end'}>
                                    <Collapse.Panel
                                      header={
                                        <Row
                                          style={{
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Col x={8} md={5}>
                                            {item?.thumbnail ? (
                                              <div
                                                className="product_card_thumbnail"
                                                style={{
                                                  marginRight: 15,
                                                  height: '80px',
                                                  border: '1px solid #E0E0E0',
                                                  background: `url(${
                                                    item?.thumbnail &&
                                                    item?.thumbnail
                                                  }) no-repeat center/contain`,
                                                }}
                                              ></div>
                                            ) : (
                                              <FontAwesomeIcon
                                                style={{
                                                  fontSize: '50px',
                                                  marginRight: 15,
                                                  height: '80px',
                                                  border: '1px solid #E0E0E0',
                                                  width: 'calc(100% - 15px)',
                                                }}
                                                icon={faImage}
                                              />
                                            )}
                                          </Col>
                                          {/*  */}
                                          <Col x={16} md={5}>
                                            <Title
                                              size={4}
                                              style={{
                                                display: 'block',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                              }}
                                            >
                                              {item?.product_id !== 0
                                                ? item.product_name
                                                : `${stringToMoneyFormat(
                                                    item?.purchase_price,
                                                    'P'
                                                  )}`}
                                            </Title>
                                            <p
                                              style={{
                                                marginTop: '5px',
                                                fontSize: '1em',
                                              }}
                                            >
                                              {productCategories?.map(
                                                (subitem) => {
                                                  if (
                                                    subitem.product_category_id ===
                                                    item.product_category_id
                                                  ) {
                                                    if (subitem.type === 'C') {
                                                      categoryNameSet['child'] =
                                                        subitem.product_category_name;
                                                      productCategories?.map(
                                                        (parent) => {
                                                          if (
                                                            parent.product_category_id ===
                                                            subitem.parents_id
                                                          ) {
                                                            categoryNameSet[
                                                              'parent'
                                                            ] =
                                                              parent.product_category_name;
                                                            return '';
                                                          } else {
                                                            return '';
                                                          }
                                                        }
                                                      );
                                                    } else {
                                                      categoryNameSet['child'] =
                                                        subitem.product_category_name;
                                                    }
                                                    return (
                                                      categoryNameSet[
                                                        'parent'
                                                      ] +
                                                      ' / ' +
                                                      categoryNameSet['child']
                                                    );
                                                  } else {
                                                    return '';
                                                  }
                                                }
                                              )}
                                            </p>
                                            {item?.product_id !== 0 ? (
                                              <p
                                                style={{
                                                  margin: 0,
                                                  fontSize: '0.95em',
                                                }}
                                              >
                                                구매 수량 :{' '}
                                                {item.cart_num
                                                  ? item.cart_num
                                                  : 1}
                                              </p>
                                            ) : null}
                                          </Col>
                                          {/* 결제일 */}
                                          <Col x={0} md={5}>
                                            <div
                                              style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                              }}
                                            >
                                              <p
                                                style={{
                                                  fontSize: '1.3em',
                                                  fontWeight: 700,
                                                }}
                                              >
                                                {item?.product_id !== 0
                                                  ? moment(
                                                      item.purchase_created_at
                                                    ).format('YY.MM.DD 결제')
                                                  : moment(
                                                      item.purchase_created_at
                                                    ).format('YY.MM.DD 충전')}
                                              </p>
                                              {item?.product_id !== 0 ? (
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    fontSize: '1em',
                                                  }}
                                                >
                                                  {handleComma(
                                                    item.puchase_price
                                                      ? '총 금액 : ' +
                                                          item.puchase_price
                                                      : '개 당 : ' + item.price
                                                  )}
                                                  {item?.is_cash === 'Y'
                                                    ? '원'
                                                    : 'P'}
                                                </p>
                                              ) : null}
                                            </div>
                                          </Col>
                                          {/* 상품상세보기 버튼 */}
                                          <Col
                                            style={{
                                              marginTop: screenSize < 768 && 10,
                                            }}
                                            x={item?.product_id !== 0 ? 24 : 0}
                                            md={4}
                                          >
                                            {item?.product_id !== 0 ? (
                                              <Button
                                                type="link"
                                                style={
                                                  screenSize >= 768
                                                    ? {
                                                        color: '#ab5476',
                                                        fontWeight: 'bold',
                                                      }
                                                    : {
                                                        color: '#ab5476',
                                                        fontWeight: 'bold',
                                                        border:
                                                          '1px solid #AB5476',
                                                        width: '100%',
                                                      }
                                                }
                                                onClick={() =>
                                                  handleDetail(item.product_id)
                                                }
                                              >
                                                상품상세보기
                                              </Button>
                                            ) : null}
                                          </Col>
                                          {/* //상품상세보기 버튼 */}

                                          {/* 다운 버튼 및 환불 버튼 */}
                                          <Col
                                            x={24}
                                            md={5}
                                            style={{
                                              marginTop: screenSize < 768 && 5,
                                            }}
                                          >
                                            {item.sales_type === 'F' ? (
                                              // NOTE: 상품 타입이 파일이고 7일 이내일 경우
                                              <div
                                                style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                {today -
                                                  new Date(
                                                    item?.purchase_created_at
                                                  ) <=
                                                7 * 24 * 60 * 60 * 1000 ? (
                                                  <div
                                                    style={
                                                      screenSize >= 768
                                                        ? {
                                                            width: '80%',
                                                            textAlign: 'center',
                                                          }
                                                        : {
                                                            display: 'flex',
                                                            justifyContent:
                                                              'space-between',
                                                            alignItems:
                                                              'center',
                                                            width: '100%',
                                                          }
                                                    }
                                                  >
                                                    <Button
                                                      block
                                                      type="primary"
                                                      style={{
                                                        background: '#AB5476',
                                                        border: 'none',
                                                        width:
                                                          screenSize < 768 &&
                                                          item?.productProgram
                                                            ? '49%'
                                                            : '100%',
                                                      }}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleFileDownload(
                                                          item?.productFile
                                                        );
                                                      }}
                                                    >
                                                      파일 다운로드
                                                    </Button>
                                                    {/* //NOTE: 교안 다운 추가 */}
                                                    {item?.productProgram ? (
                                                      <Button
                                                        block
                                                        type="primary"
                                                        style={{
                                                          background: '#AB5476',
                                                          marginTop:
                                                            screenSize >= 768
                                                              ? 5
                                                              : 0,
                                                          border: 'none',
                                                          width:
                                                            screenSize < 768 &&
                                                            '49%',
                                                        }}
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          setProgramModal(true);
                                                          setProgramValue({
                                                            program_id:
                                                              item
                                                                ?.productProgram
                                                                ?.program_id,
                                                            ...item
                                                              ?.productProgram
                                                              ?.content,
                                                          });
                                                        }}
                                                      >
                                                        교안 다운로드
                                                      </Button>
                                                    ) : null}

                                                    {/* NOTE: 다운로드 기간 */}
                                                    {screenSize >= 768 && (
                                                      <p
                                                        style={{
                                                          marginTop: 5,
                                                          color: '#757575',
                                                        }}
                                                      >
                                                        {dateFormatter(
                                                          item?.purchase_created_at,
                                                          'YY-MM-DD'
                                                        )}{' '}
                                                        ~{' '}
                                                        {dateFormatter(
                                                          new Date(
                                                            item?.purchase_created_at
                                                          ).setDate(
                                                            new Date(
                                                              item?.purchase_created_at
                                                            ).getDate() + 7
                                                          ),
                                                          'YY-MM-DD'
                                                        )}
                                                      </p>
                                                    )}
                                                  </div>
                                                ) : (
                                                  // NOTE: 상품 타입이 파일이고 다운로드 기간이 만료된 경우
                                                  <Title
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#FB4E4E',
                                                    }}
                                                  >
                                                    다운로드 기간 만료
                                                  </Title>
                                                )}
                                              </div>
                                            ) : item?.product_id !== 0 ? (
                                              // NOTE: 포인트 상품이 아니고 구매 후 7일 이내일 경우
                                              <div
                                                style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                {today -
                                                  new Date(
                                                    item?.purchase_created_at
                                                  ) <=
                                                7 * 24 * 60 * 60 * 1000 ? (
                                                  item?.purchase_state ===
                                                  'P' ? (
                                                    <Content
                                                      padding={
                                                        screenSize >= 768
                                                          ? 15
                                                          : 0
                                                      }
                                                      style={{
                                                        display: 'flex',
                                                        flexDirection:
                                                          screenSize >= 768
                                                            ? 'column'
                                                            : 'row',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                          screenSize >= 768
                                                            ? 'flex-start'
                                                            : 'space-between',
                                                      }}
                                                    >
                                                      {/* //NOTE: 렌탈 상품 교안 다운 추가 */}
                                                      {item?.sales_type ===
                                                        'R' &&
                                                        item?.productProgram && (
                                                          <Button
                                                            block
                                                            type="primary"
                                                            style={{
                                                              background:
                                                                '#AB5476',
                                                              marginBottom:
                                                                screenSize >=
                                                                768
                                                                  ? 5
                                                                  : 0,
                                                              border: 'none',
                                                              width:
                                                                screenSize >=
                                                                768
                                                                  ? '80%'
                                                                  : '49%',
                                                            }}
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              setProgramModal(
                                                                true
                                                              );
                                                              setProgramValue({
                                                                program_id:
                                                                  item
                                                                    ?.productProgram
                                                                    ?.program_id,
                                                                ...item
                                                                  ?.productProgram
                                                                  ?.content,
                                                              });
                                                            }}
                                                          >
                                                            교안 다운로드
                                                          </Button>
                                                        )}
                                                      {/* 환불요청 버튼 */}
                                                      <Button
                                                        block
                                                        type="primary"
                                                        style={{
                                                          background: '#AB5476',
                                                          border: 'none',
                                                          width:
                                                            screenSize >= 768
                                                              ? '50%'
                                                              : item?.productProgram
                                                              ? '49%'
                                                              : '100%',
                                                        }}
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleCancle(
                                                            item?.purchase_id
                                                          );
                                                        }}
                                                      >
                                                        환불 요청
                                                      </Button>
                                                    </Content>
                                                  ) : item?.purchase_state ===
                                                    'R' ? (
                                                    <Title
                                                      style={{
                                                        fontSize: '14px',
                                                        color: '#03A8F4',
                                                      }}
                                                    >
                                                      확인중
                                                    </Title>
                                                  ) : item?.purchase_state ===
                                                    'C' ? (
                                                    <Title
                                                      style={{
                                                        fontSize: '14px',
                                                        color: '#FB4E4E',
                                                      }}
                                                    >
                                                      환불 완료
                                                    </Title>
                                                  ) : (
                                                    ''
                                                  )
                                                ) : (
                                                  <Title
                                                    style={{
                                                      fontSize: '14px',
                                                      color: '#2AC769',
                                                    }}
                                                  >
                                                    결제 완료
                                                  </Title>
                                                )}
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <Title
                                                  style={{
                                                    fontSize: '14px',
                                                    color: '#2AC769',
                                                  }}
                                                >
                                                  결제 완료
                                                </Title>
                                              </div>
                                            )}
                                          </Col>
                                        </Row>
                                      }
                                      showArrow={
                                        item?.delivery?.delivery_address_id &&
                                        screenSize >= 768
                                          ? true
                                          : false
                                      }
                                    >
                                      {item?.delivery?.delivery_address_id ? (
                                        <Card
                                          hoverable
                                          style={{
                                            width: '100%',
                                            marginTop: 10,
                                          }}
                                        >
                                          <Title
                                            size={screenSize >= 768 ? 4 : 5}
                                            style={{
                                              justifyContent: 'flex-start',
                                            }}
                                          >
                                            배송지 입력 정보{' '}
                                            <span
                                              style={{
                                                marginLeft: 10,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                fontWeight: 500,
                                                color:
                                                  item?.rent_state === 'P'
                                                    ? '#BDBDBD'
                                                    : item?.rent_state ===
                                                        'C' ||
                                                      item?.rent_state === 'G'
                                                    ? '#2AC769'
                                                    : item?.rent_state === 'R'
                                                    ? '#03A9F4'
                                                    : '#FB4E4E',
                                              }}
                                            >
                                              {rentState[item?.rent_state]}
                                            </span>
                                          </Title>
                                          <div style={styles.addressCardP}>
                                            <p>
                                              {item?.delivery?.recipient_name}
                                            </p>
                                            <p>
                                              {
                                                item?.delivery
                                                  ?.recipient_address
                                              }
                                              ,
                                              {
                                                item?.delivery
                                                  ?.recipient_address_detail
                                              }
                                            </p>
                                            <p>
                                              {item?.delivery?.recipient_phone}
                                            </p>
                                            <p>{item?.delivery?.request}</p>
                                          </div>
                                        </Card>
                                      ) : null}
                                    </Collapse.Panel>
                                  </Collapse>
                                </Card>
                              </Col>
                            </Row>
                          )
                      )
                  )}
                  <Row>
                    <Col x={24}>
                      <Pagination
                        current={currentPage}
                        total={NewOrderList?.length}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        pageSize={pageSize}
                        onChange={(e) => setCurrentPage(e)}
                      />
                    </Col>
                  </Row>
                </Content>
              </Col>
            </Content>
          </Col>
        </Row>
      </Content>
      {/* 교안 미리 보기 모달 */}
      <ModalLayout
        title={'프로그램'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={programModal}
        onCancel={() => {
          setProgramModal(false);
          setProgramValue({});
        }}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 768 : '100%'}
        height={'70%'}
        closable
        zIndex={5700}
      >
        <ProgramLayout programValue={programValue} />
      </ModalLayout>
    </>
  );
};

PurchasePresenter.defualtProps = {};

export default PurchasePresenter;
