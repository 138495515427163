import React, { useEffect, useState } from 'react';
import './PaymentSuccess.style.css';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Content, Row, Col, Title, Button, LoadingPage } from 'components';
import { TOSS_SECRET_KEY, getCookie, stringToMoneyFormat } from 'utils';
import { CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  InterestListApi,
  LectureOfUserApi,
  PurchaseApi,
  ProductCartApi,
  ProductApi,
  UserApi,
  AgencyApi,
} from 'api';
import { useSetRecoilState } from 'recoil';
import { userInfo } from 'atoms/Atoms';

const styles = {
  tr: {
    background: '#FAFAFA',
    borderRight: '1px solid #BDBDBD',
    padding: '20px 10px',
    borderBottom: '1px solid #BDBDBD',
    display: 'flex',
    alignItems: 'center',
  },
  td: {
    padding: '20px 10px',
    borderBottom: '1px solid #BDBDBD',
    display: 'flex',
    alignItems: 'center',
  },
  trText: {
    fontSize: '16px',
  },
  tdText: {
    fontSize: '15px',
  },
};

const PaymentSuccess = () => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paymentKey = searchParams.get('paymentKey');
  const orderId = searchParams.get('orderId');
  const amount = searchParams.get('amount');

  /* ===== STATE ===== */
  const [order, setOrder] = useState([]);
  const [orderInfo, setOrderInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // 로그인 유저 정보
  const userInfoData = useSetRecoilState(userInfo);

  /* ===== MUTATE ===== */
  const deleteInterestList = InterestListApi.DeleteInterestList();
  const createLectureOfUser = LectureOfUserApi.CreateLectureOfUser();
  const deleteProductList = ProductCartApi.DeleteProductCart();
  const createPurchase = PurchaseApi.CreatePurchase();
  const updateProduct = ProductApi.UpdateProduct();
  const updateUser = UserApi.UpdateUser({
    onSuccess: (data) => {
      if (data?.status === 200) {
        userInfoData({
          ...data?.data,
        });
      }
    },
  });
  const updateAgency = AgencyApi.UpdateAgency({
    onSuccess: (data) => {
      if (data?.status === 200) {
        userInfoData({
          ...data?.data,
        });
      }
    },
  });

  /* ===== FUNCION ===== */
  /**
   * 결제 요청 함수
   * --
   */
  const handlePayment = async () => {
    try {
      const secretKey = btoa(TOSS_SECRET_KEY + ':');
      const data = {
        paymentKey: paymentKey,
        amount: amount,
        orderId: orderId,
      };
      const url = 'https://api.tosspayments.com/v1/payments/confirm';
      const headers = {
        Authorization: `Basic ${secretKey}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(url, data, { headers });

      if (response.data.status === 'DONE') {
        setOrderInfo(response.data);

        const orderList = localStorage.getItem('orderList');
        const parsedData = JSON.parse(orderList);
        // 결제된 상품이 강의인지 상품인지 체크
        const isType = !Array.isArray(parsedData)
          ? 'point'
          : parsedData?.every((obj) => obj.hasOwnProperty('lecture_id'))
          ? 'lecture'
          : 'product';

        /**
         * 결제된 상품 장바구니 삭제
         * --
         */
        if (isType === 'lecture') {
          try {
            parsedData?.forEach((item) => {
              deleteInterestList.mutate({
                interest_list_id: item.interest_list_id,
              });
            });
          } catch (e) {
            console.log('Error !!', e);
            throw e;
          } finally {
          }
        } else if (isType === 'product') {
          try {
            parsedData?.forEach((item) => {
              deleteProductList.mutate({
                product_cart_id: item.product_cart_id,
              });
            });
          } catch (e) {
            console.log('Error !!', e);
            throw e;
          }
        } else {
        }

        /**
         * 결제된 상품 구매내역 추가
         * --
         */
        if (isType === 'lecture') {
          try {
            // console.log('paymentKey', paymentKey, paymentKey.length);
            parsedData?.forEach((item) => {
              // console.log('실행');
              createLectureOfUser.mutate({
                user_id: parseInt(getCookie('USER')),
                lecture_id: item?.lecture_id,
                score: 0,
                payments_key: paymentKey,
                purchase_state: 'P',
                price: item?.price,
              });
            });
          } catch (e) {
            console.log('Error !!', e);
            throw e;
          }
        } else if (isType === 'product') {
          try {
            parsedData?.forEach((item) => {
              try {
                createPurchase.mutate({
                  product_id: item?.product_id,
                  user_id: item?.user_id ? item?.user_id : null,
                  agency_id: item?.agency_id ? item?.agency_id : null,
                  purchase_price: item?.price,
                  num: item?.num ? (item?.cart_num ? item?.cart_num : 1) : null,
                  purchase_state: 'P',
                  payments_key: paymentKey,
                  delivery:
                    item?.sales_type === 'F'
                      ? null
                      : JSON.parse(localStorage.getItem('orderAddress')),
                  rent_state: item?.sales_type === 'F' ? null : 'P',
                });
              } catch (e) {
                console.log('Error !!', e);
              }

              try {
                const cartNum = item?.cart_num ? item?.cart_num : 1;
                updateProduct.mutate({
                  product_id: item?.product_id,
                  num: item?.num
                    ? parseInt(item?.num) - parseInt(cartNum)
                    : null,
                });
              } catch (e) {
                console.log('Error !!', e);
              }
            });
          } catch (e) {
            console.log('Error !!', e);
            throw e;
          }
        } else if (isType === 'point') {
          if (parsedData?.user_id) {
            try {
              updateUser.mutate({
                user_id: parsedData?.user_id,
                point: parsedData?.point,
              });

              createPurchase.mutate({
                product_id: 0,
                user_id: parsedData?.user_id,
                payments_key: paymentKey,
                purchase_price: amount,
                purchase_state: 'P',
              });
            } catch (err) {
              console.log('포인트 충전에러!', err);
              throw err;
            }
          } else {
            try {
              updateAgency.mutate({
                agency_id: parsedData?.agency_id,
                point: parsedData?.point,
              });

              createPurchase.mutate({
                product_id: 0,
                agency_id: parsedData?.agency_id,
                payments_key: paymentKey,
                purchase_price: amount,
                purchase_state: 'P',
              });
            } catch (err) {
              console.log('포인트 충전에러!', err);
              throw err;
            }
          }
        }
      }
      localStorage.removeItem('orderList');
      localStorage.removeItem('orderAddress');
    } catch (error) {
      console.error('Error =====>', error);
    } finally {
      setTimeout(() => setIsLoading(false), 2000);
    }
  };

  /**
   * 구매목록 이동 함수
   * --
   */
  const handleNavigate = () => {
    const isType = !Array.isArray(order)
      ? 'point'
      : order?.every((obj) => obj.hasOwnProperty('lecture_id'))
      ? 'lecture'
      : 'product';

    if (isType === 'lecture') {
      navigate('/mypage/lecture/orders');
    } else if (isType === 'product') {
      navigate('/mypage/product/purchase');
    } else {
      window.close();
    }
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    const orderList = localStorage.getItem('orderList');

    if (orderList) {
      try {
        const parsedData = JSON.parse(orderList);
        setOrder(parsedData);
        handlePayment();
      } catch (error) {
        console.error('Error !!', error);
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
        navigate(`/error`);
      }, 3000);
    }
    // eslint-disable-next-line
  }, []);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <Content maxWidth={'100%'}>
      <Content maxWidth={500} padding={'80px 0'}>
        <Row>
          <Col x={24}>
            <Content maxWidth={'100%'}>
              <div
                style={{
                  width: '100%',
                  height: '150px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <CheckCircleOutlined
                  style={{ fontSize: '80px', color: '#2AC769' }}
                />
                <Title style={{ marginTop: '30px', fontSize: '30px' }}>
                  <span style={{ color: '#AB5476' }}>결제요청</span>이
                  <span style={{ color: '#AB5476', marginLeft: '10px' }}>
                    완료
                  </span>
                  되었습니다.
                </Title>
              </div>
              <Title
                align={'left'}
                style={{
                  marginTop: '100px',
                  paddingBottom: '10px',
                  borderBottom: '2px solid #9E9E9E',
                  fontSize: '22px',
                }}
              >
                결제정보
              </Title>
              <Content maxWidth={'100%'} padding={'15px 0'}>
                <Row>
                  <Col x={8} style={{ ...styles.tr }}>
                    <Title style={{ ...styles.trText }}>주문번호</Title>
                  </Col>
                  <Col x={16} style={{ ...styles.td }}>
                    <Title style={{ ...styles.tdText }}>
                      {orderInfo.orderId}
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col x={8} style={{ ...styles.tr }}>
                    <Title style={{ ...styles.trText }}>상품명</Title>
                  </Col>
                  <Col x={16} style={{ ...styles.td }}>
                    <Title style={{ ...styles.tdText }}>
                      {!Array.isArray(order)
                        ? orderInfo?.orderName
                        : order && order.length > 1
                        ? order?.every((obj) =>
                            obj.hasOwnProperty('lecture_id')
                          )
                          ? `${order[0].title} 외 ${order.length - 1}건`
                          : `${order[0].product_name} 외 ${order.length - 1}건`
                        : order?.every((obj) =>
                            obj.hasOwnProperty('lecture_id')
                          )
                        ? `${order[0].title}`
                        : `${order[0].product_name}`}
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col x={8} style={{ ...styles.tr }}>
                    <Title style={{ ...styles.trText }}>결제일시</Title>
                  </Col>
                  <Col x={16} style={{ ...styles.td }}>
                    <Title style={{ ...styles.tdText }}>
                      {moment(orderInfo.approvedAt).format(
                        'YYYY.MM.DD HH:mm:ss'
                      )}
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col x={8} style={{ ...styles.tr }}>
                    <Title style={{ ...styles.trText }}>결제금액</Title>
                  </Col>
                  <Col x={16} style={{ ...styles.td }}>
                    <Title style={{ ...styles.tdText }}>
                      {`${stringToMoneyFormat(orderInfo.totalAmount)} ${
                        orderInfo.currency
                      } (${
                        orderInfo.card.installmentPlanMonths === 0
                          ? '일시불'
                          : `${orderInfo.card.installmentPlanMonths}개월`
                      })`}
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col x={8} style={{ ...styles.tr }}>
                    <Title style={{ ...styles.trText }}>카드번호</Title>
                  </Col>
                  <Col x={16} style={{ ...styles.td }}>
                    <Title style={{ ...styles.tdText }}>
                      {orderInfo.card.number}
                    </Title>
                  </Col>
                </Row>
              </Content>
            </Content>
          </Col>
          <Col x={24}>
            <Button
              style={{ margin: '30px auto', width: '300px', height: '50px' }}
              onClick={() => handleNavigate()}
            >
              목록으로
            </Button>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default PaymentSuccess;
