const BottomLayout = ({ shadow, style, children }) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: 10,
        zIndex: 990,
        background: '#fff',
        ...(shadow && { boxShadow: '0 -10px 15px 0 rgba(0, 0, 0, 0.06)' }),
        ...style,
      }}
    >
      {children}
    </div>
  );
};

/* DF */
BottomLayout.defaultProps = {
  shadow: false,
  children: null,
  style: {},
};

export default BottomLayout;
