import React, { useState, useEffect } from 'react';
import './CommentList.style.css';
import { Button } from 'components';
import { Input, Avatar, Button as Btn } from 'antd';
import { CommentApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { getCookie, STORE_URL } from 'utils';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTurnUp } from '@fortawesome/free-solid-svg-icons';

const Comment = ({ data, onSubmit }) => {
  const userId = getCookie('USER');
  const [commentValue, setCommentValue] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isReComment, setIsReComment] = useState(false);
  const [reCommentValue, setReCommentValue] = useState('');

  return (
    <>
      <div className="board_comment_list_item">
        <div className="board_comment_list_item_info">
          <Avatar
            size={50}
            src={`${STORE_URL}/users/${data?.profile?.user_id}/${data?.profile?.file_url}`}
          />
        </div>
        <div className="board_comment_list_item_comment">
          <h4>
            {data?.name}{' '}
            <span>{moment(data?.created_at)?.format('YYYY-MM-DD HH:mm')}</span>
          </h4>
          {isUpdate ? (
            <div className="board_comment_list_item_form">
              <div className="board_comment_list_item_form_input">
                <Input.TextArea
                  size="large"
                  placeholder={'댓글을 입력해주세요'}
                  value={commentValue}
                  onChange={(e) => setCommentValue(e.target.value)}
                  maxLength={100}
                  showCount
                  style={{ height: 100 }}
                />
              </div>
              <div className="board_comment_list_item_form_button">
                <Button
                  layoutStyle={{ margin: 0, marginRight: 5 }}
                  onClick={() => {
                    onSubmit('UPDATE', {
                      ...data,
                      comment: commentValue,
                    });
                    setIsUpdate(false);
                    setCommentValue('');
                  }}
                >
                  수정
                </Button>
                <Button
                  layoutStyle={{ margin: 0 }}
                  color="secondary"
                  onClick={() => setIsUpdate(!isUpdate)}
                >
                  취소
                </Button>
              </div>
            </div>
          ) : (
            <div className="board_comment_list_item_container">
              <pre>{data?.comment}</pre>
              <div className="board_comment_list_item_button">
                {parseInt(userId) === parseInt(data?.user_id) && (
                  <>
                    <Btn
                      type="text"
                      style={{ color: '#03A9F4', fontWeight: 500 }}
                      onClick={() => {
                        setIsUpdate(!isUpdate);
                        setCommentValue(data?.comment);
                      }}
                    >
                      수정
                    </Btn>
                    <Btn
                      danger
                      type="text"
                      style={{ fontWeight: 500 }}
                      onClick={() => onSubmit('DELETE', data?.comment_id)}
                    >
                      삭제
                    </Btn>
                  </>
                )}
                {!data?.parents_id && (
                  <Btn
                    type="text"
                    style={{ fontWeight: 500 }}
                    onClick={() => setIsReComment(!isReComment)}
                  >
                    답글
                  </Btn>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {isReComment && (
        <div className="board_recomment_list_item">
          <div style={{ width: 50, marginRight: 10 }}></div>
          <div>
            <div>
              <Input.TextArea
                size="large"
                placeholder={'답글을 입력해주세요'}
                value={reCommentValue}
                onChange={(e) => setReCommentValue(e.target.value)}
                maxLength={100}
                showCount
                style={{ height: 100 }}
              />
            </div>
            <div>
              <Button
                layoutStyle={{ margin: 0, marginRight: 5 }}
                onClick={() => {
                  onSubmit('CREATE', {
                    parents_id: data?.comment_id,
                    comment: reCommentValue,
                  });
                  setIsReComment(false);
                  setReCommentValue('');
                }}
              >
                등록
              </Button>
              <Button
                layoutStyle={{ margin: 0 }}
                color="secondary"
                onClick={() => setIsReComment(!isReComment)}
              >
                취소
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CommentList = (props) => {
  /* ===== PROPS ===== */
  const { data, comments, page, count } = props;

  /* ===== MUTATE ===== */
  const queryClient = useQueryClient();
  const createComment = CommentApi.CreateComment({
    onSuccess: (d) => {
      if (d?.status === 200) {
        const params = { lecture_id: `${data?.lecture_id}` };
        queryClient.invalidateQueries(['getBoards', params]);
      }
    },
  });
  const updateComment = CommentApi.UpdateComment({
    onSuccess: (d) => {
      if (d?.status === 200) {
        const params = { lecture_id: `${data?.lecture_id}` };
        queryClient.invalidateQueries(['getBoards', params]);
      }
    },
  });
  const deleteComment = CommentApi.DeleteComment(null, {
    onSuccess: () => {
      const params = { lecture_id: `${data?.lecture_id}` };
      queryClient.invalidateQueries(['getBoards', params]);
    },
  });

  /* ===== STATE ===== */
  const [comment, setComment] = useState('');
  const [commentList, setCommentList] = useState([]);

  /* ===== RENDER ===== */
  useEffect(() => {
    if (comments?.length) {
      const newComment = comments?.reduce((acc, cur, idx) => {
        if ((page - 1) * count <= idx && idx < page * count) {
          acc.push(cur);
          return acc;
        } else {
          return acc;
        }
      }, []);

      setCommentList(newComment);
    }
  }, [comments, page, count]);

  /* ===== FUNCTION ===== */
  /**
   * 댓글 등록/수정/삭제 핸들러
   * --
   */
  const handleSubmit = (type, d = null) => {
    const upperType = type?.toLocaleUpperCase();
    const confirm = (txt) => window.confirm(`댓글을 ${txt} 하시겠습니까?`);

    switch (upperType) {
      case 'CREATE':
        if (confirm('등록')) {
          try {
            if (d) {
              createComment.mutate({
                board_id: data?.board_id,
                user_id: getCookie('USER'),
                parents_id: d?.parents_id,
                comment: d?.comment,
              });
            } else {
              createComment.mutate({
                board_id: data?.board_id,
                user_id: getCookie('USER'),
                comment: comment,
              });
            }

            MessageAlert.success('댓글이 성공적으로 등록되었습니다');
            setComment('');
          } catch (err) {
            console.log(`[${upperType} COMMENT ERROR]`, err);
            MessageAlert.error('댓글을 등록하는데 실패하였습니다!');
            throw err;
          }
        }
        break;
      case 'UPDATE':
        if (confirm('수정')) {
          try {
            updateComment.mutate({
              ...d,
            });

            MessageAlert.success('댓글이 성공적으로 수정되었습니다.');
          } catch (err) {
            console.log(`[${upperType} COMMENT ERROR]`, err);
            MessageAlert.error('댓글을 수정하는데 실패하였습니다!');
            throw err;
          }
        }
        break;
      case 'DELETE':
        if (confirm('정말 삭제')) {
          try {
            deleteComment.mutate({
              comment_id: d,
            });

            MessageAlert.success('댓글이 성공적으로 삭제되었습니다.');
          } catch (err) {
            console.log(`[${upperType} COMMENT ERROR]`, err);
            MessageAlert.error('댓글을 삭제하는데 실패하였습니다!');
            throw err;
          }
        }
        break;
      default:
        break;
    }
  };

  /* ===== RENDER ===== */
  return (
    <div className="board_comment_layout">
      {/* 댓글 타이틀 */}
      <div className="board_comment_title">
        <h4>댓글</h4>
      </div>
      {/* /댓글 타이틀 */}

      {/* 댓글 인풋 */}
      <div className="board_comment_form">
        <div>댓글</div>
        <div>
          <Input.TextArea
            size="large"
            placeholder="댓글을 입력해주세요"
            maxLength={100}
            style={{ height: 120 }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            showCount
          />
        </div>
        <div>
          <Button onClick={() => handleSubmit('CREATE', null)}>등록</Button>
        </div>
      </div>
      {/* /댓글 인풋 */}

      {/* 댓글 목록 */}
      <div className="board_comment_list">
        {commentList?.map((item) => (
          <React.Fragment key={`comment-${item?.comment_id}`}>
            <Comment data={item} onSubmit={handleSubmit} />
            {item?.children?.map((subitem) => (
              <div
                className="board_recommenet_list"
                key={`recomment-${subitem?.comment_id}`}
              >
                <div className="board_recommenet_list_icon">
                  <FontAwesomeIcon icon={faTurnUp} />
                </div>
                <Comment data={subitem} onSubmit={handleSubmit} />
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
      {/* /댓글 목록 */}
    </div>
  );
};

export default CommentList;
