/**
 * 라인
 * --
 * date: 2021-05-10
 * writer: Derek
 */

/**
 * Line
 * --
 */
const Line = ({
  vertical,
  verticalArrow,
  horizontalArrow,
  stroke,
  color,
  width,
  children,
  style,
  shape,
  verticalMargin,
}) => {
  const styles = vertical
    ? {
        [`border${verticalArrow}`]: `${stroke}px ${shape} ${color}`,
        height: width,
        ...(!children && { width: 0 }),
      }
    : {
        [`border${horizontalArrow}`]: `${stroke}px ${shape} ${color}`,
        width: width,
        ...(!children && { height: 0 }),
      };

  /* RENDER */
  return (
    <div
      style={{
        // margin: '0 auto',
        margin: `${verticalMargin}px 0`,
        ...styles,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

/* Default Props */
Line.defaultProps = {
  vertical: false,
  verticalArrow: 'Right',
  horizontalArrow: 'Bottom',
  verticalMargin: 12,
  stroke: 1,
  color: '#dbdbdb',
  children: null,
  width: '100%',
  shape: 'solid',
  style: {},
};

export default Line;
