import React, { useState, useEffect } from 'react';
import AgencySchedulesPresenter from './AgencySchedulesPresenter';

const AgencySchedulesContainer = ({ data }) => {
  /* ===== Props ===== */
  const { users, teachers, agencies, categories, profiles } = data;

  /* ===== Router ===== */
  /* ===== State ===== */
  const [newData, setNewData] = useState({});

  /* ===== Functions ===== */
  /* ===== Hooks ===== */
  useEffect(() => {
    setNewData({
      teachers: users?.concat(teachers),
      agencies: agencies,
      categories: categories,
    });
  }, [agencies, categories, profiles, teachers, users]);

  /* ===== Render ===== */
  return (
    <AgencySchedulesPresenter
      teachers={newData?.teachers}
      agencies={newData?.agencies}
      categories={newData?.categories}
      repetitionSchedules={newData?.repetitionSchedules}
    />
  );
};

AgencySchedulesContainer.defaultProps = {};

export default AgencySchedulesContainer;
