import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 반복 스케쥴 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetRepetitionSchedules: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getRepetitionSchedules', params] : 'getRepetitionSchedules',
        async () =>
          await $http.get(APIConstant.GET_REPETITION_SCHEDULES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 반복 스케쥴 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetRepetitionSchedule: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_REPETITION_SCHEDULE, {
        repetition_schedule_id: idx,
      });
      const res = useQuery(
        params ? ['getRepetitionSchedule', params] : 'getRepetitionSchedule',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 반복 스케쥴 생성
   * --
   * @method POST
   * @returns
   */
  CreateRepetitionSchedule: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_REPETITION_SCHEDULE,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getRepetitionSchedules');
            queryClient.invalidateQueries('getRepetitionSchedule');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 반복 스케쥴 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateRepetitionSchedule: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_REPETITION_SCHEDULE, {
            repetition_schedule_id: data.repetition_schedule_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getRepetitionSchedules');
            queryClient.invalidateQueries('getRepetitionSchedule');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 반복스케쥴 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteRepetitionSchedule: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_REPETITION_SCHEDULE, {
            repetition_schedule_id: data.repetition_schedule_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getRepetitionSchedules');
            queryClient.invalidateQueries('getRepetitionSchedule');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
