/**
 *
 *
 */

import React from 'react';
import { Layout } from 'antd';
import { Title } from 'components';
import notifications from 'assets/bg/notifications.png';

const { Header } = Layout;

/**
 * [Component]
 * --
 */
const PageHeader = ({
  children,
  size,
  subButton,
  containerStyle,
  titleStyle,
  subButtonOnClick,
}) => {
  return (
    <Header
      className="site-layout-background"
      style={{
        padding: '0 18px',
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        zIndex: 99,
        paddingTop: 0,
        lineHeight: 0,
        ...containerStyle,
      }}
    >
      <Title
        size={size}
        {...(subButton && {
          subButton:
            subButton === true ? (
              <img
                src={notifications}
                style={{ width: 16, marginTop: 3 }}
                onClick={subButtonOnClick}
                alt=""
              />
            ) : (
              subButton
            ),
        })}
        style={{ width: '100%', lineHeight: '200%', ...titleStyle }}
        // subButton={subButton === true ?<img src={notifications} style={{ width: 16 }} />}
      >
        {children}
      </Title>
    </Header>
  );
};

PageHeader.defaultProps = {
  size: 5,
  subButton: false, // true || <img src={notifications} style={{ width: 16 }} />
  subButtonOnClick: () => {},
  containerStyle: {},
  titleStyle: {},
};
export default PageHeader;
