/**
 *
 *
 */
import { message } from 'antd';

message.config({
  // top: 100,
  duration: 2,
  maxCount: 1,
});

const MessageAlert = {
  /** Success */
  success: (m = 'success', time = 1.2) => message.success(m, time),

  /** error */
  error: (m = 'error', time = 1.2) => message.error(m, time),

  /** warning */
  warning: (m = 'warning', time = 1.2) =>
    message.warning({
      content: m,
      duration: time,
      style: {
        fontSize: '18px',
      },
    }),

  /** info */
  info: (m = 'info', time = 1.2) => message.info(m, time),
};

export default MessageAlert;
