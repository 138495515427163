/**
 *
 *
 */
import ApiManager from '../APIManager';
import APIConstant from '../APIConstant';
import { parameterToPath } from 'utils';
const $http = new ApiManager();

const Api = {
  /**
   * [POST] 요청 등록
   * --
   */
  postRequest: async (newData) => {
    try {
      const { status, data, message } = await $http.post(
        APIConstant.POST_REQUEST,
        newData
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * [PUT] 수정
   * --
   */
  acceptRequest: async (newData) => {
    try {
      const { requestId, helperId } = newData;
      const { status, data, message } = await $http.put(
        parameterToPath(APIConstant.ACCEPT_REQUEST, { requestId }),
        {
          helper_idx: helperId,
        }
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * [PUT] 수정
   * --
   */
  updateRequestStatus: async (newData) => {
    try {
      const { request_idx, request_status } = newData;
      const { status, data, message } = await $http.put(
        parameterToPath(APIConstant.UPDATE_REQUEST_STATUS, { request_idx }),
        {
          request_status,
        }
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * [DELETE] 요청 삭제
   * --
   */
  deleteRequest: async (requestId) => {
    try {
      const { status, data, message } = await $http.delete(
        parameterToPath(APIConstant.DELETE_REQUEST, { requestId })
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * [GET] 요청 목록
   * --
   */
  getRequests: async (q) => {
    try {
      const { status, data, message } = await $http.get(
        APIConstant.GET_REQUESTS,
        q
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      return false;
    }
  },

  /**
   * [GET] 요청 상세
   * --
   */
  getRequest: async (request_idx) => {
    try {
      const { status, data, message } = await $http.get(
        parameterToPath(APIConstant.GET_REQUEST, { request_idx })
      );
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (e) {
      throw e;
    }
  },
};
export default Api;
