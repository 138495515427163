import React from 'react';
import { Content, Row, Col, Title } from 'components';
import './Refund.style.css';

const Refund = ({ screenSize }) => {
  return (
    <Content maxWidth={'100%'}>
      <Content
        maxWidth={1200}
        padding={screenSize >= 768 ? '120px 0' : '60px 0'}
      >
        <Row>
          <Col x={24}>
            <div id="refund_layout">
              <Title size={screenSize >= 768 ? 1 : 3} align={'center'}>
                환 불 규 정
              </Title>
              <h4>
                브레인힐 시니어 콘텐츠랩 평생교육원은 ‘평가 인정 학습 과정
                운영에 관한 규정’에 의거하여 수강료를 환불해드리고 있으니
                유의하시기 바랍니다.
              </h4>
              <ul>
                <li>1. 과오납의 경우</li>
                <li>
                  2. 학습자가 병역법에 따른 병역 의무를 이행하기 위하여 군에
                  입대하는 경우 등 법령에 의하여 학습을 지속할 수 없는 경우
                </li>
                <li>3. 학습자가 학습 포기의 의사를 표시한 경우</li>
                <li>
                  4. 학습자가 본인의 질병, 사망 또는 천재지변이나 그 밖의
                  부득이한 사유로 학습을 시작하지 아니하거나 학업을 계속하지
                  못하게 된 경우
                </li>
              </ul>
              <table>
                <tbody>
                  <tr>
                    <td>반환 사유</td>
                    <td>반환 사유 발생 시점</td>
                    <td>반환 금액</td>
                  </tr>
                  <tr>
                    <td>제1호에 해당하는 경우</td>
                    <td>-</td>
                    <td>과오납된 금액 전액</td>
                  </tr>
                  <tr>
                    <td rowSpan={5}>
                      제2호 부터 제4호까지의 규정에 해당하는 경우
                    </td>
                    <td>수업 시작일 전 일까지</td>
                    <td>학습비 전액</td>
                  </tr>
                  <tr>
                    <td>수업 시작일부터 총 수업 시간의 1/6 경과 전</td>
                    <td>학습비의 6분의 5에 해당하는 금액</td>
                  </tr>
                  <tr>
                    <td>
                      총 수업시간의 1/6 이상부터 1/3 미만 까지의 기간 동안
                    </td>
                    <td>학습비의 3분의 2에 해당하는 금액</td>
                  </tr>
                  <tr>
                    <td>총 수업시간의 1/3 이상부터 1/2 미만까지의 기간 동안</td>
                    <td>학습비의 2분의 1에 해당하는 금액</td>
                  </tr>
                  <tr>
                    <td>총 수업시간의 1/2 이상 경과</td>
                    <td>반환하지 않음</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default Refund;
