import React from 'react';
import { Collapse, Radio, Space } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const RadioCategory = ({ items, title, value, onChange }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const { Panel } = Collapse;

  /* ===== FUNCTION ===== */
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) =>
        isActive ? <MinusOutlined /> : <PlusOutlined />
      }
      expandIconPosition="end"
      defaultActiveKey={['1']}
    >
      <Panel
        header={
          <p
            style={{
              color: '#000000',
              fontWeight: 700,
              fontSize: '17px',
              lineHeight: '20px',
            }}
          >
            {title}
          </p>
        }
        key="1"
        style={{ background: '#FFFFFF', borderRadius: '4px' }}
      >
        <div style={{ marginTop: '25px' }}>
          <Radio.Group value={value} onChange={handleChange}>
            <Space direction="vertical">
              {items?.map((item) => (
                <Radio
                  key={item.value}
                  value={item.value}
                  style={{
                    color: '#9999A3',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    marginBottom: '15px',
                  }}
                >
                  {item.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      </Panel>
    </Collapse>
  );
};

RadioCategory.defaultProps = {
  title: '카테고리',
  items: [
    {
      label: 'Option A',
      value: 1,
    },
    {
      label: 'Option B',
      value: 2,
    },
    {
      label: 'Option C',
      value: 3,
    },
    {
      label: 'Option D',
      value: 4,
    },
    {
      label: 'Option E',
      value: 5,
    },
  ],
};

export default RadioCategory;
