import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './TaxBillLayout.style.css';
import { Content, Row, Col, Button } from 'components';
import { Input } from 'antd';
import { decrypt } from 'utils/Crypto';
import moment from 'moment';
import { EmailApi, TotalCalculateApi } from 'api';
import MessageAlert from 'utils/MessageAlert';

const TaxBillLayout = () => {
  /* ===== ROUTE ===== */
  const [searchParams] = useSearchParams();

  /* ===== MUTATE ===== */
  const updateTotalCalculate = TotalCalculateApi.UpdateTotalCalculate();

  /* ===== STATE ===== */
  const [decodeValue, setDecodeValue] = useState({});
  const [taxBillData, setTaxBillData] = useState({
    approval_number: null, // 승인번호
    company_number: null, // 등록번호
    chief_name: null, // 대표자명
    name: null, // 기관명
    address: null, // 주소
    business_type: null, // 업태
    type: null, // 종목
    email01: null, // 이메일1
    email02: null, // 이메일2
    date: null, // 작성일자
    supply_price: null, // 공급가액
    correction: null, // 수정사유
    etc01: null, // 비고1
    etc02: null, // 비고2
    month: null, // 월
    day: null, // 일
    item: null, // 품목
    standard: null, // 규격
    num: null, // 수량
    unit_price: null, // 단가
    total_price: null, // 합계금액
    cash: null, // 현금
  });

  /* ===== FUNCTION ===== */
  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...taxBillData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setTaxBillData(d);

    if (callback) callback(v);
  };

  /**
   * 이메일 전송
   * --
   */
  const handleSendEmail = async () => {
    const confirm = window.confirm(`메일을 전송하시겠습니까?`);

    if (confirm) {
      try {
        const res = await EmailApi.Sendmail({
          toEmail: decodeValue?.email,
          type: 'tax_bill',
          template_value: {
            agency_id: decodeValue?.agency_id,
            ...taxBillData,
          },
        });

        if (res.status === 200) {
          updateTotalCalculate.mutate({
            total_calculate_id: decodeValue?.total_calculate_id,
            tax_bill_count: decodeValue?.tax_bill_count + 1,
            tax_bill: {
              agency_id: decodeValue?.agency_id,
              ...taxBillData,
              send_date: new Date(),
            },
          });
        }
        MessageAlert.success('메일을 성공적으로 전송하였습니다');
      } catch (err) {
        console.log('이메일 전송 실패', err);
        MessageAlert.error('이메일을 전송하는데 실패하였습니다.');
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다');
    }
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    const data = searchParams.get('data');
    const decodeData = JSON.parse(decrypt(data));

    if (decodeData) {
      const today = new Date();

      setDecodeValue(decodeData);
      if (decodeData?.tax_bill) {
        setTaxBillData({
          ...decodeData?.tax_bill,
        });
      } else {
        setTaxBillData({
          approval_number: null,
          company_number: decodeData?.company_number,
          chief_name: decodeData?.chief_name,
          name: decodeData?.name,
          address: `${decodeData?.address}\n${
            decodeData?.address_detail ? decodeData?.address_detail : ''
          }`,
          business_type: null,
          type: decodeData?.type,
          email01: decodeData?.email,
          email02: null,
          date: `${moment(today).format('YYYY-MM-DD')}`,
          supply_price: decodeData?.total_price,
          correction: null,
          etc01: null,
          etc02: null,
          month: decodeData?.month,
          day: null,
          item: decodeData?.bill?.program,
          standard: null,
          num: null,
          unit_price: null,
          total_price: decodeData?.total_price,
          cash: null,
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'}>
      <Row>
        <Col x={24}>
          <Content maxWidth={'100%'} padding={0}>
            <table className="taxbill-table">
              <thead>
                <tr>
                  <th colSpan={11}>전자계산서</th>
                  <th colSpan={3}>승인번호</th>
                  <td colSpan={8}>
                    <Input
                      size="large"
                      placeholder={`승인번호를 '-'를 포함하여 입력해주세요`}
                      value={taxBillData?.approval_number}
                      onChange={(e) =>
                        handleChange('approval_number', e.target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th
                    rowSpan={6}
                    className={'title type01'}
                    style={{ width: 66.8 }}
                  >
                    공<br />급<br />자
                  </th>
                  <th colSpan={2} className={'type01'} style={{ width: 133.6 }}>
                    등록번호
                  </th>
                  <td colSpan={3} style={{ width: 200.4 }}>
                    <p>625-88-02197</p>
                  </td>
                  <th colSpan={2} className={'type01'} style={{ width: 133.6 }}>
                    종사업장번호
                  </th>
                  <td colSpan={3} style={{ width: 200.4 }}>
                    <p></p>
                  </td>
                  <th
                    rowSpan={6}
                    className={'title type02'}
                    style={{ width: 66.8 }}
                  >
                    공<br />급<br />받<br />는<br />자
                  </th>
                  <th colSpan={2} className={'type02'} style={{ width: 133.6 }}>
                    등록번호
                  </th>
                  <td colSpan={3} style={{ width: 200.4 }}>
                    <Input
                      size="large"
                      placeholder={`등록번호를 '-'를 포함하여 입력해주세요`}
                      value={taxBillData?.company_number}
                      onChange={(e) =>
                        handleChange('company_number', e.target.value)
                      }
                    />
                  </td>
                  <th colSpan={2} className={'type02'} style={{ width: 133.6 }}>
                    종사업장번호
                  </th>
                  <td colSpan={3} style={{ width: 200.4 }}></td>
                </tr>
                <tr>
                  <th colSpan={2} className={'type01'}>
                    상호
                    <br />
                    (법입명)
                  </th>
                  <td colSpan={3}>
                    <p>(주)브레인힐</p>
                  </td>
                  <th colSpan={2} className={'type01'}>
                    성명
                  </th>
                  <td colSpan={3}>
                    <p>정상원</p>
                  </td>
                  <th colSpan={2} className={'type02'}>
                    상호
                    <br />
                    (법입명)
                  </th>
                  <td colSpan={3}>
                    <p>
                      <Input
                        size="large"
                        placeholder="상호(법인명)을 입력해주세요"
                        value={taxBillData?.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                      />
                    </p>
                  </td>
                  <th colSpan={2} className={'type02'}>
                    성명
                  </th>
                  <td colSpan={3}>
                    <Input
                      size="large"
                      placeholder="성명을 입력해주세요"
                      value={taxBillData?.chief_name}
                      onChange={(e) =>
                        handleChange('chief_name', e.target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={2} className={'type01'}>
                    사업장
                    <br />
                    주소
                  </th>
                  <td colSpan={8}>
                    <p>
                      부산광역시 남구 신선로 365, 10공학관 15호(감만동,
                      부경대학교 용당캠퍼스)
                    </p>
                  </td>
                  <th colSpan={2} className={'type02'}>
                    사업장
                    <br />
                    주소
                  </th>
                  <td colSpan={8}>
                    <Input.TextArea
                      size="large"
                      placeholder="사업장 주소를 입력해주세요"
                      value={taxBillData?.address}
                      onChange={(e) => handleChange('address', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={2} className={'type01'}>
                    업태
                  </th>
                  <td colSpan={3}>
                    <p>서비스업</p>
                  </td>
                  <th colSpan={2} className={'type01'}>
                    종목
                  </th>
                  <td colSpan={3}>
                    <p>평생교육서비스</p>
                  </td>
                  <th colSpan={2} className={'type02'}>
                    업태
                  </th>
                  <td colSpan={3}>
                    <Input
                      size="large"
                      placeholder="업태를 입력해주세요"
                      value={taxBillData?.business_type}
                      onChange={(e) =>
                        handleChange('business_type', e.target.value)
                      }
                    />
                  </td>
                  <th colSpan={2} className={'type02'}>
                    종목
                  </th>
                  <td colSpan={3}>
                    <Input
                      size="large"
                      placeholder="종목을 입력해주세요"
                      value={taxBillData?.type}
                      onChange={(e) => handleChange('type', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={2} rowSpan={2} className={'type01'}>
                    이메일
                  </th>
                  <td colSpan={8} rowSpan={2}>
                    <p>jsw485@naver.com</p>
                  </td>
                  <th colSpan={2} className={'type02'}>
                    이메일
                  </th>
                  <td colSpan={8}>
                    <Input
                      size="large"
                      placeholder="이메일을 입력해주세요"
                      value={taxBillData?.email01}
                      onChange={(e) => handleChange('email01', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={2} className={'type02'}>
                    이메일
                  </th>
                  <td colSpan={8}>
                    <Input
                      size="large"
                      placeholder="이메일을 입력해주세요"
                      value={taxBillData?.email02}
                      onChange={(e) => handleChange('email02', e.target.value)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan={3}>작성일자</th>
                  <th colSpan={5}>공급가액</th>
                  <th colSpan={4}>수정사유</th>
                  <th colSpan={10}>비고</th>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <Input
                      size="large"
                      placeholder="작성일자를 입력해주세요"
                      value={taxBillData?.date}
                      onChange={(e) => handleChange('date', e.target.value)}
                    />
                  </td>
                  <td colSpan={5}>
                    <Input
                      size="large"
                      placeholder="공급가액을 입력해주세요"
                      value={taxBillData?.supply_price}
                      onChange={(e) =>
                        handleChange('supply_price', e.target.value)
                      }
                    />
                  </td>
                  <td colSpan={4}>
                    <Input
                      size="large"
                      placeholder="수정사유를 입력해주세요"
                      value={taxBillData?.correction}
                      onChange={(e) =>
                        handleChange('correction', e.target.value)
                      }
                    />
                  </td>
                  <td colSpan={10}>
                    <Input
                      size="large"
                      placeholder="비고를 입력해주세요"
                      value={taxBillData?.etc01}
                      onChange={(e) => handleChange('etc01', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th style={{ width: 66.8 }}>월</th>
                  <th style={{ width: 66.8 }}>일</th>
                  <th colSpan={4}>품목</th>
                  <th colSpan={2}>규격</th>
                  <th colSpan={3}>수량</th>
                  <th colSpan={3}>단가</th>
                  <th colSpan={5}>공급가액</th>
                  <th colSpan={3}>비고</th>
                </tr>
                <tr>
                  <td>
                    <Input
                      size="large"
                      placeholder="월을 입력해주세요"
                      value={taxBillData?.month}
                      onChange={(e) => handleChange('month', e.target.value)}
                      maxLength={2}
                    />
                  </td>
                  <td>
                    <Input
                      size="large"
                      placeholder="일을 입력해주세요"
                      value={taxBillData?.day}
                      onChange={(e) => handleChange('day', e.target.value)}
                      maxLength={2}
                    />
                  </td>
                  <td colSpan={4}>
                    <Input
                      size="large"
                      placeholder="품목을 입력해주세요"
                      value={taxBillData?.item}
                      onChange={(e) => handleChange('item', e.target.value)}
                    />
                  </td>
                  <td colSpan={2}>
                    <Input
                      size="large"
                      placeholder="규격를 입력해주세요"
                      value={taxBillData?.standard}
                      onChange={(e) => handleChange('standard', e.target.value)}
                    />
                  </td>
                  <td colSpan={3}>
                    <Input
                      size="large"
                      placeholder="수량을 입력해주세요"
                      value={taxBillData?.num}
                      onChange={(e) => handleChange('num', e.target.value)}
                    />
                  </td>
                  <td colSpan={3}>
                    <Input
                      size="large"
                      placeholder="단가를 입력해주세요"
                      value={taxBillData?.unit_price}
                      onChange={(e) =>
                        handleChange('unit_price', e.target.value)
                      }
                    />
                  </td>
                  <td colSpan={5}>
                    <Input
                      size="large"
                      placeholder="공급가액을 입력해주세요"
                      value={taxBillData?.supply_price}
                      onChange={(e) =>
                        handleChange('supply_price', e.target.value)
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <Input
                      size="large"
                      placeholder="비고를 입력해주세요"
                      value={taxBillData?.etc02}
                      onChange={(e) => handleChange('etc02', e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan={6}>합계금액</th>
                  <th colSpan={5}>현금</th>
                  <th>수표</th>
                  <th>어음</th>
                  <th colSpan={5}>외상미수금</th>
                  <td colSpan={3} rowSpan={2}>
                    이 금액을 (청구)함
                  </td>
                </tr>
                <tr>
                  <td colSpan={6}>
                    <Input
                      size="large"
                      placeholder="합계를 입력해주세요"
                      value={taxBillData?.total_price}
                      onChange={(e) =>
                        handleChange('total_price', e.target.value)
                      }
                    />
                  </td>
                  <td colSpan={5}>
                    <Input
                      size="large"
                      placeholder="현금을 입력해주세요"
                      value={taxBillData?.cash}
                      onChange={(e) => handleChange('cash', e.target.value)}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td colSpan={5}></td>
                </tr>
              </tbody>
            </table>
            <Button
              style={{ width: '60%', margin: '30px auto', height: 54 }}
              onClick={() => handleSendEmail('A')}
            >
              메일발송
            </Button>
          </Content>
        </Col>
      </Row>
    </Content>
  );
};

export default TaxBillLayout;
