/**
 *
 *
 */

// React
import React from 'react';
import Routemap from 'routes';
// Router
import { useLocation } from 'react-router-dom';
// Redux
// import { MemberApi } from 'api';
// import MessageAlert from 'utils/MessageAlert';
// import { getCookie } from 'utils';
// import { actions as MemberActions } from 'redux/member/member.reducer';
import queryString from 'query-string';
import { DOMAIN_URL } from 'utils';
/**
 * [Component] App
 * --
 */
function App() {
  /* ===== Initial ===== */
  // const dispatch = useDispatch();
  // const navigation = useNavigate();
  // const { pathname } = useLocation();
  // const serviceType = pathname.split('/')[1];

  const { pathname } = useLocation();
  const { search } = window.location;
  const qs = queryString.parse(search);
  if (qs.paymentKey && qs.paymentKey !== null) {
    window.location.href = `${DOMAIN_URL}/#${pathname}${search}`;
  }

  /* ===== State ===== */

  /* ===== Functions ===== */
  /* ===== Hooks ===== */

  /**
   * 인증 훅
   * --
   */
  // useEffect(() => {
  //   const call = async () => {
  //     const oat = getCookie('ONEGAI_ACCESS_TYPE');
  //     try {
  //       const result = await MemberApi.authentication();
  //       // if (!result) throw { result };
  //       if (!result) throw result;
  //     } catch (e) {
  //       dispatch(MemberActions.logout());
  //       MessageAlert.error('인증이 만료되었습니다. 다시 로그인해주세요.');
  //       if (oat === 'admin') {
  //         navigation('/admin/login');
  //       } else {
  //         navigation('/mobile/login');
  //       }
  //     }
  //   };
  //   serviceType === 'mobile' && call();
  //   // []
  // }, [dispatch, navigation, serviceType]);

  /* ===== Render ===== */
  return <Routemap />;
}

export default App;
