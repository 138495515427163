import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 강의 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetLectures: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getLectures', params] : 'getLectures',
        async () => await $http.get(APIConstant.GET_LECTURES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetLecture: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_LECTURE, { lecture_id: idx });
      const res = useQuery(
        params ? ['getLecture', params] : 'getLecture',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 추천 강의 조회
   * --
   * @method GET
   * @returns
   */
  GetRecommendLectures: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getRecommendLectures', params] : 'getRecommendLectures',
        async () => await $http.get(APIConstant.GET_RECOMMEND_LECTURES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 생성
   * --
   * @method POST
   * @returns
   */
  CreateLecture: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_LECTURE, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getLectures');
            queryClient.invalidateQueries('getLecture');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateLecture: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_LECTURE, {
            lecture_id: data.lecture_id,
          });
          await $http.put(url, data);
        },
        {
          ...options,
          onSuccess: () => {
            if (options.onSuccess) {
              options.onSuccess();
            }
            queryClient.invalidateQueries('getLectures');
            queryClient.invalidateQueries('getLecture');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteLecture: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_LECTURE, {
            lecture_id: data.lecture_id,
          });
          await $http.delete(url, params);
        },
        {
          ...options,
          onSuccess: () => {
            if (options.onSuccess) {
              options.onSuccess();
            }
            queryClient.invalidateQueries('getLectures');
            queryClient.invalidateQueries('getLecture');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
