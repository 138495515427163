/*
 * 에러표시 텍스트 컴포넌트
 * --
 * date: 2022-10-20
 * writer: Owen
 */
import React from 'react';

const StatusText = ({ status, statusText, statusIcon, statusStyle }) => {
  const styles = {
    textCon: {
      marginTop: '5px',
      height: '16px',
      display: 'flex',
      alignItems: 'center',
    },
    textP: {
      margin: 0,
      fontSize: '12px',
      paddingRight: '5px',
      color:
        status === 'Warning' || status === 'warning'
          ? '#FFE146'
          : status === 'Error' || status === 'error'
          ? '#FF6262'
          : status === 'Loading' || status === 'loading'
          ? '#03A9F4'
          : status === 'Success' || (status === 'success' && '#40DD7F'),
    },
    textSpan: {
      fontSize: '12px',
      color:
        status === 'Warning' || status === 'warning'
          ? '#FFE146'
          : status === 'Error' || status === 'error'
          ? '#FF6262'
          : status === 'Loading' || status === 'loading'
          ? '#03A9F4'
          : status === 'Success' || (status === 'success' && '#40DD7F'),
    },
  };

  return (
    <div style={{ ...styles.textCon, ...statusStyle }}>
      {statusIcon ? (
        <p style={styles.textP}>
          <span>{statusIcon}</span>
        </p>
      ) : null}
      <span style={styles.textSpan}>{statusText}</span>
    </div>
  );
};

StatusText.defaultProps = {
  status: 'default',
  statusText: '',
  statusIcon: null,
  statusStyle: {},
};

export default StatusText;
