/*
 * 카테고리 목록 컴포넌트
 * --
 * date: 2022-09-21
 * writer: Owen
 */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './Category.style.css';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const Category = ({ items, value, setValue, style, more }) => {
  /* ===== REF ===== */
  const outsideRef = useRef(null);
  const itemRef = useRef([]);
  const scrollContainer = useRef(null);

  /* ===== STATE ===== */
  // 더보기
  const [isClick, setIsClick] = useState(false);
  // 스크롤 높이
  const [scrollHeight, setScrollHeight] = useState(0);

  /* ===== FUNCTION ===== */
  /**
   * box 밖 영역 클릭 시 닫기
   * --
   */
  const handleClickOutside = useCallback(
    (e) => {
      if (isClick && !outsideRef.current.contains(e.target)) {
        setIsClick(false);
      }
    },
    [isClick]
  );

  /**
   * 해당 아이템으로 포커스
   * --
   */
  const handleScroll = (index) => {
    itemRef.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  /**
   * 컨테이너 스크롤 감지
   * --
   */
  const handleScrollElement = () => {
    setScrollHeight(scrollContainer.current.scrollLeft);
  };

  /* ===== RENDER ===== */
  /**
   * 이벤트 등록
   * --
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  /**
   * 스크롤 이벤트 등록
   * --
   */
  useEffect(() => {
    const element = scrollContainer.current;

    if (element) {
      element.addEventListener('scroll', handleScrollElement);

      return () => {
        element.removeEventListener('scroll', handleScrollElement);
      };
    }
  }, []);

  return (
    <div className="category-layout" style={{ ...style }}>
      {more && (
        <>
          <div
            className="gradient left-gradient"
            style={{ display: scrollHeight === 0 ? 'none' : 'block' }}
          ></div>
          <div
            className="gradient right-gradient"
            style={{
              display:
                scrollHeight + scrollContainer?.current?.clientWidth + 1 ===
                scrollContainer?.current?.scrollWidth
                  ? 'none'
                  : 'block',
            }}
          ></div>
        </>
      )}

      <ul
        ref={scrollContainer}
        style={{ padding: scrollHeight === 0 ? 0 : '0 50px' }}
      >
        {items.map((item, index) => (
          <li
            key={index + '_' + index}
            ref={(element) => (itemRef.current[index] = element)}
          >
            <div
              className="category_radio_button"
              onClick={item?.onClick && item?.onClick}
            >
              <input
                id={item.value}
                className="category_radio_input"
                type="radio"
                name="radio_group"
                value={item.value}
                checked={value === item.value ? true : false}
                onChange={(e) => setValue(e.target.value)}
              />
              <label htmlFor={item.value} className="category_radio_label">
                {item.label}
              </label>
            </div>
            {/* <p style={{ fontSize: `${fontSize}`, fontWeight: `${fontWeight}` }}>
              {item + unit}
            </p> */}
          </li>
        ))}
      </ul>
      {more && (
        <div className="more-view">
          <div
            onClick={() => setIsClick(!isClick)}
            style={{
              borderColor: isClick ? '#AB5476' : '#BDBDBD',
              background: isClick ? '#F2D3DB' : '#FFFFFF',
            }}
          >
            {isClick ? (
              <UpOutlined style={{ color: '#AB5476' }} />
            ) : (
              <DownOutlined style={{ color: '#999999' }} />
            )}
          </div>
        </div>
      )}

      {isClick ? (
        <div className="more-view-item-layout" ref={outsideRef}>
          <ul>
            {items.map((item, index) => (
              <li key={index + '_' + index}>
                <div
                  className="category_radio_button"
                  onClick={() => handleScroll(index)}
                >
                  <input
                    id={`more_${item.value}`}
                    className="category_radio_input"
                    type="radio"
                    name="more_radio_group"
                    value={`more_${item.value}`}
                    checked={value === item.value ? true : false}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <label htmlFor={item.value} className="category_radio_label">
                    {item.label}
                  </label>
                </div>
                {/* <p style={{ fontSize: `${fontSize}`, fontWeight: `${fontWeight}` }}>
              {item + unit}
            </p> */}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

Category.defaultProps = {
  items: [
    {
      label: '아이템1',
      value: '아이템1',
    },
    {
      label: '아이템2',
      value: '아이템2',
    },
    {
      label: '아이템3',
      value: '아이템3',
    },
  ],
  setValue: () => {},
  more: true,
  // defaultValue: items[0].value,
};

Category.propTypes = {
  items: PropTypes.array,
  unit: PropTypes.string,
};

export default Category;
