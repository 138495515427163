/**
 * 회원가입 - 이용약관 페이지
 * --
 */
import React from 'react';
import { Content, Row, Col, Title, Card } from 'components';
import { Button, Divider } from 'antd';
import certificate from 'assets/certificate_brainheal.png';
import { useNavigate, useLocation } from 'react-router-dom';
import MessageAlert from 'utils/MessageAlert';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useState } from 'react';

function Certificate(props) {
  /* ===== ROUTE ===== */
  const location = useLocation();
  let from = location.state?.from?.pathname;

  const navigate = useNavigate();
  /* ===== STATE ===== */
  const data = location.state?.data;
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  /* ===== FUNCTION ===== */
  async function printPDF(id, name) {
    html2canvas(document.getElementById(id), {
      onclone: (document) => {
        document.getElementById('print').style.visibility = 'hidden';
      },
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm');
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var position = 0;
        var pageHeight = 295;
        pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        //화면이 넘칠경우 다음페이지 생성
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(`${name}.pdf`);
      })
      .catch((error) => console.log(error));
  }

  // console.log('data', data);
  /* ===== HOOKS ===== */
  useEffect(() => {
    if (from === undefined) {
      navigate('/');
      MessageAlert.warning('잘못된 접근입니다.');
    }
  });

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);
  /* ===== RENDER ===== */
  return (
    <>
      <Content maxWidth={'100%'} height={'880px'} padding={'12px'}>
        <Row>
          <Col x={screenSize < 1044 ? 24 : 16}>
            <div
              style={{
                width: '595px',
                height: '830px',
                display: 'flex',
                margin: 'auto',
                background: `url(${certificate}) no-repeat center`,
                backgroundSize: 'contain',
                border: '1px solid #d8ae57',
              }}
              className={'completeContent'}
              id={'PDF'}
            >
              <Row
                style={{
                  width: '595px',
                  height: '830px',
                  padding: '100px',
                  paddingTop: '75px',
                }}
              >
                <Col
                  x={24}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    padding: 20,
                  }}
                >
                  <Row>
                    <Col
                      x={24}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        fontSize: '16px',
                      }}
                    >
                      <p>증서번호 : {data?.certificate_num}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      x={24}
                      style={{
                        paddingTop: 30,
                        paddingBottom: 40,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '32px',
                        fontWeight: '600',
                      }}
                    >
                      <p>
                        {data?.major_name ? data?.major_name : '강사 수료증'}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ fontSize: '17px', paddingBottom: '3px' }}>
                    <Col x={8} style={{ paddingLeft: 5 }}>
                      <p>등 &nbsp; &nbsp; 급 : </p>
                    </Col>
                    <Col x={14}>
                      <p>{data?.difficulty_level} </p>
                    </Col>
                  </Row>
                  <Row style={{ fontSize: '17px', paddingBottom: '3px' }}>
                    <Col x={8} style={{ paddingLeft: 5 }}>
                      <p>성 &nbsp; &nbsp; 명 : </p>
                    </Col>
                    <Col x={14}>
                      <p>{data?.user_name}</p>
                    </Col>
                  </Row>
                  <Row style={{ fontSize: '17px', paddingBottom: '3px' }}>
                    <Col x={8} style={{ paddingLeft: 5 }}>
                      <p>생 년 월 일 : </p>
                    </Col>
                    <Col x={14}>
                      <p>{data?.birthday}</p>
                    </Col>
                  </Row>
                  <Row style={{ fontSize: '17px', paddingBottom: '3px' }}>
                    <Col x={8} style={{ paddingLeft: 5 }}>
                      <p>자 격 번 호 : </p>
                    </Col>
                    <Col x={14}>
                      <p>{data?.certificate_num}</p>
                    </Col>
                  </Row>
                  <Row style={{ fontSize: '17px', paddingBottom: '3px' }}>
                    <Col x={8} style={{ paddingLeft: 5 }}>
                      <p>종 &nbsp; &nbsp; 목 : </p>
                    </Col>
                    <Col x={14}>
                      <p>{data?.category_name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      x={24}
                      style={{
                        paddingTop: 25,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: '600',
                        lineHeight: '2rem',
                      }}
                    >
                      <p>위 사람은 (주)브레인힐 자격규정에 따라</p>
                      <p>{data?.major_name} 교육과정을 성실히 이수하고</p>
                      <p>소정의 자격검정시험에 합격 하였으므로</p>
                      <p>본 자격증을 드립니다. </p>

                      <p style={{ paddingTop: 40 }}>{data?.day}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col x={1}></Col>
          <Col x={5}>
            <br />
            <br />
            <Card
              shadow
              style={{ display: screenSize < 1044 ? 'none' : '' }}
              hoverable={false}
            >
              <Title size={3} align="center" style={{ padding: '10px 8px' }}>
                {data?.major_name}
              </Title>
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <small>자격 번호 : {data?.certificate_num}</small>
              </div>

              <div
                style={{
                  textAlign: 'center',
                  marginTop: 15,
                }}
              >
                <p
                  style={{
                    padding: '4px 8px',
                    fontSize: '16px',
                    width: '50%',
                    margin: 'auto',
                    fontWeight: 500,
                    border: '1px solid #AB5476',
                    borderRadius: '4px',
                  }}
                >
                  {data?.difficulty_level}
                </p>
                <p
                  style={{
                    marginTop: 10,
                    padding: '4px 8px',
                    fontSize: '16px',
                    width: '50%',
                    margin: '10px auto',
                    fontWeight: 500,
                    border: '1px solid #e3a4af',
                    borderRadius: '4px',
                  }}
                >
                  {data?.category_name}
                </p>
              </div>
              <Divider style={{ margin: '20px 0' }} />
              <Button
                block
                id="print"
                size="large"
                type="primary"
                style={{
                  marginTop: 7,
                  background: '#AB5476',
                  border: 'none',
                }}
                //다이나믹 임포트로 가져오기
                onClick={async () => {
                  await printPDF('PDF', 'Project');
                }}
              >
                PDF로 저장하기
              </Button>
            </Card>
          </Col>
          <Col x={2}></Col>
        </Row>
      </Content>
      <div
        style={{
          width: '60%',
          margin: '0px auto',
          display: screenSize < 1044 ? '' : 'none',
        }}
      >
        <Button
          block
          id="print"
          size="large"
          type="primary"
          style={{
            marginTop: 7,
            background: '#AB5476',
            border: 'none',
            width: '100%',
            height: '50px',
          }}
          //다이나믹 임포트로 가져오기
          onClick={async () => {
            await printPDF('PDF', 'Certificate_brainheal');
          }}
        >
          PDF로 저장하기
        </Button>
      </div>
    </>
  );
}

Certificate.defaultProps = {};

export default Certificate;
