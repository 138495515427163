import React, { useState, useEffect, useMemo } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  Calendar,
  Button,
  ModalLayout,
} from 'components';
import { List, Button as Btn, Input, Avatar, Statistic } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { encrypt, decrypt } from 'utils/Crypto';
import UseDebounce from 'utils/useDebounce';
import { CalculateApi, ScheduleApi } from 'api';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { handleDeleteCheck, DOMAIN_URL } from 'utils';
import moment from 'moment';

const AgencySchedulesPresenter = (props) => {
  /* ===== Props ===== */
  const { teachers, agencies, categories } = props;

  /* ===== Router ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');

  /* ===== State ===== */
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = UseDebounce(searchValue, 300);
  const [filterAgenecyList, setFilterAgencyList] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [selectAgencyId, setSelectAgencyId] = useState(0);
  const [eventItems, setEventItems] = useState([]);
  const [selectAgencyInfo, setSelectAgencyInfo] = useState({});
  // 기관 Select 아이템
  const [agencyList, setAgencyList] = useState([]);
  // 강의 일지 모달
  const [journalModal, setJournalModal] = useState(false);
  const [journalData, setJournalData] = useState([]);

  /* ===== MUTATE ===== */
  const { data: schedules, refetch: scheduleRefetch } =
    ScheduleApi.GetSchedules(
      {
        agency_id: parseInt(selectAgencyId),
        date: `${year}-${month}`,
      },
      {
        enabled: selectAgencyId && year && month ? true : false,
      }
    );
  const { data: calculate, refetch: calculateRefetch } =
    CalculateApi.GetCalculates(
      {
        agency_id: parseInt(selectAgencyId),
        date: `${year}-${month}`,
      },
      {
        enabled: selectAgencyId && year && month ? true : false,
      }
    );

  /**
   * 캘린더 아이템 정제
   * --
   */
  const events = useMemo(() => {
    const newTeachers = teachers;

    const newSchedules = schedules?.data?.map((item) => ({
      ...item,
      ...(newTeachers &&
        newTeachers?.filter((subitem) => subitem.user_id === item.user_id)[0]),
      category_name: categories?.filter(
        (subitem) => subitem?.category_id === item?.category_id
      )[0]?.category_name,
    }));

    const s = newSchedules?.map((item, index) => ({
      id: item?.schedule_id,
      title: `${item?.category_name} (${item?.name})`,
      start: new Date(item?.start_date),
      end: new Date(item?.end_date),
      user_confirm: item?.user_confirm,
      agency_confirm: item?.agency_confirm,
      type: item?.schedule_type,
      content: item?.content,
      name: `${item?.name}`,
      email: item?.email,
      phone: item?.phone,
      file: item?.file,
      chief_name: item?.chief_name,
      address: item?.agency_address,
      address_detail: item?.agency_address_detail,
      agency_id: item?.agency_id,
      registrant: item?.registrant,
      user_id: item?.user_id,
      category_id: item?.category_id,
      category_name: item?.category_name,
      is_calculate: handleDeleteCheck(item, calculate?.data),
      repetition_schedule_id: item?.repetition_schedule_id,
    }));
    return s;
  }, [schedules, teachers, categories, calculate]);

  /* ===== Hooks ===== */
  /**
   * 선택한 기관 스케쥴 데이터 호출
   * --
   */
  useEffect(() => {
    scheduleRefetch();
    calculateRefetch();
  }, [selectAgencyId, year, month, scheduleRefetch, calculateRefetch]);

  /**
   * 기관목록 정제
   * --
   */
  useEffect(() => {
    setAgencyList(agencies);
    setSelectAgencyId(agencies?.length ? agencies[0]?.agency_id : 0);
  }, [agencies]);

  /**
   * 선택한 기관 라우팅
   * --
   */
  useEffect(() => {
    const agencyId = searchParams.get('agency');

    if (agencyId) {
      const id = decrypt(agencyId);
      setSelectAgencyId(Number(id));
    } else {
      setSelectAgencyId(agencyList?.length ? agencyList[0]?.agency_id : 0);
    }
  }, [navigate, searchParams, agencyList]);

  /**
   * 캘린더 아이템 렌더링
   * --
   */
  useEffect(() => {
    setEventItems(events);
  }, [events]);

  /**
   * 기관 검색
   * --
   */
  useEffect(() => {
    const list = agencyList;
    const filterList = list?.filter((item) =>
      item?.name?.includes(debouncedValue)
    );

    setFilterAgencyList(filterList);
  }, [debouncedValue, agencyList]);

  /**
   * 선택한 기관 정보 정제
   * --
   */
  useEffect(() => {
    if (selectAgencyId) {
      const agency = agencyList?.filter(
        (item) => item?.agency_id === selectAgencyId
      )[0];

      setSelectAgencyInfo(agency);
    }
  }, [selectAgencyId, agencyList]);

  /**
   * 날짜 querystring 값 정제
   * --
   */
  useEffect(() => {
    const splitDate = date?.split('-');
    setYear(parseInt(splitDate[0]));
    setMonth(parseInt(splitDate[1]));
  }, [date]);

  /**
   * 강의 일지 데이터 정제
   * --
   */
  useEffect(() => {
    const newSchedules = schedules?.data?.map((item) => ({
      ...item,
      category_name: categories?.filter(
        (subitem) => subitem?.category_id === item?.category_id
      )[0]?.category_name,
      agency_name: agencies?.filter(
        (subitem) => subitem?.agency_id === item?.agency_id
      )[0]?.name,
      address: agencies?.filter(
        (subitem) => subitem?.agency_id === item?.agency_id
      )[0]?.address,
      agency_signature: agencies?.filter(
        (subitem) => subitem?.agency_id === item?.agency_id
      )[0]?.signature,
      teacher_name: teachers?.filter(
        (subitem) => subitem?.user_id === item?.user_id
      )[0]?.name,
      signature: teachers?.filter(
        (subitem) => subitem?.user_id === item?.user_id
      )[0]?.signature,
    }));
    let arr = [];
    newSchedules?.forEach((item, index) => {
      if (!item?.repetition_schedule_id) {
        arr.push({ key: `null-${index}`, data: [item] });
      } else {
        const ext = arr.findIndex(
          (subitem) => subitem?.key === `${item?.repetition_schedule_id}`
        );
        if (ext === -1) {
          arr.push({ key: `${item?.repetition_schedule_id}`, data: [item] });
        } else {
          const newData = [...arr[ext]?.data, item];
          arr[ext].data = newData;
        }
      }
    });
    setJournalData(arr);
  }, [schedules, categories, agencies, teachers]);

  /* ===== Functions ===== */
  /**
   * 날짜 값 변경
   * --
   */
  const handleDate = (type) => {
    let m = month,
      y = year;
    if (type === 'prev') {
      --m;

      if (m === 0) {
        m = 12;
        --y;
      }
    } else {
      ++m;

      if (m > 12) {
        m = 1;
        ++y;
      }
    }

    navigate(
      `/admin/agency-schedules?date=${y}-${m}${
        selectAgencyId &&
        `&agency=${encodeURIComponent(encrypt(selectAgencyId))}`
      }`
    );
  };

  /**
   * 팝업 메시지
   * --
   */
  const handlePopupMessage = (event) => {
    if (event.data === 'refresh') {
      queryClient.invalidateQueries('getSchedules');
    }
  };

  /**
   * 강의 일지 팝업 핸들러
   * --
   */
  const handlePopup = (data) => {
    const popData = data[0];
    const domain = DOMAIN_URL;
    const popupData = {
      program: popData?.category_name,
      agency_name: popData?.agency_name,
      teacher_name: popData?.teacher_name,
      address: popData?.address,
      year: year,
      month: month,
      start_time: moment(popData?.start_date).format('HH:mm'),
      end_time: moment(popData?.end_date).format('HH:mm'),
      schedules: [...data],
    };
    const encodeData = encrypt(JSON.stringify(popupData));
    window.open(
      `${domain}/#/popup/journal?data=${encodeURIComponent(encodeData)}`,
      '팝업',
      'width=1200,height=800'
    );
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={0} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={6} style={{ padding: 5 }}>
            <Content maxWidth={'100%'} className={'admin_container'}>
              <Title>기관목록</Title>
              <Input
                size="large"
                placeholder="기관명을 입력해주세요"
                style={{ marginTop: 30 }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Content maxWidth={'100%'} padding={0} style={{ marginTop: 30 }}>
                <Row>
                  <Col x={24}>
                    <List
                      style={{
                        background: '#F5F5F5',
                        border: '1px solid #E0E0E0',
                        borderRadius: 5,
                        height: 1000,
                        overflowY: 'scroll',
                      }}
                    >
                      {filterAgenecyList?.map((item) => (
                        <div
                          key={`agency-${item?.agency_id}-${item?.name}`}
                          onClick={() =>
                            navigate(
                              `/admin/agency-schedules?date=${year}-${month}&agency=${encodeURIComponent(
                                encrypt(item?.agency_id)
                              )}`
                            )
                          }
                        >
                          <Row
                            style={{
                              borderBottom: '1px solid #E0E0E0',
                              padding: 10,
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              position: 'relative',
                              top: 0,
                              left: 0,
                            }}
                          >
                            {selectAgencyId === item?.agency_id ? (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: `50%`,
                                  left: `50%`,
                                  width: '98%',
                                  height: '95%',
                                  background: '#F2D3DB',
                                  border: '1px solid #AB6576',
                                  borderRadius: 5,
                                  transform: `translate(-50%, -50%)`,
                                }}
                              ></div>
                            ) : null}

                            <Col x={4}>
                              <Avatar size={50}></Avatar>
                            </Col>
                            <Col x={8}>
                              <p
                                style={{
                                  color: '#9E9E9E',
                                  fontSize: 20,
                                  textAlign: 'center',
                                }}
                              >
                                {item?.name}
                              </p>
                            </Col>
                            <Col x={12}>
                              <p
                                style={{
                                  color: '#9E9E9E',
                                  fontSize: 18,
                                }}
                              >
                                {item?.address}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </List>
                  </Col>
                </Row>
              </Content>
            </Content>
          </Col>
          <Col x={18}>
            <Row>
              <Col x={24} style={{ padding: 5 }}>
                <Content maxWidth={'100%'} className={'admin_container'}>
                  <Title>기관정보</Title>
                  <Content
                    maxWidth={'100%'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Row style={{ width: '100%' }}>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'기관명'}
                          value={selectAgencyInfo?.name}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'대표자명'}
                          value={selectAgencyInfo?.chief_name}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'사업자번호'}
                          value={selectAgencyInfo?.company_number}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={4} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'연락처'}
                          value={selectAgencyInfo?.phone}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={6} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'이메일'}
                          value={selectAgencyInfo?.email}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                      <Col x={14} style={{ marginBottom: 10 }}>
                        <Statistic
                          title={'주소'}
                          value={`${
                            selectAgencyInfo?.address
                              ? selectAgencyInfo?.address
                              : '-'
                          }\n${
                            selectAgencyInfo?.address_detail
                              ? selectAgencyInfo?.address_detail
                              : ''
                          }`}
                          valueStyle={{ fontSize: 18 }}
                        />
                      </Col>
                    </Row>
                  </Content>
                </Content>
              </Col>
            </Row>
            <Row>
              <Col x={24} style={{ padding: 5 }}>
                <Content maxWidth={'100%'} className={'admin_container'}>
                  <Content
                    maxWidth={'100%'}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Btn
                      size="large"
                      icon={<LeftOutlined />}
                      onClick={() => handleDate('prev')}
                    />
                    <Title style={{ padding: '0 10px' }}>
                      {year}년 {month}월
                    </Title>
                    <Btn
                      size="large"
                      icon={<RightOutlined />}
                      onClick={() => handleDate('next')}
                    />
                  </Content>
                  <Calendar
                    items={eventItems}
                    height={800}
                    isHeader={false}
                    dateTime={`${year}-${month}`}
                    detail
                    update
                    add
                    program
                    onPopupMessage={handlePopupMessage}
                  />
                  <Content
                    maxWidth={'100%'}
                    padding={8}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <Button
                        layoutStyle={{ margin: 0 }}
                        onClick={() => setJournalModal(!journalModal)}
                      >
                        {month}월 강의 일지
                      </Button>
                    </div>
                  </Content>
                </Content>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      {/* 활동일지 리스트 모달 */}
      <ModalLayout
        title={'강의 일지'}
        type={'drawer'}
        open={journalModal}
        onCancel={setJournalModal}
        placement={'right'}
        width={500}
        closable
        bodyStyle={{ padding: '15px' }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              {journalData?.map((item) => (
                <div
                  key={item?.key}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #EEEEEE',
                    margin: '5px 0',
                    padding: '20px 10px',
                  }}
                >
                  <div>
                    <Title>{item?.data[0]?.category_name}</Title>
                    <p style={{ fontSize: 18 }}>
                      {item?.data[0]?.teacher_name}
                    </p>
                  </div>
                  <Button
                    layoutStyle={{ margin: 0 }}
                    onClick={() => handlePopup(item?.data)}
                  >
                    보기
                  </Button>
                </div>
              ))}
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

AgencySchedulesPresenter.defaultProps = {};

export default AgencySchedulesPresenter;
