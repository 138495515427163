import React from 'react';
import SmsLogPresenter from './SmsLogsPresenter';
const SmsLogContainer = ({ data }) => {
  /* ===== PROPS ===== */

  /* ===== STATE ===== */

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */

  /* ===== RENDER ===== */
  return (
    <SmsLogPresenter
      users={data?.users}
      agency={data?.agencies}
      teacher={data?.teachers}
    />
  );
};

export default SmsLogContainer;
