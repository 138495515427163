/**
 * 라벨 컴포넌트
 * --
 * date: 2022-10-18
 * writer: Owen
 */
import React from 'react';

const Label = ({ htmlFor, labelStyle, label, essential }) => {
  return (
    <>
      <label htmlFor={htmlFor} style={labelStyle}>
        {label}
        {essential && (
          <span style={{ color: '#FB4E4E', padding: '0 5px' }}>*</span>
        )}
      </label>
    </>
  );
};

Label.defaultProps = {
  htmlFor: '',
  labelStyle: {},
  label: '',
  essential: false,
};

export default Label;
