import React, { useState, useEffect } from 'react';
import EventListPresenter from './EventListPresenter';

const EventListContainer = ({ data, screenSize }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const [newData, setNewData] = useState([]);

  // console.log('Data', Data);
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    if (data) {
      const { events } = data;

      const newEvents = events?.map((item) => ({
        ...item,
      }));

      setNewData(newEvents);
    }
  }, [data]);
  /* ===== RENDER ===== */
  return <EventListPresenter events={newData} screenSize={screenSize} />;
};

EventListContainer.defaultProps = {};

export default EventListContainer;
