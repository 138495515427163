import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox, Radio, Tag } from 'antd';
import {
  Title,
  Content,
  Col,
  Row,
  Card,
  LectureCard,
  BottomLayout,
} from 'components';
import { Divider, Select, Input, Empty } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import MessageAlert from 'utils/MessageAlert';
import {
  ProductCartApi,
  PurchaseApi,
  ProductApi,
  UserApi,
  AgencyApi,
} from 'api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import {
  DOMAIN_URL,
  TOSS_CLIENT_KEY,
  TOSS_CUSTOMER_KEY,
  getCookie,
  getFirstImageFromContent,
} from 'utils';
import { nanoid } from 'nanoid';
import { useRecoilValue } from 'recoil';
import { userInfo, loginUser } from 'atoms/Atoms';
import { encrypt } from 'utils/Crypto';

const styles = {
  cardButton: {
    height: 240,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentCardButton: {
    border: 'none',
    padding: 0,
    borderRadius: 0,
    background: 'none',
  },
  addressCardP: {
    fontSize: 16,
  },
};

const ProductCartPresenter = ({
  user,
  products,
  productCarts,
  productFiles,
  purchases,
  productCategories,
  idx,
  recommend,
  address,
}) => {
  /* ===== Router ===== */
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* ===== Props ===== */

  /* ===== Mutate ===== */
  /**
   * 상품 수량 업데이트
   * --
   */
  const updateProduct = ProductApi.UpdateProduct({
    onSuccess: (data) => {
      try {
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /**
   * 장바구니 삭제
   * --
   */
  const deleteProductCart = ProductCartApi.DeleteProductCart({
    onSuccess: (data) => {
      try {
        MessageAlert.error('삭제되었습니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /**
   * 구매 등록
   * --
   */
  const createPurchase = PurchaseApi.CreatePurchase({
    onSuccess: (data) => {
      try {
        // MessageAlert.error('등록되었습니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /**
   * 포인트 업데이트
   * --
   */
  const updateUser = UserApi.UpdateUser({});
  const updateAgency = AgencyApi.UpdateAgency();

  /* ===== Router ===== */

  /* ===== State ===== */
  // NOTE: TOSSPAYMENTS
  const [paymentWidget, setPaymentWidget] = useState(null);
  const paymentMethodsWidgetRef = useRef(null);

  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const userInfoData = useRecoilValue(userInfo);
  const [numArr, setNumArr] = useState();
  const [recommendList, setRecommendList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  //상품데이터 정제
  const Newproducts = products?.map((item) => ({
    ...item,
    // thumbnail: getFirstImageFromContent(item?.content),
    ...productCategories
      ?.filter(
        (subitem) => item.product_category_id === subitem.product_category_id
      )
      ?.pop(),
  }));
  const NewproductCart = productCarts
    ?.filter((item) =>
      loginWho === 'agency' ? item.agency_id === idx : item.user_id === idx
    )
    .map((subitem) => ({
      ...subitem,
      cart_num: subitem.num,
      ...(
        Newproducts &&
        Newproducts?.filter(
          (lowitem) => subitem.product_id === lowitem.product_id
        )
      )?.pop(),
      selectCheck: 'N',
    }));

  //TODO: 쿠폰, 포인트 어떻게 진행할 건지 확인 필요.
  //REVIEW: 쿠폰관련 요구사항 없었음. 해당 기능 필요시 확장 가능성만 인지.
  const coupon = [
    // {
    //   coupon_id: 1,
    //   value: 1,
    //   coupon_name: '10% 할인쿠폰',
    //   label: '10% 할인쿠폰',
    //   type: 'P',
    // },
    // {
    //   coupon_id: 2,
    //   value: 2,
    //   coupon_name: '10,000원 할인쿠폰',
    //   label: '10,000원 할인쿠폰',
    //   type: 'N',
    // },
  ];
  const userPoint = 0;

  const plainOptions = ['신용카드', '실시간 계좌이체', '무통장입금', '휴대폰'];

  const [paymentMethod, setPaymentMethod] = useState('무통장입금');

  const [couponValue, setCouponValue] = useState(1);
  const [payAgreeCheck, setPayAgreeCheck] = useState(false);

  const [usePoint, setUsePoint] = useState(0);

  // 상품 선택 리스트
  let productItemList = [];
  const ListProductCart = NewproductCart?.filter(
    (item) => item.is_show === 'Y' && (item.num >= 1 || item.num === null)
  );
  ListProductCart?.map((item) => productItemList.push(item));
  const today = new Date();
  // 결제 리스트
  let purchasesList = [];

  purchases
    ?.filter((item) => item.user_id === idx)
    ?.forEach((item) => purchasesList.push(item.product_id));

  const newProductCart = NewproductCart?.filter((item) =>
    purchasesList?.includes(item.product_id)
  );

  purchasesList = newProductCart?.filter((x) => {
    //조건 : 타입이 파일 이면서, 현재 날짜 - 생성일이 7일보다 작을시 (다운로드 기간이 남아있음)
    // F ,
    // today - new Date(alredyPayments?.created_at) <= 7 * 24 * 60 * 60 * 1000
    if (x.sales_type === 'F') {
      const filterPurchace = purchases?.filter(
        (item) => item.product_id === x.product_id
      )[0];
      return (
        today - new Date(filterPurchace?.created_at) <= 7 * 24 * 60 * 60 * 1000
      );
    } else {
      // 타입이 파일이 아닌 것(판매,랜트 등)
      return purchasesList?.includes(x.product_id);
    }
  });

  // 체크박스 기능
  const defaultCheckedList = [];
  const [selectProduct, setSelectProduct] = useState(defaultCheckedList);
  // const [checkAll, setCheckAll] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTitle, setTotalTitle] = useState('상품을 선택해주세요.');
  const [isCash, setIsCash] = useState(null);
  const [cashCheckAll, setCashCheckAll] = useState(false);
  const [pointCheckAll, setPointCheckAll] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  let categoryNameSet = { parent: '', child: '' };

  /* ===== Hooks ===== */
  /**
   * 비회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }
  }, [token, uid, loginWho, navigate]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  useEffect(() => {
    if (recommend) {
      const list = recommend?.map((item) => ({
        ...item,
        thumbnail: getFirstImageFromContent(item?.content),
      }));

      setRecommendList(list);
    }
  }, [recommend]);

  useEffect(() => {
    if (!selectProduct.length) setIsCash(null);
  }, [selectProduct]);

  /* ===== Functions ===== */
  /**
   * 항목개별선택
   * --
   */
  const onChange = (item, price, title, product_cart_id, cash) => {
    if (!isCash) return setIsCash(cash);

    if (isCash && isCash !== cash)
      return MessageAlert.warning(
        '포인트 상품과 현금 상품을 같이 선택할 수 없습니다!'
      );

    const cartPrice =
      numArr?.filter((item) => item.product_cart_id === product_cart_id)
        ?.length > 0
        ? numArr
            ?.filter((item) => item.product_cart_id === product_cart_id)
            ?.pop()?.cart_num * price
        : price;
    // console.log('-------------product_cart_id------------');
    // console.log('product_cart_id', product_cart_id);
    // console.log('-------------cartPrice------------');
    // console.log('cartPrice', cartPrice);

    const list = selectProduct.includes(item)
      ? selectProduct?.filter((e) => e !== item)
      : [...selectProduct, item];

    if (selectProduct.includes(item)) {
      setTotalPrice(totalPrice - cartPrice);
      selectProduct?.length === 1
        ? setTotalTitle('상품을 선택해주세요.')
        : setTotalTitle(
            NewproductCart?.filter((item) => item.product_id === list[0])?.pop()
              ?.product_name
          );
    } else {
      setTotalPrice(totalPrice + cartPrice);
      selectProduct?.length === 0 && setTotalTitle(title);
    }

    const itemList = productItemList?.filter((item) => item.is_cash === isCash);
    if (isCash === 'Y') {
      setCashCheckAll(list.length === itemList.length);
    } else {
      setPointCheckAll(list.length === itemList.length);
    }
    setSelectProduct(list);
  };

  /**
   * 항목전체선택
   * --
   */
  const onCheckAllChange = (e, t) => {
    if (!isCash) setIsCash(t);

    if (isCash && isCash !== t)
      return MessageAlert.warning(
        '포인트 상품과 현금 상품을 같이 선택할 수 없습니다!'
      );

    // 타입에 맞는 아이템 정제
    const itemList = productItemList?.filter((item) => item.is_cash === t);
    const itemIdList = itemList?.map((item) => item.product_id);

    if (!itemList?.length) {
      setIsCash(null);
      return MessageAlert.warning(
        `${t === 'Y' ? '현금' : '포인트'} 상품이 존재하지 않습니다`
      );
    }

    //전체금액 계산
    const totalValue = itemList?.reduce(function (accumulator, currentValue) {
      return (
        accumulator +
        currentValue.price *
          (numArr?.filter(
            (item) => item.product_cart_id === currentValue.product_cart_id
          )?.length > 0
            ? numArr
                ?.filter(
                  (item) =>
                    item.product_cart_id === currentValue.product_cart_id
                )
                ?.pop()?.cart_num
            : 1)
      );
    }, 0);

    if (t === 'Y') {
      setCashCheckAll(e.target.checked);
    } else {
      setPointCheckAll(e.target.checked);
    }
    setSelectProduct(e.target.checked ? itemIdList : []);
    // setCheckAll(e.target.checked);
    if (e.target.checked) {
      setTotalPrice(totalValue);
      setTotalTitle(
        itemList?.length === 1
          ? `${itemList[0]?.product_name}`
          : `${itemList[0]?.product_name} 외 ${itemList?.length - 1}강`
      );
    } else {
      setTotalPrice(0);
      setTotalTitle('상품을 선택해주세요.');
    }
  };

  /**
   * 결제완료 된 장바구니 리스트 삭제
   * --
   * @param {*} 중복된 상품 값 여부 : purchasesList
   */
  const handleDoubleListDelete = () => {
    try {
      alert('이미 결제했던 상품을 제거합니다.');
      purchasesList?.forEach((subitem) => {
        deleteProductCart.mutate({
          product_cart_id: subitem.product_cart_id,
        });
      });
    } catch (e) {
      throw e;
    }
    setSelectProduct([]);
    // setCheckAll(false);
    setCashCheckAll(false);
    setPointCheckAll(false);
    setTotalPrice(0);
    setTotalTitle('상품을 선택해주세요.');
  };

  /**
   * 상품 삭제
   * --
   * @param {*} eventTargetValue : product_cart_id
   */
  const handleDeleteClick = (value) => {
    // console.log('value', value);
    if (window.confirm('해당상품을 삭제하시겠습니까?')) {
      //좋아요 상품 삭제
      // console.log('삭제:', value);
      try {
        deleteProductCart.mutate({
          product_cart_id: value,
        });
      } catch (e) {
        throw e;
      }
      setSelectProduct([]);
      // setCheckAll(false);
      setCashCheckAll(false);
      setPointCheckAll(false);
      setTotalPrice(0);
      setTotalTitle('상품을 선택해주세요.');
    } else {
      console.log('삭제취소:', value);
    }
  };

  /**
   * 배송지 팝업
   * --
   */
  const handleOpenPopup = (id) => {
    const domain = DOMAIN_URL;
    if (!id) {
      window.open(
        `${domain}/#/popup/delivery-address`,
        '팝업',
        'width=500,height=600'
      );
      setIsOpened(true);
    } else {
      window.open(
        `${domain}/#/popup/delivery-address?address=${encodeURIComponent(
          encrypt(id)
        )}`,
        '팝업',
        'width=500,height=600'
      );
      setIsOpened(true);
    }
  };

  /**
   * 선택한 상품리스트 결제
   * --
   * @param {*} selectProduct
   */
  const handleOrder = async () => {
    // if (true) {
    //   NewproductCart.filter((item) =>
    //     selectProduct.includes(item.product_id)
    //   ).forEach((subitem) => {
    //     console.log(subitem, 'dd');
    //   });
    // } else {
    //선택 상품 필터링
    // console.log(addressValue);
    const selectList = NewproductCart.filter((item) =>
      selectProduct.includes(item.product_id)
    );
    //세일즈 타입 검사
    const checkSalesType = selectList.some((item) =>
      ['R', 'S'].includes(item.sales_type)
    );

    if (purchasesList?.length > 0) {
      handleDoubleListDelete();
      return;
    } else if (selectProduct.length === 0) {
      MessageAlert.warning('상품을 선택해주세요.');
      return;
    } else if (!payAgreeCheck) {
      MessageAlert.warning('결제 정보에 동의해주세요.');
      return;
    } else if (checkSalesType && !addressValue) {
      MessageAlert.warning(
        '배송지가 필요한 상품이 존재합니다.\n배송지를 선택해주세요.'
      );
      return;
    }

    if (
      window.confirm(
        '파일 상품일 경우 환불이 불가능합니다\n선택한 상품을 결제하시겠습니까?'
      )
    ) {
      // 현금 결제 상품일 경우
      if (isCash === 'Y') {
        if (totalPrice === 0) {
          try {
            NewproductCart.filter((item) =>
              selectProduct.includes(item.product_id)
            ).forEach((subitem) => {
              // 구매 내역 생성
              createPurchase.mutate({
                user_id: subitem?.user_id ? subitem?.user_id : null,
                agency_id: subitem?.agency_id ? subitem?.agency_id : null,
                product_id: subitem.product_id,
                purchase_price: subitem.price,
                num:
                  numArr?.filter(
                    (i) => i.product_cart_id === subitem.product_cart_id
                  ).length > 0
                    ? numArr
                        ?.filter(
                          (i) => i.product_cart_id === subitem.product_cart_id
                        )
                        ?.pop()?.cart_num
                    : subitem.cart_num,
                purchase_state: 'P',
                delivery:
                  checkSalesType && subitem?.sales_type !== 'F'
                    ? address?.filter(
                        (item) => item?.delivery_address_id === addressValue
                      )[0]
                    : null,
                rent_state: subitem?.sales_type === 'F' ? null : 'P',
              });

              // // 상품 수량 업데이트
              updateProduct.mutate({
                product_id: subitem.product_id,
                num:
                  subitem.num === null
                    ? null
                    : numArr?.filter(
                        (i) => i.product_cart_id === subitem.product_cart_id
                      ).length > 0
                    ? subitem.num -
                      numArr
                        ?.filter(
                          (i) => i.product_cart_id === subitem.product_cart_id
                        )
                        ?.pop()?.cart_num
                    : subitem.num - subitem.cart_num,
              });
              // 장바구니에서 삭제
              deleteProductCart.mutate({
                product_cart_id: subitem?.product_cart_id,
              });
            });
            navigate('/mypage/product/purchase');
          } catch (e) {
            throw e;
          }
        } else {
          // const paymentWidget = paymentWidgetRef.current;
          const selectList = NewproductCart?.filter((item) =>
            selectProduct.includes(item.product_id)
          )?.map((item) => ({
            ...item,
            cart_num: numArr?.filter(
              (subitem) => item?.product_cart_id === subitem?.product_cart_id
            )[0]?.cart_num,
          }));

          localStorage.setItem('orderList', JSON.stringify(selectList));

          localStorage.setItem(
            'orderAddress',
            address?.length > 0
              ? JSON.stringify(
                  address?.filter(
                    (item) => item?.delivery_address_id === addressValue
                  )[0]
                )
              : null
          );
          try {
            const orderName =
              selectList?.length > 1
                ? `${selectList[0].product_name} 외 ${selectList.length - 1}건`
                : `${selectList[0].product_name}`;
            await paymentWidget
              ?.requestPayment({
                orderId: nanoid(),
                orderName: `${orderName}`,
                customerName: `${userInfoData.name}`,
                customerEmail: `${userInfoData.email}`,
                successUrl: `${DOMAIN_URL}/#/success`,
                failUrl: `${DOMAIN_URL}/#/fail`,
              })
              .catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                  // 결제 고객이 결제창을 닫았을 때 에러 처리
                }
                if (error.code === 'INVALID_CARD_COMPANY') {
                  // 유효하지 않은 카드 코드에 대한 에러 처리
                }
              });
          } catch (error) {
            // 에러 처리하기
            console.error(error);
          }
        }
      } else {
        // 포인트가 부족할 경우 처리
        if (parseInt(totalPrice) > parseInt(user?.point))
          return MessageAlert.warning(
            '포인트가 부족합니다! 충전 후 다시 이용해주세요'
          );
        try {
          NewproductCart.filter((item) =>
            selectProduct.includes(item.product_id)
          ).forEach((subitem) => {
            // 구매 내역 생성
            createPurchase.mutate({
              user_id: subitem?.user_id ? subitem?.user_id : null,
              agency_id: subitem?.agency_id ? subitem?.agency_id : null,
              product_id: subitem.product_id,
              purchase_price: subitem.price,
              num:
                numArr?.filter(
                  (i) => i.product_cart_id === subitem.product_cart_id
                ).length > 0
                  ? numArr
                      ?.filter(
                        (i) => i.product_cart_id === subitem.product_cart_id
                      )
                      ?.pop()?.cart_num
                  : subitem.cart_num,
              purchase_state: 'P',
              delivery:
                checkSalesType && subitem?.sales_type !== 'F'
                  ? address?.filter(
                      (item) => item?.delivery_address_id === addressValue
                    )[0]
                  : null,
              rent_state: subitem?.sales_type === 'F' ? null : 'P',
            });
            // 상품 수량 업데이트
            updateProduct.mutate({
              product_id: subitem.product_id,
              num:
                subitem.num === null
                  ? null
                  : numArr?.filter(
                      (i) => i.product_cart_id === subitem.product_cart_id
                    ).length > 0
                  ? subitem.num -
                    numArr
                      ?.filter(
                        (i) => i.product_cart_id === subitem.product_cart_id
                      )
                      ?.pop()?.cart_num
                  : subitem.num - subitem.cart_num,
            });
            // 포인트 업데이트
            if (loginWho === 'agency') {
              updateAgency.mutate({
                agency_id: idx,
                point: parseInt(user.point) - parseInt(totalPrice),
              });
            } else {
              updateUser.mutate({
                user_id: idx,
                point: parseInt(user.point) - parseInt(totalPrice),
              });
            }

            // 장바구니에서 삭제
            deleteProductCart.mutate({
              product_cart_id: subitem?.product_cart_id,
            });
          });
          navigate('/mypage/product/purchase');
        } catch (e) {
          throw e;
        }
      }
    }
  };

  /**
   * 원화 콤마
   * --
   * @param {*} inputValue
   */
  const handleComma = (v) => {
    return [v].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /**
   * 숫자만 입력
   * --
   * @param {*} inputValue
   */
  const OnlyNumber = (v) => {
    let str = v.replace(/[^0-9]/g, '');
    return str;
  };

  /**
   * 결제방법 선택
   * --
   * @param {*} paymentMethod
   */
  const onChangePayment = ({ target: { value } }) => {
    if (value !== 3) {
      MessageAlert.warning('현재 무통장 입금만 가능합니다.');
    } else {
      setPaymentMethod(value);
    }
  };

  /**
   * 장바구니 선택 삭제
   * --
   * @param {*} 선택한 상품value : selectProduct
   */
  const handleListDelete = () => {
    if (selectProduct.length === 0) {
      MessageAlert.warning('상품을 선택해주세요.');
      return;
    } else if (window.confirm('선택한 상품을 삭제하시겠습니까?')) {
      // 좋아요 깅의리스트 부분
      try {
        NewproductCart.filter((item) =>
          selectProduct.includes(item.product_id)
        ).forEach((subitem) => {
          deleteProductCart.mutate({
            product_cart_id: subitem.product_cart_id,
          });
        });
      } catch (e) {
        throw e;
      }
      setSelectProduct([]);
      setCashCheckAll(false);
      setPointCheckAll(false);
      setTotalPrice(0);
      setTotalTitle('상품을 선택해주세요.');
    } else {
      console.log('삭제취소:');
    }
  };

  /**
   * 수량 제어
   * @param {*} product_cart_id
   * @param {*} cart_num 담은 수량
   * @param {*} type - 1 : '+', 2 : '-', 3: e.target.value
   * @param {*} num 남은 수량
   * @param {*} price 상품단가
   * @param {*} product_id 상품idx
   */
  const handleCartNum = (
    product_cart_id,
    cart_num,
    type,
    num,
    price,
    product_id
  ) => {
    if (cart_num < 1 || (cart_num === 1 && type === 2)) {
      MessageAlert.warning('1보다 작은 수량은 입력할 수 없습니다.');
      return;
    } else if ((type === 1 && cart_num === num) || cart_num > num) {
      MessageAlert.warning('남은 수량보다 많은 수량은 입력할 수 없습니다.');
      return;
    } else {
      const OtherNumArr =
        numArr !== undefined
          ? numArr?.filter((item) => item.product_cart_id !== product_cart_id)
          : [];
      setNumArr([
        ...OtherNumArr,
        {
          product_cart_id: product_cart_id,
          cart_num:
            type === 1
              ? cart_num + 1
              : type === 2
              ? cart_num - 1
              : parseInt(cart_num),
          price: price,
        },
      ]);
      if (selectProduct.includes(product_id)) {
        if (type === 1) {
          setTotalPrice(totalPrice + price);
        } else if (type === 2) {
          setTotalPrice(totalPrice - price);
        } else if (type === 3) {
          const cur_price =
            numArr?.filter((i) => i.product_cart_id === product_cart_id)
              .length > 0
              ? numArr
                  ?.filter((i) => i.product_cart_id === product_cart_id)
                  ?.pop()?.cart_num * price
              : price;
          setTotalPrice(totalPrice - cur_price + price * cart_num);
        }
      }
    }
  };
  // console.log('numArr', numArr);

  /**
   * 포인트사용
   * --
   * @param {*} usePoint
   */
  const handleChangePoint = (e) => {
    let str = OnlyNumber(e);
    if (str === '') {
      MessageAlert.warning('숫자만 입력해 주세요!');
    } else if (usePoint - e > 0) {
      setUsePoint(str);
    } else {
      MessageAlert.warning('포인트가 부족합니다.');
    }
  };

  /* ===== Hooks ===== */
  /**
   * NOTE: 토스 페이먼츠 테스트
   */
  useEffect(() => {
    const fetchPaymentWidget = async () => {
      try {
        const loadedWidget = await loadPaymentWidget(
          TOSS_CLIENT_KEY,
          TOSS_CUSTOMER_KEY + getCookie('USER')
        );
        setPaymentWidget(loadedWidget);
      } catch (error) {
        console.error('Error fetching payment widget:', error);
      }
    };

    fetchPaymentWidget();
  }, []);

  useEffect(() => {
    if (paymentWidget == null) {
      return;
    }

    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      '#payment-widget',
      { value: totalPrice },
      { variantKey: 'DEFAULT' }
    );

    paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' });

    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  }, [paymentWidget, totalPrice]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(totalPrice);
  }, [totalPrice]);

  // const selector = '#payment-widget';
  // const paymentWidgetRef = useRef(null);
  // const paymentMethodsWidgetRef = useRef(null);
  // useEffect(() => {
  //   (async () => {
  //     const paymentWidget = await loadPaymentWidget(
  //       TOSS_CLIENT_KEY,
  //       TOSS_CUSTOMER_KEY + getCookie('USER')
  //     );

  //     const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
  //       selector,
  //       { value: parseInt(totalPrice) }
  //     );

  //     paymentWidgetRef.current = paymentWidget;
  //     paymentMethodsWidgetRef.current = paymentMethodsWidget;
  //   })();
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   const paymentMethodsWidget = paymentMethodsWidgetRef.current;

  //   if (paymentMethodsWidget == null) {
  //     return;
  //   }

  //   paymentMethodsWidget.updateAmount(
  //     parseInt(totalPrice),
  //     paymentMethodsWidget.UPDATE_REASON.COUPON
  //   );
  // }, [totalPrice]);

  /**
   * 팝업창 메시지 이벤트 감지
   * --
   */
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'refresh') {
        const loginWhoValue =
          loginWho === 'agency' ? { agency_id: uid } : { user_id: uid };
        queryClient.invalidateQueries('getDeliveryAddresses', loginWhoValue);
      }
    };

    if (isOpened) {
      window.addEventListener('message', handleMessage);
    } else {
      window.removeEventListener('message', handleMessage);
    }

    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line
  }, [isOpened]);

  /**
   * 구매형식 초기화
   * --
   */
  useEffect(() => {
    if (!selectProduct.length) setIsCash(null);
  }, [selectProduct]);

  // console.log('NewproductCart', NewproductCart);

  /* ===== Render ===== */
  return (
    <>
      <Content maxWidth={1200} padding={'0 10px'}>
        <Row>
          <Col x={24} padding={8}>
            <Title
              size={screenSize >= 768 ? 2 : 4}
              padding={8}
              style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
            >
              장바구니
            </Title>
          </Col>

          <Col x={24} md={16}>
            <Row
              maxWidth={'100%'}
              padding={0}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row-reverse',
                marginBottom: 10,
              }}
            >
              <Col x={24} md={3}>
                <Col x={24}>
                  <Button
                    onClick={() => handleListDelete()}
                    style={{ float: 'right' }}
                  >
                    선택 삭제
                  </Button>
                </Col>
              </Col>
              <Col x={24} md={21}>
                <Checkbox
                  onChange={(e) => onCheckAllChange(e, 'Y')}
                  checked={cashCheckAll}
                >
                  현금 상품 전체 선택
                </Checkbox>
                <Checkbox
                  onChange={(e) => onCheckAllChange(e, 'N')}
                  checked={pointCheckAll}
                >
                  포인트 상품 전체 선택
                </Checkbox>
              </Col>
            </Row>
            <Content
              maxWidth={'100%'}
              padding={0}
              style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
            >
              <Col x={24}>
                {/* 장바구니 리스트 */}
                <Content
                  maxWidth={'100%'}
                  padding={'20px 10px 0 10px'}
                  maxHeight={'400px'}
                  minHeight={'400px'}
                  style={{ overflowY: 'scroll' }}
                >
                  {NewproductCart?.length < 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '400px',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    NewproductCart?.map((item) => ({
                      ...item,
                      thumbnail: getFirstImageFromContent(item?.content),
                    }))?.map((item, index) => (
                      <Row key={`product_list_item_${index}`}>
                        <Col
                          x={24}
                          md={1}
                          style={{
                            display: 'flex',
                            alignItems:
                              screenSize >= 768 ? 'stretch' : 'center',
                          }}
                        >
                          <Checkbox
                            style={{
                              fontWeight: 600,
                              fontSize: '20px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                            }}
                            onChange={() =>
                              onChange(
                                item.product_id,
                                item.price,
                                item.product_name,
                                item.product_cart_id,
                                item.is_cash
                              )
                            }
                            checked={
                              selectProduct.includes(item.product_id)
                                ? true
                                : false
                            }
                            disabled={item.is_show === 'N' || item.num === 0}
                          />
                          {screenSize < 768 && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Title
                                size={5}
                                style={{
                                  color:
                                    item.is_show === 'Y' &&
                                    (item.num >= 1 || item.num === null)
                                      ? ''
                                      : '#d9d9d9',
                                  paddingLeft: 10,
                                }}
                              >
                                {item.product_name}
                              </Title>
                              <div
                                style={{
                                  width: '10%',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  type="link"
                                  shape="circle"
                                  icon={<CloseOutlined />}
                                  style={{
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                  }}
                                  onClick={() =>
                                    handleDeleteClick(item.product_cart_id)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col x={23}>
                          <Card
                            style={{
                              border: 'none',
                              marginBottom: 25,
                              color:
                                item.is_show === 'Y' &&
                                (item.num >= 1 || item.num === null)
                                  ? ''
                                  : '#d9d9d9',
                            }}
                            bodyStyle={{ padding: 0 }}
                            hoverable={false}
                          >
                            <div style={{ display: 'flex' }}>
                              {item?.thumbnail ? (
                                <div
                                  className="product_card_thumbnail"
                                  style={{
                                    marginRight: 15,
                                    width: screenSize >= 768 ? '20%' : '40%',
                                    height: '80px',
                                    background: `url(${
                                      item?.thumbnail && item?.thumbnail
                                    }) no-repeat center center/contain`,
                                    border: '1px solid #E0E0E0',
                                  }}
                                ></div>
                              ) : (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: '50px',
                                    marginRight: 15,
                                    width: screenSize >= 768 ? '20%' : '40%',
                                    height: '80px',
                                    border: '1px solid #E0E0E0',
                                  }}
                                  icon={faImage}
                                />
                              )}

                              {/*  */}
                              <div
                                style={{
                                  width: screenSize >= 768 ? '50%' : '60%',
                                }}
                              >
                                {screenSize >= 768 && (
                                  <Title
                                    size={5}
                                    style={{
                                      color:
                                        item.is_show === 'Y' &&
                                        (item.num >= 1 || item.num === null)
                                          ? ''
                                          : '#d9d9d9',
                                    }}
                                  >
                                    {item.product_name}
                                  </Title>
                                )}
                                <p
                                  style={{ marginTop: '5px', fontSize: '1em' }}
                                >
                                  {productCategories?.map((subitem) => {
                                    if (
                                      subitem.product_category_id ===
                                      item.product_category_id
                                    ) {
                                      if (subitem.type === 'C') {
                                        categoryNameSet['child'] =
                                          subitem.product_category_name;
                                        productCategories?.map((parent) => {
                                          if (
                                            parent.product_category_id ===
                                            subitem.parents_id
                                          ) {
                                            categoryNameSet['parent'] =
                                              parent.product_category_name;
                                            return '';
                                          } else {
                                            return '';
                                          }
                                        });
                                      } else {
                                        categoryNameSet['child'] =
                                          subitem.product_category_name;
                                      }
                                      return (
                                        categoryNameSet['parent'] +
                                        ' / ' +
                                        categoryNameSet['child']
                                      );
                                    } else {
                                      return '';
                                    }
                                  })}
                                </p>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: '0.95em',
                                    color: item.num < 5 ? 'red' : '',
                                  }}
                                >
                                  {item.num !== null ? (
                                    '남은 수량 : ' + item.num
                                  ) : (
                                    <Tag style={{ marginTop: 5 }}>
                                      {categoryNameSet['parent']}
                                    </Tag>
                                  )}
                                </p>
                                {screenSize < 768 && (
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: '1.3em',
                                        fontWeight: 700,
                                      }}
                                    >
                                      {handleComma(item.price)}
                                      {item?.is_cash === 'Y' ? '원' : 'P'}
                                    </p>
                                    <p
                                      style={{
                                        marginTop: 5,
                                        fontSize: '1em',
                                      }}
                                    >
                                      {item.num !== null ? (
                                        <>
                                          구매 수량 :
                                          <button
                                            style={{
                                              marginLeft: 10,
                                              padding: '0 5px',
                                            }}
                                            onClick={() =>
                                              handleCartNum(
                                                item.product_cart_id,
                                                numArr?.filter(
                                                  (i) =>
                                                    i.product_cart_id ===
                                                    item.product_cart_id
                                                ).length > 0
                                                  ? numArr
                                                      ?.filter(
                                                        (i) =>
                                                          i.product_cart_id ===
                                                          item.product_cart_id
                                                      )
                                                      ?.pop()?.cart_num
                                                  : item.cart_num,
                                                2,
                                                item.num,
                                                item.price,
                                                item.product_id
                                              )
                                            }
                                            disabled={
                                              item.is_show === 'N' ||
                                              item.num === 0
                                            }
                                          >
                                            -
                                          </button>
                                          <input
                                            style={{
                                              width: '25px',
                                              margin: '0 2px',
                                              textAlign: 'center',
                                            }}
                                            value={
                                              numArr?.filter(
                                                (i) =>
                                                  i.product_cart_id ===
                                                  item.product_cart_id
                                              ).length > 0
                                                ? numArr
                                                    ?.filter(
                                                      (i) =>
                                                        i.product_cart_id ===
                                                        item.product_cart_id
                                                    )
                                                    ?.pop()?.cart_num
                                                : item.cart_num
                                            }
                                            onChange={(e) =>
                                              handleCartNum(
                                                item.product_cart_id,
                                                e.target.value,
                                                3,
                                                item.num,
                                                item.price,
                                                item.product_id
                                              )
                                            }
                                          />
                                          <button
                                            style={{
                                              padding: '0 5px',
                                            }}
                                            onClick={() =>
                                              handleCartNum(
                                                item.product_cart_id,
                                                numArr?.filter(
                                                  (i) =>
                                                    i.product_cart_id ===
                                                    item.product_cart_id
                                                ).length > 0
                                                  ? numArr
                                                      ?.filter(
                                                        (i) =>
                                                          i.product_cart_id ===
                                                          item.product_cart_id
                                                      )
                                                      ?.pop()?.cart_num
                                                  : item.cart_num,
                                                1,
                                                item.num,
                                                item.price,
                                                item.product_id
                                              )
                                            }
                                            disabled={
                                              item.is_show === 'N' ||
                                              item.num === 0
                                            }
                                          >
                                            +
                                          </button>
                                        </>
                                      ) : (
                                        <Tag>{categoryNameSet['child']}</Tag>
                                      )}
                                    </p>
                                  </div>
                                )}
                              </div>
                              {/*  */}
                              {screenSize >= 768 && (
                                <div
                                  style={{
                                    width: '30%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: '1.3em',
                                      fontWeight: 700,
                                    }}
                                  >
                                    {handleComma(item.price)}
                                    {item?.is_cash === 'Y' ? '원' : 'P'}
                                  </p>
                                  <p
                                    style={{
                                      marginTop: 5,
                                      fontSize: '1em',
                                    }}
                                  >
                                    {item.num !== null ? (
                                      <>
                                        구매 수량 :
                                        <button
                                          style={{
                                            marginLeft: 10,
                                            padding: '0 5px',
                                          }}
                                          onClick={() =>
                                            handleCartNum(
                                              item.product_cart_id,
                                              numArr?.filter(
                                                (i) =>
                                                  i.product_cart_id ===
                                                  item.product_cart_id
                                              ).length > 0
                                                ? numArr
                                                    ?.filter(
                                                      (i) =>
                                                        i.product_cart_id ===
                                                        item.product_cart_id
                                                    )
                                                    ?.pop()?.cart_num
                                                : item.cart_num,
                                              2,
                                              item.num,
                                              item.price,
                                              item.product_id
                                            )
                                          }
                                          disabled={
                                            item.is_show === 'N' ||
                                            item.num === 0
                                          }
                                        >
                                          -
                                        </button>
                                        <input
                                          style={{
                                            width: '25px',
                                            margin: '0 2px',
                                            textAlign: 'center',
                                          }}
                                          value={
                                            numArr?.filter(
                                              (i) =>
                                                i.product_cart_id ===
                                                item.product_cart_id
                                            ).length > 0
                                              ? numArr
                                                  ?.filter(
                                                    (i) =>
                                                      i.product_cart_id ===
                                                      item.product_cart_id
                                                  )
                                                  ?.pop()?.cart_num
                                              : item.cart_num
                                          }
                                          onChange={(e) =>
                                            handleCartNum(
                                              item.product_cart_id,
                                              e.target.value,
                                              3,
                                              item.num,
                                              item.price,
                                              item.product_id
                                            )
                                          }
                                        />
                                        <button
                                          style={{
                                            padding: '0 5px',
                                          }}
                                          onClick={() =>
                                            handleCartNum(
                                              item.product_cart_id,
                                              numArr?.filter(
                                                (i) =>
                                                  i.product_cart_id ===
                                                  item.product_cart_id
                                              ).length > 0
                                                ? numArr
                                                    ?.filter(
                                                      (i) =>
                                                        i.product_cart_id ===
                                                        item.product_cart_id
                                                    )
                                                    ?.pop()?.cart_num
                                                : item.cart_num,
                                              1,
                                              item.num,
                                              item.price,
                                              item.product_id
                                            )
                                          }
                                          disabled={
                                            item.is_show === 'N' ||
                                            item.num === 0
                                          }
                                        >
                                          +
                                        </button>
                                      </>
                                    ) : (
                                      <Tag>{categoryNameSet['child']}</Tag>
                                    )}
                                  </p>
                                </div>
                              )}

                              {/*  */}
                              {screenSize >= 768 && (
                                <div
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Button
                                    type="link"
                                    shape="circle"
                                    icon={<CloseOutlined />}
                                    style={{
                                      color: '#333',
                                      fontWeight: 'bold',
                                    }}
                                    onClick={() =>
                                      handleDeleteClick(item.product_cart_id)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    ))
                  )}
                </Content>
              </Col>
            </Content>
            <Row>
              <Col x={24}>
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{
                    marginTop: 30,
                    display: 'flex',
                  }}
                >
                  <Select
                    size="large"
                    placeholder="배송지를 선택해주세요"
                    options={address?.map((item) => ({
                      label: `${item.recipient_address}, ${item.recipient_address_detail}`,
                      value: item.delivery_address_id,
                    }))}
                    value={addressValue}
                    onChange={(e) => setAddressValue(e)}
                    style={{ width: screenSize >= 768 ? '100%' : '100%' }}
                  />
                  <Button
                    size="large"
                    style={{
                      marginLeft: '5px',
                    }}
                    onClick={() => handleOpenPopup()}
                  >
                    배송지 등록
                  </Button>
                </Content>
                {addressValue ? (
                  <Content
                    maxWidth={'100%'}
                    padding={0}
                    style={{
                      marginTop: 30,
                    }}
                  >
                    {address?.map((item, idx) =>
                      item.delivery_address_id === addressValue ? (
                        <Card key={idx} hoverable style={{ width: '100%' }}>
                          <div style={styles.addressCardP}>
                            <p>{item?.recipient_name}</p>
                            <p>
                              {item?.recipient_address},
                              {item?.recipient_address_detail}
                            </p>
                            <p>{item?.recipient_phone}</p>
                            <p>{item?.request}</p>
                          </div>
                          <Button
                            size="large"
                            style={{
                              margin: '5px 0',
                            }}
                            onClick={() => handleOpenPopup(addressValue)}
                          >
                            배송지 수정
                          </Button>
                        </Card>
                      ) : null
                    )}
                  </Content>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                x={24}
                style={{ display: isCash === 'Y' ? 'block' : 'none' }}
              >
                <div id="payment-widget" />
                <div id="agreement" />
              </Col>
            </Row>
          </Col>
          <Col x={1} />
          <Col x={0} md={7} style={{ paddingTop: '50px' }}>
            <Card
              style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
              hoverable={false}
            >
              <Title size={4} align="left" style={{ marginBottom: '10px' }}>
                {totalTitle}
                {selectProduct.length > 1
                  ? ` 외 ${selectProduct.length - 1} 상품`
                  : ''}
              </Title>
              <div>
                <Title
                  style={{ fontSize: '16px' }}
                  subButton={
                    <>
                      <Title align="right">
                        <small>
                          {' '}
                          {handleComma(totalPrice)}
                          {isCash === 'Y' ? '원' : 'P'}
                        </small>
                      </Title>
                    </>
                  }
                >
                  주문 금액
                </Title>
                <Title
                  size={5}
                  subButton={
                    <>
                      <Title align="right">
                        <small> 0</small>
                      </Title>
                    </>
                  }
                >
                  할인 금액
                </Title>
                <Title
                  size={5}
                  subButton={
                    <>
                      <Title align="right">
                        <small> 0</small>
                      </Title>
                    </>
                  }
                >
                  쿠폰 금액
                </Title>
              </div>
              <Divider style={{ margin: '10px 0' }} />
              <div>
                <Title
                  size={5}
                  subButton={
                    <>
                      <Title align="right">
                        <small>
                          {handleComma(totalPrice)}
                          {isCash === 'Y' ? '원' : 'P'}
                        </small>
                      </Title>
                    </>
                  }
                >
                  총 결제금액
                </Title>
              </div>
              <Divider style={{ margin: '10px 0' }} />
              <div style={{ display: 'flex' }}>
                <Checkbox
                  style={{ fontSize: '12px' }}
                  checked={payAgreeCheck}
                  onChange={() => setPayAgreeCheck(!payAgreeCheck)}
                >
                  상품 및 결제 정보를 확인하였으며, 이에 동의합니다.(필수)
                </Checkbox>
              </div>
              <Button
                block
                size="large"
                type="primary"
                style={{ marginTop: 7, background: '#AB5476', border: 'none' }}
                onClick={() => handleOrder()}
              >
                결제하기
              </Button>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col x={24}>
            <Content maxWidth={'100%'} padding={0}>
              <Title size={3}>이런 상품은 어떠세요?</Title>
              <Row>
                {recommendList && recommendList?.length > 0 ? (
                  recommendList?.map((_item, _index) => (
                    <Col
                      x={screenSize >= 768 ? 6 : 12}
                      style={{ padding: 0 }}
                      key={`mn2_${_index}`}
                    >
                      <Card
                        style={styles.contentCardButton}
                        bodyStyle={{ padding: 10 }}
                        onClick={() =>
                          navigate(
                            `/shop/detail/${encodeURIComponent(
                              encrypt(_item?.product_id)
                            )}`
                          )
                        }
                      >
                        <LectureCard
                          thumbnail={_item?.thumbnail && _item?.thumbnail}
                          title={_item?.product_name}
                          teacher={_item?.num ? '수량 :' + _item?.num : ''}
                          category={_item?.product_category_name}
                          price={_item?.price}
                          productCheck={true}
                        />
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col x={24}>
                    <Content
                      maxWidth={'100%'}
                      height={250}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: ' center',
                      }}
                    >
                      <Title>비슷한 카테고리의 상품 없습니다.</Title>
                    </Content>
                  </Col>
                )}
              </Row>
            </Content>
          </Col>
        </Row>
        <Row
          style={{
            display: 'none',
          }}
        >
          <Col x={24}>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Col x={16}>
                <Content
                  maxWidth={'100%'}
                  padding={'20px 10px 20px 10px'}
                  style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
                >
                  <Col x={24}>
                    <Title>쿠폰 및 포인트</Title>
                  </Col>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Col x={8}>
                      <Title size={5} align="left">
                        사용 가능한 쿠폰
                      </Title>
                    </Col>
                    <Col x={16}>
                      <Select
                        bordered={false}
                        style={{
                          width: '80%',
                          padding: '4px 0',
                          borderRadius: '4px',
                          border: '1px solid #BDBDBD',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '14px',
                          margin: '8px 0 10px',
                        }}
                        disabled={coupon?.length > 0 ? false : true}
                        value={
                          coupon?.length > 0
                            ? couponValue
                            : '적용가능한 쿠폰이 없습니다.'
                        }
                        onChange={(e) => setCouponValue(e)}
                        options={coupon}
                      />
                    </Col>
                    <Col x={8}>
                      <Title size={5} align="left">
                        포인트 사용
                      </Title>
                    </Col>
                    <Col x={16}>
                      <Input
                        bordered={false}
                        style={{
                          width: '40%',
                          padding: '10px 0',
                          borderRadius: '4px',
                          border: '1px solid #BDBDBD',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '19px',
                          marginRight: '10px',
                          textAlign: 'center',
                        }}
                        // disabled={userPoint <= 0 ? true : false}
                        value={usePoint}
                        onChange={(e) => handleChangePoint(e.target.value)}
                      />
                      <Button
                        style={{ height: '41px' }}
                        onClick={() => setUsePoint(userPoint)}
                      >
                        전액사용
                      </Button>
                      <small
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#ab5576',
                          marginLeft: '10px',
                        }}
                      >
                        포인트 : {userPoint}
                      </small>
                    </Col>
                  </Row>
                </Content>
              </Col>
              <Col x={1} />
              <Col x={7} />
              <Col x={16} style={{ marginTop: '20px' }}>
                <Content
                  maxWidth={'100%'}
                  padding={'20px 10px 20px 10px'}
                  style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
                >
                  <Col x={24}>
                    <Title>결제방법</Title>
                  </Col>
                  <Col x={24}>
                    <Radio.Group
                      options={plainOptions}
                      onChange={onChangePayment}
                      value={paymentMethod}
                    />
                  </Col>
                </Content>
              </Col>
              <Col x={1} />
              <Col x={7} />
            </Row>
          </Col>
        </Row>
      </Content>

      {screenSize >= 768 ? null : (
        <BottomLayout shadow={true}>
          <Content maxWidth={'100%'} padding={0}>
            <div>
              <Title>
                {totalTitle}
                {selectProduct.length > 1
                  ? ` 외 ${selectProduct.length - 1} 상품`
                  : ''}
              </Title>
              <Title
                size={5}
                color={'#9E9E9E'}
                subButton={
                  <>
                    <Title align="right" color={'#9E9E9E'}>
                      <small>
                        {handleComma(totalPrice)}
                        {isCash === 'Y' ? '원' : 'P'}
                      </small>
                    </Title>
                  </>
                }
              >
                총 결제금액
              </Title>
              <div style={{ display: 'flex' }}>
                <Checkbox
                  style={{ fontSize: '12px' }}
                  checked={payAgreeCheck}
                  onChange={() => setPayAgreeCheck(!payAgreeCheck)}
                >
                  상품 및 결제 정보를 확인하였으며, 이에 동의합니다.(필수)
                </Checkbox>
              </div>
            </div>
            <Button
              block
              size="large"
              type="primary"
              style={{ marginTop: 7, background: '#AB5476', border: 'none' }}
              onClick={() => handleOrder()}
            >
              결제하기
            </Button>
          </Content>
        </BottomLayout>
      )}
    </>
  );
};

ProductCartPresenter.defualtProps = {};

export default ProductCartPresenter;
