import React from 'react';
import { Content, Row, Col, Title, Button, Statistic } from 'components';
import {
  Button as Btn,
  Table,
  InputNumber,
  Typography,
  Select,
  Input,
  Tooltip,
} from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { stringToMoneyFormat } from 'utils';
import MessageAlert from 'utils/MessageAlert';

const statusItem = [
  { label: '전체', value: 'all' },
  { label: '미정산', value: false },
  { label: '정산', value: true },
];

const inputItem = [
  { label: '강사명 검색', value: 'teachers' },
  { label: '기관명 검색', value: 'agencies' },
];

const pageItem = [
  {
    label: '10개씩보기',
    value: 10,
  },
  {
    label: '30개씩보기',
    value: 30,
  },
  {
    label: '50개씩보기',
    value: 50,
  },
];

const TotalCalculatePresenter = (props) => {
  /* ===== Props ===== */
  const {
    year,
    month,
    page,
    onDate,
    onPagination,
    calculate,
    updateCalculate,
    selectedItems,
    setSelectedItems,
    selectedKeys,
    setSelectedKeys,
    searchValue,
    setSearchValue,
    selectValue,
    setSelectValue,
    inputSelectValue,
    setInputSelectValue,
    pageSize,
    onPageSize,
    onCalculateTime,
    onSubmit,
    onCalChange,
    calculateInfo,
    editingItem,
    setEditingItem,
  } = props;

  /* ===== State ===== */
  const columns = [
    {
      title: `상태`,
      dataIndex: 'calculate_status',
      key: 'calculate_status',
      fixed: 'left',
      width: 100,
      render: (_, { calculate }) => (
        <span
          style={{
            ...styles.tag,
            background: calculate?.calculate_id ? '#2AC769' : '#FB4E4E',
          }}
        >
          {calculate?.calculate_id ? '정산' : '미정산'}
        </span>
      ),
    },
    {
      title: `강사명`,
      dataIndex: 'user_name',
      key: 'user_name',
      fixed: 'left',
      sorter: (a, b) => a.user_name.localeCompare(b.user_name, 'ko-KR'),
      render: (_, { user_name }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {user_name}
        </Title>
      ),
    },
    {
      title: `기관명`,
      dataIndex: 'agency_name',
      key: 'agency_name',
      fixed: 'left',
      sorter: (a, b) => a.agency_name.localeCompare(b.agency_name, 'ko-KR'),
      render: (_, { agency_name }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {agency_name}
        </Title>
      ),
    },
    {
      title: `주소`,
      dataIndex: 'address',
      key: 'address',
      width: 500,
      render: (_, { agency_address, agency_address_detail }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {agency_address}
          <br />
          {agency_address_detail}
        </Title>
      ),
    },
    {
      title: `프로그램`,
      dataIndex: 'category_name',
      key: 'category_name',
      sorter: (a, b) => a.category_name.localeCompare(b.category_name, 'ko-KR'),
      render: (_, { category_name }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {category_name}
        </Title>
      ),
    },
    {
      title: (
        <Tooltip
          title={
            '총 근무시간은 강사와 기관이 모두 확인한 스케줄 시간만 계산됩니다.'
          }
        >
          총 근무시간
        </Tooltip>
      ),
      dataIndex: 'total_work_hour',
      key: 'total_work_hour',
      sorter: (a, b) => b.calculate?.work_hour - a.calculate?.work_hour,
      render: (_, { calculate, key }) => {
        return calculate?.calculate_id &&
          calculate?.calculate_id !== editingItem?.calculate_id ? (
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
            }}
          >
            {stringToMoneyFormat(calculate?.work_hour, '시간')}
          </Title>
        ) : calculate?.calculate_id &&
          calculate?.calculate_id === editingItem?.calculate_id ? (
          <InputNumber
            value={editingItem?.work_hour}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(e) => editingChange('work_hour', e)}
            controls={false}
            disabled={selectedItems.some((item) => item?.key === key)}
            placeholder="인정 근무시간을 입력해주세요."
          />
        ) : (
          <InputNumber
            value={calculate?.work_hour}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(e) => onCalChange(key, 'work_hour', e)}
            controls={false}
            disabled={selectedItems.some((item) => item?.key === key)}
            placeholder="인정 근무시간을 입력해주세요."
          />
        );
      },
    },
    {
      title: `강사시급`,
      dataIndex: 'teacher_time_price',
      key: 'teacher_time_price',
      sorter: (a, b) => b.calculate?.time_price - a.calculate?.time_price,
      render: (_, { calculate, key }) => {
        return calculate?.calculate_id &&
          calculate?.calculate_id !== editingItem?.calculate_id ? (
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
            }}
          >
            {stringToMoneyFormat(calculate?.time_price, '원')}
          </Title>
        ) : calculate?.calculate_id &&
          calculate?.calculate_id === editingItem?.calculate_id ? (
          <InputNumber
            value={editingItem?.time_price}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(e) => editingChange('time_price', e)}
            controls={false}
            disabled={selectedItems.some((item) => item?.key === key)}
            placeholder="강사 시급을 입력해주세요."
          />
        ) : (
          <InputNumber
            value={calculate?.time_price}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(e) => onCalChange(key, 'time_price', e)}
            controls={false}
            disabled={selectedItems.some((item) => item?.key === key)}
            placeholder="강사 시급을 입력해주세요."
          />
        );
      },
    },
    {
      title: `기관시급`,
      dataIndex: 'agency_time_price',
      key: 'agency_time_price',
      sorter: (a, b) =>
        b.calculate?.agency_time_price - a.calculate?.agency_time_price,
      render: (_, { calculate, key }) => {
        return calculate?.calculate_id &&
          calculate?.calculate_id !== editingItem?.calculate_id ? (
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
            }}
          >
            {stringToMoneyFormat(calculate?.agency_time_price, '원')}
          </Title>
        ) : calculate?.calculate_id &&
          calculate?.calculate_id === editingItem?.calculate_id ? (
          <InputNumber
            value={editingItem?.agency_time_price}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(e) => editingChange('agency_time_price', e)}
            controls={false}
            disabled={selectedItems.some((item) => item?.key === key)}
            placeholder="기관 시급을 입력해주세요."
          />
        ) : (
          <InputNumber
            value={calculate?.agency_time_price}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            onChange={(e) => onCalChange(key, 'agency_time_price', e)}
            controls={false}
            disabled={selectedItems.some((item) => item?.key === key)}
            placeholder="기관 시급을 입력해주세요."
          />
        );
      },
    },
    {
      title: `강사정산금액`,
      dataIndex: 'calculate_price',
      key: 'calculate_price',
      render: (_, { calculate }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {calculate
            ? stringToMoneyFormat(
                calculate?.time_price * calculate?.work_hour,
                '원'
              )
            : '-'}
        </Title>
      ),
    },
    {
      title: `청구금액`,
      dataIndex: 'agency_calculate_price',
      key: 'agency_calculate_price',
      render: (_, { calculate }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {calculate
            ? stringToMoneyFormat(
                calculate?.agency_time_price * calculate?.work_hour,
                '원'
              )
            : '-'}
        </Title>
      ),
    },
    {
      title: `정산일`,
      dataIndex: 'calculate_created_at',
      key: 'calculate_created_at',
      render: (_, { calculate }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {calculate?.calculate_id
            ? moment(calculate?.created_at).format('YYYY.MM.DD')
            : '-'}
        </Title>
      ),
    },
    {
      title: `정산 수정일`,
      dataIndex: 'calculate_updated_at',
      key: 'calculate_updated_at',
      render: (_, { calculate }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {calculate?.calculate_id &&
          calculate?.created_at !== calculate?.updated_at
            ? moment(calculate?.updated_at).format('YYYY.MM.DD')
            : '-'}
        </Title>
      ),
    },

    {
      title: '',
      dataIndex: 'operation',
      render: (_, { calculate }) => {
        return calculate?.calculate_id &&
          calculate?.calculate_id !== editingItem?.calculate_id ? (
          <Typography.Link
            disabled={editingItem}
            onClick={() => setEditingItem(calculate)}
          >
            수정
          </Typography.Link>
        ) : calculate?.calculate_id &&
          calculate?.calculate_id === editingItem?.calculate_id ? (
          <span>
            <Typography.Link
              onClick={() => editingSave()}
              style={{
                marginRight: 8,
              }}
            >
              저장
            </Typography.Link>
            <Typography.Link
              onClick={() => editingCancel()}
              style={{
                marginRight: 8,
              }}
            >
              취소
            </Typography.Link>
          </span>
        ) : (
          <></>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRowKeys);
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: ({ calculate }) => ({
      disabled: calculate?.calculate_id ? true : false,
    }),
    selectedRowKeys: selectedKeys,
  };

  /* ===== Function ===== */
  const editingCancel = () => {
    setEditingItem(null);
  };
  const editingSave = () => {
    const confirm = window.confirm('정산내역을 수정하시겠습니까?');

    if (confirm) {
      try {
        updateCalculate.mutate(editingItem);

        setEditingItem(null);
        MessageAlert.success('정산내역이 성공적으로 수정되었습니다.');
      } catch (err) {
        console.log('정산내역 수정 에러!!', err);
        MessageAlert.error('정산내역을 수정하는데 오류가 발생하였습니다!');
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다');
    }
  };
  const editingChange = (t, v, callback = null) => {
    let newData = { ...editingItem };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setEditingItem(d);

    if (callback) callback(v);
  };

  /* ===== Hooks ===== */
  /* ===== Styles ===== */
  const styles = {
    tag: {
      padding: '3px 5px',
      color: '#FFFFFF',
      borderRadius: '3px',
    },
    title: {
      fontSize: 18,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={0} maxWidth={'100%'} backgroundColor={'none'}>
        {/* 타이틀 */}
        <Row>
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              backgroundColor={'none'}
            >
              <Title size={2} style={{ marginBottom: 20 }}>
                통합 정산 관리
              </Title>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Btn
                  size="large"
                  icon={<LeftOutlined />}
                  onClick={() => onDate('prev')}
                />
                <Title style={{ padding: '0 10px' }}>
                  {year}년 {month}월
                </Title>
                <Btn
                  size="large"
                  icon={<RightOutlined />}
                  onClick={() => onDate('next')}
                />
              </div>
            </Content>
          </Col>
        </Row>
        {/* /타이틀 */}

        {/* 정산현황 */}
        <Row>
          <Col x={24}>
            <Content maxWidth={'100%'} className={'admin_container'}>
              <Title style={{ marginBottom: 20 }}>정산현황</Title>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {calculateInfo?.map((item, index) => (
                  <div key={`${item.title}-${index}`} style={{ width: '20%' }}>
                    <Statistic
                      title={item.title}
                      value={item.value}
                      layoutStyle={{ padding: '10px 15px', width: '95%' }}
                    />
                  </div>
                ))}
              </div>
            </Content>
          </Col>
        </Row>
        {/* /정산현황 */}

        {/* 정산목록 */}
        <Row style={{ marginTop: 20 }}>
          <Col x={24}>
            <Content maxWidth={'100%'} className={'admin_container'}>
              <Title style={{ marginBottom: 20 }}>정산목록</Title>
              {/* 검색필터 */}
              <div
                style={{
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <ul
                  style={{ display: 'flex', alignItems: 'flex-end', margin: 0 }}
                >
                  <li>
                    <p style={{ color: '#BDBDBD' }}>상태</p>
                    <Select
                      value={selectValue}
                      onChange={(e) => setSelectValue(e)}
                      options={statusItem}
                      style={{ width: 150 }}
                      size="large"
                    />
                  </li>
                  <li style={{ display: 'flex', marginLeft: 10 }}>
                    <Select
                      size="large"
                      value={inputSelectValue}
                      options={inputItem}
                      onChange={(e) => setInputSelectValue(e)}
                      style={{ padding: 0 }}
                    />
                    <Input
                      size="large"
                      value={searchValue}
                      style={{ padding: '0px 10px' }}
                      placeholder={
                        inputSelectValue === 'teachers'
                          ? '강사명을 입력해주세요.'
                          : '기관명을 입력해주세요.'
                      }
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </li>
                </ul>
                <div>
                  <Select
                    value={pageSize}
                    options={pageItem}
                    onChange={(e) => onPageSize(e)}
                    size="large"
                  />
                </div>
              </div>
              {/* /검색필터 */}

              {/* 버튼 */}
              <div style={{ marginBottom: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    disabled={!selectedItems?.length}
                    layoutStyle={{ margin: 0 }}
                    onClick={() => onSubmit(selectedItems)}
                  >
                    일괄정산
                  </Button>
                  <p style={{ marginLeft: 10, color: '#BDBDBD' }}>
                    {selectedItems?.length}개 선택됨
                  </p>
                </div>
              </div>
              {/* /버튼 */}

              {/* 테이블 */}
              <Table
                columns={columns}
                dataSource={calculate}
                showSorterTooltip={false}
                scroll={{ x: 3000 }}
                pagination={{
                  position: ['bottomCenter'],
                  current: page,
                  pageSize: pageSize,
                  onChange: (e) => onPagination(e),
                  showSizeChanger: false,
                }}
                expandable={{
                  expandedRowRender: ({ schedules }) => (
                    <Row>
                      <Col x={5} style={{ ...styles.title, fontWeight: 700 }}>
                        교안
                      </Col>
                      <Col x={4} style={{ ...styles.title, fontWeight: 700 }}>
                        진행일
                      </Col>
                      <Col x={3} style={{ ...styles.title, fontWeight: 700 }}>
                        시작시간
                      </Col>
                      <Col x={3} style={{ ...styles.title, fontWeight: 700 }}>
                        종료시간
                      </Col>
                      <Col x={3} style={{ ...styles.title, fontWeight: 700 }}>
                        진행시간
                      </Col>
                      <Col x={3} style={{ ...styles.title, fontWeight: 700 }}>
                        강사확인
                      </Col>
                      <Col x={3} style={{ ...styles.title, fontWeight: 700 }}>
                        기관확인
                      </Col>
                      {schedules?.map((item) => (
                        <>
                          <Col x={5} style={{ ...styles.title }}>
                            {item?.content?.title ? item?.content?.title : '-'}
                          </Col>
                          <Col x={4} style={{ ...styles.title }}>
                            {moment(item?.start_date)?.format('YYYY-MM-DD')}
                          </Col>
                          <Col x={3} style={{ ...styles.title }}>
                            {moment(item?.start_date)?.format('HH:mm')}
                          </Col>
                          <Col x={3} style={{ ...styles.title }}>
                            {moment(item?.end_date)?.format('HH:mm')}
                          </Col>
                          <Col x={3} style={{ ...styles.title }}>
                            {onCalculateTime(item?.start_date, item?.end_date)}h
                          </Col>
                          <Col x={3} style={{ ...styles.title }}>
                            {item?.user_check
                              ? moment(item?.user_check)?.format(
                                  'YYYY.MM.DD HH:mm'
                                )
                              : '-'}
                          </Col>
                          <Col x={3} style={{ ...styles.title }}>
                            {item?.agency_check
                              ? moment(item?.agency_check)?.format(
                                  'YYYY.MM.DD HH:mm'
                                )
                              : '-'}
                          </Col>
                        </>
                      ))}
                    </Row>
                  ),
                }}
                rowSelection={rowSelection}
              />
              {/* /테이블 */}
            </Content>
          </Col>
        </Row>
        {/* /정산목록 */}
      </Content>
    </>
  );
};

export default TotalCalculatePresenter;
