/**
 *
 *
 *
 */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import 'styles/antdMenu.css';
import {
  // Input, Button,
  Collapse,
  Menu,
  Badge,
} from 'antd';
import {
  Content,
  Button as Btn,
  Row,
  Col,
  ModalLayout,
  Button,
} from 'components';
import {
  DownOutlined,
  MenuOutlined,
  UserOutlined,
  // SearchOutlined
} from '@ant-design/icons';
import { useNavigate, NavLink } from 'react-router-dom';
import LOGO from 'assets/brainheal_logo.png';
import { deleteCookie, getCookie } from 'utils';
// import { useEffect } from 'react';
import '../styles/antStyle.css';
import '../styles/animation.css';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { loginState, loginUser, userInfo } from 'atoms/Atoms';
import { encrypt } from 'utils/Crypto';
import { ScheduleApi } from 'api';

const { Panel } = Collapse;

/**
 * [Component]
 * --
 */
const Navbar = ({ subPath, ...props }) => {
  // const { pathname } = useLocation();
  const navigate = useNavigate();

  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;

  /* ====== Data ===== */
  // 로그인 상태 여부
  const [isLogin, setIsLogin] = useRecoilState(loginState);
  const [loginWho, setLoginWho] = useRecoilState(loginUser);
  const uid = getCookie('USER');
  const { data: schedules } = ScheduleApi.GetSchedules(
    loginWho === 'agency'
      ? {
          agency_id: parseInt(uid),
          date: `${year}-${month}`,
        }
      : {
          user_id: parseInt(uid),
          date: `${year}-${month}`,
        },
    {
      enabled: uid && year && month ? true : false,
    }
  );

  /* ====== STATE ===== */
  // 드롭다운 버튼 메뉴
  const [isShow, setIsShow] = useState(false);
  // 드롭다운 호버
  const [isHover, setIsHover] = useState(false);
  // Active Key
  const [activeKey, setActiveKey] = useState([]);

  const userInfoData = useSetRecoilState(userInfo);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  // 모바일 반응형 사이드 메뉴 모달
  const [isOpened, setIsOpened] = useState(false);
  const [scheduleCount, setScheduleCount] = useState(0);

  const ref = useRef(null);

  const NavMenus = useMemo(() => {
    const menus = [
      {
        title: '온라인교육',
        path: '/lectures',
        subMenu: [
          {
            title: '자격증과정',
            path: `/lectures?category_id=${encodeURIComponent(encrypt(1))}`,
            subMenu: [],
          },
          {
            title: '직무역량강화',
            path: `/lectures?category_id=${encodeURIComponent(encrypt(2))}`,
            subMenu: [],
          },
          {
            title: '직무기본교육',
            path: `/lectures?category_id=${encodeURIComponent(encrypt(3))}`,
            subMenu: [],
          },
          {
            title: '자격검정지원',
            path: `/lectures?category_id=${encodeURIComponent(encrypt(4))}`,
            subMenu: [],
          },
        ],
      },
      {
        title: '실버강사',
        path: '/teachers',
        subMenu: [
          {
            title: '강사찾기',
            path: '/teachers',
            subMenu: [],
          },
          {
            title: '강사프로필',
            path: '/teachers/profiles',
            subMenu: [],
          },
        ],
      },
      subPath === 'MAIN' && {
        title: '쇼핑몰',
        path: '/shop/P',
        subMenu: [
          {
            title: '교구',
            path: `/shop/P?category_id=${encodeURIComponent(encrypt(1))}`,
            subMenu: [],
          },
          {
            title: '워크북',
            path: `/shop/P?category_id=${encodeURIComponent(encrypt(2))}`,
            subMenu: [],
          },
          {
            title: '학습교재',
            path: `/shop/P?category_id=${encodeURIComponent(encrypt(3))}`,
            subMenu: [],
          },
          {
            title: '렌탈',
            path: `/shop/P?category_id=${encodeURIComponent(encrypt(4))}`,
            subMenu: [],
          },
        ],
      },
      subPath === 'MAIN' && {
        title: '치매예방다이어리',
        path: '/shop/D',
        subMenu: [
          {
            title: '교육',
            path: '/shop/D',
            subMenu: [
              {
                title: 'VOD교육',
                path: `/shop/D?category_id=${encodeURIComponent(encrypt(13))}`,
              },
              {
                title: '화상교육',
                path: `/shop/D?category_id=${encodeURIComponent(encrypt(14))}`,
              },
              {
                title: '기업교육',
                path: `/shop/D?category_id=${encodeURIComponent(encrypt(15))}`,
              },
              {
                title: '개인관리 챌린지',
                path: `/shop/D?category_id=${encodeURIComponent(encrypt(16))}`,
              },
            ],
          },
          {
            title: '다이어리',
            path: '/shop/D',
            subMenu: [
              {
                title: '자기관리 속지',
                path: `/shop/D?category_id=${encodeURIComponent(encrypt(17))}`,
              },
              {
                title: '인지관리 속지',
                path: `/shop/D?category_id=${encodeURIComponent(encrypt(18))}`,
              },
              {
                title: '디지털 다이어리',
                path: `/shop/D?category_id=${encodeURIComponent(encrypt(19))}`,
              },
            ],
          },
        ],
      },
      subPath === 'MAIN' && {
        title: '소셜챌린지',
        path: '/shop/C',
        subMenu: [
          {
            title: '직무역량강화',
            path: `/shop/C?category_id=${encodeURIComponent(encrypt(7))}`,
            subMenu: [],
          },
          {
            title: '취미•여가',
            path: `/shop/C?category_id=${encodeURIComponent(encrypt(8))}`,
            subMenu: [],
          },
          {
            title: '자격증',
            path: `/shop/C?category_id=${encodeURIComponent(encrypt(9))}`,
            subMenu: [],
          },
          {
            title: '건강',
            path: `/shop/C?category_id=${encodeURIComponent(encrypt(10))}`,
            subMenu: [],
          },
        ],
      },
      {
        title: '이벤트',
        path: '/events?status=ing',
        subMenu: [
          {
            title: '진행중',
            path: '/events?status=ing',
            subMenu: [],
          },
          {
            title: '종료',
            path: '/events?status=end',
            subMenu: [],
          },
          {
            title: '진행예정',
            path: '/events?status=soon',
            subMenu: [],
          },
        ],
      },
    ];
    return menus;
  }, [subPath]);

  /* ===== Function ===== */
  /**
   * 로그아웃
   * --
   */
  const handleLogout = () => {
    const confirm = window.confirm('로그아웃 하시겠습니까?');

    if (confirm) {
      deleteCookie('BRAINHEAL_ACCESS_TOKEN', {});
      deleteCookie('USER', {});
      setIsLogin(false);
      setLoginWho('');
      userInfoData({});
      navigate('/');
    } else {
      return;
    }
  };

  /**
   * PC헤더 메뉴 Collapse
   * --
   */
  const handleCollapseChange = (index, subindex) => {
    const key = `${index}-${subindex}`;
    const list = [...activeKey];
    if (list.includes(key)) {
      const newList = list.filter((item) => item !== key);
      setActiveKey(newList);
    } else {
      const newList = [...list, key];
      setActiveKey(newList);
    }
  };

  /**
   * 모바일 사이드 메뉴
   * --
   */
  const handleModalOpen = (open) => {
    setIsOpened(!open);
  };

  /**
   * 모바일 navigate
   * --
   * @param {*} path
   */
  const handleNavigate = (path) => {
    navigate(`${path}`);
    setIsOpened(false);
  };

  /* ===== Hooks ===== */
  /**
   * 드롭다운 메뉴 Collapse Active Key 설정
   * --
   */
  useEffect(() => {
    if (isShow) {
      let newArr = [];
      NavMenus?.forEach(
        (item, index) =>
          item &&
          item.subMenu.forEach(
            (subitem, subindex) =>
              subitem && newArr.push(`${index}-${subindex}`)
          )
      );
      setActiveKey(newArr);
    } else {
      setActiveKey([]);
    }
  }, [isShow, NavMenus]);

  /**
   * 클릭 이벤트 감지할 요소의 참조 생성
   * --
   */
  useEffect(() => {
    const handleClickOutside = (event) => {
      // 클릭 이벤트가 발생한 요소가 감지할 요소 내부에 속하지 않는 경우
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShow(false);
      }
    };

    if (isShow) {
      document.addEventListener('click', handleClickOutside);
    } else {
      return () => {
        // 컴포넌트 언마운트 시 클릭 이벤트 리스너 제거
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [isShow]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /**
   * 요청받은 스케줄 카운트
   * --
   */
  useEffect(() => {
    if (
      isLogin &&
      (loginWho === 'teacher' || loginWho === 'agency') &&
      schedules?.data?.length
    ) {
      const newSchedules = schedules?.data?.filter(
        (item) =>
          item?.schedule_type === 'R' &&
          (loginWho === 'teacher'
            ? item?.user_confirm === 'C'
            : item?.agency_confirm === 'C')
      );
      setScheduleCount(newSchedules?.length);
    }
  }, [isLogin, loginWho, schedules]);

  return screenSize >= 768 ? (
    <>
      {/* ===== 헤더 ===== */}
      <Content
        maxWidth={'100%'}
        backgroundColor={'#FFFFFF'}
        padding={0}
        style={{
          borderBottom: '1px solid #EEEEEE',
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 3000,
        }}
      >
        <Content maxWidth={1200} padding={0}>
          {/* 상단 로고/검색/로그인/회원가입 */}
          <Content
            maxWidth={'100%'}
            height={50}
            padding={0}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* 로고 */}
            <div>
              <img
                src={LOGO}
                alt=""
                className="hover-opacity"
                style={{ width: '93.33px', height: '35px' }}
                onClick={() => navigate('/')}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '8px',
              }}
            >
              {/* 검색창 */}
              <div>
                {/* <Input.Group>
                  <Input
                    placeholder="Search..."
                    style={{
                      border: 'none',
                      borderBottom: '1px solid #dbdbdb',
                      width: '83%',
                    }}
                  />
                  <Button
                    type="default"
                    // size="small"
                    shape="circle"
                    style={{
                      // width: '13%',
                      fontWeight: 'bold',
                      border: 'none',
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                </Input.Group> */}
              </div>
              {/* 헤더 버튼 */}
              {!isLogin ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Btn
                    color="secondary"
                    style={{
                      padding: '4px 20px',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '19px',
                      margin: '0 8px',
                    }}
                    onClick={() => navigate('/login')}
                  >
                    로그인
                  </Btn>
                  <Btn
                    color="primary"
                    style={{
                      padding: '4px 20px',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '19px',
                    }}
                    onClick={() => navigate('/join/1')}
                  >
                    회원가입
                  </Btn>
                </div>
              ) : isLogin && loginWho !== 'admin' ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Btn
                    color="secondary"
                    style={{
                      padding: '4px 20px',
                      fontSize: '16px',
                      fontWeight: 700,
                      lineHeight: '19px',
                      margin: '0 8px',
                    }}
                    onClick={() => handleLogout()}
                  >
                    로그아웃
                  </Btn>
                  <Badge count={scheduleCount} overflowCount={10}>
                    <Btn
                      color="primary"
                      style={{
                        padding: '4px 20px',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '19px',
                        margin: '0 8px',
                      }}
                      onClick={() => navigate('/mypage/my_info')}
                    >
                      마이클래스
                    </Btn>
                  </Badge>
                  {/* <Badge count={scheduleCount} overflowCount={10}>
                    <Btn
                      color="secondary"
                      style={{
                        padding: '4px 20px',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '19px',
                        margin: '0 8px',
                      }}
                      onClick={() => navigate('/mypage/my_info')}
                    >
                      내정보관리
                    </Btn>
                  </Badge> */}
                </div>
              ) : (
                isLogin &&
                loginWho === 'admin' && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Btn
                      color="secondary"
                      style={{
                        padding: '4px 20px',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '19px',
                        margin: '0 8px',
                      }}
                      onClick={() => handleLogout()}
                    >
                      로그아웃
                    </Btn>
                    <Btn
                      color="primary"
                      style={{
                        padding: '4px 20px',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '19px',
                        margin: '0 8px',
                      }}
                      onClick={() => navigate('/admin/users')}
                    >
                      관리자 메뉴
                    </Btn>
                  </div>
                )
              )}
            </div>
          </Content>

          {/* Nav 버튼 */}
          <Content
            maxWidth={'100%'}
            height={79}
            style={{
              // display: 'flex',
              // justifyContent: 'space-between',
              position: 'relative',
              top: 0,
              left: 0,
            }}
            padding={screenSize >= 1200 ? 0 : '0 30px'}
          >
            <Row
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Col x={0} xl={3}>
                <div ref={ref} style={{ width: '100%', height: '100%' }}>
                  <Btn
                    style={{
                      fontSize: '20px',
                      lineHeight: '24px',
                      fontWeight: 600,
                      padding: '9px 19.47px 7px 15.79px',
                    }}
                    onClick={() => setIsShow(!isShow)}
                  >
                    {<MenuOutlined />}&nbsp; 전체보기
                  </Btn>
                </div>
              </Col>
              <Col x={0} xl={1}></Col>
              <Col x={24} xl={20} style={{ height: '100%' }}>
                <nav
                  style={{
                    flex: 1,
                    position: 'relative',
                    top: 0,
                    left: 0,
                    zIndex: 3000,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <ul
                    style={{
                      position: 'relative',
                      top: 0,
                      right: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '0px',
                    }}
                    onMouseLeave={() => setIsHover(false)}
                    onMouseEnter={() => setIsHover(true)}
                  >
                    {NavMenus?.map(
                      (item, index) =>
                        item && (
                          <li
                            key={`${item.title}`}
                            style={{
                              width: 'auto',
                              height: '100%',
                              position: 'relative',
                              top: 0,
                              left: 0,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingRight: '6%',
                            }}
                          >
                            <NavLink
                              to={item.path}
                              style={({ isActive }) => ({
                                fontWeight: 600,
                                fontSize: '20px',
                                color: '#000000',
                                position: 'relative',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: isActive
                                  ? '3px solid #AB5476'
                                  : 'none',
                                boxSizing: 'border-box',
                              })}
                            >
                              {item.title}
                            </NavLink>
                            <div>
                              <>
                                <ul
                                  className={
                                    isShow || isHover ? 'drop_down' : 'drop_up'
                                  }
                                  style={{
                                    position: 'absolute',
                                    top: '80px',
                                    left: 0,
                                    width: '100%',
                                    background: '#FFFFFF',
                                    zIndex: 2800,
                                    height: 0,
                                    overflow: 'hidden',
                                  }}
                                >
                                  {item.subMenu?.map((subitem, subindex) => (
                                    <li key={`${subitem.title}`}>
                                      <Collapse
                                        bordered={false}
                                        expandIcon={({ isActive }) => (
                                          <DownOutlined
                                            rotate={isActive ? 180 : 0}
                                          />
                                        )}
                                        expandIconPosition="end"
                                        style={{ padding: 0 }}
                                        ghost
                                        accordion={false}
                                        activeKey={activeKey}
                                        onChange={() =>
                                          handleCollapseChange(index, subindex)
                                        }
                                      >
                                        <Panel
                                          key={`${index}-${subindex}`}
                                          showArrow={
                                            subitem.subMenu.length === 0
                                              ? false
                                              : true
                                          }
                                          header={
                                            subitem.subMenu.length === 0 ? (
                                              <NavLink
                                                to={subitem.path}
                                                style={({ isActive }) => ({
                                                  padding:
                                                    subindex === 0
                                                      ? '27px 0 12px 0'
                                                      : '12px 0',
                                                  display: 'inline-block',
                                                  fontSize: '18px',
                                                  fontWeight: 400,
                                                  lineHeight: '22px',
                                                  color: '#000000',
                                                })}
                                              >
                                                {subitem.title}
                                              </NavLink>
                                            ) : (
                                              <p
                                                style={{
                                                  padding:
                                                    subindex === 0
                                                      ? '27px 0 12px 0'
                                                      : '12px 0',
                                                  display: 'inline-block',
                                                  fontSize: '18px',
                                                  fontWeight: 400,
                                                  lineHeight: '22px',
                                                  color: '#000000',
                                                }}
                                              >
                                                {subitem.title}
                                              </p>
                                            )
                                          }
                                          style={{ padding: 0 }}
                                        >
                                          <ul>
                                            {subitem.subMenu?.map(
                                              (thirditem) => (
                                                <li key={`${thirditem.title}`}>
                                                  <NavLink
                                                    to={thirditem.path}
                                                    style={{
                                                      display: 'inline-block',
                                                      padding: '6px 0',
                                                      fontSize: '16px',
                                                      fontWeight: 400,
                                                      lineHeight: '19px',
                                                      color: '#616161',
                                                    }}
                                                  >
                                                    {thirditem.title}
                                                  </NavLink>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </Panel>
                                      </Collapse>
                                    </li>
                                  ))}
                                </ul>
                              </>
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                </nav>
              </Col>
            </Row>
          </Content>
          <Content
            maxWidth={'100%'}
            height={400}
            // backgroundColor={'red'}
            className={isShow || isHover ? 'drop_down' : 'drop_up'}
            style={{
              position: 'absolute',
              left: 0,
              top: '130px',
              zIndex: 2900,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: 0,
            }}
          ></Content>
        </Content>
      </Content>
    </>
  ) : (
    <>
      {/* ===== 모바일 헤더 ===== */}
      <Content
        maxWidth={'100%'}
        backgroundColor={'#FFFFFF'}
        padding={0}
        style={{
          borderBottom: '1px solid #EEEEEE',
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 3000,
        }}
      >
        <Content maxWidth={768} padding={0}>
          <Row>
            <Col x={24}>
              <Content
                maxWidth={'100%'}
                padding={10}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <img
                    src={LOGO}
                    alt=""
                    className="hover-opacity"
                    style={{ width: '93.33px', height: '35px' }}
                    onClick={() => navigate('/')}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {!isLogin ? (
                    <div
                      onClick={() => navigate('/login')}
                      style={{
                        width: '40px',
                        height: '40px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <UserOutlined style={{ fontSize: '25px' }} />
                    </div>
                  ) : null}
                  <div
                    onClick={() => handleModalOpen(isOpened)}
                    style={{
                      width: '40px',
                      height: '40px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <MenuOutlined style={{ fontSize: '25px' }} />
                  </div>
                </div>
              </Content>
            </Col>
          </Row>
        </Content>
      </Content>
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'right'}
        width={'90%'}
        height={'100%'}
        closable
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
        title={
          isLogin ? (
            <Content maxWidth={'100%'} padding={0} style={{ display: 'flex' }}>
              <Button
                layoutStyle={{ margin: screenSize >= 768 ? '0 10px' : '0 5px' }}
                onClick={() => handleLogout()}
                style={{ fontSize: screenSize <= 768 && '12px' }}
              >
                로그아웃
              </Button>
              {loginWho === 'admin' ? (
                <Button
                  layoutStyle={{
                    margin: screenSize >= 768 ? '0 10px' : '0 5px',
                  }}
                  color="secondary"
                  onClick={() => handleNavigate('/admin/users')}
                  style={{ fontSize: screenSize <= 768 && '12px' }}
                >
                  관리자메뉴
                </Button>
              ) : loginWho === 'agency' ? (
                <Button
                  layoutStyle={{
                    margin: screenSize >= 768 ? '0 10px' : '0 5px',
                  }}
                  color="secondary"
                  onClick={() => handleNavigate('/mypage/my_info')}
                  style={{ fontSize: screenSize <= 768 && '12px' }}
                >
                  내정보관리
                </Button>
              ) : (
                <Button
                  layoutStyle={{
                    margin: screenSize >= 768 ? '0 10px' : '0 5px',
                  }}
                  color="secondary"
                  onClick={() => handleNavigate('/mypage/my_info')}
                  style={{ fontSize: screenSize <= 768 && '12px' }}
                >
                  마이클래스
                </Button>
              )}
            </Content>
          ) : (
            <Content maxWidth={'100%'} padding={0} style={{ display: 'flex' }}>
              <Button
                layoutStyle={{ margin: screenSize >= 768 ? '0 10px' : '0 5px' }}
                onClick={() => handleNavigate('/login')}
                style={{ fontSize: screenSize <= 768 && '12px' }}
              >
                로그인
              </Button>
              <Button
                layoutStyle={{ margin: screenSize >= 768 ? '0 10px' : '0 5px' }}
                color="secondary"
                onClick={() => handleNavigate('/join/1')}
                style={{ fontSize: screenSize <= 768 && '12px' }}
              >
                회원가입
              </Button>
            </Content>
          )
        }
      >
        <Menu
          mode="inline"
          items={NavMenus?.map((item, index) => ({
            label: item?.title,
            key: `${item?.title}-${index}`,
            onClick: () =>
              (!item?.subMenu || item?.subMenu?.length === 0) &&
              handleNavigate(`${item?.path}`),
            children:
              item?.subMenu &&
              item?.subMenu?.length > 0 &&
              item?.subMenu?.map((subitem, subindex) => ({
                label: subitem?.title,
                key: `${item?.title}-${subitem?.title}-${subindex}`,
                onClick: () =>
                  (!subitem?.subMenu || subitem?.subMenu?.length === 0) &&
                  handleNavigate(`${subitem?.path}`),
                children:
                  subitem?.subMenu &&
                  subitem?.subMenu?.length > 0 &&
                  subitem?.subMenu?.map((thirditem, thirdindex) => ({
                    label: thirditem?.title,
                    key: `${item?.title}-${subitem?.title}-${thirditem?.title}-${thirdindex}`,
                    onClick: () => handleNavigate(`${thirditem?.path}`),
                  })),
              })),
          }))}
        />
        {/* {NavMenus?.map((_item, _index) => (
          <Collapse expandIconPosition="end" destroyInactivePanel={true}>
            <Panel
              header={`${_item?.title}`}
              key={`menu_${_index}`}
              collapsible="icon"
            >
              {_item?.subMenu?.map((_subitem, _subindex) =>
                _subitem?.subMenu && _subitem?.subMenu?.length > 0 ? (
                  <Collapse expandIconPosition="end">
                    <Panel
                      header={`${_subitem?.title}`}
                      key={`sub_menu_${_index}-${_subindex}`}
                      collapsible="icon"
                    >
                      {_subitem?.subMenu?.map((_thirditem, _thirdindex) => (
                        <p
                          key={`sub_menu_${_index}-${_subindex}-${_thirdindex}`}
                        >
                          {_thirditem?.title}
                        </p>
                      ))}
                    </Panel>
                  </Collapse>
                ) : (
                  <p key={`sub_menu_${_index}-${_subindex}`}>
                    {_subitem?.title}
                  </p>
                )
              )}
            </Panel>
          </Collapse>
        ))} */}
      </ModalLayout>
    </>
  );
};

export default Navbar;
