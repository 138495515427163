import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 강사 프로필 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetUserProfiles: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getUserProfiles', params] : 'getUserProfiles',
        async () => await $http.get(APIConstant.GET_USER_PROFILES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 프로필 상세조회
   * --
   * @method GET
   * @returns
   */
  GetUserProfile: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_USER_PROFILE, {
        profile_id: idx,
      });
      const res = useQuery(
        params ? ['getUserProfile', params] : 'getUserProfile',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 프로필 생성
   * --
   * @method POST
   * @returns
   */
  CreateUserProfile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_USER_PROFILE,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getUserProfiles');
            queryClient.invalidateQueries('getUserProfile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 프로필 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateUserProfile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_USER_PROFILE, {
            profile_id: data.profile_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getUserProfiles');
            queryClient.invalidateQueries('getUserProfile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 프로필 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteUserProfile: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_USER_PROFILE, {
            profile_id: data.profile_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getUserProfiles');
            queryClient.invalidateQueries('getUserProfile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
