import moment from 'moment';
import MessageAlert from './MessageAlert';
import { encrypt, decrypt } from './Crypto';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const HOST_URL = process.env.REACT_APP_HOST_URL;
export const STORE_URL = process.env.REACT_APP_STORE_URL;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const TOSS_CLIENT_KEY = process.env.REACT_APP_TOSS_CLIENT_KEY;
export const TOSS_SECRET_KEY = process.env.REACT_APP_TOSS_SECRET_KEY;
export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
export const TOSS_CUSTOMER_KEY = process.env.REACT_APP_TOSS_CUSTOMER_KEY;
export const PLAYER_URL = process.env.REACT_APP_PLAYER_URL;
export { default as TypeManager } from './TypeManager';
// export { default as APIManager } from 'api/APIManager';
export { default as Validation } from './Validation';

/**
 * 쿠키찾기
 * --
 */
export const getCookie = (name, options = null) => {
  const value = window.document.cookie.match(
    '(^|;) ?' + name + '=([^;]*)(;|$)'
  );

  let decryptValue = null;
  if (value) {
    if (name === 'BRAINHEAL_ACCESS_TOKEN') {
      decryptValue = value[2];
    } else {
      decryptValue = decrypt(value[2]);
    }
  }

  return decryptValue;
};

/**
 * 쿠키저장
 */
export const setCookie = (name, value, callback = false) => {
  let encryptValue = null;
  if (name === 'BRAINHEAL_ACCESS_TOKEN') {
    encryptValue = value;
  } else {
    encryptValue = encrypt(value);
  }

  window.document.cookie = `${name}=${encryptValue}; path=/`;
  if (callback) callback();
};

/**
 * 쿠키삭제
 */
export const removeCookie = (name, value, d) => {
  let encryptValue = null;
  if (name === 'BRAINHEAL_ACCESS_TOKEN') {
    encryptValue = value;
  } else {
    encryptValue = encrypt(value);
  }

  const t = new Date();
  d = d === '' ? '' : '; expires=' + t.setTime(t.getTime() + d);

  document.cookie =
    name +
    '=' +
    encryptValue +
    '; path=/' +
    (d ? '; expires=' + t.toGMTString() : '');
};

/**
 * 쿠키삭제
 */
export const deleteCookie = (name, { path, domain }) => {
  if (getCookie(name)) {
    window.document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
};

// 금액 포맷터
export const stringToMoneyFormat = (v = 0, unit = '') => {
  // const value = String(isNull(v) ? 0 : v)
  const value = String(v ? v : 0)
    .split('')
    .reverse()
    .join('');
  const valueLength = value.length;
  let result = '';
  for (let ii in value) {
    result += String(value[ii]);
    if ((ii + 1) % 3 === 0 && ii < valueLength - 1) {
      result += ',';
    }
  }
  return `${result.split('').reverse().join('')}${unit}`;
};

/**
 * API파라미터 Replace함수
 * --
 */
export const parameterToPath = (path, params = {}) => {
  const keys = Object.keys(params);
  let newStr = '';
  for (let key of keys) {
    newStr = path.replace(`:${key}`, params[key]);
  }
  return newStr;
};

/**
 * guid 생성기
 * --
 */
export const guidGenerator = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};

/**
 * 나이 계산
 * --
 * @param {*} birthday
 * @returns
 */
export const calculateAge = (birthday) => {
  const today = new Date(); // 현재 날짜와 시간을 가져옴
  const birth = new Date(birthday); // 생년월일을 날짜 객체로 생성

  // 만나이 계산
  let age = today.getFullYear() - birth.getFullYear();

  // 생일이 지나지 않았다면 -1
  const monthDiff = today.getMonth() - birth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  const koreanAge = age + 1;

  return {
    age: age,
    koreanAge: koreanAge,
  };
};

/**
 * content에 저장된 첫번째 이미지 url 가져오기
 * --
 * @param {*} content
 * @returns
 */
export const getFirstImageFromContent = (content) => {
  const div = document.createElement('div');
  div.innerHTML = content;

  const imageElement = div.querySelector('img');
  if (imageElement) {
    const src = imageElement.getAttribute('src');
    return src;
  }

  return null;
};

/**
 * 날짜 포맷터
 * --
 */
export const dateFormatter = (date, format) => {
  // const korTime = moment(date).subtract(9, 'hours');
  const formattedDate = moment(date).format(format);

  return formattedDate;
};

/**
 * 월 첫날, 마지막날 계산
 * --
 */
export const calculateFirstAndLastDay = (year, month) => {
  const firstDay = new Date(year, month - 1, 1);
  const lastDay = new Date(year, month, 0);

  const format = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return {
    firstDay: format(firstDay),
    lastDay: format(lastDay),
  };
};

/**
 * 시간입력 포맷터
 * --
 */
export const timeInputFormatter = (value) => {
  let str = value.replace(/[^0-9]/g, '');
  str === '' && MessageAlert.warning('숫자만 입력해 주세요!');

  let formattedValue = '';
  if (str.length <= 2) {
    formattedValue = str;
  } else {
    formattedValue += str.substr(0, 2);
    formattedValue += ':';
    formattedValue += str.substr(2);
  }

  return formattedValue;
};

/**
 * HTML 태그 텍스트 추출
 * --
 */
export const extractTextFromHTML = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');

  return doc.body.textContent || '';
};

/**
 * 스케쥴 삭제 가능 여부 체크
 * @param { item } scheduleItem
 * @param { cal } calculate
 * --
 */
export const handleDeleteCheck = (item, cal) => {
  let column = '';
  if (item?.repetition_schedule_id) column = 'repetition_schedule_id';
  else column = 'schedule_id';

  const result = cal?.find((d) => d[column] === item[column]);

  return result ? 'Y' : 'N';
};

/**
 * 시간 차이 계산
 * --
 * @param {*} t1 HH:mm
 * @param {*} t2 HH:mm
 * @param {*} type 리턴 타입(시, 분, 초, 밀리초)
 * @returns
 */
export const handleDiffTime = (t1, t2, type) => {
  let startTime = '',
    endTime = '';
  if (t1 > t2) {
    startTime = t2;
    endTime = t1;
  } else {
    startTime = t1;
    endTime = t2;
  }
  const currentTime = new Date();
  const time01 = new Date(currentTime.toDateString() + ' ' + startTime);
  const time02 = new Date(currentTime.toDateString() + ' ' + endTime);

  const diffTime = Math.abs(time02 - time01);

  switch (type) {
    case 'H':
    case 'h':
      const hour = 1000 * 60 * 60;
      return diffTime / hour;
    case 'M':
    case 'm':
      const minute = 1000 * 60;
      return diffTime / minute;
    case 'S':
    case 's':
      const second = 1000;
      return diffTime / second;
    default:
      return diffTime;
  }
};

/**
 * 이미지 파일 가로 세로 크기
 * --
 */
export const handleImageReader = (file) => {
  return new Promise((resolve, reject) => {
    if (file && file.type.startsWith('image')) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          });
        };
        img.onerror = reject;
        img.src = e.target.result;
      };

      reader.onerror = reject;
      reader.readAsDataURL(file);
    } else {
      resolve({
        width: 0,
        height: 0,
      });
    }
  });
};
