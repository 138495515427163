import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath, setCookie } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 관리자 로그인
   * --
   * @method POST
   * @returns
   */
  LoginAdmin: async (data) => {
    try {
      let status;
      await $http.post(APIConstant.ADMIN_LOGIN, data).then((res) => {
        if (res.status !== 200) {
          status = 500;
        } else {
          setCookie('BRAINHEAL_ACCESS_TOKEN', res.data.token);
          setCookie('USER', 0);
          status = 200;
        }
      });

      return status;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 관리자 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetAdmins: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getAdmins', params] : 'getAdmins',
        async () => await $http.get(APIConstant.GET_ADMINS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 관리자 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetAdmin: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_ADMIN, { admin_id: idx });
      const res = useQuery(
        params ? ['getAdmin', params] : 'getAdmin',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 관리자 생성
   * --
   * @method POST
   * @returns
   */
  CreateAdmin: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_ADMIN, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAdmins');
            queryClient.invalidateQueries('getAdmin');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 관리자 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateAdmin: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_ADMIN, {
            admin_id: data.admin_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAdmins');
            queryClient.invalidateQueries('getAdmin');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 관리자 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteAdmin: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_ADMIN, {
            admin_id: data.admin_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAdmins');
            queryClient.invalidateQueries('getAdmin');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
