import React, { useState, useEffect } from 'react';
import TeacherCalculatePresenter from './TeacherCalculatePresenter';
import { TemplateApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { LoadingPage } from 'components';
import { CalculateApi } from 'api';

const TeacherCalculateContainer = ({ data }) => {
  /* ===== PROPS ===== */
  const { teachers, agencies, categories } = data;

  /* ===== STATE ===== */
  const [htmlTemplate, setHtmlTemplate] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [latestData, setLatestData] = useState({});

  const getHtml = TemplateApi.GetTemplatePdf({
    onSuccess: async (d) => {
      const { status, data } = d;

      if (status === 200) {
        setHtmlTemplate(data?.html);
      }
    },
  });
  const template = TemplateApi.GetTemplatePdf({
    onSuccess: async (d) => {
      const { status, data } = d;

      if (status === 200) {
        const uint8Array = new Uint8Array(data.pdf.data);
        const blob = new Blob([uint8Array], {
          type: 'application/pdf',
        });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${data.title ? data?.title : 'brainheal'}.pdf`;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      }
    },
  });

  /* ===== FUNCTION ===== */
  /**
   * html 템플릿 호출
   * --
   */
  const handleDownload = (data) => {
    // 정산된 데이터만 조회
    const calData = data?.reduce((acc, cur) => {
      if (cur?.calculate) return acc.concat(cur?.calculate?.teacher_bill);
      else return acc;
    }, []);

    if (!calData?.length) {
      return MessageAlert.warning(`정산된 내역이 없습니다!`);
    }

    if (calData[0] === null) {
      return MessageAlert.warning(`정산메일 전송 후 시도해주세요!`);
    }

    // 가장 최근 날짜 찾기
    const findLatestData = calData?.reduce((acc, cur) => {
      const mostRecentDate = new Date(acc?.send_date);
      const currentDate = new Date(cur?.send_date);

      return currentDate > mostRecentDate ? cur : acc;
    });
    setLatestData(findLatestData);

    try {
      getHtml.mutate({
        type: 'teacher_bill',
        template_value: {
          ...findLatestData,
        },
        return_type: 'html',
      });
      setIsOpened(!isOpened);
    } catch (err) {
      console.log('HTML 호출 실패!', err);
    }
  };

  /**
   * PDF 다운로드
   * --
   */
  const handlePDFDownload = (title) => {
    try {
      setIsLoading(true);
      template.mutate({
        type: 'teacher_bill',
        template_value: {
          title: title,
          ...latestData,
        },
      });
    } catch (err) {
      console.log('PDF 다운로드 실패!', err);
      MessageAlert.error('PDF 파일을 다운로드 하는데 실패하였습니다');
      throw err;
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  /**
   * 정산 EXCEL 다운로드
   * --
   */
  const handleExcelDownload = async (y, m) => {
    try {
      setIsLoading(true);
      const { status, data } = await CalculateApi.GetCalculateExcel({
        year: y,
        month: m,
      });

      if (status !== 200)
        return MessageAlert.error('엑셀파일을 다운로드하는데 실패하였습니다.');

      const file_name = `${y}-${m} 강사 정산서(종합).xlsx`;
      const uint8Array = new Uint8Array(data.data);
      const blob = new Blob([uint8Array], {
        type: 'application/octet-stream',
      });
      const downloadURL = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadURL;
      a.download = file_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadURL);
    } catch (err) {
      console.log('엑셀 다운로드 실패!', err);
      MessageAlert.error('엑셀 파일을 다운로드 하는데 실패하였습니다');
      throw err;
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    if (!isOpened) {
      setHtmlTemplate('');
      setLatestData({});
    }
  }, [isOpened]);

  /* ===== RENDER ===== */
  return (
    <>
      {isLoading ? <LoadingPage text={'다운로드 준비중...'} /> : null}
      <TeacherCalculatePresenter
        teachers={teachers}
        agencies={agencies}
        categories={categories}
        onDownload={handleDownload}
        htmlTemplate={htmlTemplate}
        opened={isOpened}
        setOpened={setIsOpened}
        onPDFDownload={handlePDFDownload}
        onExcelDownload={handleExcelDownload}
      />
    </>
  );
};

export default TeacherCalculateContainer;
