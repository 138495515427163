import React from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faVolumeXmark,
  faVolumeHigh,
  faVolumeLow,
  faPause,
  faVolumeOff,
  faExpand,
} from '@fortawesome/free-solid-svg-icons';
import { Slider } from 'antd';
import screenfull from 'screenfull';

/**
 * 컨트롤러
 * --
 */
const PlayerController = (props) => {
  /* ===== PROPS ===== */
  const {
    playProgress,
    setPlayProgress,
    playing,
    setPlaying,
    playerRef,
    muted,
    setMuted,
    volume,
    setVolume,
    current,
    setCurrent,
    selectedSection,
    screenSize,
  } = props;

  /* ===== STYLE ===== */
  const styles = {
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    ControllerButton: {
      background: 'none',
      border: 'none',
      cousor: 'pointer',
    },
    range: {
      display: 'block',
      width: '100%',
      accentColor: '#E3A4AF',
      pointerEvents: 'auto',
    },
  };

  /* ===== FUNCTION ===== */
  /**
   * 시간 포맷터
   * --
   */
  const handleFormatTime = (secondsFloat) => {
    const seconds = Math.floor(secondsFloat); // 소수점 이하를 버림
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  /**
   * FULLSCREEN
   * --
   */
  const handleToggleFullScreen = () => {
    screenfull.toggle(playerRef.current.wrapper);
  };

  return (
    <div
      style={{
        ...styles.flex,
        height: screenSize >= 1200 ? 80 : 'auto',
        width: '100%',
        padding: screenSize >= 1200 ? '0px 15px' : 10,
      }}
    >
      <div
        style={{
          ...styles.flex,
          justifyContent: 'space-between',
          height: '100%',
          width: '100%',
        }}
      >
        <div
          style={{
            ...styles.flex,
            justifyContent: 'center',
            fontSize: screenSize >= 1200 ? 30 : 18,
            cursor: 'pointer',
            border: '1px solid #000',
            width: screenSize >= 1200 ? 60 : 35,
            height: screenSize >= 1200 ? 60 : 35,
            borderRadius: '50%',
          }}
          onClick={() => setPlaying(!playing)}
        >
          {playing ? (
            <FontAwesomeIcon icon={faPause} />
          ) : (
            <FontAwesomeIcon icon={faPlay} />
          )}
        </div>
        <div
          style={{
            ...styles.flex,
            justifyContent: 'center',
            width: '60%',
          }}
        >
          <p style={{ whiteSpace: 'nowrap', paddingRight: 10 }}>
            {handleFormatTime(playProgress?.playedSeconds)} /{' '}
            {handleFormatTime(playProgress?.duration)}
          </p>
          <Slider
            min={0}
            max={playProgress?.duration}
            value={playProgress?.playedSeconds}
            onChange={(e) => {
              setPlayProgress((prev) => ({
                ...prev,
                playedSeconds: e,
              }));
              playerRef.current.seekTo(parseFloat(e));
            }}
            style={{
              ...styles.range,
            }}
            tooltip={{ formatter: (value) => handleFormatTime(value) }}
          />
        </div>
        <div
          style={{
            ...styles.flex,
            width: '10%',
            display: screenSize >= 1200 ? 'flex' : 'none',
          }}
        >
          <p
            style={{
              fontSize: screenSize >= 1200 ? 18 : 14,
              cursor: 'pointer',
              paddingRight: 10,
            }}
            onClick={() => setMuted(!muted)}
          >
            <FontAwesomeIcon
              icon={
                muted
                  ? faVolumeXmark
                  : volume === 0
                  ? faVolumeOff
                  : volume > 0.5
                  ? faVolumeHigh
                  : faVolumeLow
              }
            />
          </p>
          {/* <Slider
            min={0}
            max={1}
            step={0.1}
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
            style={{ ...styles.range }}
          /> */}
          <input
            type="range"
            min={0}
            max={1}
            step={0.1}
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
            style={{ ...styles.range, pointerEvents: 'auto' }}
          />
        </div>
        <div style={{ ...styles.flex, fontSize: screenSize >= 1200 ? 30 : 18 }}>
          <button
            disabled={current === 0}
            onClick={() => setCurrent(current - 1)}
            style={{
              ...styles.ControllerButton,
              color: current === 0 ? '#BDBDBD' : '#000000',
            }}
          >
            <CaretLeftOutlined />
          </button>
          <p>
            {current + 1} / {selectedSection?.video?.length}
          </p>
          <button
            disabled={current + 1 === selectedSection?.video?.length}
            onClick={() => setCurrent(current + 1)}
            style={{
              ...styles.ControllerButton,
              color:
                current + 1 === selectedSection?.video?.length
                  ? '#BDBDBD'
                  : '#000000',
            }}
          >
            <CaretRightOutlined />
          </button>
        </div>
        <div
          style={{
            ...styles.flex,
            fontSize: screenSize >= 1200 ? 25 : 16,
            cursor: 'pointer',
          }}
          onClick={() => handleToggleFullScreen()}
        >
          <FontAwesomeIcon icon={faExpand} />
        </div>
      </div>
    </div>
  );
};

export default PlayerController;
