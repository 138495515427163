import React, { useState, useEffect } from 'react';
import './AddressLayout.style.css';
import { Content, Row, Col, Title, DaumAddress } from 'components';
import { useSearchParams } from 'react-router-dom';
import { decrypt } from 'utils/Crypto';
import { DeliveryAddressApi } from 'api';
import { parameterToPath } from 'utils';
import MessageAlert from 'utils/MessageAlert';
import APIConstant from '../../../api/APIConstant';
import APIManager from '../../../api/APIManager';
import { Button, Input } from 'antd';
import { userInfo, loginUser } from 'atoms/Atoms';
import { useRecoilValue } from 'recoil';
const $http = new APIManager();

const AddressLayout = () => {
  /* ===== ROUTE ===== */
  const [searchParams] = useSearchParams();
  const addressId = searchParams.get('address');
  const loginWho = useRecoilValue(loginUser);
  const userInfoData = useRecoilValue(userInfo);
  /* ===== MUTATE ===== */
  /**
   * 배송지 업데이트
   * --
   */
  const updateDeliveryAddress = DeliveryAddressApi.UpdateDeliveryAddress({
    onSuccess: () => {
      window.opener.postMessage('refresh', '*');
    },
  });
  const createDeliveryAddress = DeliveryAddressApi.CreateDeliveryAddress({
    onSuccess: () => {
      try {
        window.opener.postMessage('refresh', '*');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });
  /* ===== STATE ===== */
  const [modalData, setModalData] = useState({});
  DeliveryAddressApi.GetDeliveryAddresses();

  /* ===== Functions ===== */
  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...modalData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setModalData(d);

    if (callback) callback(v);
  };
  // console.log(modalData);
  // console.log(editData);
  /**
   * 핸드폰 번호 자동 Hyphen
   * --
   * @ 휴대폰 인증 기능 시 제외
   */
  const handleHyphen = (e, v) => {
    let str = v.replace(/[^0-9]/g, '');
    str === '' && MessageAlert.warning('숫자만 입력해 주세요!');
    let phoneText = '';
    // 02 연락처 예외처리
    if (`${str[0]}${str[1]}` === '02') {
      if (str.length < 3) {
        phoneText = str;
      } else if (str.length < 6) {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2);
      } else if (str.length < 10) {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2, 3);
        phoneText += '-';
        phoneText += str.substr(5);
      } else {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2, 4);
        phoneText += '-';
        phoneText += str.substr(6);
      }
    } else {
      if (str.length < 4) {
        phoneText = str;
      } else if (str.length < 7) {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3);
      } else if (str.length < 11) {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3, 3);
        phoneText += '-';
        phoneText += str.substr(6);
      } else {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3, 4);
        phoneText += '-';
        phoneText += str.substr(7);
      }
    }
    phoneText === modalData.recipient_phone &&
      MessageAlert.warning('숫자만 입력해 주세요!');
    handleChange(e, phoneText);
  };
  /**
   * 배송지 데이터 조회
   * --
   */
  const handleGetAddressData = async (id) => {
    try {
      const delivery_address_id = decrypt(id);
      const url = parameterToPath(APIConstant.GET_DELIVERY_ADDRESS, {
        delivery_address_id: delivery_address_id,
      });
      const { status, data } = await $http.get(`${url}`);
      if (status === 200) {
        setModalData(data);
      } else {
        MessageAlert.warning('데이터를 호출하는데 실패하였습니다!');
      }
    } catch (err) {
      console.error('프로그램 데이터 호출 실패!', err);
      MessageAlert.error('데이터를 호출하는데 실패하였습니다!');
    }
  };
  /**
   * 배송지 수정
   * --
   */
  const handleUpdateAddress = async () => {
    if (window.confirm('배송지를 수정하시겠습니까?')) {
      try {
        updateDeliveryAddress.mutate({
          delivery_address_id: modalData?.delivery_address_id,
          recipient_name: modalData?.recipient_name,
          recipient_address: modalData?.recipient_address,
          recipient_address_detail: modalData?.recipient_address_detail,
          recipient_phone: modalData?.recipient_phone,
          request: modalData?.request,
        });
        MessageAlert.success('수정이 완료 되었습니다.');
      } catch (e) {
        throw e;
      }
    }
  };
  /**
   * 배송지 등록
   * --
   */
  const handleCreateAddress = async () => {
    if (window.confirm('배송지를 등록하시겠습니까?')) {
      try {
        if (loginWho === 'agency') {
          createDeliveryAddress.mutate({
            agency_id: userInfoData.agency_id,
            recipient_name: modalData?.recipient_name,
            recipient_address: modalData?.recipient_address,
            recipient_address_detail: modalData?.recipient_address_detail,
            recipient_phone: modalData?.recipient_phone,
            request: modalData?.request,
          });
        } else {
          createDeliveryAddress.mutate({
            user_id: userInfoData.user_id,
            recipient_name: modalData?.recipient_name,
            recipient_address: modalData?.recipient_address,
            recipient_address_detail: modalData?.recipient_address_detail,
            recipient_phone: modalData?.recipient_phone,
            request: modalData?.request,
          });
        }
        MessageAlert.success('배송지 등록이 완료 되었습니다.');
      } catch (e) {
        throw e;
      }
    }
  };
  /* ===== HOOKS ===== */

  useEffect(() => {
    if (addressId) {
      handleGetAddressData(addressId);
    }
  }, [addressId]);
  /* ===== RENDER ===== */
  return (
    <>
      <Content maxWidth={'100%'} padding={20}>
        <Row>
          <Col x={24}>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col x={6}>
                <Title>수령인</Title>
              </Col>
              <Col x={18}>
                <Input
                  size="large"
                  bordered={false}
                  value={modalData?.recipient_name}
                  placeholder="수령인을 입력해주세요"
                  onChange={(e) =>
                    handleChange('recipient_name', e.target.value)
                  }
                  // readOnly
                  // disabled
                  style={{
                    padding: '16px 20px',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                    color: 'black',
                  }}
                />
              </Col>
              <Col x={6}>
                <Title>주소</Title>
              </Col>
              <Col x={18}>
                <DaumAddress
                  size="large"
                  bordered={false}
                  value={modalData?.recipient_address}
                  placeholder="주소를 검색해주세요."
                  style={{
                    padding: '16px 20px',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                    cursor: 'pointer',
                  }}
                  readOnly
                  callback={(v) => handleChange('recipient_address', v)}
                />
              </Col>
              <Col x={6}>
                <Title>상세주소</Title>
              </Col>
              <Col x={18}>
                <Input
                  size="large"
                  bordered={false}
                  placeholder="상세주소를 입력해주세요."
                  value={modalData?.recipient_address_detail}
                  style={{
                    padding: '16px 20px',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                  }}
                  onChange={(e) =>
                    handleChange('recipient_address_detail', e.target.value)
                  }
                />
              </Col>
              <Col x={6}>
                <Title>수령인 번호</Title>
              </Col>
              <Col x={18}>
                <Input
                  size="large"
                  value={modalData?.recipient_phone}
                  placeholder="수령인 번호를 입력해주세요"
                  onChange={(e) =>
                    handleHyphen('recipient_phone', e.target.value)
                  }
                  // readOnly
                  // disabled
                  style={{
                    padding: '16px 20px',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                    color: 'black',
                  }}
                  maxLength={13}
                />
              </Col>
              <Col x={6}>
                <Title>요청사항</Title>
              </Col>
              <Col x={18}>
                <Input
                  size="large"
                  value={modalData?.request}
                  bordered={false}
                  placeholder="요청사항을 입력해주세요"
                  onChange={(e) => handleChange('request', e.target.value)}
                  // readOnly
                  // disabled
                  style={{
                    padding: '16px 20px',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                    color: 'black',
                  }}
                />
              </Col>
            </Row>
          </Col>
          {addressId ? (
            <Button
              size="large"
              style={{
                width: '100%',
                margin: '5px 0',
              }}
              onClick={() => handleUpdateAddress()}
            >
              수정하기
            </Button>
          ) : (
            <Button
              size="large"
              style={{
                width: '100%',
                margin: '5px 0',
              }}
              onClick={() => handleCreateAddress()}
            >
              등록하기
            </Button>
          )}

          <Button
            size="large"
            style={{
              width: '100%',
              margin: '5px 0',
            }}
            onClick={() => window.close()}
          >
            닫기
          </Button>
        </Row>
      </Content>
    </>
  );
};

AddressLayout.defaultProps = {};

export default AddressLayout;
