import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 답안지 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetAnswerExams: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getAnswerExams', params] : 'getAnswerExams',
        async () => await $http.get(APIConstant.GET_ANSWER_EXAMS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 답안지 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetAnswerExam: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_ANSWER_EXAM, {
        answer_exam_id: idx,
      });
      const res = useQuery(
        params ? ['getAnswerExam', params] : 'getAnswerExam',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 답안지 생성
   * --
   * @method POST
   * @returns
   */
  CreateAnswerExam: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_ANSWER_EXAM,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAnswerExams');
            queryClient.invalidateQueries('getAnswerExam');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 답안지 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateAnswerExam: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_ANSWER_EXAM, {
            answer_exam_id: data.answer_exam_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAnswerExams');
            queryClient.invalidateQueries('getAnswerExam');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 답안지 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteAnswerExam: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_ANSWER_EXAM, {
            answer_exam_id: data.answer_exam_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAnswerExams');
            queryClient.invalidateQueries('getAnswerExam');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
