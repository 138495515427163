/**
 *
 *
 */

// React
import React from 'react';
// import Routemap from 'routes';
// Router
import { HashRouter as Router } from 'react-router-dom';
import App from './App';

// paymentKey
/**
 * [Component] AppContainer
 * --
 */
function AppContainer() {
  /* ===== Initial ===== */

  /* ===== State ===== */
  /* ===== Functions ===== */
  /* ===== Hooks ===== */

  /* ===== Render ===== */
  return (
    <Router>
      {/* <Routemap /> */}
      <App />
    </Router>
  );
}

export default AppContainer;
