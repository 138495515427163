import React, { useState, useEffect } from 'react';
import { Button, Tag, Checkbox, Empty } from 'antd';
import { Title, Content, Col, Row, Card, BottomLayout } from 'components';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { STORE_URL, getCookie } from 'utils';
import MessageAlert from 'utils/MessageAlert';
import { InterestListApi } from 'api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';

const LectureFavoritesPresenter = ({
  lectures,
  lectureFiles,
  users,
  interestLists,
  categories,
  idx,
}) => {
  /* ===== Props ===== */

  /* ===== Mutate ===== */
  /**
   * 장바구니 등록
   * --
   */
  const createInterestList = InterestListApi.CreateInterestList({
    onSuccess: (data) => {
      try {
        // MessageAlert.error('수강바구니에 담았습니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });
  /**
   * 좋아요 삭제
   * --
   */
  const deleteInterestList = InterestListApi.DeleteInterestList({
    onSuccess: (data) => {
      try {
        MessageAlert.error('삭제되었습니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /* ===== Router ===== */
  const navigate = useNavigate();
  const today = moment(new Date()).format('YYYY-MM-DD');

  const NewLectures = lectures?.map((item) => ({
    ...item,
    lectureFiles:
      lectureFiles &&
      lectureFiles?.filter((subitem) => item.lecture_id === subitem.lecture_id),
    ...(
      users && users?.filter((subitem) => item.user_id === subitem.user_id)
    )?.pop(),
    category: categories?.filter(
      (subitem) => item?.category_id === subitem?.category_id
    )[0]?.category_name,
  }));

  const NewInterestList = interestLists
    ?.filter((item) => item.user_id === idx && item.type === 'L')
    ?.map((subitem) => ({
      ...subitem,
      ...(
        NewLectures &&
        NewLectures?.filter(
          (lowitem) => subitem.lecture_id === lowitem.lecture_id
        )
      )?.pop(),
      selectCheck: 'N',
    }));

  // console.log('interestLists', interestLists);
  // console.log('NewInterestList', NewInterestList);
  /* ===== State ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');

  // 강의 선택 리스트
  let lectureIdList = [];
  NewInterestList?.map(
    (item) =>
      moment(item.start_date).format('YYYY-MM-DD') <= today &&
      moment(item.end_date).format('YYYY-MM-DD') >= today &&
      lectureIdList.push(item.lecture_id)
  );

  // 체크박스 기능
  const [selectLecture, setSelectLecture] = useState([]);
  const [isCash, setIsCash] = useState(null);
  // const [cashCheckAll, setCashCheckAll] = useState(false);
  const [pointCheckAll, setPointCheckAll] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTitle, setTotalTitle] = useState('강의를 선택해주세요.');
  let CartList = [];
  interestLists
    ?.filter((item) => item.user_id === idx && item.type === 'C')
    .map((subitem) => CartList.push(subitem.lecture_id));

  /* ===== Hooks ===== */
  /**
   * 기관회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== Functions ===== */

  /**
   * 항목개별선택
   * --
   */
  const onChange = (item, price, cash) => {
    if (!isCash) return setIsCash(cash);

    if (isCash && isCash !== cash)
      return MessageAlert.warning(
        '포인트 상품과 현금 상품을 같이 선택할 수 없습니다!'
      );

    const list = selectLecture.includes(item)
      ? selectLecture?.filter((e) => e !== item)
      : [...selectLecture, item];

    const items = NewInterestList.filter((l) => list.includes(l?.lecture_id));

    if (selectLecture.includes(item)) {
      setTotalPrice(totalPrice - price);
      selectLecture?.length === 0
        ? setTotalTitle('강의를 선택해주세요.')
        : setTotalTitle(
            list?.length === 1
              ? `${items[0]?.title}`
              : `${items[0]?.title} 외 ${items?.length - 1}강`
          );
    } else {
      setTotalPrice(totalPrice + price);
      list?.length === 0
        ? setTotalTitle('강의를 선택해주세요.')
        : setTotalTitle(
            list?.length === 1
              ? `${items[0]?.title}`
              : `${items[0]?.title} 외 ${items?.length - 1}강`
          );
    }

    const itemList = NewInterestList?.filter((item) => item.is_cash === isCash);
    if (isCash === 'Y') {
      // setCashCheckAll(list.length === itemList.length);
    } else {
      setPointCheckAll(list.length === itemList.length);
    }
    setSelectLecture(list);
  };

  /**
   * 항목전체선택
   * --
   */
  const onCheckAllChange = (e, t) => {
    if (!isCash) setIsCash(t);

    if (isCash && isCash !== t)
      return MessageAlert.warning(
        '포인트 상품과 현금 상품을 같이 선택할 수 없습니다!'
      );

    // 타입에 맞는 아이템 정제
    const itemList = NewInterestList?.filter((item) => item.is_cash === t);
    const itemIdList = itemList?.map((item) => item.lecture_id);

    //전체금액 계산
    const totalValue = itemList?.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue.price;
    }, 0);

    if (!itemList?.length) {
      setIsCash(null);
      return MessageAlert.warning(
        `${t === 'Y' ? '현금' : '포인트'} 상품이 존재하지 않습니다`
      );
    }

    if (t === 'Y') {
      // setCashCheckAll(e.target.checked);
    } else {
      setPointCheckAll(e.target.checked);
    }
    setSelectLecture(e.target.checked ? itemIdList : []);

    if (e.target.checked) {
      setTotalPrice(totalValue);
      setTotalTitle(
        itemList?.length === 1
          ? `${itemList[0]?.title}`
          : `${itemList[0]?.title} 외 ${itemList?.length - 1}강`
      );
    } else {
      setTotalPrice(0);
      setTotalTitle('강의를 선택해주세요.');
    }
  };
  // console.log('selectLecture', selectLecture);
  // console.log('totalPrice', totalPrice);

  /**
   * 강의 삭제
   * --
   * @param {*} eventTargetValue : interest_list_id
   */
  const handleDeleteClick = (value) => {
    // console.log('value', value);
    if (window.confirm('해당강의를 삭제하시겠습니까?')) {
      // console.log('삭제:', value);
      try {
        deleteInterestList.mutate({
          interest_list_id: value,
        });
      } catch (e) {
        throw e;
      }
      setSelectLecture([]);
      // setCashCheckAll(false);
      setPointCheckAll(false);
      setTotalPrice(0);
      setTotalTitle('강의를 선택해주세요.');
    } else {
      // console.log('삭제취소:', value);
    }
  };

  /**
   * 좋아요 목록 강의 선택 삭제
   * --
   * @param {*} 선택한 강의 value : selectLecture
   */
  const handleListDelete = () => {
    if (selectLecture.length === 0) {
      MessageAlert.warning('강의를 선택해주세요.');
      return;
    } else if (window.confirm('선택한 강의를 삭제하시겠습니까?')) {
      // 좋아요 깅의리스트 부분
      try {
        NewInterestList.filter((item) =>
          selectLecture.includes(item.lecture_id)
        ).forEach((subitem) => {
          deleteInterestList.mutate({
            interest_list_id: subitem.interest_list_id,
          });
        });
      } catch (e) {
        throw e;
      }
      setSelectLecture([]);
      // setCashCheckAll(false);
      setPointCheckAll(false);
      setTotalPrice(0);
      setTotalTitle('강의를 선택해주세요.');
    } else {
      // console.log('삭제취소:');
    }
  };

  /**
   * 원화 콤마
   * --
   * @param {*} inputValue
   */
  const handleComma = (v) => {
    return [v].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /**
   * 선택헌 강의 리스트 수강신청(장바구니)
   * --
   * @param {*} selectLecture
   */
  const handleApplication = () => {
    if (selectLecture.length === 0) {
      MessageAlert.warning('강의를 선택해주세요.');
      return;
    } else if (window.confirm('선택한 강의를 수강신청 하시겠습니까?')) {
      //TODO: 깅의 장바구니 등록 부분
      try {
        selectLecture
          .filter((it) => !CartList.includes(it))
          .forEach((item) => {
            createInterestList.mutate({
              user_id: idx,
              lecture_id: item,
              type: 'C',
            });
          });
      } catch (e) {
        throw e;
      }
      navigate('/mypage/lecture/cart');
    }
  };

  /**
   * 선택헌 강의 리스트 장바구니 등록
   * --
   * @param {*} selectLecture
   */
  const handleCart = () => {
    if (selectLecture.length === 0) {
      MessageAlert.warning('강의를 선택해주세요.');
      return;
    } else if (window.confirm('선택한 강의를 수강바구니에 담으시겠습니까?')) {
      try {
        selectLecture
          .filter((it) => !CartList.includes(it))
          .forEach((item) => {
            createInterestList.mutate({
              user_id: idx,
              lecture_id: item,
              type: 'C',
            });
          });
      } catch (e) {
        throw e;
      }
      if (window.confirm('수강바구니로 이동하시겠습니까?')) {
        navigate('/mypage/lecture/cart');
      } else {
        MessageAlert.success('중복된 상품을 제외하고 수강바구니에 담았습니다.');
      }
    }
  };

  // console.log(NewInterestList);
  /* ===== Render ===== */
  return (
    <>
      <Content maxWidth={1200} padding={'0 12px 0'}>
        <Row>
          <Col x={24} padding={8}>
            <Title
              size={screenSize >= 768 ? 2 : 4}
              padding={8}
              style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
            >
              찜목록 / 좋아요
            </Title>
          </Col>

          <Col x={24} md={16}>
            <Row
              maxWidth={'100%'}
              padding={0}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row-reverse',
                marginBottom: 10,
              }}
            >
              <Col x={24} md={3}>
                <Button
                  onClick={() => handleListDelete()}
                  style={{ float: 'right' }}
                >
                  선택 삭제
                </Button>
              </Col>
              <Col x={24} md={21}>
                {/* <Checkbox
                  onChange={(e) => onCheckAllChange(e, 'Y')}
                  checked={cashCheckAll}
                >
                  현금 상품 전체 선택
                </Checkbox> */}
                <Checkbox
                  onChange={(e) => onCheckAllChange(e, 'N')}
                  checked={pointCheckAll}
                >
                  포인트 상품 전체 선택
                </Checkbox>
              </Col>
            </Row>
            <Content
              maxWidth={'100%'}
              padding={0}
              style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
            >
              <Col x={24}>
                {/* 좋아요 리스트 */}
                <Content
                  maxWidth={'100%'}
                  padding={'20px 10px 0 10px'}
                  maxHeight={'400px'}
                  minHeight={'400px'}
                  style={{ overflowY: 'scroll' }}
                >
                  {NewInterestList?.length < 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '400px',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    NewInterestList?.map((item, index) => (
                      <Row key={`lecture_list_item_${index}`}>
                        <Col
                          x={24}
                          md={1}
                          style={{
                            display: 'flex',
                            alignItems:
                              screenSize >= 768 ? 'stretch' : 'center',
                          }}
                        >
                          <Checkbox
                            style={{
                              fontWeight: 600,
                              fontSize: '20px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                            }}
                            onChange={() =>
                              onChange(
                                item.lecture_id,
                                item.price,
                                item?.is_cash
                              )
                            }
                            checked={
                              selectLecture.includes(item.lecture_id)
                                ? true
                                : false
                            }
                            disabled={
                              moment(item.start_date).format('YYYY-MM-DD') <=
                                today &&
                              moment(item.end_date).format('YYYY-MM-DD') >=
                                today
                                ? false
                                : true
                            }
                          />
                          {screenSize < 768 && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Title
                                size={5}
                                style={{
                                  color:
                                    moment(item.end_date).format(
                                      'YYYY-MM-DD'
                                    ) >= today
                                      ? ''
                                      : '#d9d9d9',
                                  paddingLeft: 10,
                                }}
                              >
                                {item.title}
                              </Title>
                              <div
                                style={{
                                  width: '10%',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  type="link"
                                  shape="circle"
                                  icon={<CloseOutlined />}
                                  style={{
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                  }}
                                  onClick={() =>
                                    handleDeleteClick(item.interest_list_id)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col x={23}>
                          <Card
                            style={{
                              border: 'none',
                              marginBottom: 25,
                              color:
                                moment(item.start_date).format('YYYY-MM-DD') <=
                                  today &&
                                moment(item.end_date).format('YYYY-MM-DD') >=
                                  today
                                  ? ''
                                  : '#d9d9d9',
                            }}
                            bodyStyle={{ padding: 0 }}
                            hoverable={false}
                          >
                            <div style={{ display: 'flex' }}>
                              {item?.lectureFiles?.length > 0 ? (
                                <div
                                  className="lecture_card_thumbnail02"
                                  style={{
                                    marginRight: 15,
                                    width: screenSize >= 768 ? '20%' : '40%',
                                    height: '80px',
                                    border: '1px solid #E0E0E0',
                                    background: `url(${
                                      item?.lectureFiles?.length > 0 &&
                                      `${STORE_URL}/lecture/${
                                        item?.lectureFiles
                                          ?.filter((i) => i.type === 'T')
                                          ?.pop()?.file_url
                                      }`
                                    }) no-repeat center center/contain`,
                                  }}
                                ></div>
                              ) : (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: '50px',
                                    marginRight: 15,
                                    width: screenSize >= 768 ? '20%' : '40%',
                                    height: '80px',
                                    border: '1px solid #E0E0E0',
                                  }}
                                  icon={faImage}
                                />
                              )}

                              {/*  */}
                              <div
                                style={{
                                  width: screenSize >= 768 ? '50%' : '60%',
                                }}
                              >
                                {screenSize >= 768 && (
                                  <Title
                                    size={5}
                                    style={{
                                      color:
                                        moment(item.start_date).format(
                                          'YYYY-MM-DD'
                                        ) <= today &&
                                        moment(item.end_date).format(
                                          'YYYY-MM-DD'
                                        ) >= today
                                          ? ''
                                          : '#d9d9d9',
                                    }}
                                  >
                                    {item.title}
                                  </Title>
                                )}

                                <p style={{ margin: 0, fontSize: '1em' }}>
                                  {item?.category}
                                </p>
                                <p style={{ margin: 0, fontSize: '0.95em' }}>
                                  {item.name}/({item.nickname})
                                </p>
                                {screenSize < 768 && (
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: '1.3em',
                                        fontWeight: 700,
                                      }}
                                    >
                                      {handleComma(item.price)}
                                      {item?.is_cash === 'Y' ? '원' : 'P'}
                                    </p>
                                  </div>
                                )}
                              </div>
                              {/*  */}
                              {screenSize >= 768 && (
                                <div
                                  style={{
                                    width: '30%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: '1.3em',
                                      fontWeight: 700,
                                    }}
                                  >
                                    {handleComma(item.price)}
                                    {item?.is_cash === 'Y' ? '원' : 'P'}
                                  </p>
                                </div>
                              )}

                              {/*  */}
                              {screenSize >= 768 && (
                                <div
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Button
                                    type="link"
                                    shape="circle"
                                    icon={<CloseOutlined />}
                                    style={{
                                      color: '#333',
                                      fontWeight: 'bold',
                                    }}
                                    onClick={() =>
                                      handleDeleteClick(item.interest_list_id)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    ))
                  )}
                </Content>
              </Col>
            </Content>
          </Col>
          <Col x={0} md={1} />
          <Col x={0} md={7} style={{ paddingTop: '50px' }}>
            <Card
              style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
              hoverable={false}
            >
              <Title size={4} align="left" style={{ marginBottom: '10px' }}>
                {totalTitle}
                {selectLecture.length > 1
                  ? ` 외 ${selectLecture.length - 1} 강`
                  : ''}
              </Title>
              <div>
                <Tag>디지털미래트렌드</Tag>
                <Tag>바로 수강 가능</Tag>
              </div>
              <br />
              <div>
                <Title
                  size={5}
                  subButton={
                    <>
                      <Title align="right">
                        {handleComma(totalPrice)}
                        {isCash === 'Y' ? '원' : 'P'}
                      </Title>
                    </>
                  }
                >
                  강의 금액 합계 :
                </Title>
              </div>
              <Divider style={{ margin: '10px 0' }} />
              <Row>
                <Col x={12}>수료증 발급</Col>
                <Col x={12}>180일 수강가능</Col>
                <Col x={12}>초급자 대상</Col>
                <Col x={12}>만족도99%</Col>
              </Row>
              <Divider style={{ margin: '10px 0' }} />
              {/* <div style={{ display: 'flex' }}>
                <Button block style={{ margin: '0 5px' }}>
                  공유
                </Button>
                <Button block>선물</Button>
              </div> */}
              <Button
                block
                size="large"
                type="primary"
                style={{ marginTop: 7, background: '#E3A4AF', border: 'none' }}
                onClick={() => handleApplication()}
              >
                수강신청 하기
              </Button>
              <Button
                block
                size="large"
                type="primary"
                style={{ marginTop: 7, background: '#AB5476', border: 'none' }}
                onClick={() => handleCart()}
              >
                장바구니 담기
              </Button>
            </Card>
          </Col>
        </Row>
      </Content>

      {screenSize >= 768 ? null : (
        <BottomLayout shadow={true}>
          <Content maxWidth={'100%'} padding={0}>
            <div>
              <Title>{totalTitle}</Title>
              <Title
                size={5}
                color={'#9E9E9E'}
                subButton={
                  <>
                    <Title align="right" color={'#9E9E9E'}>
                      <small>
                        {handleComma(totalPrice)}
                        {isCash === 'Y' ? '원' : 'P'}
                      </small>
                    </Title>
                  </>
                }
              >
                강의 금액 합계
              </Title>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                block
                size="large"
                type="primary"
                style={{
                  marginTop: 7,
                  background: '#E3A4AF',
                  border: 'none',
                  width: '49%',
                }}
                onClick={() => handleApplication()}
              >
                수강신청 하기
              </Button>
              <Button
                block
                size="large"
                type="primary"
                style={{
                  marginTop: 7,
                  background: '#AB5476',
                  border: 'none',
                  width: '49%',
                }}
                onClick={() => handleCart()}
              >
                장바구니 담기
              </Button>
            </div>
          </Content>
        </BottomLayout>
      )}
    </>
  );
};

LectureFavoritesPresenter.defualtProps = {};

export default LectureFavoritesPresenter;
