/**
 * CourseStatusPresenter
 * --
 */
import React, { useEffect, useState, useRef } from 'react';
import { Content, Row, Col, Title, Button as Btn } from 'components';
import { Input, Avatar, Table, Space, Button, Select, Tag, Tabs } from 'antd';
import moment from 'moment';
import { decrypt, encrypt } from 'utils/Crypto';
import { DOMAIN_URL, STORE_URL } from 'utils';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const CourseStatusPresenter = (props) => {
  /* ===== ROUTE ===== */
  /* ===== PROPS ===== */
  const {
    data,
    currentPage,
    onPagination,
    filterOptions,
    filterValue,
    setFilterValue,
    onSelect,
    lectureId,
    onDetailPopup,
    lecture,
    onSendMessage,
  } = props;

  /* ===== STATE ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [boardData, setBoardData] = useState([]);
  const [tabKey, setTabKey] = useState('1');

  /* ===== VARIABLE ===== */
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${title}을(를) 입력해주세요`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            검색
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            초기화
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '수강생 명',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', '수강생 명'),
      render: (_, item) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            width={50}
            height={50}
            preview={false}
            src={
              item?.profile
                ? `${STORE_URL}/users/${item?.user_id}/${item?.profile?.file_url}`
                : item?.name
            }
          />
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            <p style={{ margin: 0 }}>{item?.name}</p>
          </Title>
        </Content>
      ),
    },
    {
      title: '강의 구매일',
      dataIndex: 'payment_date',
      key: 'payment_date',
      sorter: (a, b) => new Date(b?.payment_date) - new Date(a?.payment_date),
      render: (_, { payment_date }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {moment(payment_date)?.format('YYYY-MM-DD')}
        </Title>
      ),
    },
    {
      title: '강의 시작일',
      dataIndex: 'lecture_start_date',
      key: 'lecture_start_date',
      sorter: (a, b) =>
        new Date(b?.lecture_start_date) - new Date(a?.lecture_start_date),
      render: (_, { lecture_start_date }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {lecture_start_date?.length
            ? moment(lecture_start_date)?.format('YYYY-MM-DD HH:mm')
            : '-'}
        </Title>
      ),
    },
    {
      title: '최근 수강일',
      dataIndex: 'lecture_last_date',
      key: 'lecture_last_date',
      sorter: (a, b) =>
        new Date(b?.lecture_last_date) - new Date(a?.lecture_last_date),
      render: (_, { lecture_last_date }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {lecture_last_date?.length
            ? moment(lecture_last_date)?.format('YYYY-MM-DD HH:mm')
            : '-'}
        </Title>
      ),
    },
    {
      title: '진행률',
      dataIndex: 'progress_status',
      key: 'progress_status',
      sorter: (a, b) => b?.progress_status - a?.progress_status,
      render: (_, { progress_status }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {progress_status?.toFixed(2)}%
        </Title>
      ),
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: 100,
      render: (_, item) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            size="small"
            onClick={() => onSendMessage('email', item)}
            style={{ marginRight: 5 }}
          >
            메일 전송
          </Button>
          <Button size="small" onClick={() => onSendMessage('sms', item)}>
            문자 전송
          </Button>
        </Content>
      ),
    },
  ];
  const columns2 = [
    {
      title: '게시판',
      dataIndex: 'type',
      key: 'type',
      render: (_, { type }) => (
        <Tag
          color={
            type === 'INQUIRY'
              ? 'blue'
              : type === 'DISCUSSION'
              ? 'green'
              : 'red'
          }
        >
          {
            filterOptions
              ?.find((item) => item?.value === type)
              .label.split('게시판')[0]
          }
        </Tag>
      ),
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title', '제목'),
      render: (_, { title, task_status, type }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
          align={'left'}
        >
          {type === 'ASSIGNMENT' && (
            <span style={{ paddingRight: 5 }}>
              {task_status === 'PASS' ? (
                <Tag color="#2AC769">통과</Tag>
              ) : task_status === 'FAIL' ? (
                <Tag color="#FB4E4E">불통</Tag>
              ) : (
                <Tag color="#E0E0E0">확인중</Tag>
              )}
            </span>
          )}
          {title}
        </Title>
      ),
    },
    {
      title: '글쓴이',
      dataIndex: 'writer',
      key: 'writer',
      ...getColumnSearchProps('writer', '글쓴이'),
      render: (_, item) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            width={50}
            height={50}
            preview={false}
            src={
              item?.profile
                ? `${STORE_URL}/users/${item?.user_id}/${item?.profile?.file_url}`
                : item?.name
            }
          />
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            <p style={{ margin: 0 }}>{item?.writer}</p>
          </Title>
        </Content>
      ),
    },
    {
      title: '댓글',
      dataIndex: 'comment',
      key: 'comment',
      render: (_, item) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            <p style={{ margin: 0 }}>{item?.comment.length}</p>
          </Title>
        </Content>
      ),
    },
    {
      title: '등록일',
      dataIndex: 'board_created_at',
      key: 'board_created_at',
      sorter: (a, b) =>
        new Date(b?.board_created_at) - new Date(a?.board_created_at),
      render: (_, { board_created_at }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {moment(board_created_at)?.format('YYYY-MM-DD HH:mm')}
        </Title>
      ),
    },
    {
      title: '수정일',
      dataIndex: 'board_updated_at',
      key: 'board_updated_at',
      sorter: (a, b) =>
        new Date(b?.board_updated_at) - new Date(a?.board_updated_at),
      render: (_, { board_updated_at, board_created_at }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {board_updated_at !== board_created_at
            ? moment(board_updated_at)?.format('YYYY-MM-DD HH:mm')
            : '-'}
        </Title>
      ),
    },
  ];

  /* ===== FUNCTION ===== */
  /**
   * 게시판 팝업
   * --
   */
  const handlePopup = (e, item) => {
    e.stopPropagation();
    const domain = DOMAIN_URL;

    window.open(
      `${domain}/#/popup/board?lecture=${encodeURIComponent(
        encrypt(item)
      )}&type=inquiry`,
      '팝업',
      'width=1500, height=850'
    );
  };

  /* ===== HOOKS ===== */
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /**
   * 게시판 데이터 필터
   * --
   */
  useEffect(() => {
    const d = data?.board?.sort(
      (a, b) => new Date(b?.board_created_at) - new Date(a?.board_created_at)
    );

    const newData = d?.filter((item) =>
      filterValue === 'ALL' ? item : item?.type === filterValue
    );
    setBoardData(newData);
  }, [data, filterValue]);

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'} padding={0} style={{ padding: '48px 0 84px' }}>
      <Content
        maxWidth={1200}
        padding={screenSize >= 1200 ? 0 : '0 30px'}
        backgroundColor={'none'}
      >
        <Row>
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              padding={0}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title size={2}>{lecture?.title}</Title>
              <Select
                size="large"
                value={parseInt(decrypt(lectureId))}
                options={data?.lectures?.map((item) => ({
                  label: item.title,
                  value: item.lecture_id,
                }))}
                style={{ width: 300 }}
                onChange={(e) => onSelect(e)}
              />
            </Content>
          </Col>
          <Col x={24} style={{ padding: 5 }}>
            <Tabs
              size="large"
              activeKey={tabKey}
              onTabClick={(k) => setTabKey(k)}
            >
              <Tabs.TabPane tab="수강 현황" key={'1'}>
                <Content maxWidth={'100%'} padding={0}>
                  <Table
                    columns={columns}
                    dataSource={lecture?.student?.sort(
                      (a, b) =>
                        new Date(b?.payment_date) - new Date(a?.payment_date)
                    )}
                    showSorterTooltip={false}
                    pagination={{
                      position: ['bottomCenter'],
                      current: currentPage,
                      onChange: (e) => onPagination(e),
                    }}
                  />
                </Content>
              </Tabs.TabPane>
              <Tabs.TabPane tab="게시판" key={'2'}>
                <Content maxWidth={'100%'} padding={0}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 30,
                    }}
                  >
                    <Select
                      size="large"
                      style={{ width: 300 }}
                      options={filterOptions}
                      value={filterValue}
                      onChange={(e) => setFilterValue(e)}
                    />
                    <Btn
                      layoutStyle={{ margin: 0 }}
                      onClick={(e) => handlePopup(e, decrypt(lectureId))}
                    >
                      게시판
                    </Btn>
                  </div>
                  <Table
                    columns={columns2}
                    dataSource={boardData}
                    showSorterTooltip={false}
                    pagination={{
                      pageSize: 15,
                      position: ['bottomCenter'],
                      current: currentPage,
                      onChange: (e) => onPagination(e),
                    }}
                    onRow={(record) => {
                      return {
                        onClick: (e) => onDetailPopup(e, record),
                        style: {
                          cursor: 'pointer',
                        },
                      };
                    }}
                  />
                </Content>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default CourseStatusPresenter;
