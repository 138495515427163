import React from 'react';
import ProductCartPresenter from './ProductCartPresenter';
import { useQueryClient } from 'react-query';
import { getCookie } from 'utils';
import { ProductApi, UserApi, DeliveryAddressApi, AgencyApi } from 'api';

const ProductCartContainer = ({ loginWho }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const queryClient = useQueryClient();

  const idx = parseInt(getCookie('USER'));
  const user =
    loginWho === 'agency'
      ? AgencyApi.GetAgency(idx)?.data?.data
      : UserApi.GetUser(idx)?.data?.data;
  const recommend = ProductApi.GetRecommendProducts()?.data;
  const products = queryClient.getQueryData('getProducts')?.data;
  const productFiles = queryClient.getQueryData('getProductFiles')?.data;
  const productCategories = queryClient.getQueryData(
    'getProductCategories'
  )?.data;
  const productCarts = queryClient.getQueryData('getProductCarts')?.data;
  const purchases = queryClient.getQueryData('getPurchases')?.data;
  const address = DeliveryAddressApi.GetDeliveryAddresses(
    loginWho === 'agency'
      ? {
          agency_id: idx,
        }
      : {
          user_id: idx,
        }
  )?.data?.data;

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <ProductCartPresenter
      user={user}
      products={products}
      productFiles={productFiles}
      productCarts={productCarts}
      purchases={purchases}
      productCategories={productCategories}
      idx={idx}
      recommend={recommend?.data}
      address={address}
    />
  );
};

ProductCartContainer.defaultProps = {};

export default ProductCartContainer;
