import React, { useState, useEffect } from 'react';
import AgencyCalculatePresenter from './AgencyCalculatePresenter';
import { TotalCalculateApi, TemplateApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { LoadingPage } from 'components';

const AgencyCalculateContainer = ({ data }) => {
  /* ===== PROPS ===== */
  const { users, teachers, agencies, categories } = data;

  /* ===== STATE ===== */
  const [htmlTemplate, setHtmlTemplate] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newTeachers, setNewTeachers] = useState([]);

  const getHtml = TemplateApi.GetTemplatePdf({
    onSuccess: async (d) => {
      const { status, data } = d;

      if (status === 200) {
        setHtmlTemplate(data?.html);
      }
    },
  });
  const template = TemplateApi.GetTemplatePdf({
    onSuccess: async (d) => {
      const { status, data } = d;

      if (status === 200) {
        const uint8Array = new Uint8Array(data.pdf.data);
        const blob = new Blob([uint8Array], {
          type: 'application/pdf',
        });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${data.title ? data?.title : 'brainheal'}.pdf`;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      }
    },
  });

  /* ===== FUNCTION ===== */
  /**
   * 계산서 엑셀파일 다운로드
   * --
   */
  const handleExcelDownload = async (y, m) => {
    const { status, data } = await TotalCalculateApi.GetTotalCalculateExcel({
      year: y,
      month: m,
    });
    if (status !== 200) {
      return MessageAlert.error('엑셀파일을 다운로드하는데 실패하였습니다.');
    } else {
      try {
        const file_name = `${y}-${m} 계산서(종합).xlsx`;
        const uint8Array = new Uint8Array(data.data);
        const blob = new Blob([uint8Array], {
          type: 'application/octet-stream',
        });
        const downloadURL = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadURL;
        a.download = file_name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadURL);
      } catch (err) {
        console.log('파일 다운로드 에러!', err);
        throw err;
      }
    }
  };

  /**
   * html 템플릿 호출
   * --
   */
  const handleDownload = (type, data) => {
    const txt = type === 'bill' ? '청구서' : '계산서';
    if (!data) {
      return MessageAlert.warning(`등록된 ${txt}가 없습니다!`);
    }

    try {
      getHtml.mutate({
        type: type,
        template_value: {
          ...data,
        },
        return_type: 'html',
      });
      setIsOpened(!isOpened);
    } catch (err) {
      console.log('HTML 호출 실패!', err);
    }
  };

  /**
   * PDF 다운로드
   * --
   */
  const handlePDFDownload = (type, d) => {
    try {
      setIsLoading(true);
      const title = `${d?.year}-${d?.month} ${d[type]?.name} ${
        type === 'bill' ? '청구서' : '계산서'
      }`;
      template.mutate({
        type: type,
        template_value: {
          title: title,
          ...d[type],
        },
      });
    } catch (err) {
      console.log('PDF 다운로드 실패!', err);
      MessageAlert.error('PDF 파일을 다운로드 하는데 실패하였습니다');
      throw err;
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    if (!isOpened) setHtmlTemplate('');
  }, [isOpened]);

  useEffect(() => {
    if (users && teachers) {
      const newTeahcerList = users.concat(teachers);
      setNewTeachers(newTeahcerList);
    }
  }, [users, teachers]);

  /* ===== RENDER ===== */
  return (
    <>
      {isLoading ? <LoadingPage text={'다운로드 준비중...'} /> : null}
      <AgencyCalculatePresenter
        teachers={newTeachers}
        agencies={agencies}
        categories={categories}
        onExcelDownload={handleExcelDownload}
        onDownload={handleDownload}
        htmlTemplate={htmlTemplate}
        opened={isOpened}
        setOpened={setIsOpened}
        onPDFDownload={handlePDFDownload}
      />
    </>
  );
};

export default AgencyCalculateContainer;
