import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 상품 장바구니 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetProductCarts: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getProductCarts', params] : 'getProductCarts',
        async () => await $http.get(APIConstant.GET_PRODUCT_CARTS, params),
        options
      );
      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 장바구니 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetProductCart: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_PRODUCT_CART, {
        product_cart_id: idx,
      });
      const res = useQuery(
        params ? ['getProductCart', params] : 'getProductCart',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 장바구니 생성
   * --
   * @method POST
   * @returns
   */
  CreateProductCart: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_PRODUCT_CART,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getProductCarts');
            queryClient.invalidateQueries('getProductCart');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 장바구니 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateProductCart: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_PRODUCT_CART, {
            product_cart_id: data.product_cart_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getProductCarts');
            queryClient.invalidateQueries('getProductCart');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 장바구니 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteProductCart: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_PRODUCT_CART, {
            product_cart_id: data.product_cart_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getProductCarts');
            queryClient.invalidateQueries('getProductCart');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
