import React from 'react';
import ProfilePresenter from './ProfilePresenter';
import { useQueryClient } from 'react-query';

const ProfileContainer = ({ data }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const queryClient = useQueryClient();
  const area = queryClient.getQueryData('getAddressSplit')?.data;
  const subway = queryClient.getQueryData('getSubways')?.data;

  // console.log('area', area);

  const Data = {
    user: data && data?.user,
    profile: data?.profile && {
      ...data?.profile,
      gallery: data?.teacherFiles?.filter((item) => item?.type === 'G'),
      attachment: data?.teacherFiles?.filter((item) => item?.type === 'E'),
      certificate: data?.teacherFiles?.filter((item) => item?.type === 'C'),
      profileImage: data?.teacherFiles
        ?.filter((item) => item?.type === 'P')
        ?.pop(),
    },
    preference_area:
      data &&
      data?.preference_area?.map((item) => ({
        ...item,
        ...(area &&
          area.local &&
          area.local?.filter(
            (subitem) => subitem.local_id === item.local_id
          )[0]),
        ...(area &&
          area.city &&
          area.city?.filter((subitem) => subitem.city_id === item.city_id)[0]),
        ...(area &&
          area.district &&
          area.district?.filter(
            (subitem) => subitem.district_id === item.district_id
          )[0]),
      })),
    preference_subway:
      data &&
      data?.preference_subway?.map((item) => ({
        ...item,
        ...(subway &&
          subway?.filter((subitem) => subitem.subway_id === item.subway_id))[0],
      })),
    sns: data && data?.sns,
    major: data && data?.major,
  };
  // console.log('data', data);
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <ProfilePresenter
      user={Data?.user}
      profile={Data?.profile}
      area={Data?.preference_area}
      subway={Data?.preference_subway}
      sns={Data?.sns}
      major={Data?.major}
    />
  );
};

ProfileContainer.defaultProps = {};

export default ProfileContainer;
