import React from 'react';
import './NotFoundPage.style.css';
import LOGO from 'assets/brainheal_logo.png';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="notfound-layout">
      <div className="notfound-container">
        <img
          src={LOGO}
          alt=""
          className="hover-opacity"
          style={{ width: '300px', height: '150px' }}
        />

        <div>
          <h4>
            죄송합니다.
            <br />
            요청하신 페이지를 찾을 수 없습니다.
          </h4>
          <p>
            방문하시려는 페이지의 주소가 잘못 입력되었거나,
            <br />
            페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수
            없습니다.
          </p>
          <p>입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.</p>
          <p>
            관련 문의사항은 070-7701-7077로 문의주시면 친절하게 안내해
            드리겠습니다.
          </p>
          <p>감사합니다.</p>
        </div>

        <Button
          style={{
            width: '300px',
            height: '50px',
            fontSize: '18px',
            marginTop: '80px',
          }}
          onClick={() => navigate('/')}
        >
          홈으로
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
