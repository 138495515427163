import React, { useState, useEffect, useRef } from 'react';
import './DatePicker.style.css';
import { Label, StatusText } from 'components';

const DatePicker = ({
  label,
  placeholder,
  labelStyle,
  essential,
  inputId,
  statusText,
  status,
  width,
  height,
  margin,
  padding,
  disabled,
  fontSize,
  fontWeight,
  setValue,
  onChange,
  value,
  minRange,
  maxRange,
  defaultValue,
  type,
}) => {
  /* ===== STATE ===== */
  // input 박스 focus 상태 설정
  const [inputState, setInputState] = useState(false);
  // 상태에 따른 border, background 설정
  const [borderColor, setBorderColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  const dateInput = useRef(null);

  /* ===== RENDER ===== */
  useEffect(() => {
    // 상태에 따른 border 색, background 색 설정
    if (status === 'Default' || status === 'default') {
      setBorderColor('1px solid #BDBDBD');
      setBackgroundColor('#FFFFFF');

      // setSpanText('');
    } else if (status === 'Warning' || status === 'warning') {
      setBorderColor('1px solid #FFE146');
      setBackgroundColor('#FFFBF4');

      // setSpanText('Warning');
    } else if (status === 'Error' || status === 'error') {
      setBorderColor('1px solid #FF6262');
      setBackgroundColor('#FFF5F5');

      // setSpanText('Error');
    } else if (status === 'Loading' || status === 'loading') {
      setBorderColor('1px solid #BDBDBD');
      setBackgroundColor('#FFFFFF');

      // setSpanText('Loading');
    } else if (status === 'Success' || status === 'success') {
      setBorderColor('1px solid #40DD7F');
      setBackgroundColor('#F5FFF6');

      // setSpanText('Success');
    }
  }, [status]);

  return (
    <div
      className="datepicker-layout-background"
      style={{
        width: `${width}`,
      }}
    >
      {label !== '' && (
        <Label
          label={label}
          labelStyle={labelStyle}
          essential={essential}
          inputId={inputId}
        />
      )}
      <div
        className="datepicker-layout"
        onClick={() => dateInput.current.showPicker()}
        style={{
          height: `${height}`,
          margin: `${margin}`,
          border: inputState
            ? '1px solid #03A9F4'
            : !disabled
            ? `${borderColor}`
            : 'none',
          background: !disabled ? `${backgroundColor}` : '#F5F5F5',
          padding: `${padding}`,
        }}
      >
        <input
          id={inputId}
          ref={dateInput}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          min={minRange}
          max={maxRange}
          onFocus={() => setInputState(true)}
          onBlur={() => setInputState(false)}
          value={value ? value : defaultValue}
          style={{
            fontSize: `${fontSize}`,
            fontWeight: `${fontWeight}`,
            color: value ? '' : '#d6d6d6',
          }}
          onChange={onChange}
        />
      </div>
      {statusText !== '' && (
        <StatusText status={status} statusText={statusText} />
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  label: '',
  placeholder: '날짜를 선택해주세요',
  status: 'Default',
  statusText: '',
  essential: false,
  inputId: '',
  inputStyle: { fontSize: '16px', fontWeight: 400 },
  width: '340px',
  height: '56px',
  margin: 0,
  fontSize: '18px',
  fontWeight: 400,
  padding: '20px 10px',
  type: 'date',
};

export default DatePicker;
