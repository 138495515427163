import React, { useState, useEffect } from 'react';
import MyCalculatePresenter from './MyCalculatePresenter';
import { ScheduleApi, CalculateApi, TemplateApi, TotalCalculateApi } from 'api';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { getCookie } from 'utils';
import { useQueryClient } from 'react-query';
import MessageAlert from 'utils/MessageAlert';
import { LoadingPage } from 'components';

const MyCalculateContainer = ({ data, screenSize }) => {
  /* ===== ROUTE ===== */
  /* ===== PROPS ===== */
  /* ===== STATE ===== */
  const uid = getCookie('USER');
  // 접속자 확인 (수강생, 강사, 기관)
  const loginWho = useRecoilValue(loginUser);
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [newCalculates, setNewCalculates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [htmlTemplate, setHtmlTemplate] = useState(null);
  const [newTotalCalculate, setNewTotalCalculate] = useState(null);

  /* ===== MUATE ===== */
  const queryClient = useQueryClient();
  const { data: schedules, refetch: scheduleRefetch } =
    ScheduleApi.GetSchedules(
      loginWho === 'agency'
        ? {
            agency_id: parseInt(uid),
            date: `${year}-${month}`,
          }
        : {
            user_id: parseInt(uid),
            date: `${year}-${month}`,
          },
      {
        enabled: uid && year && month ? true : false,
      }
    );
  const { data: calculate, refetch: calculateRefetch } =
    CalculateApi.GetCalculates(
      loginWho === 'agency'
        ? {
            agency_id: parseInt(uid),
            date: `${year}-${month}`,
          }
        : {
            user_id: parseInt(uid),
            date: `${year}-${month}`,
          }
    );
  const { data: totalCalculate, refetch: totalCalculateRefetch } =
    TotalCalculateApi.GetTotalCalculates(
      {
        agency_id: parseInt(uid),
      },
      {
        enabled: loginWho === 'agency' ? true : false,
      }
    );
  const template = TemplateApi.GetTemplatePdf({
    onSuccess: async (d) => {
      const { status, data } = d;

      if (status === 200) {
        const uint8Array = new Uint8Array(data.pdf.data);
        const blob = new Blob([uint8Array], {
          type: 'application/pdf',
        });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${data.title ? data?.title : 'brainheal'}.pdf`;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      }
    },
  });
  const getHtml = TemplateApi.GetTemplatePdf({
    onSuccess: async (d) => {
      const { status, data } = d;

      if (status === 200) {
        setHtmlTemplate(data?.html);
      }
    },
  });

  /* ===== FUNCTION ===== */
  /**
   * 날짜 값 변경
   * --
   */
  const handleDate = (type) => {
    let m = month;
    if (type === 'prev') {
      --m;

      if (m === 0) {
        setMonth(12);
        setYear(year - 1);
      } else {
        setMonth(m);
      }
    } else {
      ++m;

      if (m > 12) {
        setMonth(1);
        setYear(year + 1);
      } else {
        setMonth(m);
      }
    }
  };

  /**
   * 진행시간 계산
   * --
   */
  const handleCalculateTime = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const timeDifference = endDate - startDate;
    const hours = timeDifference / (1000 * 60 * 60);

    return hours;
  };

  /**
   * PDF 다운로드
   * --
   */
  const handlePDFDownload = (type, d) => {
    try {
      setIsLoading(true);
      template.mutate({
        type: type,
        template_value: {
          title: `${year}-${month} ${
            type === 'tax_bill'
              ? `${d?.name} 계산서`
              : type === 'bill'
              ? `${d?.program} 청구서`
              : `${d?.program} 정산서`
          }`,
          ...d,
        },
      });
    } catch (err) {
      console.log('PDF 다운로드 실패!', err);
      MessageAlert.error('PDF 파일을 다운로드 하는데 실패하였습니다');
      throw err;
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  /**
   * 템플릿 호출
   * --
   */
  const handleHtmlTemplate = (type, d) => {
    try {
      getHtml.mutate({
        type: type,
        template_value: {
          ...d,
        },
        return_type: 'html',
      });
    } catch (err) {
      console.log('템플릿 호출 실패!', err);
      MessageAlert.error('PDF 파일을 호출 하는데 실패하였습니다');
      throw err;
    }
  };

  /* ===== HOOKS ===== */
  /**
   * 년/월에 따른 스케쥴/정산 데이터 호출
   * --
   */
  useEffect(() => {
    scheduleRefetch();
    calculateRefetch();
  }, [uid, year, month, scheduleRefetch, calculateRefetch]);

  /**
   * 정산 데이터 정제
   * --
   */
  useEffect(() => {
    if (schedules?.status === 200) {
      let scheduleList = [],
        repetitionScheduleList = [];
      schedules?.data?.forEach((item) => {
        if (item?.schedule_type === 'S') {
          if (item?.repetition_schedule_id) {
            repetitionScheduleList.push(item);
          } else {
            scheduleList?.push(item);
          }
        }
      });
      const groupedByRepetitionId = repetitionScheduleList?.reduce(
        (acc, item) => {
          const repetitionId = item?.repetition_schedule_id;

          if (!repetitionId) {
            if (!acc[null]) {
              acc[null] = [];
            }
            acc[null].push(item);
          } else {
            // repetition_schedule_id가 있는 경우
            if (!acc[repetitionId]) {
              acc[repetitionId] = [];
            }
            acc[repetitionId].push(item);
          }

          return acc;
        },
        {}
      );
      const resultArray = Object.values(groupedByRepetitionId);

      const categories = queryClient.getQueryData('getCategories')?.data;
      if (data) {
        if (loginWho === 'agency') {
          const { agency } = data;
          const users = queryClient?.getQueryData('getUsers')?.data;

          const newData = [
            ...(resultArray &&
              resultArray?.map((item) => {
                const userData = users?.filter(
                  (subitem) => item[0]?.user_id === subitem?.user_id
                )[0];
                const categoryData = categories?.filter(
                  (subitem) => item[0]?.category_id === subitem?.category_id
                )[0];

                return {
                  user_id: userData?.user_id,
                  user_name: userData?.name,
                  user_email: userData?.email,
                  agency_id: agency?.agency_id,
                  agency_name: agency?.name,
                  agency_address: agency?.address,
                  agency_address_detail: agency?.address_detail,
                  agency_email: agency?.email,
                  chief_name: agency?.chief_name,
                  company_number: agency?.company_number,
                  category_name: categoryData?.category_name,
                  type: 'repetition_schedule',
                  parents_name: categories?.filter(
                    (subitem) =>
                      subitem?.category_id === categoryData?.parents_id
                  )[0]?.category_name,
                  schedules: [...item],
                  calculate: calculate?.data?.filter(
                    (subitem) =>
                      subitem?.repetition_schedule_id ===
                      item[0]?.repetition_schedule_id
                  )[0],
                };
              })),
            ...(scheduleList &&
              scheduleList?.map((item) => {
                const userData = users?.filter(
                  (subitem) => item?.user_id === subitem?.user_id
                )[0];
                const categoryData = categories?.filter(
                  (subitem) => item?.category_id === subitem?.category_id
                )[0];

                return {
                  user_id: userData?.user_id,
                  user_name: userData?.name,
                  user_email: userData?.email,
                  agency_id: agency?.agency_id,
                  agency_name: agency?.name,
                  agency_address: agency?.address,
                  agency_address_detail: agency?.address_detail,
                  category_name: categoryData?.category_name,
                  parents_name: categories?.filter(
                    (subitem) =>
                      subitem?.category_id === categoryData?.parents_id
                  )[0]?.category_name,
                  type: 'schedule',
                  schedules: [item],
                  calculate: calculate?.data?.filter(
                    (subitem) => subitem?.schedule_id === item?.schedule_id
                  )[0],
                };
              })),
          ];
          setNewCalculates(newData);
        } else {
          const { user } = data;
          const agencies = queryClient.getQueryData('getAgencies')?.data;

          const newData = [
            ...(resultArray &&
              resultArray?.map((item) => {
                const agencyData = agencies?.filter(
                  (subitem) => item[0]?.agency_id === subitem?.agency_id
                )[0];
                const categoryData = categories?.filter(
                  (subitem) => item[0]?.category_id === subitem?.category_id
                )[0];

                return {
                  user_id: user?.user_id,
                  user_name: user?.name,
                  user_email: user?.email,
                  agency_id: agencyData?.agency_id,
                  agency_name: agencyData?.name,
                  agency_address: agencyData?.address,
                  agency_address_detail: agencyData?.address_detail,
                  agency_email: agencyData?.email,
                  chief_name: agencyData?.chief_name,
                  company_number: agencyData?.company_number,
                  category_name: categoryData?.category_name,
                  type: 'repetition_schedule',
                  parents_name: categories?.filter(
                    (subitem) =>
                      subitem?.category_id === categoryData?.parents_id
                  )[0]?.category_name,
                  schedules: [...item],
                  calculate: calculate?.data?.filter(
                    (subitem) =>
                      subitem?.repetition_schedule_id ===
                      item[0]?.repetition_schedule_id
                  )[0],
                };
              })),
            ...(scheduleList &&
              scheduleList?.map((item) => {
                const agencyData = agencies?.filter(
                  (subitem) => item?.agency_id === subitem?.agency_id
                )[0];
                const categoryData = categories?.filter(
                  (subitem) => item?.category_id === subitem?.category_id
                )[0];

                return {
                  user_id: user?.user_id,
                  user_name: user?.name,
                  user_email: user?.email,
                  agency_id: agencyData?.agency_id,
                  agency_name: agencyData?.name,
                  agency_address: agencyData?.address,
                  agency_address_detail: agencyData?.address_detail,
                  category_name: categoryData?.category_name,
                  parents_name: categories?.filter(
                    (subitem) =>
                      subitem?.category_id === categoryData?.parents_id
                  )[0]?.category_name,
                  type: 'schedule',
                  schedules: [item],
                  calculate: calculate?.data?.filter(
                    (subitem) => subitem?.schedule_id === item?.schedule_id
                  )[0],
                };
              })),
          ];
          setNewCalculates(newData);
        }
      }
    }
  }, [schedules, calculate, loginWho, queryClient, data]);

  /**
   * 총 정산 데이터 정제
   * --
   */
  useEffect(() => {
    if (loginWho === 'agency') {
      totalCalculateRefetch();
    }
  }, [loginWho, totalCalculateRefetch, uid]);

  /**
   * 년/월에 따른 총 정산 데이터 정제
   * --
   */
  useEffect(() => {
    if (loginWho === 'agency' && totalCalculate?.status === 200) {
      const findData = totalCalculate?.data?.filter(
        (item) =>
          parseInt(item?.year) === year && parseInt(item?.month) === month
      )[0];

      setNewTotalCalculate(findData ? findData : null);
    } else {
      const findData = calculate?.data
        ?.filter((item) => item?.teacher_bill)
        ?.pop();

      setNewTotalCalculate(findData);
    }
  }, [totalCalculate, calculate, year, month, loginWho]);

  /* ===== RENDER ===== */
  return (
    <>
      {isLoading ? <LoadingPage text={'다운로드 준비중...'} /> : null}
      <MyCalculatePresenter
        year={year}
        month={month}
        onDate={handleDate}
        calculates={newCalculates}
        loginWho={loginWho}
        schedules={schedules?.data}
        onCalculateTime={handleCalculateTime}
        onPDFDownload={handlePDFDownload}
        htmlTemplate={htmlTemplate}
        onHtmlTemplate={handleHtmlTemplate}
        totalCalculate={newTotalCalculate}
        screenSize={screenSize}
      />
    </>
  );
};

export default MyCalculateContainer;
