/**
 * 회원가입 페이지
 * --
 */
import React, { useState } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  Button,
  Label,
  DatePicker,
  DaumAddress,
} from 'components';
import { Form, Input, Select, Checkbox } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import MessageAlert from 'utils/MessageAlert';
import { UserApi, AgencyApi, NiceApi, AuthApi, AgencyStorageApi } from 'api';
import { useEffect, useRef } from 'react';
import { Validation, deleteCookie, getCookie } from 'utils';
import validateCheck from 'utils/Validation';

/**
 * 회원가입 페이지
 * --
 * @userValue : 1:개인회원 / 2:강사회원 / 3:기관회원
 */
function Join03({
  userValue,
  agencyData,
  ValueCheck,
  OnlyNumber,
  OnlyEngNum,
  AddressSplit,
  getUsers,
}) {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();
  const location = useLocation();

  const encodeData = getCookie('encodeData');
  const from = location.state?.from?.pathname; //url 접근 제어
  const radioValue = location.state?.radioValue; //url 접근 제어
  // console.log(from);

  /* ===== Mutate ===== */
  const createUser = UserApi.CreateUser({
    onSuccess: () => {
      MessageAlert.success('가입되었습니다');
      //이전 페이지 전달 : url 접근 제어
      navigate('/login', { replace: true });
    },
  });
  const createAgency = AgencyApi.CreateAgency({
    onSuccess: () => {
      MessageAlert.success('가입되었습니다');
      //이전 페이지 전달 : url 접근 제어
      navigate('/login', { replace: true });
    },
  });
  const createAgencyStorage = AgencyStorageApi.CreateAgencyStorage();

  /* ===== STATE ===== */
  const [data, setData] = useState({
    id: '',
    password: '',
    password_check: '',
    name: radioValue === 3 ? agencyData?.agency_name : '',
    email: radioValue === 3 ? agencyData?.agency_email : '',
    phone: radioValue === 3 ? agencyData?.agency_phone : '',
    birthday: '',
    chief_name: radioValue === 3 ? agencyData?.chief_name : '',
    address: radioValue === 3 ? agencyData?.agency_address : '',
    address_detail: radioValue === 3 ? agencyData?.agency_address_detail : '',
    recommender_id: '',
    sms_check: false,
    email_check: false,
    //user data
    gender: 'M',
    nickname: undefined,
    job: undefined,
  });
  let newData = {};

  const [checkList, setCheckList] = useState({
    userAccountCheck: false,
    passwordCheck: false,
    passwordDoubleCheck: false,
  });

  const [doubleCheckClick, setDoubleCheckClick] = useState('N');
  // 12세 미만 회원가입 제한
  const [maxRange, setMaxRange] = useState('');

  const inputPasswordRef = useRef();
  const inputID = useRef();

  // 유저데이터 배열화
  let userAccount = [];
  getUsers?.map((item) => userAccount.push(item.user_account));

  //성별 상태
  const genderlist = [
    { value: 'M', label: '남자' },
    { value: 'F', label: '여자' },
  ];

  // console.log('userAccount', userAccount);
  // console.log(userValue);
  // console.log(radioValue);
  // console.log(agencyData);

  // TODO: 유효성 검사

  /* ===== FUNCTION ===== */
  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    //ID 중복검사 이후 수정 제어
    if (t === 'id') {
      setDoubleCheckClick('N');
    }
    let newData = { ...data };

    if (t === 'local_id') {
      newData['city_id'] = '';
      newData['district_id'] = '';
    } else if (t === 'city_id') {
      newData['district_id'] = '';
    }
    newData[t] = v;
    const d = {
      ...newData,
    };
    setData(d);

    if (callback) callback(v);
  };

  const handleCheckStateChange = (t, v, callback = null) => {
    let newData = { ...checkList };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setCheckList(d);

    if (callback) callback(v);
  };

  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = ''; //Chrome에서 동작하도록; deprecated
  };

  /**
   * ID 중복 검사
   * --
   * @
   */
  const handleDoubleCheck = async () => {
    if (data.id.length < 6) {
      inputID.current.focus();
      MessageAlert.warning('아이디는 6자 이상으로 입력해주세요.');
      return;
    } else {
      if (!ValueCheck(data.id, '아이디')) {
        inputID.current.focus();
        return;
      }

      // 아이디에 한글이 포함되었을 경우
      if (/[\u3131-\uD79D]/.test(data.id)) {
        MessageAlert.warning('아이디는 영문, 숫자로만 입력가능합니다.');
        inputID.current.focus();
        return;
      }

      try {
        const { status } = await AuthApi.authCheck(
          radioValue === 1
            ? {
                type: 'user',
                user_account: data.id,
              }
            : {
                type: 'agency',
                agency_account: data.id,
              }
        );

        if (status === 403) {
          MessageAlert.warning('사용불가능한 아이디 입니다.');
          handleCheckStateChange('userAccountCheck', true);
          inputID.current.focus();
          setDoubleCheckClick('R');
        } else if (status === 200) {
          MessageAlert.success('사용가능한 아이디입니다.');
          handleCheckStateChange('userAccountCheck', false);
          inputID.current.focus();
          setDoubleCheckClick('Y');
        }
      } catch (err) {
        console.log('중복검사 에러!', err);
        throw err;
      }
    }
  };

  /**
   * 핸드폰 번호 자동 Hyphen
   * --
   * @ 휴대폰 인증 기능 시 제외
   */
  const handleHyphen = (v) => {
    let str = OnlyNumber(v);
    let phoneText = '';
    if (str.length < 4) {
      phoneText = str;
    } else if (str.length < 7) {
      phoneText += str.substr(0, 3);
      phoneText += '-';
      phoneText += str.substr(3);
    } else if (str.length < 11) {
      phoneText += str.substr(0, 3);
      phoneText += '-';
      phoneText += str.substr(3, 3);
      phoneText += '-';
      phoneText += str.substr(6);
    } else {
      phoneText += str.substr(0, 3);
      phoneText += '-';
      phoneText += str.substr(3, 4);
      phoneText += '-';
      phoneText += str.substr(7);
    }
    phoneText === data.phone && alert('숫자만 입력해 주세요!');
    handleChange('phone', phoneText);
  };
  /**
   * 영어,숫자만 가능
   * --
   *
   */
  // const handleEngNum = (t, v) => {
  //   let str = OnlyEngNum(v);
  //   t === 'id'
  //     ? str === data.id && alert('영어,숫자만 입력해 주세요!')
  //     : str === data.recommender_id && alert('영어,숫자만 입력해 주세요!');

  //   handleChange(t, str);
  // };

  /**
   * 한글입력제어
   * --
   */
  const handleNoKorean = (t, v) => {
    // const a = v.replace(data.email, '');
    // let str = a.replace(/^[ㄱ-ㅎㅏ-ㅣ가-힣]+$/, '');
    // str === '' ? alert('한글은 입력이 불가합니다!') : handleChange(t, v);
    const value = data[t];

    if (value === '' || !value) {
      if (v === '' || !v) {
        v = undefined;
      } else if (validateCheck('korean', v)) {
        alert('한글은 입력이 불가합니다!');
        v = undefined;
      }
    } else {
      const a = v.replace(data.email, '');
      if (v === '' || !v) {
        v = undefined;
      } else if (validateCheck('korean', a)) {
        alert('한글은 입력이 불가합니다!');
        v = value;
      }
    }

    handleChange(t, v);
  };

  /**
   * 회원가입
   * --
   */
  const handleSubmit = () => {
    //데이터 입력 확인
    if (
      !ValueCheck(data.id, '아이디') ||
      !ValueCheck(data.name, '이름') ||
      !ValueCheck(data.password, '비밀번호') ||
      !ValueCheck(data.email, '이메일') ||
      !ValueCheck(data.phone, '전화번호') ||
      // !ValueCheck(data.local_id, '시/도') ||
      // !ValueCheck(data.city_id, '구/군') ||
      // !ValueCheck(data.district_id, '동/읍/면/리') ||
      !ValueCheck(data.address, '주소')
    ) {
      return;
    } else if (data.id.length < 6) {
      inputID.current.focus();
      MessageAlert.warning('아이디는 6자 이상으로 입력해주세요.');
      return;
    } else if (data.password.length < 8 || data.password.length > 16) {
      handleCheckStateChange('passwordCheck', true);
      inputPasswordRef.current.focus();
      MessageAlert.warning('비밀번호를 정확히 입력해주세요.');
      return;
    } else if (data.password !== data.password_check) {
      setCheckList({
        ...checkList,
        passwordCheck: false,
        passwordDoubleCheck: true,
      });
      inputPasswordRef.current.focus();
      MessageAlert.warning('비밀번호를 정확히 입력해주세요.');
      return;
    } else if (!Validation('phone2', data.phone)) {
      MessageAlert.warning('휴대폰번호를 정확히 입력해주세요.');
      return;
    } else if (!Validation('email', data.email)) {
      MessageAlert.warning('이메일을 정확히 입력해주세요.');
      return;
    } else if (
      doubleCheckClick !== 'Y' ||
      checkList.userAccountCheck !== false
    ) {
      MessageAlert.warning(
        doubleCheckClick === 'R'
          ? '유효한 아이디를 입력해주세요.'
          : doubleCheckClick === 'N' && '아이디 중복검사를 실행해주세요.'
      );
      inputID.current.focus();
      return;
    } else {
      setCheckList({
        ...checkList,
        passwordCheck: false,
        passwordDoubleCheck: false,
      });
      //가입하는 사용자에 따른 데이터 셋 정제
      if (userValue === 1) {
        if (!ValueCheck(data.birthday, '생년월일')) {
          return;
        } else {
          newData = data;
        }
      } else if (userValue === 3) {
        const { birthday, gender, nickname, job, ...setData } = data;
        const {
          agency_address,
          agency_name,
          agency_type,
          agency_capacity,
          agency_phone,
          agency_storage_id,
          company_number,
        } = agencyData;

        newData = {
          ...setData,
          address: agency_address,
          name: agency_name,
          type: agency_type,
          capacity: agency_capacity,
          phone: agency_phone,
          agency_storage_id: agency_storage_id,
          company_number: company_number,
        };
      }

      if (window.confirm('가입을 완료하시겠습니까?')) {
        handleCreateUser(userValue);
        deleteCookie('encodeData', {});
      }
    }
  };

  /**
   * 회원 등록 함수
   * --
   */
  const handleCreateUser = (value) => {
    if (value === 1) {
      try {
        createUser.mutate({
          user_account: newData.id,
          ...newData,
          teacher_check: 'N',
        });
      } catch (e) {
        throw e;
      }
    } else if (value === 3) {
      try {
        if (!newData.agency_storage_id) {
          createAgencyStorage.mutate({
            ...agencyData,
          });
        }

        createAgency.mutate({
          agency_account: newData.id,
          ...newData,
        });
      } catch (e) {
        throw e;
      }
    }
    // console.log(newData);
  };

  /**
   * 주소 SELECT 검색
   * --
   */
  // const handleSearch = (value, option) => {
  //   return (option?.label ?? '').includes(value);
  // };

  /* ===== HOOKS ===== */
  useEffect(() => {
    if (from === undefined) {
      MessageAlert.warning('잘못된 접근 입니다.');
      navigate('/');
    } else if (userValue !== radioValue) {
      MessageAlert.warning('회원 선택 화면으로 돌아갑니다.');
      navigate('/join/2', {
        state: { from: location },
        replace: true,
      }); //이전 페이지 전달 : url 접근 제어
    } else if (!encodeData && radioValue === 1) {
      MessageAlert.warning('본인인증 후 진행해주세요.');
      navigate('/join/2', {
        state: { from: location },
        replace: true,
      });
    } else if (!agencyData?.company_number && radioValue === 2) {
      MessageAlert.warning('본인인증 후 진행해주세요.');
      navigate('/join/2', {
        state: { from: location },
        replace: true,
      });
    }
  });

  useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', preventClose);
    })();

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, []);

  /**
   * DatePicker 12세 미만 회원가입 제한
   * --
   */
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    // console.log('today', today);
    // console.log('month', month);
    // console.log('day', day);
    const maxYear = year - 12;
    const max = `${maxYear}-${month < 9 ? `0${month + 1}` : `${month + 1}`}-${
      day < 10 ? `0${day}` : day
    }`;
    setMaxRange(max);
  }, []);

  useEffect(() => {
    const getDecodeData = async () => {
      const result = await NiceApi.verifyToken(
        `?EncodeData=${encodeURIComponent(encodeData)}`
      );

      // 본인인증 받은 생일 값 정제
      const year = result.birthdate.slice(0, 4);
      const month = result.birthdate.slice(4, 6);
      const day = result.birthdate.slice(6, 8);

      // 본인인증 받은 휴대폰 값 정제
      const phoneNum01 = result.mobileno.slice(0, 3);
      const phoneNum02 = result.mobileno.slice(3, 7);
      const phoneNum03 = result.mobileno.slice(7, 11);

      setData({
        ...data,
        name: userValue === 3 ? agencyData.company_name : result.name,
        gender: result.gender === '1' ? 'M' : 'F',
        birthday: `${year}-${month}-${day}`,
        phone: `${phoneNum01}-${phoneNum02}-${phoneNum03}`,
      });
    };
    if (encodeData) {
      getDecodeData();
    }
    // eslint-disable-next-line
  }, [encodeData]);

  /* ===== RENDER ===== */
  return (
    <Content
      maxWidth={'100%'}
      padding={'48px 0'}
      style={{
        border: '1px solid #9E9E9E',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Content maxWidth={384} padding={0}>
        <Row>
          <Col x={24}>
            <Title
              style={{
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '24px',
                marginBottom: '54px',
              }}
            >
              회원정보에 등록할 정보를 입력해주세요.
            </Title>
          </Col>
          <Col x={24}>
            <Form layout="vertical">
              <Form.Item style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  아이디
                  <Label essential={true} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Input
                      bordered={false}
                      placeholder="아이디를 입력해주세요."
                      style={{
                        padding: '16px 20px',
                        borderRadius: '4px',
                        border: '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                        width: '75%',
                      }}
                      ref={inputID}
                      value={data.id}
                      // onChange={(e) => handleEngNum('id', e.target.value)}
                      onChange={(e) => handleChange('id', e.target.value)}
                      autoComplete="username"
                    />
                    <Button
                      onClick={() => handleDoubleCheck()}
                      style={{ margin: '8px 0px 10px 5px', padding: '10px' }}
                    >
                      중복검사
                    </Button>
                  </div>
                </label>
                {checkList.userAccountCheck && (
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  >
                    이미 사용중이거나 탈퇴한 아이디입니다.
                  </span>
                )}
              </Form.Item>
              <Form.Item style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  비밀번호
                  <Label essential={true} />
                  <Input.Password
                    bordered={false}
                    placeholder="비밀번호를 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                    }}
                    value={data.password}
                    ref={inputPasswordRef}
                    maxLength={16}
                    onChange={(e) => handleChange('password', e.target.value)}
                    autoComplete="current-password"
                  />
                </label>
                {checkList.passwordCheck && (
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  >
                    8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.
                  </span>
                )}
              </Form.Item>
              <Form.Item style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  비밀번호 확인
                  <Label essential={true} />
                  <Input.Password
                    bordered={false}
                    placeholder="비밀번호를 한번 더 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                    }}
                    value={data.password_check}
                    maxLength={16}
                    onChange={(e) =>
                      handleChange('password_check', e.target.value)
                    }
                    autoComplete="current-password"
                  />
                </label>
                {checkList.passwordDoubleCheck && (
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  >
                    정확한 정보를 입력해주세요.
                  </span>
                )}
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '20px' }}
                hidden={userValue === 3 ? true : false}
              >
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  이름
                  <Label essential={true} />
                  <Input
                    bordered={false}
                    placeholder="이름을 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      // border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                      background: '#F5F5F5',
                    }}
                    readOnly={true}
                    disabled={true}
                    value={data.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                  />
                </label>
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '20px' }}
                hidden={userValue === 3 ? true : false}
              >
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  생년월일
                  <Label essential={true} />
                  <DatePicker
                    name="birthday"
                    width="100%"
                    fontSize="15px"
                    margin="8px 0 10px"
                    value={data.birthday}
                    defaultValue={'1970-01-01'}
                    onChange={(e) => handleChange('birthday', e.target.value)}
                    minRange={'1910-01-01'}
                    maxRange={maxRange}
                    readOnly={true}
                    disabled={true}
                  />
                </label>
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
              </Form.Item>

              <Form.Item
                style={{ marginBottom: '20px' }}
                hidden={userValue === 3 ? true : false}
              >
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  성별
                  <Select
                    defaultValue={genderlist && genderlist[0].value}
                    bordered={false}
                    style={{
                      padding: '10px 0',
                      borderRadius: '4px',
                      // border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      background: '#F5F5F5',
                    }}
                    readOnly={true}
                    disabled={true}
                    value={data.gender}
                    onChange={(e) => handleChange('gender', e)}
                    options={genderlist}
                  />
                  {/* <Input
                    bordered={false}
                    placeholder="성별을 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                    }}
                    value={data.gender}
                    onChange={(e) => handleChange('gender', e.target.value)}
                  /> */}
                </label>
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '20px' }}
                hidden={userValue === 3 ? true : false}
              >
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  닉네임
                  <Input
                    bordered={false}
                    placeholder="닉네임을 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                    }}
                    value={data.nickname}
                    onChange={(e) => handleChange('nickname', e.target.value)}
                  />
                </label>
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
              </Form.Item>
              <Form.Item style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  이메일
                  <Label essential={true} />
                  <Input
                    bordered={false}
                    placeholder="이메일을 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: '#000000',
                      border: radioValue === 3 ? 'none' : '1px solid #BDBDBD',
                      background: radioValue === 3 ? '#F5F5F5' : '#FFFFFF',
                    }}
                    value={data.email}
                    readOnly={radioValue === 3 ? true : false}
                    disabled={radioValue === 3 ? true : false}
                    onChange={(e) => handleNoKorean('email', e.target.value)}
                  />
                </label>
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
                <Checkbox
                  style={{
                    fontSize: '16px',
                    lineHeight: '19px',
                    fontWeight: 400,
                  }}
                  checked={data.email_check}
                  onChange={() =>
                    handleChange('email_check', !data.email_check)
                  }
                >
                  이메일 수신 동의 (선택)
                </Checkbox>
              </Form.Item>
              <Form.Item style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  연락처
                  <Label essential={true} />
                  <Input
                    bordered={false}
                    placeholder="연락처를 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      // border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: '#000000',
                      background: '#F5F5F5',
                    }}
                    value={data.phone}
                    maxLength={13}
                    readOnly={true}
                    disabled={true}
                    onChange={(e) => handleHyphen(e.target.value)}
                  />
                </label>
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
                <Checkbox
                  style={{
                    fontSize: '16px',
                    lineHeight: '19px',
                    fontWeight: 400,
                  }}
                  checked={data.sms_check}
                  onChange={() => handleChange('sms_check', !data.sms_check)}
                >
                  SMS 수신 동의 (선택)
                </Checkbox>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '20px' }}
                hidden={userValue === 3 ? true : false}
              >
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  직업
                  <Input
                    bordered={false}
                    placeholder="직업을 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                    }}
                    value={data.job}
                    onChange={(e) => handleChange('job', e.target.value)}
                  />
                </label>
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '20px' }}
                hidden={userValue === 1 ? true : false}
              >
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  대표자명
                </label>
                <Label essential={true} />
                <Input
                  bordered={false}
                  placeholder="대표자명 입력해주세요."
                  style={{
                    padding: '16px 20px',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                  }}
                  value={data.chief_name}
                  onChange={(e) => handleChange('chief_name', e.target.value)}
                />
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
              </Form.Item>
              <Form.Item style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  주소
                </label>
                <Label essential={true} />
                {/* <Select
                  showSearch
                  filterOption={(input, option) => handleSearch(input, option)}
                  defaultValue={local && local[0].value}
                  bordered={false}
                  style={{
                    padding: '10px 0',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                  }}
                  value={data.local_id}
                  onChange={(e) => handleChange('local_id', e)}
                  options={local}
                />
                <Select
                  showSearch
                  filterOption={(input, option) => handleSearch(input, option)}
                  bordered={false}
                  style={{
                    padding: '10px 0',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                  }}
                  value={data.city_id}
                  onChange={(e) => handleChange('city_id', e)}
                  options={city}
                />
                <Select
                  showSearch
                  filterOption={(input, option) => handleSearch(input, option)}
                  bordered={false}
                  style={{
                    padding: '10px 0',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                  }}
                  value={data.district_id}
                  onChange={(e) => handleChange('district_id', e)}
                  options={district}
                /> */}
                <DaumAddress
                  bordered={false}
                  placeholder="주소를 입력해주세요."
                  style={{
                    padding: '16px 20px',
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                    color: '#000000',
                    border: radioValue === 3 ? 'none' : '1px solid #BDBDBD',
                    background: radioValue === 3 ? '#F5F5F5' : '#FFFFFF',
                    cursor: radioValue === 1 ? 'pointer' : 'not-allowed',
                  }}
                  value={data.address}
                  readOnly
                  action={radioValue === 1}
                  callback={(v) => handleChange('address', v)}
                />
                <Input
                  bordered={false}
                  placeholder="상세주소를 입력해주세요."
                  style={{
                    padding: '16px 20px',
                    borderRadius: '4px',
                    border: '1px solid #BDBDBD',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    margin: '8px 0 10px',
                  }}
                  value={data.address_detail}
                  onChange={(e) =>
                    handleChange('address_detail', e.target.value)
                  }
                />
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
              </Form.Item>
              <Form.Item style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    color: '#000000',
                  }}
                >
                  추천인
                  <Input
                    bordered={false}
                    placeholder="추천인을 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                    }}
                    value={data.recommender_id}
                    // onChange={(e) =>
                    //   handleEngNum('recommender_id', e.target.value)
                    // }
                    onChange={(e) =>
                      handleChange('recommender_id', e.target.value)
                    }
                  />
                </label>
                <span
                  style={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                    color: '#F93232',
                  }}
                ></span>
              </Form.Item>
            </Form>
            <div>
              {/* <p
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '19px',
                  color: '#000000',
                }}
              >
                ※ 추천인 아이디를 적어주시면 추천인과 본인 모두에게 추가 100p를
                드립니다.
              </p> */}
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '19px',
                  color: '#000000',
                }}
              >
                ※ 주요 공지사항 및 이벤트 당첨안내 등은 수신동의 여부와 관계
                없이 메일(또는 SMS)이 발송됩니다.
              </p>
            </div>
            <Button
              style={{
                marginTop: '40px',
                fontSize: '16px',
                fontweight: 700,
                padding: '16px',
                width: '100%',
              }}
              onClick={() => handleSubmit()}
            >
              가입하기
            </Button>
          </Col>
        </Row>
      </Content>
    </Content>
  );
}

Join03.defaultProps = {
  local: [],
};

export default Join03;
