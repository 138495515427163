import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 강사 선호지역 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetPreferenceAreaUsers: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getPreferenceAreaUsers', params] : 'getPreferenceAreaUsers',
        async () =>
          await $http.get(APIConstant.GET_PREFERENCE_AREA_USERS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 선호지하철 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetPreferenceSubwayUsers: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params
          ? ['getPreferenceSubwayUsers', params]
          : 'getPreferenceSubwayUsers',
        async () =>
          await $http.get(APIConstant.GET_PREFERENCE_SUBWAY_USERS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 선호지역 상세조회
   * --
   * @method GET
   * @returns
   */
  GetPreferenceAreaUser: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_PREFERENCE_AREA_USER, {
        preference_area_id: idx,
      });
      const res = useQuery(
        params ? ['getPreferenceAreaUser', params] : 'getPreferenceAreaUser',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 선호지하철 상세조회
   * --
   * @method GET
   * @returns
   */
  GetPreferenceSubwayUser: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_PREFERENCE_SUBWAY_USER, {
        preference_subway_id: idx,
      });
      const res = useQuery(
        params
          ? ['getPreferenceSubwayUser', params]
          : 'getPreferenceSubwayUser',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 선호지역 생성
   * --
   * @method POST
   * @returns
   */
  CreatePreferenceAreaUser: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_PREFERENCE_AREA_USER,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getPreferenceAreaUsers');
            queryClient.invalidateQueries('getPreferenceAreaUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 선호 지하철 생성
   * --
   * @method POST
   * @returns
   */
  CreatePreferenceSubwayUser: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_PREFERENCE_SUBWAY_USER,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getPreferenceSubwayUsers');
            queryClient.invalidateQueries('getPreferenceSubwayUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 선호 지역 수정
   * --
   * @method PUT
   * @returns
   */
  UpdatePreferenceAreaUser: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.put(
            APIConstant.UPDATE_PREFERENCE_AREA_USER,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getPreferenceAreaUsers');
            queryClient.invalidateQueries('getPreferenceAreaUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 선호 지하철역 수정
   * --
   * @method PUT
   * @returns
   */
  UpdatePreferenceSubwayUser: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.put(
            APIConstant.UPDATE_PREFERENCE_SUBWAY_USER,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getPreferenceSubwayUsers');
            queryClient.invalidateQueries('getPreferenceSubwayUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 선호지역 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeletePreferenceAreaUser: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_PREFERENCE_AREA_USER, {
            preference_area_id: data.preference_area_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getPreferenceAreaUsers');
            queryClient.invalidateQueries('getPreferenceAreaUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 선호 지하철 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeletePreferenceSubwayUser: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(
            APIConstant.DELETE_PREFERENCE_SUBWAY_USER,
            {
              preference_subway_id: data.preference_subway_id,
            }
          );
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getPreferenceSubwayUsers');
            queryClient.invalidateQueries('getPreferenceSubwayUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
