import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import './MainSlide.style.css';
import {
  LeftOutlined,
  RightOutlined,
  CaretRightOutlined,
  PauseOutlined,
} from '@ant-design/icons';
import { STORE_URL } from 'utils';

const MainSlide = ({ data }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const sliderRef = useRef(null);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [isPlay, setIsPlay] = useState(true);
  const [slide, setSlide] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  // const slide = [
  //   {
  //     pc: PCMain01,
  //     mobile: MobileMain01,
  //   },
  // ];
  // const slide = data;

  const settings = {
    dots: false, // 토트 표시기 표시 여부
    infinite: true, // 무한 루프 허용 여부
    sllidesToShow: 1, // 한 번에 보여질 슬라이드의 개수
    slidesToScroll: 1, // 한 번에 스크롤될 슬라이드 개수
    autoplay: true, // 자동 재생 여부
    autoplaySpeed: 5000, // 자동 재생 속도
    arrows: false, // 이전/다음 화살표 표시 여부
    speed: 1000, // 슬라이드 전환 속도
    afterChange: (index) => {
      setCurrentSlide(index);
    },
  };

  /* ===== FUNCTION ===== */
  /**
   * 슬라이드 재생 컨트롤러 함수
   * --
   * @param {*} isPlay
   */
  const handlePlay = (e, isPlay) => {
    e.stopPropagation();
    if (sliderRef.current) {
      if (isPlay) {
        sliderRef.current.slickPause();
      } else {
        sliderRef.current.slickPlay();
      }
      setIsPlay(!isPlay);
    }
  };

  /**
   * 슬라이드 좌우 컨트롤러 함수
   * @param {*} type
   */
  const handleSlideArrow = (e, type) => {
    e.stopPropagation();
    if (sliderRef.current) {
      if (type === 'prev') {
        sliderRef.current.slickPrev();
      } else {
        sliderRef.current.slickNext();
      }
    }
  };

  /**
   * 링크 열기
   * --
   */
  const handleOpenLink = (link) => {
    let newLink = link;
    if (!link.includes('http')) {
      newLink = `https://${link}`;
    }

    window.open(newLink);
  };

  /* ===== RENDER ===== */
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  useEffect(() => {
    if (screenSize >= 768) {
      const s = data?.reduce((acc, cur) => {
        if (cur?.pc_file_url) {
          acc.push(cur);
          return acc;
        } else {
          return acc;
        }
      }, []);

      setSlide(s);
    } else {
      const s = data?.reduce((acc, cur) => {
        if (cur?.mobile_file_url) {
          acc.push(cur);
          return acc;
        } else {
          return acc;
        }
      }, []);

      setSlide(s);
    }
  }, [data, screenSize]);

  return (
    <div style={{ position: 'relative', top: 0, left: 0 }}>
      <Slider {...settings} ref={sliderRef}>
        {slide?.map((item, index) => (
          <div className="slide-content" key={`slide-banner-${index}`}>
            <img
              src={`${STORE_URL}/banner/${
                screenSize >= 768 ? item?.pc_file_url : item?.mobile_file_url
              }`}
              alt={item?.title}
            />
          </div>
        ))}
      </Slider>
      {slide?.length > 1 ? (
        <div
          className="slide-controller-box"
          style={{
            cursor: slide[currentSlide]?.link_url ? 'pointer' : 'default',
          }}
          onClick={
            slide[currentSlide]?.link_url
              ? () => handleOpenLink(`${slide[currentSlide]?.link_url}`)
              : () => {}
          }
        >
          <div className="slide-controller">
            <div className="slide-controller-button">
              <div className="controller-pagination">
                {currentSlide + 1}
                <span>/</span>
                {slide?.length}
              </div>
              <div className="controller-button">
                <button onClick={(e) => handleSlideArrow(e, 'prev')}>
                  <LeftOutlined style={{ fontSize: 20 }} />
                </button>
                <button onClick={(e) => handlePlay(e, isPlay)}>
                  {isPlay ? (
                    <PauseOutlined style={{ fontSize: 20 }} />
                  ) : (
                    <CaretRightOutlined style={{ fontSize: 20 }} />
                  )}
                </button>
                <button onClick={(e) => handleSlideArrow(e, 'next')}>
                  <RightOutlined style={{ fontSize: 20 }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="slide-controller-box"
          style={{
            cursor: slide[currentSlide]?.link_url ? 'pointer' : 'default',
          }}
          onClick={
            slide[currentSlide]?.link_url
              ? () => handleOpenLink(`${slide[currentSlide]?.link_url}`)
              : () => {}
          }
        ></div>
      )}
    </div>
  );
};

export default MainSlide;
