import React from 'react';
import './loading.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingPage = ({ text }) => {
  return (
    <div className="global-loading-container">
      <FontAwesomeIcon className="loading-spinner" icon={faSpinner} />
      <div className="loading-text">{text}</div>
    </div>
  );
};

LoadingPage.defaultProps = {
  text: '페이지를 로드중입니다.',
};

export default LoadingPage;
