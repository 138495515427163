/**
 *
 *
 *
 */
import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { Content, Row, Col, Title, Button } from 'components';
import MessageAlert from 'utils/MessageAlert';
import { getCookie } from 'utils';
import { AdminApi } from 'api';

// const { intl } = globalObj;

// import {setCoo}

/**
 * [Component] Login
 * --
 */
function AdminLogin(props) {
  /* ===== Props ===== */
  const { navigation, queryClient, isLogin, setIsLogin } = props;
  // const dispatch = useDispatch();

  /* ===== State ===== */
  // 로그인 id, pw 입력 값
  const [account, setAccount] = useState({
    id: '',
    pw: '',
  });

  /* ===== Hooks ===== */

  /* ===== Functions ===== */
  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...account };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setAccount(d);

    if (callback) callback(v);
  };

  /**
   * 로그인 함수
   * --
   */
  const handleLogin = async () => {
    const { id, pw } = account;
    if (!id || !pw) {
      MessageAlert.warning('계정정보를 모두 입력해주세요');
      return;
    }

    try {
      const res = await AdminApi.LoginAdmin({
        admin_account: id,
        password: pw,
      });

      if (res === 200) {
        setIsLogin(true);
        navigation('/admin/users?page=1', { replace: true });
        MessageAlert.success('로그인 성공');
        queryClient.invalidateQueries();
      } else {
        MessageAlert.warning('계정정보를 다시 확인해주세요');
      }
    } catch (e) {
      console.log('[handleLogin] Error: ', e);
      MessageAlert.error('로그인 실패');
    }
  };

  /**
   * 로그인 버튼 Enter 처리
   * --
   * @param {*} e
   * @param {*} data
   */
  const handleEnterSubmit = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  /* ===== Render ===== */
  /**
   * 쿠키가 저장되어 있는지 체크
   * --
   */
  useEffect(() => {
    const isCookie = getCookie('BRAINHEAL_ACCESS_TOKEN');

    if (isCookie) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isLogin, setIsLogin]);

  /* ===== Render ===== */
  return (
    <>
      <Content
        maxWidth={'100%'}
        padding={'48px 0 84px'}
        backgroundColor={'#FAFAFA'}
      >
        <Content maxWidth={792} padding={0} backgroundColor={'#FAFAFA'}>
          <Row>
            <Col x={24}>
              <Content maxWidth={'100%'} backgroundColor={'#FAFAFA'}>
                <Row>
                  <Col x={24}>
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{
                        border: '1px solid #9E9E9E',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '50px 0',
                      }}
                    >
                      <Content maxWidth={'384px'} padding={0}>
                        <Row>
                          <Col x={24}>
                            <Title
                              size={2}
                              align="center"
                              style={{
                                fontSize: '35px',
                                fontWeight: 700,
                                lineHeight: '42px',
                                letterSpacing: '0.02em',
                                color: '#000000',
                                marginBottom: '63px',
                              }}
                            >
                              관리자 로그인
                            </Title>
                          </Col>
                          <Col x={24}>
                            <label
                              style={{
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '22px',
                                letterSpacing: '0.02em',
                              }}
                            >
                              아이디
                              <Input
                                placeholder="아이디를 입력해주세요"
                                size="large"
                                style={{
                                  height: '51px',
                                  padding: '10px 20px',
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  marginTop: '8px',
                                }}
                                name="id"
                                value={account.id}
                                onChange={(e) =>
                                  handleChange('id', e.target.value)
                                }
                                onKeyDown={(e) => handleEnterSubmit(e)}
                              />
                            </label>
                          </Col>
                          <Col x={24} style={{ marginTop: '20px' }}>
                            <label
                              style={{
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '22px',
                                letterSpacing: '0.02em',
                              }}
                            >
                              비밀번호
                              <Input
                                type="password"
                                placeholder="비밀번호를 입력해주세요"
                                size="large"
                                style={{
                                  height: '51px',
                                  padding: '10px 20px',
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  marginTop: '8px',
                                }}
                                name="pw"
                                value={account.pw}
                                onChange={(e) =>
                                  handleChange('pw', e.target.value)
                                }
                                onKeyDown={(e) => handleEnterSubmit(e)}
                              />
                            </label>
                          </Col>
                          <Col x={24} style={{ marginTop: '40px' }}>
                            <Button
                              onClick={handleLogin}
                              style={{
                                width: '100%',
                                height: '56px',
                                fontSize: '18px',
                                fontWeight: 700,
                                lineHeight: '22px',
                              }}
                            >
                              로그인
                            </Button>
                          </Col>
                        </Row>
                      </Content>
                    </Content>
                  </Col>
                </Row>
              </Content>
            </Col>
          </Row>
        </Content>
      </Content>
    </>
  );
}

AdminLogin.defaultProps = {};

export default AdminLogin;
