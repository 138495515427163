import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 카테고리 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetCategories: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getCategories', params] : 'getCategories',
        async () => await $http.get(APIConstant.GET_CATEGORIES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 카테고리 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetCategory: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_CATEGORY, {
        category_id: idx,
      });
      const res = useQuery(
        params ? ['getCategory', params] : 'getCategory',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 카테고리 생성
   * --
   * @method POST
   * @returns
   */
  CreateCategory: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_CATEGORY, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getCategories');
            queryClient.invalidateQueries('getCategory');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 카테고리 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateCategory: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_CATEGORY, {
            category_id: data.category_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getCategories');
            queryClient.invalidateQueries('getCategory');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 카테고리 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteCategory: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_CATEGORY, {
            category_id: data.category_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getCategories');
            queryClient.invalidateQueries('getCategory');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
