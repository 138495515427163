import React, { useState, useEffect } from 'react';
// import * as ReactDOMServer from 'react-dom/server';
import {
  Content,
  Row,
  Col,
  Title,
  FileUploadButton,
  Button as Btn,
} from 'components';
import { useParams } from 'react-router-dom';
import { decrypt } from 'utils/Crypto';
import { ScheduleApi } from 'api';
import { getCookie, STORE_URL } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { Input, Button, Image, Divider } from 'antd';
import MessageAlert from 'utils/MessageAlert';
import { FileUploadManager } from 'utils/FileUploadManager';
import '../styles/program.css';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

const Program = () => {
  /* ===== ROUTE ===== */
  const { scheduleId } = useParams();

  /* ===== MUTATE ===== */
  const updateSchedule = ScheduleApi.UpdateSchedule();

  /* ===== STATE ===== */
  const { data: schedule, refetch: scheduleRefetch } = ScheduleApi.GetSchedule(
    decrypt(scheduleId),
    null,
    {
      enabled: !!scheduleId,
    }
  );
  const uId = getCookie('USER');
  const loginWho = useRecoilValue(loginUser);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [type, setType] = useState('read');
  const [scheduleData, setScheduleData] = useState({});
  const [programData, setProgramData] = useState({});
  const [linkValue, setLinkValue] = useState('');
  const [fileList, setFileList] = useState([]);
  const [addFileList, setAddFileList] = useState([]);

  /* ===== FUNCTION ===== */
  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...programData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setProgramData(d);

    if (callback) callback(v);
  };

  /**
   * 참고링크 데이터 바인딩
   * --
   */
  const handleLinkList = (i = null) => {
    const link = programData?.link;
    if (i === null) {
      if (!linkValue || linkValue === '') {
        MessageAlert.warning('주소를 입력해주세요!');
        return;
      }

      try {
        new URL(linkValue);
        link.push(linkValue);
        handleChange('link', link);
        setLinkValue('');
      } catch (e) {
        return MessageAlert.warning('URL 주소 형식으로 입력해주세요!');
      }
    } else {
      const newLinkList = link?.filter((item, index) => index !== i);
      handleChange('link', newLinkList);
    }
  };

  /**
   * 첨부파일 추가
   * --
   */
  const handleAddFile = (file) => {
    const preview = URL.createObjectURL(file);
    setFileList((prev) => [...prev, preview]);
    setAddFileList((prev) => [...prev, file]);
  };

  /**
   * 첨부파일 제거
   * --
   */
  const handleRemoveFile = (i) => {
    const f = fileList;
    const newFileList = f?.filter((item, index) => index !== i);
    setFileList(newFileList);
  };

  /**
   * 교안 수정 등록
   * --
   */
  const handleProgramUpdate = async () => {
    const uploadFile = async () => {
      const uploadedFiles = [];

      for (const item of addFileList) {
        try {
          const res = await FileUploadManager('T', item);
          const newFileUrl = `${STORE_URL}/program/${res.name}`;
          uploadedFiles.push(newFileUrl);
        } catch (error) {
          console.error('파일 업로드 실패:', error);
        }
      }

      return uploadedFiles;
    };

    const confirm = window.confirm('교안을 등록하시겠습니까?');
    if (confirm) {
      try {
        let addFiles = [];
        if (fileList?.length) {
          addFiles = await uploadFile();
        }

        const f =
          fileList && fileList?.filter((item) => item?.includes(STORE_URL));

        updateSchedule.mutate({
          ...scheduleData,
          content: {
            ...programData,
            files: [...f, ...addFiles],
          },
        });
        MessageAlert.success('등록되었습니다!');
      } catch (err) {
        MessageAlert.error('교안을 등록하는데 실패하였습니다!');
        console.log('교안등록 실패!', err);
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다');
      return;
    }
  };

  /**
   * PDF 다운로드
   * --
   */
  const handlePDFDownload = async () => {
    MessageAlert.info('준비중입니다');
    // const template = ReactDOMServer.renderToStaticMarkup(
    //   <ProgramTemplate data={programData} />
    // );
    // const container = document.createElement('div');
    // container.innerHTML = template;

    // const canvas = await html2canvas(container, {
    //   useCORS: true,
    // });

    // const imgData = canvas.toDataURL('image/png', 1.5);
    // const pageWidth = 210;
    // const pageHeight = pageWidth * 1.414;
    // const imgWidth = pageWidth - 20;
    // const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // let heightLeft = imgHeight;

    // const doc = new jsPDF('p', 'mm', [pageHeight, pageWidth]);
    // let position = 10;
    // doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
    // heightLeft -= pageHeight;

    // while (heightLeft >= 0) {
    //   position = heightLeft - imgHeight;
    //   doc.addPage();
    //   doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);

    //   heightLeft -= pageHeight;
    // }

    // // doc.save();
    // window.open(doc.output('bloburl'));
  };

  /* ===== HOOKS ===== */
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  useEffect(() => {
    scheduleRefetch();
  }, [scheduleId, scheduleRefetch]);

  /**
   * 교안 데이터 추출
   * --
   */
  useEffect(() => {
    if (schedule?.status === 200) {
      const { data } = schedule;
      if (loginWho === 'teacher' && parseInt(data?.user_id) === parseInt(uId)) {
        setType('write');
      }

      const { created_at, ...d } = data;
      setScheduleData({ ...d });
      setProgramData(data?.content);
      setFileList(data?.content?.files ? data?.content?.files : []);
    }
  }, [schedule, uId, loginWho]);

  /* ===== STYLES ===== */

  /* ===== RENDER ===== */
  return (
    <>
      <Content
        maxWidth={'100%'}
        padding={screenSize >= 480 ? 0 : '10px'}
        style={{ margin: '48px 0 84px' }}
      >
        <Content maxWidth={1200}>
          <Row>
            <Col x={18} padding={10}>
              <Content
                maxWidth={'100%'}
                style={{ border: '1px solid #9E9E9E', borderRadius: '8px' }}
              >
                {type === 'write' ? (
                  <Input
                    size="large"
                    value={programData?.title}
                    onChange={(e) => handleChange('title', e.target.value)}
                  />
                ) : (
                  <Title>{programData?.title}</Title>
                )}
                <Content
                  maxWidth={'100%'}
                  style={{ marginTop: 20 }}
                  padding={0}
                >
                  <table className="program_layout">
                    <tbody>
                      {/* 진행자 / 프로그램유형 / 게임환경 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            진행자
                          </Title>
                        </th>
                        <td>
                          {type === 'write' ? (
                            <Input
                              size="large"
                              value={programData?.name}
                              onChange={(e) =>
                                handleChange('name', e.target.value)
                              }
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.name}
                            </Title>
                          )}
                        </td>
                        <th>
                          <Title size={5} align={'center'}>
                            프로그램
                            <br />
                            유형
                          </Title>
                        </th>
                        <td>
                          {type === 'write' ? (
                            <Input
                              size="large"
                              value={programData?.category}
                              onChange={(e) =>
                                handleChange('category', e.target.value)
                              }
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.category}
                            </Title>
                          )}
                        </td>
                        <th>
                          <Title size={5} align={'center'}>
                            게임환경
                          </Title>
                        </th>
                        <td>
                          {type === 'write' ? (
                            <Input
                              size="large"
                              value={programData?.environment}
                              onChange={(e) =>
                                handleChange('environment', e.target.value)
                              }
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.environment}
                            </Title>
                          )}
                        </td>
                      </tr>
                      {/* 장소 / 참여자 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            장소
                          </Title>
                        </th>
                        <td colSpan={2}>
                          {type === 'write' ? (
                            <Input
                              size="large"
                              value={programData?.location}
                              onChange={(e) =>
                                handleChange('location', e.target.value)
                              }
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.location}
                            </Title>
                          )}
                        </td>
                        <th>
                          <Title size={5} align={'center'}>
                            참여자
                          </Title>
                        </th>
                        <td colSpan={2}>
                          {type === 'write' ? (
                            <Input
                              size="large"
                              value={programData?.participants}
                              onChange={(e) =>
                                handleChange('participants', e.target.value)
                              }
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.participants}
                            </Title>
                          )}
                        </td>
                      </tr>
                      {/* 운영일자 / 시간 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            운영일자
                          </Title>
                        </th>
                        <td colSpan={2}>
                          {type === 'write' ? (
                            <Input
                              size="large"
                              value={programData?.date}
                              onChange={(e) =>
                                handleChange('date', e.target.value)
                              }
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.date}
                            </Title>
                          )}
                        </td>
                        <th>
                          <Title size={5} align={'center'}>
                            시간
                          </Title>
                        </th>
                        <td colSpan={2}>
                          {type === 'write' ? (
                            <Input
                              size="large"
                              value={programData?.time}
                              onChange={(e) =>
                                handleChange('time', e.target.value)
                              }
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.time}
                            </Title>
                          )}
                        </td>
                      </tr>
                      {/* 준비물 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            준비물
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.materials}
                              onChange={(e) =>
                                handleChange('materials', e.target.value)
                              }
                            />
                          ) : (
                            <pre>{programData?.materials}</pre>
                          )}
                        </td>
                      </tr>
                      {/* 활동목표 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            활동목표
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.goal}
                              onChange={(e) =>
                                handleChange('goal', e.target.value)
                              }
                              style={{ height: 200 }}
                            />
                          ) : (
                            <pre>{programData?.goal}</pre>
                          )}
                        </td>
                      </tr>
                      {/* 활동내용 */}
                      <tr>
                        <th colSpan={6}>
                          <Title size={5} align={'center'}>
                            활동내용
                          </Title>
                        </th>
                      </tr>
                      {/* 도입 */}
                      <tr>
                        <th x={4}>
                          <Title size={5} align={'center'}>
                            도입
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.introduction}
                              onChange={(e) =>
                                handleChange('introduction', e.target.value)
                              }
                              style={{ height: 200 }}
                            />
                          ) : (
                            <pre>{programData?.introduction}</pre>
                          )}
                        </td>
                      </tr>
                      {/* 전개 */}
                      <tr>
                        <th x={4}>
                          <Title size={5} align={'center'}>
                            전개
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.deployment}
                              onChange={(e) =>
                                handleChange('deployment', e.target.value)
                              }
                              style={{ height: 300 }}
                            />
                          ) : (
                            <pre>{programData?.deployment}</pre>
                          )}
                        </td>
                      </tr>
                      {/* 마무리 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            마무리
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.finish}
                              onChange={(e) =>
                                handleChange('finish', e.target.value)
                              }
                              style={{ height: 100 }}
                            />
                          ) : (
                            <pre>{programData?.finish}</pre>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Content>

                <Content
                  maxWidth={'100%'}
                  style={{ marginTop: 50 }}
                  padding={0}
                >
                  {/* 참고링크 */}
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col x={4}>
                      <Title size={5} align={'center'}>
                        참고링크
                      </Title>
                    </Col>
                    <Col x={20}>
                      {type === 'write' ? (
                        <Content maxWidth={'100%'} padding={0}>
                          <div style={{ display: 'flex' }}>
                            <Input
                              size="large"
                              value={linkValue}
                              onChange={(e) => setLinkValue(e.target.value)}
                            />
                            <Button
                              size="large"
                              onClick={() => handleLinkList()}
                            >
                              추가
                            </Button>
                          </div>
                        </Content>
                      ) : null}
                    </Col>
                    {type === 'write' && <Col x={4}></Col>}
                    <Col x={20}>
                      <ul>
                        {programData?.link?.map((item, index) => (
                          <li
                            key={`link-${index}`}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 10,
                            }}
                          >
                            <a href={item} target="_blank" rel="noreferrer">
                              {item}
                            </a>
                            {type === 'write' && (
                              <button
                                style={{
                                  width: 30,
                                  height: 30,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  border: '1px solid #E0E0E0',
                                  borderRadius: 5,
                                  background: 'none',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleLinkList(index)}
                              >
                                x
                              </button>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                  {/* 참고 이미지 */}
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    <Col x={4}>
                      <Title size={5} align={'center'}>
                        이미지
                      </Title>
                    </Col>
                    <Col x={20}>
                      {type === 'write' ? (
                        <Content maxWidth={'100%'} padding={0}>
                          <FileUploadButton
                            onChange={handleAddFile}
                            layoutStyle={{
                              maxWidth: 156,
                              width: '100%',
                            }}
                            style={{
                              textAlign: 'center',
                              fontSize: '16px',
                            }}
                            accept="image/*"
                          >
                            파일 추가 +
                          </FileUploadButton>
                        </Content>
                      ) : null}
                    </Col>
                    {type === 'write' && <Col x={4}></Col>}
                    <Col x={20}>
                      <Content maxWidth={'100%'} padding={0}>
                        <Row>
                          {fileList?.map((item, index) => (
                            <Col x={6} key={`image-${index}`}>
                              <div
                                style={{
                                  width: '100%',
                                  height: 150,
                                  position: 'relative',
                                  top: 0,
                                  left: 0,
                                  marginTop: 10,
                                  padding: 10,
                                }}
                              >
                                <Image
                                  src={item}
                                  width={'100%'}
                                  height={'100%'}
                                />
                                {type === 'write' ? (
                                  <button
                                    style={{
                                      width: 20,
                                      height: 20,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderRadius: 5,
                                      border: '1px solid #BDBDBD',
                                      background: '#BDBDBD',
                                      position: 'absolute',
                                      top: 0,
                                      right: 0,
                                      cursor: 'pointer',
                                      color: '#FFFFFF',
                                    }}
                                    onClick={() => handleRemoveFile(index)}
                                  >
                                    x
                                  </button>
                                ) : null}
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Content>
                    </Col>
                  </Row>
                </Content>
              </Content>
              {type === 'write' ? (
                <Row style={{ marginTop: 50 }}>
                  <Col x={24}>
                    <Btn
                      style={{
                        width: '50%',
                        height: 56,
                        fontSize: 18,
                        margin: '0 auto',
                      }}
                      onClick={() => handleProgramUpdate()}
                    >
                      등록
                    </Btn>
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col x={6} padding={10}>
              <Content
                maxWidth={'100%'}
                style={{ border: '1px solid #9E9E9E', borderRadius: '8px' }}
              >
                <Title size={4}>{programData?.title}</Title>
                <Divider style={{ margin: '20px 0' }} />
                <Btn
                  style={{ width: '100%', height: 40 }}
                  onClick={async () => await handlePDFDownload()}
                >
                  PDF로 저장하기
                </Btn>
              </Content>
            </Col>
          </Row>
        </Content>
      </Content>

      {/* <div id="program-template">
        <ProgramTemplate data={programData} />
      </div> */}
      {/* <ModalLayout> */}
      {/* </ModalLayout> */}
    </>
  );
};

Program.defaultProps = {};

export default Program;
