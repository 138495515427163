import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * SNS 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetSNSUsers: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getSNSUsers', params] : 'getSNSUsers',
        async () => await $http.get(APIConstant.GET_SNS_USERS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * SNS 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetSNSUser: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_SNS_USER, { sns_id: idx });
      const res = useQuery(
        params ? ['getSNSUser', params] : 'getSNSUser',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * SNS 생성
   * --
   * @method POST
   * @returns
   */
  CreateSNSUser: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_SNS_USER, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getSNSUsers');
            queryClient.invalidateQueries('getSNSUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * SNS 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateSNSUser: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.put(APIConstant.UPDATE_SNS_USER, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getSNSUsers');
            queryClient.invalidateQueries('getSNSUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * SNS 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteSNSUser: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_SNS_USER, {
            sns_id: data.sns_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getSNSUsers');
            queryClient.invalidateQueries('getSNSUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
