import React, { useState, useEffect } from 'react';
import './ProfileCard.style.css';
import {
  Content,
  Row,
  Col,
  Button,
  ModalLayout,
  DatePicker,
  DaumAddress,
} from 'components';
import { Avatar, Input, Form, Select, Checkbox } from 'antd';
import MessageAlert from 'utils/MessageAlert';
import {
  DOMAIN_URL,
  STORE_URL,
  calculateAge,
  deleteCookie,
  getCookie,
  stringToMoneyFormat,
} from 'utils';
import { UserApi, AgencyApi } from 'api';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { loginUser, userInfo } from 'atoms/Atoms';
import { Validation } from 'utils';
import { useNavigate } from 'react-router-dom';

const ProfileCard = ({ data }) => {
  /* ===== ROUTE ===== */
  const navigation = useNavigate();

  /* ===== DATA ===== */
  const queryClient = useQueryClient();
  const loginWho = useRecoilValue(loginUser);
  const userData = useRecoilValue(userInfo);
  const AddressSplit = queryClient.getQueryData('getAddressSplit')?.data;
  const newData = loginWho === 'agency' ? data?.agency : data?.user;

  // console.log(data);
  /* ===== Mutate ===== */
  const updateUser = UserApi.UpdateUser({
    onSuccess: () => {
      MessageAlert.success('수정되었습니다');
    },
  });
  const updateAgency = AgencyApi.UpdateAgency({
    onSuccess: () => {
      MessageAlert.success('수정되었습니다');
    },
  });
  const updateUserPW = UserApi.UpdateUserPassword({
    onSuccess: () => {
      window.alert(
        '비밀번호가 성공적으로 변경되었습니다.\n다시 로그인해주세요'
      );
      deleteCookie('BRAINHEAL_ACCESS_TOKEN', {});
      deleteCookie('USER', {});
      navigation('/');
    },
  });
  const updateAgencyPW = AgencyApi.UpdateAgencyPassword({
    onSuccess: () => {
      window.alert(
        '비밀번호가 성공적으로 변경되었습니다.\n다시 로그인해주세요'
      );
      deleteCookie('BRAINHEAL_ACCESS_TOKEN', {});
      deleteCookie('USER', {});
      navigation('/');
    },
  });

  /* ===== STATE ===== */
  const [isOpened, setIsOpened] = useState(false);
  const [isOpenedEditer, setIsOpenedEditer] = useState(false);
  const [radio, setRadio] = useState(1);
  const [password, setPassword] = useState('');
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [agencyData, setAgencyData] = useState({
    company_name: undefined,
    chief_name: undefined,
    company_number: undefined,
  });
  // console.log('agencyData', agencyData);
  const [editData, setEditData] = useState({});
  //checkvalue (email, sms)
  const [smsCheck, setSmsCheck] = useState();
  const [emailCheck, setEmailCheck] = useState({});
  // 비밀번호 변경 모달
  const [isPWOpened, setIsPWOpened] = useState(false);
  const [rePassword, setRePassword] = useState('');
  const [rePasswordCheck, setRePasswordCheck] = useState('');

  //성별 상태
  const genderlist = [
    { value: 'M', label: '남자' },
    { value: 'F', label: '여자' },
  ];

  /* ===== FUNCTION ===== */
  const ValueCheck = (data, name) => {
    // 데이터 값 미입력 체크
    if (data === '' || data === undefined || data === null) {
      MessageAlert.warning(name + '을(를) 입력해주세요');
      return false;
    } else {
      return true;
    }
  };

  /**
   * 비밀번호 재확인 모달 오픈
   * --
   */
  const handleModal = (open) => {
    setIsOpened(!open);
  };

  /**
   * 회원정보 수정 모달 오픈
   * --
   */
  const handleModalEditer = (open) => {
    setIsOpenedEditer(!open);
  };

  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newEditData = { ...editData };
    if (t === 'local_id') {
      newEditData['city_id'] = '';
      newEditData['district_id'] = '';
    } else if (t === 'city_id') {
      newEditData['district_id'] = '';
    }
    if (t === 'email_check' || t === 'sms_check') {
      newEditData[t] = v === true ? 'Y' : 'N';
      t === 'email_check' ? setEmailCheck(v) : setSmsCheck(v);
    } else {
      newEditData[t] = v;
    }
    const d = {
      ...newEditData,
    };
    setEditData(d);

    if (callback) callback(v);
  };

  /**
   * 핸드폰 번호 자동 Hyphen
   * --
   * @ 휴대폰 인증 기능 시 제외
   */
  const handleHyphen = (v) => {
    let str = v.replace(/[^0-9]/g, '');
    str === '' && MessageAlert.warning('숫자만 입력해 주세요!');
    let phoneText = '';

    // 02 연락처 예외처리
    if (`${str[0]}${str[1]}` === '02') {
      if (str.length < 3) {
        phoneText = str;
      } else if (str.length < 6) {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2);
      } else if (str.length < 10) {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2, 3);
        phoneText += '-';
        phoneText += str.substr(5);
      } else {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2, 4);
        phoneText += '-';
        phoneText += str.substr(6);
      }
    } else {
      if (str.length < 4) {
        phoneText = str;
      } else if (str.length < 7) {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3);
      } else if (str.length < 11) {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3, 3);
        phoneText += '-';
        phoneText += str.substr(6);
      } else {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3, 4);
        phoneText += '-';
        phoneText += str.substr(7);
      }
    }
    phoneText === editData.phone &&
      MessageAlert.warning('숫자만 입력해 주세요!');
    handleChange('phone', phoneText);
  };

  const handleSubmit = () => {
    //데이터 입력 확인
    // MessageAlert.success(editData.sms_check);
    // MessageAlert.success(editData.email_check);
    if (
      !ValueCheck(editData.name, '이름') ||
      !ValueCheck(editData.email, '이메일') ||
      !ValueCheck(editData.phone, '전화번호') ||
      !ValueCheck(editData.address, '주소')
    ) {
      return;
    } else if (!Validation('phone2', editData.phone)) {
      MessageAlert.warning('전화번호를 정확히 입력해주세요.');
      return;
    } else if (!Validation('email', editData.email)) {
      MessageAlert.warning('이메일을 정확히 입력해주세요.');
      return;
    } else {
      //가입하는 사용자에 따른 데이터 셋 정제
      if (radio === 1) {
        if (!ValueCheck(editData.birthday, '생년월일')) {
          return;
        }
      }

      if (window.confirm('수정을 완료하시겠습니까?')) {
        handleUpdateUser(radio);
      }
    }
  };

  /**
   * 회원 수정 함수
   * --
   */
  const handleUpdateUser = (value) => {
    if (value === 1) {
      try {
        updateUser.mutate({
          user_id: data?.user.user_id,
          ...editData,
        });
      } catch (e) {
        throw e;
      }
    } else if (value === 2) {
      try {
        updateAgency.mutate({
          agency_id: data?.agency.agency_id,
          ...editData,
        });
      } catch (e) {
        throw e;
      }
    }
    setIsOpenedEditer(false);
    // console.log('editData', editData);
  };

  /**
   * 비밀번호 체크
   * --
   */
  const handleCheckAccount = async () => {
    if (!password) {
      MessageAlert.warning('비밀번호를 입력해주세요');
      return;
    } else {
      try {
        let res;
        switch (radio) {
          case 1:
            res = await UserApi.LoginUser({
              user_account: newData?.user_account,
              password: password,
            });
            break;
          case 2:
            res = await AgencyApi.LoginAgency({
              agency_account: newData?.agency_account,
              password: password,
            });
            break;
          default:
            break;
        }

        if (res === 200) {
          MessageAlert.success('본인 확인이 완료됐습니다.');
          setIsOpened(false);
          handleModalEditer(isOpenedEditer);
          setPassword();
        } else {
          MessageAlert.warning('비밀번호를 다시 확인해주세요');
        }
      } catch (e) {
        console.log('[handleLogin] Error: ', e);
        MessageAlert.error('로그인 실패');
      }
    }
  };

  /**
   * enter key
   *
   */
  const handleEnterSubmit = (e) => {
    if (e.key === 'Enter') {
      handleCheckAccount();
    }
  };

  /**
   * 포인트 충전 팝업 Open
   * --
   */
  const handleOpenPopup = () => {
    const domain = DOMAIN_URL;
    window.open(`${domain}/#/popup/point`, '팝업', 'width=500,height=700');
  };

  /**
   * 비밀번호 변경
   * --
   */
  const handlePWSubmit = () => {
    // 비밀번호 자리수 확인
    if (rePassword?.length < 8 || rePassword?.length > 16) {
      MessageAlert.warning('비밀번호는 8자리이상 16자리 이하로 입력해주세요.');
      return;
    }

    // 비밀번호 일치 확인
    if (rePassword !== rePasswordCheck) {
      MessageAlert.warning('비밀번호가 일치하지 않습니다.');
      return;
    }

    if (loginWho === 'agency') {
      updateAgencyPW.mutate({
        agency_id: getCookie('USER'),
        agency_account: userData?.agency_account,
        email: userData?.email,
        password: rePassword,
      });
    } else {
      updateUserPW.mutate({
        user_id: getCookie('USER'),
        user_account: userData?.user_account,
        email: userData?.email,
        password: rePassword,
      });
    }
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    if (newData) {
      if (loginWho === 'agency') {
        setEditData({
          ...agencyData,
          email: data?.agency?.email,
          phone: data?.agency?.phone,
          address: data?.agency?.address,
          address_detail: data?.agency?.address_detail,
          sms_check: data?.agency?.sms_check,
          email_check: data?.agency?.email_check,
        });
        setSmsCheck(data?.agency?.sms_check === 'Y' ? true : false);
        setEmailCheck(data?.agency?.email_check === 'Y' ? true : false);
      } else {
        setEditData({
          name: data?.user?.name,
          email: data?.user?.email,
          phone: data?.user?.phone,
          birthday: moment(data?.user?.birthday).format('YYYY-MM-DD'),
          address: data?.user?.address,
          address_detail: data?.user?.address_detail,
          sms_check: data?.user?.sms_check,
          email_check: data?.user?.email_check,
          //user data
          job: data?.user?.job,
          gender: data?.user?.gender,
          nickname: data?.user?.nickname,
        });
        setSmsCheck(data?.user?.sms_check === 'Y' ? true : false);
        setEmailCheck(data?.user?.email_check === 'Y' ? true : false);
      }
    }
  }, [newData, AddressSplit, loginWho, agencyData, data]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /**
   * 수강생,강사 / 기관 정의
   * --
   */
  useEffect(() => {
    const num = loginWho === 'agency' ? 2 : 1;
    setRadio(num);
    if (num === 2) {
      setAgencyData({
        name: data?.agency?.name,
        chief_name: data?.agency?.chief_name,
        company_number: data?.agency?.company_number,
      });
    }
  }, [radio, loginWho, data]);

  /* ===== Styles ===== */
  const styles = {
    modalLabel: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.02em',
      color: '#000000',
    },
    sectionButton: {
      width: '30px',
      height: '30px',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      fontSize: '18px',
    },
  };

  /* ===== RENDER ===== */
  return (
    <>
      {/* 반응형 잔여포인트/포인트 충전 */}
      {screenSize < 768 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 10,
          }}
        >
          <p style={{ fontSize: 16 }}>
            잔여 포인트{' '}
            <span style={{ fontWeight: 700, color: '#AB5476' }}>
              {stringToMoneyFormat(newData?.point, 'P')}
            </span>
          </p>
          <Button
            color="secondary"
            layoutStyle={{ margin: 0 }}
            onClick={() => handleOpenPopup()}
          >
            충전하기
          </Button>
        </div>
      )}
      <Content
        maxWidth={'100%'}
        padding={screenSize >= 768 ? 24 : 10}
        style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
      >
        <Col x={24}>
          <Row>
            <Col x={screenSize >= 972 ? 4 : 24}>
              <Content
                maxWidth={'100%'}
                padding={0}
                style={{
                  display: screenSize >= 972 ? '' : 'flex',
                  justifyContent: 'space-between',
                  marginBottom: screenSize >= 972 ? 0 : 10,
                }}
              >
                <Avatar
                  size={screenSize >= 972 ? 125 : screenSize >= 768 ? 100 : 150}
                  icon={
                    !data?.teacherFiles?.filter((item) => item.type === 'P')
                      ?.length > 0 && <UserOutlined />
                  }
                  src={
                    data?.teacherFiles?.filter((item) => item.type === 'P')
                      ?.length > 0 &&
                    `${STORE_URL}/users/${
                      data?.teacherFiles
                        ?.filter((item) => item.type === 'P')
                        ?.pop()?.user_id
                    }/${
                      data?.teacherFiles
                        ?.filter((item) => item.type === 'P')
                        ?.pop()?.file_url
                    }`
                  }
                />
                <Button
                  color="default"
                  type="secondary"
                  style={{
                    marginTop: screenSize >= 972 ? '26px' : '0px',
                    width: '125px',
                  }}
                  layoutStyle={{ margin: screenSize < 768 && 0 }}
                  onClick={() => handleModal(isOpened)}
                >
                  프로필 변경
                </Button>
              </Content>
            </Col>
            <Col
              x={screenSize >= 768 ? 20 : 24}
              style={{ paddingLeft: '10px' }}
            >
              <Content
                maxWidth={'100%'}
                padding={0}
                className="profile_info_layout"
              >
                <Row>
                  <Col x={24}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p className="profile_title">
                        {loginWho === 'agency'
                          ? newData?.name + '(' + newData?.type + ')'
                          : newData
                          ? newData?.name
                          : '알 수 없음'}
                        {screenSize < 768 && <br />}

                        {loginWho === 'agency' ? (
                          <span
                            style={{
                              display: 'block',
                              margin: '5px 0 0 0',
                            }}
                          >
                            {newData?.company_number
                              ? '사업자번호 : ' + newData?.company_number
                              : '-'}
                          </span>
                        ) : (
                          <span
                            style={{
                              marginLeft: screenSize < 768 ? '0px' : '8px',
                            }}
                          >
                            {newData?.gender === 'M' ? '남자' : '여자'} 만{' '}
                            {calculateAge(newData?.birthday).age}세 /{' '}
                            {moment(newData?.birthday).format('YYYY')}년생
                          </span>
                        )}
                      </p>
                      {screenSize >= 768 && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <p style={{ fontSize: 16 }}>
                            잔여 포인트{' '}
                            <span style={{ fontWeight: 700, color: '#AB5476' }}>
                              {stringToMoneyFormat(newData?.point, 'P')}
                            </span>
                          </p>
                          <Button
                            color="secondary"
                            style={{ marginLeft: 10 }}
                            onClick={() => handleOpenPopup()}
                          >
                            충전하기
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col x={24} style={{ marginTop: '14px' }}>
                    <Row>
                      <Col x={24}>
                        <Row className="profile_info_container">
                          <Col x={6} md={4}>
                            <p className="info_title">주소</p>
                          </Col>
                          <Col x={18} md={20}>
                            <p className="info_title">
                              {newData?.address
                                ? newData?.address
                                : '알 수 없음'}
                            </p>
                            <p
                              className="info_title"
                              style={{ marginTop: '5px' }}
                            >
                              {newData?.address_detail
                                ? newData.address_detail
                                : ''}
                            </p>
                          </Col>
                        </Row>
                        <Row className="profile_info_container">
                          <Col x={6} md={4}>
                            <p className="info_title">연락처</p>
                          </Col>
                          <Col x={18} md={20}>
                            <p className="info_title">
                              {newData ? newData.phone : '알 수 없음'}
                            </p>
                          </Col>
                        </Row>
                        <Row className="profile_info_container">
                          <Col x={6} md={4}>
                            <p className="info_title">이메일</p>
                          </Col>
                          <Col x={18} md={20}>
                            <p className="info_title">
                              {newData ? newData.email : '알 수 없음'}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Content>
            </Col>
          </Row>
        </Col>
      </Content>

      <ModalLayout
        title={'본인 확인'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 556 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: '40px' }}
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Row
                style={{ width: '100%', display: 'flex', alignItems: 'center' }}
              >
                <Col x={6}>
                  <label htmlFor="user_id" style={{ ...styles.modalLabel }}>
                    아이디
                  </label>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    id={'userid'}
                    maxLength={100}
                    value={
                      loginWho === 'agency'
                        ? newData?.agency_account
                        : newData?.user_account
                    }
                    disabled
                  />
                </Col>
              </Row>
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '25px',
                }}
              >
                <Col x={6}>
                  <label htmlFor="password" style={{ ...styles.modalLabel }}>
                    비밀번호
                  </label>
                </Col>
                <Col x={18}>
                  <Input.Password
                    size="large"
                    id={'password'}
                    placeholder={'비밀번호를 입력하세요.'}
                    value={password}
                    maxLength={100}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => handleEnterSubmit(e)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col x={24}>
              <Button
                style={{
                  width: '100%',
                  height: '40px',
                  marginTop: '24px',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
                onClick={() => handleCheckAccount()}
              >
                확인
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      <ModalLayout
        title={'회원정보 수정'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isOpenedEditer}
        onCancel={setIsOpenedEditer}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 556 : '100%'}
        height={'80%'}
        closable
        bodyStyle={{ padding: '20px' }}
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Button
            color="secondary"
            layoutStyle={{ display: 'flex', justifyContent: 'flex-end' }}
            onClick={() => {
              setIsOpenedEditer(false);
              setIsPWOpened(!isPWOpened);
            }}
          >
            비밀번호 변경
          </Button>
          <Row>
            <Col x={24}>
              <Form layout="vertical">
                <Form.Item
                  style={{
                    marginBottom: '20px',
                  }}
                >
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    아이디
                    <Input
                      bordered={false}
                      style={{
                        padding: '16px 20px',
                        borderRadius: '4px',
                        // border: '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                        background: '#F5F5F5',
                        color: '#000000',
                      }}
                      value={
                        loginWho === 'agency'
                          ? newData?.agency_account
                          : newData?.user_account
                      }
                      readOnly={true}
                      disabled={true}
                    />
                  </label>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: '20px' }}
                  hidden={radio === 2 ? true : false}
                >
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    이름
                    <Input
                      bordered={false}
                      placeholder="이름을 입력해주세요."
                      style={{
                        padding: '16px 20px',
                        borderRadius: '4px',
                        // border: '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                        color: 'black',
                        background: '#F5F5F5',
                      }}
                      disabled={true}
                      readOnly={true}
                      value={editData.name}
                      onChange={(e) => handleChange('name', e.target.value)}
                    />
                  </label>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: '20px' }}
                  hidden={radio === 1 ? true : false}
                >
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    사업자등록번호
                    <Input
                      placeholder="이름을 입력해주세요."
                      bordered={false}
                      style={{
                        padding: '16px 20px',
                        borderRadius: '4px',
                        // border: '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                        color: 'black',
                        background: '#F5F5F5',
                      }}
                      value={
                        loginWho === 'agency' ? newData?.company_number : ''
                      }
                      disabled
                    />
                  </label>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: '20px' }}
                  hidden={radio === 1 ? true : false}
                >
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    기관명
                    <Input
                      bordered={false}
                      placeholder="이름을 입력해주세요."
                      style={{
                        padding: '16px 20px',
                        borderRadius: '4px',
                        border: '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                        color: 'black',
                      }}
                      value={editData.name}
                      onChange={(e) => handleChange('name', e.target.value)}
                    />
                  </label>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: '20px' }}
                  hidden={radio === 2 ? true : false}
                >
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    생년월일
                    <DatePicker
                      name="birthday"
                      width="100%"
                      fontSize="15px"
                      margin="8px 0 10px"
                      value={editData?.birthday}
                      onChange={(e) => handleChange('birthday', e.target.value)}
                      disabled={true}
                      readOnly={true}
                    />
                  </label>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: '20px' }}
                  hidden={radio === 2 ? true : false}
                >
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    성별
                    <Select
                      defaultValue={genderlist && genderlist[0].value}
                      bordered={false}
                      style={{
                        padding: '10px 0',
                        borderRadius: '4px',
                        // border: '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                        background: '#F5F5F5',
                      }}
                      value={editData?.gender}
                      onChange={(e) => handleChange('gender', e)}
                      options={genderlist}
                      dropdownStyle={{ zIndex: 99999 }}
                      disabled={true}
                      readOnly={true}
                    />
                  </label>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: '20px' }}
                  hidden={radio === 2 ? true : false}
                >
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    닉네임
                    <Input
                      bordered={false}
                      placeholder="닉네임을 입력해주세요."
                      style={{
                        padding: '16px 20px',
                        borderRadius: '4px',
                        border: '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                      }}
                      value={editData?.nickname}
                      onChange={(e) => handleChange('nickname', e.target.value)}
                    />
                  </label>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                </Form.Item>
                <Form.Item style={{ marginBottom: '20px' }}>
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    이메일
                    <Input
                      placeholder="이메일을 입력해주세요."
                      style={{
                        padding: '16px 20px',
                        borderRadius: '4px',
                        border:
                          loginWho === 'agency' ? '' : '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                      }}
                      value={editData?.email}
                      onChange={(e) => handleChange('email', e.target.value)}
                    />
                  </label>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                  <Checkbox
                    style={{
                      fontSize: '16px',
                      lineHeight: '19px',
                      fontWeight: 400,
                    }}
                    checked={emailCheck}
                    onChange={() => handleChange('email_check', !emailCheck)}
                  >
                    이메일 수신 동의 (선택)
                  </Checkbox>
                </Form.Item>
                <Form.Item style={{ marginBottom: '20px' }}>
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    연락처
                    <Input
                      bordered={loginWho === 'agency'}
                      placeholder={
                        loginWho === 'agency'
                          ? '연락처를 입력해주세요'
                          : '휴대전화를 입력해주세요.'
                      }
                      style={{
                        padding: '16px 20px',
                        borderRadius: '4px',
                        // border: '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                        background:
                          loginWho === 'agency' ? '#FFFFFF' : '#F5F5F5',
                      }}
                      value={editData?.phone}
                      maxLength={13}
                      disabled={loginWho !== 'agency'}
                      onChange={(e) => handleHyphen(e.target.value)}
                    />
                  </label>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                  <Checkbox
                    style={{
                      fontSize: '16px',
                      lineHeight: '19px',
                      fontWeight: 400,
                    }}
                    checked={smsCheck}
                    onChange={() => handleChange('sms_check', !smsCheck)}
                  >
                    SMS 수신 동의 (선택)
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: '20px' }}
                  hidden={radio === 2 ? true : false}
                >
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    직업
                    <Input
                      bordered={false}
                      placeholder="직업을 입력해주세요."
                      style={{
                        padding: '16px 20px',
                        borderRadius: '4px',
                        border: '1px solid #BDBDBD',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        margin: '8px 0 10px',
                      }}
                      value={editData?.job}
                      onChange={(e) => handleChange('job', e.target.value)}
                    />
                  </label>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                </Form.Item>
                <Form.Item style={{ marginBottom: '20px' }}>
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    주소
                  </label>
                  <DaumAddress
                    bordered={false}
                    placeholder="주소를 검색해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      cursor: 'pointer',
                    }}
                    value={editData?.address}
                    readOnly
                    callback={(v) => handleChange('address', v)}
                  />
                  <Input
                    bordered={false}
                    placeholder="상세주소를 입력해주세요."
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                    }}
                    value={editData?.address_detail}
                    onChange={(e) =>
                      handleChange('address_detail', e.target.value)
                    }
                  />
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      color: '#F93232',
                    }}
                  ></span>
                </Form.Item>
              </Form>
              <Button
                style={{
                  marginTop: '40px',
                  fontSize: '16px',
                  fontweight: 700,
                  padding: '16px',
                  width: '100%',
                }}
                onClick={() => handleSubmit()}
              >
                수정하기
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      <ModalLayout
        title={'비밀번호 변경'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isPWOpened}
        onCancel={setIsPWOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 556 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: 40 }}
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Form layout="vertical">
                <Form.Item style={{ marginBottom: '20px' }}>
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    새 비밀번호
                    <Input.Password
                      size="large"
                      value={rePassword}
                      placeholder={'새 비밀번호를 입력하세요.'}
                      maxLength={16}
                      onChange={(e) => setRePassword(e.target.value)}
                      style={{ marginTop: 10 }}
                    />
                  </label>
                </Form.Item>
                <Form.Item style={{ marginBottom: '20px' }}>
                  <label
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      color: '#000000',
                    }}
                  >
                    비밀번호 확인
                    <Input.Password
                      size="large"
                      value={rePasswordCheck}
                      placeholder={'비밀번호를 다시 한번 입력하세요.'}
                      maxLength={16}
                      onChange={(e) => setRePasswordCheck(e.target.value)}
                      style={{ marginTop: 10 }}
                    />
                  </label>
                </Form.Item>
              </Form>
              <Button
                style={{
                  marginTop: '40px',
                  fontSize: '16px',
                  fontweight: 700,
                  padding: '16px',
                  width: '100%',
                }}
                onClick={() => handlePWSubmit()}
              >
                변경하기
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

ProfileCard.defaultProps = {};

export default ProfileCard;
