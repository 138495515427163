import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import StudentPresenter from './StudentPresenter';
import UseDebounce from 'utils/useDebounce';
import { decrypt, encrypt } from 'utils/Crypto';
import { EmailApi } from 'api';
import MessageAlert from 'utils/MessageAlert';

const StudentContainer = ({ data }) => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('lecture');
  const page = searchParams.get('page');

  /* ===== PROPS ===== */
  const {
    lectures,
    chapters,
    sections,
    lectureOfUsers,
    users,
    teachers,
    categories,
  } = data;

  /* ===== STATE ===== */
  // 강의 검색
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = UseDebounce(searchValue, 300);
  // 강의 목록
  const [lectureList, setLectureList] = useState([]);
  const [filterLectureList, setFilterLectureList] = useState([]);
  // 선택 강의
  const [selectedLectureId, setSelectedLectureId] = useState(null);
  const [selectedLectureInfo, setSelectedLectureInfo] = useState({});
  // 페이지
  const [currentPage, setCurrentPage] = useState(1);
  // 문자 남은 횟수
  const [smsRemain, setSmsRemain] = useState(1);
  /* ===== Functions ===== */
  /**
   * pagination 처리 함수
   * --
   */
  const handlePagination = (page) => {
    navigate(
      `/admin/student?page=${page}&lecture=${encodeURIComponent(
        encrypt(selectedLectureId)
      )}`
    );
  };

  /**
   * 독려 메세지 전송
   * --
   */
  const handleSendMessage = async (type, data) => {
    const { name, user_id, email, phone } = data;

    const confirm = (title) =>
      window.confirm(`강의 독려 ${title}를 전송하시겠습니까?`);

    switch (type) {
      case 'EMAIL':
      case 'Email':
      case 'email':
        if (confirm('이메일')) {
          const { status } = await EmailApi.Sendmail({
            toEmail: email,
            type: 'encouragement',
            template_value: {
              lecture_title: selectedLectureInfo?.title,
              name: name,
              user_id: user_id,
            },
          });

          if (status !== 200)
            return MessageAlert.error('이메일을 전송하는데 실패하였습니다!');

          return MessageAlert.success('성공적으로 이메일을 전송하였습니다.');
        } else {
          return;
        }
      case 'SMS':
      case 'Sms':
      case 'sms':
        // TODO: 독려 문자 전송
        if (confirm('문자')) {
          const { status } = await EmailApi.Sendsms({
            type: 'send',
            receiver: phone,
            msg: `${name}님 ${selectedLectureInfo?.title} 강의 진도율이 낮습니다.조금만 더 힘내세요!`,
          });
          if (status !== 200)
            return MessageAlert.error('문자를 전송하는데 실패하였습니다!');

          return MessageAlert.success('성공적으로 문자를 전송하였습니다.');
        } else {
          return;
        }
      default:
        break;
    }
  };

  /* ===== RENDER ===== */
  /**
   * 강의 데이터 정제
   * --
   */
  useEffect(() => {
    // 강사 + 유저
    const newUsers = users?.concat(teachers);
    const newUsreList = newUsers?.map((item) => ({
      ...item,
      profile: item?.files?.filter((subitem) => subitem?.type === 'P')?.pop(),
    }));
    // 강의 데이터 정제
    const newLectures = lectures?.map((item) => ({
      ...item,
      regist_date: item?.created_at,
      category_name: categories
        ?.filter((subitem) => item?.category_id === subitem?.category_id)
        ?.pop()?.category_name,
      chapters: chapters?.filter(
        (subitem) => subitem?.lecture_id === item?.lecture_id
      ),
      sections: sections?.reduce((acc, cur) => {
        const c = chapters?.filter(
          (subitem) => subitem?.lecture_id === item?.lecture_id
        );

        const isExist = c?.some(
          (subitem) => subitem?.chapter_id === cur?.chapter_id
        );
        if (isExist) {
          acc.push(cur);
          return acc;
        } else {
          return acc;
        }
      }, []),
      thumbnail: item?.files?.filter((subitem) => subitem?.type === 'T')?.pop(),
      ...(newUsreList &&
        newUsreList?.filter(
          (subitem) => parseInt(item?.user_id) === parseInt(subitem?.user_id)
        )[0]),
    }));
    // 수강생 데이터 정제
    const newLectureOfUsers = lectureOfUsers?.map((item) => ({
      lecture_start_date:
        item?.progress?.length && item?.progress[0]?.created_at,
      lecture_last_date:
        item?.progress?.length &&
        item?.progress[item?.progress.length - 1]?.created_at,
      ...item,
      payment_date: item?.created_at,
      ...(newUsreList &&
        newUsreList?.filter(
          (subitem) => parseInt(item?.user_id) === parseInt(subitem?.user_id)
        )[0]),
    }));

    // console.log('sections', sections);
    // 최종 데이터 정제
    const Data = newLectures?.map((item) => ({
      ...item,
      student: newLectureOfUsers
        ?.filter((subitem) => item?.lecture_id === subitem?.lecture_id)
        ?.map((subitem) => ({
          ...subitem,
          progress_status: subitem?.progress?.length
            ? (subitem?.progress?.filter((item) => item?.status === 'DONE')
                ?.length /
                item?.sections?.length) *
              100
            : 0,
        })),
    }));

    setLectureList(Data);
  }, [
    lectures,
    chapters,
    sections,
    lectureOfUsers,
    users,
    teachers,
    categories,
  ]);

  /**
   * 강의 검색
   * --
   */
  useEffect(() => {
    const list = lectureList;
    const filterList = list?.filter((item) =>
      item?.title?.includes(debouncedValue)
    );

    setFilterLectureList(filterList);
  }, [debouncedValue, lectureList]);

  /**
   * 쿼리 스트링 값 정제
   * --
   */
  useEffect(() => {
    if (lectureId) {
      const id = decodeURIComponent(lectureId);
      const l = lectures?.filter((item) => item?.lecture_id === id)?.pop();
      setSelectedLectureInfo(l);
      setSelectedLectureId(decrypt(id));
    } else {
      const firstLecture = lectures?.length ? lectures[0] : null;

      if (firstLecture) {
        const l = lectures
          ?.filter((item) => item?.lecture_id === firstLecture?.lecture_id)
          ?.pop();
        setSelectedLectureId(firstLecture?.lecture_id);
        setSelectedLectureInfo(l);
      }
    }
  }, [lectureId, lectures]);

  /**
   * 선택한 강의 정보 정제
   * --
   */
  useEffect(() => {
    if (selectedLectureId) {
      const info = lectureList
        ?.filter(
          (item) => parseInt(item?.lecture_id) === parseInt(selectedLectureId)
        )
        ?.pop();
      setSelectedLectureInfo(info);
    }
  }, [lectureList, selectedLectureId]);

  /**
   * 문자 남은 정보
   * --
   */
  useEffect(() => {
    const RemainSms = async () => {
      const { status, data } = await EmailApi.RemainSms({
        type: 'remain',
      });
      if (status === 200) return setSmsRemain(data);
    };
    RemainSms();
  }, []);

  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  return (
    <>
      <StudentPresenter
        filterLectureList={filterLectureList}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedLectureId={selectedLectureId}
        setSelectedLectureId={setSelectedLectureId}
        selectedLectureInfo={selectedLectureInfo}
        currentPage={currentPage}
        navigate={navigate}
        onPagination={handlePagination}
        onSendMessage={handleSendMessage}
        smsRemain={smsRemain}
      />
    </>
  );
};

export default StudentContainer;
