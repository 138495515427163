import React, { useState, useEffect, useMemo } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  RadioCategory,
  LectureCard,
  LoadingPage,
  Category,
  ModalLayout,
  Button,
} from 'components';
import { Card, Pagination } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { encrypt, decrypt } from 'utils/Crypto';
import {
  // getCookie,
  getFirstImageFromContent,
  STORE_URL,
} from 'utils';
import { FilterOutlined } from '@ant-design/icons';

const styles = {
  cardButton: {
    height: 230,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentCardButton: {
    border: 'none',
    padding: 0,
    borderRadius: 0,
    background: 'none',
  },
};

// 렌더링 갯수
const RENDER = 12;

const ShopList = () => {
  /* ===== Props ===== */
  const { type } = useParams();

  /* ===== Router ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get('category_id');
  const [page, setPage] = useState(1);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);

  /* ===== Data ===== */
  const queryClient = useQueryClient();
  const products = queryClient.getQueryData('getProducts')?.data;
  const productFiles = queryClient.getQueryData('getProductFiles')?.data;
  const productCategories = queryClient.getQueryData(
    'getProductCategories'
  )?.data;

  //상품데이터 정제
  const NewProduct = useMemo(() => {
    const firstProduct = products
      ?.filter((i) => i.is_show === 'Y')
      ?.map((item) => ({
        ...item,
        productFiles:
          productFiles &&
          productFiles?.filter(
            (subitem) => item.product_id === subitem.product_id
          ),
        ...productCategories
          ?.filter(
            (subitem) =>
              item.product_category_id === subitem.product_category_id
          )
          ?.pop(),
      }));

    return firstProduct?.filter((i) => i.product_type === type);
  }, [type, products, productCategories, productFiles]);

  /* ===== State ===== */
  const [productList, setProductList] = useState([]);
  const [filterItem01, setFilterItem01] = useState([]);
  const [filterOption01, setFilterOption01] = useState('all');
  const filterItem02 = [
    {
      label: '전체',
      value: '0~9999999999999',
    },
    {
      label: '1만원이하',
      value: '0~10000',
    },
    {
      label: '1만원 ~ 3만원',
      value: '10000~30000',
    },
    {
      label: '3만원 ~ 5만원',
      value: '30000~50000',
    },
    {
      label: '5만원이상',
      value: '50000~9999999999999',
    },
  ];
  const [filterOption02, setFilterOption02] = useState(filterItem02[0].value);
  const filterItem03 = [
    {
      label: '전체',
      value: 'all',
    },
    {
      label: '포인트 결제',
      value: 'N',
    },
    {
      label: '현금 결제',
      value: 'Y',
    },
  ];
  const [filterOption03, setFilterOption03] = useState(filterItem03[0].value);
  const [dependency, setDependency] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subFilterList, setSubFilterList] = useState([]);
  const [category, setCategory] = useState('all');
  const [isOpened, setIsOpened] = useState(false);

  /* ===== Hooks ===== */
  /**
   * 카테고리 아이템 정제
   * --
   */
  useEffect(() => {
    if (productCategories) {
      const categories = productCategories?.filter(
        (item) => item?.product_type === type && item?.type === 'P'
      );
      const newCategory = [
        {
          label: '전체',
          value: 'all',
        },
        ...(categories &&
          categories?.map((item) => ({
            label: `${item?.product_category_name}`,
            value: `${item?.product_category_id}`,
          }))),
      ];
      setFilterItem01(newCategory);

      const subFilter = productCategories
        ?.filter((item) => item?.product_type === type && item?.type === 'C')
        ?.filter((item) =>
          filterOption01 === 'all'
            ? item
            : item?.parents_id === parseInt(filterOption01)
        );
      const newSubCategory = [
        {
          label: '전체',
          value: 'all',
        },
        ...(subFilter &&
          subFilter?.map((item) => ({
            label: `${item?.product_category_name}`,
            value: `${item?.product_category_id}`,
          }))),
      ];
      setSubFilterList(newSubCategory);
    }
  }, [type, productCategories, filterOption01]);

  /**
   * 필터 아이템 정제
   * --
   */
  useEffect(() => {
    if (productCategories) {
      if (categoryId) {
        let id = '';
        if (type === 'D') {
          id = productCategories?.filter(
            (item) =>
              item?.product_category_id === parseInt(decrypt(categoryId))
          )[0]?.parents_id;

          setCategory(decrypt(categoryId));
        } else {
          id = productCategories?.filter(
            (item) =>
              item?.product_category_id === parseInt(decrypt(categoryId))
          )[0]?.product_category_id;
          setCategory('all');
        }

        setFilterOption01(`${id}`);

        const subFilter = productCategories
          ?.filter((item) => item?.parents_id === id)
          ?.map((item) => ({
            label: item?.product_category_name,
            value: `${item?.product_category_id}`,
          }));
        setSubFilterList([{ label: '전체', value: 'all' }, ...subFilter]);
      } else {
        setFilterOption01('all');
        setCategory('all');

        const subFilter = productCategories
          ?.filter((item) => item?.product_type === type && item?.type === 'C')
          ?.map((item) => ({
            label: item?.product_category_name,
            value: `${item?.product_category_id}`,
          }));
        setSubFilterList([{ label: '전체', value: 'all' }, ...subFilter]);
      }
    }
  }, [type, categoryId, productCategories]);

  /**
   * 카테고리별 상품 아이템 정제
   * --
   */
  useEffect(() => {
    const filterData = () => {
      if (categoryId) {
        const newProducts = NewProduct?.filter(
          (item) =>
            item?.category_id === parseInt(decrypt(categoryId)) ||
            item?.parents_id === parseInt(decrypt(categoryId))
        );
        setProductList(newProducts);
      } else {
        setProductList(NewProduct);
      }
    };

    if (NewProduct && productFiles && !dependency) {
      filterData();
      setDependency(true);
    }
  }, [categoryId, NewProduct, dependency, productFiles]);

  /**
   * 로딩 처리
   * --
   */
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [isLoading]);

  /**
   * 서브 카테고리별 강의 검색
   * --
   */
  useEffect(() => {
    const filterData = NewProduct?.filter((item) =>
      category !== 'all'
        ? item?.product_category_id === parseInt(category)
        : item
    );

    setProductList(filterData);
  }, [category, NewProduct]);

  /**
   * 필터별 강의 검색
   * --
   */
  useEffect(() => {
    const range = filterOption02?.split('~');

    const newLectures = NewProduct?.filter((item) =>
      filterOption01 !== 'all'
        ? item?.product_category_id === parseInt(filterOption01) ||
          item?.parents_id === parseInt(filterOption01)
        : item
    )
      ?.filter((item) =>
        filterOption03 !== 'all' ? item?.is_cash === filterOption03 : item
      )
      ?.filter(
        (item) =>
          parseInt(range[0]) <= item?.price && item?.price <= parseInt(range[1])
      )
      ?.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

    setProductList(newLectures);
  }, [filterOption01, filterOption02, filterOption03, NewProduct]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== Functions ===== */
  /**
   * 네비게이트 함수
   * --
   * @param {*} id
   */
  const handleNavigate = (id) => {
    navigate(`/shop/detail/${encodeURIComponent(encrypt(id))}`);
    // if (getCookie('BRAINHEAL_ACCESS_TOKEN')) {
    // } else {
    //   window.alert('로그인 후 이용해주세요');
    //   navigate(`/login`);
    // }
  };

  /**
   * 모달
   * --
   */
  const handleFilterModal = (open) => {
    setIsOpened(!open);
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={0} maxWidth={'100%'} style={{ margin: '48px 0 84px' }}>
        {isLoading && <LoadingPage />}
        <Content padding={0} maxWidth={1200}>
          <Row>
            <Col x={24}>
              <Title
                align={'center'}
                style={{
                  fontSize: '35px',
                  fontWeight: 700,
                  lineHeight: '42px',
                  letterSpacing: '0.02em',
                  marginBottom: '37px',
                }}
              >
                쇼핑몰
              </Title>
            </Col>
            <Col x={0} xl={5} style={{ paddingTop: '15px' }}>
              <Card
                hoverable={false}
                bodyStyle={{
                  padding: 15,
                  border: '1px solid #9999A3',
                  borderRadius: '4px',
                }}
              >
                <RadioCategory
                  title={'카테고리별'}
                  items={filterItem01}
                  value={filterOption01}
                  onChange={setFilterOption01}
                />
              </Card>
              <br />
              <Card
                hoverable={false}
                bodyStyle={{
                  padding: 15,
                  border: '1px solid #9999A3',
                  borderRadius: '4px',
                }}
              >
                <RadioCategory
                  title={'결제수단별'}
                  items={filterItem03}
                  value={filterOption03}
                  onChange={setFilterOption03}
                />
              </Card>
              <br />
              <Card
                hoverable={false}
                bodyStyle={{
                  padding: 15,
                  border: '1px solid #9999A3',
                  borderRadius: '4px',
                }}
              >
                <RadioCategory
                  title={'금액별'}
                  items={filterItem02}
                  value={filterOption02}
                  onChange={setFilterOption02}
                />
              </Card>
            </Col>
            <Col x={0} xl={1}></Col>
            <Col x={24} xl={18}>
              {/* ===== 온라인교육 ===== */}
              <div
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <div style={{ padding: '0 10px' }}>
                  <Content
                    maxWidth={'100%'}
                    padding={0}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '29px',
                    }}
                  >
                    <Title size={3}>쇼핑몰</Title>
                    {screenSize >= 1200 ? null : (
                      <p
                        style={{
                          fontSize: '14px',
                          cursor: 'pointer',
                          color: '#9E9E9E',
                        }}
                        onClick={() => handleFilterModal(isOpened)}
                      >
                        필터
                        <span>
                          <FilterOutlined
                            style={{ fontSize: '18px', color: '#E3A4AF' }}
                          />
                        </span>
                      </p>
                    )}
                  </Content>
                  <Category
                    items={subFilterList}
                    value={category}
                    setValue={setCategory}
                    style={{ margin: '29px 0 23px' }}
                  />
                </div>
                <div>
                  <Row>
                    {productList && productList.length > 0 ? (
                      productList
                        ?.slice((page - 1) * RENDER, page * RENDER)
                        ?.map((_item, _index) => (
                          <Col
                            x={12}
                            xl={8}
                            lg={6}
                            md={8}
                            style={{ padding: 0 }}
                            key={`shop_list_item_${_index}`}
                          >
                            <Card
                              hoverable
                              style={styles.contentCardButton}
                              bodyStyle={{ padding: 10 }}
                              onClick={() => handleNavigate(_item?.product_id)}
                            >
                              <LectureCard
                                productCheck={true}
                                thumbnail={
                                  _item?.thumbnail_url
                                    ? `${STORE_URL}/product/${_item.thumbnail_url}`
                                    : getFirstImageFromContent(_item.content)
                                }
                                title={_item?.product_name}
                                teacher={
                                  _item?.num ? '수량 :' + _item?.num : ''
                                }
                                category={_item?.product_category_name}
                                price={_item?.price}
                                isCash={_item?.is_cash}
                              />
                            </Card>
                          </Col>
                        ))
                    ) : (
                      <Col x={24}>
                        <Content
                          maxWidth={'100%'}
                          height={screenSize >= 768 ? 800 : 350}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <h4
                            style={{
                              fontSize: screenSize >= 768 ? '30px' : 18,
                            }}
                          >
                            해당 조건을 만족하는 상품이 없습니다
                          </h4>
                        </Content>
                      </Col>
                    )}
                  </Row>
                  <br />
                  <br />
                  {productList?.length && productList?.length > 0 ? (
                    <Pagination
                      defaultCurrent={1}
                      current={page}
                      defaultPageSize={12}
                      total={productList?.length}
                      onChange={(e) => setPage(e)}
                      style={{ textAlign: 'center' }}
                    />
                  ) : null}
                </div>
              </div>
              {/* ===== 온라인교육 ===== */}
            </Col>
          </Row>
        </Content>
      </Content>
      {/* ===== 필터모달 ===== */}
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'right'}
        width={'70%'}
        height={'100%'}
        closable
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
        footerStyle={{ padding: 0 }}
        footer={
          <Content maxWidth={'100%'} padding={0}>
            <Button
              style={{
                width: '100%',
                height: '50px',
                borderRadius: 0,
                fontSize: '16px',
              }}
              onClick={() => handleFilterModal(isOpened)}
            >
              확인
            </Button>
          </Content>
        }
      >
        <Content maxWidth={'100%'}>
          <Card
            hoverable={false}
            bodyStyle={{
              padding: 15,
              border: '1px solid #9999A3',
              borderRadius: '4px',
            }}
          >
            <RadioCategory
              title={'카테고리별'}
              items={filterItem01}
              value={filterOption01}
              onChange={setFilterOption01}
            />
          </Card>
          <br />
          <Card
            hoverable={false}
            bodyStyle={{
              padding: 15,
              border: '1px solid #9999A3',
              borderRadius: '4px',
            }}
          >
            <RadioCategory
              title={'금액별'}
              items={filterItem02}
              value={filterOption02}
              onChange={setFilterOption02}
            />
          </Card>
        </Content>
      </ModalLayout>
    </>
  );
};

ShopList.defaultProps = {};

export default ShopList;
