import React, { useState, useEffect } from 'react';
import SchedulesPresenter from './SchedulesPresenter';
import { useQueryClient } from 'react-query';

const SchedulesContainer = ({ data }) => {
  /* ===== Props ===== */
  const { teachers, agencies, categories, profiles, programs } = data;

  /* ===== Router ===== */
  /* ===== State ===== */
  const queryClient = useQueryClient();
  const repetitionSchedules = queryClient.getQueryData(
    'getRepetitionSchedules'
  )?.data;
  const [newData, setNewData] = useState({});

  /* ===== Functions ===== */
  /* ===== Hooks ===== */
  useEffect(() => {
    const newProgram = programs?.map((item) => ({
      ...item,
      ...(categories &&
        categories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]),
    }));

    const filterAgencyData = agencies?.map((item) => ({
      agency_id: item?.agency_id,
      agency_name: item?.name,
      agency_address: item?.address,
      agency_address_detail: item?.address_detail,
      agency_type: item?.type,
      agency_phone: item?.phone,
      agency_email: item?.email,
    }));

    const newRepetitionSchedules = repetitionSchedules?.map((item) => ({
      ...item,
      ...(filterAgencyData &&
        filterAgencyData?.filter(
          (subitem) => subitem?.agency_id === item?.agency_id
        )[0]),
      category_name: categories?.filter(
        (subitem) => subitem?.category_id === item?.category_id
      )[0]?.category_name,
    }));

    const newTeachers = teachers?.map((item) => ({
      ...item,
      profile: profiles
        ?.filter((subitem) => item?.user_id === subitem?.user_id)
        ?.pop(),
    }));

    setNewData({
      teachers: newTeachers,
      agencies: agencies,
      categories: categories,
      repetitionSchedules: newRepetitionSchedules,
      programs: newProgram?.map((item) => ({
        ...item,
        parents_name: categories?.filter(
          (subitem) => subitem?.category_id === item?.parents_id
        )[0]?.category_name,
      })),
    });
  }, [repetitionSchedules, agencies, categories, profiles, teachers, programs]);

  /* ===== Render ===== */
  return (
    <SchedulesPresenter
      teachers={newData?.teachers}
      agencies={newData?.agencies}
      categories={newData?.categories}
      repetitionSchedules={newData?.repetitionSchedules}
      programs={newData?.programs}
    />
  );
};

SchedulesContainer.defaultProps = {};

export default SchedulesContainer;
