import React, { useState, useEffect } from 'react';
import ProductDeliveryPresenter from './ProductDeliveryPresenter';

const ProductDeliveryContainer = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);
  const [productData, setProductData] = useState([]);
  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { users, teachers, agencies, products } = data;
      if (users && teachers && products) {
        const mergeUsers = [...users, ...teachers, ...agencies];
        const newUsers = mergeUsers?.map((item) => ({
          ...item,
          profile_img: item?.files?.filter((subitem) => subitem?.type === 'P'),
        }));
        const salesProduct = products?.filter(
          (item) => item.sales_type === 'S' && item.product_id !== 0
        );
        setNewData(newUsers);
        setProductData(salesProduct);
      }
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <ProductDeliveryPresenter userList={newData} productList={productData} />
  );
};

ProductDeliveryContainer.defaultProps = {};

export default ProductDeliveryContainer;
