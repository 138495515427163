import React, { useEffect } from 'react';
import { Row, Col, Content, Title } from 'components';
import { Tabs, List, Empty } from 'antd';
import moment from 'moment';
import VirtualList from 'rc-virtual-list';
import { useNavigate, useLocation } from 'react-router-dom';
import { encrypt } from 'utils/Crypto';
import { AnswerExamApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { getCookie } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';

function MyTestPresenter({
  lectures,
  users,
  lectureOfUsers,
  chapters,
  sections,
  exams,
  answerExams,
  idx,
  screenSize,
}) {
  /* ===== Props ===== */

  /* ===== Mutate ===== */
  /**
   * 재시험
   * --
   */
  const UpdateReExam = AnswerExamApi.UpdateAnswerExam({
    onSuccess: (data) => {
      try {
        MessageAlert.success('재시험을 시작합니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /* ===== Router ===== */
  const navigate = useNavigate();
  const location = useLocation();
  /* ===== State ===== */
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const today = moment(new Date()).format('YYYY-MM-DD');

  const NewExams = exams?.map((item) => ({
    ...item,
    answerExams:
      answerExams &&
      answerExams
        ?.filter((subitem) => item.exam_id === subitem.exam_id)
        ?.filter((lowitem) => lowitem.user_id === idx)
        ?.pop(),
  }));
  /**
   * 강의 데이터 정제
   * --
   */
  const NewLectures = lectures?.map((item) => ({
    ...item,
    lecture_title: item.title,
    ...(
      users && users?.filter((subitem) => item.user_id === subitem.user_id)
    )?.pop(),
    ing: today < moment(item.end_date).format('YYYY-MM-DD'),
    ...(
      NewExams &&
      NewExams?.filter((subitem) => item.lecture_id === subitem.lecture_id)
    )?.pop(),
  }));
  /**
   * 챕터-섹션 데이터 정제
   * --
   */
  const NewChapters = chapters?.map((item) => ({
    ...item,
    sections_count: sections?.filter(
      (subitem) => item.chapter_id === subitem.chapter_id
    )?.length,
  }));
  /**
   * 수강 + 시험 데이터 정제
   * --
   */
  const NewClassList = lectureOfUsers
    ?.filter((item) => item.user_id === idx)
    .map((subitem) => ({
      ...subitem,
      lecture_of_user_created_at: subitem.created_at,
      ...(
        NewLectures &&
        NewLectures?.filter(
          (lowitem) => subitem.lecture_id === lowitem.lecture_id
        )
      )?.pop(),
      ...(exams &&
        exams
          ?.filter((examitem) => subitem.lecture_id === examitem.lecture_id)
          ?.pop()),
      chapters: NewChapters?.filter(
        (chapterItem) => subitem.lecture_id === chapterItem.lecture_id
      ),
    }));
  /**
   * 수강 데이터 정제 - 진도율, 시험 등
   * --
   * FIXME: exam_score 정제 수정/ 시험점수
   * NOTE: 완료
   */
  const NewLectureOfUserList = NewClassList?.map((item) => ({
    ...item,
    total_sections_count: item.chapters?.reduce(
      (prev, current) => prev + current.sections_count,
      0
    ),
    progress_count: item.progress ? item.progress.length : 0,
    exam_score: item.answerExams?.answer?.reduce(
      (prev, current) => prev + current.score,
      0
    ),
  }));

  // console.log('NewLectureOfUserList', NewLectureOfUserList);
  /* ===== Hooks ===== */
  /**
   * 기관회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /* ===== Functions ===== */
  const onhandleTest = (lecture_id, exam_id, re_exam_num, answer_exam_id) => {
    // console.log('answer_exam_id', answer_exam_id);
    // console.log('re_exam_num', re_exam_num);
    if (
      window.confirm(
        (answer_exam_id ? '재' : '') +
          '시험을 응시하시겠습니까?' +
          (answer_exam_id ? '\n확인 하시면 재시험 횟수가 증가합니다.' : '')
      )
    ) {
      if (answer_exam_id) {
        try {
          UpdateReExam.mutate({
            answer_exam_id: answer_exam_id,
            re_exam_num: re_exam_num + 1,
          });
        } catch (e) {
          throw e;
        }
      }
      navigate(
        `/exam/${encodeURIComponent(encrypt(lecture_id))}/${encodeURIComponent(
          encrypt(exam_id)
        )}`,
        { state: { from: location } }
      );
    }
  };

  /* ===== Render ===== */
  return (
    <Content maxWidth={'100%'} padding={'0 12px'}>
      <Row>
        <Col x={24} padding={8}>
          <Title
            size={screenSize >= 768 ? 2 : 4}
            padding={8}
            style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
          >
            지원 가능한 자격 시험
          </Title>
        </Col>
        <Col x={24}>
          <Content
            maxWidth={'100%'}
            padding={
              screenSize >= 768 ? '24px 24px 0 24px' : '10px 10px 0 10px'
            }
            style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
          >
            <Col x={24}>
              <Tabs
                size="small"
                defaultActiveKey="1"
                items={[
                  {
                    key: '1',
                    label: `응시 가능한 시험`,
                    children: (
                      <Content maxWidth={'100%'} padding={0}>
                        <Row>
                          <Col x={24}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: screenSize >= 768 ? 60 : 40,
                                padding: '9px 6px',
                                background: '#D28494',
                                borderRadius: '4px 4px 0px 0px',
                              }}
                            >
                              <Col x={10} md={7} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  강의명
                                </Title>
                              </Col>
                              <Col x={0} md={7}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                  }}
                                >
                                  시험응시 가능 기간
                                </Title>
                              </Col>
                              <Col x={10} md={5}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  시험응시 가능 여부
                                </Title>
                              </Col>
                              <Col x={4} md={5}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  점수
                                </Title>
                              </Col>
                            </div>
                          </Col>
                          <Col x={24}>
                            <List>
                              {NewLectureOfUserList?.length < 1 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    minHeight: '400px',
                                    alignItems: 'center',
                                    border: '1px solid #f0f0f0',
                                  }}
                                >
                                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                              ) : (
                                <VirtualList
                                  data={NewLectureOfUserList}
                                  height={screenSize >= 768 ? 440 : 350}
                                  itemHeight={60}
                                  itemKey="title"
                                >
                                  {(item, index) => (
                                    <List.Item
                                      key={item.lecture_title + index}
                                      style={{
                                        background:
                                          index % 2 === 0
                                            ? '#FFFFFF'
                                            : '#FAFAFA',
                                      }}
                                    >
                                      <Content
                                        maxWidth={'100%'}
                                        padding={0}
                                        backgroundColor={'none'}
                                      >
                                        <Row style={{ alignItems: 'center' }}>
                                          <Col x={10} md={7} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                                display: 'block',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                              }}
                                            >
                                              {item.lecture_title}
                                            </Title>
                                          </Col>
                                          <Col x={0} md={7} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize: '16px',
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {moment(item.start_date).format(
                                                'YY-MM-DD'
                                              )}
                                              ~
                                              {moment(item.end_date).format(
                                                'YY-MM-DD'
                                              )}
                                            </Title>
                                          </Col>
                                          <Col
                                            x={10}
                                            md={5}
                                            padding={8}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                fontWeight: 500,
                                                lineHeight: '19px',
                                                color: '#000000',
                                                width: '66px',
                                                height: '27px',
                                                marginLeft: '10px',
                                                borderRadius: '4px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background:
                                                  item.exam_score >= 0
                                                    ? '#f2d3db'
                                                    : (!item.exam ||
                                                        item.exam?.length ===
                                                          0 ||
                                                        item.progress_count /
                                                          item.total_sections_count <=
                                                          1) &&
                                                      '#E0E0E0',
                                              }}
                                            >
                                              {item.exam_score >= 0 ? (
                                                '응시완료'
                                              ) : item.exam &&
                                                item.exam?.length &&
                                                item.progress_count /
                                                  item.total_sections_count ===
                                                  1 ? (
                                                <button
                                                  style={{
                                                    background: '#A4E3AA',
                                                    border: 'none',
                                                    width: '66px',
                                                    height: '27px',
                                                    borderRadius: '4px',
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() =>
                                                    onhandleTest(
                                                      item.lecture_id,
                                                      item.exam_id
                                                    )
                                                  }
                                                >
                                                  응시가능
                                                </button>
                                              ) : (
                                                '응시불가'
                                              )}
                                            </div>
                                          </Col>
                                          <Col
                                            x={4}
                                            md={5}
                                            padding={screenSize >= 768 ? 8 : 0}
                                          >
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {item.exam_score >= 0
                                                ? item.exam_score
                                                : 0}
                                              점
                                            </Title>
                                          </Col>
                                        </Row>
                                      </Content>
                                    </List.Item>
                                  )}
                                </VirtualList>
                              )}
                            </List>
                          </Col>
                        </Row>
                      </Content>
                    ),
                  },

                  // 종료된 시험 -
                  // 강의명, 재시험 요청 가능 날짜(시험 응시 완료 이후 7일), 시험점수, 종료이유(응시완료, 기간종료), 재시험 요청 버튼(시험 요청, 요청 불가, 요청 중)
                  {
                    key: '2',
                    label: `종료된 시험`,
                    children: (
                      <Content maxWidth={'100%'} padding={0}>
                        <Row>
                          <Col x={24}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: screenSize >= 768 ? 60 : 40,
                                padding: '9px 6px',
                                background: '#D28494',
                                borderRadius: '4px 4px 0px 0px',
                              }}
                            >
                              <Col x={10} md={5} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  강의명
                                </Title>
                              </Col>
                              <Col x={0} md={4}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                  }}
                                >
                                  재 시험 가능 날짜
                                </Title>
                              </Col>
                              <Col x={0} md={4} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                  }}
                                >
                                  시험 점수
                                </Title>
                              </Col>
                              <Col x={7} md={4} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  종료 사유
                                </Title>
                              </Col>
                              <Col x={7} md={4} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 13,
                                    lineHeight: '19px',
                                  }}
                                >
                                  재시험 요청
                                </Title>
                              </Col>
                              <Col x={0} md={3} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  요청한 횟수
                                </Title>
                              </Col>
                            </div>
                          </Col>
                          <Col x={24}>
                            <List>
                              {NewLectureOfUserList?.filter(
                                (item) =>
                                  item.end_date < today ||
                                  item.answerExams !== undefined
                              )?.length < 1 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    minHeight: '400px',
                                    alignItems: 'center',
                                    border: '1px solid #f0f0f0',
                                  }}
                                >
                                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                              ) : (
                                <VirtualList
                                  data={NewLectureOfUserList?.filter(
                                    (item) =>
                                      item.end_date < today ||
                                      item.answerExams !== undefined
                                  )}
                                  height={screenSize >= 768 ? 440 : 350}
                                  itemHeight={60}
                                  itemKey="title"
                                >
                                  {(item, index) => (
                                    <List.Item
                                      key={item.lecture_title + index}
                                      style={{
                                        background:
                                          index % 2 === 0
                                            ? '#FFFFFF'
                                            : '#FAFAFA',
                                      }}
                                    >
                                      <Content
                                        maxWidth={'100%'}
                                        padding={0}
                                        backgroundColor={'none'}
                                      >
                                        <Row style={{ alignItems: 'center' }}>
                                          <Col x={10} md={5} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                                display: 'block',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                              }}
                                            >
                                              {item.lecture_title}
                                            </Title>
                                          </Col>
                                          <Col x={0} md={4} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize: '16px',
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {item.exam_score
                                                ? moment(
                                                    item.answerExams?.created_at
                                                  )
                                                    .add(7, 'days')
                                                    .format('YYYY-MM-DD')
                                                : moment(item.end_date)
                                                    .add(7, 'days')
                                                    .format('YYYY-MM-DD')}
                                            </Title>
                                          </Col>
                                          <Col x={0} md={4} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize: '16px',
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {item.exam_score
                                                ? item.exam_score
                                                : 0}
                                              점
                                            </Title>
                                          </Col>
                                          <Col
                                            x={7}
                                            md={4}
                                            padding={8}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                fontWeight: 500,
                                                lineHeight: '19px',
                                                color: '#000000',
                                                width: '66px',
                                                height: '27px',
                                                borderRadius: '4px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: item.exam_score
                                                  ? '#f2d3db'
                                                  : // : (!item.exam ||
                                                    //     item.exam?.length === 0 ||
                                                    //     item.progress_count /
                                                    //       item.total_sections_count <=
                                                    //       1) &&
                                                    '#E0E0E0',
                                              }}
                                            >
                                              {item.exam_score
                                                ? '응시완료'
                                                : '기간종료'}
                                            </div>
                                          </Col>
                                          <Col x={7} md={4} padding={8}>
                                            <div
                                              style={{
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                fontWeight: 500,
                                                lineHeight: '19px',
                                                color: '#000000',
                                                width: '66px',
                                                height: '27px',
                                                marginLeft: '10px',
                                                borderRadius: '4px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background:
                                                  item.exam_score >= 80
                                                    ? '#A4E3AA'
                                                    : item.exam_score
                                                    ? '#f2d3db'
                                                    : // : (!item.exam ||
                                                      //     item.exam?.length ===
                                                      //       0 ||
                                                      //     item.progress_count /
                                                      //       item.total_sections_count <=
                                                      //       1) &&
                                                      '#E0E0E0',
                                              }}
                                            >
                                              {item.exam_score ? (
                                                moment(
                                                  item.answerExams?.created_at
                                                )
                                                  .add(7, 'days')
                                                  .format('YYYY-MM-DD') >=
                                                  today &&
                                                item.answerExams?.re_exam_num <
                                                  5 &&
                                                item.exam_score < 80 ? (
                                                  <button
                                                    style={{
                                                      background: '#A4E3AA',
                                                      border: 'none',
                                                      width: '66px',
                                                      height: '27px',
                                                      borderRadius: '4px',
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                      onhandleTest(
                                                        item.lecture_id,
                                                        item.exam_id,
                                                        item.answerExams
                                                          ?.re_exam_num ===
                                                          undefined ||
                                                          item.answerExams
                                                            ?.re_exam_num ===
                                                            null
                                                          ? 0
                                                          : item.answerExams
                                                              ?.re_exam_num,
                                                        item.answerExams
                                                          ?.answer_exam_id
                                                      )
                                                    }
                                                  >
                                                    시험 요청
                                                  </button>
                                                ) : item.exam_score < 80 ? (
                                                  '요청 불가'
                                                ) : (
                                                  '통과'
                                                )
                                              ) : moment(item.end_date)
                                                  .add(7, 'days')
                                                  .format('YYYY-MM-DD') >=
                                                  today && item.exam ? (
                                                <button
                                                  style={{
                                                    background: '#A4E3AA',
                                                    border: 'none',
                                                    width: '66px',
                                                    height: '27px',
                                                    borderRadius: '4px',
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() =>
                                                    onhandleTest(
                                                      item.lecture_id,
                                                      item.exam_id
                                                    )
                                                  }
                                                >
                                                  시험 요청
                                                </button>
                                              ) : (
                                                '요청 불가'
                                              )}
                                            </div>
                                          </Col>
                                          <Col x={0} md={3} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize: '16px',
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {item.answerExams?.re_exam_num
                                                ? item.answerExams?.re_exam_num
                                                : 0}{' '}
                                              / 5
                                            </Title>
                                          </Col>
                                        </Row>
                                      </Content>
                                    </List.Item>
                                  )}
                                </VirtualList>
                              )}
                            </List>
                          </Col>
                        </Row>
                      </Content>
                    ),
                  },

                  // 통과한 시험 -
                  // 강의명, 시험응시 가능기간, 시험점수, 통과여부
                  {
                    key: '3',
                    label: `통과한 시험`,
                    children: (
                      <Content maxWidth={'100%'} padding={0}>
                        <Row>
                          <Col x={24}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: screenSize >= 768 ? 60 : 40,
                                padding: '9px 6px',
                                background: '#D28494',
                                borderRadius: '4px 4px 0px 0px',
                              }}
                            >
                              <Col x={10} md={7} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  강의명
                                </Title>
                              </Col>
                              <Col x={0} md={7}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    lineHeight: '19px',
                                  }}
                                >
                                  시험응시 가능 기간
                                </Title>
                              </Col>
                              <Col x={6} md={5}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  시험 점수
                                </Title>
                              </Col>
                              <Col x={8} md={5}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  통과 여부
                                </Title>
                              </Col>
                            </div>
                          </Col>
                          <Col x={24}>
                            <List>
                              {NewLectureOfUserList?.filter(
                                (item) =>
                                  item.exam_score && item.exam_score >= 80
                              )?.length < 1 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    minHeight: '400px',
                                    alignItems: 'center',
                                    border: '1px solid #f0f0f0',
                                  }}
                                >
                                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                              ) : (
                                <VirtualList
                                  data={NewLectureOfUserList?.filter(
                                    (item) =>
                                      item.exam_score && item.exam_score >= 80
                                  )}
                                  height={screenSize >= 768 ? 440 : 350}
                                  itemHeight={60}
                                  itemKey="title"
                                >
                                  {(item, index) => (
                                    <List.Item
                                      key={item.lecture_title + index}
                                      style={{
                                        background:
                                          index % 2 === 0
                                            ? '#FFFFFF'
                                            : '#FAFAFA',
                                      }}
                                    >
                                      <Content
                                        maxWidth={'100%'}
                                        padding={0}
                                        backgroundColor={'none'}
                                      >
                                        <Row style={{ alignItems: 'center' }}>
                                          <Col x={10} md={7} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                                display: 'block',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                              }}
                                            >
                                              {item.lecture_title}
                                            </Title>
                                          </Col>
                                          <Col x={0} md={7} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize: '16px',
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {moment(item.start_date).format(
                                                'YY-MM-DD'
                                              )}
                                              ~
                                              {moment(item.end_date).format(
                                                'YY-MM-DD'
                                              )}
                                            </Title>
                                          </Col>
                                          <Col x={6} md={5} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {item.exam_score
                                                ? item.exam_score
                                                : 0}
                                              점
                                            </Title>
                                          </Col>
                                          <Col
                                            x={8}
                                            md={5}
                                            padding={8}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                fontWeight: 500,
                                                lineHeight: '19px',
                                                color: '#000000',
                                                width: '66px',
                                                height: '27px',
                                                marginLeft: '10px',
                                                borderRadius: '4px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: '#A4E3AA',
                                              }}
                                            >
                                              통과
                                            </div>
                                          </Col>
                                        </Row>
                                      </Content>
                                    </List.Item>
                                  )}
                                </VirtualList>
                              )}
                            </List>
                          </Col>
                        </Row>
                      </Content>
                    ),
                  },
                ]}
                tabBarStyle={{
                  marginBottom: screenSize >= 768 ? 32 : 10,
                  fontSize: screenSize >= 768 ? 16 : 14,
                  fontWeight: 400,
                  lineHeight: '19px',
                }}
              />
            </Col>
          </Content>
        </Col>
      </Row>
    </Content>
  );
}

MyTestPresenter.defualtProps = {};

export default MyTestPresenter;
