import React, { useEffect, useState } from 'react';
import './ArticleList.style.css';
import { useNavigate } from 'react-router-dom';
import { encrypt } from 'utils/Crypto';

const ArticleList = (props) => {
  /* ===== PROPS ===== */
  const { current, data } = props;

  /* ===== PROPS ===== */
  const navigation = useNavigate();

  /* ===== STATE ===== */
  const [boardPrevNext, setBoardPrevNext] = useState({
    prev: null,
    next: null,
  });

  /* ===== RENDER ===== */
  useEffect(() => {
    if (data) {
      const findIndex = data?.findIndex((item) => item?.board_id === current);
      const dataLength = data?.length;

      let prev = null,
        next = null;

      if (findIndex === -1) {
        prev = null;
        next = null;
      } else {
        // 다음 게시물
        if (findIndex === 0) {
          next = null;
        } else {
          next = data[findIndex - 1];
        }
        // 이전 게시물
        if (findIndex + 1 === dataLength) {
          prev = null;
        } else {
          prev = data[findIndex + 1];
        }
      }

      setBoardPrevNext({
        prev: prev,
        next: next,
      });
    }
  }, [current, data]);

  /* ===== FUNCTION ===== */
  return (
    <div className="board_article_container">
      <ul>
        <li>
          <h4>다음글</h4>
          <p
            style={{ cursor: boardPrevNext?.next ? 'pointer' : 'default' }}
            onClick={
              boardPrevNext?.next
                ? () =>
                    navigation(
                      `/popup/board/detail/${encodeURIComponent(
                        encrypt(boardPrevNext?.next?.board_id)
                      )}?lecture=${encodeURIComponent(
                        encrypt(boardPrevNext?.next?.lecture_id)
                      )}&type=${boardPrevNext?.next?.type?.toLocaleLowerCase()}`
                    )
                : () => {}
            }
          >
            {boardPrevNext?.next
              ? boardPrevNext?.next?.title
              : '다음 게시글이 없습니다.'}
          </p>
        </li>
        <li>
          <h4>이전글</h4>
          <p
            style={{ cursor: boardPrevNext?.prev ? 'pointer' : 'default' }}
            onClick={
              boardPrevNext?.prev
                ? () =>
                    navigation(
                      `/popup/board/detail/${encodeURIComponent(
                        encrypt(boardPrevNext?.prev?.board_id)
                      )}?lecture=${encodeURIComponent(
                        encrypt(boardPrevNext?.prev?.lecture_id)
                      )}&type=${boardPrevNext?.prev?.type?.toLocaleLowerCase()}`
                    )
                : () => {}
            }
          >
            {boardPrevNext?.prev
              ? boardPrevNext?.prev?.title
              : '이전 게시글이 없습니다.'}
          </p>
        </li>
      </ul>
    </div>
  );
};

export default ArticleList;
