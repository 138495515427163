/**
 * 회원가입 - 이용약관 페이지
 * --
 */
import React, { useState, useEffect, useRef } from 'react';
import {
  Content,
  Row,
  Col,
  Button,
  Title,
  LoadingPage,
  DaumAddress,
} from 'components';
import { Radio, Input, Button as Btn } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import MessageAlert from 'utils/MessageAlert';
import { AuthApi, EmailApi, NiceApi } from 'api';
import { DOMAIN_URL, deleteCookie, getCookie } from 'utils';
import validateCheck from 'utils/Validation';

function Join02({
  setUserValue,
  agencyData,
  setAgencyData,
  ValueCheck,
  OnlyNumber,
  users,
}) {
  /* ===== ROUTE ===== */
  const location = useLocation();
  let from = location.state?.from?.pathname;

  const navigate = useNavigate();
  /* ===== STATE ===== */
  const [encodeData, setEncodeData] = useState(null);
  // let encodeData = null;
  const [radioValue, setRadioValue] = useState(1);
  // const [data, setData] = useState({
  //   company_name: undefined,
  //   chief_name: undefined,
  //   company_number: undefined,
  // });
  const [isPopup, setIsPopup] = useState(false);
  const [agencyRadioValue, setAgencyRadioValue] = useState(1);
  const [authCode, setAuthCode] = useState('');
  const [inputAuth, setInputAuth] = useState('');
  const [timeText, setTimeText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // NOTE: 성공 : Y, 거부 : R, 미실행 : N
  const [duplicationCheck, setDuplicationCheck] = useState('N');

  /* ===== FUNCTION ===== */
  /**
   * 인증 이메일 발송
   * --
   */
  const handleSendAuthcode = async () => {
    try {
      setIsLoading(true);
      const { status, data } = await EmailApi.Sendmail({
        toEmail: agencyData?.agency_email,
        type: 'code',
      });

      if (status !== 200)
        return MessageAlert.error('인증 메일 발송에 실패하였습니다');

      MessageAlert.success('입력하신 메일로 인증코드가 발송되었습니다');
      const code = data?.code;
      setAuthCode(code);

      handleStartTimer();
    } catch (err) {
      console.log('이메일 인증 에러!', err);
      MessageAlert.error(
        '이메일 인증에 실패하였습니다.\n관리자에게 문의해주세요!'
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 사업자번호(고유번호) 중복검사
   * --
   */
  const handleDuplicationCheck = async () => {
    if (!agencyData?.company_number || agencyData?.company_number === '')
      return MessageAlert.warning('사업자 번호를 입력해주세요!');

    try {
      const { status } = await AuthApi.authCheck({
        type: 'agency',
        company_number: agencyData?.company_number,
      });

      if (status === 403) {
        setDuplicationCheck('R');
      } else if (status === 200) {
        setDuplicationCheck('Y');
      }
    } catch (err) {
      console.log('중복체크 에러!', err);
      throw err;
    }
  };

  /**
   * 인증코드 입력 시간 제한 설정
   * --
   */
  const timerStart = useRef(null);
  const handleStartTimer = () => {
    if (timerStart.current) {
      clearInterval(timerStart.current);
      timerStart.current = null;
    }

    let time = 300;
    timerStart.current = setInterval(() => {
      --time;

      if (time === 0) {
        setAuthCode('만료');
        clearInterval(timerStart.current);
        timerStart.current = null;
        setTimeText('시간만료');
        return;
      }

      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
      setTimeText(`${formattedMinutes}:${formattedSeconds}`);
    }, 1000);
  };

  /**
   * 인증코드 체크
   * --
   */
  const handleAuthCheck = () => {
    if (authCode === '만료')
      return MessageAlert.warning(
        '인증코드가 만료되었습니다. 다시 인증해주세요!'
      );

    if (parseInt(authCode) !== parseInt(inputAuth))
      return MessageAlert.warning('인증코드를 확인해주세요!');

    MessageAlert.success('인증되었습니다!');
    navigate('/join/3', { state: { from: location, radioValue: radioValue } });
  };

  /**
   * 기관 팝업창 검색
   * --
   */
  const handlePopup = () => {
    const domain = DOMAIN_URL;
    window.open(`${domain}/#/agency-search`, '팝업', 'width=500,height=600');

    window.parentCallback = () => {
      try {
        const value = JSON.parse(localStorage.getItem('selectedValue'));
        const { is_show, created_at, ...d } = value;

        setAgencyData({
          ...d,
        });
      } catch (err) {
        console.log('데이터 가져오기 실패!', err);
      } finally {
        localStorage.removeItem('selectedValue');
      }
    };
  };

  /**
   * 사업자등록번호 자동 Hyphen
   * --
   */
  const handleHyphen = (v) => {
    let str = OnlyNumber(v);
    let company_number = '';
    if (str.length < 4) {
      company_number = str;
    } else if (str.length < 6) {
      company_number += str.substr(0, 3);
      company_number += '-';
      company_number += str.substr(3);
    } else if (str.length) {
      company_number += str.substr(0, 3);
      company_number += '-';
      company_number += str.substr(3, 2);
      company_number += '-';
      company_number += str.substr(5);
    }
    company_number === agencyData.company_number &&
      MessageAlert.warning('숫자만 입력해 주세요!');
    handleChange('company_number', company_number);
  };

  /**
   * 핸드폰 번호 자동 Hyphen
   * --
   * @ 휴대폰 인증 기능 시 제외
   */
  const handlePhoneHyphen = (v) => {
    let str = OnlyNumber(v);
    let phoneText = '';

    // 02 연락처 예외처리
    if (`${str[0]}${str[1]}` === '02') {
      if (str.length < 3) {
        phoneText = str;
      } else if (str.length < 6) {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2);
      } else if (str.length < 10) {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2, 3);
        phoneText += '-';
        phoneText += str.substr(5);
      } else {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2, 4);
        phoneText += '-';
        phoneText += str.substr(6);
      }
    } else {
      if (str.length < 4) {
        phoneText = str;
      } else if (str.length < 7) {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3);
      } else if (str.length < 11) {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3, 3);
        phoneText += '-';
        phoneText += str.substr(6);
      } else {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3, 4);
        phoneText += '-';
        phoneText += str.substr(7);
      }
    }
    phoneText === agencyData.agency_phone && alert('숫자만 입력해 주세요!');
    handleChange('agency_phone', phoneText);
  };

  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    if (t === 'agency_capacity') {
      if (v !== '' && !validateCheck('number', v)) {
        return MessageAlert.warning('숫자만 입력해 주세요!');
      }
    }

    let newData = { ...agencyData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setAgencyData(d);

    if (callback) callback(v);
  };

  /**
   * 휴대폰 인증 및 회원가입 3단계 이동
   * --
   */
  const handleAuth = async () => {
    //기관일 경우 데이터 추가
    // console.log('agencyData', agencyData);
    if (radioValue === 3) {
      if (
        !ValueCheck(agencyData?.agency_name, '기관명') ||
        !ValueCheck(agencyData?.agency_type, '업종') ||
        !ValueCheck(agencyData?.agency_capacity, '정원') ||
        !ValueCheck(agencyData?.agency_address, '주소') ||
        !ValueCheck(agencyData?.agency_phone, '기관 연락처') ||
        !ValueCheck(agencyData?.chief_name, '대표자명') ||
        !ValueCheck(agencyData?.agency_email, '이메일') ||
        !ValueCheck(agencyData?.company_number, '사업자등록번호')
      ) {
        return;
      } else if (!validateCheck('email', agencyData?.agency_email)) {
        return MessageAlert.warning('이메일을 정확히 입력해주세요.');
      } else if (agencyData.company_number?.length < 12) {
        MessageAlert.warning('사업자등록번호를 정확히 입력해주세요.');
        return;
      } else if (duplicationCheck !== 'Y') {
        MessageAlert.warning('사업자등록번호 중복검사를 진행해주세요.');
        return;
      }

      handleSendAuthcode();
    } else {
      setIsPopup(true);
      const encodeDataToken = await NiceApi.getNiceToken();
      window.open(
        '',
        'popupChk',
        'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      );

      document.form_chk.action =
        'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
      document.form_chk.EncodeData.value = encodeDataToken;
      document.form_chk.target = 'popupChk';
      document.form_chk.submit();
    }
  };

  /**
   * 사용자(개인/강사/기관) 선택
   * --
   */
  const handleRadioClick = (value) => {
    setRadioValue(value);
    setUserValue(value);
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    if (from === undefined) {
      navigate('/');
      MessageAlert.warning('잘못된 접근입니다.');
    } else {
      setUserValue(radioValue);
    }
  });

  /**
   * 본인인증 후 처리
   * --
   */
  useEffect(() => {
    if (encodeData && encodeData !== '') {
      setIsPopup(false);

      const getDecodeData = async () => {
        const result = await NiceApi.verifyToken(
          `?EncodeData=${encodeURIComponent(encodeData)}`
        );

        // 본인인증 받은 휴대폰 값 정제
        const phoneNum01 = result.mobileno.slice(0, 3);
        const phoneNum02 = result.mobileno.slice(3, 7);
        const phoneNum03 = result.mobileno.slice(7, 11);

        const phone = `${phoneNum01}-${phoneNum02}-${phoneNum03}`;
        const id = users.filter((item) => item.phone === phone)[0]
          ?.user_account;

        if (id) {
          MessageAlert.warning('이미 가입된 아이디가 있습니다');
          deleteCookie('encodeData', {});
          return;
        } else {
          navigate(`/join/3`, {
            state: { from: location, radioValue: radioValue },
          });
        }
      };
      getDecodeData();
    }
  }, [encodeData, navigate, location, radioValue, users]);

  /**
   * 본인인증 창 닫힘 감지
   * --
   */
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'close') {
        const encodeNiceData = getCookie('encodeData');
        setEncodeData(encodeNiceData);
      }
    };

    if (isPopup) {
      window.addEventListener('message', handleMessage);
    } else {
      window.removeEventListener('message', handleMessage);
    }

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [isPopup]);

  useEffect(() => {
    deleteCookie('encodeData', {});
  }, []);

  useEffect(() => {
    setAgencyData({});
    setAuthCode('');
    setTimeText('');
    // eslint-disable-next-line
  }, [radioValue, agencyRadioValue]);

  useEffect(() => {
    setDuplicationCheck('N');
  }, [agencyData?.company_number]);

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'} padding={0} backgroundColor={'#FAFAFA'}>
      {isPopup ? <LoadingPage text={'본인인증 진행중입니다.'} /> : null}
      {isLoading ? <LoadingPage text={'잠시만 기다려주세요.'} /> : null}
      <Row>
        <Col
          x={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '48px',
          }}
        >
          <Radio.Group
            value={radioValue}
            size="large"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
            onChange={(e) => handleRadioClick(e.target.value)}
          >
            <Radio
              value={1}
              style={{ fontSize: '20px', fontWeight: 600, lineHeight: '24px' }}
            >
              개인회원
            </Radio>
            <Radio
              value={3}
              style={{ fontSize: '20px', fontWeight: 600, lineHeight: '24px' }}
            >
              기관회원
            </Radio>
          </Radio.Group>
        </Col>
        <Col x={24}>
          <Content
            maxWidth={'100%'}
            padding={0}
            style={{
              border: '1px solid #9E9E9E',
              display: 'flex',
              justifyContent: 'center',
              padding: '63px 10px',
            }}
          >
            <Content maxWidth={'588px'} padding={0}>
              <Row>
                <Col x={24}>
                  <Title
                    align="center"
                    style={{
                      fontSize: '20px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      letterSpacing: '0.02em',
                      marginBottom: '36px',
                    }}
                  >
                    {radioValue !== 3
                      ? '휴대폰 인증을 통해 회원가입을 하실 수 있습니다.'
                      : '회원정보에 등록할 정보를 입력해 주세요.'}
                  </Title>
                </Col>
                {radioValue === 3 ? (
                  <Col x={24}>
                    <Content maxWidth={'100%'} padding={0}>
                      <Row style={{ marginBottom: 50 }}>
                        <Col x={24}>
                          <Radio.Group
                            value={agencyRadioValue}
                            size="large"
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-around',
                            }}
                            onChange={(e) => {
                              setAgencyData({});
                              setAgencyRadioValue(e.target.value);
                            }}
                          >
                            <Radio
                              value={1}
                              style={{
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '24px',
                              }}
                            >
                              기관 검색
                            </Radio>
                            <Radio
                              value={3}
                              style={{
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '24px',
                              }}
                            >
                              신규 기관 등록
                            </Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                      {agencyRadioValue === 1 ? (
                        <Row>
                          <Button
                            layoutStyle={{ marginBottom: 30 }}
                            color="secondary"
                            onClick={() => handlePopup()}
                          >
                            기관 검색
                          </Button>
                        </Row>
                      ) : null}
                      <Row
                        style={{
                          marginBottom: '24px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col x={8}>
                          <Title
                            style={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22px',
                              letterSpacing: '0.02em',
                            }}
                          >
                            기관명
                          </Title>
                        </Col>
                        <Col x={16}>
                          <Input
                            placeholder={
                              agencyRadioValue === 1
                                ? '기관을 검색해주세요'
                                : '기관명을 입력해주세요'
                            }
                            style={{
                              height: '40px',
                              padding: '10px 20px',
                              fontSize: '16px',
                              fontWeight: 600,
                              cursor:
                                agencyRadioValue === 1
                                  ? 'not-allowed'
                                  : 'default',
                            }}
                            value={agencyData?.agency_name}
                            onChange={(e) =>
                              handleChange('agency_name', e.target.value)
                            }
                            readOnly={agencyRadioValue === 1}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginBottom: '24px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col x={8}>
                          <Title
                            style={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22px',
                              letterSpacing: '0.02em',
                            }}
                          >
                            업종
                          </Title>
                        </Col>
                        <Col x={16}>
                          <Input
                            placeholder={
                              agencyRadioValue === 1
                                ? '기관을 검색해주세요'
                                : '업종을 입력해주세요'
                            }
                            style={{
                              height: '40px',
                              padding: '10px 20px',
                              fontSize: '16px',
                              fontWeight: 600,
                              cursor:
                                agencyRadioValue === 1
                                  ? 'not-allowed'
                                  : 'default',
                            }}
                            value={agencyData?.agency_type}
                            onChange={(e) =>
                              handleChange('agency_type', e.target.value)
                            }
                            readOnly={agencyRadioValue === 1}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginBottom: '24px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col x={8}>
                          <Title
                            style={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22px',
                              letterSpacing: '0.02em',
                            }}
                          >
                            정원
                          </Title>
                        </Col>
                        <Col x={16}>
                          <Input
                            placeholder={
                              agencyRadioValue === 1
                                ? '기관을 검색해주세요'
                                : '정원을 숫자로 입력해주세요'
                            }
                            style={{
                              height: '40px',
                              padding: '10px 20px',
                              fontSize: '16px',
                              fontWeight: 600,
                              cursor:
                                agencyRadioValue === 1
                                  ? 'not-allowed'
                                  : 'default',
                            }}
                            value={agencyData?.agency_capacity}
                            onChange={(e) =>
                              handleChange('agency_capacity', e.target.value)
                            }
                            readOnly={agencyRadioValue === 1}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginBottom: '24px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col x={8}>
                          <Title
                            style={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22px',
                              letterSpacing: '0.02em',
                            }}
                          >
                            주소
                          </Title>
                        </Col>
                        <Col x={16}>
                          <DaumAddress
                            placeholder={
                              agencyRadioValue === 1
                                ? '기관을 검색해주세요'
                                : '주소를 입력해주세요'
                            }
                            style={{
                              height: '40px',
                              padding: '10px 20px',
                              fontSize: '16px',
                              fontWeight: 600,
                              cursor:
                                agencyRadioValue === 1
                                  ? 'not-allowed'
                                  : 'pointer',
                            }}
                            value={agencyData?.agency_address}
                            action={agencyRadioValue === 1 ? false : true}
                            // onChange={(e) =>
                            //   handleChange('agency_address', e.target.value)
                            // }
                            callback={(v) => handleChange('agency_address', v)}
                            readOnly
                          />
                        </Col>
                      </Row>
                      {agencyRadioValue !== 1 ? (
                        <Row
                          style={{
                            marginBottom: '24px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Col x={8}>
                            <Title
                              style={{
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '22px',
                                letterSpacing: '0.02em',
                              }}
                            >
                              상세주소
                            </Title>
                          </Col>
                          <Col x={16}>
                            <Input
                              placeholder={'상세 주소를 입력해주세요'}
                              style={{
                                height: '40px',
                                padding: '10px 20px',
                                fontSize: '16px',
                                fontWeight: 600,
                              }}
                              value={agencyData?.agency_address_detail}
                              onChange={(e) =>
                                handleChange(
                                  'agency_address_detail',
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                        </Row>
                      ) : null}
                      <Row
                        style={{
                          marginBottom: '24px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col x={8}>
                          <Title
                            style={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22px',
                              letterSpacing: '0.02em',
                            }}
                          >
                            기관 연락처
                          </Title>
                        </Col>
                        <Col x={16}>
                          <Input
                            placeholder={
                              agencyRadioValue === 1
                                ? '기관을 검색해주세요'
                                : '기관 연락처를 입력해주세요'
                            }
                            style={{
                              height: '40px',
                              padding: '10px 20px',
                              fontSize: '16px',
                              fontWeight: 600,
                              cursor:
                                agencyRadioValue === 1
                                  ? 'not-allowed'
                                  : 'default',
                            }}
                            value={agencyData?.agency_phone}
                            onChange={(e) => handlePhoneHyphen(e.target.value)}
                            readOnly={agencyRadioValue === 1}
                            maxLength={13}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginBottom: '24px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col x={8}>
                          <Title
                            style={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22px',
                              letterSpacing: '0.02em',
                            }}
                          >
                            대표자명
                          </Title>
                        </Col>
                        <Col x={16}>
                          <Input
                            placeholder="대표자명을 입력해주세요"
                            style={{
                              height: '40px',
                              padding: '10px 20px',
                              fontSize: '16px',
                              fontWeight: 600,
                            }}
                            value={agencyData?.chief_name}
                            onChange={(e) =>
                              handleChange('chief_name', e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginBottom: '24px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col x={8}>
                          <Title
                            style={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22px',
                              letterSpacing: '0.02em',
                            }}
                          >
                            이메일
                          </Title>
                        </Col>
                        <Col x={16}>
                          <Input
                            placeholder="이메일을 입력해주세요"
                            style={{
                              height: '40px',
                              padding: '10px 20px',
                              fontSize: '16px',
                              fontWeight: 600,
                            }}
                            value={agencyData?.agency_email}
                            onChange={(e) =>
                              handleChange('agency_email', e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginBottom: '48px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col x={8}>
                          <Title
                            style={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22px',
                              letterSpacing: '0.02em',
                            }}
                          >
                            사업자등록번호
                          </Title>
                        </Col>
                        <Col x={16} style={{ display: 'flex' }}>
                          <Input
                            placeholder="숫자만 입력해 주세요"
                            style={{
                              height: '40px',
                              padding: '10px 20px',
                              fontSize: '16px',
                              fontWeight: 600,
                            }}
                            maxLength={12}
                            value={agencyData?.company_number}
                            onChange={(e) => handleHyphen(e.target.value)}
                          />
                          <Btn
                            style={{ width: 80, height: 40, marginLeft: 30 }}
                            onClick={() => handleDuplicationCheck()}
                          >
                            중복 검사
                          </Btn>
                        </Col>
                        <Col x={8}></Col>
                        {duplicationCheck !== 'N' ? (
                          <Col x={16}>
                            <p
                              style={{
                                color:
                                  duplicationCheck === 'Y'
                                    ? '#2AC769'
                                    : '#FB4E4E',
                              }}
                            >
                              {duplicationCheck === 'Y'
                                ? '사용가능한 사업자번호입니다'
                                : '사용할 수 없는 사업자번호입니다'}
                            </p>
                          </Col>
                        ) : null}
                      </Row>
                    </Content>
                  </Col>
                ) : null}
                <Col x={24}>
                  {radioValue === 1 ? (
                    <Button
                      style={{
                        width: '100%',
                        padding: '16px 0',
                        fontSize: '18px',
                        fontWeight: 700,
                        lineHeight: '22px',
                      }}
                      onClick={() => handleAuth()}
                    >
                      휴대폰 인증
                    </Button>
                  ) : authCode ? (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: 50,
                        }}
                      >
                        <Input
                          placeholder="인증코드를 입력해주세요"
                          style={{
                            height: '40px',
                            padding: '10px 20px',
                            fontSize: '16px',
                            fontWeight: 600,
                          }}
                          value={inputAuth}
                          suffix={
                            <p
                              style={{
                                color:
                                  timeText !== '만료' ? '#BDBDBD' : '#FB4E4E',
                              }}
                            >
                              {timeText}
                            </p>
                          }
                          onChange={(e) => setInputAuth(e.target.value)}
                        />
                        <Btn
                          style={{ width: 80, height: 40, marginLeft: 30 }}
                          onClick={() => handleSendAuthcode()}
                        >
                          재전송
                        </Btn>
                      </div>
                      <Button
                        style={{
                          width: '100%',
                          padding: '16px 0',
                          fontSize: '18px',
                          fontWeight: 700,
                          lineHeight: '22px',
                        }}
                        onClick={() => handleAuthCheck()}
                      >
                        인증코드 확인
                      </Button>
                    </div>
                  ) : (
                    <Button
                      style={{
                        width: '100%',
                        padding: '16px 0',
                        fontSize: '18px',
                        fontWeight: 700,
                        lineHeight: '22px',
                      }}
                      onClick={() => handleAuth()}
                    >
                      인증코드 발송
                    </Button>
                  )}
                </Col>
              </Row>
            </Content>
          </Content>
        </Col>
      </Row>
      <form name="form_chk" method="post">
        <input type="hidden" name="m" value="checkplusService"></input>
        <input type="hidden" name="EncodeData" value=""></input>
      </form>
    </Content>
  );
}

Join02.defaultProps = {};

export default Join02;
