import React, { useState, useEffect, useRef } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  ModalLayout,
  Button,
  DaumAddress,
} from 'components';
import { Avatar, Collapse, Input, Radio, Table, Space } from 'antd';
import moment from 'moment';
import { SettingOutlined, SearchOutlined } from '@ant-design/icons';
import { LectureApi, UserApi, ChapterApi, SectionApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { encrypt } from 'utils/Crypto';
import validateCheck from 'utils/Validation';
import { STORE_URL } from 'utils';
import Highlighter from 'react-highlight-words';
import { useNavigate, useSearchParams } from 'react-router-dom';
const { Panel } = Collapse;

const TeachersPresenter = (props) => {
  /* ===== Props ===== */
  const { teachers, screenSize, address } = props;

  /* ===== Router ===== */
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  /* ===== Mutate ===== */
  const deleteLecture = LectureApi.DeleteLecture();
  const updateUser = UserApi.UpdateUser();
  const deleteUser = UserApi.DeleteUser();
  const deleteChapter = ChapterApi.DeleteChapter();
  const deleteSection = SectionApi.DeleteSection();

  /* ===== State ===== */
  const [lectuerModal, setLectureModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [userModal, setUserModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newData, setNewData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${title}을(를) 입력해주세요`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            검색
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            초기화
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '강사명',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', '강사명'),
      render: (_, { name, data }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={
              data?.profileImage &&
              `${STORE_URL}/users/${data?.user_id}/${data?.profileImage?.file_url}`
            }
          >
            {name[0]}
          </Avatar>
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            {name}
          </Title>
        </Content>
      ),
    },
    {
      title: '활동지역',
      dataIndex: 'activity_area_name',
      key: 'activity_area_name',
      filters: address?.map((item) => ({
        text: item?.local_name,
        value: item?.local_name,
      })),
      onFilter: (value, record) =>
        value
          ? record?.activity_area_name === value
          : record?.activity_area_name,
      filterSearch: true,
      render: (_, { activity_area_name }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {activity_area_name}
        </Title>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone', '연락처'),
      render: (_, { phone }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {phone}
        </Title>
      ),
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', '이메일'),
      render: (_, { email }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {email}
        </Title>
      ),
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      filters: [
        {
          text: '남',
          value: 'M',
        },
        {
          text: '여',
          value: 'F',
        },
      ],
      onFilter: (value, record) =>
        value ? record?.gender === value : record?.gender,
      render: (_, { gender }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {gender === 'M' ? '남' : '여'}
        </Title>
      ),
    },
    {
      title: '등록강의',
      dataIndex: 'lectures',
      key: 'lectures',
      sorter: (a, b) => a?.lectures - b?.lectures,
      render: (_, { data, lectures }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#008ED2',
            cursor: 'pointer',
          }}
        >
          <p style={{ margin: 0 }} onClick={() => handleModal('lecture', data)}>
            {lectures}
          </p>
        </Title>
      ),
    },
    {
      title: '프로필',
      dataIndex: 'profile',
      key: 'profie',
      render: (_, { profile }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {profile}
        </Title>
      ),
    },
    {
      title: '',
      dataIndex: 'butotn',
      key: 'button',
      render: (_, { data }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          <p
            onClick={() => handleUserUpdate('update', data)}
            style={{
              cursor: 'pointer',
              margin: 0,
            }}
          >
            <SettingOutlined />
          </p>
        </Title>
      ),
    },
  ];

  /* ===== Hooks ===== */
  /**
   * 회원 데이터 정제
   * --
   */
  useEffect(() => {
    const n = teachers?.map((item) => ({
      key: item?.user_id,
      name: item?.name,
      activity_area_name: item?.profile?.activity_area_name
        ? item?.profile?.activity_area_name
        : '-',
      phone: item?.phone,
      email: item?.email,
      gender: item?.gender,
      profile: item?.profile ? '등록완료' : '미등록',
      lectures: item?.lectures?.length,
      data: item,
    }));

    setNewData(n);
  }, [teachers]);
  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);
  /**
   * 회원 수만큼 opened 객체 생성
   * --
   */
  // useEffect(() => {
  //   const d = newTeachers?.map((item, index) => ({
  //     key: index,
  //     value: false,
  //   }));

  //   const obj = {};
  //   d?.forEach((item) => {
  //     obj[item.key] = item.value;
  //   });

  //   setIsUserOpened(obj);
  // }, [newTeachers]);

  /**
   * 강의 수만큼 opened 객체 생성
   * --
   */
  // useEffect(() => {
  //   const d = modalData?.lectures?.map((item, index) => ({
  //     key: index,
  //     value: false,
  //   }));

  //   const obj = {};
  //   d?.forEach((item) => {
  //     obj[item.key] = item.value;
  //   });

  //   setIsLectureOpened(obj);
  // }, [modalData]);

  /**
   * 모달 데이터 초기화
   * --
   */
  useEffect(() => {
    if (!lectuerModal && !userModal) {
      setModalData({});
    }
  }, [lectuerModal, userModal]);

  /**
   * 모달 데이터 렌더링
   * --
   */
  // useEffect(() => {
  //   if (lectuerModal) {
  //     const newModalData = newTeachers
  //       ?.filter((item) => item?.user_id === modalData?.user_id)
  //       ?.pop();
  //     setModalData(newModalData);
  //   }
  // }, [newTeachers, modalData, lectuerModal]);

  /* ===== Functions ===== */
  /**
   * pagination 처리 함수
   * --
   */
  const handlePagination = (page) => {
    navigation(`/admin/teachers?page=${page}`);
  };
  /**
   * 팝업창 이벤트
   * --
   * @param {*} type
   * @param {*} newOpen
   * @param {*} index
   */
  // const handleOpenChange = (type, newOpen, index) => {
  //   if (type === 'user') {
  //     const d = {
  //       ...isUserOpened,
  //     };
  //     d[index] = !newOpen;
  //     setIsUserOpened(d);
  //   } else if (type === 'lecture') {
  //     const d = {
  //       ...isLectureOpened,
  //     };
  //     d[index] = !newOpen;
  //     setIsLectureOpened(d);
  //   }
  // };

  /**
   * 모달 컨트롤 함수
   * --
   * @param {*} type
   * @param {*} data
   */
  const handleModal = (type, data) => {
    if (type === 'lecture') {
      setLectureModal(!lectuerModal);
    } else {
      setUserModal(!userModal);
    }
    setModalData(data);
  };

  /**
   * 강의 업데이트 제어 함수
   * --
   * @param {*} type
   * @param {*} data
   * @returns
   */
  const handleLectureUpdate = (type, data) => {
    if (type === 'update') {
      const confirm = window.confirm('해당 강의를 수정하시겠습니까?');

      if (confirm) {
        navigation(
          `/lectures/register/1?lecture=${encodeURIComponent(
            encrypt(data?.lecture_id)
          )}`
        );
      }
    } else {
      const confirm = window.confirm(
        '해당 강의를 정말 삭제하시겠습니까?\n삭제 시 관련 데이터가 손실될수 있습니다'
      );

      if (confirm) {
        if (window.confirm('정말 삭제하시겠습니까?')) {
          try {
            deleteLecture.mutate({ lecture_id: data?.lecture_id });
            MessageAlert.success('삭제되었습니다');
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('삭제에 실패하였습니다');
            throw e;
          }
        } else {
          MessageAlert.warning('취소되었습니다');
          return;
        }
      } else {
        MessageAlert.warning('취소되었습니다');
        return;
      }
    }
  };

  /**
   * 강의 삭제 제어 함수
   * --
   * @param {*} type
   * @param {*} id
   */
  const handleDelete = (type, id) => {
    const dataId = parseInt(id);
    if (type === 'chapter') {
      const confirm = window.confirm('정말 해당 챕터를 삭제하시겠습니까?');

      if (confirm) {
        try {
          deleteChapter.mutate({
            chapter_id: dataId,
          });
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('챕터 삭제에 실패하였습니다');
          throw e;
        }
      }
    } else if (type === 'section') {
      const confirm = window.confirm('정말 해당 섹션을 삭제하시겠습니까?');

      if (confirm) {
        try {
          deleteSection.mutate({
            section_id: dataId,
          });
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('섹션 삭제에 실패하였습니다');
          throw e;
        }
      }
    }
  };

  /**
   * 회원 상세 데이터 처리
   * --
   * @param {*} type
   * @param {*} data
   */
  const handleUserUpdate = (type, data) => {
    if (type === 'update') {
      handleModal('user');
      setModalData(data);
    } else {
      const confirm = window.confirm(
        '해당 회원을 정말 삭제하시겠습니까?\n삭제 시 관련된 모든 내용이 삭제됩니다'
      );

      if (confirm) {
        try {
          deleteUser.mutate({
            user_id: data?.user_id,
          });
          MessageAlert.success('삭제되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('회원 삭제에 실패하였습니다');
          throw e;
        }
      }
    }
  };

  /**
   * 회원 데이터 수정 함수
   * --
   */
  const handleUserDataUpdate = () => {
    const { email, recommender_id, point } = modalData;
    if (!validateCheck('email', email)) {
      MessageAlert.warning('이메일 형식을 확인해주세요');
      return;
    }
    if (recommender_id && recommender_id !== '') {
      if (!validateCheck('account', recommender_id)) {
        MessageAlert.warning('추천인ID는 영문 또는 숫자만 입력가능합니다');
        return;
      }
    }
    if (point) {
      if (!validateCheck('number', point))
        return MessageAlert.warning('포인트는 숫자로만 입력 가능합니다');
    }

    const confirm = window.confirm('회원 정보를 수정하시겠습니까?');

    if (confirm) {
      try {
        const { created_at, ...d } = modalData;
        updateUser.mutate({
          ...d,
        });
        MessageAlert.success('회원정보가 수정되었습니다');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('회원 정보 수정에 실패하였습니다');
        throw e;
      }
    }
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (t, v, callback = null) => {
    if (userModal) {
      let newData = { ...modalData };

      if (t === 'email') {
        const value = newData[t];
        // v 값에 한글이 포함되어 있는지 검사하는 정규식
        const koreanRegex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;

        if (value === '' || !value) {
          if (v === '' || !v) {
            v = undefined;
          } else if (v?.match(koreanRegex)) {
            MessageAlert.warning('영문 또는 숫자만 입력해주세요');
            v = undefined;
          }
        } else {
          if (v === '' || !v) {
            v = undefined;
          } else if (v?.match(koreanRegex)) {
            MessageAlert.warning('영문 또는 숫자만 입력해주세요');
            v = value;
          }
        }
      }

      newData[t] = v;
      const d = {
        ...newData,
      };
      setModalData(d);

      if (callback) callback(v);
    }
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={24}>
            <Title size={2} style={{ marginBottom: 20 }}>
              강사회원 관리
            </Title>
            <Content
              maxWidth={'100%'}
              padding={'24px 24px 0 24px'}
              className={'admin_container'}
            >
              <Table
                columns={columns}
                dataSource={newData}
                showSorterTooltip={false}
                pagination={{
                  position: ['bottomCenter'],
                  current: currentPage,
                  onChange: (e) => handlePagination(e),
                }}
              />
            </Content>
          </Col>
        </Row>
      </Content>

      {/* 강의 상세 모달 */}
      <ModalLayout
        title={'등록한 강의 목록'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={lectuerModal}
        onCancel={setLectureModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 700 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{
          padding: '40px 20px 20px',
          minHeight: screenSize > 768 && '500px',
          maxHeight: screenSize > 768 && '800px',
          overflowY: 'scroll',
        }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '60px',
              padding: '9px 6px',
              background: '#D28494',
              borderRadius: '4px 4px 0px 0px',
            }}
          >
            <Col x={2}>
              <Title
                align={'center'}
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                No
              </Title>
            </Col>
            <Col x={10}>
              <Title
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                강의명
              </Title>
            </Col>
            <Col x={4}>
              <Title
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                리뷰
              </Title>
            </Col>
            <Col x={3}>
              <Title
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                수강인원
              </Title>
            </Col>
            <Col x={4}>
              <Title
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                등록일
              </Title>
            </Col>
            <Col x={1}></Col>
          </Row>
          {modalData?.lectures && modalData?.lectures?.length > 0 ? (
            modalData?.lectures?.map((item, index) => (
              <Collapse key={`user-lecture-${index}`}>
                <Panel
                  showArrow={false}
                  header={
                    <Row
                      style={{
                        background: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA',
                        height: '70px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Col x={2}>
                        <Title
                          align={'center'}
                          style={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '19px',
                          }}
                        >
                          {index + 1}
                        </Title>
                      </Col>
                      <Col x={10}>
                        <Title
                          style={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '19px',
                          }}
                        >
                          {item?.title}
                        </Title>
                      </Col>
                      <Col x={4}>
                        <Title
                          style={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '19px',
                          }}
                        >
                          {item?.reviews && item?.reviews?.length > 0
                            ? item?.reviews?.length
                            : 0}
                          건 (
                          {item?.average && !isNaN(item?.average)
                            ? `${item?.average?.toFixed(1)} 점`
                            : '-'}
                          )
                        </Title>
                      </Col>
                      <Col x={3}>
                        <Title
                          style={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '19px',
                          }}
                        >
                          {item?.lectureOfUsers &&
                          item?.lectureOfUsers?.length > 0
                            ? item?.lectureOfUsers?.length
                            : '0'}
                        </Title>
                      </Col>
                      <Col x={4}>
                        <Title
                          style={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '19px',
                          }}
                        >
                          {moment(item?.lecture_created_date)?.format(
                            'YYYY.MM.DD'
                          )}
                        </Title>
                      </Col>
                      <Col x={1}>
                        <Title
                          style={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '19px',
                          }}
                        >
                          <p
                            onClick={() => handleLectureUpdate('update', item)}
                            style={{
                              cursor: 'pointer',
                              margin: 0,
                            }}
                          >
                            <SettingOutlined />
                          </p>
                        </Title>
                      </Col>
                    </Row>
                  }
                >
                  {item?.chapters?.map((subitem, subindex) => (
                    <Collapse
                      key={`chapter-${subitem?.chapter_id}`}
                      bordered={false}
                      style={{ padding: 10, background: '#FFFFFF' }}
                    >
                      <Panel
                        showArrow={false}
                        header={
                          <Content
                            maxWidth={'100%'}
                            padding={10}
                            style={{
                              border: '1px solid #EEEEEE',
                              background: '#FAFAFA',
                            }}
                          >
                            <Row>
                              <Col
                                x={24}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Title
                                  style={{
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    lineHeight: '15px',
                                  }}
                                >
                                  Chapter{subindex + 1}. {subitem?.chapter_name}
                                </Title>
                                <button
                                  style={{
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    handleDelete('chapter', subitem?.chapter_id)
                                  }
                                >
                                  x
                                </button>
                              </Col>
                            </Row>
                          </Content>
                        }
                      >
                        {subitem?.sections?.map((thriditem, thridindex) => (
                          <Collapse
                            key={`section-${thridindex}`}
                            bordered={false}
                            style={{
                              padding: 10,
                              border: '1px solid #EEEEEE',
                              background: '#FFFFFF',
                            }}
                          >
                            <Panel
                              showArrow={false}
                              header={
                                <Content
                                  maxWidth={'100%'}
                                  padding={10}
                                  style={{
                                    border: '1px solid #EEEEEE',
                                    background: '#FAFAFA',
                                  }}
                                >
                                  <Row>
                                    <Col
                                      x={24}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Title
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '13px',
                                          lineHeight: '15px',
                                          padding: '0 0 0 10px',
                                        }}
                                      >
                                        Section{subindex + 1}-{thridindex + 1}.{' '}
                                        {thriditem?.section_name}
                                      </Title>
                                      <button
                                        style={{
                                          border: 'none',
                                          background: 'none',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          handleDelete(
                                            'section',
                                            thriditem?.section_id
                                          )
                                        }
                                      >
                                        x
                                      </button>
                                    </Col>
                                  </Row>
                                </Content>
                              }
                            >
                              <Content
                                maxWidth={'100%'}
                                padding={10}
                                style={{ border: '1px solid #EEEEEE' }}
                              >
                                {thriditem?.video_url &&
                                thriditem?.video_url?.length > 0 ? (
                                  thriditem?.video_url?.map(
                                    (video, videoindex) => (
                                      <p
                                        key={`video-${thridindex}-${videoindex}`}
                                        style={{
                                          padding: '0 0 0 20px',
                                          fontSize: '14px',
                                        }}
                                      >
                                        {videoindex + 1}.{' '}
                                        <a
                                          href={`${video.url}`}
                                          target='_blank'
                                          rel='noreferrer'
                                        >
                                          {video.title}
                                        </a>
                                      </p>
                                    )
                                  )
                                ) : (
                                  <p style={{ padding: '0 0 0 20px' }}>
                                    등록된 영상이 없습니다
                                  </p>
                                )}
                              </Content>
                            </Panel>
                          </Collapse>
                        ))}
                      </Panel>
                    </Collapse>
                  ))}
                </Panel>
              </Collapse>
            ))
          ) : (
            <Row>
              <Col x={24}>
                <Content
                  maxWidth={'100%'}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                  }}
                >
                  <Title align={'center'}>강사가 등록한 강의가 없습니다</Title>
                </Content>
              </Col>
            </Row>
          )}
        </Content>
      </ModalLayout>

      {/* 회원 상세 모달 */}
      <ModalLayout
        title={'회원 정보 수정'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={userModal}
        onCancel={setUserModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: '40px 20px 20px' }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              {/* 아이디 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>아이디</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.user_account}
                    placeholder='아이디를 입력해주세요'
                    readOnly
                    disabled
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 이름 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>이름</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.name}
                    placeholder='이름을 입력해주세요'
                    onChange={(e) => handleChange('name', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 닉네임 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>닉네임</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.nickname}
                    placeholder='닉네임을 입력해주세요'
                    onChange={(e) => handleChange('nickname', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 전화번호 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>전화번호</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.phone}
                    placeholder='핸드폰번호를 입력해주세요'
                    readOnly
                    disabled
                    onChange={(e) => handleChange('phone', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 이메일 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>이메일</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.email ? modalData?.email : null}
                    placeholder='이메일을 입력해주세요'
                    onChange={(e) => handleChange('email', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 생년월일 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>생년월일</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={moment(modalData?.birthday).format('YYYY.MM.DD')}
                    placeholder='생년월일을 입력해주세요'
                    onChange={(e) => handleChange('birthday', e.target.value)}
                    readOnly
                    disabled
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 주소 */}
              <Row style={{ display: 'flex' }}>
                <Col x={6} style={{ marginTop: 20 }}>
                  <Title>주소</Title>
                </Col>
                <Col x={18}>
                  <DaumAddress
                    size='large'
                    value={modalData?.address}
                    placeholder='주소를 입력해주세요'
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                      cursor: 'pointer',
                    }}
                    callback={(e) => handleChange('address', e)}
                  />
                  <Input
                    size='large'
                    value={modalData?.address_detail}
                    placeholder='상세주소를 입력해주세요'
                    onChange={(e) =>
                      handleChange('address_detail', e.target.value)
                    }
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 직업 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>직업</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.job}
                    placeholder='직업을 입력해주세요'
                    onChange={(e) => handleChange('job', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 추천인 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>추천인 ID</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.recommender_id}
                    placeholder='추천인 ID를 입력해주세요'
                    onChange={(e) =>
                      handleChange('recommender_id', e.target.value)
                    }
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 추천인 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>포인트</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.point}
                    placeholder='숫자로 입력해주세요'
                    onChange={(e) => handleChange('point', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 강사여부 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '71px',
                }}
              >
                <Col x={6}>
                  <Title>강사자격</Title>
                </Col>
                <Col x={18}>
                  <Radio.Group
                    size='large'
                    value={modalData?.teacher_check}
                    onChange={(e) =>
                      handleChange('teacher_check', e.target.value)
                    }
                  >
                    <Radio value={'Y'}>Y</Radio>
                    <Radio value={'N'}>N</Radio>
                  </Radio.Group>
                </Col>
              </Row>
              {/* 노출여부 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '71px',
                }}
              >
                <Col x={6}>
                  <Title>노출여부</Title>
                </Col>
                <Col x={18}>
                  <Radio.Group
                    size='large'
                    value={modalData?.is_show}
                    onChange={(e) => handleChange('is_show', e.target.value)}
                  >
                    <Radio value={'Y'}>Y</Radio>
                    <Radio value={'N'}>N</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col x={24}>
              <Button
                style={{ width: '100%', height: '50px', marginTop: '30px' }}
                onClick={() => handleUserDataUpdate()}
              >
                수정
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

TeachersPresenter.defaultProps = {};

export default TeachersPresenter;
