import React, { useState, useEffect } from 'react';
import { Content, Row, Col, Title, ModalLayout, Button } from 'components';
import { Tree, Input, Select, Switch } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { AddressApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { Empty } from 'antd';

const AddressPresenter = (props) => {
  /* ===== Props ===== */
  const { local, city, district, screenSize } = props;

  /* ===== Router ===== */

  /* ===== Mutate ===== */
  const createLocal = AddressApi.CreateAddressLocal();
  const updateLocal = AddressApi.UpdateAddressLocal();
  const deleteLocal = AddressApi.DeleteAddressLocal(null, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        MessageAlert.success('시/도 삭제에 성공하였습니다');
      } else {
        MessageAlert.error(
          '시/도 삭제에 실패하였습니다\n구/군 삭제 후 진행해주세요'
        );
      }
    },
  });
  const createCity = AddressApi.CreateAddressCity();
  const updateCity = AddressApi.UpdateAddressCity();
  const deleteCity = AddressApi.DeleteAddressCity(null, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        MessageAlert.success('구/군 삭제에 성공하였습니다');
      } else {
        MessageAlert.error(
          '구/군 삭제에 실패하였습니다\n동/읍/면/리 삭제 후 진행해주세요'
        );
      }
    },
  });
  const createDistrict = AddressApi.CreateAddressDistrict({
    onSuccess: (data) => console.log('data', data),
  });
  const updateDistrict = AddressApi.UpdateAddressDistrict();
  const deleteDistrict = AddressApi.DeleteAddressDistrict(null, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        MessageAlert.success('동/읍/면/리 삭제에 성공하였습니다');
      } else {
        MessageAlert.error('동/읍/면/리 삭제에 실패하였습니다');
      }
    },
  });

  /* ===== State ===== */
  const [treeData, setTreeData] = useState([]);
  const [selectData, setSelectData] = useState({});
  const [isOpened, setIsOpened] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState({});

  /* ===== Hooks ===== */
  /**
   * Tree 데이터 정제
   * --
   */
  useEffect(() => {
    const newCity = city?.map((item) => ({
      ...item,
      district: district?.filter(
        (subitem) => item?.city_id === subitem?.city_id
      ),
    }));
    const newLocal = local?.map((item) => ({
      ...item,
      city: newCity?.filter((subitem) => item?.local_id === subitem?.local_id),
    }));
    const tree = newLocal?.map((item) => ({
      title: (
        <p
          style={{
            margin: 0,
            color: item?.is_show === 'Y' ? '#000000' : '#DBDBDB',
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          {item?.local_name}
        </p>
      ),
      key: `${item?.local_id}`,
      children: item?.city?.map((subitem) => ({
        title: (
          <p
            style={{
              margin: 0,
              color: subitem?.is_show === 'Y' ? '#000000' : '#DBDBDB',
            }}
          >
            {subitem?.city_name}
          </p>
        ),
        key: `${item?.local_id}-${subitem?.city_id}`,
        children: subitem?.district?.map((thirditem) => ({
          title: (
            <p
              style={{
                margin: 0,
                color: thirditem?.is_show === 'Y' ? '#000000' : '#DBDBDB',
              }}
            >
              {thirditem?.district_name}
            </p>
          ),
          key: `${item?.local_id}-${subitem?.city_id}-${thirditem?.district_id}`,
        })),
      })),
    }));

    setTreeData(tree);
  }, [local, city, district]);

  useEffect(() => {
    if (!isOpened) {
      setModalData({});
    }
  }, [isOpened]);

  /* ===== Functions ===== */
  /**
   * Tree 클릭 Item
   * --
   * @param {*} key
   */
  const handleTreeSelect = (key) => {
    const id = key[0]?.split('-');
    if (id?.length === 1) {
      // 선택 key값이 시/도 인경우
      const d = local?.filter((item) => item?.local_id === parseInt(id[0]))[0];

      setSelectData(d);
    } else if (id?.length === 2) {
      // 선택 key값이 구/군 인경우
      const d = city?.filter((item) => item?.city_id === parseInt(id[1]))[0];

      setSelectData(d);
    } else {
      // 선택 key값이 동/읍/면/리 인경우
      const d = district?.filter(
        (item) => item?.district_id === parseInt(id[2])
      )[0];

      setSelectData(d);
    }
  };

  /**
   * 지역추가 모달 핸들러
   * --
   * @param {*} type
   * @param {*} data
   * @param {*} open
   */
  const handleAddAddressModal = (type, data, open) => {
    setModalType(type);
    setIsOpened(!open);
  };

  /**
   * 지역 데이터 컨트롤 함수
   * --
   * @param {*} type
   * @returns
   */
  const handleAddress = (type) => {
    if (type === 'C') {
      const confirm = window.confirm('지역을 추가하시겠습니까?');
      if (confirm) {
        if (modalType === 'local') {
          if (!modalData?.local_name || !modalData?.local_name === '') {
            MessageAlert.warning('지역명을 입력해주세요');
            return;
          }

          const list = local;
          const dataCheck = list?.find(
            (item) => item?.local_name === modalData?.local_name
          );
          if (dataCheck) {
            MessageAlert.warning('같은 이름을 가진 지역이 존재합니다');
            return;
          }

          try {
            createLocal.mutate({
              ...modalData,
              order: local?.length + 1,
            });

            MessageAlert.success('지역이 생성되었습니다');
            setIsOpened(false);
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('지역 생성에 실패하였습니다');
            throw e;
          }
        } else if (modalType === 'city') {
          if (!modalData?.city_name || modalData?.city_name === '') {
            MessageAlert.warning('지역명을 입력해주세요');
            return;
          }

          const list = city?.filter(
            (item) => item?.local_id === selectData?.local_id
          );
          const dataCheck = list?.find(
            (item) => item?.city_name === modalData?.city_name
          );
          if (dataCheck) {
            MessageAlert.warning('같은 이름을 가진 지역이 존재합니다');
            return;
          }

          try {
            createCity.mutate({
              ...modalData,
              local_id: selectData?.local_id,
            });

            MessageAlert.success('지역이 생성되었습니다');
            setIsOpened(false);
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('지역 생성에 실패하였습니다');
            throw e;
          }
        } else {
          if (!modalData?.district_name || !modalData?.district_name === '') {
            MessageAlert.warning('지역명을 입력해주세요');
            return;
          }

          const list = district?.filter(
            (item) => item?.city_id === selectData?.city_id
          );
          const dataCheck = list?.find(
            (item) => item?.district_name === modalData?.district_name
          );
          if (dataCheck) {
            MessageAlert.warning('같은 이름을 가진 지역이 존재합니다');
            return;
          }

          try {
            createDistrict.mutate({
              ...modalData,
              local_id: selectData?.local_id,
              city_id: selectData?.city_id,
            });

            MessageAlert.success('지역이 생성되었습니다');
            setIsOpened(false);
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('지역 생성에 실패하였습니다');
            throw e;
          }
        }
      }
    } else if (type === 'U') {
      const confirm = window.confirm('지역을 수정하시겠습니까?');

      if (confirm) {
        const address = !selectData?.city_id
          ? 'local'
          : !selectData?.district_id
          ? 'city'
          : 'district';

        const { created_at, ...d } = selectData;

        if (address === 'local') {
          if (!selectData?.local_name || !selectData?.local_name === '') {
            MessageAlert.warning('지역명을 입력해주세요');
            return;
          }

          const list = local;
          const dataCheck = list?.find(
            (item) => item?.local_name === selectData?.local_name
          );
          if (dataCheck) {
            MessageAlert.warning('같은 이름을 가진 지역이 존재합니다');
            return;
          }

          try {
            updateLocal.mutate({
              ...d,
            });

            MessageAlert.success('지역이 정상적으로 수정되었습니다');
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('지역 수정에 실패하였습니다');
            throw e;
          }
        } else if (address === 'city') {
          if (!selectData?.city_name || !selectData?.city_name === '') {
            MessageAlert.warning('지역명을 입력해주세요');
            return;
          }

          const list = city?.filter(
            (item) => item?.local_id === selectData?.local_id
          );
          const dataCheck = list?.find(
            (item) => item?.city_name === selectData?.city_name
          );
          if (dataCheck) {
            MessageAlert.warning('같은 이름을 가진 지역이 존재합니다');
            return;
          }

          try {
            updateCity.mutate({
              ...d,
            });

            MessageAlert.success('지역이 정상적으로 수정되었습니다');
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('지역 수정에 실패하였습니다');
            throw e;
          }
        } else {
          if (!selectData?.district_name || !selectData?.district_name === '') {
            MessageAlert.warning('지역명을 입력해주세요');
            return;
          }

          const list = district?.filter(
            (item) => item?.city_id === selectData?.city_id
          );
          const dataCheck = list?.find(
            (item) => item?.district_name === selectData?.district_name
          );
          if (dataCheck) {
            MessageAlert.warning('같은 이름을 가진 지역이 존재합니다');
            return;
          }

          try {
            updateDistrict.mutate({
              ...d,
            });

            MessageAlert.success('지역이 정상적으로 수정되었습니다');
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('지역 수정에 실패하였습니다');
            throw e;
          }
        }
      }
    } else if (type === 'D') {
      const confirm = window.confirm(
        '지역 삭제하시겠습니까?\n삭제시 관련된 데이터가 손상될 수 있습니다'
      );

      if (confirm) {
        const address = !selectData?.city_id
          ? 'local'
          : !selectData?.district_id
          ? 'city'
          : 'district';

        if (address === 'local') {
          try {
            deleteLocal.mutate({
              local_id: selectData?.local_id,
            });
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('지역 삭제에 실패하였습니다');
            throw e;
          }
        } else if (address === 'city') {
          try {
            deleteCity.mutate({
              city_id: selectData?.city_id,
            });
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('지역 삭제에 실패하였습니다');
            throw e;
          }
        } else {
          try {
            deleteDistrict.mutate({
              district_id: selectData?.district_id,
            });
          } catch (e) {
            console.log('Error !!', e);
            MessageAlert.error('지역 삭제에 실패하였습니다');
            throw e;
          }
        }
        setSelectData({});
      }
    }
  };

  /**
   * 데이터 바인딩 함수
   * ==
   * @param {*} w
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (w, t, v, callback = null) => {
    if (w === 'modal') {
      let newData = { ...modalData };

      newData[t] = v;
      const d = {
        ...newData,
      };
      setModalData(d);

      if (callback) callback(v);
    } else {
      let newData = { ...selectData };

      newData[t] = v;
      const d = {
        ...newData,
      };
      setSelectData(d);

      if (callback) callback(v);
    }
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={24}>
            <Title size={2} style={{ marginBottom: 20 }}>
              지역 데이터 관리
            </Title>
            <Row>
              <Col x={8} padding={8}>
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{ marginBottom: '12px', textAlign: 'right' }}
                  backgroundColor={'none'}
                >
                  <Button
                    layoutStyle={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    style={{ padding: '10px 11px' }}
                    onClick={() => handleAddAddressModal('local', isOpened)}
                  >
                    {'추가'}
                  </Button>
                </Content>
                <Content
                  maxWidth={'100%'}
                  padding={24}
                  className={'admin_container'}
                  maxHeight={'80vh'}
                  style={{ overflowY: 'scroll' }}
                >
                  {treeData && treeData?.length > 0 ? (
                    <Tree
                      switcherIcon={<DownOutlined />}
                      onSelect={handleTreeSelect}
                      treeData={treeData}
                      expandAction="click"
                    />
                  ) : (
                    <Content
                      maxWidth={'100%'}
                      height={'100%'}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Content>
                  )}
                </Content>
              </Col>
              <Col x={16} padding={8}>
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{ marginBottom: '12px', textAlign: 'right' }}
                  backgroundColor={'none'}
                >
                  {selectData && !selectData?.district_id ? (
                    <Button
                      layoutStyle={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      style={{ padding: '10px 11px' }}
                      onClick={
                        !selectData?.city_id
                          ? () => handleAddAddressModal('city', isOpened)
                          : () => handleAddAddressModal('district', isOpened)
                      }
                    >
                      {'하위 추가'}
                    </Button>
                  ) : (
                    <Button
                      layoutStyle={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      style={{ padding: '10px 11px', visibility: 'hidden' }}
                    >
                      {'하위 추가'}
                    </Button>
                  )}
                </Content>
                {selectData && Object.keys(selectData)?.length !== 0 ? (
                  <Content
                    maxWidth={'100%'}
                    padding={24}
                    height={'80vh'}
                    className={'admin_container'}
                  >
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      backgroundColor={'#EEEEEE'}
                      style={{ borderRadius: '4px' }}
                    >
                      {/* 상위 메뉴 */}
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: '#EEEEEE',
                          padding: '10px',
                          borderRadius: '4px',
                        }}
                      >
                        <Col
                          x={6}
                          style={{
                            background: '#D9D9D9',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                          }}
                        >
                          <Title
                            style={{
                              fontSize: '17px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                              fontWeight: 700,
                            }}
                          >
                            상위메뉴
                          </Title>
                        </Col>
                        <Col x={1}></Col>
                        <Col
                          x={17}
                          style={{
                            background: '#FFFFFF',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                            padding: '0 20px',
                          }}
                        >
                          {selectData?.city_id ? (
                            <Select
                              size="large"
                              style={{ width: '100%' }}
                              value={
                                selectData?.district_id
                                  ? selectData?.city_id
                                  : selectData?.local_id
                              }
                              options={
                                selectData?.district_id
                                  ? city?.map((item) => ({
                                      label: item?.city_name,
                                      value: item?.city_id,
                                    }))
                                  : local?.map((item) => ({
                                      label: item?.local_name,
                                      value: item?.local_id,
                                    }))
                              }
                              onChange={(e) =>
                                handleChange('data', 'parents_id', e)
                              }
                            />
                          ) : (
                            <Title
                              style={{
                                fontSize: '17px',
                                lineHeight: '38px',
                                letterSpacing: '0.02em',
                                padding: '0 11px',
                              }}
                            >
                              -
                            </Title>
                          )}
                        </Col>
                      </Row>
                      {/* 지역 명 */}
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: '#EEEEEE',
                          padding: '10px',
                        }}
                      >
                        <Col
                          x={6}
                          style={{
                            background: '#D9D9D9',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                          }}
                        >
                          <Title
                            style={{
                              fontSize: '17px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                              fontWeight: 700,
                            }}
                          >
                            지역명
                          </Title>
                        </Col>
                        <Col x={1}></Col>
                        <Col
                          x={17}
                          style={{
                            background: '#FFFFFF',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                            padding: '0 20px',
                          }}
                        >
                          <Input
                            size="large"
                            placeholder="지역명을 입력해주세요"
                            value={
                              !selectData?.city_id
                                ? selectData?.local_name
                                : !selectData?.district_id
                                ? selectData?.city_name
                                : selectData?.district_name
                            }
                            onChange={
                              !selectData?.city_id
                                ? (e) =>
                                    handleChange(
                                      'data',
                                      'local_name',
                                      e.target.value
                                    )
                                : !selectData?.district_id
                                ? (e) =>
                                    handleChange(
                                      'data',
                                      'city_name',
                                      e.target.value
                                    )
                                : (e) =>
                                    handleChange(
                                      'data',
                                      'district_name',
                                      e.target.value
                                    )
                            }
                          />
                        </Col>
                      </Row>
                      {/* 숨김 */}
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: '#EEEEEE',
                          padding: '10px',
                        }}
                      >
                        <Col
                          x={6}
                          style={{
                            background: '#D9D9D9',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                          }}
                        >
                          <Title
                            style={{
                              fontSize: '17px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                              fontWeight: 700,
                            }}
                          >
                            노출여부
                          </Title>
                        </Col>
                        <Col x={1}></Col>
                        <Col
                          x={17}
                          style={{
                            background: '#FFFFFF',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                            padding: '0 20px',
                          }}
                        >
                          <Switch
                            size="large"
                            checked={selectData?.is_show === 'Y' ? true : false}
                            onChange={(checked) =>
                              handleChange(
                                'data',
                                'is_show',
                                checked ? 'Y' : 'N'
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Content>
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{ marginTop: '12px', textAlign: 'right' }}
                    >
                      {selectData &&
                        (selectData?.local_id ||
                          selectData?.city_id ||
                          selectData?.district_id) && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              layoutStyle={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                margin: '0 12px 0 0',
                              }}
                              style={{ padding: '10px 11px' }}
                              onClick={() => handleAddress('U')}
                            >
                              {'수정'}
                            </Button>
                            <Button
                              layoutStyle={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                margin: 0,
                              }}
                              style={{ padding: '10px 11px' }}
                              onClick={() => handleAddress('D')}
                            >
                              {'삭제'}
                            </Button>
                          </div>
                        )}
                    </Content>
                  </Content>
                ) : (
                  <Content
                    maxWidth={'100%'}
                    padding={24}
                    height={'80vh'}
                    className={'admin_container'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Title>지역을 선택해주세요</Title>
                  </Content>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      {/* 수강중인 강의 상세 모달 */}
      <ModalLayout
        title={'지역 추가'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: 0 }}
        footer={
          screenSize < 768 ? (
            <Content padding={0} maxWidth={'100%'} key={'modalBottomButton'}>
              <Button width={'100%'} onClick={() => handleAddress('C')}>
                추가
              </Button>
            </Content>
          ) : null
        }
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Row
                style={{ display: 'flex', alignItems: 'center', padding: 20 }}
              >
                <Col x={6}>
                  <Title>지역명</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={
                      modalType === 'local'
                        ? modalData?.local_name
                        : modalType === 'city'
                        ? modalData?.city_name
                        : modalData?.district_name
                    }
                    placeholder="지역명을 입력해주세요"
                    onChange={(e) =>
                      modalType === 'local'
                        ? handleChange('modal', 'local_name', e.target.value)
                        : modalType === 'city'
                        ? handleChange('modal', 'city_name', e.target.value)
                        : handleChange('modal', 'district_name', e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col x={24}>
              <Button
                style={{
                  width: '100%',
                  height: '50px',
                  borderRadius: 0,
                  marginTop: '30px',
                }}
                onClick={() => handleAddress('C')}
              >
                추가
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

AddressPresenter.defaultProps = {};

export default AddressPresenter;
