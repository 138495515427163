import React, { useEffect, useState, useMemo } from 'react';
import './BoardWriteLayout.style.css';
import {
  Content,
  Row,
  Col,
  Title,
  TextEditor,
  FileUploadButton,
  Button,
  ModalLayout,
} from 'components';
import { Select, Input, Button as Btn } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { MenuLayout } from '../BoardLayout/components';
import { useSearchParams, useLocation } from 'react-router-dom';
import MessageAlert from 'utils/MessageAlert';
import { BoardApi, FileApi, LectureApi } from 'api';
import { decrypt } from 'utils/Crypto';
import { FileUploadManager } from 'utils/FileUploadManager';

// 게시글 타입
const boardType = [
  {
    label: '문의 게시판',
    value: 'INQUIRY',
  },
  {
    label: '토론 게시판',
    value: 'DISCUSSION',
  },
  {
    label: '과제 게시판',
    value: 'ASSIGNMENT',
  },
];

const BoardWriteLayout = (props) => {
  /* ===== PROPS ===== */
  const { navigation, userInfoData, loginWho, queryClient } = props;

  /* ===== ROUTE ===== */
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('lecture');
  const type = searchParams.get('type');
  const { state } = useLocation();

  /* ===== MUTATE ===== */
  const createBoard = BoardApi.CreateBoard({
    onSuccess: async (data) => {
      if (inputData?.type === 'ASSIGNMENT' && uploadFile) {
        const res =
          loginWho !== 'admin'
            ? await FileUploadManager('B', uploadFile)
            : await FileUploadManager('B', uploadFile, userInfoData?.user_id);

        if (res.status === 200) {
          createBoardFile.mutate({
            board_id: data?.data?.board_id,
            file_name: res.file_name,
            file_url: res.name,
            size: uploadFile?.size,
          });
        }
      }
    },
  });
  const updateBoard = BoardApi.UpdateBoard({
    onSuccess: async (data) => {
      if (inputData?.type === 'ASSIGNMENT' && uploadFile) {
        const res =
          loginWho !== 'admin'
            ? await FileUploadManager('B', uploadFile)
            : await FileUploadManager('B', uploadFile, userInfoData?.user_id);

        if (res.status === 200) {
          createBoardFile.mutate({
            board_id: data?.data?.board_id,
            file_name: res.file_name,
            file_url: res.name,
            size: uploadFile?.size,
          });
        }
      }
    },
  });
  const deleteBoard = BoardApi.DeleteBoard();
  const createBoardFile = FileApi.CreateBoardFile({
    onSuccess: () => {
      const params = {
        lecture_id: decrypt(lectureId),
      };
      queryClient.invalidateQueries(['getBoards', params]);
    },
  });

  /* ===== DATA ===== */
  const { data: lecture, refetch: lectureRefetch } = LectureApi.GetLecture(
    decrypt(lectureId),
    null,
    {
      enabled: !!lectureId,
      select: (data) => data?.data,
    }
  );

  /* ===== STATE ===== */
  const [data, setData] = useState({});
  // 입력값
  const [inputData, setInputData] = useState({
    title: '',
    content: '',
    type: type?.toLocaleUpperCase(),
    task_similarity_status: 'N',
    task_status: 'DEFAULT',
  });
  const [htmlString, setHtmlString] = useState(null);
  // 게시글 작성 타입
  const [inputType, setInputType] = useState('CREATE');
  // 첨부파일 데이터
  const [fileData, setFileData] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  // 반응형 메뉴
  const [isOpened, setIsOpened] = useState(false);

  // 게시판 메뉴 설정
  const boardMenu = useMemo(() => {
    const menu = [
      {
        key: 'INQUIRY',
        title: '문의',
        path: `/popup/board?lecture=${encodeURIComponent(
          lectureId
        )}&type=inquiry`,
      },
      {
        key: 'DISCUSSION',
        title: '토론',
        path: `/popup/board?lecture=${encodeURIComponent(
          lectureId
        )}&type=discussion`,
      },
      {
        key: 'ASSIGNMENT',
        title: '과제',
        path: `/popup/board?lecture=${encodeURIComponent(
          lectureId
        )}&type=assignment`,
      },
    ];

    return menu;
  }, [lectureId]);

  /* ===== RENDER ===== */
  /**
   * 데이터 Refetch
   * --
   */
  useEffect(() => {
    if (lectureId) {
      lectureRefetch();
    }
  }, [lectureId, lectureRefetch]);

  /**
   * state 값 정제
   * --
   */
  useEffect(() => {
    if (state) {
      const { data: stateData } = state;

      if (stateData?.detail) {
        setInputType('UPDATE');
        const {
          board_id,
          user_id,
          title,
          content,
          type,
          task_similarity,
          task_status,
          board_file,
        } = stateData?.detail;

        setInputData({
          board_id,
          user_id,
          title,
          content,
          type,
          task_similarity,
          task_status,
        });
        setFileData(board_file?.pop());
      } else {
        setInputType('CREATE');
      }

      setData({ ...stateData });
    } else {
      MessageAlert.warning('잘못된 접근입니다!');
      navigation('/');
    }
  }, [state, navigation]);

  /**
   * 기관회원 + 비구매고객 접근제한
   * --
   */
  useEffect(() => {
    if (state) {
      // 기관회원 접근 제한
      if (loginWho && userInfoData && lecture) {
        if (loginWho === 'agency') {
          MessageAlert.warning('접근 권한이 없습니다!');
          navigation('/');
        }

        // 구매고객인지 체크
        const { student } = state?.data;
        const findStudent = student?.some(
          (item) => item?.user_id === userInfoData?.user_id
        );
        if (!findStudent && (loginWho === 'teacher' || loginWho === 'agency')) {
          if (lecture?.user_id === userInfoData?.user_id) {
            return;
          }
          MessageAlert.warning('접근 권한이 없습니다!');
          navigation('/');
        }
      }
    } else {
      MessageAlert.warning('잘못된 접근입니다!');
      navigation('/');
    }
  }, [loginWho, userInfoData, state, navigation, lecture]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== FUNCTION ===== */
  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...inputData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setInputData(d);

    if (callback) callback(v);
  };

  /**
   * 게시판 글 핸들러
   * --
   */
  const handleSubmit = (type) => {
    const submitType = type?.toLocaleUpperCase();

    const confirm = (txt) => window.confirm(`게시글을 ${txt} 하시겠습니까?`);

    // 유효성 검사
    const validation = () => {
      const { title, content, type } = inputData;
      if (!title || title === '') {
        MessageAlert.warning('제목을 입력해주세요!');
        return false;
      }

      if ((!htmlString || htmlString === '') && (!content || content === '')) {
        MessageAlert.warning('내용을 입력해주세요!');
        return false;
      }

      if (type === 'ASSIGNMENT') {
        if (!fileData) {
          MessageAlert.warning('첨부파일을 등록해주세요!');
          return false;
        }
      }

      return true;
    };

    const submit = (cal, data) => {
      cal.mutate(data);
    };

    switch (submitType) {
      case 'CREATE':
        if (validation()) {
          if (confirm(`등록`)) {
            try {
              const findFileSize = data?.files?.filter(
                (item) => parseInt(item?.size) === parseInt(fileData?.size)
              );

              const newData = {
                lecture_id: decrypt(lectureId),
                user_id: loginWho === 'admin' ? 0 : userInfoData?.user_id,
                ...inputData,
                content: htmlString,
                task_similarity_status: findFileSize?.length ? 'Y' : 'N',
              };
              submit(createBoard, newData);
              navigation(-1);
              MessageAlert.success('게시글이 등록되었습니다.');
            } catch (err) {
              MessageAlert.error(`게시글을 등록하는데 오류가 발생하였습니다!`);
              console.log(`[BOARD ${submitType} ERROR]`, err);
              return err;
            }
          }
        }
        break;
      case 'UPDATE':
        if (validation()) {
          if (confirm(`수정`)) {
            try {
              let newData = {
                ...inputData,
                content: !htmlString ? inputData?.content : htmlString,
              };
              if (uploadFile) {
                const findFileSize = data?.files?.filter(
                  (item) => parseInt(item?.size) === parseInt(uploadFile?.size)
                );

                newData = {
                  ...newData,
                  task_status: 'DEFAULT',
                  task_similarity_status: findFileSize?.length ? 'Y' : 'N',
                };
              }
              submit(updateBoard, newData);
              navigation(-1);
              MessageAlert.success('게시글이 수정되었습니다.');
            } catch (err) {
              MessageAlert.error(`게시글을 수정하는데 오류가 발생하였습니다!`);
              console.log(`[BOARD ${submitType} ERROR]`, err);
              return err;
            }
          }
        }
        break;
      case 'DELETE':
        if (confirm(`정말 삭제`)) {
          try {
            const { board_id } = inputData;
            submit(deleteBoard, {
              board_id,
            });
            navigation(-1);
            MessageAlert.success('게시글이 삭제되었습니다.');
          } catch (err) {
            MessageAlert.error(`게시글을 삭제하는데 오류가 발생하였습니다!`);
            console.log(`[BOARD ${submitType} ERROR]`, err);
            return err;
          }
        }
        break;
      default:
        break;
    }
  };
  // console.log('data ===>', data);

  /**
   * 첨부파일 선택
   * --
   */
  const handleSelectFile = (file) => {
    const d = {
      file_name: file.name,
      file_url: null,
      size: file.size,
    };
    setFileData(d);
    setUploadFile(file);
  };

  /**
   * Form 아이템
   * --
   */
  const form = [
    {
      title: '게시판',
      render: (
        <Select
          size="large"
          options={boardType}
          value={inputData?.type}
          onChange={(e) => handleChange('type', e)}
        />
      ),
    },
    {
      title: '제목',
      render: (
        <Input
          size="large"
          value={inputData?.title}
          placeholder="제목을 입력해주세요."
          onChange={(e) => handleChange('title', e.target.value)}
        />
      ),
    },
    {
      title: '작성자',
      render: <p>{userInfoData?.name}</p>,
    },
    {
      title: '내용',
      render: (
        <TextEditor
          data={inputData?.content}
          setHtmlString={setHtmlString}
          editorStyle={{ height: screenSize >= 768 ? 600 : 450 }}
        />
      ),
    },
    inputData?.type === 'ASSIGNMENT' && {
      title: '첨부파일',
      render: !fileData ? (
        <FileUploadButton
          layoutStyle={{ width: 250, height: 54 }}
          onChange={handleSelectFile}
        >
          첨부파일 +
        </FileUploadButton>
      ) : (
        <div className="board_file_input">
          <p>{fileData?.file_name}</p>
          <button
            onClick={() => {
              setFileData(null);
              setUploadFile(null);
            }}
          >
            x
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Content maxWidth={1500}>
        <Row style={{ marginBottom: 30 }}>
          <Col x={21} md={24}>
            <Title size={screenSize >= 768 ? 2 : 3}>
              {data?.lecture?.title} 강의 게시판
            </Title>
          </Col>
          <Col x={3} md={0}>
            <Btn
              onClick={() => setIsOpened(!isOpened)}
              style={{
                float: 'right',
                background: '#E3A4AF',
                border: '1px solid #E3A4AF',
                color: '#FFFFFF',
              }}
            >
              <MenuOutlined />
            </Btn>
          </Col>
        </Row>
        <Row>
          <Col x={0} md={4}>
            <MenuLayout menu={boardMenu} currentType={type} />
          </Col>
          <Col x={24} md={20}>
            <Title style={{ marginBottom: 20 }}>게시글 등록</Title>
            {/* 인풋 폼 */}
            <Content maxWidth={'100%'} padding={0}>
              <table>
                <tbody>
                  {form?.map(
                    (item) =>
                      item && (
                        <tr className="board_form" key={`form_${item?.title}`}>
                          <td>
                            <p>{item?.title}</p>
                          </td>
                          <td>{item?.render}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Content>
            {/* /인풋 폼 */}

            {/* 버튼 */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 30,
              }}
            >
              {inputType === 'CREATE' ? (
                <Button
                  style={{
                    width: '100%',
                    height: 54,
                    fontSize: 18,
                  }}
                  layoutStyle={{
                    width: screenSize >= 768 ? '25%' : '50%',
                  }}
                  onClick={() => handleSubmit('CREATE')}
                >
                  등록하기
                </Button>
              ) : (
                <>
                  <Button
                    style={{
                      width: '100%',
                      height: 54,
                      fontSize: 18,
                    }}
                    layoutStyle={{
                      width: screenSize >= 768 ? '25%' : '33%',
                      margin: '0px 10px',
                    }}
                    onClick={() => handleSubmit('UPDATE')}
                  >
                    수정하기
                  </Button>
                  <Button
                    type="secondary"
                    style={{
                      width: '100%',
                      height: 54,
                      fontSize: 18,
                      border: '1px solid #FB4E4E',
                      color: '#FB4E4E',
                    }}
                    layoutStyle={{
                      width: screenSize >= 768 ? '25%' : '33%',
                      margin: '0px 10px',
                    }}
                    onClick={() => handleSubmit('DELETE')}
                  >
                    삭제하기
                  </Button>
                </>
              )}
            </div>
            {/* /버튼 */}
          </Col>
        </Row>
      </Content>

      {/* 반응형 메뉴 모달 */}
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'right'}
        width={'60%'}
        height={'100%'}
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={10}>
          <Row>
            <Col x={24}>
              <MenuLayout menu={boardMenu} currentType={type} />
            </Col>
          </Row>
        </Content>
      </ModalLayout>
      {/* /반응형 메뉴 모달 */}
    </>
  );
};

export default BoardWriteLayout;
