import React, { useState, useEffect } from 'react';
import AgenciesPresenter from './AgenciesPresenter';

const AgenciesContainer = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newAgenyData, setNewAgencyData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { agencies } = data;

      const a = agencies?.map((item) => ({
        ...item,
      }));

      setNewAgencyData(a);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <AgenciesPresenter agencies={newAgenyData} screenSize={screenSize} />;
};

AgenciesContainer.defaultProps = {};

export default AgenciesContainer;
