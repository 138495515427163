import React, { useState, useEffect } from 'react';
import './ProgramLayout.style.css';
import {
  Content,
  Row,
  Col,
  Title,
  FileUploadButton,
  Button as Btn,
  LoadingPage,
  ModalLayout,
} from 'components';
import { useParams, useSearchParams } from 'react-router-dom';
import { decrypt } from 'utils/Crypto';
import { ScheduleApi, ProgramApi } from 'api';
import { getCookie, STORE_URL, parameterToPath, HOST_URL } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { Input, Button, Image, Select, Empty, List } from 'antd';
import VirtualList from 'rc-virtual-list';
import MessageAlert from 'utils/MessageAlert';
import { FileUploadManager } from 'utils/FileUploadManager';
import { TemplateApi } from 'api';
import APIConstant from '../../../api/APIConstant';
import APIManager from '../../../api/APIManager';
import { useQueryClient } from 'react-query';
import validateCheck from 'utils/Validation';
import useDebounce from 'utils/useDebounce';
import DOMPurify from 'dompurify';
import axios from 'axios';

DOMPurify.addHook('uponSanitizeElement', (node, data) => {
  if (data.tagName === 'iframe') {
    // 특정 조건(예: 특정 도메인의 URL만 허용)에 따라 iframe을 허용하거나 제거할 수 있습니다.
    const src = node.getAttribute('src') || '';
    if (src.startsWith('https://www.youtube.com/')) {
      // YouTube 도메인의 iframe만 허용합니다.
      return;
    }
    // 조건에 맞지 않는 iframe은 제거합니다.
    DOMPurify.sanitize(node, { ALLOWED_TAGS: ['iframe'] });
  }
});

const $http = new APIManager();

const ProgramLayout = (props) => {
  /* ===== PROPS ===== */
  const { programValue } = props;

  /* ===== ROUTE ===== */
  const queryClient = useQueryClient();
  const { scheduleId } = useParams();
  const [searchParams] = useSearchParams();
  /* ===== MUTATE ===== */
  const categories = queryClient.getQueryData('getCategories')?.data;
  const programs = queryClient.getQueryData('getPrograms')?.data;
  const updateSchedule = ScheduleApi.UpdateSchedule({
    onSuccess: async (data) => {
      MessageAlert.success('교안이 성공적으로 등록되었습니다.');
      window.opener.postMessage('refresh', '*');
    },
  });
  const createProgram = ProgramApi.CreateProgram({
    onSuccess: async (data) => {
      MessageAlert.success('교안이 성공적으로 등록되었습니다.');
      window.opener.postMessage('refresh', '*');
    },
  });
  const updateProgram = ProgramApi.UpdateProgram({
    onSuccess: async (data) => {
      MessageAlert.success('교안이 성공적으로 수정되었습니다.');
      window.opener.postMessage('refresh', '*');
    },
  });
  const template = TemplateApi.GetTemplatePdf({
    onSuccess: async (d) => {
      const { status, data } = d;

      if (status === 200) {
        const uint8Array = new Uint8Array(data.pdf.data);
        const blob = new Blob([uint8Array], {
          type: 'application/pdf',
        });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${data.title ? data?.title : 'brainheal'}.pdf`;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      }
    },
  });

  /* ===== STATE ===== */
  const { data: schedule, refetch: scheduleRefetch } = ScheduleApi.GetSchedule(
    decrypt(scheduleId),
    null,
    {
      enabled: scheduleId !== 'regist',
    }
  );
  const program = searchParams.get('program');
  const uId = getCookie('USER');
  const loginWho = useRecoilValue(loginUser);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [type, setType] = useState('read');
  const [scheduleData, setScheduleData] = useState({});
  const [programData, setProgramData] = useState(null);
  const [linkValue, setLinkValue] = useState('');
  const [fileList, setFileList] = useState([]);
  const [addFileList, setAddFileList] = useState([]);
  const [programId, setProgramId] = useState(0);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // 프로그램
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, 300);
  const [newPrograms, setNewPrograms] = useState([]);
  const [programModal, setProgramModal] = useState(false);
  const [programItems, setProgramItems] = useState([]);
  const [majorValue, setMajorValue] = useState(0);
  // 참고영상
  const [videoModal, setVideoModal] = useState(false);
  const [videoValue, setVideoValue] = useState({
    title: '',
    html: '',
  });
  //
  const [isRender, setIsRender] = useState(false);

  /* ===== FUNCTION ===== */
  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...programData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setProgramData(d);

    if (callback) callback(v);
  };

  /**
   * 참고링크 데이터 바인딩
   * --
   */
  const handleLinkList = (i = null) => {
    let linkList = programData?.link ? [...programData?.link] : [];
    if (i === null) {
      if (!linkValue || linkValue === '') {
        MessageAlert.warning('주소를 입력해주세요!');
        return;
      }

      if (!validateCheck('url', linkValue)) {
        MessageAlert.warning('URL 주소 형식으로 입력해주세요!');
        return;
      }

      linkList.push(linkValue);
      handleChange('link', linkList);
      setLinkValue('');
    } else {
      const newLinkList = linkList?.filter((item, index) => index !== i);
      handleChange('link', newLinkList);
    }
  };

  /**
   * 참고영상 데이터 바인딩
   */
  const handleVideoList = (i = null) => {
    let videoList = programData?.video?.length ? [...programData?.video] : [];

    if (i === null) {
      const { title, html } = videoValue;
      if (!title || title === '') {
        MessageAlert.warning('영상 타이틀을 입력해주세요!');
        return;
      }

      if (!html || html === '') {
        MessageAlert.warning('영상 퍼가기 코드를 입력해주세요!');
        return;
      }

      videoList.push({
        title: title,
        html: html,
      });

      handleChange('video', videoList);
      setVideoModal(!videoModal);
      setVideoValue({
        title: '',
        html: '',
      });
      MessageAlert.success('추가되었습니다.');
    } else {
      const newLinkList = videoList?.filter((item, index) => index !== i);
      handleChange('video', newLinkList);
    }
  };

  /**
   * 첨부 이미지 파일 추가
   * --
   */
  const handleAddFile = (file) => {
    const preview = URL.createObjectURL(file);
    // URL 형태
    setFileList((prev) => [...prev, preview]);
    // File 형태
    setAddFileList((prev) => [...prev, file]);
  };

  /**
   * 첨부파일 추가
   * --
   */
  const handleFiles = async (file) => {
    try {
      setIsLoading(true);
      const res = await FileUploadManager('T', file);

      const newFileUrl = `${STORE_URL}/program/${res.name}`;
      const fileName = res.file_name;

      const data = {
        ...programData,
        file: {
          file_name: fileName,
          url: newFileUrl,
        },
      };

      setProgramData(data);
    } catch (error) {
      console.error('파일 업로드 실패:', error);
    } finally {
      setTimeout(() => setIsLoading(false), 500);
    }
  };

  /**
   * 첨부파일 제거
   * --
   */
  const handleRemoveFile = (i) => {
    const f = fileList;
    const newFileList = f?.filter((item, index) => index !== i);
    setFileList(newFileList);
  };

  /**
   * 교안 수정 저장
   * --
   */
  const handleProgramUpdate = async () => {
    const uploadFile = async () => {
      const uploadedFiles = [];

      for (const item of addFileList) {
        try {
          const res = await FileUploadManager('T', item);
          const newFileUrl = `${STORE_URL}/program/${res.name}`;
          uploadedFiles.push(newFileUrl);
        } catch (error) {
          console.error('파일 업로드 실패:', error);
        }
      }

      return uploadedFiles;
    };

    if (!programData?.title || programData?.title === '')
      return MessageAlert.warning('프로그램 타이틀을 입력해주세요!');
    if (type === 'regist' && !categoryValue)
      return MessageAlert.warning('프로그램유형을 선택해주세요!');

    const confirm = window.confirm('교안을 저장하시겠습니까?');
    if (confirm) {
      try {
        let addFiles = [];
        if (fileList?.length) {
          addFiles = await uploadFile();
        }

        const f =
          fileList && fileList?.filter((item) => item?.includes(STORE_URL));

        if (scheduleId === 'regist') {
          if (programId) {
            updateProgram.mutate({
              program_id: programId,
              category_id: categoryValue,
              content: {
                ...programData,
                category: categories?.filter(
                  (item) =>
                    parseInt(item?.category_id) === parseInt(categoryValue)
                )[0]?.category_name,
                files: [...f, ...addFiles],
              },
            });
          } else {
            createProgram.mutate({
              category_id: categoryValue,
              content: {
                ...programData,
                category: categories?.filter(
                  (item) =>
                    parseInt(item?.category_id) === parseInt(categoryValue)
                )[0]?.category_name,
                files: [...f, ...addFiles],
              },
            });
          }
        } else {
          updateSchedule.mutate({
            ...scheduleData,
            content: {
              ...programData,
              files: [...f, ...addFiles],
            },
          });
        }

        setAddFileList([]);
        MessageAlert.success('저장되었습니다!');
      } catch (err) {
        MessageAlert.error('교안을 저장하는데 실패하였습니다!');
        console.log('교안등록 실패!', err);
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다');
      return;
    }
  };

  /**
   * PDF 다운로드
   * --
   */
  const handlePDFDownload = async () => {
    if (
      type === 'write' &&
      !window.confirm('저장되지 않은 내용은 사라집니다.\n다운로드하시겠습니까?')
    )
      return;
    try {
      setIsLoading(true);

      template.mutate({
        type: 'program',
        template_value: {
          ...programData,
        },
      });
    } catch (err) {
      console.log('PDF 다운로드 실패!');
      MessageAlert.error('PDF 파일을 다운로드 하는데 실패하였습니다');
      throw err;
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  /**
   * 첨부파일 다운로드
   * --
   */
  const handleFileDownload = async (file) => {
    const { file_name, url } = file;

    const confirm = window.confirm('첨부파일을 다운로드 하시겠습니까?');

    if (confirm) {
      try {
        setIsLoading(true);
        const splitUrl = url.split(`${STORE_URL}/`);
        const res = await axios.post(`${HOST_URL}/file/blob`, {
          key: `brainheal/${splitUrl[1]}`,
        });

        const blob = res.data.data.data;
        const uint8Array = new Uint8Array(blob);
        const fileData = new File([uint8Array], file_name);
        const downloadURL = window.URL.createObjectURL(fileData);
        const a = document.createElement('a');
        a.href = downloadURL;
        a.download = file_name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadURL);
      } catch (err) {
        console.log('첨부파일 다운로드 에러!', err);
      } finally {
        setTimeout(() => setIsLoading(false), 500);
      }
    }
  };

  /**
   * 교안 선택 관리
   * --
   */
  const handleSelectProgram = (d) => {
    setProgramData(d?.content);
    setCategoryValue(d?.category_id);
    setProgramModal(false);
  };

  /* ===== HOOKS ===== */
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /**
   * 공통교안 데이터 조회
   * --
   */
  const handleGetProgramData = async (id) => {
    try {
      const url = parameterToPath(APIConstant.GET_PROGRAM, {
        program_id: id,
      });
      const { status, data } = await $http.get(`${url}`);
      if (status === 200) {
        const { files, ...d } = data?.content;
        setProgramData(d);

        setCategoryValue(data?.category_id);
        setFileList(files && files?.length ? files : []);
      } else {
        MessageAlert.warning('데이터를 호출하는데 실패하였습니다!');
      }
    } catch (err) {
      console.error('프로그램 데이터 호출 실패!', err);
      MessageAlert.error('데이터를 호출하는데 실패하였습니다!');
    }
  };

  /**
   * 교안 권한 체크
   * --
   */
  useEffect(() => {
    /**
     * NOTE
     * regist : 교안 등록(생성) 권한
     * write : 교안 작성 권한
     */
    if (loginWho) {
      if (scheduleId === 'regist') {
        const program = searchParams.get('program');
        if (program) {
          if (loginWho === 'admin') setType('regist');
          else setType('write');
        } else {
          setType('regist');
        }
      } else {
        if (loginWho === 'admin') {
          setType('write');
        }
        scheduleRefetch();
      }
    }
  }, [scheduleId, scheduleRefetch, searchParams, loginWho]);

  /**
   * 교안 데이터 추출
   * --
   */
  useEffect(() => {
    // const program = searchParams.get('program');
    if (!isRender) {
      if (programValue) {
        const { files, ...d } = programValue;
        setProgramData({ ...d });
        setFileList(files);
        setIsRender(true);
      } else if (scheduleId === 'regist' && program && loginWho) {
        const id = decrypt(program);
        handleGetProgramData(id);
        setProgramId(id);
        setIsRender(true);
      } else if (
        scheduleId !== 'regist' &&
        schedule?.status === 200 &&
        loginWho
      ) {
        const { data } = schedule;

        if (loginWho === 'teacher' && parseInt(data?.user_id) === parseInt(uId))
          setType('write');

        if (
          loginWho === 'agency' &&
          parseInt(data?.agency_id) === parseInt(uId)
        )
          setType('write');

        const { created_at, ...d } = data;
        setScheduleData({ ...d });
        setProgramData(data?.content);
        setFileList(data?.content?.files ? data?.content?.files : []);
        setIsRender(true);
      }

      // setIsRender(true);
    }
    // eslint-disable-next-line
  }, [
    schedule,
    uId,
    loginWho,
    programValue,
    isRender,
    scheduleId,
    searchParams,
    // program,
  ]);

  /**
   * 카테고리 아이템 정제
   * --
   */
  useEffect(() => {
    if (type === 'regist' || type === 'write') {
      let d = [];

      categories?.forEach((item) => {
        if (item?.type === 'D') {
          d.push(item);
        } else {
          return;
        }
      });

      const options = d?.map((item) => ({
        label: item?.category_name,
        value: item?.category_id,
      }));
      setCategoryOptions([
        {
          label: '전체',
          value: 0,
        },
        ...options,
      ]);
    }
  }, [type, categories]);

  /**
   * 프로그램 아이템 정제
   * --
   */
  useEffect(() => {
    const newData = programs?.map((item) => ({
      ...item,
      category_name: categories?.filter(
        (subitem) => item?.category_id === subitem?.category_id
      )[0]?.category_name,
    }));
    setNewPrograms(newData);
  }, [programs, categories]);

  /**
   * 필터 값에 따른 교안 아이템 정제
   * --
   */
  useEffect(() => {
    const newItem = newPrograms
      ?.filter((item) =>
        majorValue === 0 ? item : Number(item?.category_id) === majorValue
      )
      ?.filter((item) =>
        debouncedValue === ''
          ? item
          : item?.content?.title?.includes(debouncedValue)
      );

    setProgramItems(newItem);
  }, [majorValue, debouncedValue, newPrograms]);

  /* ===== RENDER ===== */
  return (
    <>
      {isLoading ? <LoadingPage text={'다운로드 준비중...'} /> : null}
      {!isRender && program ? (
        !programData ? (
          <LoadingPage text={'로딩중...'} />
        ) : null
      ) : null}

      <Content maxWidth={'100%'} padding={screenSize >= 480 ? 0 : '10px'}>
        <Content maxWidth={1200}>
          {/* 교안 검색 버튼 */}
          {loginWho !== 'agency' && (
            <Row>
              <Col x={24}>
                <Content maxWidth={'100%'} padding={10}>
                  {type === 'write' || type === 'regist' ? (
                    <div>
                      <Button
                        type="primary"
                        onClick={() => setProgramModal(!programModal)}
                      >
                        교안검색
                      </Button>
                    </div>
                  ) : null}
                </Content>
              </Col>
            </Row>
          )}
          <Row>
            <Col x={24} padding={10}>
              <Content
                maxWidth={'100%'}
                style={{ border: '1px solid #9E9E9E', borderRadius: '8px' }}
              >
                {type === 'write' || type === 'regist' ? (
                  <Input
                    size="large"
                    value={programData?.title}
                    onChange={(e) => handleChange('title', e.target.value)}
                    placeholder="타이틀을 입력해주세요"
                  />
                ) : (
                  <Title>{programData?.title}</Title>
                )}
                <Content
                  maxWidth={'100%'}
                  style={{ marginTop: 20 }}
                  padding={0}
                >
                  <table className="program_layout">
                    <tbody>
                      {/* 진행자 / 프로그램유형 / 프로그램구성 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            진행자
                          </Title>
                        </th>
                        <td>
                          {type === 'write' || type === 'regist' ? (
                            <Input
                              size="large"
                              value={programData?.name}
                              onChange={(e) =>
                                handleChange('name', e.target.value)
                              }
                              placeholder="진행자 이름을 입력해주세요"
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.name}
                            </Title>
                          )}
                        </td>
                        <th>
                          <Title size={5} align={'center'}>
                            프로그램
                            <br />
                            유형
                          </Title>
                        </th>
                        <td>
                          {type === 'write' ? (
                            <Input
                              size="large"
                              value={programData?.category}
                              onChange={(e) =>
                                handleChange('category', e.target.value)
                              }
                              placeholder="프로그램 유형을 입력해주세요"
                            />
                          ) : type === 'regist' ? (
                            <Select
                              size="large"
                              options={categoryOptions}
                              style={{ width: '100%' }}
                              value={categoryValue}
                              onChange={(e) => setCategoryValue(e)}
                              // placeholder="프로그램 유형을 선택해주세요"
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.category}
                            </Title>
                          )}
                        </td>
                        <th>
                          <Title size={5} align={'center'}>
                            프로그램
                            <br />
                            구성
                          </Title>
                        </th>
                        <td>
                          {type === 'write' || type === 'regist' ? (
                            <Input
                              size="large"
                              value={programData?.environment}
                              onChange={(e) =>
                                handleChange('environment', e.target.value)
                              }
                              placeholder="프로그램 구성을 입력해주세요"
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.environment}
                            </Title>
                          )}
                        </td>
                      </tr>
                      {/* 장소 / 참여자 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            장소
                          </Title>
                        </th>
                        <td colSpan={2}>
                          {type === 'write' || type === 'regist' ? (
                            <Input
                              size="large"
                              value={programData?.location}
                              onChange={(e) =>
                                handleChange('location', e.target.value)
                              }
                              placeholder="장소를 입력해주세요"
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.location}
                            </Title>
                          )}
                        </td>
                        <th>
                          <Title size={5} align={'center'}>
                            참여자
                          </Title>
                        </th>
                        <td colSpan={2}>
                          {type === 'write' || type === 'regist' ? (
                            <Input
                              size="large"
                              value={programData?.participants}
                              onChange={(e) =>
                                handleChange('participants', e.target.value)
                              }
                              placeholder="참여자를 입력해주세요"
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.participants}
                            </Title>
                          )}
                        </td>
                      </tr>
                      {/* 운영일자 / 시간 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            운영일자
                          </Title>
                        </th>
                        <td colSpan={2}>
                          {type === 'write' || type === 'regist' ? (
                            <Input
                              size="large"
                              value={programData?.date}
                              onChange={(e) =>
                                handleChange('date', e.target.value)
                              }
                              placeholder="운영일자를 입력해주세요"
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.date}
                            </Title>
                          )}
                        </td>
                        <th>
                          <Title size={5} align={'center'}>
                            시간
                          </Title>
                        </th>
                        <td colSpan={2}>
                          {type === 'write' || type === 'regist' ? (
                            <Input
                              size="large"
                              value={programData?.time}
                              onChange={(e) =>
                                handleChange('time', e.target.value)
                              }
                              placeholder="시간을 입력해주세요"
                            />
                          ) : (
                            <Title size={5} align={'center'}>
                              {programData?.time}
                            </Title>
                          )}
                        </td>
                      </tr>
                      {/* 준비물 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            준비물
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' || type === 'regist' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.materials}
                              onChange={(e) =>
                                handleChange('materials', e.target.value)
                              }
                              placeholder="준비물을 입력해주세요"
                            />
                          ) : (
                            <pre>{programData?.materials}</pre>
                          )}
                        </td>
                      </tr>
                      {/* 활동목표 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            활동목표
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' || type === 'regist' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.goal}
                              onChange={(e) =>
                                handleChange('goal', e.target.value)
                              }
                              style={{ height: 200 }}
                              placeholder="활동목표를 입력해주세요"
                            />
                          ) : (
                            <pre>{programData?.goal}</pre>
                          )}
                        </td>
                      </tr>
                      {/* 활동내용 */}
                      <tr>
                        <th colSpan={6}>
                          <Title size={5} align={'center'}>
                            활동내용
                          </Title>
                        </th>
                      </tr>
                      {/* 도입 */}
                      <tr>
                        <th x={4}>
                          <Title size={5} align={'center'}>
                            도입
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' || type === 'regist' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.introduction}
                              onChange={(e) =>
                                handleChange('introduction', e.target.value)
                              }
                              style={{ height: 200 }}
                              placeholder="도입 내용을 입력해주세요"
                            />
                          ) : (
                            <pre>{programData?.introduction}</pre>
                          )}
                        </td>
                      </tr>
                      {/* 전개 */}
                      <tr>
                        <th x={4}>
                          <Title size={5} align={'center'}>
                            전개
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' || type === 'regist' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.deployment}
                              onChange={(e) =>
                                handleChange('deployment', e.target.value)
                              }
                              style={{ height: 300 }}
                              placeholder="전개 내용을 입력해주세요"
                            />
                          ) : (
                            <pre>{programData?.deployment}</pre>
                          )}
                        </td>
                      </tr>
                      {/* 마무리 */}
                      <tr>
                        <th>
                          <Title size={5} align={'center'}>
                            마무리
                          </Title>
                        </th>
                        <td colSpan={5}>
                          {type === 'write' || type === 'regist' ? (
                            <Input.TextArea
                              size="large"
                              value={programData?.finish}
                              onChange={(e) =>
                                handleChange('finish', e.target.value)
                              }
                              style={{ height: 100 }}
                              placeholder="마무리 내용을 입력해주세요"
                            />
                          ) : (
                            <pre>{programData?.finish}</pre>
                          )}
                        </td>
                      </tr>
                      {/* 참고영상 */}
                      {programData?.video?.length ? (
                        <tr>
                          <th>
                            <Title size={5} align={'center'}>
                              참고영상
                            </Title>
                          </th>
                          <td colSpan={5}>
                            {programData?.video?.map((item, index) => (
                              <div
                                key={`video-${index}`}
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(item?.html, {
                                    ADD_TAGS: ['iframe'],
                                  }),
                                }}
                              ></div>
                            ))}
                          </td>
                        </tr>
                      ) : null}
                      {/* 참고링크 */}
                      {programData?.link?.length ? (
                        <tr>
                          <th>
                            <Title size={5} align={'center'}>
                              참고링크
                            </Title>
                          </th>
                          <td colSpan={5}>
                            {programData?.link?.map((item, index) => (
                              <p key={`${item}-${index}`}>
                                -{' '}
                                <a href={item} target="_blank" rel="noreferrer">
                                  {item}
                                </a>
                              </p>
                            ))}
                          </td>
                        </tr>
                      ) : null}
                      {/* 첨부 이미지 */}
                      {fileList?.length ? (
                        <tr>
                          <th>
                            <Title size={5} align={'center'}>
                              첨부 파일
                            </Title>
                          </th>
                          <td colSpan={5}>
                            {fileList?.map((item, index) => (
                              <p key={`${item}-${index}`}>
                                <img src={`${item}`} alt="첨부 이미지" />
                              </p>
                            ))}
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </Content>

                <Content
                  maxWidth={'100%'}
                  style={{ marginTop: 50 }}
                  padding={0}
                >
                  {/* 참고영상 */}
                  {type === 'write' || type === 'regist' ? (
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <Col x={4}>
                        <Title size={5} align={'center'}>
                          참고영상
                        </Title>
                      </Col>
                      <Col x={20}>
                        <Content maxWidth={'100%'} padding={0}>
                          <div style={{ display: 'flex' }}>
                            <Button
                              size="large"
                              onClick={() => setVideoModal(!videoModal)}
                              style={{ width: 154 }}
                            >
                              추가 +
                            </Button>
                          </div>
                        </Content>
                      </Col>
                      <Col x={4}></Col>
                      <Col x={20}>
                        <ul>
                          {programData?.video?.length
                            ? programData?.video?.map((item, index) => (
                                <li
                                  key={`video-${index}`}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: 10,
                                  }}
                                >
                                  <p>{item?.title}</p>
                                  {(type === 'write' || type === 'regist') && (
                                    <button
                                      style={{
                                        width: 30,
                                        height: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #E0E0E0',
                                        borderRadius: 5,
                                        background: 'none',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => handleVideoList(index)}
                                    >
                                      x
                                    </button>
                                  )}
                                </li>
                              ))
                            : null}
                        </ul>
                      </Col>
                    </Row>
                  ) : null}
                  {/* 참고링크 */}
                  {type === 'write' || type === 'regist' ? (
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <Col x={4}>
                        <Title size={5} align={'center'}>
                          참고링크
                        </Title>
                      </Col>
                      <Col x={20}>
                        <Content maxWidth={'100%'} padding={0}>
                          <div style={{ display: 'flex' }}>
                            <Input
                              size="large"
                              value={linkValue}
                              onChange={(e) => setLinkValue(e.target.value)}
                              onPressEnter={() => handleLinkList()}
                            />
                            <Button
                              size="large"
                              onClick={() => handleLinkList()}
                            >
                              추가
                            </Button>
                          </div>
                        </Content>
                      </Col>
                      <Col x={4}></Col>
                      <Col x={20}>
                        <ul>
                          {programData?.link?.map((item, index) => (
                            <li
                              key={`link-${index}`}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: 10,
                              }}
                            >
                              <a href={item} target="_blank" rel="noreferrer">
                                {item}
                              </a>
                              {(type === 'write' || type === 'regist') && (
                                <button
                                  style={{
                                    width: 30,
                                    height: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: '1px solid #E0E0E0',
                                    borderRadius: 5,
                                    background: 'none',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleLinkList(index)}
                                >
                                  x
                                </button>
                              )}
                            </li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  ) : null}
                  {/* 참고 이미지 */}
                  {type === 'write' || type === 'regist' ? (
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 10,
                      }}
                    >
                      <Col x={4}>
                        <Title size={5} align={'center'}>
                          이미지
                        </Title>
                      </Col>
                      <Col x={20}>
                        <Content maxWidth={'100%'} padding={0}>
                          <FileUploadButton
                            onChange={handleAddFile}
                            layoutStyle={{
                              maxWidth: 156,
                              width: '100%',
                            }}
                            style={{
                              textAlign: 'center',
                              fontSize: '16px',
                            }}
                            accept="image/*"
                          >
                            파일 추가 +
                          </FileUploadButton>
                        </Content>
                      </Col>
                      <Col x={4}></Col>
                      <Col x={20}>
                        <Content maxWidth={'100%'} padding={0}>
                          <Row>
                            {fileList?.map((item, index) => (
                              <Col x={6} key={`image-${index}`}>
                                <div
                                  style={{
                                    width: '100%',
                                    height: 150,
                                    position: 'relative',
                                    top: 0,
                                    left: 0,
                                    marginTop: 10,
                                    padding: 10,
                                  }}
                                >
                                  <Image
                                    src={item}
                                    width={'100%'}
                                    height={'100%'}
                                  />

                                  <button
                                    style={{
                                      width: 20,
                                      height: 20,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderRadius: 5,
                                      border: '1px solid #BDBDBD',
                                      background: '#BDBDBD',
                                      position: 'absolute',
                                      top: 0,
                                      right: 0,
                                      cursor: 'pointer',
                                      color: '#FFFFFF',
                                    }}
                                    onClick={() => handleRemoveFile(index)}
                                  >
                                    x
                                  </button>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Content>
                      </Col>
                    </Row>
                  ) : null}
                  {/* 첨부파일 */}
                  {type === 'write' || type === 'regist' ? (
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 10,
                      }}
                    >
                      <Col x={4}>
                        <Title size={5} align={'center'}>
                          첨부파일
                        </Title>
                      </Col>
                      <Col x={20}>
                        <Content maxWidth={'100%'} padding={0}>
                          {!programData?.file ? (
                            <FileUploadButton
                              onChange={handleFiles}
                              layoutStyle={{
                                maxWidth: 156,
                                width: '100%',
                              }}
                              style={{
                                textAlign: 'center',
                                fontSize: '16px',
                              }}
                            >
                              파일 추가 +
                            </FileUploadButton>
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                position: 'relative',
                                top: 0,
                                left: 0,
                                fontSize: 18,
                              }}
                            >
                              <p
                                style={{ color: '#1890FF', cursor: 'pointer' }}
                                onClick={() =>
                                  handleFileDownload(programData?.file)
                                }
                              >
                                {programData?.file?.file_name}
                              </p>

                              {loginWho === 'agency' ? null : (
                                <button
                                  style={{
                                    width: 20,
                                    height: 20,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 5,
                                    border: '1px solid #BDBDBD',
                                    background: '#BDBDBD',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    cursor: 'pointer',
                                    color: '#FFFFFF',
                                  }}
                                  onClick={() => handleChange('file', null)}
                                >
                                  x
                                </button>
                              )}
                            </div>
                          )}
                        </Content>
                      </Col>
                    </Row>
                  ) : (
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 10,
                      }}
                    >
                      <Col x={4}>
                        <Title size={5} align={'center'}>
                          첨부파일
                        </Title>
                      </Col>
                      <Col x={20}>
                        <Content maxWidth={'100%'} padding={0}>
                          {!programData?.file ? null : (
                            <div
                              style={{
                                width: '100%',
                                position: 'relative',
                                top: 0,
                                left: 0,
                                fontSize: 18,
                              }}
                            >
                              <p
                                style={{ color: '#1890FF', cursor: 'pointer' }}
                                onClick={() =>
                                  handleFileDownload(programData?.file)
                                }
                              >
                                {programData?.file?.file_name}
                              </p>
                            </div>
                          )}
                        </Content>
                      </Col>
                    </Row>
                  )}
                </Content>
              </Content>
              {type === 'write' ? (
                <Row style={{ marginTop: 50 }}>
                  <Col
                    x={24}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <Btn
                      style={{
                        width: '100%',
                        height: 56,
                        fontSize: 18,
                        margin: '0 auto',
                      }}
                      layoutStyle={{ width: '40%' }}
                      color="secondary"
                      onClick={() => handleProgramUpdate()}
                    >
                      저장하기
                    </Btn>
                    <Btn
                      style={{
                        width: '100%',
                        height: 56,
                        fontSize: 18,
                        margin: '0 auto',
                      }}
                      layoutStyle={{ width: '40%' }}
                      onClick={() => handlePDFDownload()}
                    >
                      PDF로 저장하기
                    </Btn>
                  </Col>
                </Row>
              ) : type === 'regist' ? (
                <Row style={{ marginTop: 50 }}>
                  <Col x={24}>
                    <Btn
                      style={{
                        width: '50%',
                        height: 56,
                        fontSize: 18,
                        margin: '0 auto',
                      }}
                      onClick={() => handleProgramUpdate()}
                    >
                      등록하기
                    </Btn>
                  </Col>
                </Row>
              ) : (
                <Row style={{ marginTop: 50 }}>
                  <Col x={24}>
                    <Btn
                      style={{
                        width: '50%',
                        height: 56,
                        fontSize: 18,
                        margin: '0 auto',
                      }}
                      onClick={() => handlePDFDownload()}
                    >
                      PDF로 저장하기
                    </Btn>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Content>
      </Content>

      <div id="template_container"></div>

      {/* 프로그램 선택 모달 */}
      <ModalLayout
        title={'프로그램'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={programModal}
        onCancel={setProgramModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 768 : '100%'}
        height={'70%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={3700}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={7} padding={5}>
              <p>구분</p>
              <Select
                value={majorValue}
                onChange={(e) => setMajorValue(e)}
                style={{ width: '100%' }}
                size="large"
                options={categoryOptions}
                dropdownStyle={{
                  zIndex: 3800,
                }}
              />
            </Col>

            <Col x={10} padding={5}>
              <p>프로그램명</p>
              <Input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                style={{ width: '100%' }}
                size="large"
                placeholder="프로그램명을 검색해주세요"
              />
            </Col>
          </Row>
          <Row>
            <Col x={24} style={{ marginTop: 20, border: '1px solid #EEEEEE' }}>
              <Row>
                <Col
                  x={8}
                  padding={5}
                  style={{
                    borderRight: '1px solid #EEEEEE',
                    color: '#BDBDBD',
                    fontWeight: 700,
                  }}
                >
                  구분
                </Col>
                <Col
                  x={16}
                  padding={5}
                  style={{
                    color: '#BDBDBD',
                    fontWeight: 700,
                  }}
                >
                  프로그램명
                </Col>
              </Row>
            </Col>
            <Col
              x={24}
              style={{
                border: '1px solid #EEEEEE',
                borderTop: 'none',
                height: 500,
                overflowY: 'scroll',
              }}
            >
              {programItems && programItems?.length ? (
                <List>
                  <VirtualList
                    data={programItems}
                    height={'100%'}
                    itemKey={'program_id'}
                  >
                    {(item) => (
                      <Row
                        key={item?.program_id}
                        style={{ borderBottom: '1px solid #EEEEEE' }}
                      >
                        <Col
                          x={8}
                          padding={'10px 5px'}
                          style={{
                            borderRight: '1px solid #EEEEEE',
                            color: '#000000',
                            fontWeight: 700,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {item?.category_name}
                        </Col>
                        <Col
                          x={16}
                          padding={'10px 5px'}
                          style={{
                            color: '#000000',
                            fontWeight: 700,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          {item?.content?.title}
                          <Button onClick={() => handleSelectProgram(item)}>
                            선택
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </VirtualList>
                </List>
              ) : (
                <Row
                  style={{
                    borderBottom: '1px solid #EEEEEE',
                    height: '100%',
                  }}
                >
                  <Col x={24}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{
                        height: '100%',
                        margin: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Content>
      </ModalLayout>

      {/* 참고영상 추가 모달 */}
      <ModalLayout
        title={'참고영상'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={videoModal}
        onCancel={setVideoModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 768 : '100%'}
        height={'70%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={3700}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24} padding={5}>
              <div>
                <Title size={5}>영상 제목</Title>
                <Input
                  size="large"
                  value={videoValue?.title}
                  onChange={(e) =>
                    setVideoValue((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  placeholder="영상 제목을 입력해주세요."
                />
              </div>
              <div style={{ marginTop: 30 }}>
                <Title size={5}>영상</Title>
                <Input.TextArea
                  size="large"
                  value={videoValue?.html}
                  onChange={(e) =>
                    setVideoValue((prev) => ({
                      ...prev,
                      html: e.target.value,
                    }))
                  }
                  style={{ height: 300 }}
                  placeholder='ex) <iframe width="560" height="315" src="영상주소" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
                />
              </div>
              <div style={{ marginTop: 30 }}>
                <Btn
                  style={{ width: '100%', height: 54, fontSize: 18 }}
                  onClick={() => handleVideoList()}
                >
                  추가
                </Btn>
              </div>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

ProgramLayout.defaultProps = {};

export default ProgramLayout;
