/**
 * @title 브레인힐 서비스에서 사용하는 주소 양식 반환
 * @param response 다음 api 결과
 */
const serviceLocationFormatter = (response) => {
  const { roadAddress } = response;
  return roadAddress;
};

/**
 * @title 다음 주소 API 모달 활성화
 * @param callback 데이터 성공시 실행할 함수
 * @return Success => callback 함수 실행
 * @return Failure => failure 함수 실행
 */
export const openAddressModal = (success = () => {}) => {
  new window.daum.Postcode({
    onComplete: (data) => {
      success(serviceLocationFormatter(data));
    },
  }).open({
    popupKey: 'popup1', // 여러개의 팝업창이 뜨는 것을 방지
  });
};

/**
 * @async
 * @title 다음 주소 API 모달 활성화
 * @param callback 데이터 성공시 실행할 함수
 * @return Success => callback 함수 실행
 * @return Failure => failure 함수 실행
 */
export const openAddressModalAsync = (success = () => {}) => {
  return new Promise((resolve, reject) => {
    new window.daum.Postcode({
      onComplete: (data) => {
        try {
          resolve(serviceLocationFormatter(data));
        } catch (error) {
          reject(error);
        }
      },
    }).open();
  });
};
