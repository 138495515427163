import React, { useEffect } from 'react';
import { Content, Row, Col, FileUploadButton } from 'components';
import { Button, Form, Input, Select, Typography } from 'antd';

const selectOptions = [
  {
    label: '표시',
    value: 'Y',
  },
  {
    label: '숨김',
    value: 'N',
  },
];

const BannerRegisterPresenter = (props) => {
  /* ===== Route ===== */
  /* ===== Props ===== */
  const {
    inputValue,
    registType,
    pcFile,
    mobileFile,
    onChange,
    onSubmit,
    onError,
    onDelete,
    onFile,
    isRender,
    setIsRender,
  } = props;

  /* ===== State ===== */
  const [form] = Form.useForm();

  /* ===== Function ===== */

  /* ===== Hooks ===== */
  useEffect(() => {
    const fillForm = (data) => {
      const { title, link_url, is_show } = data;
      form.setFieldsValue({
        title,
        link_url,
        is_show,
      });
      setIsRender(true);
    };

    if (inputValue?.title && !isRender && form) {
      fillForm(inputValue);
    }
  }, [inputValue, isRender, form, setIsRender]);

  /* ===== Render ===== */
  return (
    <Content padding={0} maxWidth={'100%'} backgroundColor={'none'}>
      <Row>
        <Col x={24}>
          {/* 입력 폼 */}
          <Content maxWidth={'100%'}>
            <Form
              form={form}
              layout="vertical"
              onFinish={() => onSubmit(registType)}
              onFinishFailed={onError}
              initialValues={{
                title: inputValue?.title,
                link_url: inputValue?.link_url,
                is_show: 'Y',
              }}
            >
              {/* 타이틀 */}
              <Form.Item
                label={'타이틀'}
                name={'title'}
                rules={[
                  { required: true, message: '배너 타이틀을 입력해주세요!' },
                ]}
              >
                <Input
                  size="large"
                  placeholder="배너 타이틀을 입력해주세요"
                  // value={inputValue?.title}
                  onChange={(e) => onChange('title', e.target.value)}
                />
              </Form.Item>
              {/* //타이틀 */}

              {/* PC 파일 */}
              <Form.Item
                label={'PC 배너'}
                name={'pc_file_url'}
                tooltip={{
                  title: '1920 x 700 규격의 이미지를 등록해주세요',
                  zIndex: 3500,
                  color: '#AB5476',
                  overlayInnerStyle: { width: 280, textAlign: 'center' },
                }}
              >
                {!inputValue?.pc_file_url && !pcFile ? (
                  <FileUploadButton
                    onChange={(file) => onFile('PC', file)}
                    accept="image/*"
                  />
                ) : !inputValue?.pc_file_url && pcFile ? (
                  <Typography>
                    <pre style={{ margin: 0, height: 40 }}>
                      {pcFile?.name}
                      <span style={{ ...styles.removeButtonLayout }}>
                        <button
                          type="button"
                          style={{ ...styles.removeButton }}
                          onClick={() => onFile('PC', null)}
                        >
                          x
                        </button>
                      </span>
                    </pre>
                  </Typography>
                ) : (
                  <Typography>
                    <pre style={{ margin: 0, height: 40 }}>
                      {inputValue?.pc_file_url}
                      <span style={{ ...styles.removeButtonLayout }}>
                        <button
                          type="button"
                          style={{ ...styles.removeButton }}
                          onClick={() => onChange('pc_file_url', null)}
                        >
                          x
                        </button>
                      </span>
                    </pre>
                  </Typography>
                )}
              </Form.Item>
              {/* //PC 파일 */}

              {/* 모바일 파일 */}
              <Form.Item
                label={'모바일 배너'}
                name={'mobile_file_url'}
                tooltip={{
                  title: '760 x 400 규격의 이미지를 등록해주세요',
                  zIndex: 3500,
                  color: '#AB5476',
                  overlayInnerStyle: { width: 280, textAlign: 'center' },
                }}
              >
                {!inputValue?.mobile_file_url && !mobileFile ? (
                  <FileUploadButton
                    onChange={(file) => onFile('MOBILE', file)}
                    accept="image/*"
                  />
                ) : !inputValue?.mobile_file_url && mobileFile ? (
                  <Typography>
                    <pre style={{ margin: 0, height: 40 }}>
                      {mobileFile?.name}
                      <span style={{ ...styles.removeButtonLayout }}>
                        <button
                          type="button"
                          style={{ ...styles.removeButton }}
                          onClick={() => onFile('MOBILE', null)}
                        >
                          x
                        </button>
                      </span>
                    </pre>
                  </Typography>
                ) : (
                  <Typography>
                    <pre style={{ margin: 0, height: 40 }}>
                      {inputValue?.mobile_file_url}
                      <span style={{ ...styles.removeButtonLayout }}>
                        <button
                          type="button"
                          style={{ ...styles.removeButton }}
                          onClick={() => onChange('mobile_file_url', null)}
                        >
                          x
                        </button>
                      </span>
                    </pre>
                  </Typography>
                )}
              </Form.Item>
              {/* //모바일 파일 */}

              {/* 링크 */}
              <Form.Item
                label={'URL'}
                name={'link_url'}
                rules={[
                  {
                    type: 'url',
                    warningOnly: true,
                    message: 'URL 형식을 정확하게 입력해주세요!',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="연결할 URL 주소를 입력해주세요"
                  value={inputValue?.link_url}
                  onChange={(e) => onChange('link_url', e.target.value)}
                />
              </Form.Item>
              {/* //링크 */}

              {/* 숨김여부 */}
              <Form.Item label={'숨김여부'} name={'is_show'}>
                <Select
                  size="large"
                  options={selectOptions}
                  value={inputValue?.is_show}
                  onChange={(e) => onChange('is_show', e)}
                  dropdownStyle={{ zIndex: 3500 }}
                />
              </Form.Item>
              {/* //숨김여부 */}

              {/* 버튼 */}
              <Form.Item style={{ margin: 0 }}>
                {registType === 'CREATE' ? (
                  <Button type="primary" htmlType="submit">
                    추가
                  </Button>
                ) : (
                  <div>
                    <Button type="primary" htmlType="submit">
                      수정
                    </Button>
                    <Button
                      danger
                      type="primary"
                      htmlType="button"
                      onClick={() => onDelete(inputValue.banner_id)}
                      style={{ marginLeft: 30 }}
                    >
                      삭제
                    </Button>
                  </div>
                )}
              </Form.Item>
              {/* //버튼 */}
            </Form>
          </Content>
        </Col>
      </Row>
    </Content>
  );
};

const styles = {
  removeButtonLayout: {
    display: 'inline-block',
    paddingLeft: 10,
  },
  removeButton: {
    border: '1px solid #BDBDBD',
    borderRadius: 3,
    background: '#FFFFFF',
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export default BannerRegisterPresenter;
