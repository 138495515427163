import React, { useEffect, useState } from 'react';
import PurchasePresenter from './PurchasePresenter';
import { useQueryClient } from 'react-query';
import { getCookie } from 'utils';
import { PurchaseApi } from 'api';
import { loginUser } from 'atoms/Atoms';
import { useRecoilValue } from 'recoil';
const PurchaseContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const loginWho = useRecoilValue(loginUser);
  const [newPurchases, setNewPurchases] = useState([]);

  /* ===== Mutate ===== */
  /* ===== DATA ===== */
  const queryClient = useQueryClient();

  const products = queryClient.getQueryData('getProducts')?.data;
  const productFiles = queryClient.getQueryData('getProductFiles')?.data;
  const programs = queryClient.getQueryData('getPrograms')?.data;
  const productCategories = queryClient.getQueryData(
    'getProductCategories'
  )?.data;
  const idx = parseInt(getCookie('USER'));
  const { data: purchases, refetch: purchasesRefetch } =
    PurchaseApi.GetPurchases(
      loginWho === 'agency'
        ? {
            agency_id: idx,
          }
        : {
            user_id: idx,
          }
    );

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    purchasesRefetch();
  }, [idx, purchasesRefetch]);

  useEffect(() => {
    if (purchases?.status === 200) {
      const { data } = purchases;

      setNewPurchases(data);
    }
  }, [purchases]);

  /* ===== RENDER ===== */
  return (
    <>
      <PurchasePresenter
        products={products}
        productFiles={productFiles}
        purchases={newPurchases}
        productCategories={productCategories}
        idx={idx}
        //NOTE: 교안
        programs={programs}
      />
    </>
  );
};

PurchaseContainer.defaultProps = {};

export default PurchaseContainer;
