import { getCookie, HOST_URL } from 'utils';
import MessageAlert from './MessageAlert';

export const FileUploadManager = async (type, file, id = 0) => {
  const body = new FormData();
  // 로그인된 유저 ID
  const userId = getCookie('USER');

  if (file && userId) {
    /**
     * 버킷 PATH 설정
     * --
     * S : 스케쥴
     * U : 유저
     * L : 강의
     * C : 컨텐츠
     * P : 상품
     * T : 교안
     * B : 게시판
     * SIGN: 서명
     * BANNER: 배너
     */
    const path =
      type === 'S'
        ? `brainheal/schedule/${id === 0 ? userId : id}/`
        : type === 'U'
        ? `brainheal/users/${id === 0 ? userId : id}/`
        : type === 'L'
        ? `brainheal/lecture/`
        : type === 'C'
        ? `brainheal/content/`
        : type === 'P'
        ? `brainheal/product/`
        : type === 'B'
        ? `brainheal/board/`
        : type === 'SIGN'
        ? `brainheal/${id}`
        : type === 'BANNER'
        ? `brainheal/banner/`
        : `brainheal/program/`;
    const fileName = file.name;
    const randomName = `${userId}_${type}_file_${Math.random()
      .toString(36)
      .substring(2, 11)}`;
    const buketFileName = path + randomName;

    body.append('file_name', fileName);
    body.append('file', file);
    body.append('name', buketFileName);

    try {
      const res = await fetch(`${HOST_URL}/file/upload`, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json;charset=UTF-8',
          // Accept: 'application/json',
          mode: 'no-cors',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${getCookie('BRAINHEAL_ACCESS_TOKEN')}`,
        },
        body: body,
      });

      return {
        status: res.status,
        file_name: fileName,
        name: randomName,
        type: type,
      };
    } catch (e) {
      console.log('Error !!', e);
      MessageAlert.error('파일 업로드에 실패하였습니다');
      throw e;
    }
  } else {
    MessageAlert.error('파일 업로드에 실패하였습니다');
  }
};
