import React, { useState, useEffect } from 'react';
import LectureOrdersPresenter from './LectureOrdersPresenter';

const LectureOrdersContainer = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);
  const [lectureData, setLectureData] = useState([]);
  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { users, teachers, lectures } = data;
      if (users && teachers && lectures) {
        const mergeUsers = [...users, ...teachers];
        const newUsers = mergeUsers?.map((item) => ({
          ...item,
          profile_img: item?.files?.filter((subitem) => subitem?.type === 'P'),
        }));
        setNewData(newUsers);
        setLectureData(lectures);
      }
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <LectureOrdersPresenter userList={newData} lectureList={lectureData} />
  );
};

LectureOrdersContainer.defaultProps = {};

export default LectureOrdersContainer;
