import React, { useState, useEffect, useRef } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  ModalLayout,
  Button,
  DaumAddress,
} from 'components';
import { Avatar, Input, Switch, Table, Space, Radio } from 'antd';
import moment from 'moment';
import { SettingOutlined, SearchOutlined } from '@ant-design/icons';
import { AgencyApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import validateCheck from 'utils/Validation';
import Highlighter from 'react-highlight-words';
import { useNavigate, useSearchParams } from 'react-router-dom';
const AgenciesPresenter = (props) => {
  /* ===== Props ===== */
  const { agencies, screenSize } = props;

  /* ===== Router ===== */
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  /* ===== Mutate ===== */
  const updateAgency = AgencyApi.UpdateAgency();
  const deleteAgency = AgencyApi.DeleteAgency();

  /* ===== State ===== */
  // const [isAgencyOpened, setIsAgencyOpened] = useState({});
  const [modalData, setModalData] = useState({});
  const [agencyModal, setAgencyModal] = useState(false);
  const [newData, setNewData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${title}을(를) 입력해주세요`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            검색
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            초기화
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '기관명',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', '기관명'),
      render: (_, { name, data }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar>{name[0]}</Avatar>
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            {name}
          </Title>
        </Content>
      ),
    },
    {
      title: '대표자명',
      dataIndex: 'chief_name',
      key: 'chief_name',
      ...getColumnSearchProps('chief_name', '대표자명'),
      render: (_, { chief_name }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {chief_name}
        </Title>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone', '연락처'),
      render: (_, { phone }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {phone}
        </Title>
      ),
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', '이메일'),
      render: (_, { email }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {email}
        </Title>
      ),
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      render: (_, { address }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {address}
        </Title>
      ),
    },
    {
      title: '가입일',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(a?.created_at) - new Date(b?.created_at),
      render: (_, { created_at }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {created_at}
        </Title>
      ),
    },
    {
      title: '',
      dataIndex: 'butotn',
      key: 'button',
      render: (_, { data }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          <p
            onClick={() => handleAgencyUpdate('update', data)}
            style={{
              cursor: 'pointer',
              margin: 0,
            }}
          >
            <SettingOutlined />
          </p>
        </Title>
      ),
    },
  ];
  /**
   * pagination 처리 함수
   * --
   */
  const handlePagination = (page) => {
    navigation(`/admin/agencies?page=${page}`);
  };

  /* ===== Hooks ===== */
  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);
  /**
   * 기관 데이터 정제
   * --
   */
  useEffect(() => {
    const n = agencies?.map((item) => ({
      key: item.agency_id,
      name: item?.name,
      chief_name: item?.chief_name,
      phone: item?.phone,
      email: item?.email,
      address: `${item?.address ? item?.address : '-'} ${
        item?.address_detail ? item?.address_detail : ''
      }`,
      created_at: moment(item?.created_at).format('YYYY-MM-DD'),
      data: item,
    }));

    setNewData(n);
  }, [agencies]);

  /* ===== Functions ===== */
  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (t, v, callback = null) => {
    if (agencyModal) {
      let newData = { ...modalData };

      if (t === 'email') {
        const value = newData[t];
        // v 값에 한글이 포함되어 있는지 검사하는 정규식
        const koreanRegex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;

        if (value === '' || !value) {
          if (v === '' || !v) {
            v = undefined;
          } else if (v?.match(koreanRegex)) {
            MessageAlert.warning('영문 또는 숫자만 입력해주세요');
            v = undefined;
          }
        } else {
          if (v === '' || !v) {
            v = undefined;
          } else if (v?.match(koreanRegex)) {
            MessageAlert.warning('영문 또는 숫자만 입력해주세요');
            v = value;
          }
        }
      }

      newData[t] = v;
      const d = {
        ...newData,
      };
      setModalData(d);

      if (callback) callback(v);
    }
  };

  /**
   * 기관 상세 데이터 처리
   * --
   * @param {*} type
   * @param {*} data
   */
  const handleAgencyUpdate = (type, data) => {
    if (type === 'update') {
      setAgencyModal(!agencyModal);
      setModalData(data);
    } else {
      const confirm = window.confirm(
        '삭제 후 복구할 수 없습니다. 해당 기관을 정말 삭제하시겠습니까?\n삭제 시 기관이 요청한 스케쥴도 함께 삭제됩니다'
      );

      if (confirm) {
        try {
          deleteAgency.mutate({
            agency_id: data?.agency_id,
          });
          MessageAlert.success('삭제되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('회원 삭제에 실패하였습니다');
          throw e;
        }
      }
    }
  };

  /**
   * 기관 데이터 수정 함수
   * --
   */
  const handleAgencyDataUpdate = () => {
    const { email, recommender_id, point, capacity } = modalData;

    if (!validateCheck('email', email)) {
      MessageAlert.warning('이메일 형식을 확인해주세요');
      return;
    }

    if (capacity) {
      if (!validateCheck('number', capacity))
        return MessageAlert.warning('정원은 숫자로만 입력 가능합니다');
    }

    if (recommender_id && recommender_id !== '') {
      if (!validateCheck('account', recommender_id)) {
        MessageAlert.warning('추천인ID는 영문 또는 숫자만 입력가능합니다');
        return;
      }
    }

    if (point) {
      if (!validateCheck('number', point))
        return MessageAlert.warning('포인트는 숫자로만 입력 가능합니다');
    }

    const confirm = window.confirm('기관 정보를 수정하시겠습니까?');

    if (confirm) {
      try {
        const { created_at, ...d } = modalData;
        updateAgency.mutate({
          ...d,
        });
        MessageAlert.success('기관 정보가 수정되었습니다');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('기관 정보 수정에 실패하였습니다');
        throw e;
      }
    }
  };

  /**
   * 숫자 체크
   * --
   */
  const OnlyNumber = (v) => {
    let str = v.replace(/[^0-9]/g, '');
    str === '' && MessageAlert.warning('숫자만 입력해 주세요!');
    return str;
  };

  /**
   * 사업자등록번호 자동 Hyphen
   * --
   */
  const handleHyphen = (v) => {
    let str = OnlyNumber(v);
    let company_number = '';
    if (str.length < 4) {
      company_number = str;
    } else if (str.length < 6) {
      company_number += str.substr(0, 3);
      company_number += '-';
      company_number += str.substr(3);
    } else if (str.length) {
      company_number += str.substr(0, 3);
      company_number += '-';
      company_number += str.substr(3, 2);
      company_number += '-';
      company_number += str.substr(5);
    }
    company_number === modalData.company_number &&
      MessageAlert.warning('숫자만 입력해 주세요!');
    handleChange('company_number', company_number);
  };

  /**
   * 핸드폰 번호 자동 Hyphen
   * --
   */
  const handlePhoneHyphen = (v) => {
    let str = OnlyNumber(v);
    let phoneText = '';
    // 02 연락처 예외처리
    if (`${str[0]}${str[1]}` === '02') {
      if (str.length < 3) {
        phoneText = str;
      } else if (str.length < 6) {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2);
      } else if (str.length < 10) {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2, 3);
        phoneText += '-';
        phoneText += str.substr(5);
      } else {
        phoneText += str.substr(0, 2);
        phoneText += '-';
        phoneText += str.substr(2, 4);
        phoneText += '-';
        phoneText += str.substr(6);
      }
    } else {
      if (str.length < 4) {
        phoneText = str;
      } else if (str.length < 7) {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3);
      } else if (str.length < 11) {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3, 3);
        phoneText += '-';
        phoneText += str.substr(6);
      } else {
        phoneText += str.substr(0, 3);
        phoneText += '-';
        phoneText += str.substr(3, 4);
        phoneText += '-';
        phoneText += str.substr(7);
      }
    }
    phoneText === modalData.phone && alert('숫자만 입력해 주세요!');
    handleChange('phone', phoneText);
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={24}>
            <Title size={2} style={{ marginBottom: 20 }}>
              기관회원 관리
            </Title>
            <Content
              maxWidth={'100%'}
              padding={'24px 24px 0 24px'}
              className={'admin_container'}
            >
              <Table
                columns={columns}
                dataSource={newData}
                showSorterTooltip={false}
                pagination={{
                  position: ['bottomCenter'],
                  current: currentPage,
                  onChange: (e) => handlePagination(e),
                }}
              />
            </Content>
          </Col>
        </Row>
      </Content>

      {/* 기관 상세 모달 */}
      <ModalLayout
        title={'기관 정보 수정'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={agencyModal}
        onCancel={setAgencyModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'70%'}
        closable
        bodyStyle={{ padding: '40px 20px 20px' }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              {/* 아이디 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>아이디</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.agency_account}
                    placeholder="아이디를 입력해주세요"
                    readOnly
                    disabled
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 기관명 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>기관명</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.name}
                    placeholder="기관명을 입력해주세요"
                    onChange={(e) => handleChange('name', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 대표자명 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>대표자명</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.chief_name}
                    placeholder="대표자명을 입력해주세요"
                    onChange={(e) => handleChange('chief_name', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 종별 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>종별</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.type}
                    placeholder="종별을 입력해주세요"
                    onChange={(e) => handleChange('type', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 정원 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>정원</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.capacity}
                    placeholder="정원을 입력해주세요"
                    onChange={(e) => handleChange('capacity', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 전화번호 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>전화번호</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.phone}
                    placeholder="핸드폰번호를 입력해주세요"
                    onChange={(e) => handlePhoneHyphen(e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 사업자등록번호 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>사업자번호</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.company_number}
                    placeholder="사업자등록번호를 입력해주세요"
                    onChange={(e) => handleHyphen(e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 이메일 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>이메일</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.email ? modalData?.email : null}
                    placeholder="이메일을 입력해주세요"
                    onChange={(e) => handleChange('email', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 주소 */}
              <Row style={{ display: 'flex' }}>
                <Col x={6} style={{ marginTop: 20 }}>
                  <Title>주소</Title>
                </Col>
                <Col x={18}>
                  <DaumAddress
                    size="large"
                    value={modalData?.address}
                    placeholder="주소를 입력해주세요"
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                      cursor: 'pointer',
                    }}
                    callback={(e) => handleChange('address', e)}
                  />
                  <Input
                    size="large"
                    value={modalData?.address_detail}
                    placeholder="상세주소를 입력해주세요"
                    onChange={(e) =>
                      handleChange('address_detail', e.target.value)
                    }
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 추천인 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>추천인 ID</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.recommender_id}
                    placeholder="추천인 ID를 입력해주세요"
                    onChange={(e) =>
                      handleChange('recommender_id', e.target.value)
                    }
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 포인트 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>포인트</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.point}
                    placeholder="포인트를 입력해주세요"
                    onChange={(e) => handleChange('point', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 활성화 여부 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>계정 활성화</Title>
                </Col>
                <Col x={18}>
                  <Switch
                    checkedChildren={'승인'}
                    unCheckedChildren={'차단'}
                    checked={modalData?.is_enable === 'Y' ? true : false}
                    onChange={(e) =>
                      e
                        ? handleChange('is_enable', 'Y')
                        : handleChange('is_enable', 'N')
                    }
                  />
                </Col>
              </Row>
              {/* 노출여부 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <Col x={6}>
                  <Title>노출여부</Title>
                </Col>
                <Col x={18}>
                  <Radio.Group
                    size="large"
                    value={modalData?.is_show}
                    onChange={(e) => handleChange('is_show', e.target.value)}
                  >
                    <Radio value={'Y'}>Y</Radio>
                    <Radio value={'N'}>N</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col x={24}>
              <Button
                style={{ width: '100%', height: '50px', marginTop: '30px' }}
                onClick={() => handleAgencyDataUpdate()}
              >
                수정
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

AgenciesPresenter.defaultProps = {};

export default AgenciesPresenter;
