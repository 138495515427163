import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Title, Button, Navbar, Footer } from 'components';
import { useSearchParams } from 'react-router-dom';
import { decrypt, encrypt } from 'utils/Crypto';
import { List, Collapse } from 'antd';
import { LectureApi } from 'api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import '../styles/antStyle.css';
import VirtualList from 'rc-virtual-list';
import moment from 'moment';

const { Panel } = Collapse;

const ExamList = (props) => {
  /* ===== PROPS ===== */
  const { navigation, queryClient } = props;

  /* ===== ROUTE ===== */
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('lecture');

  /* ===== STATE ===== */
  const [data, setData] = useState({});

  /* ===== DATA ===== */
  const { data: lecture, refetch } = LectureApi.GetLecture(
    parseInt(decrypt(lectureId))
  );
  const exams = queryClient.getQueryData('getExams')?.data;
  const answers = queryClient.getQueryData('getAnswerExams')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const lectureOfUsers = queryClient.getQueryData('getLectureOfUsers')?.data;

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /**
   * 강의 Refetch
   * --
   */
  useEffect(() => {
    refetch();
  }, [lectureId, refetch]);

  /**
   * 시험 데이터 정제
   * --
   */
  useEffect(() => {
    const filterUser = lectureOfUsers?.filter(
      (item) => item?.lecture_id === parseInt(decrypt(lectureId))
    );
    const NewUsers = filterUser?.map((item) => ({
      ...item,
      ...(users &&
        users?.filter((subitem) => subitem?.user_id === item?.user_id)[0]),
    }));
    const exam = exams
      ?.filter((item) => item.lecture_id === parseInt(decrypt(lectureId)))
      ?.pop();

    const filterAnswers = answers?.filter(
      (item) => item?.exam_id === exam?.exam_id
    );
    const newAnswers = filterAnswers?.map((item) => ({
      ...item,
      submission_date: item?.created_at,
      exam_score: item?.answer?.reduce((acc, cur) => {
        acc += cur?.score;
        return acc;
      }, 0),
      ...(NewUsers &&
        NewUsers?.filter((subitem) => subitem?.user_id === item?.user_id)[0]),
    }));

    const NewExams = exam &&
      NewUsers && {
        ...exam,
        answerers: newAnswers,
      };

    if (lecture) {
      const d = {
        lecture: lecture?.data,
        exam: NewExams,
      };

      setData(d);
    }
  }, [lecture, exams, answers, lectureId, lectureOfUsers, users]);

  /* ===== STYLES ===== */
  const styles = {
    listTitle: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.02em',
      padding: '0 18px',
    },
    listItem: {
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.02em',
      padding: '0 18px',
    },
  };

  /* ===== RENDER ===== */
  return (
    <>
      <Navbar subPath={'MAIN'} />
      <Content maxWidth={'100%'} padding={0} style={{ margin: '48px 0 84px' }}>
        <Content padding={15} maxWidth={1200}>
          <Row>
            <Col x={24}>
              <Title
                style={{
                  fontSize: '30px',
                  fontWeight: 700,
                  lineHeight: '36px',
                  padding: 8,
                }}
              >
                {data?.lecture?.title}
              </Title>
              {data?.exam ? (
                <>
                  <Collapse
                    key={`exam-${data?.exam?.exam_id}`}
                    style={{ marginTop: '24px' }}
                    bordered={false}
                    defaultActiveKey={`${data?.exam?.exam_id}`}
                  >
                    <Panel
                      showArrow={false}
                      style={{ background: '#FFFFFF' }}
                      header={
                        <div
                          style={{
                            border: '1px solid #9E9E9E',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: 24,
                          }}
                        >
                          <p
                            style={{
                              fontSize: '20px',
                              fontWeight: 700,
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                            }}
                          >
                            {data?.exam?.title}
                            <span
                              style={{
                                color: '#AB5476',
                                fontSize: '14px',
                                marginLeft: '12px',
                              }}
                            >
                              <span>
                                <FontAwesomeIcon icon={faUser} />
                              </span>
                              <span style={{ marginLeft: '6px' }}>
                                +
                                {data?.exam?.answerers &&
                                data?.exam?.answerers.length > 0
                                  ? data?.exam?.answerers?.length
                                  : 0}
                              </span>
                            </span>
                          </p>

                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigation(
                                `/exam/register?lecture=${encodeURIComponent(
                                  lectureId
                                )}&exam=${encodeURIComponent(
                                  encrypt(data?.exam?.exam_id)
                                )}`
                              );
                            }}
                            style={{ fontSize: '14px', color: '#1C1B1F' }}
                          />
                        </div>
                      }
                    >
                      <Content
                        maxWidth={'100%'}
                        padding={24}
                        backgroundColor={'#EEEEEE'}
                        style={{ marginTop: '24px', borderRadius: '4px' }}
                      >
                        <Row>
                          <Col x={3}>
                            <p
                              style={{
                                ...styles.listTitle,
                              }}
                            >
                              No.
                            </p>
                          </Col>
                          <Col x={6}>
                            <p
                              style={{
                                ...styles.listTitle,
                              }}
                            >
                              제출자
                            </p>
                          </Col>
                          <Col x={6}>
                            <p
                              style={{
                                ...styles.listTitle,
                              }}
                            >
                              제출 날짜
                            </p>
                          </Col>
                          <Col x={3}>
                            <p
                              style={{
                                ...styles.listTitle,
                              }}
                            >
                              제출 시간
                            </p>
                          </Col>
                          <Col x={3}>
                            <p
                              style={{
                                ...styles.listTitle,
                              }}
                            >
                              점수
                            </p>
                          </Col>
                          <Col x={3}>
                            <p
                              style={{
                                ...styles.listTitle,
                              }}
                            >
                              통과여부
                            </p>
                          </Col>
                        </Row>
                        {data?.exam?.answerers &&
                        data?.exam?.answerers?.length > 0 ? (
                          <List
                            style={{ background: '#FFFFFF', marginTop: '24px' }}
                          >
                            <VirtualList
                              data={data?.exam?.answerers}
                              height={384}
                              itemHeight={64}
                              itemKey={'user_id'}
                            >
                              {(item, index) => (
                                <List.Item
                                  key={item?.user_id}
                                  style={{
                                    padding: 0,
                                    height: '64px',
                                  }}
                                >
                                  <Row style={{ width: '100%' }}>
                                    <Col x={3}>
                                      <p style={{ ...styles.listItem }}>
                                        {index + 1}
                                      </p>
                                    </Col>
                                    <Col x={6}>
                                      <p style={{ ...styles.listItem }}>
                                        {item?.name}
                                      </p>
                                    </Col>
                                    <Col x={6}>
                                      <p style={{ ...styles.listItem }}>
                                        {item?.submission_date
                                          ? moment(
                                              item?.submission_date
                                            ).format('YY/MM/DD')
                                          : '-'}
                                      </p>
                                    </Col>
                                    <Col x={3}>
                                      <p style={{ ...styles.listItem }}>
                                        {item?.submission_date
                                          ? moment(
                                              item?.submission_date
                                            ).format('HH:MM')
                                          : '-'}
                                      </p>
                                    </Col>
                                    <Col x={3}>
                                      <p style={{ ...styles.listItem }}>
                                        {item?.exam_score}
                                      </p>
                                    </Col>
                                    <Col x={3}>
                                      <p
                                        style={{
                                          ...styles.listItem,
                                          color:
                                            item?.exam_score >= 80
                                              ? '#2AC769'
                                              : '#FB4E4E',
                                        }}
                                      >
                                        {item?.exam_score &&
                                        item?.exam_score >= 80
                                          ? 'PASS'
                                          : 'FAIL'}
                                      </p>
                                    </Col>
                                  </Row>
                                </List.Item>
                              )}
                            </VirtualList>
                          </List>
                        ) : (
                          <Content
                            maxWidth={'100%'}
                            height={384}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: '24px',
                            }}
                          >
                            <p style={{ fontSize: '24px', fontWeight: 700 }}>
                              제출한 수강생이 없습니다
                            </p>
                          </Content>
                        )}
                      </Content>
                    </Panel>
                  </Collapse>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: '300px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Title>등록된 시험이 없습니다.</Title>
                  </div>
                  <Button
                    style={{ width: '100%', height: '72px', marginTop: '24px' }}
                    onClick={() =>
                      navigation(
                        `/exam/register?lecture=${encodeURIComponent(
                          lectureId
                        )}`
                      )
                    }
                  >
                    시험 등록하기
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Content>
      </Content>
      <Footer />
    </>
  );
};

ExamList.defaultProps = {};

export default ExamList;
