import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 시/도, 구/군, 동/읍/면/리 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetAddress: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getAddress', params] : 'getAddress',
        async () => await $http.get(APIConstant.GET_ADDRESS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 시/도, 구/군, 동/읍/면/리 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetAddressLocal: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_ADDRESS_LOCAL, {
        local_id: idx,
      });
      const res = useQuery(
        params ? ['getAddressLocal', params] : 'getAddressLocal',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 시/도, 구/군, 동/읍/면/리 구분 조회
   * --
   * @method GET
   * @returns
   */
  GetAddressSplit: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getAddressSplit', params] : 'getAddressSplit',
        async () => await $http.get(APIConstant.GET_ADDRESS_SPLIT, params),
        options
      );
      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 시,도 생성
   * --
   * @method POST
   * @returns
   */
  CreateAddressLocal: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_ADDRESS_LOCAL,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAddress');
            queryClient.invalidateQueries('getAddressLocal');
            queryClient.invalidateQueries('getAddressSplit');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 시,도 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateAddressLocal: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_ADDRESS_LOCAL, {
            local_id: data.local_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAddress');
            queryClient.invalidateQueries('getAddressLocal');
            queryClient.invalidateQueries('getAddressSplit');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 시,도 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteAddressLocal: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_ADDRESS_LOCAL, {
            local_id: data.local_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAddress');
            queryClient.invalidateQueries('getAddressLocal');
            queryClient.invalidateQueries('getAddressSplit');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 구,군 생성
   * --
   * @method POST
   * @returns
   */
  CreateAddressCity: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_ADDRESS_CITY,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAddress');
            queryClient.invalidateQueries('getAddressLocal');
            queryClient.invalidateQueries('getAddressSplit');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 구,군 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateAddressCity: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_ADDRESS_CITY, {
            city_id: data.city_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAddress');
            queryClient.invalidateQueries('getAddressLocal');
            queryClient.invalidateQueries('getAddressSplit');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 구,군 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteAddressCity: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_ADDRESS_CITY, {
            city_id: data.city_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAddress');
            queryClient.invalidateQueries('getAddressLocal');
            queryClient.invalidateQueries('getAddressSplit');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 동,읍,면,리 생성
   * --
   * @method POST
   * @returns
   */
  CreateAddressDistrict: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_ADDRESS_DISTRICT,
            data
          );

          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAddress');
            queryClient.invalidateQueries('getAddressLocal');
            queryClient.invalidateQueries('getAddressSplit');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 동,읍,면,리 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateAddressDistrict: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_ADDRESS_DISTRICT, {
            district_id: data.district_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAddress');
            queryClient.invalidateQueries('getAddressLocal');
            queryClient.invalidateQueries('getAddressSplit');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 동,읍,면,리 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteAddressDistrict: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_ADDRESS_DISTRICT, {
            district_id: data.district_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAddress');
            queryClient.invalidateQueries('getAddressLocal');
            queryClient.invalidateQueries('getAddressSplit');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
