import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import './react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FileUploadManager } from 'utils/FileUploadManager';
import { STORE_URL } from 'utils';
import MessageAlert from 'utils/MessageAlert';

const TextEditor = ({
  data,
  placeholder,
  readOnly,
  setHtmlString,
  editorStyle,
  toolbar,
}) => {
  /* ===== STATE ===== */
  // 에디터 상태
  const [editorState, setEditorState] = useState(null);

  /* ===== FUNCTION ===== */
  /**
   * HTML 코드로 변환
   * --
   * @param {*} state
   */
  const handleUpdateTextDescription = async (state) => {
    setEditorState(state);
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setHtmlString(html);
  };

  // 이미지를 감지해 컴포넌트로 변환해주는 사용자 정의 fn
  function imageBlockRenderer(contentBlock) {
    const type = contentBlock.getType();

    // Convert image type to mediaComponent
    if (type === 'atomic') {
      return {
        component: MediaComponent,
        editable: false,
        props: {
          foo: 'bar',
        },
      };
    }
  }

  // 이미지가 입력될 경우 img 태그로 변환
  function MediaComponent({ block, contentState, blockProps: { foo } }) {
    const data = contentState.getEntity(block.getEntityAt(0)).getData();
    const emptyHtml = ' ';
    return (
      <div>
        {emptyHtml}
        <img
          src={data.src}
          alt={data.alt || ''}
          style={{ height: data.height || 'auto', width: data.width || 'auto' }}
        />
      </div>
    );
  }

  /**
   * 파일 업로드
   * --
   * @param {*} file
   * @returns
   */
  const handleImageUpload = async (file) => {
    if (file) {
      try {
        const res = await FileUploadManager('C', file);

        if (res.status === 200) {
          const url = `${STORE_URL}/content/${res?.name}`;

          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({ data: { link: url } });
            reader.onerror = reject;
          });
        }
      } catch (e) {
        console.error('Error !!', e);
        MessageAlert.error('이미지 업로드에 실패하였습니다');
        throw e;
      }
    }
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    if (data !== null && data !== undefined && data !== '') {
      const blocksFromHTML = htmlToDraft(data);

      // 직접 contentBlocks를 사용하여 ContentState 생성
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      // // 에디터 상태 설정
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    } else {
      if (!data) {
        // 데이터가 없을 때 빈 에디터 상태 생성
        setEditorState(EditorState.createEmpty());
      }
    }
  }, [data]);

  /* ===== RENDER ===== */
  return (
    <div className="editor-container">
      <div className="editor-content">
        <Editor
          placeholder={placeholder}
          editorState={editorState}
          onEditorStateChange={handleUpdateTextDescription}
          toolbar={{
            image: {
              uploadCallback: handleImageUpload,
              previewImage: true,
              defaultSize: {
                width: '100%',
                height: 'auto',
              },
            },
            embedded: {
              defaultSize: {
                width: '100%',
                height: 500,
              },
            },
            ...toolbar,
          }}
          localization={{ locale: 'ko' }}
          editorStyle={{
            height: '600px',
            width: '100%',
            border: '1px solid #BDBDBD',
            padding: '8px 20px',
            ...editorStyle,
          }}
          hashtag={{}}
          readOnly={readOnly}
          customBlockRenderFunc={imageBlockRenderer}
        />
      </div>
    </div>
  );
};

TextEditor.defaultProps = {
  data: null,
  placeholder: '내용을 입력해주세요',
  readOnly: false,
  setHtmlString: () => {},
  editorStyle: {},
  toolbar: {},
};

export default TextEditor;
