/**
 *
 *
 *
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import {
  Title,
  Content,
  Col,
  Row,
  Card,
  Tabs,
  PlayerController,
  Button,
} from 'components';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Empty, Collapse } from 'antd';
import { encrypt, decrypt } from 'utils/Crypto';
import { DOMAIN_URL, getCookie, removeCookie } from 'utils';
import { LectureApi, LectureOfUserApi } from 'api';
import DOMPurify from 'dompurify';
import MessageAlert from 'utils/MessageAlert';
import moment from 'moment';
const { Panel } = Collapse;

function LectureClass(props) {
  /* ===== Props ===== */
  const { screenSize } = props;

  /* ===== Mutate ===== */
  /**
   * progress update
   * --
   */
  const UpdateProgress = LectureOfUserApi.UpdateLectureOfUser({
    onSuccess: (data) => {
      try {
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /* ===== Router ===== */
  const navigate = useNavigate();
  const { lectureId, sectionId } = useParams();
  const [searchParams] = useSearchParams();
  const paramsUserId = searchParams.get('user');
  const paramsToken = searchParams.get('token');

  /* ===== DATA ===== */
  const user_id = parseInt(getCookie('USER'));

  /* ===== State ===== */
  // const loginWho = useRecoilValue(loginUser);
  const [isPurchase, setIsPurchase] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [data, setData] = useState({});
  const [curriculum, setCurriculum] = useState([]);
  const [isRefetch, setIsRefetch] = useState(false);
  const { data: lecture, refetch } = LectureApi.GetLecture(
    parseInt(decrypt(lectureId)),
    {
      chapter: true,
      section: true,
    },
    {
      enabled: !!lectureId,
    }
  );
  const [current, setCurrent] = useState(0);
  const [selectedSection, setSelectedSection] = useState(null);
  const playerRef = useRef(null);
  const [volume, setVolume] = useState(0.7);
  const [playing, setPlaying] = useState(false);
  const [playProgress, setPlayProgress] = useState({});
  const [muted, setMuted] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [listenCount, setListenCount] = useState(0);
  const [todayListen, setTodayListen] = useState(0);
  // const { data: section } = SectionApi.GetSection(parseInt(decrypt(sectionId)));
  const [sectionList, setSectionList] = useState([]);

  // console.log('section', section?.data?.video_url);
  const { data: lectureOfUsers, refetch: lectureOfUsersRefetch } =
    LectureOfUserApi.GetLectureOfUsers({
      lecture_id: parseInt(decrypt(lectureId)),
    });
  const NewlecureOfUsers = lectureOfUsers?.data?.filter(
    (item) => item.user_id === user_id
  );
  //NOTE: 2024/03/28 추가 수강 횟수 카운트
  const { data: userOflectures } = LectureOfUserApi.GetLectureOfUsers({
    user_id: user_id,
  });

  /* ===== Functions ===== */
  /**
   * 수강 라우팅 함수
   * --
   */
  const handleMoveSection = (chapter_id, value, preview) => {
    // 이미 수강중인 강의일 경우
    //NOTE: 2024/03/28 추가 수강 횟수 카운트
    const ListenChapterList = todayListen && todayListen?.chapter;
    const ListenSectionList = todayListen && todayListen?.section;
    if (listenCount > 7) {
      if (
        !ListenChapterList.includes(chapter_id) ||
        !ListenSectionList.includes(value)
      ) {
        window.alert('하루 수강 횟수를 초과했습니다. 내일 진행 해주세요.');
        return;
      }
    }

    // 이미 수강중인 강의를 클릭하였을 경우
    if (sectionId === encrypt(value)) return;

    // 미리보기 강의인지 체크
    if (preview === 'Y') {
      if (!isPurchase) {
        return navigate(
          `/lectureclass/${encodeURIComponent(lectureId)}/${encodeURIComponent(
            encrypt(value)
          )}`
        );
      }
    } else {
      if (!isPurchase) {
        window.alert('구매 후 이용해주세요!');
        return;
      }
    }

    const lecture_of_user_id =
      NewlecureOfUsers && NewlecureOfUsers[0].lecture_of_user_id;
    const progress = NewlecureOfUsers && NewlecureOfUsers[0]?.progress;

    // NOTE: 이전 강의 수강했는지 체크
    const isPrev = sectionList?.find(
      (item) => item?.section_id === value
    )?.prev_section_id;

    if (isPrev) {
      const isSugang = progress?.find((item) => item?.section_id === isPrev);

      if (!isSugang || isSugang?.status !== 'DONE') {
        window.alert('이전 강의를 수강 후 진행해주세요.');
        return;
      }
    }

    const findData = progress?.find((item) => item?.section_id === value);

    if (findData) {
      if (!findData?.status) {
        const newData = {
          ...findData,
          created_at: new Date(),
          complete_at: null,
          status: 'ING',
        };

        const index = progress?.findIndex((item) => item?.section_id === value);

        if (index !== -1) {
          const arr = [...progress];
          arr[index] = newData;

          try {
            UpdateProgress.mutate({
              lecture_of_user_id: lecture_of_user_id,
              progress: arr,
            });
          } catch (e) {
            throw e;
          }
        }
      }

      // 수강 완료했던 강의일 경우
      // TODO: 수강 횟수에서 카운트 안되도록 기능 추가
      navigate(
        `/lectureclass/${encodeURIComponent(lectureId)}/${encodeURIComponent(
          encrypt(value)
        )}`
      );
    } else {
      // 처음 수강하는 강의일 경우
      const progressArr = progress
        ? [
            ...progress,
            {
              key: progress?.length + 1,
              chapter_id: chapter_id,
              section_id: value,
              created_at: new Date(),
              complete_at: null,
              status: 'ING',
            },
          ]
        : [
            {
              key: 1,
              chapter_id: chapter_id,
              section_id: value,
              created_at: new Date(),
              complete_at: null,
              status: 'ING',
            },
          ];

      try {
        UpdateProgress.mutate({
          lecture_of_user_id: lecture_of_user_id,
          progress: progressArr,
        });
      } catch (e) {
        throw e;
      }

      navigate(
        `/lectureclass/${encodeURIComponent(lectureId)}/${encodeURIComponent(
          encrypt(value)
        )}`
      );
      // TODO: 수강 횟수 카운트
    }
  };

  /**
   * 구매고객인지 체크
   * --
   */
  const handleCheckUser = useCallback(() => {
    if (sectionId) {
      const isPreview = data?.sections
        ?.filter((item) => item?.section_id === parseInt(decrypt(sectionId)))
        ?.pop();

      if (isPreview && isPreview?.preview !== 'Y') {
        if (!isPurchase) {
          window.alert('잘못된 접근입니다!');
          navigate('/');
        }
      }
    }
  }, [isPurchase, navigate, sectionId, data.sections]);

  /**
   * 게시판 팝업
   * --
   */
  const handlePopup = () => {
    const domain = DOMAIN_URL;

    window.open(
      `${domain}/#/popup/board?lecture=${encodeURIComponent(
        lectureId
      )}&type=inquiry`,
      '팝업',
      'width=1500, height=850'
    );
  };

  // console.log('playProgress', playProgress);

  /* ===== Hooks ===== */
  /**
   * 선택 섹션 정보 정제
   * --
   */
  useEffect(() => {
    if (sectionId) {
      const decode = decodeURIComponent(sectionId);
      const id = parseInt(decrypt(decode));

      curriculum?.forEach((item) => {
        const findData = item?.sections
          ?.filter((subitem) => subitem?.section_id === id)
          ?.pop();

        if (findData) {
          setSelectedSection(findData);
        }
      });
    }
  }, [sectionId, curriculum]);

  /**
   * 강의 데이터 Refetch
   * --
   */
  useEffect(() => {
    refetch();
  }, [lectureId, refetch]);

  /**
   * 강의 수강 데이터 Refetch
   * --
   */
  useEffect(() => {
    lectureOfUsersRefetch();
  }, [lectureId, lectureOfUsersRefetch]);

  /**
   * 강의 id에 따른 강의 데이터 추출
   * --
   */
  useEffect(() => {
    const newData = {
      lecture: lecture?.data?.lecture,
      chapters: lecture?.data?.chapter,
      sections: lecture?.data?.section,
      users: lectureOfUsers?.data,
    };

    setData(newData);
  }, [lecture, lectureOfUsers]);

  /**
   * 섹션 리스트 데이터 정제
   * --
   */
  useEffect(() => {
    if (data) {
      const { chapters, sections } = data;

      const c = chapters?.sort((a, b) => a?.order - b?.order);
      const curriculum = c?.map((item) => ({
        ...item,
        section: sections
          ?.filter((subitem) => item?.chapter_id === subitem?.chapter_id)
          ?.sort((a, b) => a?.order - b?.order),
      }));

      const sec = curriculum?.reduce((acc, cur) => {
        const arr = [...acc, ...cur.section];
        acc = arr;

        return acc;
      }, []);

      const list = sec?.map((item, index) => ({
        ...item,
        prev_section_id: index === 0 ? null : sec[index - 1]?.section_id,
      }));

      setSectionList(list);
    }
  }, [data]);

  /**
   * 강의 구매 고객인지 체크
   * --
   */
  useEffect(() => {
    const hasValueOne = data?.users?.some(
      (user) => parseInt(user.user_id) === parseInt(user_id)
    );

    if (typeof hasValueOne === 'boolean') {
      setIsPurchase(hasValueOne);
      setIsCheck(true);
    }
  }, [user_id, data?.users]);

  /**
   * 커리큘럼 배열 생성
   * --
   */
  useEffect(() => {
    // 차트, 섹션 데이터 오름차순 정렬
    const newChapters = data?.chapters?.sort((a, b) => {
      return a.order - b.order;
    });
    const newSections = data?.sections?.sort((a, b) => {
      return a.order - b.order;
    });

    // 유저 Progress
    const progress = NewlecureOfUsers?.pop()?.progress;

    const arr = newChapters?.map((item, index) => ({
      ...item,
      chapter_title: `${index + 1} ${item?.chapter_name}`,
      sections: newSections
        ?.filter((subitem) => item.chapter_id === subitem.chapter_id)
        ?.map((subitem, subIndex) => ({
          ...subitem,
          section_title: `${index + 1}-${subIndex + 1} ${
            subitem?.section_name
          }`,
          video: subitem?.video_url?.map((v) => v?.url),
          status: progress
            ? progress?.filter((p) => p?.section_id === subitem?.section_id)[0]
                ?.status
            : null,
        })),
    }));

    setCurriculum(arr);
    // eslint-disable-next-line
  }, [data]);

  /**
   * 비구매고객 처리
   * --
   */
  useEffect(() => {
    if (isCheck && !isPurchase) handleCheckUser();
  }, [isPurchase, navigate, handleCheckUser, isCheck]);

  /**
   * 쿠키 저장
   * --
   */
  useEffect(() => {
    if (paramsUserId && paramsToken) {
      const time = 8 * 60 * 60 * 1000;

      removeCookie('USER', decodeURIComponent(paramsUserId), time);
      removeCookie(
        'BRAINHEAL_ACCESS_TOKEN',
        decodeURIComponent(paramsToken),
        time
      );

      const href = window.location.href.split('?')[0];
      const path = href.split('/#')[1];

      setIsRefetch(true);
      navigate(path);
    }
  }, [paramsUserId, paramsToken, navigate]);

  useEffect(() => {
    if (isRefetch) {
      window.location.reload();

      setIsRefetch(false);
    }
  }, [isRefetch]);

  // useEffect(() => {
  //   if (token && user_id) {
  //     window.alert('실행');
  //     window.location.reload();
  //   }
  // }, [token, user_id, refetch, lectureOfUsersRefetch]);

  /**
   * 수강 진행율 업데이트
   * --
   */
  useEffect(() => {
    if (current + 1 === selectedSection?.video?.length && isPurchase) {
      const isHtml = selectedSection?.video[current]?.includes('.html');
      if (isHtml) {
        if (!isUpdate) {
          const lecture_of_user_id =
            NewlecureOfUsers && NewlecureOfUsers[0].lecture_of_user_id;
          const progress = NewlecureOfUsers && NewlecureOfUsers[0]?.progress;

          const findData = progress?.find(
            (item) => item?.section_id === selectedSection?.section_id
          );
          const findIndex = progress?.findIndex(
            (item) => item?.section_id === selectedSection?.section_id
          );

          let progressArr = progress;
          if (findIndex !== -1 && findData?.status === 'ING') {
            progressArr[findIndex] = {
              ...findData,
              complete_at: new Date(),
              status: 'DONE',
            };

            try {
              UpdateProgress.mutate({
                lecture_of_user_id: lecture_of_user_id,
                progress: progressArr,
              });
              setIsUpdate(true);
            } catch (e) {
              throw e;
            }
          } else {
            return;
          }
        }
      } else {
        const { duration, playedSeconds } = playProgress;
        const isCheck = (playedSeconds / duration) * 100;

        // 마지막 강의 80% 이상 수강 시 수강 완료 업데이트
        if (isCheck >= 80 && !isUpdate) {
          const lecture_of_user_id =
            NewlecureOfUsers && NewlecureOfUsers[0].lecture_of_user_id;
          const progress = NewlecureOfUsers && NewlecureOfUsers[0]?.progress;

          const findData = progress?.find(
            (item) => item?.section_id === selectedSection?.section_id
          );
          const findIndex = progress?.findIndex(
            (item) => item?.section_id === selectedSection?.section_id
          );

          let progressArr = progress;
          if (findIndex !== -1 && findData?.status === 'ING') {
            progressArr[findIndex] = {
              ...findData,
              complete_at: new Date(),
              status: 'DONE',
            };

            try {
              UpdateProgress.mutate({
                lecture_of_user_id: lecture_of_user_id,
                progress: progressArr,
              });
              setIsUpdate(true);
            } catch (e) {
              throw e;
            }
          } else {
            return;
          }
        }
      }
    }
  }, [
    current,
    selectedSection,
    playProgress,
    isUpdate,
    NewlecureOfUsers,
    UpdateProgress,
    isPurchase,
  ]);
  // console.log('selectedSection', selectedSection);
  // console.log('isPurchase', isPurchase);

  /**
   * 수강 진행율 업데이트 무한 렌더링 방지
   * --
   */
  useEffect(() => {
    if (sectionId && isPurchase) {
      const progress = NewlecureOfUsers && NewlecureOfUsers[0]?.progress;
      const id = parseInt(decrypt(sectionId));

      const findData = progress?.find((item) => item?.section_id === id);
      if (findData?.status === 'DONE') setIsUpdate(true);
      else setIsUpdate(false);
    }
  }, [NewlecureOfUsers, sectionId, isPurchase]);

  useEffect(() => {
    setCurrent(0);
    setPlayProgress({});
  }, [sectionId]);
  useEffect(() => {
    setPlayProgress({});
  }, [current]);

  useEffect(() => {}, []);

  /**
   * 수강 횟수 제한 추가
   * --
   */
  //NOTE: 2024/03/28 추가 수강 횟수 카운트
  useEffect(() => {
    const data = userOflectures?.data;
    let count = 0;
    const chapterArr = [];
    const sectionArr = [];
    const today = moment().tz('Asia/Seoul');
    data?.forEach((item) => {
      item?.progress?.forEach((subitem) => {
        chapterArr.push(subitem?.chapter_id);
        sectionArr.push(subitem?.section_id);

        if (subitem?.created_at) {
          const created_at = moment(subitem?.created_at);
          if (created_at.isSame(today, 'day')) {
            count++;
          }
        }
      });
    });
    setListenCount(count);
    setTodayListen({ chapter: chapterArr, section: sectionArr });
    // userOflectures
  }, [userOflectures]);

  /* ===== Render ===== */
  return (
    <>
      <Content
        maxWidth={'100%'}
        padding={0}
        height={screenSize >= 1200 ? '100vh' : '100%'}
      >
        <Row>
          <Col x={24} xl={18}>
            <Content maxWidth={'100%'} padding={0}>
              {selectedSection ? (
                <div>
                  <div
                    style={{
                      ...styles.titleContainer,
                      height: screenSize >= 1200 ? 80 : 60,
                    }}
                  >
                    <Title>{selectedSection?.section_title}</Title>
                  </div>
                  {selectedSection?.video[current]?.includes('.html') ? (
                    <div>
                      <iframe
                        title={selectedSection?.section_name}
                        src={selectedSection?.video[current]}
                        style={{
                          width: '100%',
                          height:
                            screenSize >= 1200 ? 'calc(100vh - 80px)' : '390px',
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <ReactPlayer
                        ref={playerRef}
                        muted={muted}
                        key={`url_${current}`}
                        url={selectedSection?.video[current]}
                        width="100%"
                        height={
                          screenSize >= 1200 ? 'calc(100vh - 160px)' : '100%'
                        }
                        style={{
                          background: 'black',
                          pointerEvents: 'none',
                        }}
                        playing={playing}
                        onDuration={(d) =>
                          setPlayProgress((prev) => ({
                            ...prev,
                            duration: d,
                          }))
                        }
                        onProgress={(d) =>
                          setPlayProgress((prev) => ({
                            ...prev,
                            ...d,
                          }))
                        }
                      />

                      <PlayerController
                        playProgress={playProgress}
                        setPlayProgress={setPlayProgress}
                        playing={playing}
                        setPlaying={setPlaying}
                        playerRef={playerRef}
                        muted={muted}
                        setMuted={setMuted}
                        volume={volume}
                        setVolume={setVolume}
                        current={current}
                        setCurrent={setCurrent}
                        selectedSection={selectedSection}
                        screenSize={screenSize}
                        listenCount={listenCount}
                      />
                    </>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    minHeight: screenSize >= 768 ? '100vh' : 300,
                  }}
                >
                  <Title size={3} style={{ marginBottom: '20px' }}>
                    챕터를 선택해주세요.
                  </Title>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </Content>
          </Col>
          <Col x={24} xl={6}>
            <Content maxWidth={'100%'} padding={0}>
              <Card
                style={{
                  minHeight: screenSize >= 768 ? '100vh' : 350,
                  maxHeight: screenSize >= 768 ? '100vh' : 'auto',
                  cursor: 'default',
                  overflowY: 'scroll',
                  background: '#F2F2F2',
                }}
              >
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  backgroundColor={'none'}
                >
                  <Title>{data?.lecture?.title}</Title>
                  <Button
                    layoutStyle={{ margin: 0 }}
                    onClick={() => handlePopup()}
                  >
                    게시판
                  </Button>
                </Content>
                <Tabs
                  defaultActiveKey="1"
                  items={[
                    {
                      key: '1',
                      label: `커리큘럼`,
                      children: (
                        <>
                          {curriculum ? (
                            curriculum?.map((item) => (
                              <Collapse
                                key={`chapter_${item?.chapter_id}`}
                                expandIconPosition="end"
                                style={{ borderRadius: '4px' }}
                              >
                                <Panel
                                  header={`CHAPTER.${item?.chapter_title}`}
                                  style={{
                                    padding: '20px',
                                    fontSize: '20px',
                                    background: '#FFFFFF',
                                    marginBottom: '20px',
                                    borderRadius: '4px',
                                  }}
                                >
                                  {item?.sections?.map((subitem) => (
                                    <p
                                      key={`section_${subitem?.section_id}`}
                                      onClick={() => {
                                        handleMoveSection(
                                          item?.chapter_id,
                                          subitem?.section_id,
                                          subitem?.preview
                                        );
                                      }}
                                      style={{
                                        fontSize: '16px',
                                        marginTop: '10px',
                                        padding: '5px 15px',
                                        cursor: 'pointer',
                                        background:
                                          parseInt(decrypt(sectionId)) ===
                                          subitem?.section_id
                                            ? '#E3A4AF'
                                            : '#FFFFFF',
                                        color:
                                          parseInt(decrypt(sectionId)) ===
                                          subitem?.section_id
                                            ? '#FFFFFF'
                                            : '#000000',
                                        borderRadius: '4px',
                                        position: 'relative',
                                        top: 0,
                                        left: 0,
                                      }}
                                    >
                                      {`SECTION.${subitem?.section_title}`}
                                      {subitem?.preview === 'Y' &&
                                      !isPurchase ? (
                                        <span
                                          style={{
                                            display: 'inline-block',
                                            padding: '3px 5px',
                                            background: '#E3A4AF',
                                            borderRadius: '3px',
                                            color: '#FFFFFF',
                                            fontSize: '8px',
                                          }}
                                        >
                                          미리보기
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: 0,
                                            transform: 'translate(0, -50%)',
                                            display: 'inline-block',
                                            padding: '3px 5px',
                                            background:
                                              subitem?.status === 'ING'
                                                ? '#03A9F4'
                                                : subitem?.status === 'DONE'
                                                ? '#2AC769'
                                                : '#BDBDBD',
                                            borderRadius: '3px',
                                            color: '#FFFFFF',
                                            fontSize: '8px',
                                          }}
                                        >
                                          {subitem?.status === 'ING'
                                            ? '수강중'
                                            : subitem?.status === 'DONE'
                                            ? '수강완료'
                                            : '수강전'}
                                        </span>
                                      )}
                                    </p>
                                  ))}
                                </Panel>
                              </Collapse>
                            ))
                          ) : (
                            <div>
                              <p>등록된 커리큘럼이 없습니다</p>
                            </div>
                          )}
                        </>
                      ),
                    },
                    {
                      key: '2',
                      label: `강의소개`,
                      children: (
                        <div
                          style={{
                            background: '#FFFFFF',
                            padding: 30,
                            borderRadius: 10,
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data?.lecture?.content
                              ),
                            }}
                          ></div>
                        </div>
                      ),
                    },
                  ]}
                />
              </Card>
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
}

LectureClass.defaultProps = {};

const styles = {
  titleContainer: {
    padding: '0px 15px',
    borderBottom: '1px solid #EEE',
    display: 'flex',
    alignItems: 'center',
  },
};

export default LectureClass;
