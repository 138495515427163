import React, { useState, useEffect } from 'react';
import { Content, Row, Col, Title, ModalLayout, Button } from 'components';
import { Tree, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { CategoryApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { Empty } from 'antd';

const MajorPresenter = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== Mutate ===== */
  const createCategory = CategoryApi.CreateCategory({
    onSuccess: async (result) => {
      const { status, data } = result;
      if (status === 200 && data?.type === 'M') {
        handleSuccess(data);
      }
    },
  });
  const updateCategory = CategoryApi.UpdateCategory();
  const deleteCategory = CategoryApi.DeleteCategory();

  /* ===== State ===== */
  const [treeData, setTreeData] = useState([]);
  const [selectData, setSelectData] = useState({});
  // const [parentCategories, setParentCategories] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [modalData, setModalData] = useState({
    category_name: undefined,
    parents_id: undefined,
  });
  /* ===== Hooks ===== */
  useEffect(() => {
    const parents = data?.filter((item) => item?.type === 'M');
    // setParentCategories(parents);
    const children = data?.filter((item) => item?.type === 'D');
    const newCategories = parents?.map((item) => ({
      ...item,
      children: children?.filter(
        (subitem) => item?.category_id === subitem?.parents_id
      ),
    }));

    // const tree = newCategories?.map((item) => ({
    //   title: (
    //     <p style={{ fontSize: '14px', fontWeight: 600 }}>
    //       {item?.category_name}
    //     </p>
    //   ),
    //   key: item?.category_id,
    //   children: item?.children?.map((subitem) => ({
    //     title: subitem?.category_name,
    //     key: subitem?.category_id,
    //   })),
    // }));
    //서브아이템
    const tree = newCategories?.map((item) => ({
      title: (
        <p style={{ fontSize: '14px', fontWeight: 600 }}>
          {item?.children?.map((subitem) => subitem?.category_name)}
        </p>
      ),
      key: item?.children?.map((subitem) => subitem?.category_id),
    }));
    setTreeData(tree);
  }, [data]);

  useEffect(() => {
    if (!isOpened) {
      setModalData({
        category_name: undefined,
        parents_id: undefined,
      });
    }
  }, [isOpened]);

  /* ===== Functions ===== */
  const handleSuccess = async (data) => {
    try {
      createCategory.mutate({
        type: 'D',
        parents_id: data?.category_id,
        category_name: data?.category_name,
      });
    } catch (err) {
      MessageAlert.error('상세 분야 생성 실패');
      console.log('상세 분야 생성 실패', err);
    }
  };
  /**
   * 트리 Item 클릭 이벤트
   * --
   * @param {*} key
   */
  const handleTreeSelect = (key) => {
    const id = key[0] && key[0][0];
    const selected = data?.filter((item) => item?.category_id === id)[0];
    // const selected = data?.filter((item) => item?.category_id === id)[0];
    setSelectData(selected);
  };
  /**
   * 카테고리 추가 모달
   * --
   * @param {*} id
   */
  const handleAddCategoryModal = (id = 0, open) => {
    const d = { ...modalData };
    if (id !== 0) {
      d['parents_id'] = id;
    } else {
      d['parents_id'] = undefined;
    }
    setModalData(d);
    setIsOpened(!open);
  };

  /**
   * 카테고리 데이터 추가/수정/삭제 함수
   * --
   * @param {*} type
   */
  const handleCategory = (type) => {
    if (type === 'C') {
      if (!modalData?.category_name || !modalData?.category_name === '') {
        MessageAlert.warning('카테고리명을 입력해주세요');
        return;
      }
      const confirm = window.confirm('카테고리를 추가하시겠습니까?');

      if (confirm) {
        try {
          const list = data?.filter(
            (item) => item?.parents_id === modalData?.parents_id
          );
          const dataCheck = list?.find(
            (item) => item?.category_name === modalData?.category_name
          );
          if (dataCheck) {
            MessageAlert.warning('같은 카테고리 이름을 가진 값이 존재합니다');
            return;
          }
          createCategory.mutate({
            ...modalData,
            type: modalData?.parents_id ? 'D' : 'M',
          });
          MessageAlert.success('카테고리가 생성되었습니다');
          setIsOpened(false);
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('카테고리 생성에 실패하였습니다');
          throw e;
        }
      }
    } else if (type === 'U') {
      if (!selectData?.category_name || !selectData?.category_name === '') {
        MessageAlert.warning('카테고리명을 입력해주세요');
        return;
      }

      const confirm = window.confirm('카테고리를 수정하시겠습니까?');

      if (confirm) {
        const list = data?.filter(
          (item) => item?.parents_id === modalData?.parents_id
        );
        const dataCheck = list?.find(
          (item) => item?.category_name === modalData?.category_name
        );
        if (dataCheck) {
          MessageAlert.warning('같은 카테고리 이름을 가진 값이 존재합니다');
          return;
        }
        try {
          updateCategory.mutate({
            ...selectData,
          });
          updateCategory.mutate({
            category_id: selectData?.parents_id,
            category_name: selectData?.category_name,
          });
          MessageAlert.success('카테고리가 정상적으로 수정되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('카테고리 수정에 실패하였습니다');
          throw e;
        }
      }
    } else if (type === 'D') {
      const confirm = window.confirm(
        '카테고리를 삭제하시겠습니까?\n삭제시 관련된 데이터가 손상될 수 있습니다'
      );

      if (confirm) {
        try {
          // deleteCategory.mutate({
          //   category_id: selectData?.category_id,
          // });
          deleteCategory.mutate({
            category_id: selectData?.parents_id,
          });

          setSelectData({});
          MessageAlert.success('카테고리가 정상적으로 삭제되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('카테고리 삭제에 실패하였습니다');
          throw e;
        }
      }
    }
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} w
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (w, t, v, callback = null) => {
    if (w === 'modal') {
      let newData = { ...modalData };

      newData[t] = v;
      const d = {
        ...newData,
      };
      setModalData(d);

      if (callback) callback(v);
    } else {
      let newData = { ...selectData };

      newData[t] = v;
      const d = {
        ...newData,
      };
      setSelectData(d);

      if (callback) callback(v);
    }
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={24}>
            <Title size={2} style={{ marginBottom: 20 }}>
              분야 데이터 관리
            </Title>
            <Row>
              <Col x={8} padding={8}>
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{ marginBottom: '12px', textAlign: 'right' }}
                  backgroundColor={'none'}
                >
                  <Button
                    layoutStyle={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    style={{ padding: '10px 11px' }}
                    onClick={() => handleAddCategoryModal(0, isOpened)}
                  >
                    {'추가'}
                  </Button>
                </Content>
                <Content
                  maxWidth={'100%'}
                  padding={24}
                  className={'admin_container'}
                  maxHeight={'80vh'}
                  style={{ overflowY: 'scroll' }}
                >
                  {treeData && treeData?.length > 0 ? (
                    <Tree
                      switcherIcon={<DownOutlined />}
                      onSelect={handleTreeSelect}
                      treeData={treeData}
                      expandAction='click'
                    />
                  ) : (
                    <Content
                      maxWidth={'100%'}
                      height={'100%'}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Content>
                  )}
                </Content>
              </Col>
              <Col x={16} padding={8} style={{ marginTop: '55px' }}>
                {/* <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{ marginBottom: '12px', textAlign: 'right' }}
                  backgroundColor={'none'}
                >
                  {selectData &&
                  selectData?.category_name &&
                  !selectData?.parents_id ? (
                    <Button
                      layoutStyle={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      style={{ padding: '10px 11px' }}
                      onClick={() =>
                        handleAddCategoryModal(
                          selectData?.category_id,
                          isOpened
                        )
                      }
                    >
                      {'하위 추가'}
                    </Button>
                  ) : (
                    <Button
                      layoutStyle={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      style={{ padding: '10px 11px', visibility: 'hidden' }}
                      onClick={() =>
                        handleAddCategoryModal(
                          selectData?.category_id,
                          isOpened
                        )
                      }
                    >
                      {'하위 추가'}
                    </Button>
                  )}
                </Content> */}
                {selectData && Object.keys(selectData)?.length > 0 ? (
                  <Content
                    maxWidth={'100%'}
                    padding={24}
                    height={'80vh'}
                    className={'admin_container'}
                  >
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      backgroundColor={'#EEEEEE'}
                      style={{ borderRadius: '4px' }}
                    >
                      {/* 상위 메뉴 */}
                      {/* <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: '#EEEEEE',
                          padding: '10px',
                          borderRadius: '4px',
                        }}
                      >
                        <Col
                          x={6}
                          style={{
                            background: '#D9D9D9',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                          }}
                        >
                          <Title
                            style={{
                              fontSize: '17px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                              fontWeight: 700,
                            }}
                          >
                            상위메뉴
                          </Title>
                        </Col>
                        <Col x={1}></Col>
                        <Col
                          x={17}
                          style={{
                            background: '#FFFFFF',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                            padding: '0 20px',
                          }}
                        >
                          {selectData?.parents_id ? (
                            <Select
                              size='large'
                              style={{ width: '100%' }}
                              value={selectData?.parents_id}
                              options={parentCategories?.map((item) => ({
                                label: item?.category_name,
                                value: item?.category_id,
                              }))}
                              onChange={(e) =>
                                handleChange('data', 'parents_id', e)
                              }
                              // bordered={false}
                            />
                          ) : (
                            <Title
                              style={{
                                fontSize: '17px',
                                lineHeight: '38px',
                                letterSpacing: '0.02em',
                                padding: '0 11px',
                              }}
                            >
                              -
                            </Title>
                          )}
                        </Col>
                      </Row> */}
                      {/* 카테고리 명 */}
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: '#EEEEEE',
                          padding: '10px',
                        }}
                      >
                        <Col
                          x={6}
                          style={{
                            background: '#D9D9D9',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                          }}
                        >
                          <Title
                            style={{
                              fontSize: '17px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                              fontWeight: 700,
                            }}
                          >
                            카테고리명
                          </Title>
                        </Col>
                        <Col x={1}></Col>
                        <Col
                          x={17}
                          style={{
                            background: '#FFFFFF',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                            padding: '0 20px',
                          }}
                        >
                          {selectData && selectData?.category_id ? (
                            <Input
                              size='large'
                              placeholder='카테고리명을 입력해주세요'
                              value={selectData?.category_name}
                              onChange={(e) =>
                                handleChange(
                                  'data',
                                  'category_name',
                                  e.target.value
                                )
                              }
                              // bordered={false}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Content>
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{ marginTop: '12px', textAlign: 'right' }}
                    >
                      {selectData && selectData?.category_id && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            layoutStyle={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              margin: '0 12px 0 0',
                            }}
                            style={{ padding: '10px 11px' }}
                            onClick={() => handleCategory('U')}
                          >
                            {'수정'}
                          </Button>
                          <Button
                            layoutStyle={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              margin: 0,
                            }}
                            style={{ padding: '10px 11px' }}
                            onClick={() => handleCategory('D')}
                          >
                            {'삭제'}
                          </Button>
                        </div>
                      )}
                    </Content>
                  </Content>
                ) : (
                  <Content
                    maxWidth={'100%'}
                    padding={24}
                    height={'80vh'}
                    className={'admin_container'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Title>카테고리를 선택해주세요</Title>
                  </Content>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      {/* 수강중인 강의 상세 모달 */}
      <ModalLayout
        title={'분야 추가'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: 0 }}
        footer={
          screenSize < 768 ? (
            <Content padding={0} maxWidth={'100%'} key={'modalBottomButton'}>
              <Button width={'100%'} onClick={() => handleCategory('C')}>
                추가
              </Button>
            </Content>
          ) : null
        }
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Row
                style={{ display: 'flex', alignItems: 'center', padding: 20 }}
              >
                <Col x={6}>
                  <Title>분야명</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.category_name}
                    placeholder='분야명을 입력해주세요'
                    onChange={(e) =>
                      handleChange('modal', 'category_name', e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col x={24}>
              <Button
                style={{
                  width: '100%',
                  height: '50px',
                  borderRadius: 0,
                  marginTop: '30px',
                }}
                onClick={() => handleCategory('C')}
              >
                추가
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

MajorPresenter.defaultProps = {};

export default MajorPresenter;
