import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AppContainer } from 'app';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';

/* Styles */
import 'antd/dist/antd.min.css';
import 'styles/animation.css';
import 'styles/index.css';
import 'styles/App.css';
import 'styles/style.css';
import 'styles/antdImage.css';

/* Initial */
React.PropTypes = PropTypes;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      retry: 0, // 요청 실패 시 재요청 횟수
      // refetch 여부
      refetchOnWindowFocus: true, // 쿼리의 새 인스턴스가 마운트 될 때
      refetchOnMount: true, // window가 다시 포커스 될 때
      refetchOnReconnect: true, // 네트워크가 끊어졌다 다시 연결될 때
      onError: (error) => {
        console.log('Error !!', error);
      },
      // select: (data) => data.data, // 데이터 형식
    },
    mutations: {
      onError: (error) => {
        console.log('Error !!', error);
      },
    },
  },
});

/* View */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      {/* devtools */}
      {process.env.REACT_APP_DEPLOY_TYPE === 'local' && (
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      )}
      <AppContainer />
    </QueryClientProvider>
  </RecoilRoot>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
