import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Content, Row, Col, Title } from 'components';
import { List, Pagination } from 'antd';
import MessageAlert from 'utils/MessageAlert';
import { EmailApi } from 'api';

const SmsLogPresenter = (data) => {
  /* ===== PROPS ===== */
  /* ===== ROUTE ===== */
  const navigatoin = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  /* ===== MUTATE ===== */

  /* ===== STATE ===== */
  const [smsLogList, setSmsLogList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const show_count = 20;

  /* ===== FUNCTION ===== */
  /**
   * 페이지 네이션
   * ---
   */
  const handleChangePage = (page) => {
    navigatoin(`/admin/sms-log?page=${page}`);
  };

  /* ===== RENDER ===== */
  /**
   * 이메일 로그 데이터 조회
   * ---
   */
  useEffect(() => {
    const getEmailList = async () => {
      try {
        const { status, data } = await EmailApi.ListSms({
          type: 'list',
        });
        console.log('data', data);
        if (status === 200) {
          const smsListArr = [];
          for (let list of data) {
            const result = await EmailApi.ListSms({
              type: 'sms_list',
              mid: list.mid,
            });
            result.data.length &&
              smsListArr.push({ ...result.data[0], ...list });
          }
          console.log('smsListArr', smsListArr);
          setSmsLogList({ count: smsListArr?.length, rows: smsListArr });
        } else {
          MessageAlert.warning('데이터를 호출하는데 실패하였습니다!');
        }
      } catch (e) {
        return;
      }
    };
    getEmailList();
  }, [currentPage]); // currentPage가 변경될 때마다 데이터를 새로 가져오도록 설정

  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Col x={24}>
          <Title size={2} style={{ marginBottom: 20 }}>
            문자 관리
          </Title>
          <Content
            maxWidth={'100%'}
            padding={'24px'}
            className={'admin_container'}
          >
            <Row>
              <Col
                x={24}
                style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 20 }}
              >
                <Row>
                  <Col x={1}>No</Col>
                  <Col x={2}>유형</Col>
                  <Col x={4}>발송일</Col>
                  <Col x={2}>수신자</Col>
                  <Col x={3}>수신번호</Col>
                  <Col x={10}>내용요약</Col>
                  <Col x={2}>상태</Col>
                </Row>
              </Col>
              <Col x={24} style={{ paddingRight: 10, paddingTop: 20 }}>
                <List
                  size="large"
                  footer={
                    <Row
                      key={`footer`}
                      style={{
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col x={12}>
                        총 : {smsLogList?.count}개 / {currentPage}페이지
                      </Col>
                      <Col x={12} style={{ textAlign: 'end' }}>
                        <Pagination
                          current={currentPage}
                          onChange={handleChangePage}
                          total={smsLogList?.count}
                          pageSize={show_count}
                        />
                      </Col>
                    </Row>
                  }
                  bordered
                  position="true"
                  dataSource={smsLogList?.rows}
                  renderItem={(item, idx) => (
                    <div
                      key={`${idx}_` + item?.email_data?.email_log_id}
                      style={{ padding: 5 }}
                    >
                      <Row style={{ alignItems: 'center' }}>
                        <Col x={24}>
                          <Row>
                            <Col x={1}>{idx + 1}</Col>
                            <Col x={2}>{item?.type}</Col>
                            <Col x={4}>{item?.send_date}</Col>
                            <Col x={2}>{item?.msg?.split('님')[0]}</Col>
                            <Col x={3}>{item?.receiver}</Col>
                            <Col x={10}>{item?.msg}</Col>
                            <Col x={2}>{item?.sms_state}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}
                />
              </Col>
            </Row>
          </Content>
        </Col>
      </Content>
    </>
  );
};

export default SmsLogPresenter;
