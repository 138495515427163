import React, { useRef } from 'react';
import './ProductSlide.style.css';
import Slider from 'react-slick';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { LectureCard, Col, Content } from 'components';
import { Card } from 'antd';
import { encrypt } from 'utils/Crypto';
import { useNavigate } from 'react-router-dom';
import { STORE_URL } from 'utils';

const styles = {
  contentCardButton: {
    border: 'none',
    padding: 0,
    borderRadius: 0,
    background: 'none',
  },
};

const ProductSlide = ({
  title,
  subTitle,
  titleStyle,
  subTitleStyle,
  category,
  item,
  productCheck,
  maxView,
  screenSize,
}) => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();

  /* ===== STATE ===== */
  const sliderRef = useRef(null);

  const settings = {
    dots: false, // 토트 표시기 표시 여부
    infinite: false, // 무한 루프 허용 여부
    autoplay: false, // 자동 재생 여부
    arrows: false, // 이전/다음 화살표 표시 여부
    speed: 500, // 슬라이드 전환 속도
    slidesToShow:
      item && item?.length > 0
        ? screenSize >= 1024
          ? 4
          : screenSize >= 700
          ? 3
          : 2
        : 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  /* ===== FUNCTION ===== */
  /**
   * 슬라이드 좌우 컨트롤러 함수
   * @param {*} type
   */
  const handleSlideArrow = (type) => {
    if (sliderRef.current) {
      if (type === 'prev') {
        sliderRef.current.slickPrev();
      } else {
        sliderRef.current.slickNext();
      }
    }
  };

  /**
   * 네비게이트 함수
   * --
   * @param {*} id
   */
  const handleNavigate = (id) => {
    if (productCheck) {
      navigate(`/shop/detail/${encodeURIComponent(encrypt(id))}`);
    } else {
      navigate(`/lectures/${encodeURIComponent(encrypt(id))}`);
    }
  };

  return (
    <div className="slide-layout">
      <div className="slide-header">
        <p
          style={{
            padding: '0 10px',
            ...titleStyle,
          }}
        >
          {title}
        </p>
        <div className="slide-controller">
          <button onClick={() => handleSlideArrow('prev')}>
            <LeftOutlined />
          </button>
          <button onClick={() => handleSlideArrow('next')}>
            <RightOutlined />
          </button>
        </div>
      </div>
      <div className="slide-sub" style={{ padding: '0 10px' }}>
        {subTitle && subTitle !== '' ? (
          <p style={{ ...subTitleStyle }}>{subTitle}</p>
        ) : null}
        {category ? category : null}
      </div>
      <Slider {...settings} ref={sliderRef}>
        {item && item?.length > 0 ? (
          item?.map((_item, _index) =>
            productCheck
              ? _index < maxView && (
                  <Card
                    key={`product_${_item.product_id}`}
                    hoverable
                    bordered={false}
                    style={{
                      ...styles.contentCardButton,
                      background: 'transparent',
                    }}
                    bodyStyle={{ padding: 10, background: 'transparent' }}
                    onClick={() => handleNavigate(_item?.product_id)}
                  >
                    <LectureCard
                      productCheck={productCheck}
                      thumbnail={_item?.thumbnail && _item?.thumbnail}
                      title={_item?.product_name}
                      teacher={_item?.num ? '수량 :' + _item?.num : ''}
                      category={_item?.product_category_name}
                      price={_item?.price}
                    />
                  </Card>
                )
              : _index < maxView && (
                  <Card
                    key={`lecture${_item.lecture_id}`}
                    hoverable
                    bordered={false}
                    style={styles.contentCardButton}
                    bodyStyle={{ padding: 10 }}
                    onClick={() => handleNavigate(_item?.lecture_id)}
                  >
                    <LectureCard
                      thumbnail={
                        _item?.thumbnail &&
                        `${STORE_URL}/lecture/${_item?.thumbnail}`
                      }
                      title={_item?.title}
                      teacher={_item?.name}
                      category={_item?.category}
                      start={_item?.start_date}
                      end={_item?.end_date}
                      event={_item?.status}
                      eventColor={{
                        color:
                          _item?.status === 'NEW'
                            ? '#FAD200'
                            : _item?.status === '진행중'
                            ? '#2AC769'
                            : _item?.status === '종료'
                            ? '#FF6262'
                            : '#757575',
                        fontWeight: 700,
                        border: '1px solid #EEEEEE',
                      }}
                    />
                  </Card>
                )
          )
        ) : (
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              height={304}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
              }}
              backgroundColor={'none'}
            >
              <h4 style={{ fontSize: screenSize >= 768 ? '24px' : 18 }}>
                해당 조건을 만족하는 상품이 없습니다
              </h4>
            </Content>
          </Col>
        )}
      </Slider>
    </div>
  );
};

ProductSlide.defaultProps = {
  title: '타이틀',
  subTitle: '',
  maxView: 10,
};

export default ProductSlide;
