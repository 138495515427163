import React, { useState, useEffect, useRef } from 'react';
import { Content, Row, Col, Title, Button } from 'components';
import { Input, Table, Space } from 'antd';
import { encrypt } from 'utils/Crypto';
import { useQueryClient } from 'react-query';
import MessageAlert from 'utils/MessageAlert';
import { ProgramApi } from 'api';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { DOMAIN_URL, dateFormatter } from 'utils';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ProgramPresenter = (props) => {
  /* ===== PROPS ===== */
  const { major, programs } = props;

  /* ===== Router ===== */
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  /* ===== MUTATE ===== */
  const queryClient = useQueryClient();
  const deleteProgram = ProgramApi.DeleteProgram({
    onSuccess: () => {
      MessageAlert.success('교안이 성공적으로 삭제되었습니다.');
    },
  });

  /* ===== STATE ===== */
  const [programList, setProgramList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  // 교안 모달
  const [isOpened, setIsOpened] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${title}을(를) 입력해주세요`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            검색
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            초기화
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '구분',
      dataIndex: 'parents_name',
      key: 'parents_name',
      filters: major?.map((item) => ({
        text: item?.category_name,
        value: item?.category_id,
      })),
      onFilter: (value, record) =>
        value ? record?.parents_id === value : record?.parents_id,
      filterSearch: true,
      render: (_, { parents_name }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {parents_name}
        </Title>
      ),
      width: '10%',
    },
    // {
    //   title: '구분상세',
    //   dataIndex: 'category_name',
    //   key: 'category_name',
    //   filters: majorDetail?.map((item) => ({
    //     text: item?.category_name,
    //     value: item?.category_id,
    //   })),
    //   onFilter: (value, record) =>
    //     value ? record?.category_id === value : record?.category_id,
    //   filterSearch: true,
    //   render: (_, { category_name }) => (
    //     <Title
    //       style={{
    //         fontWeight: 500,
    //         fontSize: '16px',
    //         lineHeight: '19px',
    //         marginLeft: '5px',
    //       }}
    //     >
    //       {category_name}
    //     </Title>
    //   ),
    //   width: '10%',
    // },
    {
      title: '프로그램명',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title', '프로그램명'),
      render: (_, { title }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {title}
        </Title>
      ),
      width: '65%',
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(a?.created_at) - new Date(b?.created_at),
      render: (_, { created_at }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {dateFormatter(created_at, 'YYYY-MM-DD')}
        </Title>
      ),
      width: '15%',
    },
    {
      title: '',
      dataIndex: 'butotn',
      key: 'button',
      render: (_, { program_id }) => (
        <Space size="middle">
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              color: '#03A9F4',
            }}
          >
            <p
              onClick={() => handleOpenPopup(program_id)}
              style={{
                cursor: 'pointer',
                margin: 0,
              }}
            >
              수정
            </p>
          </Title>
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              color: '#FB4E4E',
            }}
            onClick={() => handleDeleteProgram(program_id)}
          >
            <p
              onClick={() => handleDeleteProgram(program_id)}
              style={{
                cursor: 'pointer',
                margin: 0,
              }}
            >
              삭제
            </p>
          </Title>
        </Space>
      ),
      width: '10%',
    },
  ];

  /* ===== FUNCTION ===== */
  /**
   * 교안등록 팝업
   * --
   */
  const handleOpenPopup = (id = 0) => {
    // A4 용지 크기 및 비율
    const a4Width = 595;
    const a4Height = 842;
    const aspectRatio = a4Width / a4Height;

    // 팝업 창 크기 계산 (1.5배 비율로)
    const popupWidth = a4Width * 1.5;
    const popupHeight = popupWidth / aspectRatio;

    // 중앙 정렬을 위한 창 위치 계산
    const left = (window.innerWidth - a4Width) / 2;
    const top = (window.innerHeight - a4Height) / 2;
    const domain = DOMAIN_URL;
    if (id) {
      window.open(
        `${domain}/#/popup/program/regist?program=${encodeURIComponent(
          encrypt(id)
        )}`,
        '_blank',
        `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
      );
    } else {
      window.open(
        `${domain}/#/popup/program/regist`,
        '_blank',
        `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
      );
    }
    setIsOpened(true);
  };

  /**
   * 교안 삭제
   * --
   */
  const handleDeleteProgram = (id) => {
    const confirm = window.confirm('정말 교안을 삭제하시겠습니까?');

    if (confirm) {
      try {
        deleteProgram.mutate({
          program_id: id,
        });
      } catch (err) {
        console.log('교안 삭제 실패!', err);
        MessageAlert.error('교안을 삭제하는데 실패하였습니다!');
      }
    } else {
      MessageAlert.warning('취소되었습니다');
    }
  };

  /**
   * pagination 처리 함수
   * --
   */
  const handlePagination = (page) => {
    navigation(`/admin/programs?page=${page}`);
  };

  /* ===== HOOKS ===== */

  /**
   * 검색 값에 따른 교안 아이템리스트 정제
   * --
   */
  useEffect(() => {
    const newItem = programs?.map((item) => ({
      ...item,
      title: item?.content?.title,
    }));

    setProgramList(newItem);
  }, [programs]);

  /**
   * 팝업창 메시지 이벤트 감지
   * --
   */
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'refresh') {
        queryClient.invalidateQueries('getPrograms');
      }
    };

    if (isOpened) {
      window.addEventListener('message', handleMessage);
    } else {
      window.removeEventListener('message', handleMessage);
    }

    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line
  }, [isOpened]);

  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  /* ===== RENDER ===== */
  return (
    <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
      <Row>
        <Col x={24}>
          <Content
            maxWidth={'100%'}
            padding={'24px 24px 0'}
            backgroundColor={'none'}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <Title size={2} style={{ marginBottom: 20 }}>
              교안 관리
            </Title>
            <Button
              layoutStyle={{ margin: 0 }}
              onClick={() => handleOpenPopup()}
            >
              교안등록
            </Button>
          </Content>
          <Content
            maxWidth={'100%'}
            padding={'24px 24px 0 24px'}
            className={'admin_container'}
          >
            <Table
              columns={columns}
              dataSource={programList}
              showSorterTooltip={false}
              pagination={{
                position: ['bottomCenter'],
                current: currentPage,
                onChange: (e) => handlePagination(e),
              }}
            />
          </Content>
        </Col>
      </Row>
    </Content>
  );
};

export default ProgramPresenter;
