import React, { useState, useEffect, useMemo } from 'react';
import {
  Content,
  Row,
  Col,
  Button,
  Title,
  ModalLayout,
  // TextEditor,
  FileUploadButton,
} from 'components';
import { ProfileApi, PreferenceApi, SNSApi, MajorApi, FileApi } from 'api';
import { STORE_URL, getCookie, calculateAge, HOST_URL } from 'utils';
import { Checkbox, Input, Select, DatePicker } from 'antd';
import {
  TwitterOutlined,
  YoutubeOutlined,
  FacebookOutlined,
  InstagramOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { useQueryClient, useIsFetching } from 'react-query';
import MessageAlert from 'utils/MessageAlert';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { decrypt } from 'utils/Crypto';
import { FileUploadManager } from 'utils/FileUploadManager';
import axios from 'axios';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import validateCheck from 'utils/Validation';

const { TextArea } = Input;

/**
 * 에디터 입력 정보 형태 변환
 * --
 */
const NewContent = ({ education, career, certificate, introduce }) => {
  return (
    <div id="content-layout" className="content-layout">
      {education && education?.length > 0 ? (
        <div className="education" style={{ marginBottom: '30px' }}>
          <h4 style={{ fontSize: '18px', fontWeight: 700 }}>∙ 학력</h4>
          {education?.map((item, index) => (
            <p
              key={`education-${index}`}
              className={item?.highest ? 'on' : ''}
              style={{ fontSize: '16px', fontWeight: 400 }}
            >
              - {item?.school_name} ∙ {item?.major_name} ∙ {item?.state} ∙{' '}
              {moment(item?.date).format('YYYY.MM.DD')}
            </p>
          ))}
        </div>
      ) : null}
      {career && career?.length > 0 ? (
        <div className="career" style={{ marginBottom: '30px' }}>
          <h4 style={{ fontSize: '18px', fontWeight: 700 }}>∙ 경력</h4>
          {career?.map((item, index) => (
            <p
              key={`career-${index}`}
              style={{ fontSize: '16px', fontWeight: 400 }}
            >
              - {item?.company_name} ∙ {item?.term}
            </p>
          ))}
        </div>
      ) : null}
      {certificate && certificate?.length > 0 ? (
        <div className="certificate" style={{ marginBottom: '30px' }}>
          <h4 style={{ fontSize: '18px', fontWeight: 700 }}>∙ 자격증</h4>
          {certificate?.map((item, index) => (
            <p
              key={`certificate-${index}`}
              style={{ fontSize: '16px', fontWeight: 400 }}
            >
              - {item?.certificate_name} ∙{' '}
              {moment(item?.date).format('YYYY.MM.DD')}
            </p>
          ))}
        </div>
      ) : null}
      <div className="introduce" style={{ marginBottom: '30px' }}>
        <h4 style={{ fontSize: '18px', fontWeight: 700 }}>∙ 자기소개</h4>
        <pre style={{ fontSize: '16px', fontWeight: 400 }}>{introduce}</pre>
      </div>
    </div>
  );
};

const platform = [
  {
    value: 1,
    label: '인스타그램',
  },
  {
    value: 2,
    label: '페이스북',
  },
  {
    value: 3,
    label: '트위터',
  },
  {
    value: 4,
    label: '유튜브',
  },
];

const Profile = () => {
  /* ===== Route ===== */
  const navigate = useNavigate();

  /* ===== State ===== */
  const isFetching = useIsFetching();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const categories = queryClient.getQueryData('getCategories')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const profiles = queryClient.getQueryData('getUserProfiles')?.data;
  const subways = queryClient.getQueryData('getSubways')?.data;
  const address = queryClient.getQueryData('getAddressSplit')?.data;
  const sns = queryClient.getQueryData('getSNSUsers')?.data;
  const preferenceSubway = queryClient.getQueryData(
    'getPreferenceSubwayUsers'
  )?.data;
  const preferenceArea = queryClient.getQueryData(
    'getPreferenceAreaUsers'
  )?.data;
  const major = queryClient.getQueryData('getUserMajors')?.data;
  const files = queryClient.getQueryData('getTeacherFiles')?.data;
  const userId = searchParams.get('profile');

  /**
   * 데이터 정제
   * --
   */
  const Data = useMemo(() => {
    let id;
    if (userId) {
      id = parseInt(decrypt(userId));
    } else {
      id = parseInt(getCookie('USER'));
    }
    return {
      user: users?.filter((item) => item.user_id === id)?.pop(),
      profile: profiles?.filter((item) => item.user_id === id)?.pop(),
      snsList: sns?.filter((item) => item.user_id === id),
      subwayList: preferenceSubway?.filter((item) => item.user_id === id),
      areaList: preferenceArea?.filter((item) => item.user_id === id),
      major: major?.filter((item) => item.user_id === id),
      files: files?.filter((item) => item?.user_id === id),
      profileImage: files
        ?.filter((item) => item?.user_id === id && item?.type === 'P')
        ?.pop(),
    };
  }, [
    users,
    profiles,
    sns,
    preferenceSubway,
    preferenceArea,
    major,
    files,
    userId,
  ]);

  /* ===== Mutate ===== */
  // 프로필
  const createProfile = ProfileApi.CreateUserProfile({
    onSuccess: async () => {
      if (profileImg) {
        if (profileImg?.size === 0) {
          return;
        }
        const res = await FileUploadManager('U', profileImg);

        if (res.status === 200) {
          createFile.mutate({
            user_id: parseInt(getCookie('USER')),
            file_name: res.file_name,
            file_url: res.name,
            type: 'P',
          });
        } else {
          MessageAlert.error('프로필 이미지 업로드에 실패하였습니다');
        }
      }

      if (fileList && fileList?.length > 0) {
        fileList?.map(async (item) => {
          if (item?.size === 0) {
            return;
          }
          const res = await FileUploadManager('U', item);

          if (res.status === 200) {
            createFile.mutate({
              user_id: parseInt(getCookie('USER')),
              file_name: res.file_name,
              file_url: res.name,
              type: 'G',
            });
          } else {
            MessageAlert.error('파일 업로드에 실패하였습니다');
          }
        });
      }

      if (attachmentList && attachmentList?.length > 0) {
        attachmentList?.map(async (item) => {
          if (item?.size === 0) {
            return;
          }
          const res = await FileUploadManager('U', item);

          if (res.status === 200) {
            createFile.mutate({
              user_id: parseInt(getCookie('USER')),
              file_name: res.file_name,
              file_url: res.name,
              type: 'E',
            });
          } else {
            MessageAlert.error('파일 업로드에 실패하였습니다');
          }
        });
      }

      if (certificateList && certificateList?.length > 0) {
        certificateList?.map(async (item) => {
          if (item?.size === 0) {
            return;
          }
          const res = await FileUploadManager('U', item);

          if (res.status === 200) {
            createFile.mutate({
              user_id: parseInt(getCookie('USER')),
              file_name: res.file_name,
              file_url: res.name,
              type: 'C',
            });
          } else {
            MessageAlert.error('자격증 파일 업로드에 실패하였습니다');
          }
        });
      }
    },
    onError: () => {
      MessageAlert.error('프로필 추가에 실패했습니다');
    },
  });
  const updateProfile = ProfileApi.UpdateUserProfile({
    onSuccess: async () => {
      const profileData = files
        ?.filter(
          (item) =>
            item?.user_id === parseInt(getCookie('USER')) && item?.type === 'P'
        )
        ?.pop();
      if (profileImg) {
        if (profileImg?.size === 0) {
          return;
        }

        const res =
          loginWho !== 'admin'
            ? await FileUploadManager('U', profileImg)
            : await FileUploadManager(
                'U',
                profileImg,
                parseInt(decrypt(userId))
              );

        if (profileData) {
          updateFile.mutate({
            teacher_file_id: profileData?.teacher_file_id,
            file_name: res.file_name,
            file_url: res.name,
            type: 'P',
          });
        } else {
          if (profileImg?.size === 0) {
            return;
          }

          createFile.mutate({
            user_id:
              loginWho !== 'admin'
                ? parseInt(getCookie('USER'))
                : parseInt(decrypt(userId)),
            file_name: res.file_name,
            file_url: res.name,
            type: 'P',
          });
        }
      } else {
        if (!profileImg && profileData) {
          deleteFile.mutate({
            teacher_file_id: profileData?.teacher_file_id,
          });
        }
      }

      const gfileData = files?.filter(
        (item) =>
          item?.user_id === parseInt(getCookie('USER')) && item?.type === 'G'
      );
      const efileData = files?.filter(
        (item) =>
          item?.user_id === parseInt(getCookie('USER')) && item?.type === 'E'
      );
      const cfileData = files?.filter(
        (item) =>
          item?.user_id === parseInt(getCookie('USER')) && item?.type === 'C'
      );

      if (fileList && fileList.length > 0) {
        gfileData?.map((item) =>
          deleteFile.mutate({
            teacher_file_id: item?.teacher_file_id,
          })
        );

        fileList?.map(async (item) => {
          if (item?.size === 0) {
            return;
          }

          const res = await FileUploadManager('U', item);

          if (res.status === 200) {
            createFile.mutate({
              user_id: parseInt(getCookie('USER')),
              file_name: res.file_name,
              file_url: res.name,
              type: 'G',
            });
          } else {
            MessageAlert.error('파일 업로드에 실패하였습니다');
          }
        });
      } else {
        if (gfileData && fileList.length === 0) {
          gfileData?.map((item) =>
            deleteFile.mutate({
              teacher_file_id: item?.teacher_file_id,
            })
          );
        }
      }

      // 기타 첨부파일
      if (attachmentList && attachmentList.length > 0) {
        efileData?.map((item) =>
          deleteFile.mutate({
            teacher_file_id: item?.teacher_file_id,
          })
        );

        attachmentList?.map(async (item) => {
          if (item?.size === 0) {
            return;
          }

          const res = await FileUploadManager('U', item);

          if (res.status === 200) {
            createFile.mutate({
              user_id: parseInt(getCookie('USER')),
              file_name: res.file_name,
              file_url: res.name,
              type: 'E',
            });
          } else {
            MessageAlert.error('파일 업로드에 실패하였습니다');
          }
        });
      } else {
        if (efileData && attachmentList.length === 0) {
          efileData?.map((item) =>
            deleteFile.mutate({
              teacher_file_id: item?.teacher_file_id,
            })
          );
        }
      }

      // 자격증 파일
      if (certificateList && certificateList.length > 0) {
        cfileData?.map((item) =>
          deleteFile.mutate({
            teacher_file_id: item?.teacher_file_id,
          })
        );

        certificateList?.map(async (item) => {
          if (item?.size === 0) {
            return;
          }

          const res = await FileUploadManager('U', item);

          if (res.status === 200) {
            createFile.mutate({
              user_id: parseInt(getCookie('USER')),
              file_name: res.file_name,
              file_url: res.name,
              type: 'C',
            });
          } else {
            MessageAlert.error('자격증파일 업로드에 실패하였습니다');
          }
        });
      } else {
        if (cfileData && certificateList.length === 0) {
          cfileData?.map((item) =>
            deleteFile.mutate({
              teacher_file_id: item?.teacher_file_id,
            })
          );
        }
      }
    },
    onError: () => {
      MessageAlert.error('프로필 수정에 실패했습니다');
    },
  });
  // 선호지역
  const createArea = PreferenceApi.CreatePreferenceAreaUser({
    onError: () => {
      MessageAlert.error('선호 지역 추가에 실패했습니다');
    },
  });
  const updateArea = PreferenceApi.UpdatePreferenceAreaUser({
    onError: () => {
      MessageAlert.error('선호 지역 수정에 실패했습니다');
    },
  });
  // 선호 지하철역
  const createSubway = PreferenceApi.CreatePreferenceSubwayUser({
    onError: () => {
      MessageAlert.error('선호 지하철역 추가에 실패했습니다');
    },
  });
  const updateSubway = PreferenceApi.UpdatePreferenceSubwayUser({
    onError: () => {
      MessageAlert.error('선호 지하철역 수정에 실패했습니다');
    },
  });
  const deleteSubway = PreferenceApi.DeletePreferenceSubwayUser({
    onError: () => {
      MessageAlert.error('선호 지하철역 삭제에 실패했습니다');
    },
  });
  // SNS
  const createSNS = SNSApi.CreateSNSUser({
    onError: () => {
      MessageAlert.error('SNS 추가에 실패했습니다');
    },
  });
  const updateSNS = SNSApi.UpdateSNSUser({
    onError: () => {
      MessageAlert.error('SNS 수정에 실패했습니다');
    },
  });
  // Major
  const createMajor = MajorApi.CreateUserMajor({
    onError: () => {
      MessageAlert.error('전문분야 추가에 실패했습니다');
    },
  });
  const updateMajor = MajorApi.UpdateUserMajor({
    onError: () => {
      MessageAlert.error('전문분야 수정에 실패했습니다');
    },
  });
  // File
  const createFile = FileApi.CreateTeacherFile();
  const deleteFile = FileApi.DeleteTeacherFile();
  const updateFile = FileApi.UpdateTeacherFile();

  /* ===== State ===== */
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [isOpened, setIsOpened] = useState(false);
  const [modalType, setModalType] = useState('');
  const [rendering, setRendering] = useState(false);
  const [type, setType] = useState('create');
  const [newSubways, setNewSubways] = useState([]);
  const [majorList, setMajorList] = useState([]);
  // const [majorDetailList, setMajorDetailList] = useState([]);

  // 프로필 이미지
  const [profileImg, setProfileImg] = useState(undefined);
  // 화면 표시를 위한 임시 url
  const [createObjectURL, setCreateObjectURL] = useState(undefined);
  // 프로필 데이터
  const [profileData, setProfileData] = useState({
    profile_title: undefined,
    content: undefined,
    available_time: undefined,
    salary: undefined,
    negotiable_check: undefined,
    activity_area: undefined,
  });
  // console.log('profileData', profileData);
  // 선호지역 데이터
  const [cityList, setCityList] = useState([[]]);
  // const [districtList, setDistrictList] = useState([[]]);
  const [preferenceAreaList, setPreferenceAreaList] = useState([
    { local_id: undefined, city_id: undefined },
  ]);
  // 선호지하철 데이터
  const [preferenceSubwayData, setPreferenceSubwayData] = useState({
    subway_id: undefined,
  });
  const [preferenceSubwayList, setPreferenceSubwayList] = useState([]);
  // SNS 데이터
  const [snsData, setSnsData] = useState({
    platform: 1,
    address: undefined,
  });
  const [snsList, setSnsList] = useState([]);
  // 모달 데이터
  const [modalData, setModalData] = useState({});
  // 전문분야 데이터
  const [majorData, setMajorData] = useState({});
  const [majorArr, setMajorArr] = useState([]);
  // const [htmlString, setHtmlString] = useState(undefined);
  const [fileList, setFileList] = useState([]);
  // Content 데이터
  const [contentData, setContentData] = useState({
    education: [],
    career: [],
    certificate: [],
    introduce: '',
  });
  // 유튜브 링크
  const [youtubeInput, setYoutubeInput] = useState('');
  const [youtubeList, setYoutubeList] = useState([]);
  // 기타 첨부파일 데이터
  const [attachmentList, setAttachmentList] = useState([]);
  // 자격증 파일 데이터
  const [certificateList, setCertificateList] = useState([]);

  const majorItems = useMemo(() => {
    const parentsMajor = categories?.filter((item) => item?.type === 'M');

    return parentsMajor;
  }, [categories]);

  /* ===== Functions ===== */
  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} w
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (w, t, v, callback = null) => {
    let newData = {};

    switch (w) {
      case 'profile':
      case 'Profile':
      case 'PROFILE':
        newData = { ...profileData };
        break;
      case 'preference_area':
      case 'Preference_area':
      case 'Preference_Area':
      case 'PREFERENCE_AREA':
        // newData = { ...preferenceAreaData };
        break;
      case 'preference_subway':
      case 'Preference_subway':
      case 'Preference_Subway':
      case 'PREFERENCE_SUBWAY':
        newData = { ...preferenceSubwayData };
        break;
      case 'sns':
      case 'Sns':
      case 'SNS':
        newData = { ...snsData };
        break;
      case 'modal':
      case 'Modal':
      case 'MODAL':
        newData = { ...modalData };
        break;
      case 'major':
      case 'Major':
      case 'MAJOR':
        newData = { ...majorData };
        break;
      default:
        break;
    }

    // 숫자만 입력가능 하도록
    if (t === 'salary' || t === 'ca_term') {
      const value = newData[t];

      if (value === '' || !value) {
        if (v === '' || !v) {
          v = undefined;
        } else if (!validateCheck('number', v)) {
          MessageAlert.warning('숫자만 입력해주세요');
          v = undefined;
        }
      } else {
        if (v === '' || !v) {
          v = undefined;
        } else if (!validateCheck('number', v)) {
          MessageAlert.warning('숫자만 입력해주세요');
          v = value;
        }
      }
    }

    newData[t] = v;
    const d = {
      ...newData,
    };

    switch (w) {
      case 'profile':
      case 'Profile':
      case 'PROFILE':
        return setProfileData(d);
      case 'preference_area':
      case 'Preference_area':
      case 'Preference_Area':
      case 'PREFERENCE_AREA':
        return;
      // return setPreferenceAreaData(d);
      case 'preference_subway':
      case 'Preference_subway':
      case 'Preference_Subway':
      case 'PREFERENCE_SUBWAY':
        return setPreferenceSubwayData(d);
      case 'sns':
      case 'Sns':
      case 'SNS':
        return setSnsData(d);
      case 'modal':
      case 'Modal':
      case 'MODAL':
        return setModalData(d);
      case 'major':
      case 'Major':
      case 'MAJOR':
        return setMajorData(d);
      default:
        break;
    }

    if (callback) callback(v);
  };

  /**
   * 프로필 등록 함수
   * --
   */
  const handleSubmit = () => {
    const { profile_title, available_time, salary, activity_area } =
      profileData;
    const { introduce } = contentData;

    if (!majorArr?.length) {
      MessageAlert.warning('전문분야를 선택해주세요');
      return;
    }

    if (!activity_area || activity_area === '') {
      MessageAlert.warning('활동 지역을 입력해주세요');
      return;
    }

    if (
      !preferenceAreaList ||
      !preferenceAreaList[0]?.local_id ||
      !preferenceAreaList[0]?.city_id
    ) {
      MessageAlert.warning('선호 지역을 선택해주세요');
      return;
    }

    const hasEmptyArea = preferenceAreaList?.some(
      (item) => !item?.local_id || !item?.city_id
    );
    if (hasEmptyArea) {
      MessageAlert.warning('선호 지역 값을 모두 선택해주세요');
      return;
    }

    // if (!preferenceSubwayList || preferenceSubwayList?.length === 0) {
    //   MessageAlert.warning('선호 지하철역을 하나 이상 선택해주세요');
    //   return;
    // }

    if (!available_time || available_time === '') {
      MessageAlert.warning('이동가능 시간을 입력해주세요');
      return;
    }

    if (!salary || salary === '') {
      MessageAlert.warning('희망 급여를 입력해주세요');
      return;
    }

    if (!validateCheck('number', salary)) {
      MessageAlert.warning('희망 급여는 숫자만 입력해주세요');
      return;
    }

    if (!profile_title || profile_title === '') {
      MessageAlert.warning('한 줄 소개를 입력해주세요');
      return;
    }

    if (!introduce || introduce === '') {
      MessageAlert.warning('자기소개 내용을 입력해주세요');
      return;
    }

    if (type === 'create') {
      if (window.confirm('프로필을 등록하시겠습니까?')) {
        try {
          const userId = getCookie('USER');
          // Content 데이터 정제
          const { introduce, career, education, certificate } = contentData;

          const newContent = ReactDOMServer.renderToString(
            <NewContent
              education={education}
              career={career}
              certificate={certificate}
              introduce={introduce}
            />
          );

          createProfile.mutate({
            user_id: userId,
            ...profileData,
            content: newContent,
            video_url: youtubeList,
          });

          // 선호지역 추가
          if (preferenceAreaList && preferenceAreaList.length > 0) {
            preferenceAreaList?.map((item, index) =>
              createArea.mutate({
                user_id: userId,
                ...item,
                rank: index + 1,
              })
            );
          }

          // 선호 지하철역 추가
          if (preferenceSubwayList && preferenceSubwayList.length > 0) {
            preferenceSubwayList?.map((item) =>
              createSubway.mutate({
                user_id: userId,
                subway_id: item.subway_id,
              })
            );
          }

          // sns 추가
          if (snsList && snsList.length > 0) {
            snsList?.map((item) =>
              createSNS.mutate({
                user_id: userId,
                ...item,
              })
            );
          }
          // 전문분야 데이터 추가
          if (majorArr) {
            majorArr?.map((item) =>
              createMajor.mutate({
                user_id: userId,
                ...item,
              })
            );
          }

          navigate('/mypage/profile');
          MessageAlert.success('프로필이 등록되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          throw e;
        }
      }
    } else {
      if (window.confirm('프로필을 수정하시겠습니까?')) {
        try {
          const { created_at, updated_at, ...rest } = profileData;
          const id = searchParams.get('profile');
          const userId = parseInt(decrypt(id));

          const { introduce, career, education, certificate } = contentData;

          const newContent = ReactDOMServer.renderToString(
            <NewContent
              education={education}
              career={career}
              certificate={certificate}
              introduce={introduce}
            />
          );

          updateProfile.mutate({
            ...rest,
            content: newContent,
            video_url: youtubeList,
          });

          if (preferenceAreaList && preferenceAreaList.length > 0) {
            const newAreaList = preferenceAreaList?.map((item, index) => ({
              ...item,
              user_id: userId,
              rank: index + 1,
            }));
            updateArea.mutate(newAreaList);
          }

          if (preferenceSubwayList && preferenceSubwayList.length > 0) {
            const newSubwayList = preferenceSubwayList?.map((item) => ({
              ...item,
              user_id: userId,
            }));
            updateSubway.mutate(newSubwayList);
          } else {
            const { subwayList } = Data;
            if (subwayList?.length) {
              subwayList?.forEach((item) =>
                deleteSubway.mutate({
                  preference_subway_id: item?.preference_subway_id,
                })
              );
            }
          }

          let newSNSList = [];
          if (snsList && snsList.length > 0) {
            newSNSList = snsList?.map((item) => ({
              ...item,
              user_id: userId,
            }));
          }
          updateSNS.mutate({
            user_id: userId,
            data: newSNSList,
          });

          if (majorArr && majorArr?.length) {
            const newMajorList = majorArr?.map((item) => ({
              ...item,
              user_id: userId,
            }));
            updateMajor.mutate(newMajorList);
          }

          if (parseInt(getCookie('USER')) === 0) {
            navigate('/admin/teachers');
          } else {
            navigate('/mypage/profile');
          }

          MessageAlert.success('프로필이 수정되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          throw e;
        }
      }
    }
  };

  /**
   * 주소 SELECT 검색
   * --
   */
  const handleSearch = (value, option) => {
    return (option?.label ?? '').includes(value);
  };

  /**
   * 선호 지역, 선호 지하철, sns 배열 추가 함수
   * --
   * @param {*} t
   * @param {*} w
   * @param {*} d
   */
  const handleList = (t, w, d) => {
    switch (t) {
      case 'preference_area':
      case 'Preference_area':
      case 'Preference_Area':
      case 'PREFERENCE_AREA':
        if (w === 'add') {
          setPreferenceAreaList([
            ...preferenceAreaList,
            { local_id: undefined, city_id: undefined },
          ]);
          setCityList([...cityList, []]);
        } else {
          const pa = [...preferenceAreaList];
          const newPa = pa?.filter((item, index) => index !== d);
          setPreferenceAreaList(newPa);

          const list = [...cityList];
          const newList = list?.filter((item, index) => index !== d);
          setCityList(newList);
        }
        break;
      case 'preference_subway':
      case 'Preference_subway':
      case 'Preference_Subway':
      case 'PREFERENCE_SUBWAY':
        if (w === 'add') {
          // 이미 추가한 선호 지하철역일 경우
          if (preferenceSubwayList?.some((item) => item.subway_id === d)) {
            MessageAlert.warning('이미 추가된 지하철역입니다');
            return;
          }

          const newSubway = [...subways];
          const s = newSubway?.filter((item) => item.subway_id === d)?.pop();
          const subway = {
            ...s,
          };
          setPreferenceSubwayList([...preferenceSubwayList, subway]);
          setPreferenceSubwayData({});
        } else {
          const ps = [...preferenceSubwayList];
          const newPs = ps?.filter((item, index) => index !== d);
          setPreferenceSubwayList(newPs);
        }
        break;
      case 'sns':
      case 'Sns':
      case 'SNS':
        if (w === 'add') {
          const { address } = snsData;

          if (!validateCheck('url', address)) {
            MessageAlert.warning('올바른 주소 형식이 아닙니다');
            return;
          }
          setSnsList([...snsList, d]);
          setSnsData({
            platform: 1,
            address: undefined,
          });
        } else {
          const sns = [...snsList];
          const newSns = sns?.filter((item, index) => index !== d);
          setSnsList(newSns);
        }
        break;
      case 'major':
      case 'Major':
      case 'MAJOR':
        if (w === 'add') {
        } else {
          const m = [...majorArr];
          const newMajor = m?.filter((item, index) => index !== d);
          setMajorArr(newMajor);
        }
        break;
      default:
        break;
    }
    setIsOpened(false);
  };

  /**
   * 선호지역 데이터 바인딩
   * --
   * @param {*} i
   * @param {*} t
   * @param {*} d
   */
  const handleListChange = (i, t, d) => {
    let data = [...preferenceAreaList];
    let newList = [];
    let newData = {};
    switch (t) {
      case 'local':
      case 'Local':
      case 'LOCAL':
        newData = { ...data[i] };
        newData['local_id'] = d;
        data[i] = newData;
        setPreferenceAreaList(data);

        // 이미 선택된 지역 제외
        const arr = preferenceAreaList?.map((item) => item.city_id);
        const newCity = address?.city?.filter(
          (item) => !arr.includes(item.city_id)
        );
        newList = [...cityList];
        const filterCity = newCity?.filter((item) => item.local_id === d);
        newList[i] = filterCity;
        setCityList(newList);
        break;
      case 'city':
      case 'City':
      case 'CITY':
        newData = { ...data[i] };
        newData['city_id'] = d;
        data[i] = newData;
        setPreferenceAreaList(data);
        break;
      default:
        break;
    }
  };

  /**
   * 모달
   * --
   * @param {*} type
   * @param {*} open
   */
  const handleModal = (type, open) => {
    setModalType(type);
    setIsOpened(!open);
  };

  /**
   * 파일 추가 함수
   * --
   * @param {*} file
   */
  const handleFileChange = (file) => {
    const d = [...fileList];
    const newFileList = d.concat(file);

    setFileList(newFileList);
  };

  /**
   * 첨부파일 추가 함수
   * --
   */
  const handleAttachmentFileChange = (file) => {
    const d = [...attachmentList];
    const newFileList = d.concat(file);

    setAttachmentList(newFileList);
  };

  /**
   * 자격증 추가 함수
   * --
   */
  const handleCerificateFileChange = (file) => {
    const d = [...certificateList];
    const newFileList = d.concat(file);

    setCertificateList(newFileList);
  };

  /**
   * 첨부파일 제거 함수
   * --
   * @param {*} i
   */
  const handleRemoveAttachmentFileList = (i) => {
    const newFileList = attachmentList?.filter((item, index) => i !== index);

    setAttachmentList(newFileList);
  };

  /**
   * 자격증 파일 제거 함수
   * --
   * @param {*} i
   */
  const handleRemoveCertificateFileList = (i) => {
    const newFileList = certificateList?.filter((item, index) => i !== index);

    setCertificateList(newFileList);
  };

  /**
   * 프로필 이미지 추가 함수
   * --
   * @param {*} file
   */
  const handleProfileImage = (file) => {
    if (createObjectURL) {
      URL.revokeObjectURL(createObjectURL);
    }

    if (file) {
      // console.log('실행');
      setProfileImg(file);
      setCreateObjectURL(URL.createObjectURL(file));
    }
  };

  /**
   * 파일 제거 함수
   * --
   * @param {*} i
   */
  const handleRemoveFileList = (i) => {
    const newFileList = fileList?.filter((item, index) => i !== index);

    setFileList(newFileList);
  };

  /**
   * Content 데이터 바인딩
   * --
   * @param {*} t
   * @param {*} v
   */
  const handleContentData = (t, v) => {
    const newData = { ...contentData };
    if (t === 'introduce') {
      newData[t] = v;
    } else {
      newData[t] = [...newData[t], v];
    }
    const d = {
      ...newData,
    };

    setContentData(d);
  };

  /**
   * 학력, 경력, 자격증 Content 추가
   * --
   * @param {*} type
   */
  const handleContentList = (type) => {
    let d = { ...contentData };

    if (type === 'E') {
      const { e_title, e_sub_title, e_state, e_date } = modalData;
      if (!e_title || e_title === '') {
        MessageAlert.warning('학교 이름을 입력해주세요');
        return;
      }

      if (!e_sub_title || e_sub_title === '') {
        MessageAlert.warning('전공을 입력해주세요');
        return;
      }

      if (!e_state) {
        MessageAlert.warning('졸업상태를 선택해주세요');
        return;
      }

      if (!e_date) {
        MessageAlert.warning('날짜를 선택해주세요');
        return;
      }

      let e = d['education'];
      const data = {
        school_name: e_title,
        major_name: e_sub_title,
        state: e_state,
        date: e_date,
        highest: false,
      };
      e.push(data);
      d['education'] = e;
    } else if (type === 'Ca') {
      const { ca_title, ca_term } = modalData;
      if (!ca_title || ca_title === '') {
        MessageAlert.warning('회사명을 입력해주세요');
        return;
      }

      if (!ca_term || !ca_term === '') {
        MessageAlert.warning('근무기간을 입력해주세요');
        return;
      }

      if (!validateCheck('number', ca_term)) {
        MessageAlert.warning('근무기간은 숫자만 입력해주세요');
        return;
      }

      let ca = d['career'];
      const cadata = {
        company_name: ca_title,
        term: `${ca_term}개월`,
      };
      ca.push(cadata);
      d['career'] = ca;
    } else {
      const { c_title, c_date } = modalData;
      if (!c_title || c_title === '') {
        MessageAlert.warning('자격증 명을 입력해주세요');
        return;
      }

      if (!c_date) {
        MessageAlert.warning('발급일을 선택해주세요');
        return;
      }

      let c = d['certificate'];
      const cdata = {
        certificate_name: c_title,
        date: c_date,
      };
      c.push(cdata);
      d['certificate'] = c;
    }

    setContentData(d);
    setIsOpened(false);
  };

  /**
   * Content 리스트 데이터 제거
   * --
   * @param {*} t
   * @param {*} i
   */
  const handleContentDelete = (t, i) => {
    setContentData((prevData) => {
      const newData = { ...prevData }; // 이전 데이터를 복사

      switch (t) {
        case 'E':
          newData.education = newData.education?.filter(
            (item, index) => index !== i
          );
          break;
        case 'C':
          newData.certificate = newData.certificate?.filter(
            (item, index) => index !== i
          );
          break;
        case 'Ca':
          newData.career = newData.career?.filter((item, index) => index !== i);
          break;
        default:
          break;
      }

      return newData; // 새로운 데이터로 업데이트
    });
  };

  /**
   * 전문분야 아이템 RENDER
   * --
   */
  const handleMajorItems = (data, index) => {
    // const { major, major_detail, level } = data;
    const { major, major_detail } = data;
    const m = categories?.filter(
      (item) => parseInt(item?.category_id) === parseInt(major)
    )[0];

    return (
      <p
        key={`${major}-${major_detail}-${index}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: screenSize >= 768 ? 12 : 0,
          justifyContent: 'space-between',
        }}
      >
        <strong
          style={{
            ...styles.containerItem,
            width: screenSize >= 768 ? 'auto' : 'calc(95% - 30px)',
          }}
        >
          {m?.category_name}
        </strong>
        <button
          style={{
            border: '1px solid #EEEEEE',
            background: 'none',
            cursor: 'pointer',
            width: 30,
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            fontSize: 17,
            fontWeight: 700,
          }}
          onClick={() => handleList('major', 'delete', index)}
        >
          -
        </button>
      </p>
    );
  };

  /**
   * 유튜브 링크 추가
   * --
   */
  const handleYoutubeLink = (type, idx) => {
    if (type === 'add') {
      const regex =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)/;

      if (!regex.test(youtubeInput)) {
        return MessageAlert.warning('유튜브 링크를 입력해주세요!');
      }
      setYoutubeList((prev) => [...youtubeList, youtubeInput]);
      setYoutubeInput('');
    } else {
      const newList = youtubeList?.filter((iten, index) => index !== idx);
      setYoutubeList(newList);
    }
  };

  /* ===== Hooks ===== */
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /**
   * 모달창이 닫힐 때 입력 값 초기화
   * --
   */
  useEffect(() => {
    if (!isOpened) {
      setModalData({});
    }
  }, [isOpened]);

  /**
   * 전문분야 아이템 정제
   * --
   */
  useEffect(() => {
    setMajorList(majorItems);
  }, [majorItems]);

  /**
   * 상세분야 아이템 정제
   * --
   */
  // useEffect(() => {
  //   const id = parseInt(majorData?.major);

  //   const childrenMajor = categories?.filter((item) => item.parents_id === id);
  //   const filterArr = childrenMajor?.filter((item) => {
  //     const hasMatchingItem = majorArr?.some(
  //       (subitem) => item?.category_id === subitem?.category_id
  //     );

  //     return !hasMatchingItem;
  //   });
  //   setMajorDetailList(filterArr);
  // }, [majorData?.major, categories, majorArr]);

  /**
   * 전문분야 값 변경 시 상세분야/분야수준 값 초기화
   * --
   */
  useEffect(() => {
    // setMajorData({
    //   major: majorData?.major,
    // });
    const id = parseInt(majorData?.major);
    const childrenMajor = categories?.filter((item) => item.parents_id === id);
    const filterArr = childrenMajor?.filter((item) => {
      const hasMatchingItem = majorArr?.some(
        (subitem) => item?.category_id === subitem?.category_id
      );
      return !hasMatchingItem;
    });
    const findMajor = majorArr?.filter(
      (item) => item?.major === majorData?.major
    );
    if (findMajor?.length === 0 && majorData?.major) {
      setMajorArr((prev) => [
        ...prev,
        {
          major: majorData?.major,
          major_detail: filterArr?.map((item) => item.category_id)[0],
        },
      ]);
      setMajorData({});
    }
  }, [majorData?.major, categories, majorArr]);

  /**
   * 선호 지하철역 중복 데이터 제외
   * --
   */
  useEffect(() => {
    const list = preferenceSubwayList?.map((item) => item.subway_id);
    const newList = subways?.filter((item) => !list.includes(item.subway_id));

    setNewSubways(newList);
  }, [preferenceSubwayList, subways]);

  /**
   * Update 데이터 렌더링
   * --
   */
  useEffect(() => {
    const {
      profile,
      snsList,
      areaList,
      subwayList,
      major,
      files,
      profileImage,
    } = Data;

    if (!profile) {
      setType('create');
      return;
    }

    if (Data && isFetching === 0) {
      if (!rendering) {
        if (profile) {
          const { content, video_url, ...rest } = profile;
          setProfileData({ ...rest });

          const splitContent = (data) => {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(data, 'text/html');

            // 클래스 이름으로 요소 가져오기
            const educationElement = htmlDoc
              ?.querySelector('.education')
              ?.querySelectorAll('p');
            const careerElement = htmlDoc
              ?.querySelector('.career')
              ?.querySelectorAll('p');
            const certificateElement = htmlDoc
              ?.querySelector('.certificate')
              ?.querySelectorAll('p');
            const introduceElement = htmlDoc
              ?.querySelector('.introduce')
              ?.querySelector('pre').innerText;

            // 가져온 요소를 콘솔에 출력
            let eduList = [],
              careerList = [],
              certifiList = [];
            educationElement?.forEach((item) => eduList.push(item.innerText));
            careerElement?.forEach((item) => careerList.push(item.innerText));
            certificateElement?.forEach((item) =>
              certifiList.push(item.innerText)
            );

            const newEducation = eduList?.map((item, index) => {
              const d = item?.split('∙');
              const [school_name, major_name, state, date] = d;

              const name = school_name?.split('-')[1].trim();
              const major = major_name.trim();
              const status = state.trim();
              const day = date.trim();

              return {
                school_name: name,
                major_name: major,
                state: status,
                date: day,
                highest:
                  educationElement[index]?.className === 'on' ? true : false,
              };
            });

            const newCareer = careerList?.map((item) => {
              const d = item?.split('∙');
              const [company_name, term] = d;

              const name = company_name?.split('-')[1].trim();
              const t = term.trim();

              return {
                company_name: name,
                term: t,
              };
            });

            const newCertificate = certifiList?.map((item) => {
              const d = item?.split('∙');
              const [certificate_name, date] = d;

              const name = certificate_name?.split('-')[1].trim();
              const t = date.trim();

              return {
                certificate_name: name,
                date: t,
              };
            });

            return {
              education: newEducation,
              career: newCareer,
              certificate: newCertificate,
              introduce: introduceElement,
            };
          };
          const data = splitContent(content);
          setContentData({
            ...data,
          });
          setYoutubeList(video_url ? video_url : []);
        }

        if (snsList) {
          setSnsList([...Data?.snsList]);
        }

        if (areaList) {
          let arr = [];
          const list = [...cityList];
          areaList?.map((item, index) => {
            const newCity = address?.city?.filter(
              (subitem) => !arr.includes(subitem.city_id)
            );
            const filterCity = newCity?.filter(
              (subitem) => item.local_id === subitem.local_id
            );
            list[index] = filterCity;

            return arr.push(item);
          });
          setCityList(list);
          setPreferenceAreaList(Data?.areaList);
        }

        if (subwayList) {
          const newArr = subwayList?.map((item) => ({
            ...item,
            ...(subways &&
              subways?.filter(
                (subitem) => subitem.subway_id === item.subway_id
              )[0]),
          }));
          setPreferenceSubwayList(newArr);
        }

        if (major) {
          setMajorArr([...Data?.major]);
        }

        if (profileImage) {
          const downloadFile = async (fileUrl) => {
            try {
              const response = await axios.post(`${HOST_URL}/file/blob`, {
                key: fileUrl,
              });
              const blob = response.data.data.data;
              return blob;
            } catch (error) {
              console.error('Error while downloading file:', error);
              throw error;
            }
          };

          const profileFile = async () => {
            const blob = await downloadFile(
              `brainheal/users/${getCookie('USER')}/${profileImage.file_url}`
            );
            const uint8Array = new Uint8Array(blob);
            const p = new File([uint8Array], profileImage.file_name, {
              type: 'image/*',
            });
            setProfileImg(p);
          };
          profileFile();
        }

        if (files && files?.length > 0) {
          const downloadFile = async (fileUrl) => {
            try {
              const response = await axios.post(`${HOST_URL}/file/blob`, {
                key: fileUrl,
              });
              const blob = response.data.data.data;
              return blob;
            } catch (error) {
              console.error('Error while downloading file:', error);
              throw error;
            }
          };

          // 갤러리 파일
          const gfiles = files?.filter(
            (item) =>
              item?.type === 'G' &&
              parseInt(item?.user_id) === parseInt(decrypt(userId))
          );
          // 기타 첨부파일
          const efiles = files?.filter(
            (item) =>
              item?.type === 'E' &&
              parseInt(item?.user_id) === parseInt(decrypt(userId))
          );
          // 자격증 파일
          const cfiles = files?.filter(
            (item) =>
              item?.type === 'C' &&
              parseInt(item?.user_id) === parseInt(decrypt(userId))
          );

          if (gfiles?.length) {
            const f = gfiles?.map(async (item) => {
              const blob = await downloadFile(
                `brainheal/users/${item?.user_id}/${item.file_url}`
              );
              const uint8Array = new Uint8Array(blob);
              return new File([uint8Array], item.file_name, {
                type: `image/*`,
              });
            });

            Promise.all(f).then((fileList) => {
              setFileList(fileList);
            });
          }

          if (efiles?.length) {
            const f = efiles?.map(async (item) => {
              const blob = await downloadFile(
                `brainheal/users/${item?.user_id}/${item.file_url}`
              );
              const uint8Array = new Uint8Array(blob);
              return new File([uint8Array], item.file_name, {
                type: `image/*`,
              });
            });

            Promise.all(f).then((fileList) => {
              setAttachmentList(fileList);
            });
          }

          if (cfiles?.length) {
            const f = cfiles?.map(async (item) => {
              const blob = await downloadFile(
                `brainheal/users/${item?.user_id}/${item.file_url}`
              );
              const uint8Array = new Uint8Array(blob);
              return new File([uint8Array], item.file_name, {
                type: `image/*`,
              });
            });

            Promise.all(f).then((fileList) => {
              setCertificateList(fileList);
            });
          }
        }

        setRendering(true);
        setType('update');
      }
    }
  }, [rendering, Data, cityList, subways, address, isFetching, userId]);

  /**
   * 일반회원, 기관 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'user' || loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /* ===== Styles ===== */
  const styles = {
    affixText: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '19px',
      color: '#000000',
    },
    affixIcon: {
      display: 'inline-block',
      marginRight: '11px',
    },
    list: {
      height: '44px',
      display: 'flex',
      alignItems: 'center',
      padding: '12px 8px',
    },
    modalTitle: {
      fontSize: '30px',
      fontWeight: 700,
      lineHeight: '36px',
      color: '#000000',
      marginBottom: '48px',
    },
    modalLabel: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.02em',
      color: '#000000',
    },
    listStyle: {
      borderRadius: '4px',
      background: '#F2D3DB',
      padding: '15px 20px',
      marginBottom: '24px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    listText: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '19px',
    },
    listButton: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '19px',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
    },
    title: {
      fontSize: screenSize >= 768 ? 26 : 18,
      fontWeight: 700,
      padding: '8px 13px 12px',
      borderBottom: '1px solid #9E9E9E',
      marginBottom: '24px',
      justifyContent: 'flex-start',
    },
    container: {
      marginTop: screenSize >= 768 ? 48 : 20,
    },
    containerTitle: {
      fontSize: screenSize >= 768 ? 20 : 16,
      fontWeight: 700,
    },
    containerItem: {
      fontSize: screenSize >= 768 ? 18 : 14,
      fontWeight: 400,
      color: '#000000',
      padding: 10,
      background: '#F2D3DB',
      borderRadius: '4px',
      marginRight: screenSize >= 768 ? 12 : 0,
      marginTop: screenSize >= 768 ? 0 : 5,
    },
    essential: {
      color: '#F93232',
      fontSize: screenSize >= 768 ? 16 : 14,
      fontWeight: 400,
      lineHeight: screenSize >= 768 ? '19px' : '16px',
      paddingLeft: screenSize >= 768 ? 12 : 5,
    },
  };

  /* ===== Render ===== */
  return (
    <>
      <Content
        maxWidth={'100%'}
        padding={screenSize >= 768 ? 0 : 10}
        style={{ margin: '48px 0 84px' }}
      >
        <Content
          padding={screenSize >= 480 ? 40 : 20}
          maxWidth={1200}
          style={{ border: '1px solid #9E9E9E', borderRadius: '8px' }}
        >
          <Row>
            {/* 기본정보 */}
            <Col x={24}>
              <Content
                maxWidth={'100%'}
                padding={0}
                style={{
                  display: 'flex',
                  flexDirection: screenSize >= 768 ? 'row' : 'column',
                }}
              >
                <div
                  style={
                    screenSize >= 768
                      ? {}
                      : {
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }
                  }
                >
                  {/* 프로필 이미지 */}
                  <Content
                    maxWidth={156}
                    height={200}
                    style={{
                      margin: 0,
                      background: createObjectURL
                        ? `url(${createObjectURL}) no-repeat center/cover`
                        : Data?.profileImage
                        ? `url(${STORE_URL}/users/${parseInt(
                            decrypt(userId)
                          )}/${
                            Data?.profileImage?.file_url
                          }) no-repeat center/cover`
                        : '#EEEEEE',
                      // backgroundSize: 'cover',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #EEEEEE',
                    }}
                    padding={0}
                  >
                    {!createObjectURL && !Data?.profileImage && (
                      <PictureOutlined style={{ fontSize: '30px' }} />
                    )}
                  </Content>
                  <FileUploadButton
                    onChange={handleProfileImage}
                    layoutStyle={{
                      maxWidth: 156,
                      width: '100%',
                      height: '56px',
                      marginTop: '12px',
                      background: '#9E9E9E',
                    }}
                    style={{
                      textAlign: 'center',
                      fontSize: '20px',
                      fontWeight: 700,
                      color: '#FFFFFF',
                    }}
                    accept="image/*"
                  >
                    프로필 사진 등록
                  </FileUploadButton>
                </div>

                <div
                  style={{
                    marginLeft: screenSize >= 768 ? 40 : 0,
                    width: screenSize >= 480 ? '50%' : '100%',
                    marginTop: screenSize >= 768 ? 0 : 30,
                  }}
                >
                  <Title
                    style={{
                      justifyContent: 'flex-start',
                      fontSize: screenSize >= 768 ? 26 : 20,
                      fontWeight: 700,
                    }}
                  >
                    {Data?.user?.name}
                    <span
                      style={{
                        display: 'inline-block',
                        marginLeft: '8px',
                        fontSize: screenSize >= 768 ? 16 : 14,
                        fontWeight: 400,
                      }}
                    >
                      강사
                    </span>
                  </Title>
                  <div style={{ marginTop: '24px' }}>
                    <Col x={24}>
                      {/* 성별 */}
                      <Row>
                        <Col x={8} md={6}>
                          <p style={{ fontSize: '16px', fontWeight: 700 }}>
                            <span
                              style={{
                                display: 'inline-block',
                                marginRight: '10px',
                              }}
                            >
                              ∙
                            </span>
                            성별
                          </p>
                        </Col>
                        <Col x={16} md={18}>
                          <p style={{ fontSize: '16px', fontWeight: 400 }}>
                            {Data?.user?.gender === 'M' ? '남' : '여'}
                          </p>
                        </Col>
                      </Row>
                      {/* 생년월일 */}
                      <Row style={{ marginTop: '12px' }}>
                        <Col x={8} md={6}>
                          <p style={{ fontSize: '16px', fontWeight: 700 }}>
                            <span
                              style={{
                                display: 'inline-block',
                                marginRight: '10px',
                              }}
                            >
                              ∙
                            </span>
                            생년월일
                          </p>
                        </Col>
                        <Col x={16} md={18}>
                          <p
                            style={{ fontSize: '16px', fontWeight: 400 }}
                          >{`${moment(Data?.user?.birthday).format(
                            'YYYY'
                          )}년생 (만 ${
                            calculateAge(Data?.user?.birthday)?.age
                          }세)`}</p>
                        </Col>
                      </Row>
                      {/* 직업 */}
                      <Row style={{ marginTop: '12px' }}>
                        <Col x={8} md={6}>
                          <p style={{ fontSize: '16px', fontWeight: 700 }}>
                            <span
                              style={{
                                display: 'inline-block',
                                marginRight: '10px',
                              }}
                            >
                              ∙
                            </span>
                            직업
                          </p>
                        </Col>
                        <Col x={16} md={18}>
                          <p style={{ fontSize: '16px', fontWeight: 400 }}>
                            {Data?.user?.job}
                          </p>
                        </Col>
                      </Row>
                      {/* 이메일 */}
                      <Row style={{ marginTop: '12px' }}>
                        <Col x={8} md={6}>
                          <p style={{ fontSize: '16px', fontWeight: 700 }}>
                            <span
                              style={{
                                display: 'inline-block',
                                marginRight: '10px',
                              }}
                            >
                              ∙
                            </span>
                            이메일
                          </p>
                        </Col>
                        <Col x={16} md={18}>
                          <p style={{ fontSize: '16px', fontWeight: 400 }}>
                            {Data?.user?.email}
                          </p>
                        </Col>
                      </Row>
                      {/* SNS */}
                      <Row
                        style={{
                          marginTop: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Col x={8} md={6}>
                          <p style={{ fontSize: '16px', fontWeight: 700 }}>
                            <span
                              style={{
                                display: 'inline-block',
                                marginRight: '10px',
                              }}
                            >
                              ∙
                            </span>
                            SNS
                          </p>
                        </Col>
                        <Col x={16} md={18}>
                          <Button
                            type="secondary"
                            color="default"
                            onClick={() => handleModal('S', isOpened)}
                          >
                            추가<span>+</span>
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col x={0} md={6}></Col>
                        <Col x={24} md={18}>
                          <div>
                            {snsList?.map((item, index) => (
                              <p
                                key={`sns_${item.platform}_${index}`}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: screenSize >= 768 ? 12 : 10,
                                }}
                              >
                                <a
                                  href={`${item?.address}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    display:
                                      screenSize >= 768
                                        ? 'inline-block'
                                        : 'flex',
                                    padding: '10px',
                                    fontSize: screenSize >= 768 ? 18 : 14,
                                    background: '#F2D3DB',
                                    borderRadius: '4px',
                                    alignItems: 'center',
                                    width: screenSize < 768 && '95%',
                                  }}
                                >
                                  {item.platform === 1 ? (
                                    <InstagramOutlined />
                                  ) : item.platform === 2 ? (
                                    <FacebookOutlined />
                                  ) : item.platform === 3 ? (
                                    <TwitterOutlined />
                                  ) : (
                                    <YoutubeOutlined />
                                  )}
                                  <span
                                    style={{
                                      display:
                                        screenSize >= 768
                                          ? 'inline-block'
                                          : 'block',
                                      paddingLeft: screenSize >= 768 ? 10 : 5,
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {item?.address}
                                  </span>
                                </a>
                                <button
                                  style={{
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer',
                                    marginLeft: 10,
                                  }}
                                  onClick={() =>
                                    handleList('sns', 'delete', index)
                                  }
                                >
                                  X
                                </button>
                              </p>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </div>
              </Content>
            </Col>
            {/* 전문 분야 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>
                  전문 분야
                  <span
                    style={{
                      ...styles.essential,
                    }}
                  >
                    필수
                  </span>
                </Title>
                <Col x={24}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Col x={10} md={6}>
                      <p
                        style={{
                          ...styles.containerTitle,
                        }}
                      >
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        분야
                      </p>
                    </Col>
                    <Col x={14} md={18}>
                      <Select
                        size="large"
                        placeholder="전문분야를 선택해주세요"
                        options={majorList?.map((item) => ({
                          label: `${item.category_name}`,
                          value: item.category_id,
                        }))}
                        value={majorData?.major}
                        onChange={(e) => handleChange('major', 'major', e)}
                        style={{
                          width: screenSize >= 768 ? 203 : '100%',
                          marginRight: screenSize >= 768 ? 26 : 0,
                          marginBottom: screenSize >= 768 ? 0 : 10,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col x={0} md={6}></Col>
                    <Col x={24} md={18}>
                      {majorArr?.length
                        ? majorArr?.map((item, index) =>
                            handleMajorItems(item, index)
                          )
                        : null}
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Col>
            {/* 활동 지역 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>
                  활동 지역
                  <span
                    style={{
                      ...styles.essential,
                    }}
                  >
                    필수
                  </span>
                </Title>
                <Col x={24}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '24px',
                    }}
                  >
                    <Col x={10} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        활동 지역
                      </p>
                    </Col>
                    <Col x={14} md={16}>
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          handleSearch(input, option)
                        }
                        size="large"
                        placeholder="선택해주세요"
                        onChange={(e) =>
                          handleChange('profile', 'activity_area', e)
                        }
                        options={address?.local?.map((item) => ({
                          value: item.local_id,
                          label: item.local_name,
                        }))}
                        style={{ width: screenSize >= 768 ? '307px' : '100%' }}
                        value={profileData?.activity_area}
                      />
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Col>
            {/* 선호지역 / 선호 지하철역 / 이동 가능 시간 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>
                  선호 지역
                  <span
                    style={{
                      ...styles.essential,
                    }}
                  >
                    필수
                  </span>
                </Title>
                <Col x={24}>
                  {preferenceAreaList?.map((_item, _index) => (
                    <Row
                      key={`area-${_index}`}
                      style={{
                        display: 'flex',
                        alignItems: screenSize >= 768 ? 'center' : 'stretch',
                        marginTop: _index !== 0 && '24px',
                      }}
                    >
                      <Col x={10} md={6}>
                        <p style={{ ...styles.containerTitle }}>
                          <span
                            style={
                              screenSize >= 768
                                ? {
                                    display: 'inline-block',
                                    margin: '0px 10px',
                                  }
                                : { display: 'none' }
                            }
                          >
                            ∙
                          </span>
                          {_index + 1}순위
                        </p>
                      </Col>
                      <Col x={14} md={18}>
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            handleSearch(input, option)
                          }
                          size="large"
                          options={address?.local?.map((item) => ({
                            value: item.local_id,
                            label: item.local_name,
                          }))}
                          placeholder="선택해주세요"
                          onChange={(e) => handleListChange(_index, 'local', e)}
                          value={preferenceAreaList[_index]?.local_id}
                          style={{
                            width:
                              screenSize >= 768
                                ? '203px'
                                : screenSize >= 480
                                ? '80%'
                                : '100%',
                            marginRight: screenSize >= 480 ? '26px' : 0,
                            marginBottom: screenSize >= 480 ? 0 : 10,
                          }}
                        />
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            handleSearch(input, option)
                          }
                          size="large"
                          options={cityList[_index]?.map((item) => ({
                            value: item.city_id,
                            label: item.city_name,
                          }))}
                          placeholder="선택해주세요"
                          onChange={(e) => handleListChange(_index, 'city', e)}
                          value={preferenceAreaList[_index]?.city_id}
                          style={{
                            width:
                              screenSize >= 768
                                ? '203px'
                                : screenSize >= 480
                                ? '80%'
                                : '100%',
                            marginBottom: screenSize >= 480 ? 0 : 10,
                          }}
                        />
                        {preferenceAreaList.length === 1 &&
                        preferenceAreaList.length === _index + 1 ? (
                          <div
                            style={{
                              position:
                                screenSize >= 480 ? 'absolute' : 'relative',
                              right: 0,
                              top: screenSize >= 480 ? '50%' : 0,
                              transform:
                                screenSize >= 480
                                  ? `translate(0, -50%)`
                                  : `translate(0, 0)`,
                              fontWeight: 700,
                              fontSize: '17px',
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px',
                              border: '1px solid #EEEEEE',
                              borderRadius: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            onClick={() =>
                              handleList('preference_area', 'add', _index)
                            }
                          >
                            +
                          </div>
                        ) : preferenceAreaList.length > 1 && _index === 0 ? (
                          <div
                            style={{
                              position:
                                screenSize >= 480 ? 'absolute' : 'relative',
                              right: 0,
                              top: screenSize >= 480 ? '50%' : 0,
                              transform:
                                screenSize >= 480
                                  ? `translate(0, -50%)`
                                  : `translate(0, 0)`,
                              fontWeight: 700,
                              fontSize: '17px',
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px',
                              border: '1px solid #EEEEEE',
                              borderRadius: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            onClick={() =>
                              handleList('preference_area', 'delete', _index)
                            }
                          >
                            -
                          </div>
                        ) : preferenceAreaList.length === 2 &&
                          preferenceAreaList.length === _index + 1 ? (
                          <div
                            style={{
                              position:
                                screenSize >= 480 ? 'absolute' : 'relative',
                              right: 0,
                              top: screenSize >= 480 ? '50%' : 0,
                              transform:
                                screenSize >= 480
                                  ? `translate(0, -50%)`
                                  : `translate(0, 0)`,
                              fontWeight: 700,
                              fontSize: '17px',
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent:
                                screenSize >= 480 ? 'center' : 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                width: '30px',
                                height: '30px',
                                border: '1px solid #EEEEEE',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '5px',
                              }}
                              onClick={() =>
                                handleList('preference_area', 'add', _index)
                              }
                            >
                              +
                            </p>
                            <p
                              style={{
                                width: '30px',
                                height: '30px',
                                border: '1px solid #EEEEEE',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              onClick={() =>
                                handleList('preference_area', 'delete', _index)
                              }
                            >
                              -
                            </p>
                          </div>
                        ) : (
                          <div
                            style={{
                              position:
                                screenSize >= 480 ? 'absolute' : 'relative',
                              right: 0,
                              top: screenSize >= 480 ? '50%' : 0,
                              transform:
                                screenSize >= 480
                                  ? `translate(0, -50%)`
                                  : `translate(0, 0)`,
                              fontWeight: 700,
                              fontSize: '17px',
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px',
                              border: '1px solid #EEEEEE',
                              borderRadius: '10px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            onClick={() =>
                              handleList('preference_area', 'delete', _index)
                            }
                          >
                            -
                          </div>
                        )}
                      </Col>
                    </Row>
                  ))}
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '24px',
                    }}
                  >
                    <Col x={10} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        선호 지하철역
                      </p>
                    </Col>
                    <Col x={14} md={18}>
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          handleSearch(input, option)
                        }
                        size="large"
                        placeholder="선택해주세요"
                        options={newSubways?.map((item) => ({
                          value: item.subway_id,
                          label: item.subway_name,
                        }))}
                        onChange={(e) =>
                          handleList('preference_subway', 'add', e)
                        }
                        style={{ width: screenSize >= 768 ? '307px' : '100%' }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col x={0} md={6}></Col>
                    <Col x={24} md={18}>
                      <div>
                        {preferenceSubwayList?.map((item, index) => (
                          <p
                            key={`subway_${index}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '12px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <strong
                              style={{
                                display:
                                  screenSize >= 768 ? 'inline-block' : 'block',
                                padding: '10px',
                                fontSize: screenSize >= 768 ? 18 : 12,
                                background: '#F2D3DB',
                                borderRadius: '4px',
                                width:
                                  screenSize >= 768
                                    ? 'auto'
                                    : 'calc(95% - 30px)',
                              }}
                            >
                              {item.subway_name}
                            </strong>
                            <button
                              style={{
                                border: '1px solid #EEEEEE',
                                background: 'none',
                                cursor: 'pointer',
                                width: 30,
                                height: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 10,
                                fontSize: 17,
                                fontWeight: 700,
                              }}
                              onClick={() =>
                                handleList('preference_subway', 'delete', index)
                              }
                            >
                              -
                            </button>
                          </p>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '24px',
                    }}
                  >
                    <Col x={10} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        이동 가능 시간
                      </p>
                    </Col>
                    <Col x={14} md={18}>
                      <Select
                        size="large"
                        placeholder="선택해주세요"
                        options={[
                          {
                            value: '30',
                            label: '30분',
                          },
                          {
                            value: '60',
                            label: '1시간',
                          },
                          {
                            value: '90',
                            label: '1시간30분',
                          },
                          {
                            value: '120',
                            label: '2시간',
                          },
                        ]}
                        value={profileData.available_time}
                        onChange={(e) =>
                          handleChange('profile', 'available_time', e)
                        }
                        style={{ width: screenSize >= 768 ? '307px' : '100%' }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Col>
            {/* 희망 급여 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>
                  희망 급여
                  <span
                    style={{
                      ...styles.essential,
                    }}
                  >
                    필수
                  </span>
                </Title>
                <Col x={24}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: screenSize >= 768 ? 'center' : 'stretch',
                    }}
                  >
                    <Col x={10} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        희망 월급(세전)
                      </p>
                    </Col>
                    <Col x={14} md={18}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems:
                            screenSize >= 768 ? 'center' : 'flex-start',
                          flexDirection: screenSize >= 768 ? 'row' : 'column',
                        }}
                      >
                        <Input
                          size="large"
                          placeholder="숫자만 입력해주세요"
                          value={
                            profileData?.salary ? profileData?.salary : null
                          }
                          suffix={'만원'}
                          onChange={(e) =>
                            handleChange('profile', 'salary', e.target.value)
                          }
                          style={{
                            width: screenSize >= 768 ? '307px' : '100%',
                          }}
                        />
                        <Checkbox
                          checked={
                            profileData?.negotiable_check === 'Y' ? true : false
                          }
                          onChange={(e) =>
                            e.target.checked
                              ? handleChange('profile', 'negotiable_check', 'Y')
                              : handleChange('profile', 'negotiable_check', 'N')
                          }
                          style={{ marginLeft: screenSize >= 480 ? '30px' : 0 }}
                        >
                          협의 가능
                        </Checkbox>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Col>
            {/* 한 즐 소개 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>
                  한 줄 소개
                  <span
                    style={{
                      ...styles.essential,
                    }}
                  >
                    필수
                  </span>
                </Title>
                <Col x={24}>
                  <Content maxWidth={'100%'} padding={0}>
                    <TextArea
                      showCount
                      maxLength={30}
                      style={{ height: 74 }}
                      placeholder="한 줄 소개를 입력해 주세요"
                      value={profileData?.profile_title}
                      onChange={(e) =>
                        handleChange('profile', 'profile_title', e.target.value)
                      }
                    />
                  </Content>
                </Col>
              </Content>
            </Col>
            {/* 학력 / 경력 / 자격증 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>학력 ∙ 경력 ∙ 자격증</Title>
                <Col x={24}>
                  {/* 학력 */}
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col x={24} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        학력
                      </p>
                    </Col>
                    <Col x={24} md={18}>
                      <Content
                        maxWidth={'100%'}
                        padding={0}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Button
                          type="secondary"
                          color="default"
                          onClick={() => handleModal('E', isOpened)}
                          layoutStyle={{
                            margin:
                              screenSize >= 768 ? '0 26px 0 0' : '0 10px 0 0',
                          }}
                        >
                          추가<span>+</span>
                        </Button>
                        <Select
                          size="large"
                          placeholder="최종학력을 선택해주세요"
                          style={{
                            width:
                              screenSize >= 768
                                ? '307px'
                                : 'calc(100% - 100px)',
                          }}
                          value={
                            contentData?.education?.findIndex(
                              (item) => item.highest
                            ) === null ||
                            contentData?.education?.findIndex(
                              (item) => item.highest
                            ) === undefined ||
                            contentData?.education?.findIndex(
                              (item) => item.highest
                            ) === -1
                              ? null
                              : contentData?.education?.findIndex(
                                  (item) => item.highest
                                )
                          }
                          options={contentData?.education?.map(
                            (item, index) => ({
                              label: item?.school_name,
                              value: index,
                            })
                          )}
                          onChange={(e) => {
                            let d = { ...contentData };
                            let edu = d['education'];
                            const newData = edu?.map((item, index) => ({
                              ...item,
                              highest: e === index ? true : false,
                            }));
                            d['education'] = newData;
                            setContentData(d);
                          }}
                        />
                      </Content>
                    </Col>
                    <Col x={0} md={6}></Col>
                    <Col x={24} md={18}>
                      {contentData?.education?.map((item, index) => (
                        <p
                          key={`education_${index}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: screenSize >= 768 ? 12 : 5,
                          }}
                        >
                          <strong
                            style={{
                              ...styles.containerItem,
                            }}
                          >
                            {`${item.school_name} ∙ ${item?.major_name} ∙ ${
                              item?.state
                            } ∙ ${moment(item?.date).format('YYYY.MM.DD')}`}
                          </strong>
                          <button
                            style={{
                              border: 'none',
                              background: 'none',
                              cursor: 'pointer',
                              marginLeft: '10px',
                            }}
                            onClick={() => handleContentDelete('E', index)}
                          >
                            X
                          </button>
                        </p>
                      ))}
                    </Col>
                  </Row>
                  {/* 경력 */}
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '24px',
                    }}
                  >
                    <Col x={24} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        경력
                      </p>
                    </Col>
                    <Col x={24} md={18}>
                      <Button
                        type="secondary"
                        color="default"
                        onClick={() => handleModal('Ca', isOpened)}
                      >
                        추가<span>+</span>
                      </Button>
                    </Col>
                    <Col x={0} md={6}></Col>
                    <Col x={24} md={18}>
                      {contentData?.career?.map((item, index) => (
                        <p
                          key={`career${index}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: screenSize >= 768 ? 12 : 5,
                          }}
                        >
                          <strong
                            style={{
                              ...styles.containerItem,
                            }}
                          >
                            {`${item?.company_name} ∙ ${item?.term}`}
                          </strong>
                          <button
                            style={{
                              border: 'none',
                              background: 'none',
                              cursor: 'pointer',
                              marginLeft: '10px',
                            }}
                            onClick={() => handleContentDelete('Ca', index)}
                          >
                            X
                          </button>
                        </p>
                      ))}
                    </Col>
                  </Row>
                  {/* 자격증 */}
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '24px',
                    }}
                  >
                    <Col x={24} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        자격증
                      </p>
                    </Col>
                    <Col x={24} md={18}>
                      <Button
                        type="secondary"
                        color="default"
                        onClick={() => handleModal('C', isOpened)}
                      >
                        추가<span>+</span>
                      </Button>
                    </Col>
                    <Col x={0} md={6}></Col>
                    <Col x={24} md={18}>
                      {contentData?.certificate?.map((item, index) => (
                        <p
                          key={`certificate_${index}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: screenSize >= 768 ? 12 : 5,
                          }}
                        >
                          <strong
                            style={{
                              ...styles.containerItem,
                            }}
                          >
                            {`${item.certificate_name} ∙ ${moment(
                              item?.date
                            ).format('YYYY.MM.DD')}`}
                          </strong>
                          <button
                            style={{
                              border: 'none',
                              background: 'none',
                              cursor: 'pointer',
                              marginLeft: '10px',
                            }}
                            onClick={() => handleContentDelete('C', index)}
                          >
                            X
                          </button>
                        </p>
                      ))}
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Col>
            {/* 자기소개 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>
                  자기소개
                  <span
                    style={{
                      ...styles.essential,
                    }}
                  >
                    필수
                  </span>
                </Title>

                <Col x={24}>
                  <Content maxWidth={'100%'} padding={0}>
                    <TextArea
                      showCount
                      maxLength={1000}
                      style={{ height: 150 }}
                      placeholder="자기소개를 입력해 주세요"
                      value={contentData?.introduce}
                      onChange={(e) =>
                        handleContentData('introduce', e.target.value)
                      }
                    />
                  </Content>
                </Col>
              </Content>
            </Col>
            {/* 자격증 파일 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>자격증 첨부파일</Title>
                <Col x={24}>
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col x={10} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        파일 등록
                      </p>
                    </Col>
                    <Col x={14} md={18}>
                      <FileUploadButton
                        layoutStyle={{
                          width: screenSize >= 768 ? '307px' : '100%',
                          height: screenSize >= 768 ? 54 : 40,
                        }}
                        onChange={handleCerificateFileChange}
                      >
                        + 파일추가
                      </FileUploadButton>
                    </Col>
                    <Col x={0} md={6}></Col>
                    <Col x={24} md={18}>
                      {certificateList &&
                        certificateList?.length > 0 &&
                        certificateList?.map((item, index) => (
                          <div
                            key={`file-${index}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: screenSize >= 768 ? 17 : 14,
                              marginTop: screenSize >= 768 ? 12 : 5,
                            }}
                          >
                            <p
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {item?.name}
                            </p>
                            <button
                              style={{
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontWeight: 700,
                                marginLeft: '10px',
                              }}
                              onClick={() =>
                                handleRemoveCertificateFileList(index)
                              }
                            >
                              X
                            </button>
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Col>
            {/* 기타 첨부파일 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>기타 첨부파일</Title>
                <Col x={24}>
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col x={10} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 580
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        파일 등록
                      </p>
                    </Col>
                    <Col x={14} md={18}>
                      <FileUploadButton
                        layoutStyle={{
                          width: screenSize >= 768 ? '307px' : '100%',
                          height: screenSize >= 768 ? 54 : 40,
                        }}
                        onChange={handleAttachmentFileChange}
                      >
                        + 파일추가
                      </FileUploadButton>
                    </Col>
                    <Col x={0} md={6}></Col>
                    <Col x={24} md={18}>
                      {attachmentList &&
                        attachmentList?.length > 0 &&
                        attachmentList?.map((item, index) => (
                          <div
                            key={`file-${index}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: screenSize >= 768 ? 17 : 14,
                              marginTop: screenSize >= 768 ? 12 : 5,
                            }}
                          >
                            <p
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {item?.name}
                            </p>
                            <button
                              style={{
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontWeight: 700,
                                marginLeft: '10px',
                              }}
                              onClick={() =>
                                handleRemoveAttachmentFileList(index)
                              }
                            >
                              X
                            </button>
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Col>
            {/* 갤러리 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>갤러리</Title>
                <Col x={24}>
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col x={10} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        이미지 등록
                      </p>
                    </Col>
                    <Col x={14} md={18}>
                      {' '}
                      <FileUploadButton
                        layoutStyle={{
                          width: screenSize >= 768 ? '307px' : '100%',
                          height: screenSize >= 768 ? 54 : 40,
                        }}
                        onChange={handleFileChange}
                        accept={'image/*'}
                      >
                        + 파일추가
                      </FileUploadButton>
                    </Col>
                    <Col x={0} md={6}></Col>
                    <Col x={24} md={18}>
                      {fileList &&
                        fileList?.length > 0 &&
                        fileList?.map((item, index) => (
                          <div
                            key={`file-${index}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '17px',
                              marginTop: '12px',
                            }}
                          >
                            <p
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {item?.name}
                            </p>
                            <button
                              style={{
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontWeight: 700,
                                marginLeft: '10px',
                              }}
                              onClick={() => handleRemoveFileList(index)}
                            >
                              X
                            </button>
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Col>
            {/* 유튜브 영상 */}
            <Col x={24} style={{ ...styles.container }}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.title }}>유튜브 영상</Title>
                <Col x={24}>
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col x={10} md={6}>
                      <p style={{ ...styles.containerTitle }}>
                        <span
                          style={
                            screenSize >= 768
                              ? {
                                  display: 'inline-block',
                                  margin: '0px 10px',
                                }
                              : { display: 'none' }
                          }
                        >
                          ∙
                        </span>
                        링크추가
                      </p>
                    </Col>
                    <Col x={14} md={18}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Input
                          size="large"
                          value={youtubeInput}
                          onChange={(e) => setYoutubeInput(e.target.value)}
                          placeholder="유튜브 링크를 입력해주세요"
                          style={{
                            width: screenSize >= 768 ? 307 : '75%',
                            height: screenSize >= 768 ? 54 : 40,
                          }}
                          onPressEnter={() => handleYoutubeLink('add')}
                        />
                        <Button
                          layoutStyle={{ margin: 0, width: '25%' }}
                          style={{
                            height: screenSize >= 768 ? 54 : 40,
                            width: screenSize >= 768 ? 'auto' : '100%',
                            wordBreak: 'keep-all',
                          }}
                          onClick={() => handleYoutubeLink('add')}
                        >
                          추가
                        </Button>
                      </div>
                    </Col>
                    <Col x={0} md={6}></Col>
                    <Col x={24} md={18}>
                      {youtubeList &&
                        youtubeList?.length > 0 &&
                        youtubeList?.map((item, index) => (
                          <div
                            key={`youtube-${index}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: screenSize >= 768 ? 17 : 14,
                              marginTop: screenSize >= 768 ? 12 : 5,
                            }}
                          >
                            <a href={item}>{item}</a>
                            <button
                              style={{
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontWeight: 700,
                                marginLeft: '10px',
                              }}
                              onClick={() => handleYoutubeLink('remove', index)}
                            >
                              X
                            </button>
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Col>
          </Row>
        </Content>
        <Content maxWidth={'100%'} padding={0} style={{ marginTop: '60px' }}>
          <Button
            style={{
              width: screenSize >= 768 ? '282px' : '50%',
              height: screenSize >= 768 ? 79 : 40,
              borderRadius: '4px',
              margin: '0 auto',
              fontSize: screenSize >= 768 ? 20 : 16,
              lineHeight: '24px',
              fontWeight: 700,
            }}
            onClick={() => handleSubmit()}
          >
            프로필 저장
          </Button>
        </Content>
      </Content>

      {/* 자격증 / 학력 및 전공 / SNS / 경력 모달 */}
      <ModalLayout
        type={screenSize >= 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize >= 768 ? 556 : '100%'}
        height={screenSize >= 768 ? '50%' : '80%'}
        closable
        bodyStyle={{ padding: screenSize >= 768 ? 40 : 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            {modalType === 'C' ? (
              // NOTE: 자격증
              <>
                <Col x={24}>
                  <Title align={'center'} style={{ ...styles.modalTitle }}>
                    자격증
                  </Title>
                </Col>
                <Col x={24}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label htmlFor="c_title" style={{ ...styles.modalLabel }}>
                        자격증명
                      </label>
                    </Col>
                    <Col x={18}>
                      <Input
                        id="c_title"
                        size="large"
                        value={modalData.c_title}
                        onChange={(e) =>
                          handleChange('modal', 'c_title', e.target.value)
                        }
                        placeholder="자격증명을 입력해주세요."
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label htmlFor="c_date" style={{ ...styles.modalLabel }}>
                        발급일
                      </label>
                    </Col>
                    <Col x={18}>
                      <DatePicker
                        id="c_date"
                        size="large"
                        style={{ width: '100%' }}
                        popupStyle={{ zIndex: 3600 }}
                        value={
                          modalData.c_date ? moment(modalData?.c_date) : null
                        }
                        onChange={(date, dateString) => {
                          handleChange('modal', 'c_date', dateString);
                        }}
                        inputReadOnly
                        placeholder="발급일을 선택해주세요."
                      />
                    </Col>
                  </Row>
                </Col>
                <Col x={24}>
                  <Button
                    onClick={() => handleContentList('C')}
                    style={{
                      width: '100%',
                      height: '76px',
                      marginTop: '24px',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                  >
                    적용
                  </Button>
                </Col>
              </>
            ) : modalType === 'E' ? (
              // NOTE: 학력 및 전공
              <>
                <Col x={24}>
                  <Title align={'center'} style={{ ...styles.modalTitle }}>
                    학력 및 전공
                  </Title>
                </Col>
                <Col x={24}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label htmlFor="e_title" style={{ ...styles.modalLabel }}>
                        학교명
                      </label>
                    </Col>
                    <Col x={18}>
                      <Input
                        id="e_title"
                        size="large"
                        value={modalData.e_title}
                        onChange={(e) =>
                          handleChange('modal', 'e_title', e.target.value)
                        }
                        placeholder="학교명을 입력해주세요."
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label
                        htmlFor="e_sub_title"
                        style={{ ...styles.modalLabel }}
                      >
                        전공
                      </label>
                    </Col>
                    <Col x={18}>
                      <Input
                        id="e_sub_title"
                        size="large"
                        value={modalData.e_sub_title}
                        onChange={(e) =>
                          handleChange('modal', 'e_sub_title', e.target.value)
                        }
                        placeholder="전공을 입력해주세요."
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label htmlFor="e_state" style={{ ...styles.modalLabel }}>
                        상태
                      </label>
                    </Col>
                    <Col x={18}>
                      <Select
                        id="e_state"
                        size="large"
                        options={[
                          {
                            value: '졸업',
                            label: '졸업',
                          },
                          {
                            value: '휴학',
                            label: '휴학',
                          },
                          {
                            value: '재학',
                            label: '재학',
                          },
                          {
                            value: '중퇴',
                            label: '중퇴',
                          },
                        ]}
                        style={{ width: '100%' }}
                        dropdownStyle={{ zIndex: 3600 }}
                        value={modalData.e_state}
                        onChange={(e) => handleChange('modal', 'e_state', e)}
                        placeholder="학적상태를 선택해주세요."
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label htmlFor="e_date" style={{ ...styles.modalLabel }}>
                        날짜
                      </label>
                    </Col>
                    <Col x={18}>
                      <DatePicker
                        id="e_date"
                        size="large"
                        style={{ width: '100%' }}
                        popupStyle={{ zIndex: 3600 }}
                        value={
                          modalData.e_date ? moment(modalData.e_date) : null
                        }
                        onChange={(d, s) => handleChange('modal', 'e_date', s)}
                        inputReadOnly
                        placeholder="날짜를 선택해주세요."
                      />
                    </Col>
                  </Row>
                </Col>
                <Col x={24}>
                  <Button
                    onClick={() => handleContentList('E')}
                    style={{
                      width: '100%',
                      height: '76px',
                      marginTop: '24px',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                  >
                    적용
                  </Button>
                </Col>
              </>
            ) : modalType === 'S' ? (
              // NOTE: SNS
              <>
                <Col x={24}>
                  <Title align={'center'} style={{ ...styles.modalTitle }}>
                    SNS
                  </Title>
                </Col>
                <Col x={24}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label htmlFor="c_title" style={{ ...styles.modalLabel }}>
                        플랫폼
                      </label>
                    </Col>
                    <Col x={18}>
                      <Select
                        id="s_icon"
                        size="large"
                        options={platform}
                        style={{ width: '100%' }}
                        dropdownStyle={{ zIndex: 3600 }}
                        value={snsData.platform}
                        defaultValue={1}
                        onChange={(e) => handleChange('sns', 'platform', e)}
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label
                        htmlFor="s_address"
                        style={{ ...styles.modalLabel }}
                      >
                        주소
                      </label>
                    </Col>
                    <Col x={18}>
                      <Input
                        id="s_address"
                        size="large"
                        value={snsData.address}
                        onChange={(e) =>
                          handleChange('sns', 'address', e.target.value)
                        }
                        placeholder="SNS 주소를 입력해주세요."
                      />
                    </Col>
                  </Row>
                </Col>
                <Col x={24}>
                  <Button
                    onClick={() => handleList('sns', 'add', snsData)}
                    style={{
                      width: '100%',
                      height: '76px',
                      marginTop: '24px',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                  >
                    적용
                  </Button>
                </Col>
              </>
            ) : (
              // NOTE: 경력
              <>
                <Col x={24}>
                  <Title align={'center'} style={{ ...styles.modalTitle }}>
                    경력
                  </Title>
                </Col>
                <Col x={24}>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label
                        htmlFor="ca_title"
                        style={{ ...styles.modalLabel }}
                      >
                        회사명
                      </label>
                    </Col>
                    <Col x={18}>
                      <Input
                        id="ca_title"
                        size="large"
                        value={modalData.ca_title}
                        onChange={(e) =>
                          handleChange('modal', 'ca_title', e.target.value)
                        }
                        placeholder="회사명을 입력해주세요."
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      marginBottom: '24px',
                    }}
                  >
                    <Col x={6}>
                      <label htmlFor="p_date" style={{ ...styles.modalLabel }}>
                        근무기간
                      </label>
                    </Col>
                    <Col x={18}>
                      <Input
                        id="ca_term"
                        size="large"
                        value={modalData?.ca_term ? modalData?.ca_term : null}
                        onChange={(e) =>
                          handleChange('modal', 'ca_term', e.target.value)
                        }
                        suffix={'개월'}
                        placeholder="근무기간을 입력해주세요."
                      />
                    </Col>
                  </Row>
                </Col>
                <Col x={24}>
                  <Button
                    onClick={() => handleContentList('Ca')}
                    style={{
                      width: '100%',
                      height: '76px',
                      marginTop: '24px',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                  >
                    적용
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

Profile.defaultProps = {};

export default Profile;
