/**
 *
 *
 *
 */
import React, { useState, useEffect } from 'react';
import {
  Button,
  // Avatar
} from 'antd';
import {
  Title,
  Row,
  Col,
  Card,
  Content,
  Container,
  Category,
  MainSlide,
  ProductSlide,
  // Popup,
  // Button as Btn,
} from 'components';
// import sub_banner_01 from 'assets/bg/sub_banner_01.png';
// import amazon from 'assets/icons/amazon_icon.png';
// import airbnb from 'assets/icons/airbnb_icon.png';
// import slack from 'assets/icons/slack_icon.png';
// import paypal from 'assets/icons/paypal_icon.png';
// import spotify from 'assets/icons/spotify_icon.png';
import mainBg from 'assets/bg/kenny-eliaso.png';
import mask from 'assets/bg/main_bg_mask.png';
import iconsOnline from 'assets/icons/icons-online.png';
import iconsTeacher from 'assets/icons/icons-teacher.png';
import iconsMall from 'assets/icons/icons-shoppingmall.png';
import iconsDiary from 'assets/icons/icons-diary.png';
import iconsGoal from 'assets/icons/icons-goal.png';
import iconsEvent from 'assets/icons/icons-event.png';
import {
  // getCookie,
  getFirstImageFromContent,
} from 'utils';

// import popup01 from 'assets/brainheal_popup01.png';

const quickMenus = [
  {
    icon: iconsOnline,
    title: '온라인 교육',
    href: `/lectures`,
  },
  {
    icon: iconsTeacher,
    title: '실버강사',
    href: `/teachers`,
  },
  {
    icon: iconsMall,
    title: '쇼핑몰',
    href: `/shop/P`,
  },
  {
    icon: iconsDiary,
    title: '치매예방다이어리',
    href: `/shop/D`,
  },
  {
    icon: iconsGoal,
    title: '소셜챌린지',
    href: `/shop/C`,
  },
  {
    icon: iconsEvent,
    title: '이벤트',
    href: `/events?status=ing`,
  },
];

// const popup = [
//   {
//     popup_id: 1,
//     title: '올바른 직업훈련',
//     is_show: 'Y',
//     content: `<p></p>
//     <img src=${popup01} alt="fffffff" style="height: auto;width: 100%;"/>
//     <p></p>
//     `,
//     width: 500,
//     height: 700,
//     x: 100,
//     y: 100,
//   },
// ];

/**
 * [Component] Main
 * --
 */
function Main(props) {
  /* ===== Props ===== */
  const { queryClient, navigation, screenSize } = props;
  // const el = window.document.getElementById('root');
  // const { scrollHeight } = el;

  /* ===== Data ===== */
  const banner = queryClient.getQueryData('getBanners')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  const lectures = queryClient.getQueryData('getLectures')?.data;
  const lectureFiles = queryClient.getQueryData('getLectureFiles')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const products = queryClient.getQueryData('getProducts')?.data;
  const productFiles = queryClient.getQueryData('getProductFiles')?.data;
  const purchases = queryClient.getQueryData('getPurchases')?.data;
  const lectrueOfUsers = queryClient.getQueryData('getLectureOfUsers')?.data;
  const productCategories = queryClient.getQueryData(
    'getProductCategories'
  )?.data;

  /* ===== State ===== */
  const [bannerList, setBannerList] = useState([]);
  const [onlineCategory, setOnlineCategory] = useState([]);
  const [onlineCategoryValue, setOnlineCategoryValue] = useState('all');
  const [onlineLectureList, setOnlineLectureList] = useState([]);
  const [bestProductList, setBestProductList] = useState([]);
  const [bestLectureList, setBestLectureList] = useState([]);
  // const [popupList, setPopupList] = useState([]);

  /* ===== Hooks ===== */
  /**
   * 온라인교육 카테고리 정제
   * --
   */
  useEffect(() => {
    // console.log('categories', categories);
    if (categories && categories?.length > 0) {
      const children = categories?.filter((item) => item?.type === 'C');
      const categoryoptions = [
        {
          label: '전체',
          value: 'all',
        },
        ...(children &&
          children?.map((item) => ({
            label: item?.category_name,
            value: `${item?.category_id}`,
          }))),
      ];
      setOnlineCategory(categoryoptions);
    }
  }, [categories]);

  /**
   * 온라인교육 강의 리스트 정제
   */
  useEffect(() => {
    const today = new Date();
    const newLectuers = lectures
      ?.map((item) => ({
        ...item,
        ...(
          users && users?.filter((subitem) => item.user_id === subitem.user_id)
        )?.pop(),
        thumbnail: lectureFiles?.filter(
          (subitem) =>
            item?.lecture_id === subitem?.lecture_id && subitem?.type === 'T'
        )[0]?.file_url,
        category: categories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]?.category_name,
        parents_id: categories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]?.parents_id,
        status:
          new Date(item?.start_date) >= today
            ? '출시예정'
            : new Date(item?.end_date) <= today
            ? '종료'
            : new Date(item?.start_date) >=
                new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000) &&
              new Date(item?.start_date) <=
                new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)
            ? 'NEW'
            : '진행중',
        created_at: item?.created_at,
      }))
      ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));

    if (onlineCategoryValue === 'all') {
      setOnlineLectureList(newLectuers);
    } else {
      const d = newLectuers?.filter(
        (item) => item?.category_id === parseInt(onlineCategoryValue)
      );
      setOnlineLectureList(d);
    }
  }, [onlineCategoryValue, categories, lectures, lectureFiles, users]);

  /**
   * 상품 베스트셀러 리스트 정제
   * --
   */
  useEffect(() => {
    const newProducts = products
      ?.filter((i) => i.is_show === 'Y')
      ?.map((item) => ({
        ...item,
        // thumbnail: getFirstImageFromContent(item?.content),
        ...productCategories
          ?.filter(
            (subitem) =>
              item.product_category_id === subitem.product_category_id
          )
          ?.pop(),
        sales: purchases?.filter(
          (subitem) => item?.product_id === subitem?.product_id
        )?.length,
      }))
      ?.sort((a, b) => b.sales - a.sales);

    setBestProductList(newProducts);
  }, [products, productFiles, purchases, productCategories]);

  /**
   * 온라인교육 베스트셀러 리스트 정제
   * --
   */
  useEffect(() => {
    const today = new Date();
    const newLectuers = lectures
      ?.map((item) => ({
        ...item,
        ...(
          users && users?.filter((subitem) => item.user_id === subitem.user_id)
        )?.pop(),
        thumbnail: lectureFiles?.filter(
          (subitem) =>
            item?.lecture_id === subitem?.lecture_id && subitem?.type === 'T'
        )[0]?.file_url,
        category: categories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]?.category_name,
        parents_id: categories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]?.parents_id,
        status:
          new Date(item?.start_date) >= today
            ? '출시예정'
            : new Date(item?.end_date) <= today
            ? '종료'
            : new Date(item?.start_date) >=
                new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000) &&
              new Date(item?.start_date) <=
                new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)
            ? 'NEW'
            : '진행중',
        created_at: item?.created_at,
        sales: lectrueOfUsers?.filter(
          (subitem) => item?.lecture_id === subitem?.lecture_id
        )?.length,
      }))
      ?.sort((a, b) => b?.sales - a?.sales);

    setBestLectureList(newLectuers);
  }, [lectures, lectureFiles, lectrueOfUsers, categories, users]);

  /**
   * 배너 데이터 정제
   * --
   */
  useEffect(() => {
    if (banner?.length) {
      const newBanner = banner?.filter((item) => item?.is_show === 'Y');

      setBannerList(newBanner);
    }
  }, [banner]);

  /**
   * 팝업 데이터 정제
   * --
   */
  // useEffect(() => {
  //   const cookieList = popup?.reduce((acc, cur) => {
  //     const c = getCookie(`popup_${cur.popup_id}`);

  //     if (c) return acc;
  //     else acc.push(cur);

  //     return acc;
  //   }, []);

  //   setPopupList(cookieList);
  // }, []);

  /* ===== Functions ===== */

  /* ===== Styles ===== */
  const styles = {
    cardButton: {
      height: screenSize >= 520 ? 275 : '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 8px 15px rgba(158, 158, 158, 0.3)',
    },

    contentCardButton: {
      border: 'none',
      padding: 0,
      borderRadius: 0,
      background: 'none',
    },
  };

  /* ===== Render ===== */
  return (
    <>
      {/* ===== 팝업 ===== */}
      {/* <div id="popup_overlay">
        <h2>팝업 오버레이</h2>
        {popupList?.map((item) => (
          <Popup
            id={`popup_${item?.popup_id}`}
            title={item?.title}
            content={item?.content}
            width={item?.width}
            height={item?.height}
            x={item?.x}
            y={item?.y}
          />
        ))}
      </div> */}

      {/* ===== 배너 ===== */}
      <Content maxWidth={'100%'} padding={0}>
        <MainSlide data={bannerList} />
      </Content>

      {/* ===== 퀵메뉴 ===== */}
      <Content
        maxWidth={'100%'}
        style={{
          background: '#FAFAFA',
        }}
        padding={0}
      >
        <Container
          style={{ display: 'flex', alignItems: 'center' }}
          height={screenSize >= 768 ? 700 : '100%'}
        >
          <Content
            maxWidth={'100%'}
            backgroundColor={'none'}
            padding={screenSize >= 520 ? 0 : '30px 0'}
          >
            <Row>
              {quickMenus?.map((item, _index) => (
                <Col
                  x={12}
                  md={8}
                  style={{ padding: 7.5 }}
                  key={`mn1_${_index}`}
                >
                  <Card
                    style={styles.cardButton}
                    bodyStyle={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                    onClick={() => navigation(item.href)}
                  >
                    <div
                      style={{
                        width: screenSize >= 768 ? '100px' : '80px',
                        height: screenSize >= 768 ? '100px' : '80px',
                        marginBottom: '18px',
                      }}
                    >
                      <img
                        src={item.icon}
                        alt={item.title}
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                    <Title
                      size={4}
                      color="#333"
                      align="center"
                      style={{ fontSize: screenSize >= 768 ? '20px' : '14px' }}
                    >
                      {item.title}
                    </Title>
                  </Card>
                </Col>
              ))}
            </Row>
          </Content>
        </Container>
      </Content>

      {/* ===== 온라인교육 ===== */}
      <Content maxWidth={'100%'} padding={0}>
        <Container
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: screenSize >= 768 ? 0 : '30px 0px',
          }}
          height={screenSize >= 768 ? 700 : 'auto'}
        >
          <Content maxWidth={'100%'} padding={0}>
            <ProductSlide
              title={'온라인교육'}
              category={
                <Category
                  items={onlineCategory}
                  value={onlineCategoryValue}
                  setValue={setOnlineCategoryValue}
                  style={{ marginTop: '16px' }}
                />
              }
              item={onlineLectureList}
              screenSize={screenSize}
            />
          </Content>
        </Container>
      </Content>

      {/* ===== 실버강사 직종 ===== */}
      {/* <Content maxWidth={'100%'} padding={0}>
        <Container
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          height={300}
        >
          <Content maxWidth={'100%'} padding={0}>
            <Title size={2} align="center" style={{ marginBottom: '12px' }}>
              실버강사직종
            </Title>
            <Title size={5} align="center">
              열정 강사님들이 브레인힐과 함꼐 합니다.
            </Title>
            <Content
              maxWidth={'100%'}
              padding={0}
              style={{ margin: '68px 0 109px' }}
            >
              <Row>
                {[1, 2, 3].map((_item, _index) => (
                  <Col x={8} style={{ padding: 18 }} key={`qm_${_index}`}>
                    <Card bodyStyle={{ padding: 25 }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 15,
                        }}
                      >
                        <div style={{ marginRight: 14 }}>
                          <Avatar title="A" size={52}></Avatar>
                        </div>
                        <div>
                          <Title size={5}>인지활동지도사</Title>
                          <p style={{ margin: 0 }}>디자이너</p>
                        </div>
                      </div>
                      <div>
                        <p style={{ margin: 0 }}>
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Id iure reprehenderit sapiente, nulla modi
                          laborum deleniti possimus sequi libero quod.
                        </p>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Content>

            <Content
              maxWidth={936}
              padding={0}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {[amazon, airbnb, slack, paypal, spotify]?.map((item, index) => (
                <div style={{ width: '100px' }} key={`${item}_${index}`}>
                  <img
                    src={item}
                    alt={'후원사 로고'}
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              ))}
            </Content>
          </Content>
        </Container>
      </Content> */}

      {/* ===== 베스트셀러 ===== */}
      <Content maxWidth={'100%'} padding={0} backgroundColor={'#FAFAFA'}>
        <Container
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: screenSize >= 768 ? 0 : '30px 0px',
          }}
          height={screenSize >= 768 ? 700 : 'auto'}
        >
          <Content maxWidth={'100%'} padding={0} backgroundColor={'none'}>
            <ProductSlide
              title={'쇼핑몰 베스트셀러'}
              subTitle={'가장 많이 찾는 상품을 확인하세요!'}
              productCheck={true}
              item={bestProductList?.slice(0, 8)?.map((_item) => ({
                ..._item,
                thumbnail: getFirstImageFromContent(_item?.content),
              }))}
              screenSize={screenSize}
              maxView={8}
            />
          </Content>
        </Container>
      </Content>

      {/* ===== 디지털미래트렌드 ===== */}
      <Content maxWidth={'100%'} padding={0}>
        <Container
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: screenSize >= 768 ? 0 : '30px 0px',
          }}
          height={screenSize >= 768 ? 700 : 'auto'}
        >
          <Content maxWidth={'100%'} padding={0}>
            <ProductSlide
              title={'온라인교육 베스트셀러'}
              subTitle={'가장 많이 시청한 교육을 확인하세요!'}
              item={bestLectureList}
              screenSize={screenSize}
            />
          </Content>
        </Container>
      </Content>

      <Content
        maxWidth={'100%'}
        padding={0}
        style={{
          background: `url(${mainBg}) no-repeat top center`,
          backgroundSize: 'cover',
          position: 'relative',
          top: 0,
          left: 0,
        }}
      >
        <Content
          maxWidth={'100%'}
          padding={0}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `url(${mask}) no-repeat center center`,
            backgroundSize: 'cover',
          }}
        ></Content>
        <Container
          height={screenSize >= 768 ? 400 : 300}
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Content maxWidth={'100%'} padding={0} backgroundColor={'none'}>
            <Title
              size={screenSize >= 768 ? 2 : 3}
              align="center"
              color={'#FFFFFF'}
            >
              지금 브레인힐과 함께 시작하세요.
            </Title>
            <Title
              size={5}
              color={'#FFFFFF'}
              align="center"
              style={{ margin: '30px 0 38px' }}
            >
              열정 강사님들이 브레인힐과 함꼐 합니다.
            </Title>
            <Content
              maxWidth={'100%'}
              padding={0}
              backgroundColor={'none'}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                size="small"
                type="primary"
                style={{
                  border: 'none',
                  background: '#AB5476',
                  width: '148px',
                  height: '42px',
                  borderRadius: '21px',
                  fontSize: '18px',
                  fontWeight: 700,
                }}
              >
                지금 시작하기
              </Button>
            </Content>
          </Content>
        </Container>
      </Content>
    </>
  );
}

Main.defaultProps = {};

export default Main;
