import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 배송지 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetDeliveryAddresses: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getDeliveryAddresses', params] : 'getDeliveryAddresses',
        async () => await $http.get(APIConstant.GET_DELIVERY_ADDRESSES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 배송지 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetDeliveryAddress: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_DELIVERY_ADDRESS, {
        delivery_adress_id: idx,
      });
      const res = useQuery(
        params ? ['getDeliveryAddress', params] : 'getDeliveryAddress',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 배송지 생성
   * --
   * @method POST
   * @returns
   */
  CreateDeliveryAddress: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_DELIVERY_ADDRESS,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getDeliveryAddresses');
            queryClient.invalidateQueries('getDeliveryAddress');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 배송지 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateDeliveryAddress: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          console.log(data);
          const url = parameterToPath(APIConstant.UPDATE_DELIVERY_ADDRESS, {
            delivery_address_id: data.delivery_address_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getDeliveryAddresses');
            queryClient.invalidateQueries('getDeliveryAddress');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 배송지 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteDeliveryAddress: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_DELIVERY_ADDRESS, {
            delivery_adress_id: data.delivery_adress_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getDeliveryAddresses');
            queryClient.invalidateQueries('getDeliveryAddress');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
