import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 전문분야 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetUserMajors: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getUserMajors', params] : 'getUserMajors',
        async () => await $http.get(APIConstant.GET_USER_MAJORS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 전문분야 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetUserMajor: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_USER_MAJOR, {
        major_id: idx,
      });
      const res = useQuery(
        params ? ['getUserMajor', params] : 'getUserMajor',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 전문분야 생성
   * --
   * @method POST
   * @returns
   */
  CreateUserMajor: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_USER_MAJOR,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getUserMajors');
            queryClient.invalidateQueries('getUserMajor');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 전문분야 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateUserMajor: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.put(APIConstant.UPDATE_USER_MAJOR, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getUserMajors');
            queryClient.invalidateQueries('getUserMajor');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 전문분야 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteUserMajor: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_USER_MAJOR, {
            major_id: data.major_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getUserMajors');
            queryClient.invalidateQueries('getUserMajor');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
