import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 난이도 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetDifficulties: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getDifficulries', params] : 'getDifficulties',
        async () => await $http.get(APIConstant.GET_DIFFICULTIES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 난이도 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetDifficulty: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_DIFFICULTY, {
        difficulty_id: idx,
      });
      const res = useQuery(
        params ? ['getDifficulty', params] : 'getDifficulty',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 난이도 생성
   * --
   * @method POST
   * @returns
   */
  CreateDifficulty: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_DIFFICULTY,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getDifficulties');
            queryClient.invalidateQueries('getDifficulty');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 난이도 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateDifficulty: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_DIFFICULTY, {
            difficulty_id: data.difficulty_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getDifficulties');
            queryClient.invalidateQueries('getDifficulty');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 난이도 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteDifficulty: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_DIFFICULTY, {
            difficulty_id: data.difficulty_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getDifficulties');
            queryClient.invalidateQueries('getDifficulty');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
