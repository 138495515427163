import React, { useState, useEffect, useRef } from 'react';
import './PointLayout.style.css';
import { Content, Row, Col, Title, Button } from 'components';
import { Input } from 'antd';
import {
  stringToMoneyFormat,
  TOSS_CLIENT_KEY,
  TOSS_CUSTOMER_KEY,
  getCookie,
  DOMAIN_URL,
} from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { userInfo } from 'atoms/Atoms';
import { useRecoilValue } from 'recoil';
import validateCheck from 'utils/Validation';
import MessageAlert from 'utils/MessageAlert';
import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import { nanoid } from 'nanoid';

const items = [
  {
    price: 5000,
    point: 5000,
  },
  {
    price: 10000,
    point: 10000,
  },
  {
    price: 30000,
    point: 30000,
  },
  {
    price: 50000,
    point: 50000,
  },
  {
    price: 100000,
    point: 100000,
  },
];

const PointLayout = () => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const userInfoData = useRecoilValue(userInfo);
  const [selectValue, setSelectValue] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPoint, setTotalPoint] = useState(userInfoData?.point);

  /* ===== FUNCTION ===== */
  /**
   * 포인트 직접입력 숫자인지 유효성 검사
   * --
   */
  const handleValidInputValue = (value) => {
    if (value !== '' && !validateCheck('number', value))
      return MessageAlert.warning('숫자만 입력해주세요!');

    setInputValue(value);
  };

  /**
   * 포인트 직접입력 천 단위인지 유효성 검사
   * --
   */
  const handleIsThousandthCheck = () => {
    if (inputValue % 1000 !== 0) {
      MessageAlert.warning('포인트는 천원단위로 충전 가능합니다');
      return false;
    } else {
      return true;
    }
  };

  /**
   * 토스 페이먼츠 결제
   * --
   */
  const handlePayments = async () => {
    if (!handleIsThousandthCheck()) return;

    const confirm = window.confirm('포인트를 충전하시겠습니까?');

    if (!confirm) return MessageAlert.warning('취소되었습니다');

    const paymentWidget = paymentWidgetRef.current;

    const pointData = {
      user_id: userInfoData?.user_id ? userInfoData?.user_id : null,
      agency_id: userInfoData?.agency_id ? userInfoData?.agency_id : null,
      point: totalPoint,
    };
    localStorage.setItem('orderList', JSON.stringify(pointData));
    try {
      const orderName =
        selectValue !== items?.length
          ? `${items[selectValue]?.point} 포인트`
          : `${inputValue} 포인트`;

      await paymentWidget
        ?.requestPayment({
          orderId: nanoid(),
          orderName: `${orderName}`,
          customerName: `${userInfoData.name}`,
          customerEmail: `${userInfoData.email}`,
          successUrl: `${DOMAIN_URL}/#/success`,
          failUrl: `${DOMAIN_URL}/#/fail`,
        })
        .catch(function (error) {
          if (error.code === 'USER_CANCEL') {
            // 결제 고객이 결제창을 닫았을 때 에러 처리
          }
          if (error.code === 'INVALID_CARD_COMPANY') {
            // 유효하지 않은 카드 코드에 대한 에러 처리
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  /* ===== HOOKS ===== */
  /**
   * 충전 후 포인트, 총 결제 금액 계산
   * --
   */
  useEffect(() => {
    const point = userInfoData?.point ? userInfoData?.point : 0;
    if (selectValue === items?.length) {
      // 직접입력 선택 시
      setTotalPrice(inputValue);
      setTotalPoint(parseInt(point) + parseInt(inputValue));
    } else {
      const selectedPrice = items[selectValue]?.price;
      const selectedPoint = items[selectValue]?.point;

      setTotalPrice(parseInt(selectedPrice));
      setTotalPoint(parseInt(point) + parseInt(selectedPoint));
    }
  }, [userInfoData, selectValue, inputValue]);

  /**
   * NOTE: 토스 페이먼츠 테스트
   */
  const selector = '#payment-widget';
  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  useEffect(() => {
    (async () => {
      const paymentWidget = await loadPaymentWidget(
        TOSS_CLIENT_KEY,
        TOSS_CUSTOMER_KEY + getCookie('USER')
      );

      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        selector,
        { value: parseInt(totalPrice) }
      );
      paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' });

      paymentWidgetRef.current = paymentWidget;
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(
      parseInt(totalPrice),
      paymentMethodsWidget.UPDATE_REASON.COUPON
    );
  }, [totalPrice]);

  /* ===== RENDER ===== */
  return (
    <>
      <Content maxWidth={'100%'}>
        <Row>
          <Col x={24}>
            <Content maxWidth={'100%'} padding={0}>
              <Title>포인트 충전</Title>
              <Row
                style={{
                  border: '1px solid #E0E0E0',
                  borderRadius: 5,
                  marginTop: 20,
                }}
              >
                {items?.map((item, index) => (
                  <Col
                    x={24}
                    style={{
                      background: '#F5F5F5',
                      padding: '20px 10px',
                      borderBottom: '1px solid #E0E0E0',
                    }}
                    key={`item-${index}`}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectValue(index)}
                    >
                      <h4
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 30,
                          marginBottom: 0,
                        }}
                      >
                        <strong
                          style={{
                            marginRight: 10,
                            color:
                              selectValue === index ? '#AB5476' : '#E0E0E0',
                          }}
                        >
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </strong>
                        {stringToMoneyFormat(item?.point)}
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            background: '#AB5476',
                            color: '#FFFFFF',
                            marginLeft: 10,
                          }}
                        >
                          P
                        </span>
                      </h4>
                      <p style={{ fontSize: 18, color: '#BDBDBD' }}>
                        결제 금액 {stringToMoneyFormat(item?.price, '원')}
                      </p>
                    </div>
                  </Col>
                ))}
                <Col
                  x={24}
                  style={{
                    background: '#F5F5F5',
                    padding: '20px 10px',
                    borderBottom: 'none',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => setSelectValue(items?.length)}
                  >
                    <h4
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 30,
                        marginBottom: 0,
                      }}
                    >
                      <strong
                        style={{
                          marginRight: 10,
                          color:
                            selectValue === items?.length
                              ? '#AB5476'
                              : '#E0E0E0',
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </strong>
                      <Input
                        size="large"
                        value={inputValue}
                        style={{ width: 120 }}
                        onChange={(e) => handleValidInputValue(e.target.value)}
                      />
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 50,
                          height: 50,
                          borderRadius: '50%',
                          background: '#AB5476',
                          color: '#FFFFFF',
                          marginLeft: 10,
                        }}
                      >
                        P
                      </span>
                    </h4>
                    <p style={{ fontSize: 18, color: '#BDBDBD' }}>
                      결제 금액 {stringToMoneyFormat(inputValue, '원')}
                    </p>
                  </div>
                </Col>
              </Row>
              <Content maxWidth={'100%'} padding={0}>
                <div id="payment-widget" />
                <div id="agreement" />
              </Content>
              <Content maxWidth={'100%'} padding={0} style={{ marginTop: 30 }}>
                <h4 style={{ color: '#FB4E4E', fontWeight: 700, margin: 0 }}>
                  포인트 안내
                </h4>
                <p style={{ color: '#BDBDBD' }}>
                  포인트 구매시 환불이 불가능합니다
                </p>
              </Content>
              <Content
                maxWidth={'100%'}
                padding={0}
                style={{
                  marginTop: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Title size={5} color={'#9E9E9E'}>
                  총 결제금액
                </Title>
                <Title size={5} color={'#9E9E9E'}>
                  {stringToMoneyFormat(totalPrice, '원')}
                </Title>
              </Content>
              <Content
                maxWidth={'100%'}
                padding={0}
                style={{
                  marginTop: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Title>충전 후 포인트</Title>
                <Title>{stringToMoneyFormat(totalPoint, 'P')}</Title>
              </Content>
              <Content maxWidth={'100%'} padding={0} style={{ marginTop: 30 }}>
                <Button
                  style={{ width: '100%', height: 48 }}
                  onClick={() => handlePayments()}
                >
                  결제하기
                </Button>
              </Content>
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
};

PointLayout.defaultProps = {};

export default PointLayout;
