import React, { useState, useEffect } from 'react';
import { Content, Row, Col, Title } from 'components';
import { Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { STORE_URL, stringToMoneyFormat, getCookie } from 'utils';
import { encrypt } from 'utils/Crypto';
import { ToolOutlined, RightCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import MessageAlert from 'utils/MessageAlert';

const LectureListPresenter = (props) => {
  /* ===== Props ===== */
  const { lectures, screenSize } = props;

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== State ===== */
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const [isHover, setIsHover] = useState({});
  const [isExamHover, setIsExamHover] = useState({});
  const [newLectures, setNewLectures] = useState([]);

  /* ===== Mutate ===== */

  /* ===== Hooks ===== */
  /**
   * 강의 갯수만큼 hover 객체 생성
   * --
   */
  useEffect(() => {
    const newData = lectures?.map((item) => ({
      ...item,
      thumbnail: item?.files?.filter((subitem) => subitem?.type === 'T')[0],
      answers: item?.exams?.map((subitem) => {
        let sum = 0;
        sum += subitem?.answers?.length;
        return sum;
      }),
    }));
    setNewLectures(newData);

    const d = lectures?.map((item, index) => ({
      key: index,
      value: false,
    }));

    const obj = {};
    d?.forEach((item) => {
      obj[item.key] = item.value;
    });

    setIsHover(obj);
    setIsExamHover(obj);
  }, [lectures]);

  /**
   * 일반회원, 기관 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'user' || loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /* ===== Functions ===== */
  /**
   * 수정버튼 hover 함수
   * --
   * @param {*} type
   * @param {*} index
   */
  const handleHover = (type, index, where) => {
    let newData = {};
    if (where === 'update') {
      newData = { ...isHover };
    } else {
      newData = { ...isExamHover };
    }

    if (type === 'enter') {
      newData[index] = true;
    } else {
      newData[index] = false;
    }

    const d = {
      ...newData,
    };

    if (where === 'update') {
      setIsHover(d);
    } else {
      setIsExamHover(d);
    }
  };

  /* ===== Render ===== */
  return (
    <Content maxWidth={'100%'} padding={'0 12px'}>
      <Row>
        <Col x={24} padding={8}>
          <Title
            size={screenSize >= 768 ? 2 : 4}
            padding={8}
            style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
          >
            강의 목록
          </Title>
          <Content maxWidth={'100%'} padding={0}>
            {newLectures
              ?.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
              })
              ?.map((item, index) => (
                <React.Fragment key={`lecture_${item.lecture_id}`}>
                  <Card
                    hoverable
                    style={{
                      padding: 0,
                      marginBottom: screenSize >= 768 ? 48 : 10,
                      border: '1px solid #9E9E9E',
                      borderRadius: '4px',
                      position: 'relative',
                      top: 0,
                      left: 0,
                    }}
                    bodyStyle={{ padding: 0 }}
                    bordered={false}
                    onClick={() =>
                      !isHover[index] &&
                      !isExamHover[index] &&
                      navigate(
                        `/lectures/${encodeURIComponent(
                          encrypt(item?.lecture_id)
                        )}`
                      )
                    }
                  >
                    <div
                      onMouseEnter={() => handleHover('enter', index, 'update')}
                      onMouseLeave={() => handleHover('leave', index, 'update')}
                      style={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        zIndex: 100,
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        isHover[index] &&
                          navigate(
                            `/lectures/register/1?lecture=${encodeURIComponent(
                              encrypt(item?.lecture_id)
                            )}`
                          );
                      }}
                    >
                      <ToolOutlined
                        style={{
                          fontSize: '20px',
                          color: isHover[index] ? '#000000' : '#BDBDBD',
                        }}
                      />
                    </div>
                    <Row
                      key={item}
                      style={{
                        height: screenSize >= 768 ? '231px' : 'auto',
                      }}
                    >
                      <Col
                        x={24}
                        md={8}
                        style={{
                          padding: 10,
                          borderBottom:
                            screenSize >= 768 ? 'none' : '1px solid #EEEEEE',
                          background: item?.thumbnail ? 'none' : '#D9D9D9',
                        }}
                      >
                        <Content
                          maxWidth={'100%'}
                          height={screenSize >= 768 ? '100%' : 80}
                          padding={0}
                          backgroundColor={
                            item?.thumbnail
                              ? `url(${STORE_URL}/lecture/${item?.thumbnail?.file_url}) no-repeat center center/contain`
                              : '#D9D9D9'
                          }
                          style={{
                            borderTopLeftRadius: '4px',
                            borderBottomLeftRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRight:
                              screenSize >= 768 ? '1px solid #EEEEEE' : 'none',
                          }}
                        >
                          {item?.thumbnail ? null : (
                            <FontAwesomeIcon
                              style={{ fontSize: '50px' }}
                              icon={faImage}
                            />
                          )}
                        </Content>
                      </Col>
                      <Col x={24} md={16}>
                        <Content
                          maxWidth={'100%'}
                          height={'100%'}
                          padding={screenSize >= 768 ? 20 : 10}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                          }}
                        >
                          <div>
                            <p
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '17px',
                                letterSpacing: '0.02em',
                              }}
                            >
                              {item?.category}
                            </p>
                            <p
                              style={{
                                fontSize: '18px',
                                fontWeight: 700,
                                lineHeight: '22px',
                                letterSpacing: '0.02em',
                                marginTop: '6px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {item?.title}
                            </p>
                            <p
                              style={{
                                fontSize: '18px',
                                fontWeight: 700,
                                lineHeight: '22px',
                                letterSpacing: '0.02em',
                                marginTop: '23px',
                              }}
                            >
                              {stringToMoneyFormat(item?.price, '원')}
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <ul
                              style={{
                                display: 'flex',
                                margin: 0,
                                alignItems: 'center',
                              }}
                            >
                              <li>
                                <p
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '22px',
                                    letterSpacing: '0.02em',
                                  }}
                                >
                                  시험{' '}
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      color:
                                        item?.exams && item?.exams?.length
                                          ? '#2AC769'
                                          : '#FB4E4E',
                                    }}
                                  >
                                    {item?.exams && item?.exams?.length > 0
                                      ? `등록완료`
                                      : '미등록'}
                                  </span>
                                </p>
                              </li>
                              <li style={{ margin: '0 15px' }}>
                                <p
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '22px',
                                    letterSpacing: '0.02em',
                                  }}
                                >
                                  답안
                                  <span style={{ fontWeight: 700 }}>
                                    {item?.answers && item?.answers?.length > 0
                                      ? `${item?.answers?.length}`
                                      : '0'}
                                    개 등록
                                  </span>
                                </p>
                              </li>
                              <li
                                onMouseEnter={() =>
                                  handleHover('enter', index, 'exam')
                                }
                                onMouseLeave={() =>
                                  handleHover('leave', index, 'exam')
                                }
                              >
                                <button
                                  style={{
                                    zIndex: 100,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer',
                                    fontSize: '18px',
                                    color: isExamHover[index]
                                      ? '#000000'
                                      : '#BDBDBD',
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    isExamHover[index] &&
                                      navigate(
                                        `/exam/list?lecture=${encodeURIComponent(
                                          encrypt(item?.lecture_id)
                                        )}`
                                      );
                                  }}
                                >
                                  <RightCircleOutlined />
                                </button>
                              </li>
                            </ul>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  `/course?lecture=${encodeURIComponent(
                                    encrypt(item?.lecture_id)
                                  )}&page=1`
                                );
                              }}
                            >
                              수강 현황
                            </Button>
                          </div>
                        </Content>
                      </Col>
                    </Row>
                  </Card>
                </React.Fragment>
              ))}
            <Content
              maxWidth={'100%'}
              padding={0}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '231px',
                border: '1px dashed #9E9E9E',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/lectures/register/1')}
            >
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '22px',
                  letterSpacing: '0.02em',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '50px',
                    paddingBottom: '21px',
                    display: 'inline-block',
                  }}
                >
                  +
                </span>
                <br />
                강의 등록하기
              </p>
            </Content>
          </Content>
        </Col>
      </Row>
    </Content>
  );
};

LectureListPresenter.defualtProps = {};

export default LectureListPresenter;
