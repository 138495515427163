/*
 * 체크박스 컴포넌트
 * --
 * date: 2022-09-19
 * writer: Owen
 */
import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
 * faSquareCheck : 체크박스 아이콘
 */
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import {
  faSquare,
  faCircleDot,
  faCircle,
} from '@fortawesome/free-regular-svg-icons';

const Checkbox = ({
  label,
  status,
  disabled,
  isChecked,
  setIsChecked,
  inputId,
  fontSize,
  color,
  type,
}) => {
  /* ===== FUNCTION ===== */
  const handelCheck = () => {
    if (disabled) {
      return false;
    }
    setIsChecked(!isChecked);
  };

  /* ===== RENDER ===== */
  return (
    <div className="checkbox-layout" style={{ fontSize: `${fontSize}` }}>
      <FontAwesomeIcon
        icon={
          isChecked && (type === 'Square' || type === 'square')
            ? faSquareCheck
            : !isChecked && (type === 'Square' || type === 'square')
            ? faSquare
            : isChecked && (type === 'Circle' || type === 'circle')
            ? faCircleDot
            : faCircle
        }
        style={{
          cursor: 'pointer',
          color:
            status === 'Error' && !disabled
              ? '#FB4E4E'
              : disabled
              ? '#F5F5F5'
              : isChecked
              ? '#03A9F4'
              : '#BDBDBD',
          margin: '0 10px 0 0',
          border: 'none',
        }}
        onClick={() => handelCheck()}
      />
      <input
        type="checkbox"
        defaultChecked={isChecked}
        id={inputId}
        style={{ display: 'none' }}
      />
      <label
        htmlFor={inputId}
        style={{ color: disabled ? '#9E9E9E' : color }}
        onClick={() => handelCheck()}
      >
        {label}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  label: '',
  status: 'Default',
  disabled: false,
  isChecked: 'false',
  inputId: '',
  fontSize: '16px',
  color: '#9E9E9E',
  type: 'Square',
};

Checkbox.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  inputId: PropTypes.string,
  fontSize: PropTypes.string,
  type: PropTypes.string,
};

export default Checkbox;
