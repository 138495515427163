import React, { useState, useEffect } from 'react';
import { Content, Row, Col, Title, ModalLayout, Button } from 'components';
import { Tree, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { SubwayApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { Empty } from 'antd';

const SubwayPresenter = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== Mutate ===== */
  const createSubway = SubwayApi.CreateSubway();
  const updateSubway = SubwayApi.UpdateSubway();
  const deleteSubway = SubwayApi.DeleteSubway();

  /* ===== State ===== */
  const [treeData, setTreeData] = useState([]);
  const [selectData, setSelectData] = useState({});
  const [isOpened, setIsOpened] = useState(false);
  const [modalData, setModalData] = useState({
    subway_name: undefined,
    is_show: false,
  });

  /* ===== Hooks ===== */
  useEffect(() => {
    const tree = data?.map((item) => ({
      title: item?.subway_name,
      key: item?.subway_id,
    }));

    const sortTree = tree?.slice().sort((a, b) => {
      return a.title.localeCompare(b.title);
    });

    const newTree = sortTree?.map((item) => ({
      title: <p style={{ fontSize: '14px', fontWeight: 600 }}>{item?.title}</p>,
      key: item?.key,
    }));

    setTreeData(newTree);
  }, [data]);

  useEffect(() => {
    if (!isOpened) {
      setModalData({
        subway_name: undefined,
        is_show: false,
      });
    }
  }, [isOpened]);

  /* ===== Functions ===== */
  /**
   * 트리 Item 클릭 이벤트
   * --
   * @param {*} key
   */
  const handleTreeSelect = (key) => {
    const id = key[0];
    const selected = data?.filter((item) => item?.subway_id === id)[0];

    setSelectData(selected);
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} w
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (w, t, v, callback = null) => {
    if (w === 'modal') {
      let newData = { ...modalData };

      newData[t] = v;
      const d = {
        ...newData,
      };
      setModalData(d);

      if (callback) callback(v);
    } else {
      let newData = { ...selectData };

      newData[t] = v;
      const d = {
        ...newData,
      };
      setSelectData(d);

      if (callback) callback(v);
    }
  };

  /**
   * 카테고리 데이터 추가/수정/삭제 함수
   * --
   * @param {*} type
   */
  const handleSubway = (type) => {
    if (type === 'C') {
      if (!modalData?.subway_name || !modalData?.subway_name === '') {
        MessageAlert.warning('지하철 호선명을 입력해주세요');
        return;
      }

      const confirm = window.confirm('지하철 호선을 추가하시겠습니까?');
      if (confirm) {
        const list = data;
        const dataCheck = list?.find(
          (item) => item?.subway_name === modalData?.subway_name
        );
        if (dataCheck) {
          MessageAlert.warning('같은 이름을 가진 지하철 호선이 존재합니다');
          return;
        }

        try {
          createSubway.mutate({
            ...modalData,
          });

          MessageAlert.success('지하철 호선이 생성되었습니다');
          setIsOpened(false);
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('지하철 호선 생성에 실패하였습니다');
          throw e;
        }
      }
    } else if (type === 'U') {
      if (!selectData?.subway_name || !selectData?.subway_name === '') {
        MessageAlert.warning('지하철 호선명을 입력해주세요');
        return;
      }

      const confirm = window.confirm('지하철 호선을 수정하시겠습니까?');

      if (confirm) {
        const list = data;
        const dataCheck = list?.find(
          (item) => item?.subway_name === modalData?.subway_name
        );
        if (dataCheck) {
          MessageAlert.warning('같은 이름을 가진 지하철 호선이 존재합니다');
          return;
        }

        try {
          const { created_at, ...d } = selectData;
          updateSubway.mutate({
            ...d,
          });

          MessageAlert.success('지하철 호선이 정상적으로 수정되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('지하철 호선 수정에 실패하였습니다');
          throw e;
        }
      }
    } else if (type === 'D') {
      const confirm = window.confirm(
        '지하철 호선을 삭제하시겠습니까?\n삭제시 관련된 데이터가 손상될 수 있습니다'
      );

      if (confirm) {
        try {
          deleteSubway.mutate({
            subway_id: selectData?.subway_id,
          });

          setSelectData({});
          MessageAlert.success('지하철 호선이 정상적으로 삭제되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('지하철 호선 삭제에 실패하였습니다');
          throw e;
        }
      }
    }
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              padding={0}
              backgroundColor={'none'}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <Title size={2}>지하철 데이터 관리</Title>
              <Button
                layoutStyle={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 0,
                }}
                style={{ padding: '10px 11px' }}
                onClick={() => setIsOpened(!isOpened)}
              >
                {'추가'}
              </Button>
            </Content>
            <Row>
              <Col x={8} padding={8}>
                {/* <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{ marginBottom: '12px', textAlign: 'right' }}
                >
                  <Button
                    layoutStyle={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    style={{ padding: '10px 11px' }}
                    onClick={() => setIsOpened(!isOpened)}
                  >
                    {'추가'}
                  </Button>
                </Content> */}
                <Content
                  maxWidth={'100%'}
                  padding={24}
                  maxHeight={'80vh'}
                  className={'admin_container'}
                  style={{ overflowY: 'scroll' }}
                >
                  {treeData && treeData?.length > 0 ? (
                    <Tree
                      switcherIcon={<DownOutlined />}
                      onSelect={handleTreeSelect}
                      treeData={treeData}
                    />
                  ) : (
                    <Content
                      maxWidth={'100%'}
                      height={'100%'}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Content>
                  )}
                </Content>
              </Col>
              <Col x={16} padding={8}>
                {/* <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{ marginBottom: '12px', textAlign: 'right' }}
                >
                  <Button
                    layoutStyle={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    style={{ padding: '10px 11px', visibility: 'hidden' }}
                  >
                    {'하위 추가'}
                  </Button>
                </Content> */}
                {selectData && Object.keys(selectData)?.length > 0 ? (
                  <Content
                    maxWidth={'100%'}
                    padding={24}
                    height={'80vh'}
                    className={'admin_container'}
                  >
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{ borderRadius: '4px' }}
                    >
                      {/* 지하철 명 */}
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: '#EEEEEE',
                          padding: '10px',
                          borderRadius: '4px',
                        }}
                      >
                        <Col
                          x={6}
                          style={{
                            background: '#D9D9D9',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                          }}
                        >
                          <Title
                            style={{
                              fontSize: '17px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                              fontWeight: 700,
                            }}
                          >
                            호선명
                          </Title>
                        </Col>
                        <Col x={1}></Col>
                        <Col
                          x={17}
                          style={{
                            background: '#FFFFFF',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                            padding: '0 20px',
                          }}
                        >
                          <Input
                            size="large"
                            placeholder="호선명을 입력해주세요"
                            value={selectData?.subway_name}
                            onChange={(e) =>
                              handleChange(
                                'data',
                                'subway_name',
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Content>
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{ marginTop: '12px', textAlign: 'right' }}
                    >
                      {selectData && selectData?.subway_id && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            layoutStyle={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              margin: '0 12px 0 0',
                            }}
                            style={{ padding: '10px 11px' }}
                            onClick={() => handleSubway('U')}
                          >
                            {'수정'}
                          </Button>
                          <Button
                            layoutStyle={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              margin: 0,
                            }}
                            style={{ padding: '10px 11px' }}
                            onClick={() => handleSubway('D')}
                          >
                            {'삭제'}
                          </Button>
                        </div>
                      )}
                    </Content>
                  </Content>
                ) : (
                  <Content
                    maxWidth={'100%'}
                    padding={24}
                    height={'80vh'}
                    className={'admin_container'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Title>지하철을 선택해주세요</Title>
                  </Content>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      {/* 수강중인 강의 상세 모달 */}
      <ModalLayout
        title={'지하철역 추가'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: 0 }}
        footer={
          screenSize < 768 ? (
            <Content padding={0} maxWidth={'100%'} key={'modalBottomButton'}>
              <Button width={'100%'} onClick={() => handleSubway('C')}>
                추가
              </Button>
            </Content>
          ) : null
        }
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Row
                style={{ display: 'flex', alignItems: 'center', padding: 20 }}
              >
                <Col x={6}>
                  <Title>호선명</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.subway_name}
                    placeholder="호선명을 입력해주세요"
                    onChange={(e) =>
                      handleChange('modal', 'subway_name', e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col x={24}>
              <Button
                style={{
                  width: '100%',
                  height: '50px',
                  borderRadius: 0,
                  marginTop: '30px',
                }}
                onClick={() => handleSubway('C')}
              >
                추가
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

SubwayPresenter.defaultProps = {};

export default SubwayPresenter;
