import React from 'react';
import { NavLink } from 'react-router-dom';
import { Title } from 'components';
import { Badge } from 'antd';

function Sidebar({ items, scheduleCount }) {
  /* Router */

  /* State */

  /* Functions */
  /* Hooks */
  /* Render */
  return (
    <div className="sidebar-layout">
      <ul className="sidebar-container">
        {items?.map((item, index) => (
          <div key={`${item.title}-${index}`}>
            <Title
              style={{
                marginTop: index === 0 ? 0 : '30px',
                padding: index === 0 && item.title === '' ? 0 : '12px',
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.02em',
                textTransform: 'capitalize',
                color: '#000000',
              }}
            >
              {item?.title}
            </Title>
            {item?.menus?.map((subitem) =>
              subitem ? (
                subitem?.label === '일정 관리' ? (
                  <li key={`${item?.title}-${subitem?.label}`}>
                    <Badge
                      count={scheduleCount}
                      overflowCount={10}
                      offset={[-10, 0]}
                    >
                      <NavLink
                        to={subitem?.path}
                        style={({ isActive }) => ({
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '19px',
                          color: isActive ? '#AB5476' : '#000000',
                          display: 'block',
                          padding: '12px',
                          background: isActive ? '#F2D3DB' : '#FFFFFF',
                          borderRadius: isActive ? '4px' : 0,
                        })}
                      >
                        {subitem?.label}
                      </NavLink>
                    </Badge>
                  </li>
                ) : (
                  <li key={`${item?.title}-${subitem?.label}`}>
                    <NavLink
                      to={subitem?.path}
                      style={({ isActive }) => ({
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        color: isActive ? '#AB5476' : '#000000',
                        display: 'block',
                        padding: '12px',
                        background: isActive ? '#F2D3DB' : '#FFFFFF',
                        borderRadius: isActive ? '4px' : 0,
                      })}
                    >
                      {subitem?.label}
                    </NavLink>
                  </li>
                )
              ) : null
            )}
          </div>
        ))}
      </ul>
    </div>
  );
}

Sidebar.defaultProps = {
  items: [
    {
      title: '',
      menus: [
        {
          label: '대시보드',
          path: '/mypage/dashboard',
        },
        {
          label: '내 정보',
          path: '/mypage/my_info',
        },
      ],
    },
    {
      title: '학습관리',
      menus: [
        {
          label: '나의 학습',
          path: '/mypage/my_learning',
        },
        {
          label: '온라인 테스트',
          path: '/mypage/my_test',
        },
        {
          label: '작성한 게시글',
          path: '/mypage/my_posts',
        },
      ],
    },
    {
      title: '수강관리',
      menus: [
        {
          label: '수강바구니',
          path: '/mypage/lecture/cart',
        },
        {
          label: '찜목록',
          path: '/mypage/lecture/favorites',
        },
        {
          label: '구매내역',
          path: '/mypage/lecture/orders',
        },
        {
          label: '강의등록',
          path: '/mypage/lecture/signup',
        },
      ],
    },
  ],
};

export default Sidebar;
