/*
 * Textarea 컴포넌트
 * --
 * date: 2022-09-14
 * writer: Owen
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Textarea.style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
 * faTriangleExclamation : 경고표시 아이콘
 * faCircleCheck : 성공표시 아이콘
 * faCircleXmark : 에러표시 아이콘
 * faLoader : 로딩표시 아이콘
 */
import {
  faTriangleExclamation,
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

const Textarea = ({
  label,
  placeholder,
  value,
  setValue,
  resize,
  className,
  status,
  disabled,
  spanText,
  rows,
  cols,
  name,
  width,
  height,
  fontSize,
  fontWeight,
  essential,
  labelStyle,
  padding,
}) => {
  /* Test */
  // const [spanText, setSpanText] = useState('');
  // const [value, setValue] = useState('');

  /* ===== STATE ===== */
  // 상태에 따른 border, background 설정
  const [borderColor, setBorderColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  /* ===== RENDER ===== */
  useEffect(() => {
    // 상태에 따른 border 색, background 색 설정
    if (status === 'Default') {
      setBorderColor('1px solid #BDBDBD');
      setBackgroundColor('#FFFFFF');

      // setSpanText('');
    } else if (status === 'Validation') {
      setBorderColor('1px solid #FFE146');
      setBackgroundColor('#FFFBF4');

      // setSpanText('Warning');
    } else if (status === 'Error') {
      setBorderColor('1px solid #FF6262');
      setBackgroundColor('#FFF5F5');

      // setSpanText('Error');
    } else if (status === 'Loading') {
      setBorderColor('1px solid #BDBDBD');
      setBackgroundColor('#FFFFFF');

      // setSpanText('Loading');
    } else if (status === 'Success') {
      setBorderColor('1px solid #40DD7F');
      setBackgroundColor('#F5FFF6');

      // setSpanText('Success');
    }
  }, [status]);

  return (
    <div
      className="textareaBox"
      style={{ width: `${width}`, height: `${height}` }}
    >
      {label !== '' && (
        <label style={labelStyle}>
          {label}
          {essential && (
            <span style={{ color: '#FB4E4E', padding: '0 5px' }}>*</span>
          )}
        </label>
      )}
      <textarea
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        disabled={disabled}
        onChange={(e) => setValue(e.target.value)}
        style={{
          border: !disabled ? `${borderColor}` : 'none',
          background: !disabled ? `${backgroundColor}` : '#F5F5F5',
          color: disabled && '#BDBDBD',
          resize: resize ? 'both' : 'none',
          fontSize: `${fontSize}`,
          fontWeight: `${fontWeight}`,
          padding: `${padding}`,
        }}
      ></textarea>

      {spanText !== '' && (
        <div className="spanText">
          <p>
            <FontAwesomeIcon
              icon={
                status === 'Default'
                  ? ''
                  : status === 'Validation'
                  ? faTriangleExclamation
                  : status === 'Error'
                  ? faCircleXmark
                  : status === 'Loading'
                  ? faSpinner
                  : faCircleCheck
              }
              style={{
                color:
                  status === 'Validation'
                    ? 'FFE146'
                    : status === 'Error'
                    ? '#FF6262'
                    : status === 'Loading'
                    ? '#03A9F4'
                    : status === 'Success' && '#40DD7F',
                animation: status === 'Loading' && 'rotate 2s linear infinite',
                transformOrigin: status === 'Loading' && '50% 50%',
              }}
            />
          </p>
          <span>{spanText}</span>
        </div>
      )}
    </div>
  );
};

Textarea.defaultProps = {
  label: '',
  placeholder: '내용을 입력해주세요',
  value: '',
  setValue: '',
  resize: false,
  className: '',
  status: 'Default',
  disabled: false,
  spanText: '',
  //   rows,
  //   cols,
  name: '',
  width: '100%',
  height: '100%',
  fontSize: '18px',
  fontWeight: 400,
};

Textarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resize: PropTypes.bool,
  className: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  spanText: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
};

export default Textarea;
