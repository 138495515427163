/**
 * 회원가입 - 이용약관 페이지
 * --
 */
import React from 'react';
import { Content, Row, Col } from 'components';

function Join04(props) {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'} padding={0}>
      <Row>
        <Col x={24}>4{/* <InputForm /> */}</Col>
      </Row>
    </Content>
  );
}

Join04.defaultProps = {};

export default Join04;
