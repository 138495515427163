import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Content, Row, Col, Title, Button, Progress } from 'components';
import { List, Input } from 'antd';
import Logo from '../assets/brainheal_logo.png';
import { LectureApi, ExamApi, AnswerExamApi } from 'api';
import { decrypt } from 'utils/Crypto';
import MessageAlert from 'utils/MessageAlert';
import { getCookie } from 'utils';

function Exam(props) {
  /* ===== ROUTE ===== */
  const { navigation, screenSize } = props;

  /* ===== ROUTE ===== */
  const location = useLocation();

  /* ===== STATE ===== */
  // 현재 풀고 있는 문제
  const from = location.state?.from?.pathname; //url 접근 제어
  const [currentQ, setCurrentQ] = useState(1);
  const { lectureId, examId } = useParams();
  const questionFocus = useRef();
  const question = useRef();
  const { data: lecture } = LectureApi.GetLecture(parseInt(decrypt(lectureId)));
  const { data: exam } = ExamApi.GetExam(parseInt(decrypt(examId)));
  const { data: answerExam } = AnswerExamApi.GetAnswerExams();
  // console.log('lecture', lecture);
  // console.log('exam', exam);
  // console.log('answerExam',answerExam);
  const idx = parseInt(getCookie('USER'));
  const exam_id = parseInt(decrypt(examId));

  // const [answerArr, setAnswerArr] = useState(answerData && answerData?.answer);
  const [answerArr, setAnswerArr] = useState();
  /* ===== Mutate ===== */
  /**
   * 리뷰 등록
   * --
   */
  const NewAnswerExam = answerExam?.data?.filter(
    (i) => i.exam_id === exam_id && i.user_id === idx
  );
  // console.log('NewAnswerExam', NewAnswerExam);

  const createanswerexam = AnswerExamApi.CreateAnswerExam({
    onSuccess: (data) => {
      try {
        MessageAlert.success('시험이 완료되었습니다.');
        navigation('/mypage/my_test');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });
  const updateanswerexam = AnswerExamApi.UpdateAnswerExam({
    onSuccess: (data) => {
      try {
        MessageAlert.success('재시험이 완료되었습니다.');
        navigation('/mypage/my_test');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /* ===== FUNCTION ===== */
  /**
   * TODO: 클릭한 문제로 스크롤
   * --
   * @param {Number} i
   */
  const handleScroll = (i) => {
    // console.log(questionFocus.current?.children[i]);
    questionFocus.current?.children[i - 1]?.children[0]?.scrollIntoView();
    question.current?.scrollIntoView();
    setCurrentQ(i);
  };

  const handleAnswer = (
    answerValue,
    questionkey,
    correct_answer,
    scoreValue,
    type
  ) => {
    const score = answerValue === correct_answer ? scoreValue : 0;
    // console.log(
    //   `answerValue : ${answerValue}, questionkey : ${questionkey}, correct_answer : ${correct_answer}, score : ${score}`
    // );
    const OtherAnswer =
      answerArr !== undefined
        ? answerArr?.filter((item) => item.exam_key !== questionkey)
        : [];
    setAnswerArr([
      ...OtherAnswer,
      {
        key: questionkey,
        exam_key: questionkey,
        answer: answerValue,
        correct_answer: correct_answer,
        score: score,
      },
    ]);
  };

  const handleSubmit = () => {
    // console.log(exam?.data?.exam);
    if (exam?.data?.exam.length !== answerArr?.length) {
      MessageAlert.error('모든 문제에 답을 선택/작성 해주세요.');
    } else {
      if (window.confirm('답안지를 제출하시겠습니까?')) {
        // console.log(
        //   `examid: ${exam_id}, user_id: ${idx}, answer: ${answerArr}`
        // );
        if (NewAnswerExam && NewAnswerExam?.length > 0) {
          try {
            updateanswerexam.mutate({
              answer_exam_id: NewAnswerExam[0]?.answer_exam_id,
              answer: answerArr,
            });
          } catch (e) {
            console.log('Error!!', e);
            throw e;
          }
        } else {
          try {
            createanswerexam.mutate({
              exam_id: exam_id,
              user_id: idx,
              answer: answerArr,
            });
          } catch (e) {
            console.log('Error!!', e);
            throw e;
          }
        }
      }
    }
  };

  // console.log('answerArr', answerArr);
  /* ===== HOOKS ===== */
  useEffect(() => {
    // console.log(location);
    if (from === undefined) {
      MessageAlert.warning('잘못된 접근 입니다.');
      navigation('/');
    }
  }, [from, navigation]);

  /* ===== STYLE ===== */
  const styles = {
    questionText: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.05em',
      color: '#757575',
    },
    titleText: {
      padding: '4px',
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '36px',
      color: '#000000',
    },
    examQuestion: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.02em',
      color: '#000000',
    },
    examExample: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '26px',
      letterSpacing: '-0.01em',
      textTransform: 'capitalize',
      color: '#000000',
    },
    subTitle: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.02em',
      textTransform: 'capitalize',
      color: '#000000',
      marginBottom: '24px',
    },
    progressText: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '19px',
      color: '#000000',
    },
  };

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'} padding={0}>
      {/* 헤더 */}
      <div ref={question}></div>
      <Content
        maxWidth={'100%'}
        padding={0}
        height={129}
        style={{ borderBottom: '1px solid #EEEEEE', borderRadius: '4px' }}
      >
        <Content
          maxWidth={1200}
          padding={0}
          height={'100%'}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* 로고 */}
          <div style={{ width: '151px', height: '56px' }}>
            <img
              src={Logo}
              alt="로고"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          {/* 남은 문항 */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ ...styles.questionText, marginRight: '10px' }}>
              남은 문제
            </p>
            <p style={{ ...styles.questionText }}>
              <span style={{ ...styles.questionText, color: '#AB5476' }}>
                {answerArr?.length ? answerArr?.length : 0}
              </span>
              / {exam?.data?.exam?.length} 문항
            </p>
          </div>
        </Content>
      </Content>
      {/* 컨텐츠 */}
      <Content
        maxWidth={1200}
        padding={'48px 0'}
        // height={`calc(100vh - 130px)`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Row style={{ width: '100%' }}>
          <Col x={screenSize >= 859 ? 18 : 24}>
            <Content
              maxWidth={'100%'}
              padding={'36px 32px'}
              height={876}
              style={{
                border: '1px solid #9E9E9E',
                borderRadius: '4px',
              }}
            >
              <div
                style={{ display: 'flex', width: '100%', alignItems: 'center' }}
              >
                <div>
                  <Title
                    style={{
                      ...styles.titleText,
                      marginRight: '14px',
                    }}
                  >
                    {lecture?.data?.title}
                  </Title>
                </div>
                <div
                  style={{
                    width: '24px',
                    height: 0,
                    borderTop: '1px solid #000000',
                    transform: `rotate(90deg)`,
                  }}
                ></div>
                <div>
                  <Title
                    style={{
                      ...styles.titleText,
                      color: '#9999A3',
                      marginLeft: '14px',
                    }}
                  >
                    온라인테스트
                  </Title>
                </div>
              </div>
              <Content
                maxWidth={'100%'}
                height={724}
                backgroundColor={'#FAFAFA'}
                style={{
                  borderRadius: '4px',
                  marginTop: '40px',
                  overflowY: 'scroll',
                }}
              >
                <List>
                  <div ref={questionFocus}>
                    {exam?.data?.exam.map((item, index) => (
                      <List.Item
                        key={`${item.key}`}
                        style={{ height: '625px', marginTop: '20px' }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <Title>
                            {`<${item.type === 'C' ? '객관식' : '주관식'}>`}
                          </Title>
                          <div
                            id={`focus${item.key}`}
                            style={{ width: '100%' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '0 10px',
                              }}
                            >
                              <p style={{ ...styles.examQuestion }}>
                                {index < 9
                                  ? `0${index + 1} 문제`
                                  : `${index + 1} 문제`}
                              </p>
                              <p style={{ ...styles.examQuestion }}>
                                배점 : {item.score}점
                              </p>
                            </div>
                            <div
                              style={{
                                background: '#FFFFFF',
                                border: '1px solid #9E9E9E',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '18px 0',
                                margin: '24px 0',
                              }}
                            >
                              <p style={{ ...styles.examExample }}>
                                {`<문제> ${item.q}`}
                              </p>
                            </div>
                            <div>
                              {item.type === 'C' ? (
                                item.examples?.map((subItem, i) => (
                                  <div
                                    key={`${subItem.key}`}
                                    style={{
                                      padding: '10px 0',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      handleAnswer(
                                        subItem.key,
                                        item.key,
                                        item.correct_answer,
                                        item.score,
                                        item.type
                                      )
                                    }
                                  >
                                    <p
                                      style={{
                                        ...styles.examExample,
                                        color: '#4F4F4F',
                                      }}
                                    >
                                      {`${i + 1}. ${subItem.example}`}
                                      {answerArr !== undefined &&
                                      answerArr?.filter(
                                        (answerItem) =>
                                          answerItem.exam_key === item.key
                                      )?.length > 0 ? (
                                        answerArr?.filter(
                                          (answerItem) =>
                                            answerItem.exam_key === item.key
                                        )[0].answer === subItem.key ? (
                                          <strong style={{ color: 'red' }}>
                                            {' '}
                                            V{' '}
                                          </strong>
                                        ) : (
                                          ''
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </p>
                                  </div>
                                ))
                              ) : (
                                <>
                                  <Title>답:</Title>
                                  <Input
                                    type="text"
                                    size="large"
                                    placeholder="문제에 알맞은 답을 적어주세요."
                                    value={
                                      answerArr?.filter(
                                        (subitem) => subitem.key === item.key
                                      )?.answer
                                    }
                                    style={{ padding: '20px' }}
                                    onChange={(e) =>
                                      handleAnswer(
                                        e.target.value,
                                        item.key,
                                        item.correct_answer,
                                        item.score,
                                        item.type
                                      )
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </List.Item>
                    ))}
                  </div>
                </List>
              </Content>
            </Content>
          </Col>
          <Col
            x={screenSize >= 859 ? 6 : 24}
            style={
              screenSize >= 859
                ? ''
                : { position: 'fixed', bottom: '0px', width: '100%' }
            }
          >
            <Content
              maxWidth={'100%'}
              padding={screenSize >= 859 ? '0 0 0 18px' : 0}
              height={screenSize >= 859 ? 876 : ''}
            >
              <Col x={24}>
                <Content
                  maxWidth={'100%'}
                  padding={screenSize >= 859 ? '24px 24px 26px' : '5px 25px'}
                  height={screenSize >= 859 ? 140 : 80}
                  backgroundColor={'#F5F5F5'}
                  style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
                >
                  <Title
                    style={
                      screenSize >= 859
                        ? { ...styles.subTitle }
                        : {
                            fontSize: '16px',
                            fontWeight: 600,
                            marginBottom: '5px',
                          }
                    }
                  >
                    테스트 진행도
                  </Title>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={
                        screenSize >= 859 ? { ...styles.progressText } : {}
                      }
                    >
                      {answerArr !== undefined &&
                      (answerArr?.length / exam?.data?.exam?.length) * 100 ===
                        100
                        ? '완료'
                        : '진행중'}
                    </p>
                    <p
                      style={
                        screenSize >= 859 ? { ...styles.progressText } : {}
                      }
                    >
                      {answerArr !== undefined &&
                      answerArr?.length / exam?.data?.exam?.length > 0
                        ? (
                            (answerArr?.length / exam?.data?.exam?.length) *
                            100
                          ).toFixed(2)
                        : 0}
                      %
                    </p>
                  </div>
                  <Progress
                    percent={
                      answerArr !== undefined &&
                      answerArr?.length / exam?.data?.exam?.length > 0
                        ? (answerArr?.length / exam?.data?.exam?.length) * 100
                        : 0
                    }
                    showInfo={false}
                  />
                </Content>
              </Col>
              <Col x={24}>
                <Content
                  maxWidth={'100%'}
                  padding={screenSize >= 859 ? '24px 24px 36px' : '5px 0'}
                  height={screenSize >= 859 && 726}
                  backgroundColor={screenSize >= 859 ? '#F5F5F5' : ''}
                  style={
                    screenSize >= 859
                      ? {
                          border: '1px solid #9E9E9E',
                          borderRadius: '4px',
                          marginTop: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }
                      : ''
                  }
                >
                  <div style={{ display: screenSize >= 859 ? '' : 'none' }}>
                    <Title style={{ ...styles.subTitle }}>
                      테스트 네비게이션
                    </Title>
                    <div>
                      <Row>
                        {exam?.data?.exam.map((item, index) => (
                          <React.Fragment key={`${item.key}`}>
                            <Col x={4}>
                              <Button
                                color={
                                  currentQ === index + 1 ? 'primary' : 'default'
                                }
                                type={
                                  currentQ === index + 1
                                    ? 'primary'
                                    : 'secondary'
                                }
                                style={{
                                  fontSize: '18px',
                                  fontWeight: 400,
                                  lineHeight: '22px',
                                  textTransform: 'capitalize',
                                  width: '35px',
                                  height: '35px',
                                  padding: 0,
                                  marginBottom: '24px',
                                }}
                                onClick={() => handleScroll(index + 1)}
                              >
                                {item.key}
                              </Button>
                            </Col>
                            {(index + 1) % 5 === 0 ? null : <Col x={1}></Col>}
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </div>
                  <div>
                    <Button
                      style={{
                        fontSize: '18px',
                        lineHeight: '22px',
                        padding: '16px 0',
                        width: '100%',
                      }}
                      onClick={() => handleSubmit()}
                    >
                      제출하기
                    </Button>
                  </div>
                </Content>
              </Col>
            </Content>
          </Col>
        </Row>
      </Content>
    </Content>
  );
}

Exam.defaultProps = {};

export default Exam;
