/**
 *
 *
 */

import React, { useEffect, useState } from 'react';
// Mobile routes
import Main from './Main';
import LectureList from './LectureList';
import LectureDetail from './LectureDetail';
import LectureClass from './LectureClass';
import TeacherList from './TeacherList';
import TeacherDetail from './TeacherDetail';
import Login from './Login';
import { AgencySearch, Layout, LoadingPage } from 'components';
import MypageRoutes from './Mypage';
import JoinRoutes from './JoinRoot';
import Exam from './Exam';
import ExamRegister from './ExamRegister';
import ExamList from './ExamList';
import Schedule from './Schedule';
import Certificate from './Certificate';
import ChallengeList from './ChallengeList';
import ChallengeDetail from './ChallengeDetail';
import EventList from './EventList';
import EventDetail from './EventDetail';
import ShopList from './ShopList';
import ShopDetail from './ShopDetail';
import Profile from './Profile';
import LectureRoutes from './LectureRoot';
import ReviewWrite from './ReviewWrite';
import AdminLogin from './AdminLogin';
import AdminRoutes from './AdminRoot';
import DiaryList from './DiaryList';
import DiaryDetail from './DiaryDetail';
import PaymentSuccess from 'components/Pages/PaymentSuccess';
import PaymentFail from 'components/Pages/PaymentFail';
import NotFoundPage from 'components/Pages/NotFoundPage';
import TeacherProfileList from './TeacherProfileList';
import AuthSuccess from 'components/Pages/AuthSuccess';
import AuthFail from 'components/Pages/AuthFail';
import Program from './Program';
import {
  ProgramLayout,
  PointLayout,
  AddressLayout,
  TaxBillLayout,
  BillLayout,
  TeacherBillLayout,
  JournalLayout,
  BoardLayout,
  BoardDetailLayout,
  BoardWriteLayout,
} from './PopupRoot';
import { Refund, Service, Privacy, Introduce } from './TermsRoot';
import Location from './Location';

// import { LoginHome } from './LoginRoot';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loginState, loginUser } from 'atoms/Atoms';
import { getCookie, deleteCookie } from 'utils';
// import { useIsFetching } from 'react-query';
import {
  AuthApi,
  LectureApi,
  LectureOfUserApi,
  ChapterApi,
  SectionApi,
  // ScheduleApi,
  ProfileApi,
  PreferenceApi,
  SNSApi,
  CategoryApi,
  UserApi,
  AddressApi,
  ReviewApi,
  SubwayApi,
  InterestListApi,
  ExamApi,
  DifficultyApi,
  AnswerExamApi,
  MajorApi,
  AgencyApi,
  FileApi,
  EventApi,
  EventOfUserApi,
  ProductApi,
  ProductCategoryApi,
  ProductCartApi,
  PurchaseApi,
  ProgramApi,
  RepetitionScheduleApi,
  BannerApi,
} from 'api';
import { userInfo } from 'atoms/Atoms';

import 'styles/antdPagenation.css';
import { useQueryClient } from 'react-query';
import { CourseStatus } from './CourseStatus';

// const exceptPath = ['login', 'join', 'adm', 'admin'];

/**
 * [Component]
 * --
 */
function Routemap() {
  /* ===== Router ===== */
  const location = useLocation();
  const navigation = useNavigate();

  /* ===== REACT_QUERY ===== */
  // const isFetching = useIsFetching();
  const queryClient = useQueryClient();
  UserApi.GetUsers(); // 유저 전체 조회
  LectureApi.GetLectures(); // 강의 전체조회
  LectureOfUserApi.GetLectureOfUsers(); //수강데이터 전체 조회
  // ScheduleApi.GetSchedules(); // 강사 스케줄 전체 조회
  ChapterApi.GetChapters(); // 강의 챕터 전체 조회
  SectionApi.GetSections(); // 강의 섹션 전체 조회
  ProfileApi.GetUserProfiles(); // 강사 프로필 전체 조회
  CategoryApi.GetCategories(); // 카테고리 전체 조회
  ReviewApi.GetReviews(); // 작성한 리뷰 전체 조회
  SubwayApi.GetSubways(); // 지하철 전체 조회
  SNSApi.GetSNSUsers(); // SNS 전체 조회
  PreferenceApi.GetPreferenceAreaUsers(); // 선호 지역 전체 조회
  PreferenceApi.GetPreferenceSubwayUsers(); // 선호 지하철역 전체 조회
  AddressApi.GetAddress(); // 시/도, 구/군, 동/읍/면/리 조회
  AddressApi.GetAddressSplit(); // 시/도, 구/군, 동/읍/면/리 구분조회
  InterestListApi.GetInterestLists(); // 좋아요, 장바구니 조회
  ExamApi.GetExams(); // 시험 전체 조회
  DifficultyApi.GetDifficulties(); // 난이도 전체 조회
  AnswerExamApi.GetAnswerExams(); // 답안지 전체 조회
  MajorApi.GetUserMajors(); // 전문분야 전체 조회
  AgencyApi.GetAgencies(); // 기관 전체 조회
  FileApi.GetScheduleFiles(); // 스케쥴 파일 전제 조회
  FileApi.GetTeacherFiles(); // 강사 파일 전체 조회
  FileApi.GetLectureFiles(); // 강의 파일 전체 조회
  FileApi.GetProductFiles(); // 상품 파일 전체 조회
  EventApi.GetEvents(); // 이벤트 전체 조회
  EventOfUserApi.GetEventOfUsers(); // 이벤트 참여 고객 전체 조회
  ProductApi.GetProducts(); // 상품 전체 조회
  ProductCategoryApi.GetProductCategories(); // 상품 카테고리 전체 조회
  PurchaseApi.GetPurchases(); // 구매내역 전체 조회
  ProductCartApi.GetProductCarts(); // 상품 장바구니 전체 조회
  ProgramApi.GetPrograms(); // 교안 전체 조회
  RepetitionScheduleApi.GetRepetitionSchedules(); // 반복 스케쥴 전체 조회
  BannerApi.GetBanners(null, {
    staleTime: 'Infinity',
  }); // 배너 전체 조회

  /* ===== State ===== */
  // 로그인 상태 체크
  const [isLogin, setIsLogin] = useRecoilState(loginState);
  // 로그인 한 고객이 누구인지 체크
  const [loginWho, setLoginWho] = useRecoilState(loginUser);
  // 토큰
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  // 로그인 유저 정보
  const [userInfoData, setUserInfoData] = useRecoilState(userInfo);
  // 로딩
  const [isLoading, setIsLoading] = useState(false);
  // 스크린 사이즈
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  // 서브도메인
  const [subPath, setSubPath] = useState('MAIN');

  /* ===== Variables ===== */

  /* ===== Function ===== */
  /**
   * 토큰 정제
   * --
   */
  const verifyToken = async () => {
    const result = await AuthApi.verify();
    if (result) {
      if (result.user_id && result.teacher_check === 'N') {
        setLoginWho('user');
      } else if (result.user_id && result.teacher_check === 'Y') {
        setLoginWho('teacher');
      } else if (result.agency_id) {
        setLoginWho('agency');
      } else {
        setLoginWho('admin');
      }

      if (
        !userInfoData?.user_id ||
        !userInfoData?.agency_id ||
        !userInfoData?.admin_id
      ) {
        setUserInfoData({ ...result });
      }
    }
    return false;
  };

  /* ===== RENDER ===== */
  /**
   * 로그인 상태 저장
   * --
   */
  useEffect(() => {
    if (token) {
      verifyToken();
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    // eslint-disable-next-line
  }, [token, isLogin, loginWho]);

  /**
   * 페이지 이동 시 스크롤 이동
   * --
   */
  useEffect(() => {
    // const path = location.pathname.split('/');
    // if (exceptPath.includes(path[1])) {
    //   return;
    // }
    // route 변경시 scroll 최상단으로 이동
    window.scrollTo(0, 0);
    setIsLoading(true);
  }, [location.pathname, location.search]);

  /**
   * 해시가 없을 경우 처리
   * --
   */
  useEffect(() => {
    if (isLogin) {
      const t = getCookie('BRAINHEAL_ACCESS_TOKEN');
      if (!t && location.pathname !== '/login') {
        window.alert('로그인 시간이 만료되었습니다.\n다시 로그인해주세요.');
        deleteCookie('BRAINHEAL_ACCESS_TOKEN', {});
        deleteCookie('USER', {});
        navigation('/login');
        setIsLogin(false);
        return;
      }
    }

    const hash = window.location.hash;
    const protocol = window.location.protocol;
    const path = location.pathname;
    const search = location.search;
    const host = window.location.host;

    if (!hash || hash === '') {
      window.location.href = `${protocol}//${host}/#${window.location.pathname}${window.location.search}`;
      return;
    }

    const exceptUrl = [
      { host: 'brainheal-lms.com', path: 'MAIN' },
      { host: 'student.brainheal-lms.com', path: 'STUDENT' },
      { host: 'teacher.brainheal-lms.com', path: 'TEACHER' },
      { host: 'localhost:3000', path: 'MAIN' },
      { host: 'student.localhost:3000', path: 'STUDENT' },
      { host: 'teacher.localhost:3000', path: 'TEACHER' },
    ];

    const isExcept = exceptUrl?.find((item) => item?.host === host);
    if (!isExcept) {
      return;
    } else {
      setSubPath(isExcept?.path);
      if (protocol === 'http:') {
        const deplotType = process.env.REACT_APP_DEPLOY_TYPE;
        if (deplotType === 'local') {
          return;
        }
        // window.location.href = `${DOMAIN_URL}/#${path}${search}`;
        window.location.href = `https://${host}/#${path}${search}`;
        return;
      }
    }
  }, [location, navigation, isLogin, setIsLogin]);

  /**
   * 로딩처리
   * --
   */
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [isLoading]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://hiserver.co.kr/runezchat.js';
    script.charset = 'utf-8';

    // Append the script to the document body
    document.body.appendChild(script);

    // Execute the script after it has been loaded
    script.onload = () => {
      if (typeof window.runezHelpChat === 'function') {
        window.runezHelpChat({
          divid: 'ezHelpChatdiv',
          langCd: 1, //한글 1, 영어 2, 일본어 3
          userid: 'brainheal', //ezHelp id를 입력하세요.
          customerid: '', //쇼핑몰 회원 또는 홈페이지에 로그인한 회원 id를 받는 변수값을 입력하세요.
        });
      }
    };

    // Clean up the script element on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /* ===== PROPS ===== */
  const defaultProps = {
    navigation,
    queryClient,
    isLogin,
    setIsLogin,
    loginWho,
    setLoginWho,
    userInfoData,
    setUserInfoData,
    screenSize,
  };

  /* ===== RENDER ===== */
  return (
    <>
      <Layout
        headerExceptPath={[
          'exam',
          'lectureclass',
          'auth',
          'agency-search',
          'admin',
          'popup',
        ]}
        footerExceptPath={[
          'exam',
          'lectureclass',
          'auth',
          'agency-search',
          'admin',
          'popup',
        ]}
        subPath={subPath}
        {...defaultProps}
      >
        <div id="ezHelpChatdiv" className="chat"></div>

        {isLoading && <LoadingPage />}
        <Routes>
          <Route path="/">
            <Route index element={<Main {...defaultProps} />} />
            <Route path="/login" element={<Login {...defaultProps} />} />
            <Route path="/adm" element={<AdminLogin {...defaultProps} />} />
            <Route path="/exam">
              <Route index element={<Exam {...defaultProps} />} />
              <Route
                path=":lectureId/:examId"
                element={<Exam {...defaultProps} />}
              />
              <Route
                path="register/*"
                element={<ExamRegister {...defaultProps} />}
              />
              <Route path="list" element={<ExamList {...defaultProps} />} />
            </Route>
            <Route
              path="/success"
              element={<PaymentSuccess {...defaultProps} />}
            />
            <Route path="/error" element={<PaymentFail {...defaultProps} />} />
          </Route>

          {/* 강의 */}
          <Route path="/lectures">
            <Route index element={<LectureList {...defaultProps} />} />
            <Route
              path=":lectureId"
              element={<LectureDetail {...defaultProps} />}
            />
            <Route
              path="register/*"
              element={<LectureRoutes {...defaultProps} />}
            />
          </Route>

          {/* 수강 */}
          <Route path="/lectureclass">
            <Route index element={<LectureClass {...defaultProps} />} />
            <Route
              path=":lectureId"
              element={<LectureClass {...defaultProps} />}
            />
            <Route
              path=":lectureId/:sectionId"
              element={<LectureClass {...defaultProps} />}
            />
          </Route>

          {/* 수강 현황 */}
          <Route path="/course" element={<CourseStatus />} />

          {/* 강사 */}
          <Route path="/teachers">
            <Route index element={<TeacherList {...defaultProps} />} />
            <Route path=":teacherId">
              <Route index element={<TeacherDetail {...defaultProps} />} />
              <Route path="schedule" element={<Schedule {...defaultProps} />} />
            </Route>
            <Route
              path="profiles"
              element={<TeacherProfileList {...defaultProps} />}
            />
          </Route>

          {/* 쇼핑몰 */}
          <Route path="/shop">
            <Route path=":type" element={<ShopList {...defaultProps} />} />
            <Route path="detail">
              <Route
                path=":productId"
                element={<ShopDetail {...defaultProps} />}
              />
            </Route>
          </Route>

          {/* 치매예방다이어리 */}
          <Route path="/diary">
            <Route index element={<DiaryList {...defaultProps} />} />
            <Route path="detail">
              <Route
                path=":productId"
                element={<DiaryDetail {...defaultProps} />}
              />
            </Route>
          </Route>

          {/* 소셜챌린지 */}
          <Route path="/challenge">
            <Route index element={<ChallengeList {...defaultProps} />} />
            <Route
              path=":challengeId"
              element={<ChallengeDetail {...defaultProps} />}
            />
          </Route>

          {/* 커뮤니티 */}

          {/* 이벤트 */}
          <Route path="/events">
            <Route index element={<EventList {...defaultProps} />} />
            <Route
              path=":eventId"
              element={<EventDetail {...defaultProps} />}
            />
          </Route>

          {/* 회원가입 */}
          <Route
            index
            path="/join/*"
            element={<JoinRoutes {...defaultProps} />}
          />
          {/* 프로필 등록 */}
          <Route
            index
            path="/profile"
            element={<Profile {...defaultProps} />}
          />
          {/* 마이페이지 */}
          <Route
            index
            path="/mypage/*"
            element={<MypageRoutes {...defaultProps} />}
          />

          {/* 수료증 발급 페이지 */}
          <Route
            index
            path="/certificate/*"
            element={<Certificate {...defaultProps} />}
          />

          {/* 관리자 메뉴 */}
          <Route
            index
            path="/admin/*"
            element={<AdminRoutes {...defaultProps} />}
          />

          {/* 본인인증 */}
          <Route path="/auth">
            <Route path="success" element={<AuthSuccess {...defaultProps} />} />
            <Route path="fail" element={<AuthFail {...defaultProps} />} />
          </Route>

          {/* ===== 약관 ===== */}
          <Route path="/terms/*">
            <Route path="introduce" element={<Introduce {...defaultProps} />} />
            <Route path="service" element={<Service {...defaultProps} />} />
            <Route path="privacy" element={<Privacy {...defaultProps} />} />
            <Route path="refund" element={<Refund {...defaultProps} />} />
          </Route>

          {/* 찾아오시는길 */}
          <Route
            path="location"
            index
            element={<Location {...defaultProps} />}
          />

          {/* 리뷰작성 */}
          <Route
            index
            path="/review/*"
            element={<ReviewWrite {...defaultProps} />}
          />

          {/* 기관검색 팝업 */}
          <Route
            index
            path="/agency-search"
            element={<AgencySearch {...defaultProps} />}
          />

          {/* 교안 */}
          <Route
            index
            path="/program/:scheduleId"
            element={<Program {...defaultProps} />}
          />

          {/* 팝업 */}
          <Route path="/popup">
            <Route
              path="program/:scheduleId"
              element={<ProgramLayout {...defaultProps} />}
            />
            <Route path="point" element={<PointLayout {...defaultProps} />} />
            <Route
              path="delivery-address"
              element={<AddressLayout {...defaultProps} />}
            />
            <Route
              path="tax-bill"
              element={<TaxBillLayout {...defaultProps} />}
            />
            <Route path="bill" element={<BillLayout {...defaultProps} />} />

            <Route
              path="teacher-bill"
              element={<TeacherBillLayout {...defaultProps} />}
            />
            <Route
              path="journal"
              element={<JournalLayout {...defaultProps} />}
            />
            <Route path="board">
              <Route index element={<BoardLayout {...defaultProps} />} />
              <Route
                path="detail/:boardId"
                element={<BoardDetailLayout {...defaultProps} />}
              />
              <Route
                path="write"
                element={<BoardWriteLayout {...defaultProps} />}
              />
            </Route>
          </Route>

          {/* 404 */}
          <Route path="/*" index element={<NotFoundPage {...defaultProps} />} />
        </Routes>
      </Layout>
    </>
  );
}

export default Routemap;
