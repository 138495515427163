import React from 'react';
import { Tabs } from 'antd';
import './Tabs.style.css';

const TabComp = ({
  defaultKey,
  items,
  centered,
  size,
  type,
  tabPosition,
  style,
  onChange,
}) => {
  /* ===== Props ===== */
  /* ===== Router ===== */
  /* ===== State ===== */
  /* ===== Hooks ===== */
  /* ===== Functions ===== */
  /* ===== Render ===== */
  return (
    <Tabs
      defaultActiveKey={defaultKey ? defaultKey : items && items[0]?.key}
      items={items?.map((item) => {
        return {
          label: (
            <span>
              {item.icon ? <item.icon /> : null}
              {item.label}
            </span>
          ),
          key: item.key,
          children: item.children ? item.children : null,
        };
      })}
      centered={centered}
      size={size}
      type={type}
      tabPosition={tabPosition}
      style={{ ...style }}
      onChange={onChange}
    />
  );
};

TabComp.defaultProps = {
  items: [
    {
      label: `Tab 1`,
      key: `1`,
      children: null,
    },
    {
      label: `Tab 2`,
      key: `2`,
      children: null,
    },
    {
      label: `Tab 3`,
      key: `3`,
      children: null,
    },
  ],
  centered: false,
  size: 'middle',
  type: 'line',
  tabPosition: 'top',
  style: {},
  onChange: () => {},
};

export default TabComp;
