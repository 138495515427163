import React, { useState, useEffect } from 'react';
import { Content, Row, Col, Title, Button } from 'components';
import { useParams, useNavigate } from 'react-router-dom';
import { EventApi, EventOfUserApi } from 'api';
import { decrypt } from 'utils/Crypto';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import { useQueryClient } from 'react-query';
import { getCookie, deleteCookie } from 'utils';
import MessageAlert from 'utils/MessageAlert';

const EventDetail = () => {
  /* ===== Props ===== */

  /* ===== Router ===== */
  const navigate = useNavigate();
  const { eventId } = useParams();

  /* ===== Mutate ===== */
  const createEventUser = EventOfUserApi.CreateEventOfUser();

  /* ===== Data ===== */
  const queryClient = useQueryClient();
  const { data: event, refetch } = EventApi.GetEvent(
    parseInt(decrypt(eventId)),
    null,
    {
      enabled: !!eventId,
      cacheTime: 0,
    }
  );
  const eventOfUsers = queryClient.getQueryData('getEventOfUsers')?.data;

  /* ===== State ===== */
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const [newEvent, setNewEvent] = useState({});
  const loginWho = useRecoilValue(loginUser);
  // console.log('loginWho', loginWho);
  // 참여여부 체크
  const [isChecked, setIsChecked] = useState(false);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);

  /* ===== Hooks ===== */
  /**
   * 이벤트 데이터 Refetch
   * --
   */
  useEffect(() => {
    refetch();
  }, [eventId, refetch]);

  /**
   * 데이터 정제
   * --
   */
  useEffect(() => {
    if (event?.status === 200) {
      const today = new Date();
      setNewEvent({
        ...event?.data,
        status:
          new Date(event?.data?.end_date) < today
            ? 'end'
            : new Date(event?.data?.start_date) > today
            ? 'soon'
            : 'ing',
      });
    }
  }, [event]);

  /**
   * 참여여부 체크
   * --
   */
  useEffect(() => {
    if (loginWho === 'user' || loginWho === 'teacher') {
      const users = eventOfUsers?.filter(
        (item) => item?.event_id === parseInt(decrypt(eventId))
      );

      const user = users?.filter(
        (item) => item?.user_id === parseInt(getCookie('USER'))
      )[0];

      if (user) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    }
  }, [loginWho, eventOfUsers, eventId]);

  /**
   * 비로그인 라우팅 처리
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      deleteCookie('BRAINHEAL_ACCESS_TOKEN', {});
      deleteCookie('USER', {});
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }
  }, [token, navigate, uid]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== Functions ===== */
  /**
   * 이벤트 참여 함수
   * --
   */
  const handleParticipation = () => {
    const confirm = window.confirm('해당 이벤트를 참여하시겠습니까?');

    if (confirm) {
      try {
        createEventUser.mutate({
          event_id: parseInt(decrypt(eventId)),
          user_id: parseInt(getCookie('USER')),
          event_state: 'P',
        });

        MessageAlert.success('이벤트에 참여하였습니다');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('이벤트 참여에 실패하였습니다');
        throw e;
      }
    }
  };

  /**
   * 목록 이동 함수
   * --
   */
  const handleHistoryBack = () => {
    const status = newEvent?.status;

    navigate(`/events?status=${status}`);
  };

  /* ===== Render ===== */
  return (
    <Content padding={0} maxWidth={'100%'} style={{ margin: '48px 0 84px' }}>
      <Content padding={screenSize >= 1200 ? 0 : '0 30px'} maxWidth={1200}>
        <Row>
          <Col x={24} padding={8}>
            <Title
              align={'center'}
              style={{
                fontSize: '35px',
                fontWeight: 700,
                lineHeight: '42px',
                letterSpacing: '0.02em',
                marginBottom: '37px',
              }}
            >
              이벤트
            </Title>
          </Col>
          <Col x={24}>
            <Content maxWidth={'100%'} padding={0}>
              <Col x={24}>
                <Content
                  maxWidth={'100%'}
                  padding={'30px 10px'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTop: '2px solid #AB5476',
                    borderBottom: '1px solid #D9D9D9',
                    flexDirection: screenSize >= 576 ? 'row' : 'column',
                  }}
                >
                  <Title style={{ display: 'flex', alignItems: 'center' }}>
                    {newEvent?.event_title}
                    <span
                      style={{
                        fontSize: '12px',
                        marginLeft: '10px',
                        padding: '5px 10px',
                        borderRadius: '100px',
                        background:
                          newEvent?.status === 'ing'
                            ? '#6BB93F'
                            : newEvent?.status === 'end'
                            ? '#FE4279'
                            : '#000',
                        color: '#FFFFFF',
                      }}
                    >
                      {newEvent?.status === 'ing'
                        ? '진행중'
                        : newEvent?.status === 'end'
                        ? '종료'
                        : '진행예정'}
                    </span>
                  </Title>
                  <Title
                    style={{
                      fontSize: '13px',
                      fontWeight: 400,
                      color: '#898989',
                    }}
                  >
                    <span
                      style={{
                        color: '#AB5476',
                        fontWeight: 700,
                        paddingRight: '10px',
                      }}
                    >
                      기간
                    </span>
                    {`${moment(newEvent?.start_date).format(
                      'YYYY년 MM월 DD일'
                    )} ~ ${moment(newEvent?.end_date).format(
                      'YYYY년 MM월 DD일'
                    )}`}
                  </Title>
                </Content>
              </Col>
              <Col x={24}>
                <Content
                  maxWidth={'100%'}
                  padding={'50px 0'}
                  style={{ borderBottom: '1px solid #AB5476' }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(newEvent?.event_content),
                    }}
                  ></div>
                  <Button
                    style={{
                      margin: '50px auto 0',
                      width: screenSize >= 768 ? '384px' : '100%',
                      height: '54px',
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                    onClick={
                      newEvent?.status === 'ing' &&
                      !isChecked &&
                      (loginWho === 'user' || loginWho === 'teacher')
                        ? () => handleParticipation()
                        : () => {}
                    }
                    disabled={
                      newEvent?.status === 'ing' &&
                      (loginWho === 'user' || loginWho === 'teacher') &&
                      !isChecked
                        ? false
                        : true
                    }
                  >
                    {newEvent?.status !== 'ing'
                      ? '이벤트 참여기간이 아닙니다'
                      : loginWho === 'agency' || loginWho === 'admin'
                      ? '일반 회원만 참여가능합니다'
                      : isChecked
                      ? '이미 참여한 이벤트입니다'
                      : '이벤트참여하기'}
                  </Button>
                </Content>
              </Col>
            </Content>
          </Col>
          <Col x={24}>
            <button
              style={{
                color: '#FFFFFF',
                fontSize: '14px',
                fontWeight: 700,
                height: '45px',
                widht: '80px',
                padding: '10px 20px',
                background: '#666',
                border: '1px solid #666',
                cursor: 'pointer',
                marginTop: '20px',
                borderRadius: '10px',
              }}
              onClick={() => handleHistoryBack()}
            >
              목록
            </button>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

EventDetail.defaultProps = {};

export default EventDetail;
