import React, { useState, useEffect } from 'react';
import RentDeliveryPresenter from './RentDeliveryPresenter';

const RentDeliveryContainer = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);
  const [productData, setProductData] = useState([]);
  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { users, teachers, agencies, products } = data;
      if (users && teachers && products) {
        const mergeUsers = [...users, ...teachers, ...agencies];
        const newUsers = mergeUsers?.map((item) => ({
          ...item,
          profile_img: item?.files?.filter((subitem) => subitem?.type === 'P'),
        }));
        const RentProduct = products?.filter(
          (item) => item.sales_type === 'R' && item.product_id !== 0
        );
        setNewData(newUsers);
        setProductData(RentProduct);
      }
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <RentDeliveryPresenter userList={newData} productList={productData} />;
};

RentDeliveryContainer.defaultProps = {};

export default RentDeliveryContainer;
