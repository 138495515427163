import React, { useState, useEffect } from 'react';
import ProductsPresenter from './ProductsPresenter';

const ProductsContainer = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { products, productCategories } = data;
      const d = products?.map((item) => ({
        ...item,
        key: item.product_id,
        ...(productCategories &&
          productCategories?.filter(
            (subitem) =>
              item?.product_category_id === subitem?.product_category_id
          )[0]),
      }));
      const n = d?.map((item) => ({
        ...item,
        parents_name: productCategories
          ?.filter(
            (subitem) => item?.parents_id === subitem?.product_category_id
          )
          ?.pop()?.product_category_name,
      }));

      setNewData(n);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <ProductsPresenter data={newData} />;
};

ProductsContainer.defaultProps = {};

export default ProductsContainer;
