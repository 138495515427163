/*
 * 파일 업로드 컴포넌트
 * --
 * date: 2022-09-15
 * writer: Owen
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './File.style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
 * faTriangleExclamation : 경고표시 아이콘
 * faCircleCheck : 성공표시 아이콘
 * faCircleXmark : 에러표시 아이콘
 */
import {
  faTriangleExclamation,
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

const File = ({
  label,
  btnText,
  placeholder,
  value,
  onChange,
  status,
  spanText,
  disabled,
  name,
  width,
  height,
  inputId,
  multiple,
  fontSize,
  fontWeight,
  color,
  essential,
  labelStyle,
}) => {
  /* ===== STATE ===== */
  // 상태에 따른 border, background 설정
  const [inputColor, setInputColor] = useState({
    border: '',
    background: '',
  });
  const [labelColor, setLabelColor] = useState({
    border: '',
    background: '',
    color: '',
  });

  /* ===== RENDER ===== */
  useEffect(() => {
    // 상태에 따른 border 색, background 색 설정
    if (status === 'Default') {
      setInputColor({
        border: '1px solid #BDBDBD',
        background: '#FFFFFF',
      });

      //   setSpanText('');
    } else if (status === 'Validation') {
      setInputColor({
        border: '1px solid #FFE146',
        background: '#FFFBF4',
      });

      //   setSpanText('Warning');
    } else if (status === 'Error') {
      setInputColor({
        border: '1px solid #FF6262',
        background: '#FFF5F5',
      });

      //   setSpanText('Error');
    } else if (status === 'Loading') {
      setInputColor({
        border: '1px solid #BDBDBD',
        background: '#FFFFFF',
      });

      //   setSpanText('Loading');
    } else if (status === 'Success') {
      setInputColor({
        border: '1px solid #40DD7F',
        background: '#F5FFF6',
      });

      //   setSpanText('Success');
    }
  }, [status]);

  /* ===== RENDER ===== */
  useEffect(() => {
    if (color === 'Primary' || color === 'primary') {
      setLabelColor({
        border: '1px solid #BDBDBD',
        background: '#0288D1',
        color: '#FFFFFF',
      });
    } else if (color === 'Secondary' || color === 'secondary') {
      setLabelColor({
        border: '1px solid #BDBDBD',
        background: '#EEEEEE',
        color: '#9E9E9E',
      });
    }
  }, [color]);

  /* ===== RENDER ===== */
  return (
    <div className="file-layout-background">
      {label !== '' && (
        <p style={labelStyle}>
          {label}
          {essential && (
            <span style={{ color: '#FB4E4E', padding: '0 5px' }}>*</span>
          )}
        </p>
      )}
      <div
        className="file-layout"
        style={{
          width: `${width}`,
          height: `${height}`,
        }}
      >
        <input
          type="text"
          defaultValue={value}
          name={name}
          readOnly={true}
          placeholder={placeholder}
          style={{
            fontSize: `${fontSize}`,
            fontWeight: `${fontWeight}`,
            border: !disabled ? `${inputColor.border}` : 'none',
            background: !disabled ? `${inputColor.background}` : '#F5F5F5',
          }}
        />
        <label
          htmlFor={inputId}
          style={{
            border: `${labelColor.border}`,
            background: `${labelColor.background}`,
            color: `${labelColor.color}`,
            fontSize: `${fontSize}`,
            fontWeight: `${fontWeight}`,
          }}
        >
          {btnText === '' ? '파일' : btnText}
        </label>
        <input
          type="file"
          id={`${inputId}`}
          style={{ display: 'none' }}
          multiple={multiple}
          value={value}
          onChange={onChange}
        />
      </div>
      {spanText !== '' && (
        <div className="span-text">
          <p>
            <FontAwesomeIcon
              icon={
                status === 'Default'
                  ? ''
                  : status === 'Validation'
                  ? faTriangleExclamation
                  : status === 'Error'
                  ? faCircleXmark
                  : status === 'Loading'
                  ? faSpinner
                  : faCircleCheck
              }
              style={{
                color:
                  status === 'Validation'
                    ? 'FFE146'
                    : status === 'Error'
                    ? '#FF6262'
                    : status === 'Loading'
                    ? '#03A9F4'
                    : status === 'Success' && '#40DD7F',
                animation: status === 'Loading' && 'rotate 2s linear infinite',
                transformOrigin: status === 'Loading' && '50% 50%',
              }}
            />
          </p>
          <span>{spanText}</span>
        </div>
      )}
    </div>
  );
};

File.defaultProps = {
  label: '',
  placeholder: '파일을 선택해주세요',
  value: '',
  btnText: '',
  setValue: '',
  status: 'Default',
  spanText: '',
  disabled: false,
  name: '',
  width: '340px',
  height: '56px',
  inputId: '',
  multiple: false,
  fontSize: '18px',
  fontWeight: 400,
  color: 'Secondary',
};

File.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.string,
  status: PropTypes.string,
  spanText: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  inputId: PropTypes.string,
  multiple: PropTypes.bool,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
  color: PropTypes.string,
};

export default File;
