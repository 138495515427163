import React, { useState, useEffect } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  ProfileCard,
  Card,
  LectureCard,
} from 'components';
import { useNavigate } from 'react-router-dom';
import { encrypt } from 'utils/Crypto';
import { STORE_URL } from 'utils';

const MyInfoPresenter = ({ data, lectures }) => {
  /* ===== Props ===== */
  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== Mutate ===== */

  /* ===== State ===== */
  const [filteredLectures, setFilteredLectuers] = useState([]);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);

  /* ===== Hooks ===== */
  useEffect(() => {
    setFilteredLectuers(lectures);
  }, [lectures]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== Functions ===== */

  /* ===== Styles ===== */
  const styles = {
    profileTitle: {
      fontSize: '30px',
      fontWeight: 700,
      lineHeight: '36px',
      color: '#000000',
      padding: '8px',
    },
    profileListItem: {
      borderRadius: '4px',
      background: '#F2D3DB',
      padding: '15px 20px',
      marginBottom: '24px',
      width: '80%',
    },
    profileListText: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '19px',
    },
    contentCardButton: {
      border: 'none',
    },
  };

  /* ===== Render ===== */
  return (
    <Content maxWidth={'100%'} padding={'0 12px'}>
      <Row>
        <Col x={24} padding={8}>
          <Title
            size={screenSize >= 768 ? 2 : 4}
            padding={8}
            style={{ marginBottom: screenSize >= 768 ? '28px' : 10 }}
          >
            안녕하세요, {screenSize < 768 && <br />}
            {data?.user
              ? data?.user?.nickname
                ? data?.user?.nickname
                : data?.user?.name
              : data?.agency?.name}
            님!
          </Title>
        </Col>
        {/* 강사 프로필 카드 */}
        <Col x={24}>
          <ProfileCard data={data} />
        </Col>
        {/* 강사 프로필 정보
        <Col x={24} style={{ marginTop: '37px' }}>
          <Row>
            <Col x={24}>
              <Content
                maxWidth={'100%'}
                padding={0}
                style={{ marginBottom: '56px' }}
              >
                <Title style={{ ...styles.profileTitle }}>한 줄 소개</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <p
                  style={{
                    padding: '0 8px',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                  }}
                >
                  안녕하세요. 000를 만드는 강사 000입니다.
                </p>
              </Content>
            </Col>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.profileTitle }}>전문분야</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <ul>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                </ul>
              </Content>
            </Col>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.profileTitle }}>경력사항</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <ul>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>- 교사・20년</p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 강사・1년6개월
                    </p>
                  </li>
                </ul>
              </Content>
            </Col>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.profileTitle }}>학력</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <ul>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 서울여자대학교・경역학 전공・졸업 (1982.02)
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 교육부・사회복지학전공・졸업
                    </p>
                  </li>
                </ul>
              </Content>
            </Col>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.profileTitle }}>자격증</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <ul>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 브레인힐 e-신체활동지도사・2022.12
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 실버인지놀이전문가・2022.07
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 브레인힐 e-신체활동지도사・2022.12
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 실버인지놀이전문가・2022.07
                    </p>
                  </li>
                </ul>
              </Content>
            </Col>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.profileTitle }}>희망 위치</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <ul>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                </ul>
              </Content>
            </Col>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.profileTitle }}>이동 가능 시간</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <ul>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                </ul>
              </Content>
            </Col>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.profileTitle }}>희망 급여</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <ul>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                </ul>
              </Content>
            </Col>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.profileTitle }}>
                  온라인 강의 제공 여부
                </Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <ul>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                </ul>
              </Content>
            </Col>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <Title style={{ ...styles.profileTitle }}>SNS</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <ul>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                  <li style={{ ...styles.profileListItem }}>
                    <p style={{ ...styles.profileListText }}>
                      - 미술・종이접기・초급
                    </p>
                  </li>
                </ul>
              </Content>
            </Col>
            <Col x={24}>
              <Content
                maxWidth={'100%'}
                padding={0}
                style={{ marginBottom: '56px' }}
              >
                <Title style={{ ...styles.profileTitle }}>자기소개</Title>
                <Line
                  style={{ margin: '16px 0 24px', border: '1px solid #000000' }}
                />
                <p
                  style={{
                    padding: '0 8px',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '19px',
                  }}
                >
                  안녕하세요. 000를 만드는 강사 000입니다.
                </p>
              </Content>
            </Col>
          </Row>
        </Col> */}
        {/* 갤러리 */}
        <Col x={24} style={{ marginTop: '100px' }}>
          <Content maxWidth={'100%'} padding={0}>
            <Title size={3} style={{ marginBottom: '30px' }}>
              이런 강의는 어떠세요?
            </Title>
            <Row>
              {filteredLectures?.map(
                (_item, _index) =>
                  _index < 4 && (
                    <Col
                      x={screenSize >= 768 ? 6 : 12}
                      style={{ padding: 0 }}
                      key={`mn2_${_index}`}
                    >
                      <Card
                        style={styles.contentCardButton}
                        bodyStyle={{ padding: 10 }}
                        onClick={() =>
                          navigate(
                            `/lectures/${encodeURIComponent(
                              encrypt(_item?.lecture_id)
                            )}`
                          )
                        }
                      >
                        <LectureCard
                          thumbnail={
                            _item?.thumbnail &&
                            `${STORE_URL}/lecture/${_item?.thumbnail}`
                          }
                          title={_item?.title}
                          teacher={_item?.name}
                          category={_item?.category}
                          start={_item?.start_date}
                          end={_item?.end_date}
                        />
                      </Card>
                    </Col>
                  )
              )}
            </Row>
          </Content>
        </Col>
      </Row>
    </Content>
  );
};

MyInfoPresenter.defualtProps = {};

export default MyInfoPresenter;
