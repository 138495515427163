import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Title } from 'components';
import { Input } from 'antd';
import './BillLayout.style.css';
import { Content, Row, Col, Button } from 'components';
import { decrypt } from 'utils/Crypto';
import { EmailApi, TotalCalculateApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import validateCheck from 'utils/Validation';
import { calculateFirstAndLastDay, stringToMoneyFormat } from 'utils';

const BillLayout = () => {
  /* ===== ROUTE ===== */
  const [searchParams] = useSearchParams({
    onSuccess: () => {
      window.opener.postMessage('refresh', '*');
    },
  });

  /* ===== MUTATE ===== */
  const createTotalCalculate = TotalCalculateApi.CreateTotalCalculate({
    onSuccess: (data) => {
      if (data?.status === 200) {
        setDecodeValue((prev) => ({
          ...prev,
          total_calculate: data?.data,
        }));

        window.opener.postMessage('refresh', '*');
        MessageAlert.success('메일을 성공적으로 전송하였습니다');
      }
    },
  });
  const updateTotalCalculate = TotalCalculateApi.UpdateTotalCalculate({
    onSuccess: (data) => {
      if (data?.status === 200) {
        setDecodeValue((prev) => ({
          ...prev,
          total_calculate: data?.data,
        }));

        window.opener.postMessage('refresh', '*');
        MessageAlert.success('메일을 성공적으로 전송하였습니다');
      }
    },
  });

  /* ===== STATE ===== */
  const [decodeValue, setDecodeValue] = useState({});
  const [billData, setBillData] = useState({
    program: '브레인힐 프로그램 이용료',
    name: null,
    date: null,
    calculation: null,
    price: null,
    year: null,
    month: null,
    day: null,
  });

  /* ===== FUNCTION ===== */
  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...billData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setBillData(d);

    if (callback) callback(v);
  };

  /**
   * 청구서 메일 전송
   * --
   */
  const handleSendMail = async () => {
    if (!validateCheck('number', billData?.price))
      return MessageAlert.warning('청구금액은 숫자로만 입력해주세요');

    const confirm = window.confirm('청구서 메일을 전송하시겠습니까?');

    if (confirm) {
      try {
        const res = await EmailApi.Sendmail({
          toEmail: decodeValue?.agency_email,
          type: 'bill',
          template_value: {
            agency_id: decodeValue?.agency_id,
            ...billData,
          },
        });

        if (res.status === 200) {
          const { calculate } = decodeValue;
          const idx = calculate?.map((item) => item?.calculate?.calculate_id);
          if (decodeValue?.total_calculate) {
            // 청구서 수정
            updateTotalCalculate.mutate({
              total_calculate_id:
                decodeValue?.total_calculate?.total_calculate_id,
              calculate_id: idx,
              total_price: billData?.price,
              bill_count: decodeValue?.total_calculate?.bill_count + 1,
              bill: {
                ...billData,
                send_date: new Date(),
              },
            });
          } else {
            // 청구서 생성
            createTotalCalculate.mutate({
              calculate_id: idx,
              agency_id: decodeValue?.agency_id,
              year: decodeValue?.year,
              month: decodeValue?.month,
              total_price: billData?.price,
              bill_count: 1,
              bill: {
                agency_id: decodeValue?.agency_id,
                ...billData,
                send_date: new Date(),
              },
            });
          }
        }
      } catch (err) {
        console.log('이메일 전송 실패', err);
        MessageAlert.error('이메일을 전송하는데 실패하였습니다.');
        throw err;
      }
    } else {
      MessageAlert.warning('취소되었습니다');
    }
  };

  /* ===== HOOKS ===== */
  /**
   * 데이터 정제
   */
  useEffect(() => {
    const data = searchParams.get('data');
    const decodeData = JSON.parse(decrypt(data));

    if (decodeData) {
      const today = new Date();
      const y = today.getFullYear();
      const m = today.getMonth() + 1;
      const d = today.getDate();

      // 교육기간
      const dateString = calculateFirstAndLastDay(
        decodeData?.year,
        decodeData?.month
      );

      // 산출식
      let calculation = '';
      decodeData?.calculate?.forEach((item, index) => {
        if (index === 0) {
          calculation += `${item?.category_name} (${
            item?.user_name
          }) 프로그램: ${stringToMoneyFormat(
            item?.calculate?.agency_time_price,
            '원'
          )} * ${item?.calculate?.work_hour}H`;
        } else {
          calculation += `\n${item?.category_name} (${
            item?.user_name
          }) 프로그램: ${stringToMoneyFormat(
            item?.calculate?.agency_time_price,
            '원'
          )} * ${item?.calculate?.work_hour}H`;
        }
      });

      // 청구금액
      let total = 0;
      decodeData?.calculate?.forEach((item) => {
        total +=
          item?.calculate?.work_hour * item?.calculate?.agency_time_price;
      });

      setBillData({
        program: '브레인힐 프로그램 이용료',
        name: decodeData?.agency_name,
        date: `${dateString?.firstDay} ~ ${dateString?.lastDay}`,
        calculation: calculation,
        price: total,
        year: decodeData?.total_calculate?.bill?.year
          ? decodeData?.total_calculate?.bill?.year
          : y,
        month: decodeData?.total_calculate?.bill?.month
          ? decodeData?.total_calculate?.bill?.month
          : m,
        day: decodeData?.total_calculate?.bill?.day
          ? decodeData?.total_calculate?.bill?.day
          : d,
      });

      // if (decodeData?.total_calculate) {
      //   setBillData({
      //     total_calculate_id: decodeData?.total_calculate?.total_calculate_id,
      //     ...decodeData?.total_calculate?.bill,
      //   });
      // } else {
      //   setBillData({
      //     program: '브레인힐 프로그램 이용료',
      //     name: decodeData?.agency_name,
      //     date: `${dateString?.firstDay} ~ ${dateString?.lastDay}`,
      //     calculation: calculation,
      //     price: total,
      //     year: y,
      //     month: m,
      //     day: d,
      //   });
      // }

      setDecodeValue(decodeData);
    }
    // eslint-disable-next-line
  }, []);

  /**
   * 팝업창 닫힘 메시지 전송
   * --
   */
  useEffect(() => {
    const handleClose = () => {
      window.opener.postMessage('closed', '*');
    };

    window.addEventListener('unload', handleClose);

    return () => {
      window.removeEventListener('unload', handleClose);
    };
  }, []);

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'}>
      <Row>
        <Col x={24}>
          <Content maxWidth={800} padding={0}>
            <Title size={3} align={'center'}>
              브레인힐 맞춤형 프로그램 이용료 청구서
            </Title>
            <div className="bill_container">
              <table className="bill-table">
                <tbody>
                  <tr>
                    <th>강좌명</th>
                    <td>
                      <Input
                        size="large"
                        placeholder="강좌명을 입력해주세요"
                        value={billData?.program}
                        onChange={(e) =>
                          handleChange('program', e.target.value)
                        }
                      />
                    </td>
                    <th>기관명</th>
                    <td>
                      <Input
                        size="large"
                        placeholder="기관명을 입력해주세요"
                        value={billData?.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>교육기간</th>
                    <td colSpan={3}>
                      <Input
                        size="large"
                        placeholder="교육기간을 입력해주세요"
                        value={billData?.date}
                        onChange={(e) => handleChange('date', e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>산출식</th>
                    <td colSpan={3}>
                      <Input.TextArea
                        size="large"
                        placeholder="산출식을 입력해주세요"
                        value={billData?.calculation}
                        onChange={(e) =>
                          handleChange('calculation', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>청구금액</th>
                    <td colSpan={3}>
                      <Input
                        prefix={'₩'}
                        suffix={'원'}
                        size="large"
                        placeholder="청구금액을 숫자 입력해주세요"
                        value={billData?.price}
                        onChange={(e) => handleChange('price', e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="note_txt" style={{ marginTop: 20 }}>
                상기 금액을 브레인힐 맞춤형 프로그램에 따른 이용료로 청구하오니
                아래 계좌로 입금하여 주시기 바랍니다.
              </p>
              <table className="bill-table">
                <tbody>
                  <tr>
                    <th>거래은행</th>
                    <td>
                      <p>우리은행</p>
                    </td>
                  </tr>
                  <tr>
                    <th>계좌번호</th>
                    <td>
                      <p>1005-904-269030</p>
                    </td>
                  </tr>
                  <tr>
                    <th>예금주</th>
                    <td>
                      <p>(주) 브레인힐</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="note_txt">※ 통장 사본 첨부</p>
              <Input.Group
                compact
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  margin: 20,
                }}
              >
                <Input
                  size="large"
                  placeholder="년"
                  value={billData?.year}
                  onChange={(e) => handleChange('year', e.target.value)}
                  style={{ width: 100 }}
                  maxLength={4}
                />
                <p style={{ padding: '0 5px' }}>.</p>
                <Input
                  size="large"
                  placeholder="월"
                  value={billData?.month}
                  onChange={(e) => handleChange('month', e.target.value)}
                  style={{ width: 50 }}
                  maxLength={2}
                />
                <p style={{ padding: '0 5px' }}>.</p>
                <Input
                  size="large"
                  placeholder="일"
                  value={billData?.day}
                  onChange={(e) => handleChange('day', e.target.value)}
                  style={{ width: 50 }}
                  maxLength={2}
                />
              </Input.Group>
              {/* <Title align={'center'} style={{ margin: 20 }}>
                {billData?.year}. {billData?.month}. {billData?.day}
              </Title> */}
              <div className="bill-info">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Title align={'center'}>청구인 주소 : </Title>
                  <p>경기도 김포시 태장로 741, 540호</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Title align={'center'}>사업자번호 : </Title>
                  <p>625-88-02197</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Title align={'center'}>대표 : </Title>
                  <p>정상원 ( 인 )</p>
                </div>
              </div>
            </div>
            <Button
              style={{ width: '60%', margin: '30px auto', height: 54 }}
              onClick={() => handleSendMail()}
            >
              메일 전송
            </Button>
          </Content>
        </Col>
      </Row>
    </Content>
  );
};

export default BillLayout;
