import { atom } from 'recoil';

export const loginState = atom({
  key: 'isLogin',
  default: false,
});

export const loginUser = atom({
  key: 'loginUser',
  default: '',
});

export const orderList = atom({
  key: 'orderList',
  default: [],
});

export const userInfo = atom({
  key: 'userInfo',
  default: {},
});
