import React from 'react';
import { Avatar, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const AvatarComp = ({
  size,
  style,
  children,
  shape,
  icon,
  badge,
  count,
  overflowCount,
  dot,
  badgeColor,
}) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== FUNCTION ===== */
  /* ===== HOOK ===== */
  /* ===== RENDER ===== */
  return badge ? (
    <Badge
      count={count}
      overflowCount={overflowCount}
      dot={dot}
      color={badgeColor}
    >
      {!children ? (
        <Avatar style={{ ...style }} size={size} shape={shape} icon={icon} />
      ) : (
        <Avatar style={{ ...style }} size={size} shape={shape}>
          {children}
        </Avatar>
      )}
    </Badge>
  ) : !children ? (
    <Avatar style={{ ...style }} size={size} shape={shape} icon={icon} />
  ) : (
    <Avatar style={{ ...style }} size={size} shape={shape}>
      {children}
    </Avatar>
  );
};

AvatarComp.defaultProps = {
  size: 64,
  style: {},
  children: '',
  shape: 'circle',
  icon: <UserOutlined />,
  badge: false,
  count: 0,
  overflowCount: 99,
  dot: false,
};

export default AvatarComp;
