import React, { useState, useEffect } from 'react';
import EventDetailPresenter from './EventDetailPresenter';
import { useParams } from 'react-router-dom';
import { decrypt } from 'utils/Crypto';

const EventDetailContainer = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */
  const { eventId } = useParams();

  /* ===== State ===== */
  const [newData, setNewData] = useState({});

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { events, users, teachers } = data;
      // console.log('users', users);
      let newUsers = [];
      if (users && teachers) {
        newUsers = [...users, ...teachers];
      }

      const e = events?.filter(
        (item) => item?.event_id === parseInt(decrypt(eventId))
      )[0];
      const event = {
        ...e,
        users: e?.users?.map((item) => ({
          ...item,
          ...(newUsers &&
            newUsers?.filter(
              (subitem) => item?.user_id === subitem?.user_id
            )[0]),
          participation_date: item?.created_at,
        })),
      };

      setNewData(event);
    }
  }, [data, eventId]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <EventDetailPresenter data={newData} screenSize={screenSize} />;
};

EventDetailContainer.defaultProps = {};

export default EventDetailContainer;
