import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TotalCalculatePresenter from './TotalCalculatePresenter';
import { ScheduleApi, CalculateApi } from 'api';
import UseDebounce from 'utils/useDebounce';
import MessageAlert from 'utils/MessageAlert';
import { stringToMoneyFormat } from 'utils';
import { LoadingPage } from 'components';

const TotalCalculateContainer = ({ data }) => {
  /* ===== Route ===== */
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');
  const pageParams = searchParams.get('page');
  const countParams = searchParams.get('count');

  /* ===== Props ===== */
  const { users, teachers, agencies, categories } = data;

  /* ===== State ===== */
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [editingItem, setEditingItem] = useState(null);
  // 정산 데이터
  const [calculateData, setCalculateData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  // 정산정보
  const [calculateInfo, setCalculateInfo] = useState([]);
  // 선택값
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  // 검색
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = UseDebounce(searchValue, 300);
  const [selectValue, setSelectValue] = useState('all');
  const [inputSelectValue, setInputSelectValue] = useState('teachers');
  // 로딩
  const [isLoading, setIsLoading] = useState(false);

  /* ===== Data ===== */
  const { data: schedules, refetch: scheduleRefetch } =
    ScheduleApi.GetSchedules(
      {
        date: `${year}-${month}`,
      },
      {
        select: (data) => data?.data,
        enabled: !!year && !!month,
      }
    );
  const { data: calculate, refetch: calculateRefetch } =
    CalculateApi.GetCalculates(
      {
        date: `${year}-${month}`,
      },
      {
        select: (data) => data?.data,
        enabled: !!year && !!month,
      }
    );
  const newUsers = useMemo(() => {
    if (users && teachers) {
      const newList = users.concat(teachers);

      return newList;
    }
  }, [users, teachers]);

  /* ===== Mutate ===== */
  const bulkCreateCalculate = CalculateApi.BulkCreateCalculate();
  const updateCalculate = CalculateApi.UpdateCalculate();

  /* ===== Function ===== */
  /**
   * 수정내역 있을경우 취소 처리
   * --
   */
  const handleCancel = () => {
    if (editingItem) {
      const confirm = window.confirm(
        '수정중인 정산내역이 있습니다. 취소하시겠습니까?'
      );

      if (confirm) {
        setEditingItem(null);
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  /**
   * 날짜 값 변경
   * --
   */
  const handleDate = (type) => {
    if (!handleCancel()) return;

    let m = month,
      y = year;
    if (type === 'prev') {
      --m;

      if (m === 0) {
        m = 12;
        --y;
      }
    } else {
      ++m;

      if (m > 12) {
        m = 1;
        ++y;
      }
    }
    navigation(
      `/admin/total-calculate?date=${y}-${m}&page=1&count=${pageSize}`
    );
    setSelectedItems([]);
    setSelectedKeys([]);
  };

  /**
   * 페이지 값 변경
   * --
   */
  const handlePagination = (p) => {
    if (!handleCancel()) return;

    navigation(
      `/admin/total-calculate?date=${year}-${month}&page=${p}&count=${pageSize}`
    );
  };

  /**
   * 페이지 출력 갯수 값 변경
   * --
   */
  const handlePageSize = (s) => {
    navigation(
      `/admin/total-calculate?date=${year}-${month}&page=${page}&count=${s}`
    );
  };

  /**
   * 진행시간 계산
   * --
   */
  const handleCalculateTime = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const timeDifference = endDate - startDate;
    const hours = timeDifference / (1000 * 60 * 60);

    return hours;
  };

  /**
   * 근무시간 / 시급 값 변경
   * --
   */
  const handleCalChange = (k, t, v) => {
    setCalculateData((prevData) => {
      const newData = prevData.map((item) =>
        item.key === k
          ? {
              ...item,
              calculate: {
                ...item.calculate,
                [t]: v,
              },
            }
          : item
      );

      return newData;
    });
  };

  /**
   * 일괄정산
   * --
   */
  const handleSubmit = (arr) => {
    const confirm = window.confirm(
      `${arr.length}개의 일정에대하여 일괄정산하시겠습니까?`
    );

    if (confirm) {
      try {
        setIsLoading(true);
        const newData = arr?.reduce((acc, cur) => {
          const item = {
            agency_id: cur?.agency_id,
            user_id: cur?.user_id,
            repetition_schedule_id:
              cur?.type === 'repetition_schedule'
                ? cur?.schedules[0]?.repetition_schedule_id
                : null,
            schedule_id:
              cur?.type === 'schedule' ? cur?.schedules[0]?.schedule_id : null,
            time_price: cur?.calculate?.time_price,
            agency_time_price: cur?.calculate?.agency_time_price,
            work_hour: cur?.calculate?.work_hour,
            year: year,
            month: month,
          };

          acc.push(item);

          return acc;
        }, []);

        bulkCreateCalculate.mutate(newData);
        MessageAlert.success(`일괄정산되었습니다.`);
        setSelectedItems([]);
        setSelectedKeys([]);
      } catch (err) {
        MessageAlert.error('일괄정산하는데 오류가 발생하였습니다!');
        console.log(`일괄정산 ERROR !!`, err);
        throw err;
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    }
  };

  /* ===== Hooks ===== */
  /**
   * 날짜 querystring 값 정제
   * --
   */
  useEffect(() => {
    const splitDate = date?.split('-');
    setYear(parseInt(splitDate[0]));
    setMonth(parseInt(splitDate[1]));
  }, [date]);

  /**
   * 페이지 querystring 값 정제
   * --
   */
  useEffect(() => {
    const p = parseInt(pageParams);
    setPage(p);
  }, [pageParams]);

  /**
   * 테이블 카운트 querystring 값 정제
   * --
   */
  useEffect(() => {
    const countValue = parseInt(countParams);
    const countList = [10, 30, 50];
    if (!countValue || !countList.includes(countValue)) return setPageSize(10);
    else setPageSize(countValue);
  }, [countParams]);

  /**
   * 선택한 강사 스케쥴/정산 데이터 호출
   * --
   */
  useEffect(() => {
    scheduleRefetch();
    calculateRefetch();
  }, [year, month, scheduleRefetch, calculateRefetch]);

  /**
   * 스케쥴 / 정산 데이터 정제
   * --
   */
  useEffect(() => {
    let scheduleList = [],
      repetitionScheduleList = [];
    schedules?.forEach((item) => {
      if (item?.schedule_type === 'S') {
        if (item?.repetition_schedule_id) {
          repetitionScheduleList.push(item);
        } else {
          scheduleList?.push(item);
        }
      }
    });

    const groupedByRepetitionId = repetitionScheduleList?.reduce(
      (acc, item) => {
        const repetitionId = item?.repetition_schedule_id;

        if (!repetitionId) {
          if (!acc[null]) {
            acc[null] = [];
          }
          acc[null].push(item);
        } else {
          // repetition_schedule_id가 있는 경우
          if (!acc[repetitionId]) {
            acc[repetitionId] = [];
          }
          acc[repetitionId].push(item);
        }

        return acc;
      },
      {}
    );
    const resultArray = Object.values(groupedByRepetitionId);

    const newData = [
      ...(resultArray &&
        resultArray?.map((item, index) => {
          const agencyData = agencies?.filter(
            (subitem) => item[0]?.agency_id === subitem?.agency_id
          )[0];
          const categoryData = categories?.filter(
            (subitem) => item[0]?.category_id === subitem?.category_id
          )[0];
          const userData = newUsers?.filter(
            (subitem) => item[0]?.user_id === subitem?.user_id
          )[0];

          return {
            key: `repetition_schedule_${index}`,
            user_id: userData?.user_id,
            user_name: userData?.name,
            user_email: userData?.email,
            agency_id: agencyData?.agency_id,
            agency_name: agencyData?.name,
            agency_address: agencyData?.address,
            agency_address_detail: agencyData?.address_detail,
            agency_email: agencyData?.email,
            chief_name: agencyData?.chief_name,
            company_number: agencyData?.company_number,
            category_name: categoryData?.category_name,
            type: 'repetition_schedule',
            parents_name: categories?.filter(
              (subitem) => subitem?.category_id === categoryData?.parents_id
            )[0]?.category_name,
            schedules: [...item],
            calculate: calculate?.filter(
              (subitem) =>
                subitem?.repetition_schedule_id ===
                item[0]?.repetition_schedule_id
            )[0] ?? {
              work_hour: item?.reduce((acc, cur) => {
                if (cur?.user_check)
                  return (
                    acc + handleCalculateTime(cur.start_date, cur.end_date)
                  );
                else return acc + 0;
              }, 0),
              time_price: 40000,
              agency_time_price: 55000,
            },
          };
        })),
      ...(scheduleList &&
        scheduleList?.map((item, index) => {
          const agencyData = agencies?.filter(
            (subitem) => item?.agency_id === subitem?.agency_id
          )[0];
          const categoryData = categories?.filter(
            (subitem) => item?.category_id === subitem?.category_id
          )[0];
          const userData = newUsers?.filter(
            (subitem) => item?.user_id === subitem?.user_id
          )[0];

          return {
            key: `schedule_${index}`,
            user_id: userData?.user_id,
            user_name: userData?.name,
            user_email: userData?.email,
            agency_id: agencyData?.agency_id,
            agency_name: agencyData?.name,
            agency_email: agencyData?.email,
            agency_address: agencyData?.address,
            agency_address_detail: agencyData?.address_detail,
            category_name: categoryData?.category_name,
            parents_name: categories?.filter(
              (subitem) => subitem?.category_id === categoryData?.parents_id
            )[0]?.category_name,
            type: 'schedule',
            schedules: [item],
            calculate: calculate?.filter(
              (subitem) => subitem?.schedule_id === item?.schedule_id
            )[0] ?? {
              work_hour: handleCalculateTime(item.start_date, item.end_date),
              time_price: 40000,
              agency_time_price: 55000,
            },
          };
        })),
    ];

    setCalculateData(newData);
  }, [schedules, calculate, newUsers, agencies, categories]);

  /**
   * 정산 정보 정제
   * --
   */
  useEffect(() => {
    const cal = (title, value) => {
      return { title: title, value: value };
    };
    const info = [];
    let teacherTotal = 0,
      agencyTotal = 0,
      workTotal = 0;
    if (calculate) {
      calculate?.forEach((item) => {
        teacherTotal += item?.work_hour * item?.time_price;
        agencyTotal += item?.work_hour * item?.agency_time_price;
        workTotal += item?.work_hour;
      });
    }

    let confirmedCount = 0,
      undecidedCount = 0;
    if (calculateData) {
      calculateData?.forEach((item) => {
        if (item?.calculate?.calculate_id) {
          confirmedCount++;
        } else {
          undecidedCount++;
        }
      });
    }

    let t = [],
      a = [];
    if (schedules) {
      schedules?.forEach((item) => {
        if (!t.includes(item?.user_id)) t.push(item?.user_id);

        if (!a.includes(item?.agency_id)) a.push(item?.agency_id);
      });
    }

    info.push(cal('총 근무수', stringToMoneyFormat(schedules?.length, '건')));
    info.push(
      cal(
        '총 근무시간',
        `${schedules
          ?.reduce((acc, cur) => {
            return acc + handleCalculateTime(cur.start_date, cur.end_date);
          }, 0)
          .toFixed(2)}시간`
      )
    );
    info.push(
      cal('총 정산수', stringToMoneyFormat(calculateData?.length, '건'))
    );
    info.push(cal('정산완료', stringToMoneyFormat(confirmedCount, '건')));
    info.push(cal('미정산', stringToMoneyFormat(undecidedCount, '건')));

    info.push(cal('총 정산시간', `${workTotal}시간`));
    info.push(cal('총 정산금액', stringToMoneyFormat(teacherTotal, '원')));
    info.push(cal('총 청구금액', stringToMoneyFormat(agencyTotal, '원')));
    info.push(cal('강사수', stringToMoneyFormat(t.length, '명')));
    info.push(cal('기관수', stringToMoneyFormat(a.length, '곳')));

    setCalculateInfo(info);
  }, [calculate, schedules, calculateData]);

  /**
   * 정산내역 검색
   * --
   */
  useEffect(() => {
    const newData = calculateData
      ?.filter((item) => {
        if (selectValue === 'all') return item;
        else if (selectValue === true) return item?.calculate?.calculate_id;
        else return !item?.calculate?.calculate_id;
      })
      ?.filter((item) => {
        if (debouncedValue === '') return item;
        else if (inputSelectValue === 'teachers')
          return item?.user_name.includes(debouncedValue);
        else return item?.agency_name.includes(debouncedValue);
      });

    setFilterData(newData);
  }, [calculateData, debouncedValue, inputSelectValue, selectValue]);

  /* ===== Render ===== */
  return (
    <>
      {isLoading && <LoadingPage />}
      <TotalCalculatePresenter
        year={year}
        month={month}
        page={page}
        onDate={handleDate}
        onPagination={handlePagination}
        calculate={filterData}
        calculateInfo={calculateInfo}
        updateCalculate={updateCalculate}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        inputSelectValue={inputSelectValue}
        setInputSelectValue={setInputSelectValue}
        pageSize={pageSize}
        onPageSize={handlePageSize}
        onCalculateTime={handleCalculateTime}
        onSubmit={handleSubmit}
        onCalChange={handleCalChange}
        editingItem={editingItem}
        setEditingItem={setEditingItem}
      />
    </>
  );
};

export default TotalCalculateContainer;
