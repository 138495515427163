import React, { useState, useEffect, useMemo } from 'react';
import LectureRegister from './LectureRegister';
import ChapterRegister from './ChapterRegister';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { Content, Row, Col } from 'components';
import { LectureApi, ChapterApi, SectionApi, FileApi } from 'api';
import { getCookie } from 'utils';
import MessageAlert from 'utils/MessageAlert';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { decrypt } from 'utils/Crypto';
import { FileUploadManager } from 'utils/FileUploadManager';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import validateCheck from 'utils/Validation';

const LectureRoutes = () => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('lecture');

  /* ===== DATA ===== */
  const queryClient = useQueryClient();
  const teachers = queryClient
    .getQueryData('getUsers')
    ?.data?.filter(
      (item) => item?.is_show === 'Y' && item?.teacher_check === 'Y'
    );
  const categories = queryClient.getQueryData('getCategories')?.data;
  const difficulty = queryClient.getQueryData('getDifficulties')?.data;
  const lectureFiles = queryClient.getQueryData('getLectureFiles')?.data;
  const { data: lecture, refetch } = LectureApi.GetLecture(
    parseInt(decrypt(lectureId)),
    {
      chapter: true,
      section: true,
    },
    {
      enabled: !!lectureId,
    }
  );

  /* ===== STATE ===== */
  const loginWho = useRecoilValue(loginUser);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  // 강의 등록인지 수정인지 Type
  const [type, setType] = useState('create');
  const [htmlString, setHtmlString] = useState(undefined);
  // 강의 데이터 입력 값
  const [inputData, setInputData] = useState({
    title: undefined,
    major_name: undefined,
    content: undefined,
    category_id: undefined,
    difficulty_id: undefined,
    price: undefined,
    start_date: undefined,
  });
  // 강의 파일
  const [file, setFile] = useState({
    thumbnail: undefined,
    background: undefined,
  });
  // 강의 챕터
  const [chapterList, setChapterList] = useState([]);
  // 강의 섹션
  const [sectionList, setSectionList] = useState([]);
  // 강의 카테고리 아이템 정제
  const [categoryItems, setCategoryItems] = useState([]);
  const newCategory = useMemo(() => {
    const parentCategory = categories?.filter((item) => item.type === 'P');

    const d = parentCategory?.map((item) => ({
      ...item,
      children: categories?.filter(
        (subitem) => item.category_id === subitem.parents_id
      ),
    }));
    return d;
  }, [categories]);
  // 강의 질문지
  const [question, setQuestion] = useState('');
  const [questionnaire, setQuestionnaire] = useState([]);

  /* ===== Mutate ===== */
  /**
   * 강의 등록
   * --
   */
  const createLecture = LectureApi.CreateLecture({
    onSuccess: async (data) => {
      try {
        if (file?.thumbnail) {
          const res = await FileUploadManager('L', file?.thumbnail);

          if (res.status === 200) {
            createFile.mutate({
              lecture_id: data?.data?.lecture_id,
              file_name: res.file_name,
              file_url: res.name,
              type: 'T',
            });
          }
        }

        if (file?.background) {
          const res = await FileUploadManager('L', file?.background);

          if (res.status === 200) {
            createFile.mutate({
              lecture_id: data?.data?.lecture_id,
              file_name: res.file_name,
              file_url: res.name,
              type: 'B',
            });
          }
        }

        chapterList.map((item, index) =>
          createChapter.mutate({
            lecture_id: data?.data?.lecture_id,
            ...item,
            order: index + 1,
          })
        );
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('강의 등록 실패!');
        throw e;
      }
    },
  });

  /**
   * 강의 수정
   * --
   */
  const updateLecture = LectureApi.UpdateLecture({
    onSuccess: async (data) => {
      try {
        // 업데이트
        const newArr = chapterList?.map((item, index) => ({
          ...item,
          order: index + 1,
          lecture_id: parseInt(decrypt(lectureId)),
          sections: sectionList
            ?.filter((subitem) => item.id === subitem.key)
            ?.map((subitem, subindex) => ({
              ...subitem,
              order: subindex + 1,
            })),
        }));
        updateChapter.mutate(newArr);

        if (file?.thumbnail) {
          if (file?.thumbnail?.size === 0) {
            return;
          }
          // 등록된 썸네일 파일이 있는지 체크
          const checkFile = lectureFiles?.filter(
            (item) =>
              item?.lecture_id === parseInt(decrypt(lectureId)) &&
              item?.type === 'T'
          )[0];

          const res = await FileUploadManager('L', file?.thumbnail);
          if (checkFile) {
            // 등록된 썸네일 파일이 있는 경우
            if (res.status === 200) {
              updateFile.mutate({
                lecture_file_id: checkFile?.lecture_file_id,
                lecture_id: checkFile?.lecture_id,
                file_name: res.file_name,
                file_url: res.name,
                type: 'T',
              });
            }
          } else {
            // 등록된 썸네일 파일이 없는 경우
            if (res.status === 200) {
              createFile.mutate({
                lecture_id: parseInt(decrypt(lectureId)),
                file_name: res.file_name,
                file_url: res.name,
                type: 'T',
              });
            }
          }
        } else {
          // 등록된 썸네일 파일이 있는지 체크
          const checkFile = lectureFiles?.filter(
            (item) =>
              item?.lecture_id === parseInt(decrypt(lectureId)) &&
              item?.type === 'T'
          )[0];

          if (checkFile) {
            deleteFile.mutate({
              lecture_file_id: checkFile?.lecture_file_id,
            });
          }
        }

        if (file?.background) {
          if (file?.background?.size === 0) {
            return;
          }
          // 등록된 백그라운드 파일이 있는지 체크
          const checkFile = lectureFiles?.filter(
            (item) =>
              item?.lecture_id === parseInt(decrypt(lectureId)) &&
              item?.type === 'B'
          )[0];

          const res = await FileUploadManager('L', file?.background);
          if (checkFile) {
            // 등록된 백그라운드 파일이 있는 경우
            if (res.status === 200) {
              updateFile.mutate({
                lecture_file_id: checkFile?.lecture_file_id,
                lecture_id: checkFile?.lecture_id,
                file_name: res.file_name,
                file_url: res.name,
                type: 'B',
              });
            }
          } else {
            // 등록된 백그라운드 파일이 없는 경우
            if (res.status === 200) {
              createFile.mutate({
                lecture_id: parseInt(decrypt(lectureId)),
                file_name: res.file_name,
                file_url: res.name,
                type: 'B',
              });
            }
          }
        } else {
          // 등록된 썸네일 파일이 있는지 체크
          const checkFile = lectureFiles?.filter(
            (item) =>
              item?.lecture_id === parseInt(decrypt(lectureId)) &&
              item?.type === 'B'
          )[0];

          if (checkFile) {
            deleteFile.mutate({
              lecture_file_id: checkFile?.lecture_file_id,
            });
          }
        }
        // // 삭제
        // // 원래 배열
        // const c = chapters?.filter((item) => item.lecture_id === lectureId);
        // // 제거된 데이터
        // const removeData = c?.filter(
        //   (item) =>
        //     !chapterList.some(
        //       (subitem) => subitem.chapter_id === item.chapter_id
        //     )
        // );
        // // 데이터 삭제
        // if (removeData && removeData.length > 0) {
        //   removeData?.map((item) =>
        //     deleteChapter({ chapter_id: item.chapter_id })
        //   );
        // }
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('강의 수정 실패!');
        throw e;
      }
    },
  });

  /**
   * 강의 챕터 등록
   * --
   */
  const createChapter = ChapterApi.CreateChapter({
    onSuccess: (data) => {
      try {
        const d = sectionList?.filter((item) => item.key === data?.req?.id);
        const newSections = d?.map((item, index) => ({
          chapter_id: data?.data?.chapter_id,
          section_name: item.section_name,
          order: index + 1,
          video_url:
            item.video_url && item.video_url.length > 0 ? item.video_url : null,
        }));

        createSection.mutate(newSections);
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('챕터 등록 실패!');
        throw e;
      }
    },
  });

  /**
   * 강의 챕터 수정
   * --
   */
  const updateChapter = ChapterApi.UpdateChapter({
    onSuccess: () => {
      try {
        // 삭제
        // const c = chapters?.filter((item) => item.lecture_id === lectureId);
        // // 원래 배열
        // const s = c?.reduce((acc, chap) => {
        //   const filteredSections = sections?.filter((sec) => {
        //     return sec.chapter_id === chap.chapter_id;
        //   });
        //   return acc.concat(filteredSections);
        // }, []);
        // // 제거된 데이터
        // const removeData = s?.filter(
        //   (item) =>
        //     !sectionList.some(
        //       (subitem) => subitem.section_id === item.section_id
        //     )
        // );
        // // 데이터 삭제
        // if (removeData && removeData.length > 0) {
        //   removeData?.map((item) =>
        //     deleteSection({ section_id: item.section_id })
        //   );
        // }
        // 업데이트
        // const newData = sectionList?.map((item) => ({
        //   ...item,
        //   // chapter_id: data?.data?.chapter_id,
        // }));
        // updateSection.mutate(newData);
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('챕터 수정 실패!');
        throw e;
      }
    },
  });

  /**
   * 강의 섹션 등록
   * --
   */
  const createSection = SectionApi.CreateSection();

  /**
   * 강의 파일 등록
   * --
   */
  const createFile = FileApi.CreateLectureFile();
  const updateFile = FileApi.UpdateLectureFile();
  const deleteFile = FileApi.DeleteLectureFile();

  /* ===== FUNCTION ===== */
  /**
   * 강의 등록 함수
   * --
   */
  const handleSubmit = () => {
    if (!chapterList || chapterList?.length === 0) {
      MessageAlert.warning('챕터를 하나이상 등록해주세요');
      return;
    }
    const newChapter = chapterList?.map((item) => ({
      ...item,
      sections: sectionList
        ?.filter((subitem) => item.id === subitem.key)
        ?.map((subitem, subindex) => ({
          ...subitem,
          order: subindex + 1,
        })),
    }));
    const hasEmptySections = newChapter.some((chapter) => {
      return !chapter.sections || chapter.sections.length === 0;
    });

    if (hasEmptySections) {
      MessageAlert.warning('챕터에는 하나이상의 섹션이 등록되어야 합니다');
      return;
    }

    if (type === 'create') {
      if (window.confirm('강의를 등록하시겠습니까?')) {
        try {
          const userId =
            loginWho === 'admin' ? inputData?.user_id : getCookie('USER');

          createLecture.mutate({
            user_id: userId,
            ...inputData,
            content: htmlString,
            questionnaire: questionnaire?.map((item, index) => ({
              key: index + 1,
              q: item.q,
            })),
          });

          MessageAlert.success('강의등록이 완료되었습니다');

          if (loginWho === 'admin') {
            navigate(`/admin/lectures?page=1`);
          } else {
            navigate('/mypage/my_lecture');
          }
        } catch (e) {
          console.log('Error!!', e);
          throw e;
        }
      } else {
        return;
      }
    } else {
      if (window.confirm('강의를 수정하시겠습니까?')) {
        try {
          updateLecture.mutate({
            ...inputData,
            content: htmlString,
            questionnaire: questionnaire?.map((item, index) => ({
              key: index + 1,
              q: item.q,
            })),
          });

          MessageAlert.success('강의수정이 완료되었습니다');
          if (parseInt(getCookie('USER')) === 0) {
            navigate('/admin/lectures?page=1');
          } else {
            navigate('/mypage/my_lecture');
          }
        } catch (e) {
          console.log('Error!!', e);
          throw e;
        }
      } else {
        return;
      }
    }
  };

  /**
   * 첨부파일
   * --
   * @param {*} t
   * @param {*} f
   */
  const handleFileChange = (t, f) => {
    let d = { ...file };
    d[t] = f;
    setFile(d);
  };

  /**
   * 첨부파일 삭제
   * --
   * @param {*} type
   */
  const handleDeleteFile = (type) => {
    const d = { ...file };
    d[type] = undefined;
    setFile(d);
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...inputData };

    if (t === 'price') {
      const value = newData[t];

      if (value === '' || value === null || value === undefined) {
        if (v === '' || v === null || v === undefined) {
          v = undefined;
        } else if (!validateCheck('number', v)) {
          MessageAlert.warning('숫자만 입력해주세요');
          v = undefined;
        }
      } else {
        if (v === '' || v === null || v === undefined) {
          v = undefined;
        } else if (!validateCheck('number', v)) {
          MessageAlert.warning('숫자만 입력해주세요');
          v = value;
        }
      }
    }

    newData[t] = v;
    const d = {
      ...newData,
    };
    setInputData(d);

    if (callback) callback(v);
  };

  /**
   * 데이터 배열 삽입 함수
   * --
   * @param {*} type
   * @param {*} value
   */
  const handleDataList = (type, value) => {
    if (value === null || value === undefined) {
      MessageAlert.warning('값을 입력해주세요');
      return false;
    }

    switch (type) {
      case 'chapter':
      case 'Chapter':
      case 'CHAPTER':
        const lastId =
          chapterList.length === 0
            ? 0
            : chapterList.reduce((prev, current) => current.id, null);

        const c = {
          id: lastId + 1,
          chapter_name: value,
          order: chapterList.length + 1,
        };
        setChapterList([...chapterList, c]);
        break;
      case 'section':
      case 'Section':
      case 'SECTION':
        // 섹션 리스트 추가
        const s = {
          key: value.id,
          id: sectionList.length + 1,
          section_name: value.section_name,
          video_url: value.video_url,
          order: sectionList.length + 1,
          preview: value.preview,
        };
        setSectionList([...sectionList, s]);
        break;
      case 'section_update':
      case 'Section_Update':
      case 'SECTION_UPDATE':
        const index = sectionList.findIndex((item) => item.id === value.id);
        const newArr = [...sectionList];
        newArr[index] = value;
        // console.log('value', value);

        setSectionList(newArr);
        break;
      default:
        break;
    }
  };
  // console.log('sectionList', sectionList);

  /* ===== HOOKS ===== */
  /**
   * 카테고리 아이템 정제
   * --
   */
  useEffect(() => {
    setCategoryItems(newCategory);
  }, [newCategory]);

  /**
   * 강의 데이터 Refetch
   * --
   */
  useEffect(() => {
    if (lectureId) refetch();
  }, [lectureId, refetch]);

  /**
   * 강의 수정 데이터 정제
   * --
   */
  useEffect(() => {
    if (lectureId && lectureId !== null && lectureId !== undefined) {
      setType('update');

      const l = lecture?.data?.lecture; // 강의 데이터
      const c = lecture?.data?.chapter; // 챕터 데이터
      const s = lecture?.data?.section; // 섹션 데이터

      // 등록된 강의 정보
      setInputData({
        ...l,
      });
      setHtmlString(l?.content);
      if (l?.questionnaire && l?.questionnaire.length > 0) {
        setQuestionnaire([...l?.questionnaire]);
      }

      // 등록된 챕터 정보
      const newChapters =
        c?.map((item, index) => ({
          id: index + 1,
          ...item,
        })) ?? [];
      setChapterList(newChapters);

      // 등록된 섹션 정보
      const newSections =
        s?.map((item, index) => ({
          key:
            newChapters &&
            newChapters
              ?.filter((subitem) => subitem.chapter_id === item.chapter_id)
              ?.pop()?.id,
          id: index + 1,
          ...item,
        })) ?? [];
      setSectionList(newSections);

      const newFiles = lectureFiles?.filter(
        (item) => item?.lecture_id === parseInt(decrypt(lectureId))
      );
      if (newFiles) {
        const thumbnail = newFiles?.filter((item) => item?.type === 'T')[0];
        const background = newFiles?.filter((item) => item?.type === 'B')[0];

        const blob = new Blob();
        setFile({
          thumbnail: thumbnail
            ? new File([blob], thumbnail?.file_name)
            : undefined,
          background: background
            ? new File([blob], background?.file_name)
            : undefined,
        });
      }
    } else {
      setType('create');
    }
  }, [lectureId, lecture, lectureFiles]);

  /**
   * 일반회원, 기관 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'user' || loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== RENDER ===== */
  return (
    <Content maxWidth={'100%'} padding={0}>
      <Content maxWidth={1200} padding={screenSize >= 1200 ? 0 : '0 30px'}>
        <Row>
          <Col x={24} padding={'48px 0 85px'}>
            <Routes>
              <Route
                path="1"
                element={
                  <LectureRegister
                    type={type}
                    category={categoryItems}
                    inputData={inputData}
                    setInputData={setInputData}
                    file={file}
                    onFileChange={handleFileChange}
                    onFileDelete={handleDeleteFile}
                    setFile={setFile}
                    onChange={handleChange}
                    htmlString={htmlString}
                    setHtmlString={setHtmlString}
                    question={question}
                    setQuestion={setQuestion}
                    questionnaire={questionnaire}
                    setQuestionnaire={setQuestionnaire}
                    difficulty={difficulty}
                    screenSize={screenSize}
                    loginWho={loginWho}
                    teachers={teachers}
                  />
                }
              />
              <Route
                path="2"
                element={
                  <ChapterRegister
                    type={type}
                    chapters={chapterList}
                    setChapters={setChapterList}
                    sections={sectionList}
                    setSections={setSectionList}
                    onDataList={handleDataList}
                    onSubmit={handleSubmit}
                    inputData={inputData}
                    htmlString={htmlString}
                    questionnaire={questionnaire}
                    screenSize={screenSize}
                  />
                }
              />
            </Routes>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

LectureRoutes.defaultProps = {};

export default LectureRoutes;
