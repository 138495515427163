import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 스케줄 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetSchedules: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getSchedules', params] : 'getSchedules',
        async () => await $http.get(APIConstant.GET_SCHEDULES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케줄 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetSchedule: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_SCHEDULE, {
        schedule_id: idx,
      });
      const res = useQuery(
        params ? ['getSchedule', params] : 'getSchedule',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케줄 생성
   * --
   * @method POST
   * @returns
   */
  CreateSchedule: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_SCHEDULE, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getSchedules');
            queryClient.invalidateQueries('getSchedule');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케줄 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateSchedule: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_SCHEDULE, {
            schedule_id: data.schedule_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getSchedules');
            queryClient.invalidateQueries('getSchedule');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케줄 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteSchedule: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_SCHEDULE, {
            schedule_id: data.schedule_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getSchedules');
            queryClient.invalidateQueries('getSchedule');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케줄 일괄 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteBulkSchedule: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.delete(
            APIConstant.DELETE_BULK_SCHEDULE,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getSchedules');
            queryClient.invalidateQueries('getSchedule');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
