import React from 'react';
import LectureRegisterPresenter from './LectureRegisterPresenter';

const LectureRegisterContainer = (props) => {
  /* ===== ROUTE ===== */
  /* ===== Props ===== */
  const {
    type,
    inputData,
    setInputData,
    onChange,
    file,
    onFileChange,
    onFileDelete,
    htmlString,
    setHtmlString,
    category,
    question,
    setQuestion,
    questionnaire,
    setQuestionnaire,
    difficulty,
    screenSize,
    loginWho,
    teachers,
  } = props;

  /* ===== STATE ===== */
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <LectureRegisterPresenter
      type={type}
      inputData={inputData}
      setInputData={setInputData}
      onChange={onChange}
      file={file}
      onFileChange={onFileChange}
      onFileDelete={onFileDelete}
      htmlString={htmlString}
      setHtmlString={setHtmlString}
      category={category}
      question={question}
      setQuestion={setQuestion}
      questionnaire={questionnaire}
      setQuestionnaire={setQuestionnaire}
      difficulty={difficulty}
      screenSize={screenSize}
      loginWho={loginWho}
      teachers={teachers}
    />
  );
};

LectureRegisterContainer.defaultProps = {};

export default LectureRegisterContainer;
