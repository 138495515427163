import CryptoJS from 'crypto-js';
import { SECRET_KEY } from 'utils';

/**
 * 암호화
 * --
 * @param {*} text
 * @returns
 */
export const encrypt = (text) => {
  const newText = typeof text === 'number' ? text.toString() : text;

  const cipher = CryptoJS.AES.encrypt(
    newText,
    CryptoJS.enc.Utf8.parse(SECRET_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(''),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );

  return cipher.toString();
};

/**
 * 복호화
 * --
 * @param {*} encryptedText
 * @returns
 */
export const decrypt = (encryptedText) => {
  if (encryptedText) {
    const decipher = CryptoJS.AES.decrypt(
      encryptedText,
      CryptoJS.enc.Utf8.parse(SECRET_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse(''),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );

    return decipher.toString(CryptoJS.enc.Utf8);
  } else {
    return;
  }
};
