/*
 *탭 목록 컴포넌트
 * --
 * date: 2023-07-04
 * writer: Owen
 */
import React from 'react';
import PropTypes from 'prop-types';
import './Tabs.style.css';

const Tabs = ({ items, value, setValue, style }) => {
  return (
    <div className="tab-layout" style={{ ...style }}>
      <ul className="tabs">
        {items.map((item, index) => (
          <li key={index + '_' + index}>
            <div
              className="tab_radio_button"
              onClick={item?.onClick && item?.onClick}
            >
              <input
                id={item.value}
                className="tab_radio_input"
                type="radio"
                name="radio_group"
                value={item.value}
                checked={value === item.value ? true : false}
                onChange={(e) => setValue(e.target.value)}
              />
              <label htmlFor={item.value} className="tab_radio_label">
                {item.label}
              </label>
            </div>
            {/* <p style={{ fontSize: `${fontSize}`, fontWeight: `${fontWeight}` }}>
              {item + unit}
            </p> */}
          </li>
        ))}
      </ul>
    </div>
  );
};

Tabs.defaultProps = {
  items: [
    {
      label: '아이템1',
      value: '아이템1',
    },
    {
      label: '아이템2',
      value: '아이템2',
    },
    {
      label: '아이템3',
      value: '아이템3',
    },
  ],
  setValue: () => {},
  // defaultValue: items[0].value,
};

Tabs.propTypes = {
  items: PropTypes.array,
  unit: PropTypes.string,
};

export default Tabs;
