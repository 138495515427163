/**
 *
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import {
  ModalLayout,
  Content,
  Title,
  // PageHeader,
  // CloseButton,
  // Row,
  // Col,
} from 'components';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  // MarkerF,
} from '@react-google-maps/api';
import { Tag, Rate, Button } from 'antd';
import PinIcon from 'assets/pin_icon.png';
import Icon06 from 'assets/bg/icons/06_chevron_right.png';
// import { Divider } from 'antd';
import { guidGenerator } from 'utils';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'antd';

/* ===== Statics ===== */
const MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
const containerStyle = {
  width: '100%',
  height: '100%',
};

const defaultCenter = {
  position: {
    lat: 35.15781,
    lng: 129.06003,
  },
  zoom: 18,
};

/**
 * [Component] GoogleMapCustom
 * --
 */
const GoogleMapCustom = ({ markers, onChangeCurrentLocation }) => {
  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== Init ===== */
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAP_KEY,
  });

  /* ===== State ===== */
  //
  // const [mapref, setMapRef] = React.useState(null);
  // 헬퍼 상세보기
  const [detailModal, setDetailModal] = useState(null);
  // 화면크기
  // const [screenSize] = useState(document.body.clientWidth);
  // 지도 객체
  const [map, setMap] = useState(null);
  // 중앙점 좌표
  const [center, setCenter] = useState(null);
  // 확대 레벨
  const [zoom, setZoom] = useState(19);

  /* ===== Variables ===== */
  // onLoad
  const onLoad = useCallback(
    (_map) => {
      const bounds = new window.google.maps.LatLngBounds(
        defaultCenter.position
      );

      // console.log('bounds: ',bounds);
      _map.fitBounds(bounds);
      // console.log('_map: ', _map);
      setMap(_map);
    },
    // [markers]
    []
  );

  // onUnmount
  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  const handleCenterChanged = () => {
    if (map) {
      const newCenter = map.getCenter();
      const newLocation = {
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      };
      // console.log('newLocation: ', newLocation);
      setCenter(newLocation);
      onChangeCurrentLocation(newLocation);
    }
  };

  /* ===== Variables ===== */
  /**
   * 서비스 요청 페이지 이동
   * --
   */
  const handleRequest = (navi, id = null) => {
    navi(`/mobile/request${id ? `?helperId=${id}` : ``}`);
  };

  /*  */
  useEffect(() => {
    if (isLoaded && !center) {
      setCenter(defaultCenter);
      // setZoom(18);
      setTimeout(() => {
        setZoom(18);
        // handleCenterChanged();
      }, 300);
    }
    // [isLoaded]
  }, [isLoaded, center]);

  /*  */
  const helperInfo = detailModal
    ? markers.filter((m) => m.id === detailModal)[0]
    : null;

  /* ===== Render ===== */
  return isLoaded && center ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center.position}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        // onCenterChanged={handleCenterChanged}
      >
        <Button
          size="large"
          style={{ posision: 'fixed', top: 55, left: 10 }}
          shape="round"
          onClick={() => handleCenterChanged()}
        >
          <img src={PinIcon} style={{ width: 12, marginRight: 3 }} alt="" />
          현위치
        </Button>
        {markers.map((location) => {
          return (
            <Marker
              key={guidGenerator()}
              className="cusorOver"
              icon={{ url: PinIcon, scale: 12 }}
              position={location}
              onClick={() => setDetailModal(location.id)}
              // onClick={() => setDetailModal(location.helper.helper_idx)}
              containerStyle={{ cursor: 'pointer' }}
            />
          );
        })}
      </GoogleMap>

      {/* ===== 하단 모달 ===== */}
      <ModalLayout
        type="drawer"
        width={'100%'}
        height={230}
        placement={'bottom'}
        // placement={screenSize >= 768 ? 'right' : 'bottom'}
        closable={false}
        onCancel={() => setDetailModal(null)}
        visible={detailModal !== null}
        footerStyle={{ padding: 0 }}
        footer={
          <>
            <Button
              block
              size="large"
              type="primary"
              onClick={() => handleRequest(navigate, detailModal)}
            >
              요청하기
            </Button>
          </>
        }
      >
        {helperInfo && (
          <Content
            backgroundColor={'#fff'}
            maxWidth={'100%'}
            style={{ marginBottom: 1, padding: '10px 0' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'flex-start',
              }}
              onClick={() => navigate(`/mobile/helpers/${helperInfo.id}`)}
            >
              <div
                style={{
                  width: 50,
                  height: 50,
                  position: 'relative',
                  background: '#f1f1f1',
                  borderRadius: 45,
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  // fontSize: '1.5em',
                  lineHeight: '100%',
                  padding: 0,
                  color: '#dbdbdb',
                  marginRight: 8,
                }}
              >
                {/* <span></span> */}
                <Avatar size={50}>{helperInfo.member_name[0]}</Avatar>
              </div>
              <div>
                <Title size={4} color="#2A313B" align="left">
                  <>
                    {helperInfo.member_name}
                    <img
                      src={Icon06}
                      alt=""
                      style={{
                        width: 10,
                        height: 15,
                        marginLeft: 7,
                        marginTop: -5,
                      }}
                    />
                  </>
                </Title>
                <p>
                  <Rate value={4.5} allowHalf />{' '}
                  {helperInfo.distance >= 1
                    ? `${helperInfo.distance.toFixed(1)}km`
                    : `1km 이내`}
                </p>
                <div style={{ textAlign: 'left' }}>
                  <Tag>시간 약속을 잘 지켜요.</Tag>
                  <Tag>응답이 빨라요.</Tag>
                </div>
              </div>
            </div>
          </Content>
        )}
      </ModalLayout>
    </>
  ) : (
    <></>
  );
};

/* Default props */
GoogleMapCustom.defaultProps = {
  markers: [],
  onChangeCurrentLocation: () => {},
};

export default React.memo(GoogleMapCustom);
