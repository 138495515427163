import React from 'react';
import { Content, Row, Col, Title } from 'components';
import './Service.style.css';

const Service = ({ screenSize }) => {
  return (
    <Content maxWidth={'100%'}>
      <Content
        maxWidth={1200}
        padding={screenSize >= 768 ? '120px 0' : '60px 0'}
      >
        <Row>
          <Col x={24}>
            <div id="service_layout">
              <Title size={screenSize >= 768 ? 1 : 3} align={'center'}>
                브레인힐 시니어 콘텐츠랩
              </Title>
              <Title size={screenSize >= 768 ? 1 : 3} align={'center'}>
                원격 평생교육원 이용 약관
              </Title>
              <ul>
                <li>
                  <h5>제 1 조 (목적)</h5>
                  <p>
                    이 약관은 브레인힐 시니어 콘텐츠랩 원격 평생교육원(이하
                    "교육원")이 제공하는 모든 서비스의 이용조건 및 절차,
                    이용자와 교육원의 권리, 의무 및 책임사항, 서비스 이용에 관한
                    기타 필요한 사항을 규정함을 목적으로 한다.
                  </p>
                </li>
                <li>
                  <h5>제 2 조 (정의)</h5>
                  <p>
                    서비스 : 교육원이 이용자에게 제공하는 모든 웹 기반 학습
                    콘텐츠 및 관련 지원 서비스.
                    <br />
                    이용자 : 교육원과 서비스 이용 계약을 체결하고, 이 약관에
                    따라 교육원이 제공하는 서비스를 이용하는 개인 또는 단체.
                    <br />
                    회원 : 교육원에 개인 정보를 제공하여 회원 등록을 한 개인
                    또는 단체로서, 교육원이 제공하는 서비스를 지속적으로 이용할
                    수 있는 자격을 가진 자.
                    <br />
                    아이디(ID) 및 비밀번호 : 회원의 식별과 서비스 이용을 위해
                    회원이 설정하고 교육원이 승인하는 문자 또는 숫자의 조합.
                    <br />
                  </p>
                </li>
                <li>
                  <h5>제 3 조 (약관의 게시와 변경)</h5>
                  <p>
                    교육원은 이 약관을 회원이 쉽게 알 수 있도록 교육원의 초기
                    화면에 게시한다. <br />
                    교육원은 필요한 경우 관련 법령을 위반하지 않는 범위에서 이
                    약관을 수정할 수 있으며, 변경된 약관은 적용일자 및
                    변경사유를 명시하여 공지한다. <br />
                    약관이 변경된 경우, 변경 약관은 공지 후 즉시 효력이
                    발생하며, 이용자는 변경된 약관에 동의하지 않을 시 이용
                    계약을 해지할 수 있다. <br />
                  </p>
                </li>
                <li>
                  <h5>제 4 조 (서비스의 제공 및 변경)</h5>
                  <p>
                    교육원은 다음과 같은 서비스를 제공한다:
                    <br />
                    원격 평생 교육 프로그램
                    <br />
                    관련 지원 서비스
                    <br />
                    교육원은 운영상 또는 기술상 필요에 의해 서비스의 내용을
                    변경할 수 있으며, 변경될 서비스의 내용 및 제공 일자를 사전에
                    공지한다.
                    <br />
                  </p>
                </li>
                <li>
                  <h5>제 5 조 (회원가입)</h5>
                  <p>
                    회원가입은 이용자가 교육원의 약관에 동의하고 가입신청을
                    하며, 교육원의 승인을 받아 이루어진다. 교육원은 가입신청자에
                    대해 회원가입을 승인하는 것을 원칙으로 하나, 다음의 경우
                    가입을 제한할 수 있다:
                    <br />
                    가입신청자가 이 약관에 의해 이전에 회원자격을 상실한 경우
                    <br />
                    허위의 정보를 제공하거나, 필요한 정보를 기재하지 않은 경우
                    <br />
                  </p>
                </li>
                <li>
                  <h5>제 6 조 (회원 의무)</h5>
                  <p>
                    회원은 서비스 이용과 관련하여 다음 행위를 하지 않아야 한다:
                    <br />
                    타인의 정보 도용
                    <br />
                    교육원의 서비스 정보 변경
                    <br />
                    교육원의 저작권 및 지적 재산권 침해
                    <br />
                    교육원 또는 타인의 명예 손상 및 업무 방해
                    <br />
                  </p>
                </li>
                <li>
                  <h5>제 7 조 (서비스 이용제한)</h5>
                  <p>
                    교육원은 회원이 본 약관의 의무를 위반하거나 서비스의
                    정상적인 운영을 방해하는 경우, 서비스 이용을 제한할 수 있다.
                  </p>
                </li>
                <li>
                  <h5>제 8 조 (회원정보의 변경)</h5>
                  <p>
                    회원은 개인정보 변경이 있는 경우, 교육원의 웹사이트를 통해
                    이를 즉시 갱신해야 한다.
                    <br />
                    회원정보의 변경을 게을리하여 발생하는 불이익에 대해 교육원은
                    책임지지 않는다.
                    <br />
                  </p>
                </li>
                <li>
                  <h5>제 9조 (개인정보보호 의무)</h5>
                  <p>
                    교육원은 개인정보보호법을 준수하며, 회원의 개인정보를
                    보호하고, 안전하게 처리하기 위하여 최선을 다한다.
                    개인정보보호에 관한 자세한 사항은 교육원의 개인정보
                    처리방침을 참조한다.
                  </p>
                </li>
                <li>
                  <h5>제 10 조 (회원의 아이디 및 비밀번호 관리 의무)</h5>
                  <p>
                    회원의 아이디와 비밀번호의 관리책임은 회원 본인에게 있다.
                    <br />
                    회원은 자신의 아이디 및 비밀번호를 제3자에게 이용하게 해서는
                    안 되며, 유출되었을 경우 즉시 교육원에 통보하고 교육원의
                    안내에 따라야 한다.
                    <br />
                  </p>
                </li>
                <li>
                  <h5>제 11 조 (교육원의 의무)</h5>
                  <p>
                    교육원은 법령과 이 약관이 금지하는 행위를 하지 않으며,
                    안정적인 서비스 제공을 위해 최선을 다한다.
                    <br />
                    교육원은 서비스 이용과 관련하여 회원의 의견이나 불만을
                    신속하게 처리하기 위해 노력한다.
                    <br />
                  </p>
                </li>
                <li>
                  <h5>제 12 조 (정보의 제공 및 광고의 게재)</h5>
                  <p>
                    교육원은 회원에게 서비스 이용에 필요하거나 유익한 정보를
                    전자우편, 문자메시지 등으로 제공할 수 있다. 회원은 이러한
                    정보 수신을 원치 않을 경우 수신 거부를 할 수 있다.
                    <br />
                    교육원은 서비스의 운영과 관련하여 광고를 게재할 수 있으며,
                    회원은 광고 수신 동의를 철회할 수 있다.
                  </p>
                </li>
                <li>
                  <h5>제 13 조 (수강 승인)</h5>
                  <p>
                    교육원은 회원이 수강신청 후 해당 수강료를 납입하면, 신속하게
                    수강 신청을 승인한다.
                    <br />
                    교육원은 다음과 같은 경우 수강 신청의 승인을 제한할 수 있다.
                    <br />
                    • 수강료 미납
                    <br />• 교육원의 수용 능력 초과
                  </p>
                </li>
                <li>
                  <h5>제 14 조 (수강 취소 및 환불)</h5>
                  <p>
                    회원이 수강 취소를 원할 경우, 교육 시작 전까지는 전액
                    환불받을 수 있다.
                    <br />
                    교육 시작 후 환불 조건은 교육원의 환불 규정에 따라 결정된다.
                  </p>
                </li>
                <li>
                  <h5>제 15 조 (계약의 해지)</h5>
                  <p>
                    회원은 언제든지 교육원에 계약 해지를 요청할 수 있으며,
                    교육원은 이를 즉시 처리한다. 계약 해지 시, 회원은 그동안의
                    데이터를 백업할 책임을 진다.
                  </p>
                </li>
                <li>
                  <h5>제 16 조 (약관의 해석)</h5>
                  <p>
                    이 약관에서 정하지 않은 사항에 대해서는 관련 법령 또는
                    관례에 따른다.
                  </p>
                </li>
                <li>
                  <h5>부칙</h5>
                  <p>
                    이 약관은 2023년 4월 1일부터 시행되며, 이 약관에 명시되지
                    않은 사항은 관계 법령 또는 상관례에 따른다.
                  </p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default Service;
