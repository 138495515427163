import React, { useState, useEffect } from 'react';
import EventRegisterPresenter from './EventRegisterPresenter';
import { useSearchParams } from 'react-router-dom';
import { EventApi } from 'api';
import { decrypt } from 'utils/Crypto';

const EventRegisterContainer = (props) => {
  /* ===== Props ===== */

  /* ===== Router ===== */
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get('event');

  /* ===== State ===== */
  const [newEvent, setNewEvent] = useState({});
  const [type, setType] = useState('create');
  const { data: event } = EventApi.GetEvent(parseInt(decrypt(eventId)));

  /* ===== Hooks ===== */
  useEffect(() => {
    if (eventId) {
      if (event && event.status === 200) {
        const e = event?.data;

        setNewEvent(e);
        setType('update');
      }
    }
  }, [event, eventId]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <EventRegisterPresenter data={newEvent} type={type} />;
};

EventRegisterContainer.defaultProps = {};

export default EventRegisterContainer;
