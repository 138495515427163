import React, { useState, useEffect } from 'react';
import { Content, Row, Col, Title, ModalLayout, Button } from 'components';
import { Tree, Input, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ProductCategoryApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { Empty } from 'antd';

const ProductCategoryPresenter = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== Mutate ===== */
  const createCategory = ProductCategoryApi.CreateProductCategory();
  const updateCategory = ProductCategoryApi.UpdateProductCategory();
  const deleteCategory = ProductCategoryApi.DeleteProductCategory();

  /* ===== State ===== */
  const [treeData, setTreeData] = useState([]);
  const [selectData, setSelectData] = useState({});
  const [parentCategories, setParentCategories] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [modalData, setModalData] = useState({
    product_category_name: undefined,
    type: undefined,
    parents_id: undefined,
    product_type: undefined,
  });
  const [key, setKey] = useState('');

  /* ===== Hooks ===== */
  useEffect(() => {
    let pParentsArr = [],
      pChildrenArr = [],
      dParentsArr = [],
      dChildrenArr = [],
      cParentsArr = [],
      cChildrenArr = [];
    data?.forEach((item) => {
      if (item?.product_type === 'D') {
        if (item.type === 'P') {
          dParentsArr.push(item);
        } else {
          dChildrenArr.push(item);
        }
      } else if (item?.product_type === 'C') {
        if (item.type === 'P') {
          cParentsArr.push(item);
        } else {
          cChildrenArr.push(item);
        }
      } else {
        if (item.type === 'P') {
          pParentsArr.push(item);
        } else {
          pChildrenArr.push(item);
        }
      }
    });
    const newArr = [
      pParentsArr?.map((item) => ({
        ...item,
        children: pChildrenArr?.filter(
          (subitem) => item?.product_category_id === subitem?.parents_id
        ),
      })),
      dParentsArr?.map((item) => ({
        ...item,
        children: dChildrenArr?.filter(
          (subitem) => item?.product_category_id === subitem?.parents_id
        ),
      })),
      cParentsArr?.map((item) => ({
        ...item,
        children: cChildrenArr?.filter(
          (subitem) => item?.product_category_id === subitem?.parents_id
        ),
      })),
    ];

    const tree = newArr?.map((item, index) => ({
      key: index === 0 ? 'P' : index === 1 ? 'D' : 'C',
      title:
        index === 0 ? (
          <p style={{ fontSize: '14px', fontWeight: 600 }}>쇼핑몰</p>
        ) : index === 1 ? (
          <p style={{ fontSize: '14px', fontWeight: 600 }}>치매예방다이어리</p>
        ) : (
          <p style={{ fontSize: '14px', fontWeight: 600 }}>소셜챌린지</p>
        ),
      children: item?.map((subitem) => ({
        key: subitem?.product_category_id,
        title: subitem?.product_category_name,
        children: subitem?.children?.map((thirditem) => ({
          key: thirditem?.product_category_id,
          title: thirditem?.product_category_name,
        })),
      })),
    }));

    setTreeData(tree);
  }, [data]);

  useEffect(() => {
    if (!isOpened) {
      setModalData({
        product_category_name: undefined,
        parents_id: undefined,
        product_type: undefined,
      });
    }
  }, [isOpened]);

  /* ===== Functions ===== */
  /**
   * 트리 Item 클릭 이벤트
   * --
   * @param {*} key
   */
  const handleTreeSelect = (key) => {
    const id = key[0];
    setKey(id);
    const selected = data?.filter(
      (item) => item?.product_category_id === parseInt(id)
    )[0];

    if (selected?.parents_id) {
      const parentsCategory = data?.filter(
        (item) =>
          item?.type === 'P' && item?.product_type === selected?.product_type
      );
      setParentCategories(parentsCategory);
    }
    setSelectData(selected);
  };

  /**
   * 카테고리 추가 모달
   * --
   * @param {*} data
   * @param {*} open
   */
  const handleAddCategoryModal = (data, open) => {
    const d = { ...modalData };
    if (data?.product_category_id) {
      d['parents_id'] = data?.product_category_id;
      d['product_type'] = data?.product_type;
      d['type'] = 'C';
    } else {
      d['parents_id'] = undefined;
      d['product_type'] = data?.product_type;
      d['type'] = 'P';
    }
    setModalData(d);
    setIsOpened(!open);
  };

  /**
   * 카테고리 데이터 추가/수정/삭제 함수
   * --
   * @param {*} type
   */
  const handleCategory = (type) => {
    if (type === 'C') {
      if (
        !modalData?.product_category_name ||
        !modalData?.product_category_name === ''
      ) {
        MessageAlert.warning('카테고리명을 입력해주세요');
        return;
      }

      const confirm = window.confirm('카테고리를 추가하시겠습니까?');

      if (confirm) {
        const list = data?.filter(
          (item) => item?.parents_id === modalData?.parents_id
        );
        const dataCheck = list?.find(
          (item) =>
            item?.product_category_name === modalData?.product_category_name
        );
        if (dataCheck) {
          MessageAlert.warning('같은 카테고리 이름을 가진 값이 존재합니다');
          return;
        }

        try {
          createCategory.mutate({
            ...modalData,
          });

          MessageAlert.success('카테고리가 생성되었습니다');
          setIsOpened(false);
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('카테고리 생성에 실패하였습니다');
          throw e;
        }
      }
    } else if (type === 'U') {
      if (
        !selectData?.product_category_name ||
        !selectData?.product_category_name === ''
      ) {
        MessageAlert.warning('카테고리명을 입력해주세요');
        return;
      }

      const confirm = window.confirm('카테고리를 수정하시겠습니까?');

      if (confirm) {
        const list = data?.filter(
          (item) => item?.parents_id === modalData?.parents_id
        );
        const dataCheck = list?.find(
          (item) =>
            item?.product_category_name === modalData?.product_category_name
        );
        if (dataCheck) {
          MessageAlert.warning('같은 카테고리 이름을 가진 값이 존재합니다');
          return;
        }

        try {
          updateCategory.mutate({
            ...selectData,
          });

          MessageAlert.success('카테고리가 정상적으로 수정되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('카테고리 수정에 실패하였습니다');
          throw e;
        }
      }
    } else if (type === 'D') {
      const confirm = window.confirm(
        '카테고리를 삭제하시겠습니까?\n삭제시 관련된 데이터가 손상될 수 있습니다'
      );

      if (confirm) {
        try {
          deleteCategory.mutate({
            product_category_id: selectData?.product_category_id,
          });

          setSelectData({});
          MessageAlert.success('카테고리가 정상적으로 삭제되었습니다');
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('카테고리 삭제에 실패하였습니다');
          throw e;
        }
      }
    }
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} w
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (w, t, v, callback = null) => {
    if (w === 'modal') {
      let newData = { ...modalData };

      newData[t] = v;
      const d = {
        ...newData,
      };
      setModalData(d);

      if (callback) callback(v);
    } else {
      let newData = { ...selectData };

      newData[t] = v;
      const d = {
        ...newData,
      };
      setSelectData(d);

      if (callback) callback(v);
    }
  };

  // console.log('key', key);

  /* ===== Render ===== */
  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              padding={0}
              backgroundColor={'none'}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <Title size={2}>상품 카테고리 관리</Title>
              {key &&
              key !== 'P' &&
              key !== 'D' &&
              key !== 'C' &&
              !selectData?.parents_id ? (
                <Button
                  layoutStyle={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 0,
                  }}
                  style={{ padding: '10px 11px' }}
                  onClick={() =>
                    handleAddCategoryModal(
                      selectData ? selectData : { product_type: key },
                      isOpened
                    )
                  }
                >
                  {'하위 추가'}
                </Button>
              ) : (
                <Button
                  layoutStyle={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    visibility: 'hidden',
                    margin: 0,
                  }}
                  style={{ padding: '10px 11px' }}
                  onClick={() => handleAddCategoryModal(0, isOpened)}
                >
                  {'추가'}
                </Button>
              )}
            </Content>
            <Row>
              <Col x={8} padding={8}>
                {/* <Button
                  layoutStyle={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    visibility: 'hidden',
                    marginBottom: '12px',
                  }}
                  style={{ padding: '10px 11px' }}
                  onClick={() => handleAddCategoryModal(0, isOpened)}
                >
                  {'추가'}
                </Button> */}
                <Content
                  maxWidth={'100%'}
                  padding={24}
                  className={'admin_container'}
                  maxHeight={'80vh'}
                  style={{ overflowY: 'scroll' }}
                >
                  {treeData && treeData?.length > 0 ? (
                    <Tree
                      switcherIcon={<DownOutlined />}
                      onSelect={handleTreeSelect}
                      treeData={treeData}
                      expandAction="click"
                    />
                  ) : (
                    <Content
                      maxWidth={'100%'}
                      height={'100%'}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Content>
                  )}
                </Content>
              </Col>
              <Col x={16} padding={8}>
                {/* <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{ textAlign: 'right', marginBottom: '12px' }}
                >
                  {key &&
                  key !== 'P' &&
                  key !== 'D' &&
                  key !== 'C' &&
                  !selectData?.parents_id ? (
                    <Button
                      layoutStyle={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      style={{ padding: '10px 11px' }}
                      onClick={() =>
                        handleAddCategoryModal(
                          selectData ? selectData : { product_type: key },
                          isOpened
                        )
                      }
                    >
                      {'하위 추가'}
                    </Button>
                  ) : (
                    <Button
                      layoutStyle={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        visibility: 'hidden',
                      }}
                      style={{ padding: '10px 11px' }}
                      onClick={() => handleAddCategoryModal(0, isOpened)}
                    >
                      {'추가'}
                    </Button>
                  )}
                </Content> */}
                {selectData && Object.keys(selectData)?.length > 0 ? (
                  <Content
                    maxWidth={'100%'}
                    padding={24}
                    height={'80vh'}
                    className={'admin_container'}
                  >
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      backgroundColor={'#EEEEEE'}
                      style={{ borderRadius: '4px' }}
                    >
                      {/* 상위 메뉴 */}
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: '#EEEEEE',
                          padding: '10px',
                          borderRadius: '4px',
                        }}
                      >
                        <Col
                          x={6}
                          style={{
                            background: '#D9D9D9',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                          }}
                        >
                          <Title
                            style={{
                              fontSize: '17px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                              fontWeight: 700,
                            }}
                          >
                            상위메뉴
                          </Title>
                        </Col>
                        <Col x={1}></Col>
                        <Col
                          x={17}
                          style={{
                            background: '#FFFFFF',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                            padding: '0 20px',
                          }}
                        >
                          {selectData && selectData?.parents_id ? (
                            <Select
                              size="large"
                              style={{ width: '100%' }}
                              value={selectData?.parents_id}
                              options={parentCategories?.map((item) => ({
                                label: item?.product_category_name,
                                value: item?.product_category_id,
                              }))}
                              onChange={(e) =>
                                handleChange('data', 'parents_id', e)
                              }
                              bordered={false}
                            />
                          ) : selectData?.product_category_name &&
                            !selectData?.parents_id ? (
                            <Title
                              style={{
                                fontSize: '17px',
                                lineHeight: '38px',
                                letterSpacing: '0.02em',
                              }}
                            >
                              {selectData?.product_type === 'C'
                                ? '소셜챌린지'
                                : selectData?.product_type === 'D'
                                ? '치매예방다이어리'
                                : '쇼핑몰'}
                            </Title>
                          ) : (
                            <Title
                              style={{
                                fontSize: '17px',
                                lineHeight: '38px',
                                letterSpacing: '0.02em',
                                padding: '0 11px',
                              }}
                            >
                              -
                            </Title>
                          )}
                        </Col>
                      </Row>
                      {/* 카테고리 명 */}
                      <Row
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: '#EEEEEE',
                          padding: '10px',
                        }}
                      >
                        <Col
                          x={6}
                          style={{
                            background: '#D9D9D9',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                          }}
                        >
                          <Title
                            style={{
                              fontSize: '17px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                              fontWeight: 700,
                            }}
                          >
                            카테고리명
                          </Title>
                        </Col>
                        <Col x={1}></Col>
                        <Col
                          x={17}
                          style={{
                            background: '#FFFFFF',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                            padding: '0 20px',
                          }}
                        >
                          {selectData && selectData?.parents_id ? (
                            <Input
                              size="large"
                              placeholder="카테고리명을 입력해주세요"
                              value={selectData?.product_category_name}
                              onChange={(e) =>
                                handleChange(
                                  'data',
                                  'product_category_name',
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <Title
                              style={{
                                fontSize: '17px',
                                lineHeight: '38px',
                                letterSpacing: '0.02em',
                              }}
                            >
                              {selectData?.product_category_name}
                            </Title>
                          )}
                        </Col>
                      </Row>
                    </Content>
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{ marginTop: '10px', textAlign: 'right' }}
                    >
                      {selectData && selectData?.parents_id && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              layoutStyle={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                margin: '0 12px 0 0',
                              }}
                              style={{ padding: '10px 11px' }}
                              onClick={() => handleCategory('U')}
                            >
                              {'수정'}
                            </Button>
                            <Button
                              layoutStyle={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                margin: 0,
                              }}
                              style={{ padding: '10px 11px' }}
                              onClick={() => handleCategory('D')}
                            >
                              {'삭제'}
                            </Button>
                          </div>
                        </>
                      )}
                    </Content>
                  </Content>
                ) : (
                  <Content
                    maxWidth={'100%'}
                    padding={24}
                    height={'80vh'}
                    className={'admin_container'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Title>카테고리를 선택해주세요</Title>
                  </Content>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>

      {/* 수강중인 강의 상세 모달 */}
      <ModalLayout
        title={'카테고리 추가'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: 0 }}
        footer={
          screenSize < 768 ? (
            <Content padding={0} maxWidth={'100%'} key={'modalBottomButton'}>
              <Button width={'100%'} onClick={() => handleCategory('C')}>
                추가
              </Button>
            </Content>
          ) : null
        }
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Row
                style={{ display: 'flex', alignItems: 'center', padding: 20 }}
              >
                <Col x={6}>
                  <Title>카테고리명</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    value={modalData?.product_category_name}
                    placeholder="카테고리명을 입력해주세요"
                    onChange={(e) =>
                      handleChange(
                        'modal',
                        'product_category_name',
                        e.target.value
                      )
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col x={24}>
              <Button
                style={{
                  width: '100%',
                  height: '50px',
                  borderRadius: 0,
                  marginTop: '30px',
                }}
                onClick={() => handleCategory('C')}
              >
                추가
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

ProductCategoryPresenter.defaultProps = {};

export default ProductCategoryPresenter;
