import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Content, Row, Col, Title, Button, ModalLayout } from 'components';
import { Select, List, Checkbox, Pagination } from 'antd';
import MessageAlert from 'utils/MessageAlert';
import APIConstant from '../../../api/APIConstant';
import APIManager from '../../../api/APIManager';
import { EmailApi } from 'api';
import DOMPurify from 'dompurify';
const $http = new APIManager();

const EmailLogPresenter = (data) => {
  /* ===== PROPS ===== */
  /* ===== ROUTE ===== */
  const navigatoin = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  /* ===== MUTATE ===== */

  /* ===== STATE ===== */
  const [emailLogList, setEmailLogList] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [emailType, setEmailType] = useState('all');
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const show_count = 20;
  const userInfo = data?.users;
  const agenciesInfo = data?.agency;
  const teachersInfo = data?.teacher;

  /* ===== FUNCTION ===== */
  /**
   * 모달창 데이터 바인딩
   * --
   */
  const handleDetailEmail = (id) => {
    const filteritem = emailLogList?.rows?.filter((item) => {
      return item?.email_data?.email_log_id === id;
    });
    setDetailData(filteritem[0]);
  };

  /**
   * 전체선택
   * --
   */
  const handleCheckAll = (v) => {
    if (v) {
      setCheckedList(emailLogList?.rows);
      setCheckAll(v);
    } else {
      setCheckedList([]);
      setCheckAll(v);
    }
  };
  /**
   * 아이템 선택
   * --
   */
  const handleCheckList = (t, d) => {
    if (t) {
      setCheckedList((prev) => [...prev, d]);
    } else {
      const list = checkedList;
      const filterList = list?.filter(
        (item) => item?.email_data?.email_log_id !== d?.email_data?.email_log_id
      );
      setCheckedList(filterList);
    }
  };

  /**
   * 페이지 네이션
   * ---
   */
  const handleChangePage = (page) => {
    navigatoin(`/admin/email-log?page=${page}`);
  };

  /**
   * 메일 재전송
   * ---
   */
  const handleSendEmail = (id) => {
    if (window.confirm('메일을 재전송 하시겠습니까?')) {
      try {
        if (id) {
          const filterEmailValue = emailLogList?.rows?.filter((item) => {
            return item?.email_data?.email_log_id === id;
          });
          EmailApi.Sendmail({
            toEmail: filterEmailValue[0]?.email_data?.email_log?.toEmail,
            type: filterEmailValue[0]?.email_data?.email_log?.type,
            template_value:
              filterEmailValue[0]?.email_data?.email_log?.email_value,
          });
        } else {
          checkedList?.forEach((item) => {
            EmailApi.Sendmail({
              toEmail: item?.email_data?.email_log?.toEmail,
              type: item?.email_data?.email_log?.type,
              template_value: item?.email_data?.email_log?.email_value,
            });
          });
        }
        MessageAlert.success('메일이 재전송 되었습니다.');
      } catch (e) {
        throw e;
      }
    }
  };

  /* ===== RENDER ===== */
  /**
   * 이메일 로그 데이터 조회
   * ---
   */
  useEffect(() => {
    const queryArray = [];
    if (emailType !== 'all') {
      queryArray.push({
        page: currentPage,
        show_count: show_count,
        type: emailType,
      });
    } else {
      queryArray.push({
        page: currentPage,
        show_count: show_count,
      });
    }

    const getEmailList = async () => {
      try {
        const { status, data } = await $http.get(
          `${APIConstant.GET_EMAIL_LOGS}`,
          queryArray[0]
        );
        if (status === 200) {
          const emailListArr = [];
          data?.rows?.forEach((item) => {
            const user_id = item?.email_log?.email_value?.user_id;
            const agency_id = item?.email_log?.email_value?.agency_id;
            if (user_id) {
              userInfo?.forEach((userInfo) => {
                userInfo?.user_id === user_id &&
                  emailListArr.push({ data: userInfo, email_data: item });
              });
              teachersInfo?.forEach((teachersInfo) => {
                teachersInfo?.user_id === user_id &&
                  emailListArr.push({ data: teachersInfo, email_data: item });
              });
            }
            if (agency_id) {
              agenciesInfo?.forEach((agencyInfo) => {
                agencyInfo?.agency_id === agency_id &&
                  emailListArr.push({ data: agencyInfo, email_data: item });
              });
            }
          });
          emailListArr.sort(
            (a, b) =>
              new Date(b?.email_log?.created_at) -
              new Date(a?.email_log?.created_at)
          );
          console.log(emailListArr);
          setEmailLogList({ count: data?.count, rows: emailListArr });
        } else {
          MessageAlert.warning('데이터를 호출하는데 실패하였습니다!');
        }
      } catch (e) {}
    };

    getEmailList();
  }, [currentPage, emailType, userInfo, teachersInfo, agenciesInfo]); // currentPage가 변경될 때마다 데이터를 새로 가져오도록 설정

  /**
   * 이메일 로그 데이터 조회
   * ---
   */
  useEffect(() => {
    setCurrentPage(1);
  }, [emailType]);

  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Col x={24}>
          <Title size={2} style={{ marginBottom: 20 }}>
            이메일 관리
          </Title>
          <Content
            maxWidth={'100%'}
            padding={'24px'}
            className={'admin_container'}
          >
            <Row>
              <Col x={24} style={{ paddingRight: 10 }}>
                <Title>유형</Title>
                <Col
                  x={8}
                  style={{
                    paddingRight: 10,
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Select
                    onChange={(e) => setEmailType(e)}
                    options={[
                      {
                        label: '전체',
                        value: 'all',
                      },
                      {
                        label: '청구서',
                        value: 'bill',
                      },
                      {
                        label: '계산서',
                        value: 'tax_bill',
                      },
                      {
                        label: '렌탈',
                        value: 'rent',
                      },
                      {
                        label: '정산서',
                        value: 'teacher_bill',
                      },
                      {
                        label: '독려',
                        value: 'encouragement',
                      },
                    ]}
                    value={emailType}
                    style={{ width: '60%' }}
                    size='large'
                  />
                  {checkedList?.length !== 0 && (
                    <Button
                      size='large'
                      style={{
                        width: '100%',
                        margin: '5px 0',
                        background: '#FFFFFF',
                        color: 'rgb(171, 84, 118)',
                      }}
                      onClick={() => handleSendEmail()}
                    >
                      전체 발송
                    </Button>
                  )}
                </Col>
              </Col>
            </Row>
            <Row>
              <Col x={24} style={{ paddingRight: 10, paddingTop: 20 }}>
                <Row>
                  <Col x={2}>
                    {' '}
                    <Checkbox
                      style={{
                        fontSize: '16px',
                        lineHeight: '19px',
                        fontWeight: 400,
                      }}
                      checked={checkAll}
                      onChange={(e) => handleCheckAll(e.target.checked)}
                    >
                      전체
                    </Checkbox>
                  </Col>
                  <Col x={2}>유형</Col>
                  <Col x={3}>발송일</Col>
                  <Col x={3}>수신자</Col>
                  <Col x={4}>수신메일</Col>
                  <Col x={8}>내용 요약</Col>
                  <Col x={2}>재발송</Col>
                </Row>
              </Col>
              <Col x={24} style={{ paddingRight: 10, paddingTop: 20 }}>
                <List
                  size='large'
                  footer={
                    <Row
                      key={`footer`}
                      style={{
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col x={12}>
                        총 : {emailLogList?.count}개 / {currentPage}페이지
                      </Col>
                      <Col x={12} style={{ textAlign: 'end' }}>
                        <Pagination
                          current={currentPage}
                          onChange={handleChangePage}
                          total={emailLogList?.count}
                          pageSize={show_count}
                        />
                      </Col>
                    </Row>
                  }
                  bordered
                  position='true'
                  dataSource={emailLogList?.rows}
                  renderItem={(item, idx) => (
                    <div
                      key={`${idx}_` + item?.email_data?.email_log_id}
                      style={{ padding: 5 }}
                    >
                      <Row style={{ alignItems: 'center' }}>
                        <Col x={2}>
                          {' '}
                          <Checkbox
                            style={{
                              fontSize: '16px',
                              lineHeight: '19px',
                              fontWeight: 400,
                            }}
                            checked={checkedList?.includes(item) ? true : false}
                            onChange={(e) =>
                              handleCheckList(e.target.checked, item)
                            }
                          />
                        </Col>
                        <Col
                          x={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleDetailEmail(item?.email_data?.email_log_id);
                            setIsOpened(!isOpened);
                          }}
                        >
                          <Row>
                            <Col x={2}>
                              {item?.email_data?.type === 'tax_bill'
                                ? '계산서'
                                : item?.email_data?.type === 'bill'
                                ? '청구서'
                                : item?.email_data?.type === 'rent'
                                ? '렌탈'
                                : item?.email_data?.type === 'teacher_bill'
                                ? '정산서'
                                : '독려'}
                            </Col>
                            <Col x={3}>
                              {item?.email_data?.email_log?.created_at?.slice(
                                0,
                                10
                              )}
                            </Col>
                            <Col x={3}>{item?.data?.name}</Col>
                            <Col x={4}>
                              {item?.email_data?.email_log?.toEmail}
                            </Col>
                            <Col x={8}>
                              {item?.email_data?.email_log?.subject}
                            </Col>
                          </Row>
                        </Col>
                        <Col x={2}>
                          {' '}
                          <Button
                            size='large'
                            style={{
                              width: '80%',
                              margin: '5px 0',
                              background: '#FFFFFF',
                              color: 'rgb(171, 84, 118)',
                            }}
                            onClick={() =>
                              handleSendEmail(item?.email_data.email_log_id)
                            }
                          >
                            발송
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
                />
              </Col>
            </Row>
          </Content>
        </Col>
      </Content>
      {/* 이메일 로그 상세 */}
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'right'}
        width={'40%'}
        height={'100%'}
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'}>
          <Row>
            <Col x={24}>
              <Title>상세보기</Title>
            </Col>
            <Content
              maxWidth={'100%'}
              style={{
                borderTop: '1px solid #E0E0E0',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10px',
              }}
            >
              <Row
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  textAlign: 'start',
                }}
              >
                <Col x={8}>
                  <p>수신자</p>
                  <p>{detailData?.data?.name}</p>
                </Col>
                <Col x={8}>
                  <p>연락처</p>
                  <p>{detailData?.data?.phone}</p>
                </Col>
                <Col x={8}>
                  <p>수신 메일</p>
                  <p>{detailData?.email_data?.email_log?.toEmail}</p>
                </Col>
              </Row>
              <Row
                style={{
                  margin: '20px 0',
                  display: 'flex',
                  flexDirection: 'row',
                  textAlign: 'start',
                }}
              >
                <Col x={8}>
                  <p>발송일</p>
                  <p>
                    {detailData?.email_data?.email_log?.created_at.slice(0, 10)}
                  </p>
                </Col>
                <Col x={8}>
                  <p>발송유형</p>
                  <p>
                    {detailData?.email_data?.type === 'tax_bill'
                      ? '계산서'
                      : detailData?.email_data?.type === 'bill'
                      ? '청구서'
                      : detailData?.email_data?.type === 'rent'
                      ? '렌탈'
                      : detailData?.email_data?.type === 'teacher_bill'
                      ? '정산서'
                      : '독려'}
                  </p>
                </Col>
                <Col x={8}>
                  <p>재발송</p>
                  <Button
                    size='large'
                    style={{
                      width: '80%',
                      margin: '5px 0',
                      background: '#FFFFFF',
                      color: 'rgb(171, 84, 118)',
                    }}
                    onClick={() =>
                      handleSendEmail(detailData?.email_data?.email_log_id)
                    }
                  >
                    메일발송
                  </Button>
                </Col>
              </Row>
            </Content>
            <Content
              maxWidth={'100%'}
              style={{
                // margin: '20px 0',
                borderTop: '1px solid #E0E0E0',
                borderBotton: '1px solid #E0E0E0',
              }}
            >
              {' '}
              <Row>
                <Col x={24}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        detailData?.email_data?.email_log?.html
                      ),
                    }}
                  ></div>
                </Col>
              </Row>
            </Content>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

export default EmailLogPresenter;
