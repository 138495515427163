import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Title, Category, EventCard } from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Card } from 'antd';
import { encrypt } from 'utils/Crypto';
import { useQueryClient } from 'react-query';
import { getFirstImageFromContent, getCookie } from 'utils';

const styles = {
  contentCardButton: {
    border: 'none',
    padding: 0,
    borderRadius: 0,
    background: 'none',
  },
};

const EventList = () => {
  /* ===== Props ===== */

  /* ===== Router ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  /* ===== State ===== */
  const queryClient = useQueryClient();
  const [newEvents, setNewEvents] = useState([]);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const categoryItems = [
    {
      label: '진행중',
      value: 'ing',
      onClick: () => navigate('/events?status=ing'),
    },
    {
      label: '종료',
      value: 'end',
      onClick: () => navigate('/events?status=end'),
    },
    {
      label: '진행예정',
      value: 'soon',
      onClick: () => navigate('/events?status=soon'),
    },
  ];
  const [category, setCategory] = useState(categoryItems[0]?.value);
  const events = queryClient.getQueryData('getEvents')?.data;

  /* ===== Hooks ===== */
  useEffect(() => {
    if (events) {
      const e = events
        ?.map((item) => {
          const today = new Date();
          const status =
            new Date(item?.end_date) < today
              ? 'end'
              : new Date(item?.start_date) > today
              ? 'soon'
              : 'ing';

          return {
            ...item,
            thumbnail: getFirstImageFromContent(item?.event_content),
            status: status,
          };
        })
        ?.filter((item) => item?.is_show === 'Y');

      const newE = e
        ?.filter((item) => item?.status === status)
        ?.slice()
        .sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

      setNewEvents(newE);
      setCategory(status);
    }
  }, [events, status]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== Functions ===== */
  const handleNavigate = (id) => {
    if (getCookie('BRAINHEAL_ACCESS_TOKEN')) {
      navigate(`/events/${encodeURIComponent(encrypt(id))}`);
    } else {
      const currentPath = window.location.hash.split('#');
      window.alert('로그인 후 이용해주세요');
      navigate(`/login`, { state: { prev: `${currentPath[1]}` } });
    }
  };

  /* ===== Render ===== */
  return (
    <Content padding={0} maxWidth={'100%'} style={{ margin: '48px 0 84px' }}>
      <Content padding={screenSize >= 1200 ? 0 : '0 30px'} maxWidth={1200}>
        <Row>
          <Col x={24} padding={8}>
            <Title
              align={'center'}
              style={{
                fontSize: '35px',
                fontWeight: 700,
                lineHeight: '42px',
                letterSpacing: '0.02em',
                marginBottom: '37px',
              }}
            >
              이벤트
            </Title>
          </Col>
          <Col x={24}>
            <Category
              items={categoryItems}
              value={category}
              setValue={setCategory}
              style={{
                margin: '29px auto 23px',
                display: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              more={false}
            />
          </Col>
          <Col x={24}>
            <Row>
              {newEvents && newEvents?.length > 0 ? (
                newEvents?.map((_item, _index) => (
                  <Col x={24} xl={6} lg={8} md={12} key={`event-${_index}`}>
                    <Card
                      hoverable
                      style={styles.contentCardButton}
                      bodyStyle={{ padding: 10 }}
                      onClick={() => handleNavigate(_item?.event_id)}
                    >
                      <EventCard
                        title={_item?.event_title}
                        thumbnail={_item?.thumbnail && _item?.thumbnail}
                        status={_item?.status}
                        start={_item?.start_date}
                        end={_item?.end_date}
                      />
                    </Card>
                  </Col>
                ))
              ) : (
                <Col x={24}>
                  <Content
                    maxWidth={'100%'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px',
                    }}
                  >
                    <Title>
                      {status === 'ing'
                        ? '진행중인 이벤트가 없습니다'
                        : status === 'end'
                        ? '종료된 이벤트가 없습니다'
                        : '진행예정인 이벤트가 없습니다'}
                    </Title>
                  </Content>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

EventList.defaultProps = {};

export default EventList;
