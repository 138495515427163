import React, { useState, useEffect } from 'react';
import MyInfoPresenter from './MyInfoPresenter';
import { useQueryClient } from 'react-query';
import { LectureApi } from 'api';

const MyInfoContainer = ({ data }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const queryClient = useQueryClient();
  const [newLectures, setNewLectures] = useState([]);
  const recommend = LectureApi.GetRecommendLectures(null, {
    cacheTime: 0,
  })?.data;
  const lectureFiles = queryClient.getQueryData('getLectureFiles')?.data;

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    if (recommend?.status === 200 && lectureFiles) {
      const l = recommend?.data?.map((item) => ({
        ...item,
        thumbnail: lectureFiles?.filter(
          (subitem) =>
            item?.lecture_id === subitem?.lecture_id && subitem?.type === 'T'
        )[0]?.file_url,
      }));

      setNewLectures(l);
    }
  }, [recommend, lectureFiles]);
  /* ===== RENDER ===== */
  return <MyInfoPresenter data={data} lectures={newLectures} />;
};

MyInfoContainer.defaultProps = {};

export default MyInfoContainer;
