import React from 'react';
import { Checkbox } from 'antd';
import './BoardTable.style.css';
import { useNavigate } from 'react-router-dom';
import { encrypt } from 'utils/Crypto';

const BoardTable = ({
  header,
  data,
  auth,
  checkAll,
  checkList,
  onCheckAllChange,
  onCheckChange,
}) => {
  /* ===== ROUTE ===== */
  const navigation = useNavigate();

  return (
    <table className="board_table_container">
      <colgroup>
        {auth === 'WRITER' ? <col width={`5%`} /> : null}
        {header?.map((item, index) => (
          <col
            key={`col-${index}`}
            width={`${
              auth === 'WRITER' && item?.key === 'title'
                ? item?.width - 5
                : item?.width
            }%`}
          />
        ))}
      </colgroup>
      <thead className="board_thead">
        <tr>
          {auth === 'WRITER' ? (
            <td>
              <Checkbox
                checked={checkAll}
                onChange={(e) => onCheckAllChange(e.target.checked)}
              />
            </td>
          ) : null}
          {header?.map(
            (item) =>
              item && (
                <td key={item?.key} className={`board_col_${item?.key}`}>
                  <p>{item?.title}</p>
                </td>
              )
          )}
        </tr>
      </thead>
      <tbody className="board_tbody">
        {data?.length ? (
          data?.map((item, index) => (
            <tr className="board_tbody_row" key={`board_${index}`}>
              {auth === 'WRITER' ? (
                <td>
                  <Checkbox
                    checked={checkList?.includes(item?.board_id)}
                    onChange={() => onCheckChange(item?.board_id)}
                  />
                </td>
              ) : null}
              {header?.map(
                (subitem) =>
                  subitem && (
                    <td
                      className={`board_col_${subitem?.key}`}
                      key={`board_${subitem?.key}_${index}`}
                      onClick={(e) => {
                        e.preventDefault();
                        navigation(
                          `/popup/board/detail/${encodeURIComponent(
                            encrypt(item?.board_id)
                          )}?lecture=${encodeURIComponent(
                            encrypt(item?.lecture_id)
                          )}&type=${item?.type?.toLocaleLowerCase()}`
                        );
                      }}
                    >
                      <p>
                        {subitem?.render
                          ? subitem?.render(item, auth)
                          : item[subitem?.key]}
                      </p>
                    </td>
                  )
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td
              className="board_empty"
              colSpan={auth === 'WRITER' ? header?.length + 1 : header?.length}
            >
              <div>
                <p>등록된 게시글이 없습니다</p>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default BoardTable;
