import React, { useState, useEffect } from 'react';
import LectureListPresenter from './LectureListPresenter';

const LectureListContainer = ({ data, screenSize }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  const [newData, setNewData] = useState([]);

  // console.log('Data', Data);
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    if (data) {
      const { lectures, exams, answers } = data;

      const newExams = exams?.map((item) => ({
        ...item,
        answers: answers?.filter(
          (subitem) => item?.exam_id === subitem?.exam_id
        ),
      }));

      const newLectures = lectures?.map((item) => ({
        ...item,
        exams: newExams?.filter(
          (subitem) => item.lecture_id === subitem.lecture_id
        ),
      }));

      setNewData(newLectures);
    }
  }, [data]);
  /* ===== RENDER ===== */
  return <LectureListPresenter lectures={newData} screenSize={screenSize} />;
};

LectureListContainer.defaultProps = {};

export default LectureListContainer;
