import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 상품 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetProducts: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getProducts', params] : 'getProducts',
        async () => await $http.get(APIConstant.GET_PRODUCTS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetProduct: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_PRODUCT, {
        product_id: idx,
      });
      const res = useQuery(
        params ? ['getProduct', params] : 'getProduct',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 추천 상품 조회
   * --
   * @method GET
   * @returns
   */
  GetRecommendProducts: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getRecommendProducts', params] : 'getRecommendProducts',
        async () => await $http.get(APIConstant.GET_RECOMMEND_PRODUCTS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 생성
   * --
   * @method POST
   * @returns
   */
  CreateProduct: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_PRODUCT, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getProducts');
            queryClient.invalidateQueries('getProduct');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateProduct: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_PRODUCT, {
            product_id: data.product_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getProducts');
            queryClient.invalidateQueries('getProduct');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteProduct: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_PRODUCT, {
            product_id: data.product_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getProducts');
            queryClient.invalidateQueries('getProduct');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
