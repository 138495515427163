import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath, setCookie, removeCookie } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 기관 로그인
   * --
   * @method POST
   * @returns
   */
  LoginAgency: async (data) => {
    try {
      let status;
      await $http.post(APIConstant.AGENCY_LOGIN, data).then((res) => {
        if (res.status !== 200) {
          status = 500;
        } else {
          const time = 8 * 60 * 60 * 1000;
          removeCookie('BRAINHEAL_ACCESS_TOKEN', res.data.token, time);
          setCookie('USER', res.data.agency_id);
          status = 200;
        }
      });

      return status;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 기관 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetAgencies: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getAgencies', params] : 'getAgencies',
        async () => await $http.get(APIConstant.GET_AGENCIES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 기관 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetAgency: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_AGENCY, { agency_id: idx });
      const res = useQuery(
        params ? ['getAgency', params] : 'getAgency',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 기관 생성
   * --
   * @method POST
   * @returns
   */
  CreateAgency: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_AGENCY, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAgencies');
            queryClient.invalidateQueries('getAgency');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 기관 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateAgency: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_AGENCY, {
            agency_id: data.agency_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAgencies');
            queryClient.invalidateQueries('getAgency');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 기관 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteAgency: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_AGENCY, {
            agency_id: data.agency_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAgencies');
            queryClient.invalidateQueries('getAgency');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 기관 패스워드 수정
   * --
   * @method PUT
   * @returns
   */
  //FIXME - 01/26일 수정완료
  UpdateAgencyPassword: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_AGENCY_PASSWORD, {
            agency_id: data.agency_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getAgencies');
            queryClient.invalidateQueries('getAgency');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
