import React from 'react';
import { Content, Row, Col } from 'components';

const DiaryDetail = () => {
  /* ===== Props ===== */

  /* ===== Router ===== */

  /* ===== State ===== */

  /* ===== Hooks ===== */

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <Content padding={0} maxWidth={'100%'} style={{ margin: '48px 0 84px' }}>
      <Content padding={0} maxWidth={1200}>
        <Row>
          <Col x={24} padding={8}>
            치매예방다이어리 상세보기
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

DiaryDetail.defaultProps = {};

export default DiaryDetail;
