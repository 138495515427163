import React from 'react';
import BannerPresenter from './BannerPresenter';
import { BannerApi } from 'api';
import MessageAlert from 'utils/MessageAlert';

const BannerContainer = ({ data }) => {
  /* ===== Route ===== */
  /* ===== Mutate ===== */
  const deleteBanner = BannerApi.DeleteBanner();

  /* ===== Props ===== */
  const { banner } = data;

  /* ===== State ===== */
  /* ===== Function ===== */
  /**
   * 배너 삭제
   * --
   */
  const handleDeleteBanner = (id) => {
    if (!banner?.length) {
      MessageAlert.warning('배너는 하나 이상 등록되어있어야 합니다!');
      return;
    }

    const confirm = window.confirm(
      `배너를 정말 삭제하시겠습니까?\n삭제 후 복구할 수 없습니다.`
    );

    if (confirm) {
      try {
        deleteBanner.mutate({
          banner_id: id,
        });
        MessageAlert.success('배너가 성공적으로 삭제되었습니다');
      } catch (err) {
        MessageAlert.error('배너를 삭제하는데 오류가 발생하였습니다!');
        console.log('[BANNER DELETE ERROR] ', err);
        throw err;
      }
    }
  };

  /**
   * 링크 열기
   * --
   */
  const handleOpenLink = (link) => {
    let newLink = link;
    if (!link.includes('http')) {
      newLink = `https://${link}`;
    }

    window.open(newLink);
  };

  /* ===== Hooks ===== */
  /* ===== Render ===== */
  return (
    <>
      <BannerPresenter
        banner={banner}
        onDeleteBanner={handleDeleteBanner}
        onOpenLink={handleOpenLink}
      />
    </>
  );
};

export default BannerContainer;
