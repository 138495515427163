import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import { Content } from 'components';
import { useLocation } from 'react-router-dom';

const LayoutComp = ({
  headerExceptPath,
  footerExceptPath,
  children,
  subPath,
  ...props
}) => {
  /* ===== ROUTER ===== */
  const location = useLocation();
  const { pathname } = location;

  /* ===== STATE ===== */
  const [noneHeader, setNoneHeader] = useState(false);
  const [noneFooter, setNoneFooter] = useState(false);

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    const call = (path) => {
      const splitPath = path?.split('/')[1];

      // 헤더 제외 루트 체크
      if (headerExceptPath.includes(splitPath)) {
        setNoneHeader(true);
      } else {
        setNoneHeader(false);
      }

      // 푸터 제외 루트 체크
      if (footerExceptPath.includes(splitPath)) {
        setNoneFooter(true);
      } else {
        setNoneFooter(false);
      }
    };

    call(pathname);
  }, [pathname, headerExceptPath, footerExceptPath]);
  // console.log('noneHeader', noneHeader);
  // console.log('noneFooter', noneFooter);

  /* ===== RENDER ===== */
  return (
    <Layout>
      {noneHeader ? null : <Navbar subPath={subPath} {...props} />}
      <Content maxWidth={'100%'} padding={0}>
        {children}
      </Content>
      {noneFooter ? null : <Footer {...props} />}
    </Layout>
  );
};

LayoutComp.defaultProps = {};

export default LayoutComp;
