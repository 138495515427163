import React, { useState, useEffect } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  Statistic,
  Button,
  ModalLayout,
} from 'components';
import { Button as Btn, Collapse, Empty } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { stringToMoneyFormat } from 'utils';
import moment from 'moment';
import DOMPurify from 'dompurify';
import './Mycalculate.style.css';

const MyCalculatePresenter = (props) => {
  /* ===== ROUTE ===== */
  /* ===== PROPS ===== */
  const {
    year,
    month,
    onDate,
    calculates,
    loginWho,
    schedules,
    onCalculateTime,
    onPDFDownload,
    htmlTemplate,
    onHtmlTemplate,
    totalCalculate,
    screenSize,
  } = props;

  /* ===== MUATE ===== */
  /* ===== STATE ===== */
  const [calInfoData, setCalInfoData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isOpened, setIsOpened] = useState(false);
  const [modalData, setModalData] = useState({});

  /* ===== FUNCTION ===== */
  /**
   * 모달 관리
   * --
   */
  const handleModal = (t, d) => {
    onHtmlTemplate(t, d);
    setModalData({
      type: t,
      data: d,
    });
    setIsOpened(true);
  };

  /* ===== HOOKS ===== */
  /**
   * 정산 데이터 정제
   * --
   */
  useEffect(() => {
    // 총 정산/청구금액 계산
    let total = 0;
    if (loginWho === 'agency') {
      if (totalCalculate) {
        total += totalCalculate?.total_price;
      }
    } else {
      calculates?.forEach((item) => {
        const { calculate } = item;

        if (calculate) {
          total += calculate?.work_hour * calculate?.time_price;
        } else {
          return;
        }
      });
    }
    setTotalPrice(total);
  }, [calculates, loginWho, totalCalculate]);

  /**
   * 스케쥴 현황 데이터 정제
   * --
   */
  useEffect(() => {
    // 정보 데이터 정제
    let complete = 0,
      examine = 0;
    schedules?.forEach((item) => {
      if (item.user_check && item.agency_check) {
        complete++;
      } else {
        examine++;
      }
    });

    const p = [
      {
        title: '진행강의',
        value: schedules?.length,
      },
      {
        title: '완료',
        value: complete,
      },
      {
        title: '검토중',
        value: examine,
      },
    ];
    setCalInfoData(p);
  }, [schedules]);

  /* ===== STYLES ===== */
  const styles = {
    modalTxt: {
      color: '#757575',
      fontSize: screenSize >= 768 ? 16 : 14,
    },
  };

  /* ===== RENDER ===== */
  return (
    <>
      <Content maxWidth={'100%'} padding={'0 12px'}>
        {/* 타이틀 */}
        <Row>
          <Col x={24} padding={8}>
            <Content
              maxWidth={'100%'}
              padding={0}
              style={{
                display: 'flex',
                alignItems: screenSize >= 768 ? 'center' : 'flex-start',
                justifyContent: 'flex-start',
                marginBottom: screenSize >= 768 ? 28 : 10,
                flexDirection: screenSize >= 768 ? 'row' : 'column',
              }}
            >
              <Title
                size={screenSize >= 768 ? 2 : 4}
                style={{ marginRight: 15 }}
              >
                정산내역
              </Title>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: screenSize >= 768 ? 0 : 10,
                }}
              >
                <Btn
                  size="large"
                  icon={<LeftOutlined />}
                  onClick={() => onDate('prev')}
                />
                <Title
                  size={screenSize >= 768 ? 4 : 5}
                  style={{ padding: '0 10px' }}
                >
                  {year}년 {month}월
                </Title>
                <Btn
                  size="large"
                  icon={<RightOutlined />}
                  onClick={() => onDate('next')}
                />
              </div>
            </Content>
          </Col>
        </Row>
        <Row>
          {/* 강의 진행현황 */}
          <Col x={24} padding={8}>
            <Content
              maxWidth={'100%'}
              padding={screenSize >= 768 ? 24 : 10}
              style={{ border: '1px solid #9E9E9E', borderRadius: 4 }}
            >
              <Title>강의 진행현항</Title>
              <Content
                maxWidth={'100%'}
                padding={0}
                style={{
                  display: 'flex',
                  justifyContent:
                    screenSize >= 768 ? 'center' : 'space-between',
                  alignItems: 'center',
                  marginTop: screenSize < 768 && 10,
                }}
              >
                {calInfoData?.map((item, index) => (
                  <div key={`item-${item?.title}-${index}`}>
                    <Statistic
                      title={item?.title}
                      value={item?.value}
                      suffix={'건'}
                      layoutStyle={
                        screenSize >= 768
                          ? {
                              border: '1px solid #9E9E9E',
                            }
                          : {
                              border: '1px solid #9E9E9E',
                              width: 80,
                              margin: 0,
                              padding: 10,
                            }
                      }
                      titleStyle={screenSize >= 768 ? {} : { fontSize: 14 }}
                      valueStyle={screenSize >= 768 ? {} : { fontSize: 16 }}
                    />
                  </div>
                ))}
              </Content>
            </Content>
          </Col>
          {/* 정산내역 */}
          <Col x={24} padding={8}>
            <Content
              maxWidth={'100%'}
              padding={screenSize >= 768 ? 24 : 10}
              style={{ border: '1px solid #9E9E9E', borderRadius: 4 }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Title size={screenSize >= 768 ? 4 : 5}>
                  {loginWho === 'agency' ? '청구' : '정산'}현항
                </Title>
                <Title size={screenSize >= 768 ? 4 : 5} color={'#AB5476'}>
                  총 {loginWho === 'agency' ? '청구' : '정산'} 금액{' '}
                  <span style={{ color: '#000', marginLeft: 10 }}>
                    {stringToMoneyFormat(totalPrice, '원')}
                  </span>
                </Title>
              </div>
              <div
                style={{
                  marginTop: screenSize >= 768 ? 30 : 20,
                  maxHeight: 800,
                  overflowY: 'scroll',
                  padding: screenSize >= 768 ? 10 : 0,
                  border: '1px solid #E0E0E0',
                }}
              >
                {calculates?.length ? (
                  calculates?.map((item, index) => (
                    <React.Fragment key={`calculate-${index}`}>
                      <Collapse expandIconPosition="end">
                        <Collapse.Panel
                          header={
                            <div style={{ padding: 10 }}>
                              <h4
                                style={{
                                  fontSize: screenSize >= 768 ? 18 : 16,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: screenSize >= 768 ? 14 : 12,
                                    padding: '3px 5px',
                                    background: '#E3A4AF',
                                    borderRadius: 4,
                                    color: '#FFFFFF',
                                    marginRight: 10,
                                  }}
                                >
                                  {item?.parents_name}
                                </span>
                                {loginWho === 'agency'
                                  ? `${item?.category_name} - ${item?.user_name} (${item?.schedules?.length})`
                                  : `${item?.category_name} (${item?.schedules?.length})`}

                                <span
                                  style={{
                                    fontSize: screenSize >= 768 ? 14 : 12,
                                    fontWeight: 500,
                                    marginLeft: 10,
                                    color: item?.calculate
                                      ? '#2AC769'
                                      : '#FB4E4E',
                                  }}
                                >
                                  {item?.calculate ? '정산완료' : '미정산'}
                                </span>
                              </h4>
                              {loginWho === 'agency' ? null : (
                                <p
                                  style={{
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    fontWeight: 700,
                                  }}
                                >
                                  {item?.agency_name}{' '}
                                  {screenSize < 768 && <br />}
                                  <span
                                    style={{
                                      fontSize: 14,
                                      color: '#BDBDBD',
                                      fontWeight: 400,
                                    }}
                                  >
                                    {item?.agency_address}
                                  </span>
                                </p>
                              )}
                            </div>
                          }
                        >
                          <Row padding={10}>
                            <Col x={24}>
                              <Row>
                                <Col x={6}>
                                  <Title
                                    size={5}
                                    style={{
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                    }}
                                  >
                                    진행일
                                  </Title>
                                </Col>
                                <Col x={6}>
                                  <Title
                                    size={5}
                                    style={{
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                    }}
                                  >
                                    진행시간
                                  </Title>
                                </Col>
                                <Col x={6}>
                                  <Title
                                    size={5}
                                    style={{
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                    }}
                                  >
                                    {screenSize >= 768
                                      ? '강사 확인여부'
                                      : '강사 확인'}
                                  </Title>
                                </Col>
                                <Col x={6}>
                                  <Title
                                    size={5}
                                    style={{
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                    }}
                                  >
                                    {screenSize >= 768
                                      ? '기관 확인여부'
                                      : '기관 확인'}
                                  </Title>
                                </Col>
                              </Row>
                              {item?.schedules?.map((subitem, subindex) => (
                                <React.Fragment key={`cal-subitem-${subindex}`}>
                                  <Row x={24}>
                                    <Col x={6}>
                                      <p style={{ ...styles.modalTxt }}>
                                        {moment(subitem?.start_date)?.format(
                                          screenSize >= 768
                                            ? 'YYYY.MM.DD'
                                            : 'YY.M.DD'
                                        )}
                                      </p>
                                    </Col>
                                    <Col x={6}>
                                      <p style={{ ...styles.modalTxt }}>
                                        {onCalculateTime(
                                          subitem?.start_date,
                                          subitem?.end_date
                                        )}
                                        h
                                      </p>
                                    </Col>
                                    <Col x={6}>
                                      <p style={{ ...styles.modalTxt }}>
                                        {subitem?.user_check ? 'Y' : 'N'}
                                      </p>
                                    </Col>
                                    <Col x={6}>
                                      <p style={{ ...styles.modalTxt }}>
                                        {subitem?.agency_check ? 'Y' : 'N'}
                                      </p>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              ))}
                              <Row style={{ marginTop: 10 }}>
                                <Col x={8}>
                                  <Statistic
                                    title={'정산시간'}
                                    value={
                                      item?.calculate?.work_hour
                                        ? item?.calculate?.work_hour
                                        : '-'
                                    }
                                    suffix={item?.calculate?.work_hour && 'h'}
                                    layoutStyle={{
                                      border: 'none',
                                      padding: 0,
                                      margin: '0 auto',
                                      width: screenSize >= 768 ? 120 : 'auto',
                                    }}
                                    titleStyle={{ fontSize: 14 }}
                                    valueStyle={{
                                      fontSize: screenSize >= 768 ? 20 : 16,
                                    }}
                                    suffixStyle={{
                                      fontSize: screenSize >= 768 ? 20 : 16,
                                    }}
                                  />
                                </Col>
                                <Col x={8}>
                                  <Statistic
                                    title={'정산시급'}
                                    value={stringToMoneyFormat(
                                      item?.calculate?.time_price
                                    )}
                                    suffix={'원'}
                                    layoutStyle={{
                                      border: 'none',
                                      padding: 0,
                                      margin: '0 auto',
                                      width: screenSize >= 768 ? 120 : 'auto',
                                    }}
                                    titleStyle={{ fontSize: 14 }}
                                    valueStyle={{
                                      fontSize: screenSize >= 768 ? 20 : 16,
                                    }}
                                    suffixStyle={{
                                      fontSize: screenSize >= 768 ? 20 : 16,
                                    }}
                                  />
                                </Col>
                                <Col x={8}>
                                  <Statistic
                                    title={'정산금액'}
                                    value={stringToMoneyFormat(
                                      item?.calculate?.time_price *
                                        item?.calculate?.work_hour
                                    )}
                                    suffix={'원'}
                                    layoutStyle={{
                                      border: 'none',
                                      padding: 0,
                                      margin: '0 auto',
                                      width: screenSize >= 768 ? 120 : 'auto',
                                    }}
                                    titleStyle={{ fontSize: 14 }}
                                    valueStyle={{
                                      fontSize: screenSize >= 768 ? 20 : 16,
                                    }}
                                    suffixStyle={{
                                      fontSize: screenSize >= 768 ? 20 : 16,
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    </React.Fragment>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </div>
              {loginWho === 'agency' ? (
                <Row style={{ marginTop: 30 }}>
                  <Col x={24} md={12}>
                    <Button
                      style={{
                        width: '80%',
                        margin: '0 auto',
                      }}
                      disabled={totalCalculate ? false : true}
                      onClick={() => handleModal('bill', totalCalculate?.bill)}
                    >
                      청구서 PDF 다운로드
                    </Button>
                  </Col>
                  <Col x={24} md={12}>
                    <Button
                      color="secondary"
                      type="secondary"
                      style={{
                        width: '80%',
                        margin: '0 auto',
                        marginTop: screenSize >= 768 ? 0 : 10,
                      }}
                      disabled={totalCalculate?.bill ? false : true}
                      onClick={() =>
                        handleModal('tax_bill', totalCalculate?.tax_bill)
                      }
                    >
                      계산서 PDF 다운로드
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row style={{ marginTop: 30 }}>
                  <Col x={24}>
                    <Button
                      color="secondary"
                      type="secondary"
                      style={{
                        width: screenSize >= 768 ? '50%' : '80%',
                        margin: '0 auto',
                      }}
                      disabled={totalCalculate ? false : true}
                      onClick={() =>
                        handleModal(
                          'teacher_bill',
                          totalCalculate?.teacher_bill
                        )
                      }
                    >
                      정산서 PDF 다운로드
                    </Button>
                  </Col>
                </Row>
              )}
            </Content>
          </Col>
        </Row>
      </Content>

      <ModalLayout
        open={isOpened}
        onCancel={setIsOpened}
        closable
        bodyStyle={{
          padding: screenSize >= 768 ? 24 : 10,
        }}
      >
        <Content maxWidth={'100%'} backgroundColor={'none'} padding={0}>
          <Row>
            <Col x={24}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(htmlTemplate),
                }}
              ></div>
            </Col>
          </Row>
          <Row>
            <Col x={24}>
              <Button
                style={{ margin: '0 auto', width: '50%', height: 54 }}
                onClick={() => onPDFDownload(modalData.type, modalData.data)}
              >
                다운로드
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

MyCalculatePresenter.defaultProps = {};

export default MyCalculatePresenter;
