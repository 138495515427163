import { useQuery } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';
const $http = new APIManager();

const Api = {
  /**
   * 이메일 전송
   * --
   * @method POST
   * @returns
   */
  Sendmail: async (data) => {
    try {
      const res = await $http.post(APIConstant.SEND_EMAIL, data);

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * SMS 전송
   * --
   */
  Sendsms: async (data) => {
    try {
      const res = await $http.post(APIConstant.SEND_SMS, data);

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 이메일 로그 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetEmailLogs: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getEmailLogs', params] : 'getEmailLogs',
        async () => await $http.get(APIConstant.GET_EMAIL_LOGS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 이메일 로그 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetEmailLog: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_EMAIL_LOG, {
        email_log_id: idx,
      });
      const res = useQuery(
        params ? ['getEmailLog', params] : 'getEmailLog',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
  /**
   * SMS 남은 횟수 조회
   * --
   * @method GET
   * @returns
   */
  RemainSms: async (data) => {
    try {
      const res = await $http.post(APIConstant.REMAIN_SMS, data);
      return res;
    } catch (e) {
      throw e;
    }
  },
  /**
   * SMS 전송 리스트 조회
   * --
   * @method GET
   * @returns
   */
  ListSms: async (data) => {
    try {
      const res = await $http.post(APIConstant.LIST_SMS, data);
      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
