import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 파일 업로드
   * --
   * @method POST
   * @returns
   */
  FileUpload: async (options = {}) => {
    try {
      const res = useMutation(
        async (data) => await $http.post(APIConstant.FILE_UPLOAD, data),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 파일 Blob 형태 변환
   * --
   * @method POST
   * @returns
   */
  FileBlob: async (options = {}) => {
    try {
      const res = useMutation(
        async (data) => await $http.post(APIConstant.FILE_BLOB, data),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 파일 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetTeacherFiles: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getTeacherFiles', params] : 'getTeacherFiles',
        async () => await $http.get(APIConstant.GET_TEACHER_FILES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 파일 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetTeacherFile: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_TEACHER_FILE, {
        teacher_file: idx,
      });
      const res = useQuery(
        params ? ['getTeacherFile', params] : 'getTeacherFile',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 파일 생성
   * --
   * @method POST
   * @returns
   */
  CreateTeacherFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_TEACHER_FILE,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getTeacherFiles');
            queryClient.invalidateQueries('getTeacherFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 파일 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateTeacherFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_TEACHER_FILE, {
            teacher_file_id: data.teacher_file_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getTeacherFiles');
            queryClient.invalidateQueries('getTeacherFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 파일 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteTeacherFile: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_TEACHER_FILE, {
            teacher_file_id: data.teacher_file_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getTeacherFiles');
            queryClient.invalidateQueries('getTeacherFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케쥴 파일 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetScheduleFiles: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getScheduleFiles', params] : 'getScheduleFiles',
        async () => await $http.get(APIConstant.GET_SCHEDULE_FILES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케쥴 파일 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetScheduleFile: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_SCHEDULE_FILE, {
        schedule_file_id: idx,
      });
      const res = useQuery(
        params ? ['getScheduleFile', params] : 'getScheduleFile',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케쥴 파일 생성
   * --
   * @method POST
   * @returns
   */
  CreateScheduleFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_SCHEDULE_FILE,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getScheduleFiles');
            queryClient.invalidateQueries('getScheduleFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케쥴 파일 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateScheduleFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_SCHEDULE_FILE, {
            schedule_file_id: data.schedule_file_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getScheduleFiles');
            queryClient.invalidateQueries('getScheduleFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 스케쥴 파일 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteScheduleFile: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_SCHEDULE_FILE, {
            schedule_file_id: data.schedule_file_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getScheduleFiles');
            queryClient.invalidateQueries('getScheduleFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 파일 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetLectureFiles: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getLectureFiles', params] : 'getLectureFiles',
        async () => await $http.get(APIConstant.GET_LECTURE_FILES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 파일 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetLectureFile: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_LECTURE_FILE, {
        lecture_file_id: idx,
      });
      const res = useQuery(
        params ? ['getLectureFile', params] : 'getLectureFile',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 파일 생성
   * --
   * @method POST
   * @returns
   */
  CreateLectureFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_LECTURE_FILE,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getLectureFiles');
            queryClient.invalidateQueries('getLectureFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 파일 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateLectureFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_LECTURE_FILE, {
            lecture_file_id: data.lecture_file_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getLectureFiles');
            queryClient.invalidateQueries('getLectureFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 파일 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteLectureFile: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_LECTURE_FILE, {
            lecture_file_id: data.lecture_file_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getLectureFiles');
            queryClient.invalidateQueries('getLectureFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 파일 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetProductFiles: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getProductFiles', params] : 'getProductFiles',
        async () => await $http.get(APIConstant.GET_PRODUCT_FILES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 파일 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetProductFile: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_PRODUCT_FILE, {
        product_file_id: idx,
      });
      const res = useQuery(
        params ? ['getProductFile', params] : 'getProductFile',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 파일 생성
   * --
   * @method POST
   * @returns
   */
  CreateProductFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_PRODUCT_FILE,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getProductFiles');
            queryClient.invalidateQueries('getProductFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 파일 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateProductFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_PRODUCT_FILE, {
            product_file_id: data.product_file_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getProductFiles');
            queryClient.invalidateQueries('getProductFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 상품 파일 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteProductFile: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_PRODUCT_FILE, {
            product_file_id: data.product_file_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getProductFiles');
            queryClient.invalidateQueries('getProductFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 게시판 파일 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetBoardFIles: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getBoardFiles', params] : 'getBoardFiles',
        async () => await $http.get(APIConstant.GET_BOARD_FILES, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 게시판 파일 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetBoardFile: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_BOARD_FILE, {
        board_file_id: idx,
      });
      const res = useQuery(
        params ? ['getBoardFile', params] : 'getBoardFile',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 게시판 파일 생성
   * --
   * @method POST
   * @returns
   */
  CreateBoardFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_BOARD_FILE,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getBoardFiles');
            queryClient.invalidateQueries('getBoardFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 게시판 파일 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateBoardFile: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_BOARD_FILE, {
            board_file_id: data.board_file_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getBoardFiles');
            queryClient.invalidateQueries('getBoardFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 게시판 파일 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteBoardFile: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_BOARD_FILE, {
            board_file_id: data.board_file_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getBoardFiles');
            queryClient.invalidateQueries('getBoardFile');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
