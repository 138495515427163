import React, { useEffect, useState, useRef } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  Line,
  Button,
  ModalLayout,
} from 'components';
import { Input, Radio } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MessageAlert from 'utils/MessageAlert';
import { ToolOutlined } from '@ant-design/icons';
import Validation from 'utils/Validation';
import { useNavigate } from 'react-router-dom';

const previewOption = [
  {
    label: '제공',
    value: 'Y',
  },
  {
    label: '미제공',
    value: 'N',
  },
];

const ChapterRegisterPresenter = (props) => {
  /* ===== PROPS ===== */
  const {
    type,
    chapters,
    setChapters,
    sections,
    setSections,
    onDataList,
    onSubmit,
    inputData,
    htmlString,
    questionnaire,
    screenSize,
  } = props;

  /* ===== ROUTE ===== */
  const navigate = useNavigate();

  /* ===== STATE ===== */
  const prevNewArrRef = useRef([]);
  const [chapterValue, setChapterValue] = useState(undefined);
  const [updateChapterValue, setUpdateChapterValue] = useState(undefined);
  const [sectionValue, setSectionValue] = useState(undefined);
  const [previewCheck, setPreviewCheck] = useState(undefined);
  const [videoList, setVideoList] = useState([]);
  const [newArr, setNewArr] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  // 수정할 챕터 Id
  const [chapterId, setChapterId] = useState(0);
  // 모달 열린 챕터 Id
  const [modalId, setModalId] = useState(0);
  // 수정할 섹션 Id
  const [modalData, setModalData] = useState({});

  /* ===== FUNCTION ===== */
  /**
   * 모달
   * --
   * @param {*} open
   */
  const handleModal = (open, id) => {
    setModalId(id);
    setIsOpened(!open);
  };

  /**
   * 아이템 리스트 드래그앤드롭
   * --
   * @param {*} result
   * @returns
   */
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, type } = result;

    // 챕터 유형의 드래그 앤 드롭인 경우
    if (type === 'CHAPTER') {
      const newChapters = Array.from(newArr);
      const [removed] = newChapters.splice(source.index, 1);
      newChapters.splice(destination.index, 0, removed);
      setNewArr(newChapters);
      return;
    }

    // 섹션 유형의 드래그 앤 드롭인 경우
    if (type === 'SECTION') {
      const sourceChapterIndex = parseInt(source.droppableId.split('_')[1]);
      const destinationChapterIndex = parseInt(
        destination.droppableId.split('_')[1]
      );
      const sourceSectionIndex = parseInt(source.index);
      const destinationSectionIndex = parseInt(destination.index);

      // 이동할 섹션을 소스 챕터에서 제거
      const sourceChapter = newArr[sourceChapterIndex];
      const movedSection = sourceChapter.sections.splice(
        sourceSectionIndex,
        1
      )[0];

      // 대상 챕터로 섹션을 삽입
      const destinationChapter = newArr[destinationChapterIndex];
      destinationChapter.sections.splice(
        destinationSectionIndex,
        0,
        movedSection
      );

      setNewArr([...newArr]);
      return;
    }
  };

  /**
   * 비디오 리스트 추가 함수
   * TODO: 비디오 URL 추가 시 빈 배열 제거
   * --
   */
  const handleVideoList = () => {
    const arr = [...videoList];
    const last = arr?.pop();
    let key = 0;
    if (!last) {
      key = 1;
    } else {
      key = last.key + 1;
    }

    const value = {
      key: key,
      title: undefined,
      url: undefined,
    };
    const newArr = [...videoList, value];

    setVideoList(newArr);
  };
  // console.log(videoList);

  /**
   * 비디오 리스트 데이터 바인딩
   * --
   * @param {*} i
   * @param {*} t
   * @param {*} v
   */
  const handleChanage = (i, t, v) => {
    let arr = [...videoList];
    let newData = arr[i];

    newData[t] = v;
    const d = {
      ...newData,
    };

    arr[i] = d;
    setVideoList(arr);
  };

  /**
   * 챕터 배열 관리 함수
   * --
   * @param {*} t
   * @param {*} v
   * @returns
   */
  const handleChapterList = (t, v) => {
    if (t === 'update') {
      const c = [...chapters];
      const newChapter = c?.map((item) => {
        if (item.id === v) {
          return {
            ...item,
            chapter_name: updateChapterValue,
          };
        } else {
          return item;
        }
      });

      setChapters(newChapter);
      setChapterId(0);
      setUpdateChapterValue(undefined);
    } else {
      if (window.confirm('해당 챕터를 삭제하시겠습니까?')) {
        const c = chapters?.filter((item) => item.id !== v);
        const s = sections?.filter((item) => item.key !== v);

        setChapters(c);
        setSections(s);
      } else {
        return;
      }
    }
  };

  /**
   * 섹션 배열 관리 함수
   * --
   * @param {*} t
   * @param {*} v
   * @returns
   */
  const handleSectionList = (t, v) => {
    if (t === 'update') {
      const d = sections?.filter((item) => item.id === v)?.pop();
      setModalData(d);
      setSectionValue(d?.section_name);
      setPreviewCheck(d?.preview);
      const video = d?.video_url && d?.video_url.length > 0 ? d.video_url : [];
      setVideoList(video);
      handleModal(isOpened);
    } else {
      if (window.confirm('해당 섹션을 삭제하시겠습니까?')) {
        const arr = sections?.filter((item) => item.id !== v);

        setSections(arr);
      } else {
        return;
      }
    }
  };

  /**
   * 섹션 데이터 추가/변경 함수
   * --
   */
  const handleSectionData = () => {
    if (JSON.stringify(modalData) === '{}') {
      onDataList('section', {
        id: modalId,
        section_name: sectionValue,
        video_url: [...videoList],
        preview: previewCheck,
      });
    } else {
      onDataList('section_update', {
        ...modalData,
        section_name: sectionValue,
        video_url: [...videoList],
        preview: previewCheck,
      });
    }

    setPreviewCheck(undefined);
    setSectionValue(undefined);
    setVideoList([]);
    setIsOpened(false);
  };
  // console.log('videoList', videoList);

  /* ===== HOOKS ===== */
  /**
   * 챕터와 섹션 합쳐서 정제
   * --
   */
  useEffect(() => {
    const arr = chapters?.map((item) => ({
      ...item,
      sections: sections?.filter((subitem) => subitem.key === item.id),
    }));

    setNewArr(arr);
  }, [chapters, sections]);

  /**
   * 드래그후 챕터, 섹션 업데이트
   * --
   */
  useEffect(() => {
    // 무한렌더링 방지
    const deepEqual = (a, b) => {
      if (a === b) return true;
      if (typeof a !== 'object' || typeof b !== 'object') return false;
      if (Object.keys(a).length !== Object.keys(b).length) return false;

      for (let key in a) {
        if (!deepEqual(a[key], b[key])) return false;
      }

      return true;
    };

    if (deepEqual(prevNewArrRef.current, newArr)) {
      return;
    }

    prevNewArrRef.current = newArr;

    const extractChaptersAndSections = () => {
      const chapterArr = [];
      const sectionArr = [];

      newArr.forEach((item) => {
        // 챕터 정보를 추출하여 챕터 배열에 추가
        chapterArr.push({ ...item });

        // 섹션 정보를 추출하여 섹션 배열에 추가
        const { sections: itemSections } = item;
        if (Array.isArray(itemSections)) {
          itemSections.forEach((section) => {
            sectionArr.push(section);
          });
        }
      });

      setChapters(chapterArr);
      setSections(sectionArr);
    };

    extractChaptersAndSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newArr]);

  /**
   * 모달 데이터 초기화
   * --
   */
  useEffect(() => {
    if (!isOpened) {
      setSectionValue(undefined);
      setPreviewCheck('N');
      setVideoList([]);
      setModalData({});
    }
  }, [isOpened]);

  /**
   * 새로고침 inputData 초기화 예외처리
   * --
   */
  useEffect(() => {
    const handleValueCheck = () => {
      const {
        title,
        major_name,
        category_id,
        difficulty_id,
        price,
        start_date,
        end_date,
      } = inputData;
      // 제목 유효성 검사
      if (!title || title === '') {
        MessageAlert.error('제목을 입력해주세요');
        return false;
      }

      // 자격증명 유효성 검사
      if (!major_name || major_name === '') {
        MessageAlert.error('자격증명을 입력해주세요');
        return false;
      }

      // 카테고리 유효성 검사
      if (!category_id) {
        MessageAlert.error('카테고리를 선택해주세요');
        return false;
      }

      // 난이도 유효성 검사
      if (!difficulty_id) {
        MessageAlert.error('난이도를 선택해주세요');
        return false;
      }

      // 가격 유효성 검사
      if (price === '' || price === null || price === undefined) {
        MessageAlert.error('가격을 입력해주세요');
        return false;
      } else if (!Validation('number', price)) {
        MessageAlert.error('가격은 숫자만 입력 가능합니다');
        return false;
      }

      // 시작일 유효성 검사
      if (!start_date) {
        MessageAlert.error('강의 시작일을 선택해주세요');
        return false;
      }

      // 종료일 유효성 검사
      if (!end_date) {
        MessageAlert.error('강의 종료일을 선택해주세요');
        return false;
      }

      // 내용 유효성 검사
      if (
        !htmlString ||
        htmlString === null ||
        htmlString === undefined ||
        htmlString === '' ||
        htmlString === '<p></p>\n'
      ) {
        MessageAlert.error('강의 내용을 입력해주세요');
        return false;
      }

      // 강의 질문지 입력 유효성 검사
      if (!questionnaire || questionnaire?.length === 0) {
        MessageAlert.error('강의 질문지를 입력해주세요');
        return false;
      }

      return true;
    };

    if (!handleValueCheck()) {
      navigate('/lectures/register/1');
    }
  }, [inputData, questionnaire, navigate, htmlString]);

  /* ===== Styles ===== */
  const styles = {
    modalLabel: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.02em',
      color: '#000000',
    },
    sectionButton: {
      width: screenSize >= 768 ? 30 : 20,
      height: screenSize >= 768 ? 30 : 20,
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      fontSize: screenSize >= 768 ? 18 : 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  /* ===== RENDER ===== */
  return (
    <>
      <Content maxWidth={'100%'} padding={screenSize >= 768 ? '0 12px' : 0}>
        <Row>
          <Col x={24} padding={8}>
            <Title size={screenSize >= 768 ? 2 : 4} padding={8}>
              강의등록
            </Title>
            <Line
              style={{ border: '1px solid #000000', margin: '16px 0 24px' }}
            />
            <Content
              maxWidth={'100%'}
              padding={screenSize >= 768 ? '0 8px' : 0}
            >
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={16} md={20}>
                  <Input
                    size="large"
                    value={chapterValue}
                    onChange={(e) => setChapterValue(e.target.value)}
                  />
                </Col>
                <Col x={8} md={4}>
                  <Button
                    color="secondary"
                    style={{
                      width: '100%',
                      height: '40.14px',
                      borderRadius: 0,
                      wordBreak: 'keep-all',
                    }}
                    onClick={() => {
                      onDataList('chapter', chapterValue);
                      setChapterValue(undefined);
                    }}
                  >
                    챕터 추가
                  </Button>
                </Col>
              </Row>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="chapters" type="CHAPTER">
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {newArr?.map((chapter, chapterIdx) => (
                        <Draggable
                          draggableId={`chapter_${chapterIdx}`}
                          index={chapterIdx}
                          key={`chapter-${chapterIdx}`}
                        >
                          {(provided, snapshot) => (
                            <li
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              style={{
                                border: '3px solid #BDBDBD',
                                margin: '10px 0',
                                background: snapshot.isDragging
                                  ? '#E3A4AF'
                                  : '#FFFFFF',
                                ...provided.draggableProps.style, // 기존 스타일 유지
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  borderBottom: '1px solid #BDBDBD',
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '80%',
                                  }}
                                >
                                  {chapterId &&
                                  chapterId !== 0 &&
                                  chapterId === chapter.id ? (
                                    <div
                                      style={{ width: '100%', display: 'flex' }}
                                    >
                                      <Input
                                        size="large"
                                        value={updateChapterValue}
                                        style={{
                                          padding:
                                            screenSize >= 768
                                              ? '20px 10px'
                                              : '10px 5px',
                                          fontSize: screenSize >= 768 ? 25 : 16,
                                          fontWeight: 700,
                                        }}
                                        onChange={(e) =>
                                          setUpdateChapterValue(e.target.value)
                                        }
                                      />
                                      <Button
                                        color="secondary"
                                        style={{
                                          width: screenSize >= 768 ? 100 : 60,
                                          height: '100%',
                                          borderRadius: 0,
                                          wordBreak: 'keep-all',
                                        }}
                                        onClick={() =>
                                          handleChapterList('update', chapterId)
                                        }
                                      >
                                        수정
                                      </Button>
                                    </div>
                                  ) : (
                                    <p
                                      style={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        padding:
                                          screenSize >= 768
                                            ? '20px 10px'
                                            : '10px 5px',
                                        fontSize: screenSize >= 768 ? 25 : 16,
                                        fontWeight: 700,
                                      }}
                                    >
                                      Chapter{chapterIdx + 1}.{' '}
                                      {chapter.chapter_name}
                                    </p>
                                  )}
                                </div>
                                {chapterId &&
                                chapterId !== 0 &&
                                chapterId === chapter.id ? null : (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <button
                                      style={{
                                        ...styles.sectionButton,
                                      }}
                                      onClick={() =>
                                        handleModal(isOpened, chapter.id)
                                      }
                                    >
                                      +
                                    </button>
                                    <button
                                      onClick={() => {
                                        setUpdateChapterValue(
                                          chapter.chapter_name
                                        );
                                        setChapterId(chapter.id);
                                      }}
                                      style={{
                                        ...styles.sectionButton,
                                        borderRight: '1px solid #BDBDBD',
                                        borderLeft: '1px solid #BDBDBD',
                                      }}
                                    >
                                      <ToolOutlined />
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleChapterList('delete', chapter.id)
                                      }
                                      style={{
                                        ...styles.sectionButton,
                                      }}
                                    >
                                      x
                                    </button>
                                  </div>
                                )}
                              </div>
                              <Droppable
                                droppableId={`sections_${chapterIdx}`}
                                type="SECTION"
                              >
                                {(provided) => (
                                  <ul
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {chapter?.sections?.map(
                                      (section, sectionIdx) => (
                                        <Draggable
                                          draggableId={`section_${chapterIdx}_${sectionIdx}`}
                                          index={sectionIdx}
                                          key={`section-${sectionIdx}`}
                                        >
                                          {(provided, snapshot) => (
                                            <li
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              ref={provided.innerRef}
                                              style={{
                                                border: '1px solid #BDBDBD',
                                                background: snapshot.isDragging
                                                  ? '#E3A4AF'
                                                  : '#FFFFFF',
                                                ...provided.draggableProps
                                                  .style, // 기존 스타일 유지
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent:
                                                    'space-between',
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    fontSize:
                                                      screenSize >= 768
                                                        ? 18
                                                        : 14,
                                                    fontWeight: 400,
                                                    padding:
                                                      screenSize >= 768
                                                        ? '10px 20px'
                                                        : '5px 10px',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                  }}
                                                >
                                                  Section{chapterIdx + 1}-
                                                  {sectionIdx + 1}.{' '}
                                                  {section.section_name}
                                                </p>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      marginRight:
                                                        screenSize >= 768
                                                          ? 10
                                                          : 5,
                                                      fontSize:
                                                        screenSize >= 768
                                                          ? 14
                                                          : 12,
                                                      whiteSpace: 'nowrap',
                                                    }}
                                                  >
                                                    {screenSize >= 768
                                                      ? '온라인강의'
                                                      : '강의'}{' '}
                                                    <span
                                                      style={{
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      {section.video_url &&
                                                      section.video_url
                                                        ?.length > 0
                                                        ? section.video_url
                                                            ?.length
                                                        : 0}
                                                    </span>
                                                  </p>
                                                  <button
                                                    onClick={() =>
                                                      handleSectionList(
                                                        'update',
                                                        section.id
                                                      )
                                                    }
                                                    style={{
                                                      ...styles.sectionButton,
                                                      borderRight:
                                                        '1px solid #BDBDBD',
                                                      borderLeft:
                                                        '1px solid #BDBDBD',
                                                    }}
                                                  >
                                                    <ToolOutlined />
                                                  </button>
                                                  <button
                                                    onClick={() =>
                                                      handleSectionList(
                                                        'delete',
                                                        section.id
                                                      )
                                                    }
                                                    style={{
                                                      ...styles.sectionButton,
                                                    }}
                                                  >
                                                    x
                                                  </button>
                                                </div>
                                              </div>
                                            </li>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                    {provided.placeholder}
                                  </ul>
                                )}
                              </Droppable>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </Content>
          </Col>
        </Row>
        <Row>
          <Col x={24}>
            <Button
              style={{
                width: screenSize >= 768 ? 282 : '70%',
                height: screenSize >= 768 ? 56 : 40,
                fontSize: screenSize >= 768 ? 20 : 18,
                fontWeight: 700,
                padding: 0,
                margin: screenSize >= 768 ? '120px auto 0' : '60px auto 0',
              }}
              onClick={() => onSubmit()}
            >
              {type === 'create' ? '등록' : '수정'}
            </Button>
          </Col>
        </Row>
      </Content>

      <ModalLayout
        title={'섹션 추가'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 556 : '100%'}
        height={'30%'}
        closable
        bodyStyle={{ padding: '40px' }}
        footer={
          screenSize < 768 ? (
            <Content padding={0} maxWidth={'100%'} key={'modalBottomButton'}>
              <Button
                style={{
                  width: '100%',
                  height: '76px',
                  marginTop: '24px',
                  fontSize: '20px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}
                onClick={() => handleSectionData()}
              >
                적용
              </Button>
            </Content>
          ) : null
        }
        footerStyle={{ padding: 0 }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Row
                style={{ width: '100%', display: 'flex', alignItems: 'center' }}
              >
                <Col x={6}>
                  <label htmlFor="c_title" style={{ ...styles.modalLabel }}>
                    섹션 타이틀
                  </label>
                </Col>
                <Col x={18}>
                  <Input
                    size="large"
                    id={'c_title'}
                    value={sectionValue}
                    maxLength={100}
                    onChange={(e) => setSectionValue(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: '24px' }}>
            <Col x={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <p style={{ ...styles.modalLabel }}>영상</p>
                <button
                  style={{
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '10px',
                    background: 'none',
                    border: '1px solid #BDBDBD',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleVideoList()}
                >
                  +
                </button>
              </div>
            </Col>
            {videoList && videoList.length > 0 ? (
              <>
                <Col x={12}>
                  <p>타이틀</p>
                </Col>
                <Col x={12}>
                  <p>URL</p>
                </Col>
              </>
            ) : null}
          </Row>
          {videoList?.map((item, index) => (
            <Row
              key={`video-${index}`}
              style={{
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Col x={11}>
                <Input
                  size="large"
                  value={videoList[index].title}
                  onChange={(e) =>
                    handleChanage(index, 'title', e.target.value)
                  }
                />
              </Col>
              <Col x={1}></Col>
              <Col x={11}>
                <Input
                  size="large"
                  value={videoList[index].url}
                  onChange={(e) => handleChanage(index, 'url', e.target.value)}
                />
              </Col>
              <Col x={1}>
                <button
                  style={{
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '10px',
                    background: 'none',
                    cursor: 'pointer',
                    border: 'none',
                  }}
                  onClick={() => {
                    const arr = [...videoList];
                    const newArr = arr?.filter(
                      (subitem) => subitem.key !== item.key
                    );
                    setVideoList(newArr);
                  }}
                >
                  x
                </button>
              </Col>
            </Row>
          ))}
          <Row
            style={{ marginTop: '30px', display: 'flex', alignItems: 'center' }}
          >
            <Col x={6}>
              <label htmlFor="c_check" style={{ ...styles.modalLabel }}>
                미리보기
              </label>
            </Col>
            <Col x={18}>
              <Radio.Group
                options={previewOption}
                onChange={(e) => setPreviewCheck(e.target.value)}
                value={previewCheck}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          </Row>
          {screenSize >= 768 ? (
            <Row>
              <Col x={24}>
                <Button
                  style={{
                    width: '100%',
                    height: '76px',
                    marginTop: '24px',
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '24px',
                  }}
                  onClick={() => handleSectionData()}
                >
                  적용
                </Button>
              </Col>
            </Row>
          ) : null}
        </Content>
      </ModalLayout>
    </>
  );
};

ChapterRegisterPresenter.defaultProps = {};

export default ChapterRegisterPresenter;
