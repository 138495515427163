import APIConstant from '../APIConstant';
import APIManager from '../APIManager';

const $http = new APIManager();

const Api = {
  verify: async () => {
    try {
      const url = APIConstant.VERIFY;
      const { status, data, message } = await $http.post(url);
      if (status === 200) {
        return data;
      }

      throw message;
    } catch (e) {
      console.log('Auth Token Verify Error !!', e);
      return false;
    }
  },

  /**
   * 중복검사
   * --
   * @method POST
   */
  authCheck: async (body) => {
    try {
      const url = APIConstant.AUTHCHECK;
      const res = await $http.post(url, body);

      return res;
    } catch (e) {
      console.log('사용불가!!', e);
      return false;
    }
  },
};

export default Api;
