import React, { useState, useEffect } from 'react';
import DifficultyPresenter from './DifficultyPresenter';

const DifficultyContainer = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { difficulties } = data;

      setNewData(difficulties);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <DifficultyPresenter data={newData} screenSize={screenSize} />;
};

DifficultyContainer.defaultProps = {};

export default DifficultyContainer;
