import React, { useState } from 'react';
import './DetailView.style.css';
import { Avatar, Select } from 'antd';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { HOST_URL, STORE_URL, getCookie } from 'utils';
import { LoadingPage } from 'components';
import MessageAlert from 'utils/MessageAlert';
import axios from 'axios';
import { BoardApi } from 'api';

const statusStyles = {
  DEFAULT: {
    border: '1px solid #BDBDBD',
    background: '#BDBDBD',
    color: '#FFFFFF',
    padding: '3px 10px',
  },
  PASS: {
    border: '1px solid #2AC769',
    background: '#2AC769',
    color: '#FFFFFF',
    padding: '3px 10px',
  },
  FAIL: {
    border: '1px solid #FB4E4E',
    background: '#FB4E4E',
    color: '#FFFFFF',
    padding: '3px 10px',
  },
};

const options = [
  {
    label: '통과',
    value: 'PASS',
  },
  {
    label: '불통',
    value: 'FAIL',
  },
  {
    label: '확인중',
    value: 'DEFAULT',
  },
];

const TaskStatus = ({ auth, data, onTaskStatus }) => {
  return auth === 'WRITER' ? (
    <Select
      size="large"
      options={options}
      value={data?.task_status}
      onChange={(e) => onTaskStatus(e)}
    />
  ) : (
    <span style={{ ...statusStyles[data?.task_status] }}>
      {data?.task_status === 'PASS'
        ? '통과'
        : data?.task_status === 'FAIL'
        ? '불통'
        : '확인중'}
    </span>
  );
};

const DetailView = (props) => {
  /* ===== PROPS ===== */
  const { data, auth } = props;
  // console.log('data', data);

  /* ===== MUTATE ===== */
  const updateBoard = BoardApi.UpdateBoard();

  /* ===== STATE ===== */
  const [isLoading, setIsLoading] = useState(false);

  const styles = {
    file:
      auth === 'WRITER' ||
      parseInt(data?.user_id) === parseInt(getCookie('USER'))
        ? {
            color: '#03A9F4',
            cursor: 'pointer',
          }
        : {
            color: '#000000',
            cursor: 'default',
          },
  };

  /* ===== RENDER ===== */
  /* ===== FUNCTION ===== */
  /**
   * 파일 다운로드
   * --
   */
  const handleDownloadFile = async (file) => {
    try {
      setIsLoading(true);
      const url = `brainheal/board/${file?.file_url}`;

      const res = await axios.post(`${HOST_URL}/file/blob`, {
        key: url,
      });
      const blob = res.data.data.data;
      const uint8Array = new Uint8Array(blob);
      const fileData = new File([uint8Array], file.file_name);
      const downloadURL = window.URL.createObjectURL(fileData);
      const a = document.createElement('a');
      a.href = downloadURL;
      a.download = file.file_name;
      document.body.appendChild(a);
      a.click();
    } catch (err) {
      console.log('파일 다운로드 에러!', err);
      MessageAlert.error('파일 다운로드에 실패하였습니다!');
      throw err;
    } finally {
      setTimeout(() => setIsLoading(false), 500);
    }
  };

  /**
   * 과제 상태값 핸들러
   * --
   */
  const handleTaskStatus = (status) => {
    const type = options?.find((item) => item?.value === status);
    const confirm = window.confirm(
      `과제를 ${type?.label}로 상태를 변경하시겠습니까?`
    );
    if (confirm) {
      try {
        updateBoard.mutate({
          board_id: data?.board_id,
          task_status: status,
        });
        MessageAlert.success('변경되었습니다');
      } catch (err) {
        console.log('과제 상태값 변경 에러!', err);
        MessageAlert.error('상태값을 변경하는데 에러가 발생하였습니다!');
        throw err;
      }
    } else {
      return;
    }
  };

  return (
    <div className="board_detail_layout">
      {isLoading && <LoadingPage text={'다운로드 준비중...'} />}
      {/* 게시글 타이틀 */}
      <div className="board_detail_title">
        <h4>{data?.title}</h4>
        {data?.type === 'ASSIGNMENT' && (
          <TaskStatus auth={auth} data={data} onTaskStatus={handleTaskStatus} />
        )}
      </div>
      {/* 작성자 정보 */}
      <div className="board_detail_info">
        <Avatar
          size={60}
          style={{ marginRight: 10 }}
          src={
            data?.profile &&
            `${STORE_URL}/users/${data?.profile?.user_id}/${data?.profile?.file_url}`
          }
        />
        <div>
          <h5>{data?.writer}</h5>
          <p>
            작성일:{' '}
            <span>
              {moment(data?.board_created_at)?.format('YYYY-MM-DD HH:mm')}
            </span>
          </p>
        </div>
      </div>
      {/* 게시글 내용 + 첨부파일 */}
      <div className="board_detail_content_container">
        <div className="board_detail_content">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data?.content),
            }}
          ></div>
        </div>
        {data?.type === 'ASSIGNMENT' && (
          <div className="board_detail_file">
            <ul>
              <li>첨부파일</li>
              <li>
                {data?.board_file?.length ? (
                  <p
                    style={{ ...styles?.file }}
                    onClick={
                      auth === 'WRITER' ||
                      parseInt(data?.user_id) === parseInt(getCookie('USER'))
                        ? () =>
                            handleDownloadFile(
                              data?.board_file[data?.board_file?.length - 1]
                            )
                        : () => {}
                    }
                  >
                    {data?.board_file[data?.board_file?.length - 1]?.file_name}
                    {auth === 'WRITER' &&
                      data?.task_similarity_status === 'Y' && (
                        <span>모사과제 주의!</span>
                      )}
                  </p>
                ) : (
                  <p>등록된 파일이 없습니다.</p>
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailView;
