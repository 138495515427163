import React, { useState, useEffect } from 'react';
import SubwayPresenter from './SubwayPresenter';

const SubwayContainer = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { subways } = data;

      const sortSubway = subways?.sort(
        (a, b) => a?.subway_name - b?.subway_name
      );

      setNewData(sortSubway);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <SubwayPresenter data={newData} screenSize={screenSize} />;
};

SubwayContainer.defaultProps = {};

export default SubwayContainer;
