import React from 'react';
import MyLearningPresenter from './MyLearningPresenter';
import { useQueryClient } from 'react-query';
import { getCookie } from 'utils';

const MyLearningContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const queryClient = useQueryClient();

  const lectures = queryClient.getQueryData('getLectures')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  const lectureOfUsers = queryClient.getQueryData('getLectureOfUsers')?.data;
  const chapters = queryClient.getQueryData('getChapters')?.data;
  const sections = queryClient.getQueryData('getSections')?.data;
  const exams = queryClient.getQueryData('getExams')?.data;
  const answerExams = queryClient.getQueryData('getAnswerExams')?.data;
  const difficulties = queryClient.getQueryData('getDifficulties')?.data;
  const idx = parseInt(getCookie('USER'));
  // console.log('lectureOfUsers', lectureOfUsers);

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <MyLearningPresenter
      lectures={lectures}
      users={users}
      lectureOfUsers={lectureOfUsers}
      chapters={chapters}
      sections={sections}
      categories={categories}
      exams={exams}
      answerExams={answerExams}
      difficulties={difficulties}
      idx={idx}
    />
  );
};

MyLearningContainer.defaultProps = {};

export default MyLearningContainer;
