import React, { useState, useEffect } from 'react';
import AddressPresenter from './AddressPresenter';

const AddressContainer = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [local, setLocal] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { addressSplit } = data;

      const local = addressSplit?.local;
      const city = addressSplit?.city;
      const district = addressSplit?.district;

      setLocal(local);
      setCity(city);
      setDistrict(district);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <AddressPresenter
      local={local}
      city={city}
      district={district}
      screenSize={screenSize}
    />
  );
};

AddressContainer.defaultProps = {};

export default AddressContainer;
