import React from 'react';
import MyPostsPresenter from './MyPostsPresenter';
import { useQueryClient } from 'react-query';
import { getCookie } from 'utils';

const MyPostsContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const queryClient = useQueryClient();

  const lectures = queryClient.getQueryData('getLectures')?.data;
  const lectureFiles = queryClient.getQueryData('getLectureFiles')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const reviews = queryClient.getQueryData('getReviews')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;

  const idx = parseInt(getCookie('USER'));

  // console.log('reviews', reviews && reviews);
  // console.log('lectures', lectures && lectures);
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <MyPostsPresenter
      lectures={lectures}
      lectureFiles={lectureFiles}
      users={users}
      reviews={reviews}
      categories={categories}
      idx={idx}
    />
  );
};

MyPostsContainer.defaultProps = {};

export default MyPostsContainer;
