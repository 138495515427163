import React, { useState, useEffect, useCallback } from 'react';
import {
  Input,
  Select,
  Switch,
  InputNumber,
  TreeSelect,
  Button as Btn,
  List,
  Empty,
} from 'antd';
import {
  Content,
  Row,
  Col,
  Title,
  FileUploadButton,
  TextEditor,
  ModalLayout,
} from 'components';
import MessageAlert from 'utils/MessageAlert';
import VirtualList from 'rc-virtual-list';
import { ProductApi, FileApi } from 'api';
import { useNavigate } from 'react-router-dom';
import { FileUploadManager } from 'utils/FileUploadManager';
import Validation from 'utils/Validation';
import UseDebounce from 'utils/useDebounce';

// 상품 타입
const pType = [
  {
    label: '상품',
    value: 'P',
  },
  {
    label: '치매예방다이어리',
    value: 'D',
  },
  {
    label: '소셜챌린지',
    value: 'C',
  },
];

// 구매 타입
const sType = [
  {
    label: '판매',
    value: 'S',
  },
  {
    label: '렌탈',
    value: 'R',
  },
  {
    label: '파일',
    value: 'F',
  },
];

const ProductRegisterPresenter = (props) => {
  /* ===== Props ===== */
  const { data, type, categories, productFile, programs, programCategories } =
    props;

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== Mutate ===== */
  const createProduct = ProductApi.CreateProduct({
    onSuccess: async (data) => {
      if (file) {
        if (file.size === 0) return;

        const res = await FileUploadManager('P', file);

        if (res.status === 200) {
          createFiles.mutate({
            product_id: data?.data?.product_id,
            file_name: res.file_name,
            file_url: res.name,
          });
        } else {
          MessageAlert.error('파일 업로드에 실패하였습니다');
        }
      }
    },
  });
  const updateProduct = ProductApi.UpdateProduct({
    onSuccess: async () => {
      if (file) {
        if (file?.size === 0) return;

        if (productFile) {
          deleteFiles.mutate({
            product_file_id: productFile?.product_file_id,
          });
        }

        const res = await FileUploadManager('P', file);

        if (res.status === 200) {
          createFiles.mutate({
            product_id: data?.product_id,
            file_name: res.file_name,
            file_url: res.name,
          });
        } else {
          MessageAlert.error('파일 업로드에 실패하였습니다');
        }
      }
    },
  });
  const deleteProduct = ProductApi.DeleteProduct();
  const createFiles = FileApi.CreateProductFile();
  const deleteFiles = FileApi.DeleteProductFile();

  /* ===== State ===== */
  const [addon, setAddon] = useState('포인트');
  const [inputData, setInputData] = useState({
    product_category_id: undefined,
    sales_type: 'S',
    product_name: undefined,
    price: undefined,
    num: undefined,
    is_show: 'Y',
    is_cash: 'N',
  });
  const [file, setFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailCheck, setThumbnailCheck] = useState(false);
  const [productType, setProductType] = useState(
    type === 'update' ? data?.product_type : pType[0]?.value
  );
  const [saleType, setSaleType] = useState(
    type === 'update' ? data?.sales_type : sType[0]?.value
  );
  const [productCategories, setProductCategories] = useState([]);
  const [dependency, setDependency] = useState(false);
  const [htmlString, setHtmlString] = useState(undefined);

  const [programItems, setProgramItems] = useState([]);
  const [programValue, setProgramValue] = useState({});
  const [programModal, setProgramModal] = useState(false);
  const [majorDetailValue, setMajorDetailValue] = useState(0);
  const [majorDetailItems, setMajorDetailItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [screenSize] = useState(document.body.clientWidth);
  const debouncedValue = UseDebounce(searchValue, 300);
  // console.log(programValue);

  /* ===== Functions ===== */
  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = useCallback(
    (t, v, callback = null) => {
      let newData = { ...inputData };

      newData[t] = v;
      const d = {
        ...newData,
      };
      setInputData(d);

      if (callback) callback(v);
    },
    [inputData]
  );

  /**
   * 상품 생성/수정 함수
   * --
   * @returns
   */
  const handleSubmit = async () => {
    const { product_category_id, product_name, price, num } = inputData;

    if (!product_category_id) {
      MessageAlert.warning('카테고리를 선택해주세요');
      return;
    }

    if (!product_name || product_name === '') {
      MessageAlert.warning('상품명을 입력해주세요');
      return;
    }

    if (price === '' || price === null || price === undefined) {
      MessageAlert.error('가격을 입력해주세요');
      return false;
    } else if (!Validation('number', price)) {
      MessageAlert.error('가격은 숫자만 입력 가능합니다');
      return false;
    }

    if (parseInt(price) < 100) {
      if (parseInt(price) !== 0) {
        MessageAlert.error('가격은 0원(무료) 또는 100원 이상 입력가능합니다');
        return false;
      }
    }

    if (type === 'P' && (!num || num === '')) {
      MessageAlert.warning('상품 수량을 입력해주세요');
      return;
    }

    // if (!content || content === '' || content === '<p></p>\n') {
    //   MessageAlert.error('상품 설명을 입력해주세요');
    //   return false;
    // }

    if (saleType === 'F' && !file) {
      MessageAlert.error('파일을 등록해주세요');
      return false;
    }
    if (type === 'create') {
      const confirm = window.confirm('상품을 등록하시겠습니까?');
      if (confirm) {
        try {
          const res = await FileUploadManager('P', thumbnailFile);
          const thumbnail_url = res?.status === 200 ? res?.name : null;
          createProduct.mutate({
            ...inputData,
            content: htmlString,
            sales_type: saleType,
            program_id: programValue?.program_id
              ? programValue?.program_id
              : null,
            thumbnail_url: thumbnail_url,
          });

          MessageAlert.success('상품이 등록되었습니다');
          navigate('/admin/products?page=1');
        } catch (e) {
          MessageAlert.error('상품 등록에 실패하였습니다');
          console.log('Error !!', e);
          throw e;
        }
      }
    } else {
      const confirm = window.confirm('상품을 수정하시겠습니까?');
      if (confirm) {
        const { created_at, num, ...d } = inputData;
        try {
          let thumbnail_url = thumbnailFile ? thumbnailFile?.name : null;
          if (thumbnailCheck && thumbnailFile) {
            const res = await FileUploadManager('P', thumbnailFile);
            thumbnail_url = res.status === 200 ? res.name : null;
          }
          updateProduct.mutate({
            ...d,
            num: productType === 'P' ? num : null,
            content: htmlString,
            sales_type: saleType,
            program_id: programValue?.program_id
              ? programValue?.program_id
              : null,
            thumbnail_url: thumbnail_url,
          });
          MessageAlert.success('상품이 정상적으로 수정되었습니다');
          navigate(`/admin/products?page=1`);
        } catch (e) {
          MessageAlert.error('상품 수정에 실패하였습니다');
          console.log('Error !!', e);
          throw e;
        }
      }
    }
  };

  /**
   * 파일 추가 함수
   * --
   * @param {*} file
   */
  const handleFileChange = (file) => {
    setFile(file);
  };

  /**
   * 썸네일 파일 추가 함수
   * --
   * @param {*} file
   */
  const handleThumbnailFileChange = (file) => {
    setThumbnailFile(file);
    type === 'update' && setThumbnailCheck(true);
  };

  /**
   * 상품삭제
   * --
   */
  const handleDelete = () => {
    const confirm = window.confirm(
      '삭제 후 복구할 수 없습니다. 정말 상품을 삭제하시겠습니까?\n삭제 시 상품 정보와 회원의 장바구니, 구매내역이 모두 삭제됩니다'
    );

    if (confirm) {
      try {
        const { product_id } = inputData;
        deleteProduct.mutate({
          product_id: product_id,
        });

        navigate('/admin/products');
        MessageAlert.success('상품이 삭제되었습니다');
      } catch (e) {
        MessageAlert.error('상품 삭제에 실패하였습니다');
        console.log('Error !!', e);
        throw e;
      }
    }
  };

  /* ===== Hooks ===== */
  /**
   * 상품 카테고리 정제
   * --
   */
  useEffect(() => {
    const category = categories?.filter(
      (item) => item?.product_type === productType
    );
    const parentsData = category?.filter((item) => item?.type === 'P');
    const childrenData = category?.filter((item) => item?.type === 'C');
    const treeData = parentsData?.map((item) => ({
      title: `${item?.product_category_name}`,
      value: item?.product_category_id,
      selectable: false,
      children: childrenData
        ?.filter((subitem) => item?.product_category_id === subitem?.parents_id)
        ?.map((subitem) => ({
          title: `${subitem?.product_category_name}`,
          value: subitem?.product_category_id,
        })),
    }));
    setProductCategories(treeData);
    setDependency(true);
  }, [productType, categories]);

  /**
   * 상품 타입 변경 시 카테고리 값 초기화
   * --
   */
  useEffect(() => {
    if (dependency) {
      const id = productCategories && productCategories[0]?.product_category_id;
      if (data && data?.product_type === productType) {
        return;
      }
      handleChange('product_category_id', id);
      setDependency(false);
    }
  }, [productType, productCategories, dependency, handleChange, data]);

  /**
   * 상품 수정 데이터 바인딩
   * --
   */
  useEffect(() => {
    if (type === 'update' && data) {
      const d = data;
      setInputData({
        ...d,
      });
      //NOTE :2024/12/27~ 교안 추가
      const program = programItems?.filter(
        (item) => item?.program_id === d?.program_id
      )[0];
      setProgramValue({
        program_id: program?.program_id,
        ...program?.content,
      });
      setHtmlString(d?.content);
      setProductType(d?.product_type);
      setSaleType(d?.sales_type);
      const thumbnail_url = d?.thumbnail_url
        ? { name: d?.thumbnail_url }
        : null;
      setThumbnailFile(thumbnail_url);

      // 파일이 있을 경우
      if (productFile) {
        setFile({
          name: productFile.file_name,
          size: 0,
        });
        // const downloadFile = async (fileUrl) => {
        //   try {
        //     const response = await axios.post(`${HOST_URL}/file/blob`, {
        //       key: fileUrl,
        //     });
        //     const blob = response.data.data.data;
        //     const uint8Array = new Uint8Array(blob);

        //     const fileData = new File([uint8Array], productFile.file_name, {
        //       type: `image/png`,
        //     });
        //     console.log('fileData', fileData);
        //     setFile(fileData);
        //   } catch (error) {
        //     console.error('Error while downloading file:', error);
        //     throw error;
        //   }
        // };
        // downloadFile(`brainheal/product/${productFile.file_url}`);
      }
    } else {
      setInputData({
        product_category_id: undefined,
        product_name: undefined,
        price: undefined,
        num: undefined,
        is_show: 'Y',
        is_cash: 'N',
      });
    }
  }, [type, data, productFile, programItems]);

  /**
   * 금액 단위 설정
   * --
   */
  useEffect(() => {
    if (inputData?.is_cash === 'Y') {
      setAddon('원');
    } else {
      setAddon('포인트');
    }
  }, [inputData?.is_cash]);

  //NOTE :2024/12/26~ 교안 카테고리 아이템
  /**
   * 타입에 따른 교안 데이터 초기화
   * --
   */
  useEffect(() => {
    if (productType !== 'P' || saleType === 'S') {
      setProgramValue({});
    }
  }, [productType, saleType]);

  /**
   * 카테고리 Select 아이템정제
   * --
   */
  useEffect(() => {
    if (programCategories) {
      const majorDetail = programCategories?.filter(
        (item) => item?.type === 'D'
      );
      const d = [
        {
          label: '전체보기',
          value: 0,
        },
        ...majorDetail?.map((item) => ({
          label: item?.category_name,
          value: item?.category_id,
        })),
      ];
      setMajorDetailItems(d);
    }
  }, [programCategories]);

  /**
   * 교안 아이템 정제
   * --
   */
  useEffect(() => {
    const p = programs?.map((item) => ({
      ...item,
      ...(categories &&
        programCategories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]),
    }));
    const newPrograms = p?.map((item) => ({
      ...item,
      parents_name: programCategories?.filter(
        (subitem) => subitem?.category_id === item?.parents_id
      )[0]?.category_name,
    }));
    setProgramItems(newPrograms);
  }, [programs, programCategories, categories]);

  /**
   * 필터 값에 따른 교안 아이템 정제
   * --
   */
  useEffect(() => {
    const p = programs?.map((item) => ({
      ...item,
      ...(programCategories &&
        programCategories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]),
    }));
    const newPrograms = p?.map((item) => ({
      ...item,
      parents_name: programCategories?.filter(
        (subitem) => subitem?.category_id === item?.parents_id
      )[0]?.category_name,
    }));

    const newItem = newPrograms
      ?.filter((item) =>
        majorDetailValue === 0 ? item : item?.category_id === majorDetailValue
      )
      ?.filter((item) =>
        debouncedValue === ''
          ? item
          : item?.content?.title?.includes(debouncedValue)
      );
    setProgramItems(newItem);
  }, [majorDetailValue, debouncedValue, programs, programCategories]);

  /* ===== Render ===== */
  return (
    <>
      <Content padding={24} maxWidth={1500} className={'admin_container'}>
        <Row>
          <Col x={24} padding={8}>
            <Title size={2} padding={8} style={{ marginBottom: '28px' }}>
              {type === 'create' ? '상품 등록' : '상품 수정'}
            </Title>
          </Col>
          <Col x={24}>
            {/* 상품타입 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="product_type"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  상품 타입
                </label>
              </Col>
              <Col x={18}>
                <Select
                  id="product_type"
                  placeholder="상품 타입을 선택해주세요"
                  size="large"
                  style={{ marginBottom: 10, width: '100%' }}
                  name="product_type"
                  value={productType}
                  onChange={(e) => setProductType(e)}
                  options={pType}
                />
              </Col>
            </Row>
            {/* 판매타입 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="sale_type"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  판매 타입
                </label>
              </Col>
              <Col x={18}>
                <Select
                  id="sale_type"
                  placeholder="판매 타입을 선택해주세요"
                  size="large"
                  style={{ marginBottom: 10, width: '100%' }}
                  name="sale_type"
                  value={saleType}
                  onChange={(e) => setSaleType(e)}
                  options={sType}
                />
              </Col>
            </Row>
            {/* 상품 카테고리 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="product_category"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  상품 카테고리
                </label>
              </Col>
              <Col x={18}>
                <TreeSelect
                  id="product_category"
                  placeholder="상품 카데고리를 선택해주세요"
                  size="large"
                  style={{ marginBottom: 10, width: '100%' }}
                  name="product_category"
                  value={
                    inputData?.product_category_id
                      ? inputData?.product_category_id
                      : null
                  }
                  onChange={(e) => handleChange('product_category_id', e)}
                  treeData={productCategories}
                />
              </Col>
            </Row>
            {/* 상품 타이틀 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="product_name"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  상품명
                </label>
              </Col>
              <Col x={18}>
                <Input
                  id="lecture_title"
                  placeholder="상품명을 입력해주세요"
                  size="large"
                  style={{ marginBottom: 10 }}
                  name="product_name"
                  value={inputData?.product_name}
                  onChange={(e) => handleChange('product_name', e.target.value)}
                  maxLength={100}
                />
              </Col>
            </Row>
            {/* 현금결제 여부 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="is_cash"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  현금결제
                </label>
              </Col>
              <Col x={18}>
                <Switch
                  size="large"
                  checked={inputData?.is_cash === 'Y' ? true : false}
                  onChange={(checked) =>
                    handleChange('is_cash', checked ? 'Y' : 'N')
                  }
                />
              </Col>
            </Row>
            {/* 가격 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="price"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  가격
                </label>
              </Col>
              <Col x={18}>
                <InputNumber
                  id="lecture_price"
                  placeholder="가격을 입력해주세요"
                  size="large"
                  style={{ marginBottom: 10, width: '100%' }}
                  name="price"
                  value={inputData?.price}
                  onChange={(value) => handleChange('price', value)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  controls={false}
                  addonAfter={addon}
                />
              </Col>
            </Row>
            {/* 상품 갯수 */}
            {productType === 'P' && saleType !== 'F' ? (
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Col x={6}>
                  <label
                    htmlFor="product_name"
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    수량
                  </label>
                </Col>
                <Col x={18}>
                  <InputNumber
                    id="lecture_title"
                    placeholder="상품 수량을 입력해주세요"
                    size="large"
                    style={{ marginBottom: 10, width: '100%' }}
                    name="product_name"
                    value={inputData?.num}
                    onChange={(value) => handleChange('num', value)}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    controls={false}
                  />
                </Col>
              </Row>
            ) : null}
            {/* //NOTE : 2024/12/26 - 교안 등록 화면 */}
            {/* 교안등록 */}
            {productType === 'P' && saleType !== 'S' ? (
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Col x={6}>
                  <label
                    htmlFor="product_name"
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    교안 등록
                  </label>
                </Col>
                <Col x={18}>
                  {programValue?.category ? (
                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 18,
                          display: 'flex',
                          alignItems: 'center',
                          color: '#000000',
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            marginRight: 10,
                            display: 'inline-block',
                            padding: '3px 5px',
                            background: '#E3A4AF',
                            color: '#FFFFFF',
                          }}
                        >
                          {programValue?.category}
                        </span>
                        {programValue?.title}
                      </p>
                      <button
                        style={{
                          padding: '0px 5px',
                          cursor: 'pointer',
                          background: '#FFFFFF',
                          border: '1px solid #EEEEEE',
                        }}
                        onClick={() => setProgramValue({})}
                      >
                        x
                      </button>
                    </Content>
                  ) : (
                    <Content maxWidth={'100%'} padding={0}>
                      <Btn type="primary" onClick={() => setProgramModal(true)}>
                        교안검색
                      </Btn>
                    </Content>
                  )}
                </Col>
              </Row>
            ) : null}
            {/* 숨김 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="is_show"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  노출여부
                </label>
              </Col>
              <Col x={18}>
                <Switch
                  size="large"
                  checked={inputData?.is_show === 'Y' ? true : false}
                  onChange={(checked) =>
                    handleChange('is_show', checked ? 'Y' : 'N')
                  }
                />
              </Col>
            </Row>
            {/* 내용 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={24}>
                <label
                  htmlFor="price"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    marginBottom: '12px',
                    display: 'inline-block',
                  }}
                >
                  상품 설명
                </label>
              </Col>
              <Col x={24}>
                <TextEditor
                  data={inputData?.content}
                  setHtmlString={setHtmlString}
                />
              </Col>
            </Row>
            {/* 파일 */}
            {saleType === 'F' ? (
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Col x={6}>
                  <label
                    htmlFor="price"
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    파일
                  </label>
                </Col>
                <Col x={18}>
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    {file ? (
                      <Col
                        x={10}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Title>{file?.name}</Title>
                        <button
                          style={{
                            border: '1px solid #E0E0E0',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            background: 'none',
                          }}
                          onClick={() => setFile(null)}
                        >
                          x
                        </button>
                      </Col>
                    ) : (
                      <Col x={10}>
                        <FileUploadButton
                          style={{
                            width: '100%',
                            height: '56px',
                          }}
                          onChange={handleFileChange}
                        >
                          + 파일추가
                        </FileUploadButton>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            ) : null}
            {/* 썸네일 */}
            {
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Col x={6}>
                  <label
                    htmlFor="price"
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    썸네일
                  </label>
                </Col>
                <Col x={18}>
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    {thumbnailFile ? (
                      <Col
                        x={10}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Title>{thumbnailFile?.name}</Title>
                        <button
                          style={{
                            border: '1px solid #E0E0E0',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            background: 'none',
                          }}
                          onClick={() => setThumbnailFile(null)}
                        >
                          x
                        </button>
                      </Col>
                    ) : (
                      <Col x={10}>
                        <FileUploadButton
                          style={{
                            width: '100%',
                            height: '56px',
                          }}
                          accept="image/*"
                          onChange={handleThumbnailFileChange}
                        >
                          + 썸네일추가
                        </FileUploadButton>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            }
            {/* <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="price"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                ></label>
              </Col>
              <Col x={18}>
                <Row>
                  <Col x={10}>
                    {files && files?.length > 0 ? (
                      files?.map((item, index) => (
                        <div
                          key={`file-${index}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            // width: '80%',
                          }}
                        >
                          <p style={{ padding: '5px 0' }}>{item?.name}</p>
                          <button
                            style={{
                              border: 'none',
                              background: 'none',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleRemoveFileList(index)}
                          >
                            x
                          </button>
                        </div>
                      ))
                    ) : (
                      <p style={{ padding: '5px 0' }}>
                        등록된 이미지가 없습니다
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row> */}
          </Col>
        </Row>
        {/* 등록/수정/삭제 버튼 */}
        <Row>
          <Col x={24} style={{ display: 'flex' }}>
            {type === 'update' ? (
              <Btn
                danger
                type="primary"
                style={{
                  width: '282px',
                  height: '56px',
                  fontSize: '20px',
                  fontWeight: 700,
                  padding: 0,
                  margin: '120px auto 0',
                }}
                onClick={() => handleDelete()}
              >
                삭제
              </Btn>
            ) : null}

            <Btn
              type="primary"
              style={{
                width: '282px',
                height: '56px',
                fontSize: '20px',
                fontWeight: 700,
                padding: 0,
                margin: '120px auto 0',
              }}
              onClick={() => handleSubmit()}
            >
              {type === 'create' ? '등록' : '수정'}
            </Btn>
          </Col>
        </Row>
      </Content>

      {/* 프로그램 선택 모달 */}
      {/* //NOTE : 2024/12/26 - 교안 모달*/}
      <ModalLayout
        title={'프로그램'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={programModal}
        onCancel={setProgramModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 768 : '100%'}
        height={'70%'}
        closable
        bodyStyle={{ padding: '40px' }}
        zIndex={5700}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={7} padding={5}>
              <p>구분</p>
              <Select
                value={majorDetailValue}
                onChange={(e) => setMajorDetailValue(e)}
                style={{ width: '100%' }}
                size="large"
                options={majorDetailItems}
                dropdownStyle={{
                  zIndex: 8000,
                }}
              />
            </Col>
            <Col x={17} padding={5}>
              <p>프로그램</p>
              <Input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                style={{ width: '100%' }}
                size="large"
                placeholder="프로그램명을 검색해주세요"
              />
            </Col>
          </Row>
          <Row>
            <Col x={24} style={{ marginTop: 20, border: '1px solid #EEEEEE' }}>
              <Row>
                <Col
                  x={5}
                  padding={5}
                  style={{
                    borderRight: '1px solid #EEEEEE',
                    color: '#BDBDBD',
                    fontWeight: 700,
                  }}
                >
                  구분
                </Col>
                <Col
                  x={19}
                  padding={5}
                  style={{
                    color: '#BDBDBD',
                    fontWeight: 700,
                  }}
                >
                  프로그램명
                </Col>
              </Row>
            </Col>
            <Col
              x={24}
              style={{
                border: '1px solid #EEEEEE',
                borderTop: 'none',
                height: 500,
                overflowY: 'scroll',
              }}
            >
              {programItems && programItems?.length ? (
                <List>
                  <VirtualList
                    data={programItems}
                    height={'100%'}
                    itemKey={'program_id'}
                  >
                    {(item) => (
                      <List.Item
                        style={{
                          borderBottom: '1px solid #EEEEEE',
                          padding: 0,
                        }}
                      >
                        <Col
                          x={5}
                          style={{
                            color: '#000000',
                            fontWeight: 700,
                            display: 'flex',
                            alignItems: 'center',
                            padding: 0,
                          }}
                        >
                          {item?.category_name}
                        </Col>
                        <Col
                          x={19}
                          style={{
                            borderLeft: '1px solid #EEEEEE',
                            padding: '12px 0',
                            color: '#000000',
                            fontWeight: 700,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          {item?.content?.title}
                          <Btn
                            onClick={() => {
                              setProgramValue({
                                ...item?.content,
                                program_id: item?.program_id,
                              });
                              setProgramModal(false);
                            }}
                          >
                            선택
                          </Btn>
                        </Col>
                      </List.Item>
                    )}
                  </VirtualList>
                </List>
              ) : (
                <Row
                  style={{
                    borderBottom: '1px solid #EEEEEE',
                    height: '100%',
                  }}
                >
                  <Col x={24}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{
                        height: '100%',
                        margin: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

ProductRegisterPresenter.defaultProps = {};

export default ProductRegisterPresenter;
