import React from 'react';
import { Input } from 'antd';
import './DaumAddress.style.css';
import { openAddressModal } from 'utils/DaumAPIManager';

const DaumAddress = ({ action, callback, ...antd }) => {
  /* ===== STATE ===== */
  /* ===== FUNCTION ===== */
  /**
   * @title daum address api 핸들러
   */
  // TODO: 다음 주소 api 모달
  const handelOpenAddrModal = () => {
    openAddressModal((res) => {
      callback(res);
    });
  };
  /* ===== RENDER ===== */
  return (
    <>
      <Input
        {...antd}
        onClick={action ? () => handelOpenAddrModal() : () => {}}
      />
    </>
  );
};

DaumAddress.defaultProps = {
  action: true,
};

export default DaumAddress;
