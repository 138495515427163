import React from 'react';
import { Link } from 'react-router-dom';
import './MenuLayout.style.css';

const MenuLayout = ({ menu, currentType }) => {
  return (
    <div className="board_menu_container">
      <div className="board_menu_title">
        <h4>
          <span></span>게시판
        </h4>
      </div>
      <ul className="board_menu_list">
        {menu?.map((item) => (
          <li
            key={item?.key}
            style={{
              background:
                currentType === item?.key?.toLowerCase()
                  ? '#F2D3DB'
                  : '#FFFFFF',
            }}
          >
            <Link
              to={item?.path}
              style={{
                color:
                  currentType === item?.key?.toLowerCase() ? '#AB5476' : '#000',
                fontWeight:
                  currentType === item?.key?.toLowerCase() ? 600 : 400,
              }}
            >
              {item?.title} 게시판
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MenuLayout;
