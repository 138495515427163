import React, { useState, useEffect } from 'react';
import ProductCategoryPresenter from './ProductCategoryPresenter';

const ProductCategoryContainer = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { productCategories } = data;

      setNewData(productCategories);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <ProductCategoryPresenter data={newData} screenSize={screenSize} />;
};

ProductCategoryContainer.defaultProps = {};

export default ProductCategoryContainer;
