import React, { useEffect, useState } from 'react';
import { Row, Col, Content, Title, Button } from 'components';
import { Tabs, List, Empty } from 'antd';
import moment from 'moment';
import VirtualList from 'rc-virtual-list';
import { UserApi } from 'api';
import { useNavigate, useLocation } from 'react-router-dom';
import { encrypt } from 'utils/Crypto';
import MessageAlert from 'utils/MessageAlert';
import { DOMAIN_URL, PLAYER_URL, getCookie } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';

function MyLearningPresenter({
  lectures,
  users,
  lectureOfUsers,
  chapters,
  sections,
  categories,
  exams,
  answerExams,
  difficulties,
  idx,
}) {
  /* ===== Props ===== */

  /* ===== Mutate ===== */
  const updateUser = UserApi.UpdateUser();

  /* ===== Router ===== */
  const location = useLocation();
  const navigate = useNavigate();
  /* ===== State ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');

  const today = moment(new Date()).format('YYYY-MM-DD');

  const NewExams = exams?.map((item) => ({
    ...item,
    answerExams:
      answerExams &&
      answerExams
        ?.filter((subitem) => item.exam_id === subitem.exam_id)
        ?.filter((lowitem) => lowitem.user_id === idx)
        ?.pop(),
  }));

  /**
   * 강의 데이터 정제
   * --
   */
  const NewLectures = lectures?.map((item) => ({
    ...item,
    lecture_title: item.title,
    ...(
      users && users?.filter((subitem) => item.user_id === subitem.user_id)
    )?.pop(),
    ...(
      NewExams &&
      NewExams?.filter((subitem) => item.lecture_id === subitem.lecture_id)
    )?.pop(),
    category: categories?.filter(
      (subitem) => item?.category_id === subitem?.category_id
    )[0]?.category_name,
  }));

  /**
   * 챕터-섹션 데이터 정제
   * --
   */
  const NewChapters = chapters?.map((item) => ({
    ...item,
    sections_count: sections?.filter(
      (subitem) => item.chapter_id === subitem.chapter_id
    )?.length,
  }));
  // console.log('NewChapters', NewChapters);
  // console.log(difficulties);
  /**
   * 수강 데이터 정제
   * --
   */
  const NewClassList = lectureOfUsers
    ?.filter((item) => item.user_id === idx)
    .map((subitem) => ({
      ...subitem,
      lecture_of_user_created_at: subitem.created_at,
      ...(
        NewLectures &&
        NewLectures?.filter(
          (lowitem) => subitem.lecture_id === lowitem.lecture_id
        )
      )?.pop(),
      chapters: NewChapters?.filter(
        (chapterItem) => subitem.lecture_id === chapterItem.lecture_id
      ),
    }));
  // console.log(NewClassList);

  /**
   * 수강 데이터 정제 - 진도율, 시험 등
   * --
   * FIXME: exam_score 정제 수정/ 시험점수
   * NOTE: 완료
   */

  const NewLectureOfUserList = NewClassList?.map((item) => ({
    ...item,
    total_sections_count: item.chapters?.reduce(
      (prev, current) => prev + current.sections_count,
      0
    ),
    progress_count: item.progress
      ? item.progress?.filter((item) => item?.status === 'DONE')?.length
      : 0,
    // ing: Date('2023-05-15') <= Date(moment(item.end_date).format('YYYY-MM-DD')),
    ing: today < moment(item.end_date).format('YYYY-MM-DD'),
    exam_score: item.answerExams?.answer?.reduce(
      (prev, current) => prev + current.score,
      0
    ),
    difficulty_level: difficulties
      ?.filter((lowitem) => item.difficulty_id === lowitem.difficulty_id)
      ?.pop()?.level,
    student: users?.filter((lowitem) => lowitem.user_id === idx)?.pop(),
  }));

  // console.log('NewLectureOfUserList', NewLectureOfUserList);

  // console.log(
  //   NewLectureOfUserList?.filter(
  //     (item) =>
  //       item.total_sections_count !== 0 &&
  //       item.total_sections_count === item.progress_count
  //   )
  // );
  /* ===== Hooks ===== */

  /**
   * 기관회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== Functions ===== */
  const handleCertificate = async (
    major_name,
    difficulty_level,
    category_name,
    user_name,
    birthday,
    certificate_num,
    day
  ) => {
    const confirm = window.confirm(
      '수료증을 발급 하시겠습니까? \n강사기능은 수료증 발급이후 재 로그인 이후 사용가능합니다.'
    );
    if (confirm) {
      try {
        updateUser.mutate({
          user_id: idx,
          teacher_check: 'Y',
        });
        MessageAlert.success('수료증이 발급 되었습니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('수료증 발급에 실패하였습니다');
        throw e;
      }

      navigate('/certificate', {
        state: {
          from: location,
          data: {
            major_name: major_name,
            difficulty_level: difficulty_level,
            category_name: category_name,
            user_name: user_name,
            birthday: birthday,
            certificate_num: certificate_num,
            day: day,
          },
        },
      });
    }
  };

  const handleNavigate = (item) => {
    if (!item?.progress || item?.progress_count === 0) {
      const confirm = window.confirm(
        '강의를 수강하시겠습니까?\n수강 후 환불은 불가능합니다'
      );
      if (confirm) {
        window.open(
          `${PLAYER_URL}/lectureclass/${encodeURIComponent(
            encrypt(item?.lecture_id)
          )}?user=${encodeURIComponent(
            getCookie('USER')
          )}&token=${encodeURIComponent(getCookie('BRAINHEAL_ACCESS_TOKEN'))}`,
          '_blank'
        );
        // navigate(
        //   `${PLAYER_URL}/lectureclass/${encodeURI(encrypt(item?.lecture_id))}`
        // );
      }
    } else {
      // console.log('PLAYER_URL', PLAYER_URL);
      window.open(
        `${PLAYER_URL}/lectureclass/${encodeURIComponent(
          encrypt(item?.lecture_id)
        )}?user=${encodeURIComponent(
          getCookie('USER')
        )}&token=${encodeURIComponent(getCookie('BRAINHEAL_ACCESS_TOKEN'))}`,
        '_blank'
      );
      // navigate(
      //   `${PLAYER_URL}/lectureclass/${encodeURI(encrypt(item?.lecture_id))}`
      // );
    }
  };

  /**
   * 게시판 팝업
   * --
   */
  const handlePopup = (e, item) => {
    e.stopPropagation();
    const domain = DOMAIN_URL;

    window.open(
      `${domain}/#/popup/board?lecture=${encodeURIComponent(
        encrypt(item?.lecture_id)
      )}&type=inquiry`,
      '팝업',
      'width=1500, height=850'
    );
  };

  /* ===== Render ===== */
  return (
    <>
      <Content maxWidth={'100%'} padding={'0 12px'}>
        <Row>
          <Col x={24} padding={8}>
            <Title
              size={screenSize >= 768 ? 2 : 4}
              padding={8}
              style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
            >
              나의 학습
            </Title>
          </Col>
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              padding={
                screenSize >= 768 ? '24px 24px 0 24px' : '10px 10px 0 10px'
              }
              style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
            >
              <Col x={24}>
                <Tabs
                  size="small"
                  defaultActiveKey="1"
                  items={[
                    {
                      key: '1',
                      label: `수강중인 강의`,
                      children: (
                        <Content maxWidth={'100%'} padding={0}>
                          <Row>
                            <Col x={24}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: screenSize >= 768 ? 60 : 40,
                                  padding: '9px 6px',
                                  background: '#D28494',
                                  borderRadius: '4px 4px 0px 0px',
                                }}
                              >
                                <Col x={10} md={7} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    강의명
                                  </Title>
                                </Col>
                                <Col x={0} md={4} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      lineHeight: '19px',
                                    }}
                                  >
                                    카테고리
                                  </Title>
                                </Col>
                                <Col x={0} md={4} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      lineHeight: '19px',
                                    }}
                                  >
                                    수강종료일
                                  </Title>
                                </Col>
                                <Col x={10} md={6} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    수강진행도
                                  </Title>
                                </Col>
                                <Col x={4} md={3} padding={8}></Col>
                              </div>
                            </Col>
                            <Col x={24}>
                              <List>
                                {NewLectureOfUserList?.filter(
                                  (item) => item.ing === true
                                )?.length < 1 ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      minHeight: '400px',
                                      alignItems: 'center',
                                      border: '1px solid #f0f0f0',
                                    }}
                                  >
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                  </div>
                                ) : (
                                  <VirtualList
                                    data={NewLectureOfUserList?.filter(
                                      (item) => item.ing === true
                                    )}
                                    height={screenSize >= 768 ? 440 : 350}
                                    itemHeight={60}
                                    itemKey="title"
                                  >
                                    {(item, index) => (
                                      <List.Item
                                        key={item.lecture_id}
                                        style={{
                                          background:
                                            index % 2 === 0
                                              ? '#FFFFFF'
                                              : '#FAFAFA',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => handleNavigate(item)}
                                      >
                                        <Content
                                          maxWidth={'100%'}
                                          padding={0}
                                          backgroundColor={'none'}
                                        >
                                          <Row>
                                            <Col
                                              x={10}
                                              md={7}
                                              padding={8}
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize:
                                                    screenSize >= 768 ? 16 : 14,
                                                  lineHeight: '19px',
                                                  display: 'block',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap',
                                                }}
                                              >
                                                {item.lecture_title}
                                              </Title>
                                            </Col>
                                            <Col
                                              x={0}
                                              md={4}
                                              padding={8}
                                              style={{
                                                display:
                                                  screenSize >= 768
                                                    ? 'flex'
                                                    : 'none',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize: '16px',
                                                  lineHeight: '19px',
                                                }}
                                              >
                                                {item?.category}
                                              </Title>
                                            </Col>
                                            <Col
                                              x={0}
                                              md={4}
                                              padding={8}
                                              style={{
                                                display:
                                                  screenSize >= 768
                                                    ? 'flex'
                                                    : 'none',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize: '16px',
                                                  lineHeight: '19px',
                                                }}
                                              >
                                                {moment(item.end_date).format(
                                                  'YYYY-MM-DD'
                                                )}
                                              </Title>
                                            </Col>
                                            <Col
                                              x={8}
                                              md={6}
                                              padding={8}
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize:
                                                    screenSize >= 768 ? 16 : 14,
                                                  lineHeight: '19px',
                                                  width: '55px',
                                                  marginRight: '5px',
                                                }}
                                              >
                                                {item.total_sections_count === 0
                                                  ? '0.0'
                                                  : (
                                                      (item.progress_count /
                                                        item.total_sections_count) *
                                                      100
                                                    ).toFixed(2)}
                                                %
                                              </Title>
                                              {screenSize >= 768 && (
                                                <>
                                                  <br />
                                                  <div
                                                    style={{
                                                      fontSize:
                                                        screenSize >= 768
                                                          ? 16
                                                          : 14,
                                                      fontWeight: 500,
                                                      lineHeight: '19px',
                                                      color: '#000000',
                                                      width: '55px',
                                                      height: '27px',
                                                      marginLeft:
                                                        screenSize >= 768
                                                          ? '10px'
                                                          : '',
                                                      borderRadius: '4px',
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                      background:
                                                        (item.progress_count /
                                                          item.total_sections_count) *
                                                          100 ===
                                                        100
                                                          ? '#A4E3AA'
                                                          : (item.progress_count /
                                                              item.total_sections_count) *
                                                              100 ===
                                                              0 ||
                                                            item.total_sections_count ===
                                                              0
                                                          ? '#E0E0E0'
                                                          : '#A4D4E3',
                                                    }}
                                                  >
                                                    {(item.progress_count /
                                                      item.total_sections_count) *
                                                      100 ===
                                                    100
                                                      ? '완료'
                                                      : (item.progress_count /
                                                          item.total_sections_count) *
                                                          100 ===
                                                        0
                                                      ? '진행전'
                                                      : '진행중'}
                                                  </div>
                                                </>
                                              )}
                                            </Col>
                                            <Col x={6} md={3} padding={8}>
                                              <Button
                                                onClick={(e) =>
                                                  handlePopup(e, item)
                                                }
                                                style={
                                                  screenSize >= 768
                                                    ? {}
                                                    : {
                                                        padding: '3px 5px',
                                                        width: '100%',
                                                      }
                                                }
                                              >
                                                게시판
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Content>
                                      </List.Item>
                                    )}
                                  </VirtualList>
                                )}
                              </List>
                            </Col>
                          </Row>
                        </Content>
                      ),
                    },
                    {
                      key: '2',
                      label: `종료된 강의`,
                      children: (
                        <Content maxWidth={'100%'} padding={0}>
                          <Row>
                            <Col x={24}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: screenSize >= 768 ? 60 : 40,
                                  padding: '9px 6px',
                                  background: '#D28494',
                                  borderRadius: '4px 4px 0px 0px',
                                }}
                              >
                                <Col x={10} md={6} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    강의명
                                  </Title>
                                </Col>
                                <Col x={0} md={5} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    카테고리
                                  </Title>
                                </Col>
                                <Col x={0} md={5} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    수강종료일
                                  </Title>
                                </Col>
                                <Col x={8} md={5} padding={8}>
                                  <Title
                                    size={screenSize >= 768 ? 4 : 5}
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    수강진행도
                                  </Title>
                                </Col>
                                <Col x={6} md={3} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    시험점수
                                  </Title>
                                </Col>
                              </div>
                            </Col>
                            <Col x={24}>
                              <List>
                                {NewLectureOfUserList?.filter(
                                  (item) => item.ing === false
                                )?.length < 1 ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      minHeight: '400px',
                                      alignItems: 'center',
                                      border: '1px solid #f0f0f0',
                                    }}
                                  >
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                  </div>
                                ) : (
                                  <VirtualList
                                    data={NewLectureOfUserList?.filter(
                                      (item) => item.ing === false
                                    )}
                                    height={screenSize >= 768 ? 440 : 350}
                                    itemHeight={60}
                                    itemKey="title"
                                  >
                                    {(item, index) => (
                                      <List.Item
                                        key={item.lecture_id}
                                        style={{
                                          background:
                                            index % 2 === 0
                                              ? '#FFFFFF'
                                              : '#FAFAFA',
                                        }}
                                      >
                                        <Content
                                          maxWidth={'100%'}
                                          padding={0}
                                          backgroundColor={'none'}
                                        >
                                          <Row>
                                            <Col x={10} md={6} padding={8}>
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize:
                                                    screenSize >= 768 ? 16 : 14,
                                                  lineHeight: '19px',
                                                  display: 'block',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap',
                                                }}
                                              >
                                                {item.title}
                                              </Title>
                                            </Col>
                                            <Col x={0} md={5} padding={8}>
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize: '16px',
                                                  lineHeight: '19px',
                                                }}
                                              >
                                                {item?.category}
                                              </Title>
                                            </Col>
                                            <Col x={0} md={5} padding={8}>
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize: '16px',
                                                  lineHeight: '19px',
                                                }}
                                              >
                                                {moment(item.end_date).format(
                                                  'YYYY-MM-DD'
                                                )}
                                              </Title>
                                            </Col>
                                            <Col
                                              x={8}
                                              md={5}
                                              padding={8}
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize:
                                                    screenSize >= 768 ? 16 : 14,
                                                  lineHeight: '19px',
                                                  width: '55px',
                                                  marginRight: '5px',
                                                }}
                                              >
                                                {item.total_sections_count === 0
                                                  ? '0.0'
                                                  : (
                                                      (item.progress_count /
                                                        item.total_sections_count) *
                                                      100
                                                    ).toFixed(2)}
                                                %
                                              </Title>
                                              {screenSize >= 768 && (
                                                <div
                                                  style={{
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    lineHeight: '19px',
                                                    color: '#000000',
                                                    width: '55px',
                                                    height: '27px',
                                                    marginLeft: '10px',
                                                    borderRadius: '4px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    background:
                                                      (item.progress_count /
                                                        item.total_sections_count) *
                                                        100 ===
                                                      100
                                                        ? '#A4E3AA'
                                                        : (item.progress_count /
                                                            item.total_sections_count) *
                                                            100 ===
                                                            0 ||
                                                          item.total_sections_count ===
                                                            0
                                                        ? '#E0E0E0'
                                                        : '#A4D4E3',
                                                  }}
                                                >
                                                  {(item.progress_count /
                                                    item.total_sections_count) *
                                                    100 ===
                                                  100
                                                    ? '완료'
                                                    : (item.progress_count /
                                                        item.total_sections_count) *
                                                        100 ===
                                                      0
                                                    ? '진행전'
                                                    : '진행중'}
                                                </div>
                                              )}
                                            </Col>
                                            <Col x={6} md={3} padding={8}>
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize:
                                                    screenSize >= 768 ? 16 : 14,
                                                  lineHeight: '19px',
                                                }}
                                              >
                                                {item.exam_score
                                                  ? item.exam_score
                                                  : 0}
                                                점
                                              </Title>
                                            </Col>
                                          </Row>
                                        </Content>
                                      </List.Item>
                                    )}
                                  </VirtualList>
                                )}
                              </List>
                            </Col>
                          </Row>
                        </Content>
                      ),
                    },
                    {
                      key: '3',
                      label: `수료한 강의`,
                      children: (
                        <Content maxWidth={'100%'} padding={0}>
                          <Row>
                            <Col x={24}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: screenSize >= 768 ? 60 : 40,
                                  padding: '9px 6px',
                                  background: '#D28494',
                                  borderRadius: '4px 4px 0px 0px',
                                }}
                              >
                                <Col x={10} md={6} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    강의명
                                  </Title>
                                </Col>
                                <Col x={0} md={5} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      lineHeight: '19px',
                                    }}
                                  >
                                    카테고리
                                  </Title>
                                </Col>
                                <Col x={0} md={5} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: '16px',
                                      lineHeight: '19px',
                                    }}
                                  >
                                    수강종료일
                                  </Title>
                                </Col>
                                <Col x={8} md={5} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    {screenSize >= 768
                                      ? '수료 / 강사자격'
                                      : '수료'}
                                  </Title>
                                </Col>
                                <Col x={6} md={3} padding={8}>
                                  <Title
                                    style={{
                                      color: '#FFFFFF',
                                      fontWeight: 700,
                                      fontSize: screenSize >= 768 ? 16 : 14,
                                      lineHeight: '19px',
                                    }}
                                  >
                                    시험점수
                                  </Title>
                                </Col>
                              </div>
                            </Col>
                            <Col x={24}>
                              <List>
                                {NewLectureOfUserList?.filter(
                                  (item) =>
                                    item.exam_score && item.exam_score >= 80
                                )?.length < 1 ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      minHeight: '400px',
                                      alignItems: 'center',
                                      border: '1px solid #f0f0f0',
                                    }}
                                  >
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                  </div>
                                ) : (
                                  <VirtualList
                                    data={NewLectureOfUserList?.filter(
                                      (item) =>
                                        item.exam_score && item.exam_score >= 80
                                    )}
                                    height={screenSize >= 768 ? 440 : 350}
                                    itemHeight={60}
                                    itemKey="title"
                                  >
                                    {(item, index) => (
                                      <List.Item
                                        key={item.lecture_id}
                                        style={{
                                          background:
                                            index % 2 === 0
                                              ? '#FFFFFF'
                                              : '#FAFAFA',
                                        }}
                                      >
                                        <Content
                                          maxWidth={'100%'}
                                          padding={0}
                                          backgroundColor={'none'}
                                        >
                                          <Row>
                                            <Col x={10} md={6} padding={8}>
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize:
                                                    screenSize >= 768 ? 16 : 14,
                                                  lineHeight: '19px',
                                                  display: 'block',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap',
                                                }}
                                              >
                                                {item.lecture_title}
                                              </Title>
                                            </Col>
                                            <Col x={0} md={5} padding={8}>
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize: '16px',
                                                  lineHeight: '19px',
                                                }}
                                              >
                                                {item?.category}
                                              </Title>
                                            </Col>
                                            <Col x={0} md={5} padding={8}>
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize: '16px',
                                                  lineHeight: '19px',
                                                }}
                                              >
                                                {moment(item.end_date).format(
                                                  'YYYY-MM-DD'
                                                )}
                                              </Title>
                                            </Col>
                                            <Col
                                              x={8}
                                              md={5}
                                              padding={8}
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontSize:
                                                    screenSize >= 768 ? 16 : 12,
                                                  fontWeight: 500,
                                                  lineHeight: '19px',
                                                  color: '#000000',
                                                  width: '78px',
                                                  height: '27px',
                                                  marginLeft: '10px',
                                                  borderRadius: '4px',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  background: 'A4E3AA',
                                                }}
                                              >
                                                <button
                                                  style={{
                                                    background: '#A4E3AA',
                                                    border: 'none',
                                                    width:
                                                      screenSize >= 768
                                                        ? 78
                                                        : 'auto',
                                                    height:
                                                      screenSize >= 768
                                                        ? 27
                                                        : 'auto',
                                                    borderRadius: '4px',
                                                    cursor: 'pointer',
                                                    padding:
                                                      screenSize >= 768
                                                        ? 0
                                                        : '3px 5px',
                                                  }}
                                                  onClick={() =>
                                                    handleCertificate(
                                                      item.major_name,
                                                      item.difficulty_level,
                                                      item.category,
                                                      item.student?.name,
                                                      moment(
                                                        item.student?.birthday
                                                      ).format(
                                                        'YYYY년 MM월 DD일'
                                                      ),
                                                      `br-${moment(
                                                        item.created_at
                                                      )
                                                        ?.valueOf()
                                                        ?.toString()
                                                        ?.slice(-5)}-${moment(
                                                        item.student?.created_at
                                                      )
                                                        ?.valueOf()
                                                        ?.toString()
                                                        ?.slice(-5)}-${
                                                        item.lecture_id
                                                      }${
                                                        item.student?.user_id
                                                      }`,
                                                      moment(
                                                        item.answerExams
                                                          ?.updated_at
                                                      ).format(
                                                        'YYYY년 MM월 DD일'
                                                      )
                                                    )
                                                  }
                                                >
                                                  수료증 발급
                                                </button>
                                              </div>
                                            </Col>
                                            <Col x={6} md={3} padding={8}>
                                              <Title
                                                style={{
                                                  fontWeight: 500,
                                                  fontSize:
                                                    screenSize >= 768 ? 16 : 14,
                                                  lineHeight: '19px',
                                                }}
                                              >
                                                {item.exam_score
                                                  ? item.exam_score
                                                  : 0}
                                                점
                                              </Title>
                                            </Col>
                                          </Row>
                                        </Content>
                                      </List.Item>
                                    )}
                                  </VirtualList>
                                )}
                              </List>
                            </Col>
                          </Row>
                        </Content>
                      ),
                    },
                  ]}
                  tabBarStyle={{
                    marginBottom: screenSize >= 768 ? 32 : 10,
                    fontSize: screenSize >= 768 ? 16 : 14,
                    fontWeight: 400,
                    lineHeight: '19px',
                  }}
                />
              </Col>
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
}

MyLearningPresenter.defualtProps = {};

export default MyLearningPresenter;
