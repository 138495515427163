import React, { useEffect, useState } from 'react';
// import { useState } from 'react';
import { Button, Empty, Pagination } from 'antd';
import { Title, Content, Col, Row, Card } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { STORE_URL } from 'utils';
import MessageAlert from 'utils/MessageAlert';
import { LectureOfUserApi } from 'api';
import { encrypt } from 'utils/Crypto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { getCookie } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';

const pageSize = 10;

const LectureOrdersPresenter = ({
  lectures,
  lectureFiles,
  users,
  lectureOfUsers,
  reviews,
  categories,
  idx,
}) => {
  /* ===== Props ===== */
  const today = new Date();

  /* ===== Mutate ===== */
  /**
   * 수강 업데이트
   * --
   */
  const updateLectureOfUser = LectureOfUserApi.UpdateLectureOfUser({
    onSuccess: (data) => {
      if (data?.status === 200) {
        MessageAlert.success('환불요청이 성공적으로 완료되었습니다');
      } else {
        MessageAlert.error('환불요청에 실패하였습니다');
      }
    },
  });
  /* ===== Router ===== */
  const navigate = useNavigate();
  const location = useLocation();

  /* ===== State ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [currentPage, setCurrentPage] = useState(1);
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const NewLectures = lectures?.map((item) => ({
    ...item,
    lectureFiles:
      lectureFiles &&
      lectureFiles?.filter((subitem) => item.lecture_id === subitem.lecture_id),
    ...(
      users && users?.filter((subitem) => item.user_id === subitem.user_id)
    )?.pop(),
    category: categories?.filter(
      (subitem) => item?.category_id === subitem?.category_id
    )[0]?.category_name,
  }));
  const NewReviews = reviews?.filter((item) => item.user_id === idx);
  // console.log('NewReviews', NewReviews);
  const NewOrderList = lectureOfUsers
    ?.filter((item) => item.user_id === idx)
    .map((subitem) => ({
      ...subitem,
      lecture_of_user_created_at: subitem.created_at,
      ...(
        NewLectures &&
        NewLectures?.filter(
          (lowitem) => subitem.lecture_id === lowitem.lecture_id
        )
      )?.pop(),
      review:
        NewReviews &&
        NewReviews?.filter(
          (lowitem) => subitem.lecture_id === lowitem.lecture_id
        )?.pop(),
      payment_price: subitem?.price,
    }));

  // // 체크박스 기능
  // const [selectLecture, setSelectLecture] = useState([]);
  // const [checkAll, setCheckAll] = useState(false);

  let lectureIdList = [];
  lectureOfUsers?.map((item) => lectureIdList.push(item.lecture_id));

  /* ===== Hooks ===== */
  /**
   * 기관회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);
  /* ===== Functions ===== */

  // /**
  //  * 항목개별선택
  //  * --
  //  */
  // const onChange = (item) => {
  //   const list = selectLecture.includes(item)
  //     ? selectLecture?.filter((e) => e !== item)
  //     : [...selectLecture, item];

  //   setSelectLecture(list);
  //   setCheckAll(list.length === lectureIdList.length);
  // };

  // /**
  //  * 항목전체선택
  //  * --
  //  */
  // const onCheckAllChange = (e) => {
  //   setSelectLecture(e.target.checked ? lectureIdList : []);
  //   setCheckAll(e.target.checked);
  // };

  // /**
  //  * 구매내역 선택 삭제
  //  * --
  //  * @param {*} 선택한 구매내역 value : selectLecture
  //  */
  // const handleListDelete = () => {
  //   if (selectLecture.length === 0) {
  //     MessageAlert.warning('구매내역을 선택해주세요.');
  //     return;
  //   } else if (window.confirm('선택한 구매내역을 삭제하시겠습니까?')) {
  //     try {
  //       lectureOfUsers
  //         .filter((item) => selectLecture.includes(item.lecture_id))
  //         .forEach((subitem) => {
  //           deleteLectureOfUserList.mutate({
  //             lecture_of_user_id: subitem.lecture_of_user_id,
  //           });
  //         });
  //     } catch (e) {
  //       throw e;
  //     }
  //     setSelectLecture([]);
  //     setCheckAll(false);
  //   } else {
  //     console.log('삭제취소:');
  //   }
  // };

  /**
   * 원화 콤마
   * --
   * @param {*} inputValue
   */
  const handleComma = (v) => {
    return [v].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleDetail = (value) => {
    if (window.confirm('강의상세보기로 이동 하시겠습니까?')) {
      navigate(`/lectures/${encodeURIComponent(encrypt(value))}`);
    } else {
      return;
    }
  };

  /**
   * 환불요청 함수
   * --
   * @param {*} id
   */
  const handleCancle = (id) => {
    if (window.confirm('취소/환불 요청 진행하시겠습니까?')) {
      try {
        const idx = parseInt(id);
        updateLectureOfUser.mutate({
          lecture_of_user_id: idx,
          purchase_state: 'R',
        });
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('환불요청에 실패하였습니다');
        throw e;
      }
    }
  };

  const handleWriteReview = (review_id, lecture_id) => {
    const word = review_id
      ? '이미 리뷰를 작성하셨습니다. 수정하시겠습니까?'
      : '리뷰작성 페이지로 이동하시겠습니까?';
    if (window.confirm(word)) {
      //TODO: 리뷰작성 기능
      navigate('/review', {
        state: { from: location, review_id: review_id, lecture_id: lecture_id },
      });
    }
  };

  /* ===== Render ===== */
  return (
    <>
      <Content maxWidth={1200} padding={0}>
        <Row>
          <Col x={24} padding={8}>
            <Title
              size={screenSize >= 768 ? 2 : 4}
              padding={8}
              style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
            >
              구매내역
            </Title>
          </Col>

          <Col x={24}>
            <Content maxWidth={'100%'} padding={'0px'}>
              <Col x={24}>
                {/* 장바구니 리스트 */}
                <Content maxWidth={'100%'} padding={'20px 10px 0 10px'}>
                  {NewOrderList?.length < 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: '400px',
                        alignItems: 'center',
                        border: '1px solid #f0f0f0',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    NewOrderList?.sort((a, b) => {
                      return (
                        new Date(b?.lecture_of_user_created_at) -
                        new Date(a?.lecture_of_user_created_at)
                      );
                    })?.map(
                      (item, index) =>
                        index >= 0 + pageSize * (currentPage - 1) &&
                        index <= 9 + pageSize * (currentPage - 1) && (
                          <Row key={`lecture_list_item_${index}`}>
                            <Col x={24}>
                              <Card
                                style={{ border: 'none', marginBottom: 25 }}
                                bodyStyle={{ padding: 0 }}
                                hoverable={false}
                              >
                                <Title
                                  size={5}
                                  style={
                                    screenSize >= 768
                                      ? { marginBottom: 10 }
                                      : { justifyContent: 'flex-start' }
                                  }
                                >
                                  구매완료
                                  {screenSize < 768 && (
                                    <span
                                      style={{
                                        paddingLeft: 10,
                                        fontWeight: 500,
                                        color: '#9E9E9E',
                                        fontSize: 14,
                                      }}
                                    >
                                      {moment(
                                        item.lecture_of_user_created_at
                                      ).format('YY.MM.DD 결제')}
                                    </span>
                                  )}
                                </Title>
                                <Row style={{ alignItems: 'center' }}>
                                  <Col x={8} md={5}>
                                    {item?.lectureFiles?.length > 0 ? (
                                      <div
                                        className="lecture_card_thumbnail02"
                                        style={{
                                          marginRight: 15,
                                          height: '80px',
                                          border: '1px solid #E0E0E0',
                                          background: `url(${
                                            item?.lectureFiles?.length > 0 &&
                                            `${STORE_URL}/lecture/${
                                              item?.lectureFiles
                                                ?.filter((i) => i.type === 'T')
                                                ?.pop()?.file_url
                                            }`
                                          }) no-repeat center center/contain`,
                                        }}
                                      ></div>
                                    ) : (
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: '50px',
                                          marginRight: 15,
                                          height: '80px',
                                          border: '1px solid #E0E0E0',
                                          width: 'calc(100% - 15px)',
                                        }}
                                        icon={faImage}
                                      />
                                    )}
                                  </Col>
                                  {/*  */}
                                  <Col x={16} md={5}>
                                    <Title
                                      size={4}
                                      style={{
                                        display: 'block',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {item.title}
                                    </Title>
                                    <p style={{ margin: 0, fontSize: '1em' }}>
                                      {item?.category}
                                    </p>
                                    <p
                                      style={{ margin: 0, fontSize: '0.95em' }}
                                    >
                                      {item.name}
                                      {item.nickname
                                        ? '/(' + item.nickname + ')'
                                        : ''}
                                    </p>
                                  </Col>
                                  {/* 결제일 */}
                                  <Col x={0} md={5}>
                                    <div
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '1.3em',
                                          fontWeight: 700,
                                        }}
                                      >
                                        {moment(
                                          item.lecture_of_user_created_at
                                        ).format('YY.MM.DD 결제')}
                                      </p>
                                      <p style={{ margin: 0, fontSize: '1em' }}>
                                        {handleComma(item.payment_price)}
                                        {item?.is_cash === 'Y' ? '원' : 'P'}
                                      </p>
                                    </div>
                                  </Col>
                                  {/* //결제일 */}
                                  {/* 강의 상세보기 */}
                                  <Col
                                    style={{
                                      marginTop: screenSize < 768 && 10,
                                    }}
                                    x={item?.product_id !== 0 ? 24 : 0}
                                    md={4}
                                  >
                                    <Button
                                      type="link"
                                      style={
                                        screenSize >= 768
                                          ? {
                                              color: '#E3A4AF',
                                              fontWeight: 'bold',
                                            }
                                          : {
                                              color: '#E3A4AF',
                                              fontWeight: 'bold',
                                              border: '1px solid #E3A4AF',
                                              width: '100%',
                                            }
                                      }
                                      onClick={() =>
                                        handleDetail(item.lecture_id)
                                      }
                                    >
                                      강의상세보기
                                    </Button>
                                  </Col>
                                  {/* //강의 상세보기 */}
                                  <Col
                                    x={24}
                                    md={5}
                                    style={{
                                      marginTop: screenSize < 768 && 5,
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width:
                                            screenSize >= 768 ? '50%' : '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Button
                                          style={{
                                            color: '#FFFFFF',
                                            background: '#E3A4AF',
                                            fontWeight: 'bold',
                                            border: '1px solid #E3A4AF',
                                            width:
                                              screenSize >= 768
                                                ? 'auto'
                                                : '100%',
                                          }}
                                          onClick={() =>
                                            item.questionnaire?.length > 0
                                              ? handleWriteReview(
                                                  item.review?.review_id,
                                                  item.lecture_id
                                                )
                                              : MessageAlert.warning(
                                                  '작성할 수 있는 질문지가 없습니다.'
                                                )
                                          }
                                        >
                                          리뷰 작성
                                        </Button>
                                      </div>
                                      {today -
                                        new Date(
                                          item?.lecture_of_user_created_at
                                        ) <=
                                        7 * 24 * 60 * 60 * 1000 &&
                                      (!item?.progress ||
                                        item?.progress?.length === 0) &&
                                      item?.payment_price !== 0 ? (
                                        <div
                                          style={{
                                            width:
                                              screenSize >= 768
                                                ? '50%'
                                                : '100%',
                                            display: 'flex',
                                            justifyContent:
                                              screenSize >= 768
                                                ? 'flex-end'
                                                : 'center',
                                            alignItems: 'center',
                                            marginTop:
                                              screenSize >= 768 ? 0 : 5,
                                          }}
                                        >
                                          {screenSize < 768 && (
                                            <span style={{ paddingRight: 5 }}>
                                              환불상태 :
                                            </span>
                                          )}
                                          {item?.purchase_state &&
                                          item?.purchase_state === 'R' ? (
                                            <Title
                                              style={{
                                                fontSize: '14px',
                                                color: '#03A8F4',
                                              }}
                                            >
                                              확인중
                                            </Title>
                                          ) : item?.purchase_state === 'C' ? (
                                            <Title
                                              style={{
                                                fontSize: '14px',
                                                color: '#FB4E4E',
                                              }}
                                            >
                                              환불 완료
                                            </Title>
                                          ) : (
                                            <Button
                                              block
                                              type="primary"
                                              style={{
                                                background: '#AB5476',
                                                border: 'none',
                                              }}
                                              onClick={() =>
                                                handleCancle(
                                                  item?.lecture_of_user_id
                                                )
                                              }
                                            >
                                              환불 요청
                                            </Button>
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </Row>
                        )
                    )
                  )}
                  <Row>
                    <Col x={24}>
                      <Pagination
                        current={currentPage}
                        total={NewOrderList?.length}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        pageSize={pageSize}
                        onChange={(e) => setCurrentPage(e)}
                      />
                    </Col>
                  </Row>
                </Content>
              </Col>
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
};

LectureOrdersPresenter.defualtProps = {};

export default LectureOrdersPresenter;
