import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 이벤트 참여 고객 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetEventOfUsers: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getEventOfUsers', params] : 'getEventOfUsers',
        async () => await $http.get(APIConstant.GET_EVENT_OF_USERS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 이벤트 참여 고객 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetEventOfUser: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_EVENT_OF_USER, {
        event_of_user_id: idx,
      });
      const res = useQuery(
        params ? ['getEventOfUser', params] : 'getEventOfUser',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 이벤트 참여 고객 생성
   * --
   * @method POST
   * @returns
   */
  CreateEventOfUser: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(
            APIConstant.CREATE_EVENT_OF_USER,
            data
          );
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getEventOfUsers');
            queryClient.invalidateQueries('getEventOfUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 이벤트 참여 고객 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateEventOfUser: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_EVENT_OF_USER, {
            event_of_user_id: data.event_of_user_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getEventOfUsers');
            queryClient.invalidateQueries('getEventOfUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 이벤트 참여 고객 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteEventOfUser: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_EVENT_OF_USER, {
            event_of_user_id: data.event_of_user_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getEventOfUsers');
            queryClient.invalidateQueries('getEventOfUser');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
