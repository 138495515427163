import React, { useState, useEffect } from 'react';
import { Content, Row, Col, Title } from 'components';
import { useNavigate } from 'react-router-dom';
import { Tabs, List } from 'antd';
import VirtualList from 'rc-virtual-list';
import moment from 'moment';
import { encrypt } from 'utils/Crypto';
import { getCookie } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';
import MessageAlert from 'utils/MessageAlert';

const EventListPresenter = (props) => {
  /* ===== Props ===== */
  const { events, screenSize } = props;

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== State ===== */
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const [proceedingEvents, setProceedingEvents] = useState([]);
  const [endEvents, setEndEvents] = useState([]);

  /* ===== Mutate ===== */

  /* ===== Hooks ===== */
  /**
   * 이벤트 아이템 정제
   * --
   */
  useEffect(() => {
    if (events) {
      const today = new Date();
      let ing = [],
        end = [];

      events?.forEach((item) => {
        if (new Date(item?.end_date) < today) {
          end.push(item);
        } else {
          ing.push(item);
        }
      });

      setProceedingEvents(ing);
      setEndEvents(end);
    }
  }, [events]);

  /**
   * 기관회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);
  // console.log('proceedingEvents', proceedingEvents);
  // console.log('endEvents', endEvents);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <Content maxWidth={'100%'} padding={'0 12px'}>
      <Row>
        <Col x={24} padding={8}>
          <Title size={2} padding={8} style={{ marginBottom: '28px' }}>
            참여 이벤트
          </Title>
        </Col>
        <Col x={24}>
          <Content
            maxWidth={'100%'}
            padding={screenSize >= 768 ? '24px 24px 0' : '10px 10px 0'}
            style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
          >
            <Col x={24}>
              <Tabs
                size={screenSize >= 768 ? 'large' : 'small'}
                defaultActiveKey="1"
                items={[
                  {
                    key: '1',
                    label: `진행중 이벤트`,
                    children: (
                      <Content maxWidth={'100%'} padding={0}>
                        <Row>
                          <Col x={24}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: screenSize >= 768 ? 60 : 50,
                                padding: '9px 6px',
                                background: '#D28494',
                                borderRadius: '4px 4px 0px 0px',
                              }}
                            >
                              <Col x={8} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  이벤트명
                                </Title>
                              </Col>
                              <Col x={0} md={6} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  참여일
                                </Title>
                              </Col>
                              <Col x={16} md={10}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  기간
                                </Title>
                              </Col>
                            </div>
                          </Col>
                          <Col x={24}>
                            {proceedingEvents &&
                            proceedingEvents?.length > 0 ? (
                              <List>
                                <VirtualList
                                  data={proceedingEvents}
                                  height={440}
                                  itemHeight={60}
                                  itemKey="event_id"
                                >
                                  {(item, index) => (
                                    <List.Item
                                      key={`event-${item?.event_id}`}
                                      style={{
                                        background:
                                          index % 2 === 0
                                            ? '#FFFFFF'
                                            : '#FAFAFA',
                                      }}
                                    >
                                      <Content
                                        maxWidth={'100%'}
                                        padding={0}
                                        backgroundColor={'none'}
                                      >
                                        <Row>
                                          <Col x={8} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                              }}
                                            >
                                              <p
                                                style={{
                                                  ...{
                                                    margin: 0,
                                                    cursor: 'pointer',
                                                    wordWrap: 'break-word',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                  },
                                                }}
                                                onClick={() =>
                                                  navigate(
                                                    `/events/${encodeURIComponent(
                                                      encrypt(item?.event_id)
                                                    )}`
                                                  )
                                                }
                                              >
                                                {item.event_title}
                                              </p>
                                            </Title>
                                          </Col>
                                          <Col x={0} md={6} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {moment(item?.crated_at).format(
                                                'YYYY.MM.DD'
                                              )}
                                            </Title>
                                          </Col>
                                          <Col x={16} md={10} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {screenSize >= 768
                                                ? `${moment(
                                                    item?.start_date
                                                  ).format(
                                                    'YYYY.MM.DD HH:mm'
                                                  )} ~ ${moment(
                                                    item?.end_date
                                                  ).format('YYYY.MM.DD HH:mm')}`
                                                : `${moment(
                                                    item?.start_date
                                                  ).format(
                                                    'YYYY.MM.DD'
                                                  )} ~ ${moment(
                                                    item?.end_date
                                                  ).format('YYYY.MM.DD')}`}
                                            </Title>
                                          </Col>
                                        </Row>
                                      </Content>
                                    </List.Item>
                                  )}
                                </VirtualList>
                              </List>
                            ) : (
                              <Content
                                maxWidth={'100%'}
                                height={500}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Title>참여한 이벤트가 없습니다</Title>
                              </Content>
                            )}
                          </Col>
                        </Row>
                      </Content>
                    ),
                  },
                  {
                    key: '2',
                    label: `종료된 이벤트`,
                    children: (
                      <Content maxWidth={'100%'} padding={0}>
                        <Row>
                          <Col x={24}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: screenSize >= 768 ? 60 : 50,
                                padding: '9px 6px',
                                background: '#D28494',
                                borderRadius: '4px 4px 0px 0px',
                              }}
                            >
                              <Col x={8} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  이벤트명
                                </Title>
                              </Col>
                              <Col x={0} md={6} padding={8}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  참여일
                                </Title>
                              </Col>
                              <Col x={16} md={10}>
                                <Title
                                  style={{
                                    color: '#FFFFFF',
                                    fontWeight: 700,
                                    fontSize: screenSize >= 768 ? 16 : 14,
                                    lineHeight: '19px',
                                  }}
                                >
                                  기간
                                </Title>
                              </Col>
                            </div>
                          </Col>
                          <Col x={24}>
                            {endEvents && endEvents?.length > 0 ? (
                              <List>
                                <VirtualList
                                  data={endEvents}
                                  height={440}
                                  itemHeight={60}
                                  itemKey="event_id"
                                >
                                  {(item, index) => (
                                    <List.Item
                                      key={`event-${item?.event_id}`}
                                      style={{
                                        background:
                                          index % 2 === 0
                                            ? '#FFFFFF'
                                            : '#FAFAFA',
                                      }}
                                    >
                                      <Content
                                        maxWidth={'100%'}
                                        padding={0}
                                        backgroundColor={'none'}
                                      >
                                        <Row>
                                          <Col x={8} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                              }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  cursor: 'pointer',
                                                  wordWrap: 'break-word',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap',
                                                }}
                                                onClick={() =>
                                                  navigate(
                                                    `/events/${encodeURIComponent(
                                                      encrypt(item?.event_id)
                                                    )}`
                                                  )
                                                }
                                              >
                                                {item.event_title}
                                              </p>
                                            </Title>
                                          </Col>
                                          <Col x={0} md={6} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize: '16px',
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {moment(item?.crated_at).format(
                                                'YYYY.MM.DD'
                                              )}
                                            </Title>
                                          </Col>
                                          <Col x={16} md={10} padding={8}>
                                            <Title
                                              style={{
                                                fontWeight: 500,
                                                fontSize:
                                                  screenSize >= 768 ? 16 : 14,
                                                lineHeight: '19px',
                                              }}
                                            >
                                              {screenSize >= 768
                                                ? `${moment(
                                                    item?.start_date
                                                  ).format(
                                                    'YYYY.MM.DD HH:mm'
                                                  )} ~ ${moment(
                                                    item?.end_date
                                                  ).format('YYYY.MM.DD HH:mm')}`
                                                : `${moment(
                                                    item?.start_date
                                                  ).format(
                                                    'YYYY.MM.DD'
                                                  )} ~ ${moment(
                                                    item?.end_date
                                                  ).format('YYYY.MM.DD')}`}
                                            </Title>
                                          </Col>
                                        </Row>
                                      </Content>
                                    </List.Item>
                                  )}
                                </VirtualList>
                              </List>
                            ) : (
                              <Content
                                maxWidth={'100%'}
                                height={500}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Title>참여한 이벤트가 없습니다</Title>
                              </Content>
                            )}
                          </Col>
                        </Row>
                      </Content>
                    ),
                  },
                ]}
                tabBarStyle={{
                  marginBottom: screenSize >= 768 ? 32 : 10,
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '19px',
                }}
              />
            </Col>
          </Content>
        </Col>
      </Row>
    </Content>
  );
};

EventListPresenter.defualtProps = {};

export default EventListPresenter;
