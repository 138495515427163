/**
 * @title 디바운스 처리
 * @param { value: input에 입력된 값, delay: delay 해줄 시간 }
 */
import { useEffect, useState } from 'react';

const UseDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [value]);

  return debouncedValue;
};

export default UseDebounce;
