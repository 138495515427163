/**
 * BoardListPresenter
 * --
 */
import React, { useState, useRef, useEffect } from 'react';
import { Content, Row, Col, Title, Button as Btn } from 'components';
import { Input, List, Avatar, Table, Space, Button, Select, Tag } from 'antd';
import { encrypt } from 'utils/Crypto';
import { STORE_URL, DOMAIN_URL } from 'utils';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const BoardListPresenter = (props) => {
  /* ===== ROUTE ===== */
  /* ===== PROPS ===== */
  const {
    lectureId,
    filterLectureList,
    searchValue,
    setSearchValue,
    selectedLectureId,
    currentPage,
    navigate,
    onPagination,
    data,
    filterOptions,
    filterValue,
    setFilterValue,
    onDetailPopup,
  } = props;
  /* ===== STATE ===== */
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [boardData, setBoardData] = useState([]);

  /* ===== VARIABLE ===== */
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${title}을(를) 입력해주세요`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            검색
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            초기화
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '게시판',
      dataIndex: 'type',
      key: 'type',
      render: (_, { type }) => (
        <Tag
          color={
            type === 'INQUIRY'
              ? 'blue'
              : type === 'DISCUSSION'
              ? 'green'
              : 'red'
          }
        >
          {
            filterOptions
              ?.find((item) => item?.value === type)
              .label.split('게시판')[0]
          }
        </Tag>
      ),
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      ...getColumnSearchProps('title', '제목'),
      render: (_, { title, task_status, type }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
          align={'left'}
        >
          {type === 'ASSIGNMENT' && (
            <span style={{ paddingRight: 5 }}>
              {task_status === 'PASS' ? (
                <Tag color="#2AC769">통과</Tag>
              ) : task_status === 'FAIL' ? (
                <Tag color="#FB4E4E">불통</Tag>
              ) : (
                <Tag color="#E0E0E0">확인중</Tag>
              )}
            </span>
          )}
          {title}
        </Title>
      ),
    },
    {
      title: '글쓴이',
      dataIndex: 'writer',
      key: 'writer',
      ...getColumnSearchProps('writer', '글쓴이'),
      render: (_, item) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            width={50}
            height={50}
            preview={false}
            src={
              item?.profile
                ? `${STORE_URL}/users/${item?.user_id}/${item?.profile?.file_url}`
                : item?.name
            }
          />
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            <p style={{ margin: 0 }}>{item?.writer}</p>
          </Title>
        </Content>
      ),
    },
    {
      title: '댓글',
      dataIndex: 'comment',
      key: 'comment',
      render: (_, item) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            <p style={{ margin: 0 }}>{item?.comment.length}</p>
          </Title>
        </Content>
      ),
    },
    {
      title: '등록일',
      dataIndex: 'board_created_at',
      key: 'board_created_at',
      sorter: (a, b) =>
        new Date(b?.board_created_at) - new Date(a?.board_created_at),
      render: (_, { board_created_at }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {moment(board_created_at)?.format('YYYY-MM-DD HH:mm')}
        </Title>
      ),
    },
    {
      title: '수정일',
      dataIndex: 'board_updated_at',
      key: 'board_updated_at',
      sorter: (a, b) =>
        new Date(b?.board_updated_at) - new Date(a?.board_updated_at),
      render: (_, { board_updated_at, board_created_at }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {board_updated_at !== board_created_at
            ? moment(board_updated_at)?.format('YYYY-MM-DD HH:mm')
            : '-'}
        </Title>
      ),
    },
  ];

  /* ===== FUNCTION ===== */
  /**
   * 게시판 팝업
   * --
   */
  const handlePopup = (e, item) => {
    e.stopPropagation();
    const domain = DOMAIN_URL;

    window.open(
      `${domain}/#/popup/board?lecture=${encodeURIComponent(
        encrypt(item?.lecture_id)
      )}&type=inquiry`,
      '팝업',
      'width=1500, height=850'
    );
  };

  /* ===== HOOKS ===== */
  /**
   * 게시판 데이터 필터
   * --
   */
  useEffect(() => {
    const d = data?.board?.sort(
      (a, b) => new Date(b?.board_created_at) - new Date(a?.board_created_at)
    );

    const newData = d?.filter((item) =>
      filterValue === 'ALL' ? item : item?.type === filterValue
    );
    setBoardData(newData);
  }, [data, filterValue]);

  /* ===== RENDER ===== */
  return (
    <Content padding={0} maxWidth={'100%'} backgroundColor={'none'}>
      <Row>
        <Col x={6} style={{ padding: 5 }}>
          <Content maxWidth={'100%'} className={'admin_container'}>
            <Title>강의목록</Title>
            <Input
              size="large"
              placeholder="강의명을 입력해주세요"
              style={{ marginTop: 30 }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Content maxWidth={'100%'} padding={0} style={{ marginTop: 30 }}>
              <Row>
                <Col x={24}>
                  <List
                    style={{
                      background: '#F5F5F5',
                      border: '1px solid #E0E0E0',
                      borderRadius: 5,
                      height: 1000,
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                    }}
                  >
                    {filterLectureList?.map((item) => (
                      <div
                        key={`lecture-${item?.lecture_id}`}
                        onClick={() =>
                          navigate(
                            `/admin/board?page=1&lecture=${encodeURIComponent(
                              encrypt(item?.lecture_id)
                            )}`
                          )
                        }
                      >
                        <Row
                          style={{
                            borderBottom: '1px solid #E0E0E0',
                            padding: 10,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            position: 'relative',
                            top: 0,
                            left: 0,
                          }}
                        >
                          {parseInt(selectedLectureId) ===
                          parseInt(item?.lecture_id) ? (
                            <div
                              style={{
                                position: 'absolute',
                                top: `50%`,
                                left: `50%`,
                                width: '98%',
                                height: '95%',
                                background: '#F2D3DB',
                                border: '1px solid #AB6576',
                                borderRadius: 5,
                                transform: `translate(-50%, -50%)`,
                              }}
                            ></div>
                          ) : null}

                          <Col x={4}>
                            <Avatar
                              shape="square"
                              size={50}
                              src={
                                item?.thumbnail &&
                                `${STORE_URL}/lecture/${item?.thumbnail?.file_url}`
                              }
                            ></Avatar>
                          </Col>
                          <Col x={20}>
                            <p
                              style={{
                                color: '#9E9E9E',
                                fontSize: 20,
                                textAlign: 'left',
                              }}
                            >
                              {item?.title}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </List>
                </Col>
              </Row>
            </Content>
          </Content>
        </Col>
        <Col x={18}>
          <Row>
            <Col x={24} style={{ padding: 5 }}>
              <Content
                maxWidth={'100%'}
                className={'admin_container'}
                style={{ height: 1200 }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 30,
                  }}
                >
                  <Select
                    size="large"
                    style={{ width: 300 }}
                    options={filterOptions}
                    value={filterValue}
                    onChange={(e) => setFilterValue(e)}
                  />
                  <Btn
                    layoutStyle={{ margin: 0 }}
                    onClick={(e) => handlePopup(e, lectureId)}
                  >
                    게시판
                  </Btn>
                </div>
                <Table
                  columns={columns}
                  dataSource={boardData}
                  showSorterTooltip={false}
                  pagination={{
                    pageSize: 15,
                    position: ['bottomCenter'],
                    current: currentPage,
                    onChange: (e) => onPagination(e),
                  }}
                  onRow={(record) => {
                    return {
                      onClick: (e) => onDetailPopup(e, record),
                      style: {
                        cursor: 'pointer',
                      },
                    };
                  }}
                />
              </Content>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

export default BoardListPresenter;
