import React from 'react';
import './EventCard.style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const EventCard = ({ thumbnail, status, title, start, end }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  // console.log(teacher);
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== STYLES ===== */
  const styles = {
    thumbnail: {
      position: 'relative',
      top: 0,
      left: 0,
      width: '100%',
      height: '223px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E0E0E0',
    },
    event: {
      position: 'absolute',
      bottom: '12px',
      right: '12px',
      width: '60px',
      height: '60px',
      background: '#FFFFFF',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #EEEEEE',
      color:
        status === 'ing' ? '#0B8EA9' : status === 'end' ? '#FE4279' : '#000',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '17px',
      textTransform: 'capitalize',
    },
    category: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.02em',
      textTransform: 'capitalize',
      color: '#000000',
      marginTop: '12px',
    },
    title: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: '0.02em',
      textTransform: 'capitalize',
      color: '#000000',
      margin: '6px 0 14px',
    },
    content: {
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.02em',
      textTransform: 'capitalize',
      color: '#000000',
    },
    subList: {
      display: 'flex',
    },
  };
  /* ===== RENDER ===== */
  return (
    <div className="event_card_layout">
      <div
        className="event_card_thumbnail"
        style={{
          ...styles.thumbnail,
          background: thumbnail
            ? `url(${thumbnail}) no-repeat center/cover`
            : '#D9D9D9',
        }}
      >
        {thumbnail ? null : (
          <FontAwesomeIcon style={{ fontSize: '50px' }} icon={faImage} />
        )}
        {status ? (
          <div className="event_card_event" style={{ ...styles.event }}>
            {status === 'ing'
              ? '진행중'
              : status === 'end'
              ? '종료'
              : '진행예정'}
          </div>
        ) : null}
      </div>
      <ul>
        <li>
          <p style={{ ...styles.category }}>
            {moment(start)?.format('YYYY-MM-DD')} ~{' '}
            {moment(end)?.format('YYYY-MM-DD')}
          </p>
        </li>
        <li>
          <p style={{ ...styles.title }}>{title}</p>
        </li>
      </ul>
    </div>
  );
};

EventCard.defaultProps = {
  thumbnail: undefined,
  status: 'ing',
  title: '이벤트',
};

export default EventCard;
