import React, { useState, useEffect } from 'react';
import { Input, DatePicker, Switch } from 'antd';
import { Content, Row, Col, Title, TextEditor, Button } from 'components';
import MessageAlert from 'utils/MessageAlert';
import { EventApi } from 'api';
import dayjs from 'dayjs';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { encrypt } from 'utils/Crypto';

const { RangePicker } = DatePicker;

const EventRegisterPresenter = (props) => {
  /* ===== Props ===== */
  const { data, type } = props;

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== Mutate ===== */
  const createEvent = EventApi.CreateEvent();
  const updateEvent = EventApi.UpdateEvent();

  /* ===== State ===== */
  const [inputData, setInputData] = useState({
    event_title: undefined,
    event_content: undefined,
    start_date: undefined,
    end_date: undefined,
    is_show: 'Y',
  });
  const [htmlString, setHtmlString] = useState(undefined);

  /* ===== Hooks ===== */
  /**
   * 이벤트 수정 데이터 바인딩
   * --
   */
  useEffect(() => {
    if (type === 'update' && data) {
      const d = data;

      setInputData({
        ...d,
      });
    }
  }, [type, data]);

  /* ===== Functions ===== */
  /**
   * RangeDate 데이터 바인딩 함수
   * --
   * @param {*} dates
   * @param {*} dateStrings
   */
  const dateChange = (dates, dateStrings) => {
    const d = { ...inputData };
    d['start_date'] = dateStrings[0];
    d['end_date'] = dateStrings[1];

    setInputData(d);
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...inputData };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setInputData(d);

    if (callback) callback(v);
  };

  /**
   * 이벤트 생성/수정 함수
   * FIXME: 이미지 2개 업로드 시 Data too long for column 'event_content' at row 1 에러 발생
   * --
   * @returns
   */
  const handleSubmit = () => {
    const { event_title, start_date, end_date } = inputData;

    if (!event_title || event_title === '') {
      MessageAlert.warning('이벤트 타이틀을 입력해주세요');
      return;
    }

    if (!start_date || start_date === '') {
      MessageAlert.warning('이벤트 시작일을 선택해주세요');
      return;
    }

    if (!end_date || end_date === '') {
      MessageAlert.warning('이벤트 종료일을 선택해주세요');
      return;
    }

    if (!htmlString || htmlString === '') {
      MessageAlert.warning('이벤트 내용을 입력해주세요');
      return;
    }

    if (type === 'create') {
      const confirm = window.confirm('이벤트를 생성하시겠습니까?');
      if (confirm) {
        try {
          createEvent.mutate({
            ...inputData,
            content: htmlString,
          });

          MessageAlert.success('이벤트가 생성되었습니다');
          navigate('/admin/event-list');
        } catch (e) {
          MessageAlert.error('이벤트 생성에 실패하였습니다');
          console.log('Error !!', e);
          throw e;
        }
      }
    } else {
      const confirm = window.confirm('이벤트를 수정하시겠습니까?');
      if (confirm) {
        const { created_at, ...d } = inputData;
        try {
          updateEvent.mutate({
            ...d,
            content: htmlString,
          });

          MessageAlert.success('이벤트가 정상적으로 수정되었습니다');
          navigate(
            `/admin/event-list/${encodeURIComponent(encrypt(d.event_id))}`
          );
        } catch (e) {
          MessageAlert.error('이벤트 수정에 실패하였습니다');
          console.log('Error !!', e);
          throw e;
        }
      }
    }
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={24} maxWidth={1500} className={'admin_container'}>
        <Row>
          <Col x={24} padding={0}>
            <Title size={2} padding={8} style={{ marginBottom: '28px' }}>
              {type === 'create' ? '이벤트 등록' : '이벤트 수정'}
            </Title>
          </Col>
          <Col x={24}>
            {/* 이벤트 타이틀 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="lecture_title"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  이벤트 타이틀
                </label>
              </Col>
              <Col x={18}>
                <Input
                  id="lecture_title"
                  placeholder="타이틀을 입력해주세요"
                  size="large"
                  style={{ marginBottom: 10 }}
                  name="event_title"
                  value={inputData?.event_title}
                  onChange={(e) => handleChange('event_title', e.target.value)}
                  maxLength={100}
                />
              </Col>
            </Row>
            {/* 시작일/종료일 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="lecture_start_date"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  이벤트 기간
                </label>
              </Col>
              <Col x={18}>
                <RangePicker
                  size="large"
                  style={{ width: '100%' }}
                  popupStyle={{ zIndex: 3600 }}
                  format={'YYYY-MM-DD HH:mm'}
                  placeholder={[
                    '시작일을 입력해주세요',
                    '종료일을 입력해주세요',
                  ]}
                  minuteStep={10}
                  value={
                    inputData?.start_date &&
                    inputData?.end_date && [
                      moment(inputData?.start_date, 'YYYY-MM-DD HH:mm'),
                      moment(inputData?.end_date, 'YYYY-MM-DD HH:mm'),
                    ]
                  }
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue:
                      inputData?.start_date && inputData?.end_date
                        ? [
                            dayjs(inputData?.start_date, 'YYYY-MM-DD HH:mm'),
                            dayjs(inputData?.end_date, 'YYYY-MM-DD HH:mm'),
                          ]
                        : [null, null],
                  }}
                  onChange={dateChange}
                  inputReadOnly
                />
              </Col>
            </Row>
            {/* 노출여부 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={6}>
                <label
                  htmlFor="lecture_start_date"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                  }}
                >
                  노출여부
                </label>
              </Col>
              <Col x={18}>
                <Switch
                  size="large"
                  checked={inputData?.is_show === 'Y' ? true : false}
                  onChange={(checked) =>
                    handleChange('is_show', checked ? 'Y' : 'N')
                  }
                />
              </Col>
            </Row>
            {/* 내용 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
              }}
            >
              <Col x={24}>
                <label
                  htmlFor="lecture_start_date"
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    marginBottom: '10px',
                    display: 'inline-block',
                  }}
                >
                  내용
                </label>
                <TextEditor
                  data={inputData?.event_content}
                  setHtmlString={setHtmlString}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col x={24}>
            <Button
              style={{
                width: '282px',
                height: '56px',
                fontSize: '20px',
                fontWeight: 700,
                padding: 0,
                margin: '50px auto 0',
              }}
              onClick={() => handleSubmit()}
            >
              {type === 'create' ? '등록' : '수정'}
            </Button>
          </Col>
        </Row>
      </Content>
    </>
  );
};

EventRegisterPresenter.defaultProps = {};

export default EventRegisterPresenter;
