import React from 'react';
import { Progress } from 'antd';
import './Progress.style.css';

const ProgressComp = ({
  type,
  percent,
  showInfo,
  status,
  strokeColor,
  trailColor,
  format,
  size,
  steps,
  success,
}) => {
  /* ===== STATE ===== */
  /* ===== FUNCTION ===== */
  /* ===== STYLE ===== */
  /* ===== RENDER ===== */
  /* ===== RENDER ===== */
  return (
    <Progress
      type={type}
      percent={percent}
      showInfo={showInfo}
      status={status}
      strokeColor={{
        ...strokeColor,
      }}
      trailColor={trailColor}
      format={format}
      size={size}
      steps={type === 'line' && steps}
      success={{ ...success }}
    />
  );
};

ProgressComp.defaultProps = {
  type: 'line',
  percent: 0,
  showInfo: true,
  status: 'normal',
  strokeColor: {
    '0%': '#AB5476',
    '100%': '#AB5476',
  },
  trailColor: '#FFFFFF',
  format: (percent) => `${percent}%`,
  size: 'default',
};

export default ProgressComp;
