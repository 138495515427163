import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 게시판 댓글 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetComments: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getComments', params] : 'getComments',
        async () => await $http.get(APIConstant.GET_COMMENTS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 게시판 댓글 상세조회
   * --
   * @method GET
   * @returns
   */
  GetComment: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_COMMENT, {
        comment_id: idx,
      });
      const res = useQuery(
        params ? ['getComment', params] : 'getComment',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 게시판 댓글 생성
   * --
   * @method POST
   * @returns
   */
  CreateComment: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_COMMENT, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getComments');
            queryClient.invalidateQueries('getComment');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 게시판 댓글 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateComment: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_COMMENT, {
            comment_id: data.comment_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getComments');
            queryClient.invalidateQueries('getComment');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 게시판 댓글 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteComment: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_COMMENT, {
            comment_id: data.comment_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getComments');
            queryClient.invalidateQueries('getComment');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
