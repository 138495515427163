import React, { useState, useEffect } from 'react';
import ProgramPresenter from './ProgramPresenter';

const ProgramContainer = ({ data }) => {
  /* ===== PROPS ===== */
  const { categories, programs } = data;

  /* ===== STATE ===== */
  const [newData, setNewData] = useState({});

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  useEffect(() => {
    if (data) {
      const m = categories?.filter((item) => item?.type === 'M');
      const d = categories?.filter((item) => item?.type === 'D');
      const newProgram = programs?.map((item) => ({
        ...item,
        ...(categories &&
          categories?.filter(
            (subitem) => item?.category_id === subitem?.category_id
          )[0]),
      }));

      const Data = {
        major: m,
        major_detail: d,
        programs: newProgram?.map((item) => ({
          ...item,
          key: item.program_id,
          parents_name: categories?.filter(
            (subitem) => subitem?.category_id === item?.parents_id
          )[0]?.category_name,
        })),
      };

      setNewData(Data);
    }
  }, [data, categories, programs]);

  /* ===== RENDER ===== */
  return (
    <ProgramPresenter
      major={newData?.major}
      majorDetail={newData?.major_detail}
      programs={newData?.programs}
    />
  );
};

export default ProgramContainer;
