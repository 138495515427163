import React from 'react';
import { Content, Row, Col, Title } from 'components';
import { Descriptions } from 'antd';

const Location = ({ screenSize }) => {
  return (
    <Content maxWidth={'100%'}>
      <Content
        maxWidth={1200}
        padding={screenSize >= 768 ? '120px 0' : '60px 0'}
      >
        <Row>
          <Col x={24}>
            <Title size={screenSize >= 768 ? 1 : 3}>찾아오시는길</Title>
            <div style={{ marginTop: 30 }}>
              <iframe
                title="찾아오시는길"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3159.331989951576!2d126.68141837733216!3d37.6413982720191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c872989b288d1%3A0xae5f038aeb6c9fbe!2z6rK964-Z66-466W07Juw7Iuc7YuwIOyngOyLneyCsOyXheyEvO2EsA!5e0!3m2!1sko!2skr!4v1713406667515!5m2!1sko!2skr"
                width="100%"
                height={screenSize >= 768 ? 600 : 450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <Descriptions
              style={{ marginTop: 30 }}
              size={'default'}
              bordered
              column={1}
            >
              <Descriptions.Item
                label="전화"
                labelStyle={{ whiteSpace: 'nowrap' }}
              >
                070-7701-7077
              </Descriptions.Item>
              <Descriptions.Item label="주소">
                경기도 김포시 태장로 741, 경동미르웰시티 540호
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default Location;
