import React, { useEffect } from 'react';
import './AuthFail.style.css';

const AuthFail = () => {
  useEffect(() => {
    window.alert('본인인증에 실패하였습니다! 다시 시도해주세요');
    window.close();
  }, []);

  return (
    <div>
      <div></div>
    </div>
  );
};

export default AuthFail;
