import React from 'react';
import LectureJournalPresenter from './LectureJournalPresenter';

const LectureJournalContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return <LectureJournalPresenter />;
};

LectureJournalContainer.defaultProps = {};

export default LectureJournalContainer;
