import React, { useState, useEffect, useRef } from 'react';
import { Content, Row, Col, Title, ModalLayout, Button } from 'components';
import { InputNumber, Button as Btn, Input, Avatar, Table, Space } from 'antd';
import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import MessageAlert from 'utils/MessageAlert';
import APIConstant from '../../../api/APIConstant';
import APIManager from '../../../api/APIManager';
import { UserApi, AgencyApi, PurchaseApi } from 'api';
import Highlighter from 'react-highlight-words';
import { STORE_URL } from 'utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
const $http = new APIManager();

const PointOrdersPresenter = (props) => {
  /* ===== Props ===== */
  const { userList } = props;
  /* ===== Router ===== */
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const date = searchParams.get('date');

  /* ===== Mutate ===== */
  const updateUser = UserApi.UpdateUser({
    onSuccess: (data) => {
      if (data?.status === 200) {
        MessageAlert.success('수정되었습니다.');
      } else {
        MessageAlert.error('수정에 실패하였습니다');
      }
    },
    onError: () => {
      MessageAlert.error('수정에 실패하였습니다');
    },
  });
  const updateAgency = AgencyApi.UpdateAgency({
    onSuccess: (data) => {
      if (data?.status === 200) {
        MessageAlert.success('수정되었습니다.');
      } else {
        MessageAlert.error('수정에 실패하였습니다');
      }
    },
    onError: () => {
      MessageAlert.error('수정에 실패하였습니다');
    },
  });
  const updatePurchase = PurchaseApi.UpdatePurchase({
    onSuccess: (data) => {
      if (data?.status === 200) {
        MessageAlert.success('수정되었습니다.');
      } else {
        MessageAlert.error('수정에 실패하였습니다');
      }
    },
    onError: (data) => {
      MessageAlert.error('수정에 실패하였습니다');
    },
  });

  /* ===== State ===== */
  const [, setNewData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  /* ===== Hooks ===== */
  /**
   * 정산 목록 불러오기
   * --
   */
  useEffect(() => {
    const refetch = async () => {
      const { status, data } = await $http.get(`${APIConstant.GET_PURCHASES}`, {
        date: `${year}-${month}`,
      });
      if (status === 200) {
        if (userList) {
          const newUsers = [...userList];
          const pointPurchase = data?.filter((item) => item?.product_id === 0);
          const purchase = pointPurchase
            ?.map((item) => ({
              ...item,
              order_date: item?.created_at,
              profile_img: newUsers?.files,
              ...(newUsers &&
                newUsers?.filter(
                  (subitem) => item?.user_id === subitem?.user_id
                )[0]),
              ...(newUsers &&
                newUsers?.filter(
                  (subitem) => item?.agency_id === subitem?.agency_id
                )[0]),
            }))
            .sort((a, b) => new Date(b.order_date) - new Date(a.order_date));
          // NOTE: 테이블 데이터 정제중 12/15 ~
          const tableData = purchase?.map((item) => ({
            key: item?.purchase_id,
            name: item?.name,
            point: item?.purchase_price,
            account: item?.user_account
              ? item?.user_account
              : item?.agency_account,
            phone: item?.phone,
            purchase_state: item?.purchase_state,
            profile_img: item?.files?.map(
              (subitem) => subitem.type === 'P' && subitem?.file_url
            )[0],
            created_at: moment(item?.created_at).format('YYYY-MM-DD'),
            data: item,
          }));
          setNewData(tableData);
          setEditData(tableData);
        }
      }
    };
    refetch();
  }, [userList, year, month]);

  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  /**
   * 날짜 querystring 값 정제
   * --
   */
  useEffect(() => {
    const splitDate = date?.split('-');
    setYear(parseInt(splitDate[0]));
    setMonth(parseInt(splitDate[1]));
  }, [date]);

  /* ===== Functions ===== */
  /**
   * pagination 처리 함수
   * --
   */
  const handlePagination = (page) => {
    navigation(`/admin/point-order?date=${year}-${month}&page=${page}`);
  };

  /**
   * 환불 함수
   * --
   * @returns
   */
  const handleRefund = async () => {
    const { purchase_id, point, user_id, agency_id, refund_point } = modalData;
    if (!purchase_id) {
      MessageAlert.warning('포인트를 수정할 유저를 선택해주세요');
      return;
    }
    if (!refund_point) {
      MessageAlert.warning('환불 포인트를 입력해주세요');
      return;
    }
    if (point < refund_point) {
      MessageAlert.warning('보유 포인트가 적습니다.');
      return;
    }
    const confirm = window.confirm('포인트를 수정하시겠습니까?');

    if (confirm) {
      try {
        if (user_id) {
          updateUser.mutate({
            user_id: user_id,
            point: point - refund_point,
          });
        } else {
          updateAgency.mutate({
            agency_id: agency_id,
            point: point - refund_point,
          });
        }
        updatePurchase.mutate({
          purchase_id: purchase_id,
          purchase_state: 'C',
        });
        MessageAlert.success('수정되었습니다');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error(`${e.response.data.message}`);
        throw e;
      }
    }
  };

  /**
   * 모달 컨트롤러
   * --
   * @param {*} userList
   */
  const handleModal = (data) => {
    const {
      purchase_id,
      point,
      name,
      agency_account,
      user_account,
      phone,
      user_id,
      agency_id,
      purchase_price,
    } = data;
    const newData = {};
    newData['purchase_id'] = purchase_id;
    newData['point'] = point;
    newData['refund_point'] = purchase_price;
    newData['name'] = name;
    newData['agency_account'] = agency_account;
    newData['user_account'] = user_account;
    newData['phone'] = phone;
    newData['user_id'] = user_id;
    newData['agency_id'] = agency_id;
    newData['purchase_price'] = purchase_price;
    const d = {
      ...newData,
    };
    setModalData(d);

    setIsOpened(!isOpened);
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...modalData };
    newData[t] = v;
    const d = {
      ...newData,
    };
    setModalData(d);
    if (callback) callback(v);
  };

  /**
   * 날짜 값 변경
   * --
   */
  const handleDate = (type) => {
    let m = month,
      y = year;
    if (type === 'prev') {
      --m;

      if (m === 0) {
        m = 12;
        --y;
      }
    } else {
      ++m;

      if (m > 12) {
        m = 1;
        ++y;
      }
    }
    navigation(`/admin/point-order?date=${y}-${m}&page=1`);
  };

  /* ===== Render ===== */
  /**
   * 테이블 검색
   * --
   */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /**
   *테이블 검색 초기화
   * --
   */
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  /**
   * 테이블 검색 함수
   * --
   */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${title}을(를) 입력해주세요`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            검색
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            초기화
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  /**
   * 테이블 컬럼
   * --
   */
  const columns = [
    {
      title: '구매자명',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', '성명'),
      render: (_, { name, profile_img, data }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={
              profile_img &&
              `${STORE_URL}/users/${data?.user_id}/${profile_img}`
            }
          ></Avatar>
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            {name}
          </Title>
        </Content>
      ),
    },
    {
      title: '포인트',
      dataIndex: 'point',
      key: 'point',
      sorter: (a, b) => a?.point - b?.point,
      render: (_, { point }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            {`${point}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} P
          </Title>
        </Content>
      ),
    },
    {
      title: '회원아이디',
      dataIndex: 'account',
      key: 'account',
      ...getColumnSearchProps('account', '아이디'),
      render: (_, { account }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            {account}
          </Title>
        </Content>
      ),
    },
    {
      title: '휴대전화',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone', '휴대전화'),
      render: (_, { phone }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            {phone}
          </Title>
        </Content>
      ),
    },
    {
      title: '결제일',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(a?.created_at) - new Date(b?.created_at),
      render: (_, { created_at }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            {moment(created_at).format('YYYY.MM.DD')}
          </Title>
        </Content>
      ),
    },
    {
      title: '상태',
      dataIndex: 'purchase_state',
      key: 'purchase_state',
      filters: [
        {
          text: '구매 완료',
          value: 'P',
        },
        {
          text: '환불 요청',
          value: 'R',
        },
        {
          text: '환불 완료',
          value: 'C',
        },
      ],
      onFilter: (value, record) =>
        value ? record?.purchase_state === value : record?.purchase_state,
      render: (_, { purchase_state }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
              color:
                purchase_state === 'R'
                  ? '#2AC769'
                  : purchase_state === 'C'
                  ? '#FB4E4E'
                  : '#03A9F4',
            }}
          >
            {purchase_state === 'R'
              ? '환불 요청'
              : purchase_state === 'C'
              ? '환불 완료'
              : '구매 완료'}
          </Title>
        </Content>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_, { data }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {data?.purchase_state === 'R' ? (
            <Button
              style={{
                background: '#FFFFFF',
                color: 'rgb(171, 84, 118)',
              }}
              onClick={() => handleModal(data)}
            >
              포인트 수정
            </Button>
          ) : null}
        </Content>
      ),
    },
  ];
  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Col x={24}>
          <Title size={2} style={{ marginBottom: 20 }}>
            포인트 결제내역 관리
          </Title>
          <Content
            maxWidth={'100%'}
            padding={'0 24px 24px 24px'}
            className={'admin_container'}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '30px 0',
              }}
            >
              <Btn
                size='large'
                icon={<LeftOutlined />}
                onClick={() => handleDate('prev')}
              />
              <Title style={{ padding: '0 10px' }}>
                {year}년 {month}월
              </Title>
              <Btn
                size='large'
                icon={<RightOutlined />}
                onClick={() => handleDate('next')}
              />
            </div>
            <Table
              columns={columns}
              dataSource={editData}
              showSorterTooltip={false}
              pagination={{
                position: ['bottomCenter'],
                current: currentPage,
                onChange: (e) => handlePagination(e),
              }}
            />
          </Content>
        </Col>
      </Content>
      <ModalLayout
        type={'modal'}
        open={isOpened}
        onCancel={setIsOpened}
        width={500}
        height={'50%'}
        closable
        bodyStyle={{ padding: '40px' }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <Title
                align={'center'}
                style={{
                  fontSize: '30px',
                  fontWeight: 700,
                  lineHeight: '36px',
                }}
              >
                포인트 수정
              </Title>
            </Col>
            <Col x={24} style={{ marginTop: '48px' }}>
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                    flexDirection: 'colunm',
                  }}
                >
                  <Col x={6}>
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                      }}
                    >
                      구매자명
                    </p>
                  </Col>
                  <Col x={18}>
                    <p
                      style={{
                        fontSize: '18px',
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                      }}
                    >
                      {modalData?.name}
                    </p>
                  </Col>
                </Row>
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                    flexDirection: 'colunm',
                  }}
                >
                  <Col x={6}>
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                      }}
                    >
                      아이디
                    </p>
                  </Col>
                  <Col x={18}>
                    <p
                      style={{
                        fontSize: '18px',
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                      }}
                    >
                      {modalData?.user_account !== undefined
                        ? modalData?.user_account
                        : modalData?.agency_account}
                    </p>
                  </Col>
                </Row>
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                    flexDirection: 'colunm',
                  }}
                >
                  <Col x={6}>
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                      }}
                    >
                      휴대전화
                    </p>
                  </Col>
                  <Col x={18}>
                    <p
                      style={{
                        fontSize: '18px',
                        lineHeight: '22px',
                        letterSpacing: '0.02em',
                      }}
                    >
                      {modalData?.phone}
                    </p>
                  </Col>
                </Row>
                <Col x={6}>
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      marginBottom: 20,
                    }}
                  >
                    보유 포인트
                  </p>
                </Col>
                <Col
                  x={18}
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    marginBottom: 20,
                  }}
                >
                  {`${modalData?.point}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  P
                </Col>
                <Col x={6}>
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                      marginBottom: 20,
                    }}
                  >
                    결제 포인트
                  </p>
                </Col>
                <Col
                  x={18}
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.02em',
                    marginBottom: 20,
                  }}
                >
                  {`${modalData?.purchase_price}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                  )}{' '}
                  P
                </Col>
                <Col x={6}>
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      letterSpacing: '0.02em',
                    }}
                  >
                    환불 포인트
                  </p>
                </Col>
                <Col x={18}>
                  <InputNumber
                    placeholder='수정할 포인트를 입력해주세요'
                    size='large'
                    style={{ width: '100%' }}
                    name='refund_point'
                    value={modalData?.refund_point}
                    onChange={(value) => handleChange('refund_point', value)}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    controls={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Button
            layoutStyle={{
              width: '100%',
              height: '50px',
            }}
            style={{
              width: '100%',
              height: '100%',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '24px',
              background: '#FFFFFF',
              color: 'rgb(171, 84, 118)',
            }}
            onClick={() => handleRefund()}
          >
            수정하기
          </Button>
        </Content>
      </ModalLayout>
    </>
  );
};

PointOrdersPresenter.defaultProps = {};

export default PointOrdersPresenter;
