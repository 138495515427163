import APIConstant from '../APIConstant';
import APIManager from '../APIManager';

const $http = new APIManager();

const Api = {
  /**
   * 암호화 토큰 요청
   * --
   * 본인인증 요청 팝업 띄우기 위해 일회용 암호화 토큰을 요청
   * @returns
   */
  getNiceToken: async () => {
    const apiResult = await $http.get(APIConstant.GET_NICE_TOKEN);

    return apiResult.data;
  },

  /**
   * 유저 데이터 복호화 요청
   * --
   * 회원가입에서 이름, 연락처에 암호화된 유저 데이터(본인인증 데이터)를 복호화 요청
   * @returns
   */
  getDecodeData: async (queryString) => {
    const apiResult = await $http.get(
      `${APIConstant.GET_DECODE_USER_DATA}${queryString}`
    );

    return apiResult.data;
  },

  /**
   * 유저 데이터 복호화 요청
   * --
   * 회원가입에서 이름, 연락처에 암호화된 유저 데이터(본인인증 데이터)를 복호화 요청
   * @returns
   */
  postDecodeData: async (body) => {
    const apiResult = await $http.post(
      `${APIConstant.GET_DECODE_USER_DATA}`,
      body
    );

    return apiResult.data;
  },

  verifyToken: async (queryString) => {
    const apiResult = await $http.get(
      `${APIConstant.VERIFY_NICE_TOKEN}${queryString}`
    );

    return apiResult.data;
  },
};

export default Api;
