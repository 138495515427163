import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

/**
 * [Component]
 * --
 */
const CloseButton = ({ onClick }) => {
  return (
    <>
      <Button
        type="link"
        shape="circle"
        icon={<CloseOutlined />}
        style={{ color: '#333', fontWeight: 'bold' }}
        onClick={onClick}
      />
    </>
  );
};

CloseButton.defaultProps = {
  onClick: () => {},
};
export default CloseButton;
