import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';
import { setCookie } from 'utils';
import './AuthSuccess.style.css';

const AuthSuccess = () => {
  /* ===== ROUTE ===== */
  const location = useLocation();

  /* ===== FUNCTION ===== */
  const handleClose = () => {
    const queryString = new URLSearchParams(location.search);
    const encodeData = queryString.get('EncodeData');

    setCookie('encodeData', decodeURIComponent(encodeData));
    window.opener.postMessage('close', '*');
    window.close();
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    // window.alert('인증에 성공하였습니다!');
    // const queryString = new URLSearchParams(location.search);
    // const encodeData = queryString.get('EncodeData');
    // setCookie('encodeData', decodeURIComponent(encodeData));
    // window.close();
  }, [location.search]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: document.body.clientHeight,
        flexDirection: 'column',
      }}
    >
      <p style={{ fontSize: '24px', marginBottom: 30 }}>
        웹으로 돌아가 계속 진행해주세요
      </p>
      <Button onClick={() => handleClose()}>웹으로 돌아가기</Button>
    </div>
  );
};

export default AuthSuccess;
