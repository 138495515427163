import React, { useState, useEffect } from 'react';
import { Button, Rate, Input } from 'antd';
import { Title, Content, Col, Row } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageAlert from 'utils/MessageAlert';
import { ReviewApi, LectureApi } from 'api';
import { getCookie } from 'utils';

const ReviewWrite = () => {
  /* ===== Props ===== */
  const location = useLocation();

  const from = location.state?.from?.pathname; //url 접근 제어
  const review_id = location.state?.review_id;
  const lecture_id = location.state?.lecture_id;

  /* ===== DATA ===== */
  const { data: lecture } = LectureApi.GetLecture(lecture_id);
  const lectureData = lecture?.data;

  const { data: review } = ReviewApi.GetReview(review_id);
  const reviewData = review?.data;

  const idx = parseInt(getCookie('USER'));

  /* ===== Mutate ===== */
  /**
   * 리뷰 등록
   * --
   */
  const createreview = ReviewApi.CreateReview({
    onSuccess: (data) => {
      try {
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });
  /**
   * 리뷰 수정
   * --
   */
  const updatereview = ReviewApi.UpdateReview({
    onSuccess: (data) => {
      try {
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== State ===== */

  const NewReviewList = reviewData && {
    new_review: reviewData?.review?.map((reviewItem) => ({
      key: reviewItem.key,
      q: reviewData?.questionnaire?.filter(
        (questionnaireItem) => questionnaireItem.key === reviewItem.key
      )[0]?.q,
      score: reviewItem.score,
      answer: reviewItem.answer,
    })),
  };

  const [totalScore, setTotalScore] = useState(0);
  const [reviewArr, setReviewArr] = useState();

  // console.log('reviewArr', reviewArr);

  /* ===== HOOKS ===== */
  useEffect(() => {
    if (from === undefined || lecture_id === undefined) {
      navigate('/mypage/my_posts');
      MessageAlert.warning('잘못된 접근입니다.');
    } else {
      if (review_id !== undefined) {
        // console.log(reviewData);
        const num = reviewData
          ? Math.ceil(
              (reviewData?.review?.reduce(
                (prev, current) => prev + current.score,
                0
              ) /
                reviewData?.review?.length) *
                2
            ) / 2
          : 0;
        setTotalScore(num);
      } else {
        setTotalScore(0);
      }
    }
  }, [from, lecture_id, review_id, reviewData, navigate]);

  useEffect(() => {
    if (reviewArr?.length === lectureData?.questionnaire?.length) {
      const num = reviewArr
        ? Math.ceil(
            (reviewArr?.reduce((prev, current) => prev + current.score, 0) /
              reviewArr?.length) *
              2
          ) / 2
        : 0;
      setTotalScore(num);
    } else {
      setTotalScore(0);
    }
  }, [reviewArr, lectureData]);

  useEffect(() => {
    reviewData && setReviewArr(reviewData?.review);
  }, [reviewData, setReviewArr]);

  /* ===== Functions ===== */

  /**
   * 리뷰작성 기능
   * --
   * @param {*} keyValue - item key
   * @param {*} type - score, review
   * @param {*} value - input, rate value
   */
  const handleChangeReview = (keyValue, type, value) => {
    // console.log('value', value);
    const OtherReviewValue =
      reviewArr !== undefined
        ? reviewArr?.filter((item) => item.key !== keyValue)
        : [];
    const reviewValue =
      reviewArr !== undefined
        ? reviewArr?.filter((item) => item.key === keyValue)
        : [];
    setReviewArr([
      ...OtherReviewValue,
      type === 'score'
        ? {
            key: keyValue,
            score: value,
            answer: reviewValue && reviewValue[0]?.answer,
          }
        : {
            key: keyValue,
            score:
              reviewValue && reviewValue[0]?.score === undefined
                ? 0
                : reviewValue[0]?.score,
            answer: value,
          },
    ]);
  };

  const handleEdit = () => {
    if (window.confirm('리뷰를 수정하시겠습니까?')) {
      try {
        updatereview.mutate({
          review_id: review_id,
          lecture_id: lecture_id,
          user_id: idx,
          questionnaire: lectureData?.questionnaire,
          review: reviewArr,
        });

        MessageAlert.success('리뷰 수정이 완료되었습니다.');
        navigate('/mypage/my_posts');
      } catch (e) {
        console.log('Error!!', e);
        throw e;
      }
    } else {
      return;
    }
  };

  const handleCreate = () => {
    if (totalScore === 0) {
      MessageAlert.success('별점을 등록해주세요.');
    } else {
      if (window.confirm('리뷰를 등록하시겠습니까?')) {
        try {
          createreview.mutate({
            lecture_id: lecture_id,
            user_id: idx,
            questionnaire: lectureData?.questionnaire,
            review: reviewArr,
          });

          MessageAlert.success('리뷰 작성이 완료되었습니다.');
          navigate('/mypage/my_posts');
        } catch (e) {
          console.log('Error!!', e);
          throw e;
        }
      } else {
        return;
      }
    }
  };
  /* ===== Render ===== */
  return (
    <>
      <Content maxWidth={1200} padding={0}>
        <Row>
          <Col x={24}>
            <Content maxWidth={'80%'} padding={0}>
              <div
                style={{
                  display: 'flex',
                  backgroundColor: '#FFFBFA',
                  borderTop: '3px solid #AB5476',
                  borderBottom: '3px solid #AB5476',
                  margin: '48px 0',
                  padding: '4px 20px',
                  height: '200px',
                  fontWeight: 600,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Title size={2}>리뷰를 작성해 주세요</Title>
                <Rate
                  allowHalf={true}
                  disabled={true}
                  value={totalScore}
                  style={{ color: '#ab5576', margin: '10px', fontSize: '35px' }}
                />
                <Title size={3}>{totalScore} 점</Title>
              </div>

              <Title
                size={3}
                style={{
                  backgroundColor: '#FFFBFA',
                  fontWeight: 600,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                강의 명 : {lectureData?.title}
              </Title>
            </Content>
          </Col>
          <Col x={24}>
            <Content maxWidth={'80%'} padding={'20px 10px 0 10px'}>
              {review_id !== undefined
                ? NewReviewList?.new_review?.map((item) => (
                    <div
                      key={item.key}
                      style={{ margin: '20px 0px', minHeight: '150px' }}
                    >
                      <Title>{item.q}</Title>
                      <Rate
                        allowHalf={true}
                        defaultValue={item.score}
                        value={
                          reviewArr?.filter((i) => i.key === item.key)?.pop()
                            ?.score
                        }
                        style={{ color: '#ab5576', fontSize: '25px' }}
                        onChange={(e) =>
                          handleChangeReview(item.key, 'score', e)
                        }
                      />
                      <Input
                        id="answer"
                        size="large"
                        placeholder="20자 내외로 간단한 리뷰를 적어주세요."
                        defaultValue={item.answer}
                        value={
                          reviewArr?.filter(
                            (subitem) => subitem.key === item.key
                          )?.answer
                        }
                        style={{ padding: '20px' }}
                        onChange={(e) =>
                          handleChangeReview(item.key, 'answer', e.target.value)
                        }
                      />
                    </div>
                  ))
                : lectureData?.questionnaire?.map((item) => (
                    <div
                      key={item.key}
                      style={{ margin: '20px 0px', minHeight: '150px' }}
                    >
                      <Title>{item.q}</Title>
                      <Rate
                        allowHalf={true}
                        style={{ color: '#ab5576', fontSize: '25px' }}
                        onChange={(e) =>
                          handleChangeReview(item.key, 'score', e)
                        }
                      />
                      <Input
                        id="answer"
                        size="large"
                        placeholder="20자 내외로 간단한 리뷰를 적어주세요."
                        defaultValue={item.answer}
                        value={
                          reviewArr?.filter(
                            (subitem) => subitem.key === item.key
                          )?.answer
                        }
                        style={{ padding: '20px' }}
                        onChange={(e) =>
                          handleChangeReview(item.key, 'answer', e.target.value)
                        }
                      ></Input>
                    </div>
                  ))}
            </Content>
          </Col>
          <Col x={24}>
            <Content maxWidth={'100%'} padding={'20px 10px 0 10px'}>
              <div
                style={{
                  width: '50%',
                  margin: 'auto',
                }}
              >
                <Button
                  style={{
                    color: '#ab5476',
                    fontWeight: 'bold',
                    border: '1px solid #ab5476',
                    fontSize: '26px',
                    width: '100%',
                    height: '50px',
                  }}
                  onClick={() =>
                    review_id !== undefined ? handleEdit() : handleCreate()
                  }
                >
                  리뷰 {review_id !== undefined ? '수정' : '작성'} 완료
                </Button>
              </div>
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
};

ReviewWrite.defualtProps = {};

export default ReviewWrite;
