/**
 *
 *
 */

import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Title } from 'components';
import { useLocation } from 'react-router-dom';
// import LOGO from 'assets/Onegai_logo.png';

const { Header, Sider } = Layout;
const styles = {
  logo: {
    padding: '10px 5px',
    width: '100%',
    height: 75,
    // background: 'rgba(255,255,255,0.05)',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #f1f1f1',
  },
  menus: {
    height: '100%',
    borderRight: 0,
  },
  header: {
    padding: '0 18px',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    fontSize: '0.95em',
    marginRight: 7,
  },
};

/**
 * [Component] SideMenuLayout
 * --
 */
const SideMenuLayout = ({ menus, theme, header, exceptPath, children }) => {
  /* ===== ROUTER ===== */
  const location = useLocation();
  const { pathname } = location;

  /* ===== STATE ===== */
  const [selectedKeys, setSelectedKeys] = useState(['home']);
  const [noneHeader, setNoneHeader] = useState(false);
  const [currentMenu, setCurrentMenu] = useState({
    key: 'home',
    label: '홈',
    icon: null,
  });
  const [isAdmin, setIsAdmin] = useState(false);

  /* ===== RENDER ===== */
  useEffect(() => {
    const call = (path) => {
      let value = menus[0].key;
      const parentPath = pathname.split('/')[1];
      // 선태된 메뉴 찾기
      const fid = menus.findIndex(
        (menu) => path.indexOf(`/${menu.key}`) !== -1
      );
      if (fid >= 0) {
        value = menus[fid].key;
        // 레이아웃 제외 루트
        const isHeader = exceptPath.findIndex((item) => item === pathname);
        setNoneHeader(isHeader >= 0);
      }
      //
      if (parentPath === 'admin') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
      setCurrentMenu(menus[fid]);
      setSelectedKeys((state) => {
        if (pathname !== state[0]) return [value];
      });
    };
    menus && menus.length > 0 && call(pathname);
  }, [pathname, menus, exceptPath]);

  /* ===== RENDER ===== */
  return isAdmin === false ? (
    <Layout className="site-layout" style={{ height: '100%' }}>
      {children}
    </Layout>
  ) : (
    <Layout style={{ minHeight: 'calc(100vh - 64px)' }}>
      {menus && menus.length > 0 && (
        <Sider className="site-layout-background" trigger={null} collapsible>
          <div className="logo" style={styles.logo}>
            {/* <img
              src={LOGO}
              style={{
                width: 'auto',
                height: '100%',
                marginTop: -10,
                marginRight: 5,
              }}
              alt=""
            /> */}
            <Title
              color="#03A9F4"
              size={4}
              style={{ marginBottom: '0px', lineHeight: 1 }}
            >
              ONEGAI39
            </Title>
            <Title
              color="#cacaca"
              size={5}
              style={{ marginBottom: '0px', lineHeight: 1 }}
            >
              Management
            </Title>
          </div>
          <Menu
            theme={theme}
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            selectedKeys={selectedKeys}
            style={styles.menus}
            items={menus}
          />
        </Sider>
      )}
      <Layout className="site-layout" style={{ height: '100%' }}>
        {header && noneHeader === false && (
          <Header className="site-layout-background" style={styles.header}>
            {currentMenu && (
              <Title size={4}>
                {currentMenu.icon && (
                  <>
                    <span style={styles.headerIcon}>{currentMenu.icon}</span>{' '}
                  </>
                )}
                {currentMenu.label}
              </Title>
            )}
          </Header>
        )}
        {children}
      </Layout>
    </Layout>
  );
};

SideMenuLayout.defaultProps = {
  menus: [],
  theme: 'dark',
  header: true,
  children: null,
  exceptPath: [],
};
export default SideMenuLayout;
