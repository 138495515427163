/**
 *
 *
 */

let APIConstant = {
  /* ===== Verify ===== */
  VERIFY: `/auth/check`,
  AUTHCHECK: `/auth/check/value`,

  /* ===== Template ===== */
  GET_TEMPLATE_PDF: `/template`,

  /* ===== SMS ===== */
  SEND_SMS: `/sms`,
  REMAIN_SMS: `/sms`,
  LIST_SMS: `/sms`,
  /* ===== Email ===== */
  SEND_EMAIL: `/email`,
  GET_EMAIL_LOGS: `/email_log`,
  GET_EMAIL_LOG: `/email_log/:email_log_id`,
  /* ===== NICE API ===== */
  GET_NICE_TOKEN: `/auth/verify/prev`,
  GET_DECODE_USER_DATA: `/auth/verify/success`,
  VERIFY_NICE_TOKEN: `/auth/verify`,

  /* ===== Address ===== */
  GET_ADDRESS: `/address`,
  GET_ADDRESS_LOCAL: `/address/:local_id`,
  GET_ADDRESS_SPLIT: `/split_address`,
  CREATE_ADDRESS_LOCAL: `/address/local`,
  UPDATE_ADDRESS_LOCAL: `/address/local/:local_id`,
  DELETE_ADDRESS_LOCAL: `/address/local/:local_id`,
  CREATE_ADDRESS_CITY: `/address/city`,
  UPDATE_ADDRESS_CITY: `/address/city/:city_id`,
  DELETE_ADDRESS_CITY: `/address/city/:city_id`,
  CREATE_ADDRESS_DISTRICT: `/address/district`,
  UPDATE_ADDRESS_DISTRICT: `/address/district/:district_id`,
  DELETE_ADDRESS_DISTRICT: `/address/district/:district_id`,

  /* ===== Admin ===== */
  ADMIN_LOGIN: `/admin/login`,
  GET_ADMINS: `/admin`,
  GET_ADMIN: `/admin/:admin_id`,
  CREATE_ADMIN: `/admin`,
  UPDATE_ADMIN: `/admin/:admin_id`,
  DELETE_ADMIN: `/admin/:admin_id`,

  /* ===== Agency ===== */
  AGENCY_LOGIN: `/agency/login`,
  GET_AGENCIES: `/agencies`,
  GET_AGENCY: `/agency/:agency_id`,
  CREATE_AGENCY: `/agency`,
  UPDATE_AGENCY: `/agency/:agency_id`,
  DELETE_AGENCY: `/agency/:agency_id`,
  //FIXME - 01/26일 수정완료
  UPDATE_AGENCY_PASSWORD: `/agency/password/:agency_id`,

  /* ===== Agency Storage ===== */
  GET_AGENCY_STORAGES: `/agency_storages`,
  GET_AGENCY_STORAGE: `/agency_storage/:agency_storage_id`,
  CREATE_AGENCY_STORAGE: `/agency_storage`,
  UPDATE_AGENCY_STORAGE: `/agency_storage`,
  DELETE_AGENCY_STORAGE: `/agency_storage`,

  /* ===== Answer Exam ===== */
  GET_ANSWER_EXAMS: `/answer_exam`,
  GET_ANSWER_EXAM: `/answer_exam/:answer_exam_id`,
  CREATE_ANSWER_EXAM: `/answer_exam`,
  UPDATE_ANSWER_EXAM: `/answer_exam/:answer_exam_id`,
  DELETE_ANSWER_EXAM: `/answer_exam/:answer_exam_id`,

  /* ===== Banner ===== */
  GET_BANNERS: `/banner`,
  GET_BANNER: `/banner/:banner_id`,
  CREATE_BANNER: `/banner`,
  UPDATE_BANNER: `/banner/:banner_id`,
  DELETE_BANNER: `/banner/:banner_id`,

  /* ===== Board ===== */
  GET_BOARDS: `/board`,
  GET_BOARD: `/board/:board_id`,
  CREATE_BOARD: `/board`,
  UPDATE_BOARD: `/board/:board_id`,
  DELETE_BOARD: `/board/:board_id`,

  /* ===== Board File ===== */
  GET_BOARD_FILES: `/file/board_files`,
  GET_BOARD_FILE: `/file/board_file/:board_file_id`,
  CREATE_BOARD_FILE: `/file/board_file`,
  UPDATE_BOARD_FILE: `/file/board_file/:board_file_id`,
  DELETE_BOARD_FILE: `/file/board_file/:board_file_id`,

  /* ===== Comment ===== */
  GET_COMMENTS: `/comment`,
  GET_COMMENT: `/comment`,
  CREATE_COMMENT: `/comment`,
  UPDATE_COMMENT: `/comment/:comment_id`,
  DELETE_COMMENT: `/comment/:comment_id`,

  /* ===== Calculate ===== */
  GET_CALCULATES: `/calculate`,
  GET_CALCULATE: `/calculate/:calculate_id`,
  CREATE_CALCULATE: `/calculate`,
  BULK_CREATE_CALCULATE: `/calculate`,
  UPDATE_CALCULATE: `/calculate/:calculate_id`,
  DELETE_CALCULATE: `/calculate/:calculate_id`,
  GET_CALCULATE_EXCEL: `/calculate_excel`,

  /* ===== Category ===== */
  GET_CATEGORIES: `/category`,
  GET_CATEGORY: `/category/:category_id`,
  CREATE_CATEGORY: `/category`,
  UPDATE_CATEGORY: `/category/:category_id`,
  DELETE_CATEGORY: `/category/:category_id`,

  /* ===== Chapter ===== */
  GET_CHAPTERS: `/chapter`,
  GET_CHAPTER: `/chapter/:chapter_id`,
  CREATE_CHAPTER: `/chapter`,
  UPDATE_CHAPTER: `/chapter/:chapter_id`,
  DELETE_CHAPTER: `/chapter/:chapter_id`,

  /* ===== Difficulty ===== */
  GET_DIFFICULTIES: `/difficulty`,
  GET_DIFFICULTY: `/difficulty/:difficulty_id`,
  CREATE_DIFFICULTY: `/difficulty`,
  UPDATE_DIFFICULTY: `/difficulty/:difficulty_id`,
  DELETE_DIFFICULTY: `/difficulty/:difficulty_id`,

  /* ===== Delivery Adress ===== */
  GET_DELIVERY_ADDRESSES: `/delivery_address`,
  GET_DELIVERY_ADDRESS: `/delivery_address/:delivery_address_id`,
  CREATE_DELIVERY_ADDRESS: `/delivery_address`,
  UPDATE_DELIVERY_ADDRESS: `/delivery_address/:delivery_address_id`,
  DELETE_DELIVERY_ADDRESS: `/delivery_address/:delivery_address_id`,

  /* ===== Exam ===== */
  GET_EXAMS: `/exam`,
  GET_EXAM: `/exam/:exam_id`,
  CREATE_EXAM: `/exam`,
  UPDATE_EXAM: `/exam/:exam_id`,
  DELETE_EXAM: `/exam/:exam_id`,

  /* ===== Event ===== */
  GET_EVENTS: `/event`,
  GET_EVENT: `/event/:event_id`,
  CREATE_EVENT: `/event`,
  UPDATE_EVENT: `/event/:event_id`,
  DELETE_EVENT: `/event/:event_id`,

  /* ===== Event Of User ===== */
  GET_EVENT_OF_USERS: `/event_of_user`,
  GET_EVENT_OF_USER: `/event_of_user/:event_of_user_id`,
  CREATE_EVENT_OF_USER: `/event_of_user`,
  UPDATE_EVENT_OF_USER: `/event_of_user/:event_of_user_id`,
  DELETE_EVENT_OF_USER: `/event_of_user/:event_of_user_id`,

  /* ===== File ===== */
  FILE_UPLOAD: `/file/upload`,
  FILE_BLOB: `/file/blob`,

  /* ===== Interest List ===== */
  GET_INTEREST_LISTS: `/interest_list`,
  GET_INTEREST_LIST: `/interest_list/:interest_list_id`,
  CREATE_INTEREST_LIST: `/interest_list`,
  UPDATE_INTEREST_LIST: `/interest_list/:interest_list_id`,
  DELETE_INTEREST_LIST: `/interest_list/:interest_list_id`,

  /* ===== Lecture ===== */
  GET_LECTURES: `/lecture`,
  GET_LECTURE: `/lecture/:lecture_id`,
  CREATE_LECTURE: `/lecture`,
  UPDATE_LECTURE: `/lecture/:lecture_id`,
  DELETE_LECTURE: `/lecture/:lecture_id`,
  GET_RECOMMEND_LECTURES: `/recommend_lecture`,

  /* ===== Lecture Of User ===== */
  GET_LECTURE_OF_USERS: `/lecture_of_user`,
  GET_LECTURE_OF_USER: `/lecture_of_user/:lecture_of_user_id`,
  CREATE_LECTURE_OF_USER: `/lecture_of_user`,
  UPDATE_LECTURE_OF_USER: `/lecture_of_user/:lecture_of_user_id`,
  DELETE_LECTURE_OF_USER: `/lecture_of_user/:lecture_of_user_id`,

  /* ===== Lecture File ===== */
  GET_LECTURE_FILES: `/file/lecture_files`,
  GET_LECTURE_FILE: `/file/lecture_file/:lecture_file_id`,
  CREATE_LECTURE_FILE: `/file/lecture_file`,
  UPDATE_LECTURE_FILE: `/file/lecture_file/:lecture_file_id`,
  DELETE_LECTURE_FILE: `/file/lecture_file/:lecture_file_id`,

  /* ===== Major ===== */
  GET_USER_MAJORS: `/major/users`,
  GET_USER_MAJOR: `/major/user/:major_id`,
  CREATE_USER_MAJOR: `/major/user`,
  UPDATE_USER_MAJOR: `/major/user`,
  DELETE_USER_MAJOR: `/major/user/:major_id`,

  /* ===== Preference Area ===== */
  GET_PREFERENCE_AREA_USERS: `/preference_area/users`,
  GET_PREFERENCE_AREA_USER: `/preference_area/user/:preference_area_id`,
  CREATE_PREFERENCE_AREA_USER: `/preference_area/user`,
  UPDATE_PREFERENCE_AREA_USER: `/preference_area/user`,
  DELETE_PREFERENCE_AREA_USER: `/preference_area/user/:preference_area_id`,

  /* ===== Preference Subway ===== */
  GET_PREFERENCE_SUBWAY_USERS: `/preference_subway/users`,
  GET_PREFERENCE_SUBWAY_USER: `/preference_subway/user/:preference_subway_id`,
  CREATE_PREFERENCE_SUBWAY_USER: `/preference_subway/user`,
  UPDATE_PREFERENCE_SUBWAY_USER: `/preference_subway/user`,
  DELETE_PREFERENCE_SUBWAY_USER: `/preference_subway/user/:preference_subway_id`,

  /* ===== Program ===== */
  GET_PROGRAMS: `/program`,
  GET_PROGRAM: `/program/:program_id`,
  CREATE_PROGRAM: `/program`,
  UPDATE_PROGRAM: `/program/:program_id`,
  DELETE_PROGRAM: `/program/:program_id`,

  /* ===== Product ===== */
  GET_PRODUCTS: `/product`,
  GET_PRODUCT: `/product/:product_id`,
  CREATE_PRODUCT: `/product`,
  UPDATE_PRODUCT: `/product/:product_id`,
  DELETE_PRODUCT: `/product/:product_id`,
  GET_RECOMMEND_PRODUCTS: `/recommend_product`,

  /* ===== Product Category ===== */
  GET_PRODUCT_CATEGORIES: `/product_category`,
  GET_PRODUCT_CATEGORY: `/product_category/:product_category_id`,
  CREATE_PRODUCT_CATEGORY: `/product_category`,
  UPDATE_PRODUCT_CATEGORY: `/product_category/:product_category_id`,
  DELETE_PRODUCT_CATEGORY: `/product_category/:product_category_id`,

  /* ===== Product Cart ===== */
  GET_PRODUCT_CARTS: `/product_cart`,
  GET_PRODUCT_CART: `/product_cart/:product_cart_id`,
  CREATE_PRODUCT_CART: `/product_cart`,
  UPDATE_PRODUCT_CART: `/product_cart/:product_cart_id`,
  DELETE_PRODUCT_CART: `/product_cart/:product_cart_id`,

  /* ===== Product File ===== */
  GET_PRODUCT_FILES: `/file/product_files`,
  GET_PRODUCT_FILE: `/file/product_file/:product_file_id`,
  CREATE_PRODUCT_FILE: `/file/product_file`,
  UPDATE_PRODUCT_FILE: `/file/product_file/:product_file_id`,
  DELETE_PRODUCT_FILE: `/file/product_file/:product_file_id`,

  /* ===== Purchase ===== */
  GET_PURCHASES: `/purchase`,
  GET_PURCHASE: `/purchase/:purchase_id`,
  CREATE_PURCHASE: `/purchase`,
  UPDATE_PURCHASE: `/purchase/:purchase_id`,
  DELETE_PURCHASE: `/purchase/:purchase_id`,

  /* ===== Review ===== */
  GET_REVIEWS: `/review`,
  GET_REVIEW: `/review/:review_id`,
  CREATE_REVIEW: `/review`,
  UPDATE_REVIEW: `/review/:review_id`,
  DELETE_REVIEW: `/review/:review_id`,

  /* ===== Repetition Schedule ===== */
  GET_REPETITION_SCHEDULES: `/repetition_schedule`,
  GET_REPETITION_SCHEDULE: `/repetition_schedule/:repetition_schedule_id`,
  CREATE_REPETITION_SCHEDULE: `/repetition_schedule`,
  UPDATE_REPETITION_SCHEDULE: `/repetition_schedule/:repetition_schedule_id`,
  DELETE_REPETITION_SCHEDULE: `/repetition_schedule/:repetition_schedule_id`,

  /* ===== Subway ===== */
  GET_SUBWAYS: `/subway`,
  GET_SUBWAY: `/subway/:subway_id`,
  CREATE_SUBWAY: `/subway`,
  UPDATE_SUBWAY: `/subway/:subway_id`,
  DELETE_SUBWAY: `/subway/:subway_id`,

  /* ===== Schedule File ===== */
  GET_SCHEDULE_FILES: `/file/schedule_files`,
  GET_SCHEDULE_FILE: `/file/schedule_file/:schedule_file_id`,
  CREATE_SCHEDULE_FILE: `/file/schedule_file`,
  UPDATE_SCHEDULE_FILE: `/file/schedule_file/:schedule_file_id`,
  DELETE_SCHEDULE_FILE: `/file/schedule_file/:schedule_file_id`,

  /* ===== Section ===== */
  GET_SECTIONS: `/section`,
  GET_SECTION: `/section/:section_id`,
  CREATE_SECTION: `/section`,
  UPDATE_SECTION: `/section/:section_id`,
  DELETE_SECTION: `/section/:section_id`,

  /* ===== Schedule ===== */
  GET_SCHEDULES: `/schedule`,
  GET_SCHEDULE: `/schedule/:schedule_id`,
  CREATE_SCHEDULE: `/schedule`,
  UPDATE_SCHEDULE: `/schedule/:schedule_id`,
  DELETE_SCHEDULE: `/schedule/:schedule_id`,
  DELETE_BULK_SCHEDULE: `/schedule`,

  /* ===== SNS ===== */
  GET_SNS_USERS: `/sns/users`,
  GET_SNS_USER: `/sns/user/:sns_id`,
  CREATE_SNS_USER: `/sns/user`,
  UPDATE_SNS_USER: `/sns/user`,
  DELETE_SNS_USER: `/sns/user/:sns_id`,

  /* ===== Teacher ===== */
  TEACHER_LOGIN: `/teacher/login`,
  GET_TEACHERS: `/teachers`,
  GET_TEACHER: `/teacher/:teacher_id`,
  CREATE_TEACHER: `/teacher`,
  UPDATE_TEACHER: `/teacher/:teacher_id`,
  DELETE_TEACHER: `/teacher/:teacher_id`,

  /* ===== Teacher File ===== */
  GET_TEACHER_FILES: `/file/teacher_files`,
  GET_TEACHER_FILE: `/file/teahcer_file/:teacher_file_id`,
  CREATE_TEACHER_FILE: `/file/teacher_file`,
  UPDATE_TEACHER_FILE: `/file/teacher_file/:teacher_file_id`,
  DELETE_TEACHER_FILE: `/file/teacher_file/:teacher_file_id`,

  /* ===== Total Calculate ===== */
  GET_TOTAL_CALCULATES: `/total_calculate`,
  GET_TOTAL_CALCULATE: `/total_calculate/:total_calculate_id`,
  CREATE_TOTAL_CALCULATE: `/total_calculate`,
  UPDATE_TOTAL_CALCULATE: `/total_calculate/:total_calculate_id`,
  DELETE_TOTAL_CALCULATE: `/total_calculate/:total_calculate_id`,
  GET_TOTAL_CALCULATE_EXCEL: `/total_calculate_excel`,

  /* ===== User ===== */
  USER_LOGIN: `/user/login`,
  GET_USERS: `/users`,
  GET_USER: `/user/:user_id`,
  CREATE_USER: `/user`,
  UPDATE_USER: `/user/:user_id`,
  DELETE_USER: `/user/:user_id`,
  //FIXME - 01/26일 수정완료
  UPDATE_USER_PASSWORD: `/user/password/:user_id`,

  /* ===== User Profile ===== */
  GET_USER_PROFILES: `/profile/users`,
  GET_USER_PROFILE: `/profile/user/:profile_id`,
  CREATE_USER_PROFILE: `/profile/user`,
  UPDATE_USER_PROFILE: `/profile/user/:profile_id`,
  DELETE_USER_PROFILE: `/profile/user/:profile_id`,
};
export default APIConstant;
