import React from 'react';
import MyTestPresenter from './MyTestPresenter';
import { useQueryClient } from 'react-query';
import { getCookie } from 'utils';

const MyTestContainer = ({ screenSize }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const queryClient = useQueryClient();

  const lectures = queryClient.getQueryData('getLectures')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  const lectureOfUsers = queryClient.getQueryData('getLectureOfUsers')?.data;
  const chapters = queryClient.getQueryData('getChapters')?.data;
  const sections = queryClient.getQueryData('getSections')?.data;
  const exams = queryClient.getQueryData('getExams')?.data;
  const answerExams = queryClient.getQueryData('getAnswerExams')?.data;
  const idx = parseInt(getCookie('USER'));
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <MyTestPresenter
      lectures={lectures}
      users={users}
      lectureOfUsers={lectureOfUsers}
      chapters={chapters}
      sections={sections}
      categories={categories}
      exams={exams}
      answerExams={answerExams}
      idx={idx}
      screenSize={screenSize}
    />
  );
};

MyTestContainer.defaultProps = {};

export default MyTestContainer;
