import React from 'react';
import { Content, Row, Col, Title } from 'components';
import './Introduce.style.css';

const Introduce = ({ screenSize }) => {
  return (
    <Content maxWidth={'100%'}>
      <Content
        maxWidth={1200}
        padding={screenSize >= 768 ? '120px 0' : '60px 0'}
      >
        <Row>
          <Col x={24}>
            <div id="introduce_layout">
              <Title size={screenSize >= 768 ? 1 : 3} align={'center'}>
                브레인힐 시니어 콘텐츠랩{' '}
                <br style={{ display: screenSize >= 768 ? 'none' : 'block' }} />{' '}
                원격 평생 교육원 소개
              </Title>
              <ul>
                <li>
                  브레인힐 시니어 콘텐츠랩 원격 평생 교육원은 고령자 케어 및
                  여가 활동의 질을 향상시키기 위한 전문가 양성에 초점을 맞춘
                  교육 기관입니다. 고령화 사회의 도래와 더불어 고령자들의 삶의
                  질을 높이기 위한 맞춤형 교육 및 여가 프로그램의 중요성이 점점
                  대두되고 있습니다. 이에 따라, 우리 교육원은 고령자들의 다양한
                  필요와 욕구를 충족시킬 수 있는 전문 인력을 양성하고 있습니다.
                </li>
                <li>
                  <h5>설립 배경 및 연혁</h5>
                  <p>
                    브레인힐 시니어 콘텐츠랩 원격 평생 교육원은 2021년에
                    설립되어, 다년간 고령자 케어 분야에서 축적한 경험과 전문
                    지식을 바탕으로 고령자를 위한 교육과 여가 프로그램 개발에
                    힘써왔습니다. 우리는 고령자들이 각자의 능력과 관심에 맞는
                    활동을 할 수 있도록 지원함으로써, 그들의 자아실현과 사회
                    참여를 촉진하는 데 주력하고 있습니다.
                  </p>
                </li>
                <li>
                  <h5>사업 영역</h5>
                  <p>
                    1. 전문가 교육 프로그램: 고령자 케어와 관련된 전문 교육
                    과정을 제공하여, 케어 기술 및 프로그램 제공 및 기획 능력을
                    갖춘 전문가를 양성합니다.
                    <br />
                    2. 맞춤형 프로그램 개발: 고령자의 신체적, 인지적 능력을
                    고려한 맞춤형 교육 및 여가 프로그램을 개발하여 제공합니다.
                    <br />
                    3. 온라인 플랫폼 운영: 원격 교육을 통해 전국 어디서나 접근
                    가능한 교육 기회를 제공하며, 이를 통해 고령자와 그
                    가족들에게 지속적인 학습의 기회를 보장합니다.
                  </p>
                </li>
                <li>
                  <h5>비전과 미션</h5>
                  <p>
                    우리 교육원의 미션은 고령자들이 활기찬 노후를 보낼 수 있도록
                    지원하는 것입니다. 이를 위해 최신 연구와 기술을 바탕으로 한
                    교육 내용을 제공하며, 이를 통해 고령자들이 사회의 중요한
                    구성원으로서 그들의 역량을 발휘할 수 있도록 돕습니다. 또한,
                    고령자의 삶의 질 향상을 위한 연구와 혁신에 투자하여 교육원을
                    선도적인 평생 교육 기관으로 자리매김하고자 합니다.
                  </p>
                </li>
                <li>
                  브레인힐 시니어 콘텐츠랩 원격 평생 교육원은 고령자 교육의
                  미래를 선도하며, 모든 고령자가 존중받고 품위 있는 삶을 영위할
                  수 있도록 최선을 다하고 있습니다. 학습자 여러분의 많은 관심과
                  참여를 기다립니다.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default Introduce;
