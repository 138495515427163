import React, { useState, useEffect } from 'react';
import TeachersPresenter from './TeachersPresenter';

const TeachersContainer = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newTeacherData, setNewTeacherData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const {
        teachers,
        lectures,
        chapters,
        sections,
        profiles,
        address,
        lectureOfUsers,
        reviews,
      } = data;

      const newChapters = chapters?.map((item) => ({
        ...item,
        sections: sections
          ?.filter((subitem) => item?.chapter_id === subitem?.chapter_id)
          ?.sort((a, b) => a.order - b.order),
      }));
      const l = lectures?.map((item) => ({
        ...item,
        lectureOfUsers: lectureOfUsers?.filter(
          (subitem) => item?.lecture_id === subitem?.lecture_id
        ),
        chapters: newChapters
          ?.filter((subitem) => item?.lecture_id === subitem?.lecture_id)
          ?.sort((a, b) => a.order - b.order),
        lecture_created_date: item?.created_at,
        reviews: reviews?.filter(
          (subitem) => subitem?.lecture_id === item.lecture_id
        ),
      }));

      const newLectures = l?.map((item) => {
        let scores = [];
        item?.reviews?.forEach((subitem) => {
          subitem?.review?.forEach((thirditem) => {
            scores.push(thirditem.score);
          });
        });

        return {
          ...item,
          average:
            scores?.reduce((total, score) => total + score, 0) / scores?.length,
        };
      });

      const newProfiles = profiles?.map((item) => ({
        ...item,
        activity_area_name: address?.filter(
          (subitem) => item?.activity_area === subitem?.local_id
        )[0]?.local_name,
      }));

      const newTeahcers = teachers?.map((item) => ({
        ...item,
        lectures: newLectures?.filter(
          (subitem) => item?.user_id === subitem?.user_id
        ),
        profile: newProfiles
          ?.filter((subitem) => subitem?.user_id === item?.user_id)
          ?.pop(),
        profileImage: item?.files?.filter((item) => item?.type === 'P')?.pop(),
      }));

      setNewTeacherData(newTeahcers);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <TeachersPresenter
      teachers={newTeacherData}
      screenSize={screenSize}
      address={data?.address}
    />
  );
};

TeachersContainer.defaultProps = {};

export default TeachersContainer;
