import React, { useRef } from 'react';
import './FileUploadButton.style.css';

const FileUploadButton = ({
  textAlign,
  style,
  padding,
  children,
  onChange,
  variable,
  layoutStyle,
  accept,
}) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (onChange) {
      // console.log('variable', variable);
      if (variable !== '' && variable !== undefined) {
        onChange(variable, file);
      } else {
        onChange(file);
      }
    }
  };

  return (
    <div className="file-layout" style={{ ...layoutStyle }}>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept={accept}
      />
      <button
        type="button"
        style={{ textAlign: textAlign, padding: padding, ...style }}
        onClick={() => fileInputRef.current.click()}
      >
        {children}
      </button>
    </div>
  );
};

FileUploadButton.defaultProps = {
  textAlign: 'left',
  style: {},
  padding: '10px',
  children: '파일 추가',
  onChange: () => {},
  accept: '*',
};

export default FileUploadButton;
