import React, { useState, useEffect } from 'react';
import EventListPresenter from './EventListPresenter';

const EventListContainer = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { events } = data;

      setNewData(events);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <EventListPresenter data={newData} screenSize={screenSize} />;
};

EventListContainer.defaultProps = {};

export default EventListContainer;
