import React, { useState, useEffect } from 'react';
import UsersPresenter from './UsersPresenter';

const UsersContainer = (props) => {
  /* ===== Props ===== */
  const { data, screenSize } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newUserData, setNewUserData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { users, teachers, lectures, lectureOfUsers, chapters, sections } =
        data;

      const newChapters = chapters?.map((item) => ({
        ...item,
        sections_count: sections?.filter(
          (subitem) => item?.chapter_id === subitem?.chapter_id
        )?.length,
        sections: sections?.filter(
          (subitem) => item?.chapter_id === subitem?.chapter_id
        ),
      }));
      const newLectures = lectures?.map((item) => ({
        ...item,
        chapters: newChapters?.filter(
          (subitem) => item?.lecture_id === subitem?.lecture_id
        ),
      }));

      const newTeachers = teachers?.map((item) => ({
        ...item,
        profileImage: item?.files?.filter((item) => item?.type === 'P')?.pop(),
      }));
      const u = users?.concat(newTeachers);

      const newLecture = lectureOfUsers?.map((item) => ({
        ...item,
        ...(newLectures &&
          newLectures?.filter(
            (subitem) => subitem?.lecture_id === item?.lecture_id
          )[0]),
        user_id: item?.user_id,
        payment_date: item?.created_at,
      }));

      const newUsers = u?.map((item) => ({
        ...item,
        lectures: newLecture?.filter(
          (subitem) => item.user_id === subitem.user_id
        ),
      }));

      setNewUserData(newUsers);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <UsersPresenter users={newUserData} screenSize={screenSize} />;
};

UsersContainer.defaultProps = {};

export default UsersContainer;
