import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 강의 챕터 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetChapters: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getChapters', params] : 'getChapters',
        async () => await $http.get(APIConstant.GET_CHAPTERS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 챕터 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetChapter: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_CHAPTER, {
        chapter_id: idx,
      });
      const res = useQuery(
        params ? ['getChapter', params] : 'getChapter',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 챕터 생성
   * --
   * @method POST
   * @returns
   */
  CreateChapter: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_CHAPTER, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getChapters');
            queryClient.invalidateQueries('getChapter');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 챕터 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateChapter: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.put(APIConstant.CREATE_CHAPTER, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getChapters');
            queryClient.invalidateQueries('getChapter');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강의 챕터 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteChapter: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_CHAPTER, {
            chapter_id: data.chapter_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getChapters');
            queryClient.invalidateQueries('getChapter');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
