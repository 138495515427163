import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { Content, Row, Col, Title } from 'components';
import { useQueryClient } from 'react-query';
import MessageAlert from 'utils/MessageAlert';
import Join01 from './Join01';
import Join02 from './Join02';
import Join03 from './Join03';
import Join04 from './Join04';

function JoinRoutes(props) {
  /* ===== Route ===== */
  const location = useLocation();
  const { pathname } = location;

  /* ===== STATE ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  // 현재 진행 단계
  const [currentStep, setCurrentStep] = useState(1);
  // userValue = 1: 개인회원 / 2: 강사회원 / 3: 기관회원
  const [userValue, setUserValue] = useState(1);
  const [agencyData, setAgencyData] = useState({
    agency_name: undefined,
    agency_type: undefined,
    agency_capacity: undefined,
    agency_address: undefined,
    agency_phone: undefined,
    chief_name: undefined,
    agency_email: undefined,
    company_number: undefined,
  });
  const queryClient = useQueryClient();

  // /* ===== Data ===== */
  const AddressSplit = queryClient.getQueryData('getAddressSplit')?.data;
  const getUsers = queryClient.getQueryData('getUsers')?.data;
  const getAgencies = queryClient.getQueryData('getAgencies')?.data;

  /* ===== FUNCTION ===== */
  const ValueCheck = (data, name) => {
    // 데이터 값 미입력 체크
    if (data === '' || data === undefined || data === null) {
      MessageAlert.warning(name + '을(를) 입력해주세요');
      return false;
    } else {
      return true;
    }
  };

  /**
   * 사업자등록번호 자동 Hyphen
   * --
   */
  const OnlyNumber = (v) => {
    let str = v.replace(/[^0-9]/g, '');
    str === '' && MessageAlert.warning('숫자만 입력해 주세요!');
    return str;
  };

  /**
   * 영어, 숫자만 가능
   * --
   */
  const OnlyEngNum = (v) => {
    let str = v.replace(/[^a-z|^0-9]/gi, '');
    return str;
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    const step = pathname?.split('/');
    setCurrentStep(parseInt(step[2]));
  }, [pathname]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== STYLE ===== */
  // const styles = {
  //   stepsLayout: {
  //     width: '100%',
  //     display: 'flex',
  //     justifyContent: 'space-between',
  //     alignItems: 'center',
  //     margin: '22px 0 48px',
  //   },
  //   stepsItem: {
  //     width: '100%',
  //     textAlign: 'center',
  //     padding: '24px',
  //     borderBottom: '2px solid #E0E0E0',
  //   },
  //   stpesText: {
  //     fontSize: '22px',
  //     fontWeight: 600,
  //     lineHeight: '26px',
  //     letterSpacing: '0.05em',
  //     color: '#000000',
  //   },
  // };

  /* ===== RENDER ===== */
  return (
    <Content
      maxWidth={'100%'}
      padding={screenSize >= 480 ? '48px 0 84px' : '48px 10px 84px'}
      backgroundColor={'#FAFAFA'}
    >
      <Content maxWidth={792} padding={0} backgroundColor={'#FAFAFA'}>
        <Row>
          {/* 타이틀 */}
          <Col x={24}>
            <Title
              size={2}
              align="center"
              style={{
                fontSize: '35px',
                fontWeight: 700,
                lineHeight: '42px',
                letterSpacing: '0.02em',
                color: '#000000',
                marginBottom: '70px',
              }}
            >
              {currentStep === 1
                ? '회원가입'
                : currentStep === 2
                ? '본인인증'
                : currentStep === 3
                ? (userValue === 1
                    ? '개인회원'
                    : userValue === 2
                    ? '강사회원'
                    : userValue === 3 && '기관회원') + ' 정보입력'
                : '가입완료'}
            </Title>
          </Col>
          {/* 진행상태 */}
          {/* <Col x={24}>
            <ul style={{ ...styles.stepsLayout }}>
              {['약관동의', '본인인증', '정보입력', '가입완료']?.map(
                (item, index) => (
                  <li
                    style={{
                      ...styles.stepsItem,
                      borderBottom:
                        currentStep === index + 1
                          ? '2px solid #AB5476'
                          : '2px solid #E0E0E0',
                    }}
                  >
                    <span style={{ ...styles.stpesText }}>{item}</span>
                  </li>
                )
              )}
            </ul>
          </Col> */}
          {/* 회원가입 라우터 */}
          <Col x={24}>
            <Routes>
              <Route path="1" element={<Join01 />} />
              <Route
                path="2"
                element={
                  <Join02
                    setUserValue={setUserValue}
                    agencyData={agencyData}
                    setAgencyData={setAgencyData}
                    ValueCheck={ValueCheck}
                    OnlyNumber={OnlyNumber}
                    encodeData={props.encodeData}
                    users={getUsers}
                    agencies={getAgencies}
                  />
                }
              />
              <Route
                path="3"
                element={
                  <Join03
                    userValue={userValue}
                    agencyData={agencyData}
                    ValueCheck={ValueCheck}
                    OnlyNumber={OnlyNumber}
                    OnlyEngNum={OnlyEngNum}
                    AddressSplit={AddressSplit}
                    getUsers={getUsers}
                  />
                }
              />
              <Route path="4" element={<Join04 />} />
            </Routes>
          </Col>
        </Row>
      </Content>
    </Content>
  );
}

JoinRoutes.defaultProps = {};

export default JoinRoutes;
