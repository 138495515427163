import React from 'react';
import './Statistic.style.css';

const Statistic = (props) => {
  /* ===== PROPS ===== */
  const {
    layoutStyle,
    title,
    titleStyle,
    value,
    valueStyle,
    suffix,
    suffixStyle,
  } = props;
  /* ===== RENDER ===== */
  return (
    <div className="statistic_container" style={layoutStyle}>
      <h4 style={titleStyle}>{title}</h4>
      <p style={valueStyle}>
        {value} <span style={suffixStyle}>{suffix}</span>
      </p>
    </div>
  );
};

Statistic.defaultProps = {
  title: '타이틀',
  value: '값',
  suffix: '',
};

export default Statistic;
