import React from 'react';
import { Content, Row, Col, Title } from 'components';

const LectureJournalPresenter = () => {
  /* ===== Props ===== */

  /* ===== Router ===== */

  /* ===== State ===== */

  /* ===== Mutate ===== */

  /* ===== Hooks ===== */

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <Content maxWidth={'100%'} padding={'0 12px'}>
      <Row>
        <Col x={24} padding={8}>
          <Title size={2} padding={8} style={{ marginBottom: '28px' }}>
            강의 일지
          </Title>
        </Col>
      </Row>
    </Content>
  );
};

LectureJournalPresenter.defualtProps = {};

export default LectureJournalPresenter;
