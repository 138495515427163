import React, { useState, useEffect, useRef } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  ModalLayout,
  Button,
  DaumAddress,
} from 'components';
import { Avatar, Input, Radio, Table, Space } from 'antd';
import moment from 'moment';
import { SettingOutlined, SearchOutlined } from '@ant-design/icons';
import { LectureOfUserApi, UserApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import validateCheck from 'utils/Validation';
import { STORE_URL } from 'utils';
import Highlighter from 'react-highlight-words';
import { useNavigate, useSearchParams } from 'react-router-dom';

const UsersPresenter = (props) => {
  /* ===== Props ===== */
  const { users, screenSize } = props;

  /* ===== Router ===== */
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  /* ===== Mutate ===== */
  const deleteLecture = LectureOfUserApi.DeleteLectureOfUser();
  const updateUser = UserApi.UpdateUser();
  const deleteUser = UserApi.DeleteUser(null, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        MessageAlert.success('삭제되었습니다');
      } else {
        MessageAlert.error('삭제에 실패하였습니다');
      }
    },
    onError: (data) => {
      MessageAlert.error('삭제에 실패하였습니다');
    },
  });

  /* ===== State ===== */
  // const [isUserOpened, setIsUserOpened] = useState({});
  const [lectuerModal, setLectureModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [userModal, setUserModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [newData, setNewData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${title}을(를) 입력해주세요`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            검색
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            초기화
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '성명',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', '성명'),
      render: (_, { name, data }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={
              data?.profileImage &&
              `${STORE_URL}/users/${data?.user_id}/${data?.profileImage?.file_url}`
            }
          >
            {name[0]}
          </Avatar>
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
              marginLeft: '5px',
            }}
          >
            {name}
          </Title>
        </Content>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone', '연락처'),
      render: (_, { phone }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {phone}
        </Title>
      ),
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', '이메일'),
      render: (_, { email }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {email}
        </Title>
      ),
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      filters: [
        {
          text: '남',
          value: '남',
        },
        {
          text: '여',
          value: '여',
        },
      ],
      onFilter: (value, record) =>
        value ? record?.gender === value : record?.gender,
      render: (_, { gender }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {gender}
        </Title>
      ),
    },
    {
      title: '수강',
      dataIndex: 'lectures',
      key: 'lectures',
      sorter: (a, b) => a?.lectures - b?.lectures,
      render: (_, { data, lectures }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#008ED2',
            cursor: 'pointer',
          }}
        >
          <p style={{ margin: 0 }} onClick={() => handleModal('lecture', data)}>
            {lectures}
          </p>
        </Title>
      ),
    },
    {
      title: '가입일',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(b?.created_at) - new Date(a?.created_at),
      render: (_, { created_at }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {created_at}
        </Title>
      ),
    },
    {
      title: '',
      dataIndex: 'butotn',
      key: 'button',
      render: (_, { data }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          <p
            onClick={() => handleUserUpdate('update', data)}
            style={{
              cursor: 'pointer',
              margin: 0,
            }}
          >
            <SettingOutlined />
          </p>
        </Title>
      ),
    },
  ];

  /* ===== Hooks ===== */
  /**
   * 회원 데이터 정제
   * --
   */
  useEffect(() => {
    const n = users
      ?.map((item) => ({
        ...item,
        lectures: item?.lectures?.map((subitem) => ({
          ...subitem,
          total_sections_count: subitem.chapters?.reduce(
            (prev, current) => prev + current.sections_count,
            0
          ),
          progress_count: subitem.progress ? subitem.progress.length : 0,
        })),
      }))
      ?.sort((a, b) => {
        return new Date(b?.created_at) - new Date(a?.created_at);
      });

    const tableData = n?.map((item) => ({
      key: item?.user_id,
      name: item?.name,
      phone: item?.phone,
      email: item?.email,
      gender: item?.gender === 'M' ? '남' : '여',
      lectures: item?.lectures?.length,
      created_at: moment(item?.created_at).format('YYYY-MM-DD'),
      data: item,
    }));
    setNewData(tableData);
  }, [users]);

  /**
   * 회원 수만큼 opened 객체 생성
   * --
   */
  // useEffect(() => {
  //   const d = newUsers?.map((item, index) => ({
  //     key: index,
  //     value: false,
  //   }));

  //   const obj = {};
  //   d?.forEach((item) => {
  //     obj[item.key] = item.value;
  //   });

  //   setIsUserOpened(obj);
  // }, [newUsers]);

  /**
   * 모달 데이터 초기화
   * --
   */
  useEffect(() => {
    if (!lectuerModal && !userModal) {
      setModalData({});
    }
  }, [lectuerModal, userModal]);

  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  /* ===== Functions ===== */
  /**
   * 모달 컨트롤 함수
   * --
   * @param {*} type
   * @param {*} data
   */
  const handleModal = (type, data) => {
    if (type === 'lecture') {
      setLectureModal(!lectuerModal);
    } else {
      setUserModal(!userModal);
    }
    setModalData(data);
  };

  /**
   * 수강중인 강의 삭제 함수
   * --
   * @param {*} id
   */
  const handleDeleteLecture = (id) => {
    const confirm = window.confirm(
      '삭제 후 복구할 수 없습니다. 수강생이 수강중인 강의를 정말 삭제하시겠습니까?\n삭제 시 구매내역에서 삭제되며 수강 진행 및 환불을 진행 할 수 없습니다.'
    );

    if (confirm) {
      try {
        deleteLecture.mutate({
          lecture_of_user_id: id,
        });
        MessageAlert.success('수강중인 강의를 성공적으로 삭제하였습니다');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('수강중인 강의 삭제에 실패하였습니다');
        throw e;
      }
    }
  };

  /**
   * 회원 상세 데이터 처리
   * --
   * @param {*} type
   * @param {*} data
   */
  const handleUserUpdate = (type, data) => {
    if (type === 'update') {
      handleModal('user');
      setModalData(data);
    } else {
      const confirm = window.confirm(
        '삭제 후 복구할 수 없습니다. 해당 회원을 정말 삭제하시겠습니까?\n삭제 시 관련된 모든 내용이 삭제됩니다'
      );

      if (confirm) {
        try {
          deleteUser.mutate({
            user_id: data?.user_id,
          });
        } catch (e) {
          console.log('Error !!', e);
          MessageAlert.error('회원 삭제에 실패하였습니다');
          throw e;
        }
      }
    }
  };

  /**
   * 회원 데이터 수정 함수
   * --
   */
  const handleUserDataUpdate = () => {
    const { email, recommender_id, point } = modalData;
    if (!validateCheck('email', email)) {
      MessageAlert.warning('이메일 형식을 확인해주세요');
      return;
    }
    if (recommender_id && recommender_id !== '') {
      if (!validateCheck('account', recommender_id)) {
        MessageAlert.warning('추천인ID는 영문 또는 숫자만 입력가능합니다');
        return;
      }
    }
    if (point) {
      if (!validateCheck('number', point))
        return MessageAlert.warning('포인트는 숫자로만 입력 가능합니다');
    }

    const confirm = window.confirm('회원 정보를 수정하시겠습니까?');

    if (confirm) {
      try {
        const { created_at, ...d } = modalData;
        updateUser.mutate({
          ...d,
        });
        MessageAlert.success('회원정보가 수정되었습니다');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('회원 정보 수정에 실패하였습니다');
        throw e;
      }
    }
  };

  /**
   * 데이터 바인딩 함수
   * --
   * @param {*} t
   * @param {*} v
   * @param {*} callback
   */
  const handleChange = (t, v, callback = null) => {
    if (userModal) {
      let newData = { ...modalData };

      if (t === 'email') {
        const value = newData[t];
        // v 값에 한글이 포함되어 있는지 검사하는 정규식
        const koreanRegex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;

        if (value === '' || !value) {
          if (v === '' || !v) {
            v = undefined;
          } else if (v?.match(koreanRegex)) {
            MessageAlert.warning('영문 또는 숫자만 입력해주세요');
            v = undefined;
          }
        } else {
          if (v === '' || !v) {
            v = undefined;
          } else if (v?.match(koreanRegex)) {
            MessageAlert.warning('영문 또는 숫자만 입력해주세요');
            v = value;
          }
        }
      }

      newData[t] = v;
      const d = {
        ...newData,
      };
      setModalData(d);

      if (callback) callback(v);
    }
  };

  /**
   * pagination 처리 함수
   * --
   */
  const handlePagination = (page) => {
    navigation(`/admin/users?page=${page}`);
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={'0 12px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={24}>
            <Title size={2} style={{ marginBottom: 20 }}>
              수강생회원 관리
            </Title>
            <Content
              maxWidth={'100%'}
              padding={24}
              className={'admin_container'}
            >
              <Table
                columns={columns}
                dataSource={newData}
                showSorterTooltip={false}
                pagination={{
                  position: ['bottomCenter'],
                  current: currentPage,
                  onChange: (e) => handlePagination(e),
                }}
              />
            </Content>
          </Col>
        </Row>
      </Content>

      {/* 수강중인 강의 상세 모달 */}
      <ModalLayout
        title={'수강중인 강의 목록'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={lectuerModal}
        onCancel={setLectureModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 700 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{
          padding: '40px 20px 20px',
          minHeight: screenSize > 768 && '500px',
          maxHeight: screenSize > 768 && '800px',
          overflowY: 'scroll',
        }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '60px',
              padding: '9px 6px',
              background: '#D28494',
              borderRadius: '4px 4px 0px 0px',
            }}
          >
            <Col x={4}>
              <Title
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                성명
              </Title>
            </Col>
            <Col x={9}>
              <Title
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                강의명
              </Title>
            </Col>
            <Col x={3}>
              <Title
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                진도율
              </Title>
            </Col>
            <Col x={5}>
              <Title
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                결제일
              </Title>
            </Col>
            <Col x={2}>
              <Title
                style={{
                  color: '#FFFFFF',
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                수료
              </Title>
            </Col>
            <Col x={1}></Col>
          </Row>
          {modalData?.lectures && modalData?.lectures?.length > 0 ? (
            modalData?.lectures?.map((item, index) => (
              <Row
                key={`user-lecture-${index}`}
                style={{
                  background: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA',
                  height: '70px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '9px 6px',
                  border: index % 2 === 0 ? '1px solid #FAFAFA' : 'none',
                }}
              >
                <Col x={4}>
                  <Title
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}
                  >
                    {modalData?.name}
                  </Title>
                </Col>
                <Col x={9}>
                  <Title
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}
                  >
                    {item?.title}
                  </Title>
                </Col>
                <Col x={3}>
                  <Title
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}
                  >
                    {item?.total_sections_count &&
                    item?.total_sections_count > 0
                      ? `${(
                          (item?.progress_count / item?.total_sections_count) *
                          100
                        ).toFixed(1)}%`
                      : '0.0%'}
                  </Title>
                </Col>
                <Col x={5}>
                  <Title
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}
                  >
                    {moment(modalData?.payment_date)?.format('YYYY.MM.DD')}
                  </Title>
                </Col>
                <Col x={2}>
                  <Title
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}
                  >
                    {item?.score > 80 ? '수료' : '-'}
                  </Title>
                </Col>
                <Col x={1}>
                  <Title
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}
                  >
                    <button
                      style={{
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleDeleteLecture(item?.lecture_of_user_id)
                      }
                    >
                      x
                    </button>
                  </Title>
                </Col>
              </Row>
            ))
          ) : (
            <Row>
              <Col x={24}>
                <Content
                  maxWidth={'100%'}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px     ',
                  }}
                >
                  <Title align={'center'}>수강중인 강의가 없습니다</Title>
                </Content>
              </Col>
            </Row>
          )}
        </Content>
      </ModalLayout>

      {/* 회원 상세 모달 */}
      <ModalLayout
        title={'회원 정보 수정'}
        type={screenSize > 768 ? 'modal' : 'drawer'}
        open={userModal}
        onCancel={setUserModal}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'50%'}
        closable
        bodyStyle={{ padding: '40px 20px 20px' }}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              {/* 아이디 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>아이디</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.user_account}
                    placeholder='아이디를 입력해주세요'
                    readOnly
                    disabled
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 이름 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>이름</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.name}
                    placeholder='이름을 입력해주세요'
                    onChange={(e) => handleChange('name', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 닉네임 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>닉네임</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.nickname}
                    placeholder='닉네임을 입력해주세요'
                    onChange={(e) => handleChange('nickname', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 전화번호 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>전화번호</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.phone}
                    placeholder='핸드폰번호를 입력해주세요'
                    readOnly
                    disabled
                    onChange={(e) => handleChange('phone', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 이메일 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>이메일</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.email ? modalData?.email : null}
                    placeholder='이메일을 입력해주세요'
                    onChange={(e) => handleChange('email', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 생년월일 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>생년월일</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={moment(modalData?.birthday).format('YYYY.MM.DD')}
                    placeholder='생년월일을 입력해주세요'
                    onChange={(e) => handleChange('birthday', e.target.value)}
                    readOnly
                    disabled
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 주소 */}
              <Row style={{ display: 'flex' }}>
                <Col x={6} style={{ marginTop: 20 }}>
                  <Title>주소</Title>
                </Col>
                <Col x={18}>
                  <DaumAddress
                    size='large'
                    value={modalData?.address}
                    placeholder='주소를 입력해주세요'
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                      cursor: 'pointer',
                    }}
                    callback={(e) => handleChange('address', e)}
                  />
                  <Input
                    size='large'
                    value={modalData?.address_detail}
                    placeholder='상세주소를 입력해주세요'
                    onChange={(e) =>
                      handleChange('address_detail', e.target.value)
                    }
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 직업 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>직업</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.job}
                    placeholder='직업을 입력해주세요'
                    onChange={(e) => handleChange('job', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 추천인 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>추천인 ID</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.recommender_id}
                    placeholder='추천인 ID를 입력해주세요'
                    onChange={(e) =>
                      handleChange('recommender_id', e.target.value)
                    }
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 추천인 */}
              <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col x={6}>
                  <Title>포인트</Title>
                </Col>
                <Col x={18}>
                  <Input
                    size='large'
                    value={modalData?.point}
                    placeholder='숫자로 입력해주세요'
                    onChange={(e) => handleChange('point', e.target.value)}
                    style={{
                      padding: '16px 20px',
                      borderRadius: '4px',
                      border: '1px solid #BDBDBD',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      margin: '8px 0 10px',
                      color: 'black',
                    }}
                  />
                </Col>
              </Row>
              {/* 강사여부 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '71px',
                }}
              >
                <Col x={6}>
                  <Title>강사자격</Title>
                </Col>
                <Col x={18}>
                  <Radio.Group
                    size='large'
                    value={modalData?.teacher_check}
                    onChange={(e) =>
                      handleChange('teacher_check', e.target.value)
                    }
                  >
                    <Radio value={'Y'}>Y</Radio>
                    <Radio value={'N'}>N</Radio>
                  </Radio.Group>
                </Col>
              </Row>
              {/* 노출여부 */}
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '71px',
                }}
              >
                <Col x={6}>
                  <Title>노출여부</Title>
                </Col>
                <Col x={18}>
                  <Radio.Group
                    size='large'
                    value={modalData?.is_show}
                    onChange={(e) => handleChange('is_show', e.target.value)}
                  >
                    <Radio value={'Y'}>Y</Radio>
                    <Radio value={'N'}>N</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col x={24}>
              <Button
                style={{ width: '100%', height: '50px', marginTop: '30px' }}
                onClick={() => handleUserDataUpdate()}
              >
                수정
              </Button>
            </Col>
          </Row>
        </Content>
      </ModalLayout>
    </>
  );
};

UsersPresenter.defaultProps = {};

export default UsersPresenter;
