/**
 * BoardListContainer
 * --
 */
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import UseDebounce from 'utils/useDebounce';
import { decrypt, encrypt } from 'utils/Crypto';
import BoardListPresenter from './BoardListPresenter';
import { BoardApi } from 'api';
import { DOMAIN_URL } from 'utils';

const BoardListContainer = ({ data }) => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('lecture');
  const page = searchParams.get('page');

  /* ===== PROPS ===== */
  const {
    lectures,
    chapters,
    sections,
    lectureOfUsers,
    users,
    teachers,
    categories,
  } = data;

  /* ===== STATE ===== */
  const filterOptions = [
    {
      label: '전체',
      value: 'ALL',
    },
    {
      label: '문의 게시판',
      value: 'INQUIRY',
    },
    {
      label: '토론 게시판',
      value: 'DISCUSSION',
    },
    {
      label: '과제 게시판',
      value: 'ASSIGNMENT',
    },
  ];
  const [filterValue, setFilterValue] = useState(filterOptions[0].value);
  // 강의 검색
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = UseDebounce(searchValue, 300);
  // 강의 목록
  const [newData, setNewData] = useState({});
  const [filterLectureList, setFilterLectureList] = useState([]);
  // 선택 강의
  const [selectedLectureId, setSelectedLectureId] = useState(null);
  const [selectedLectureInfo, setSelectedLectureInfo] = useState({});
  // 페이지
  const [currentPage, setCurrentPage] = useState(1);

  /* ===== DATA ===== */
  const { data: board, refetch: boardRefetch } = BoardApi.GetBoards(
    {
      lecture_id: decrypt(lectureId),
    },
    {
      enabled: !!lectureId,
      select: (data) => data?.data,
    }
  );

  /* ===== VARIABLE ===== */
  /* ===== FUNCTION ===== */
  /**
   * pagination 처리 함수
   * --
   */
  const handlePagination = (page) => {
    navigate(
      `/admin/board?page=${page}&lecture=${encodeURIComponent(
        encrypt(selectedLectureId)
      )}`
    );
  };

  const handleDetailPopup = (e, d) => {
    e.stopPropagation();

    const domain = DOMAIN_URL;

    window.open(
      `${domain}/#/popup/board/detail/${encodeURIComponent(
        encrypt(d?.board_id)
      )}?lecture=${encodeURIComponent(
        encrypt(d?.lecture_id)
      )}&type=${d?.type?.toLocaleLowerCase()}`,
      '팝업',
      'width=1500, height=850'
    );
  };

  /* ===== HOOKS ===== */
  /**
   * 데이터 Refetch
   * --
   */
  useEffect(() => {
    if (lectureId) {
      boardRefetch();
    }
  }, [lectureId, boardRefetch]);

  /**
   * 강의 데이터 정제
   * --
   */
  useEffect(() => {
    // 강사 + 유저
    const newUsers = users?.concat(teachers);
    const newUsreList = newUsers?.map((item) => ({
      ...item,
      profile: item?.files?.filter((subitem) => subitem?.type === 'P')?.pop(),
    }));
    // 강의 데이터 정제
    const newLectures = lectures?.map((item) => ({
      ...item,
      regist_date: item?.created_at,
      category_name: categories
        ?.filter((subitem) => item?.category_id === subitem?.category_id)
        ?.pop()?.category_name,
      chapters: chapters?.filter(
        (subitem) => subitem?.lecture_id === item?.lecture_id
      ),
      sections: sections?.reduce((acc, cur) => {
        const c = chapters?.filter(
          (subitem) => subitem?.lecture_id === item?.lecture_id
        );

        const isExist = c?.some(
          (subitem) => subitem?.chapter_id === cur?.chapter_id
        );
        if (isExist) {
          acc.push(cur);
          return acc;
        } else {
          return acc;
        }
      }, []),
      thumbnail: item?.files?.filter((subitem) => subitem?.type === 'T')?.pop(),
      ...(newUsreList &&
        newUsreList?.filter(
          (subitem) => parseInt(item?.user_id) === parseInt(subitem?.user_id)
        )[0]),
    }));
    // 수강생 데이터 정제
    const newLectureOfUsers = lectureOfUsers?.map((item) => ({
      lecture_start_date:
        item?.progress?.length && item?.progress[0]?.created_at,
      lecture_last_date:
        item?.progress?.length &&
        item?.progress[item?.progress.length - 1]?.created_at,
      ...item,
      payment_date: item?.created_at,
      ...(newUsreList &&
        newUsreList?.filter(
          (subitem) => parseInt(item?.user_id) === parseInt(subitem?.user_id)
        )[0]),
    }));

    // 게시판 파일 정제
    const files = board?.reduce((acc, cur) => {
      let arr = acc;
      if (cur?.board_file?.length) {
        acc = [...arr, ...cur?.board_file];
        return acc;
      } else {
        acc = arr;
        return acc;
      }
    }, []);

    const newData = {
      board: board?.map((item) => {
        const { comment, ...d } = item;

        return {
          ...d,
          ...newUsreList?.find((user) => item?.user_id === user?.user_id),
          writer:
            item?.user_id === 0
              ? '관리자'
              : newUsreList?.find((user) => item?.user_id === user?.user_id)
                  ?.name,
          board_created_at: item?.created_at,
          board_update_at: item?.updated_at,
          comment: comment?.map((subitem) => ({
            ...subitem,
            writer: newUsreList?.find(
              (user) => subitem?.user_id === user?.user_id
            ),
          })),
        };
      }),
      board_files: files,
    };

    // console.log('sections', sections);
    // 최종 데이터 정제
    const Data = newLectures?.map((item) => ({
      ...item,
      student: newLectureOfUsers
        ?.filter((subitem) => item?.lecture_id === subitem?.lecture_id)
        ?.map((subitem) => ({
          ...subitem,
          progress_status: subitem?.progress?.length
            ? (subitem?.progress?.length / item?.sections?.length) * 100
            : 0,
        })),
    }));

    setNewData({ lectures: Data, ...newData });
  }, [
    lectures,
    chapters,
    sections,
    lectureOfUsers,
    users,
    teachers,
    categories,
    board,
  ]);

  /**
   * 강의 검색
   * --
   */
  useEffect(() => {
    const list = newData.lectures;
    const filterList = list?.filter((item) =>
      item?.title?.includes(debouncedValue)
    );

    setFilterLectureList(filterList);
  }, [debouncedValue, newData.lectures]);

  /**
   * 쿼리 스트링 값 정제
   * --
   */
  useEffect(() => {
    if (lectureId) {
      const id = decodeURIComponent(lectureId);
      const l = lectures?.filter((item) => item?.lecture_id === id)?.pop();
      setSelectedLectureInfo(l);
      setSelectedLectureId(decrypt(id));
    } else {
      const firstLecture = lectures?.length ? lectures[0] : null;

      if (firstLecture) {
        const l = lectures
          ?.filter((item) => item?.lecture_id === firstLecture?.lecture_id)
          ?.pop();
        setSelectedLectureId(firstLecture?.lecture_id);
        setSelectedLectureInfo(l);
      }
    }
  }, [lectureId, lectures]);

  /**
   * 선택한 강의 정보 정제
   * --
   */
  useEffect(() => {
    if (selectedLectureId) {
      const info = newData.lectures
        ?.filter(
          (item) => parseInt(item?.lecture_id) === parseInt(selectedLectureId)
        )
        ?.pop();
      setSelectedLectureInfo(info);
    }
  }, [newData.lectures, selectedLectureId]);

  /**
   * pagination 렌더링 처리
   * --
   */
  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  /* ===== RENDER ===== */
  return (
    <>
      <BoardListPresenter
        lectureId={decrypt(lectureId)}
        filterLectureList={filterLectureList}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedLectureId={selectedLectureId}
        setSelectedLectureId={setSelectedLectureId}
        selectedLectureInfo={selectedLectureInfo}
        currentPage={currentPage}
        navigate={navigate}
        onPagination={handlePagination}
        data={newData}
        filterOptions={filterOptions}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        onDetailPopup={handleDetailPopup}
      />
    </>
  );
};

export default BoardListContainer;
