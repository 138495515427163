import React, { useEffect, useState } from 'react';
import { Content, Row, Col, Title, Button, ModalLayout } from 'components';
import { BannerRegister } from './components';
import { Table, Button as Btn } from 'antd';
import moment from 'moment';
import { STORE_URL } from 'utils';

const BannerPresenter = (props) => {
  /* ===== Route ===== */
  /* ===== Props ===== */
  const { banner, onDeleteBanner, onOpenLink } = props;
  /* ===== State ===== */
  // 모달
  const [isOpened, setIsOpened] = useState(false);
  const [modalData, setModalData] = useState(null);

  /* ===== Variables ===== */
  const columns = [
    {
      title: '배너 타이틀',
      dataIndex: 'title',
      key: 'title',
      render: (_, { title }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          <p style={{ margin: 0 }}>{title}</p>
        </Title>
      ),
    },
    {
      title: 'PC 배너',
      dataIndex: 'pc_file_url',
      key: 'pc_file_url',
      render: (_, { pc_file_url }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {pc_file_url ? (
            <p
              style={{ cursor: 'pointer', margin: 0, color: '#03A9F4' }}
              onClick={() => window.open(`${STORE_URL}/banner/${pc_file_url}`)}
            >
              {pc_file_url}
            </p>
          ) : (
            '-'
          )}
        </Title>
      ),
    },
    {
      title: '모바일 배너',
      dataIndex: 'mobile_file_url',
      key: 'mobile_file_url',
      render: (_, { mobile_file_url }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {mobile_file_url ? (
            <p
              style={{ cursor: 'pointer', margin: 0, color: '#03A9F4' }}
              onClick={() =>
                window.open(`${STORE_URL}/banner/${mobile_file_url}`)
              }
            >
              {mobile_file_url}
            </p>
          ) : (
            '-'
          )}
        </Title>
      ),
    },
    {
      title: 'URL',
      dataIndex: 'link_url',
      key: 'link_url',
      render: (_, { link_url }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {link_url ? (
            <p
              style={{ cursor: 'pointer', margin: 0, color: '#03A9F4' }}
              onClick={() => onOpenLink(link_url)}
            >
              {link_url}
            </p>
          ) : (
            '-'
          )}
        </Title>
      ),
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(a?.created_at) - new Date(b?.created_at),
      render: (_, { created_at }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {created_at ? moment(created_at)?.format('YYYY-MM-DD HH:mm') : '-'}
        </Title>
      ),
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: 150,
      render: (_, item) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Btn
            type="primary"
            onClick={() => {
              setModalData(item);
              setIsOpened(!isOpened);
            }}
          >
            수정
          </Btn>
          <Btn danger onClick={() => onDeleteBanner(item?.banner_id)}>
            삭제
          </Btn>
        </Content>
      ),
    },
  ];

  /* ===== Function ===== */
  /**
   * 모달 핸들러
   * --
   */
  const handleModal = (data) => {
    if (data) {
      setModalData(data);
    }
    setIsOpened(!isOpened);
  };

  /* ===== Hooks ===== */
  /**
   * 모달 데이터 초기화
   * --
   */
  useEffect(() => {
    if (!isOpened) {
      setModalData(null);
    }
  }, [isOpened]);

  /* ===== Render ===== */
  return (
    <>
      <Content padding={0} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              padding={0}
              backgroundColor={'none'}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <Title size={2}>배너 관리</Title>
              <Button
                layoutStyle={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 0,
                }}
                style={{ padding: '10px 11px' }}
                onClick={() => handleModal()}
              >
                {'추가'}
              </Button>
            </Content>
            <Content maxWidth={'100%'} className={'admin_container'}>
              <Table
                dataSource={banner}
                columns={columns}
                showSorterTooltip={false}
              />
            </Content>
          </Col>
        </Row>
      </Content>

      {/* 배너 등록/수정 모달 */}
      <ModalLayout
        title={'배너 관리'}
        type={'modal'}
        open={isOpened}
        onCancel={setIsOpened}
        width={700}
        height={'100%'}
        closable
      >
        <BannerRegister
          data={modalData}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      </ModalLayout>
    </>
  );
};

export default BannerPresenter;
