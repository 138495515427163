import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Divider, Rate, Tag, Empty } from 'antd';
import { Title, Content, Col, Row, Card } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageAlert from 'utils/MessageAlert';
import { ReviewApi } from 'api';
import { STORE_URL } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { getCookie } from 'utils';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'atoms/Atoms';

const MyPostsPresenter = ({
  lectures,
  lectureFiles,
  users,
  reviews,
  categories,
  idx,
}) => {
  /* ===== Props ===== */

  /* ===== Mutate ===== */
  /**
   * 리뷰 삭제
   * --
   */
  const DeleteReview = ReviewApi.DeleteReview({
    onSuccess: (data) => {
      try {
        MessageAlert.error('삭제되었습니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /* ===== Router ===== */
  const navigate = useNavigate();
  const location = useLocation();

  /* ===== State ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const NewLectures = lectures?.map((item) => ({
    ...item,
    lectureFiles:
      lectureFiles &&
      lectureFiles?.filter((subitem) => item.lecture_id === subitem.lecture_id),
    ...(
      users && users?.filter((subitem) => item.user_id === subitem.user_id)
    )?.pop(),
    category: categories?.filter(
      (subitem) => item?.category_id === subitem?.category_id
    )[0]?.category_name,
  }));
  const NewReviewList = reviews
    ?.filter((item) => item.user_id === idx)
    .map((subitem) => ({
      ...subitem,
      lecture_of_user_created_at: subitem.created_at,
      new_review: subitem.review?.map((reviewItem) => ({
        key: reviewItem.key,
        q: subitem.questionnaire.filter(
          (questionnaireItem) => questionnaireItem.key === reviewItem.key
        )[0]?.q,
        score: reviewItem.score,
        answer: reviewItem.answer,
      })),
      totalScore:
        Math.ceil(
          (subitem.review?.reduce((prev, current) => prev + current.score, 0) /
            subitem.review?.length) *
            2
        ) / 2,
      ...(
        NewLectures &&
        NewLectures?.filter(
          (lowitem) => subitem.lecture_id === lowitem.lecture_id
        )
      )?.pop(),
    }));

  // console.log('NewReviewList: ', NewReviewList);

  // 체크박스 기능
  const [selectReview, setSelectReview] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  let ReviewIdList = [];
  NewReviewList?.map((item) => ReviewIdList.push(item.review_id));

  /* ===== Hooks ===== */
  /**
   * 기관회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== Functions ===== */

  /**
   * 항목개별선택
   * --
   */
  const onChange = (item) => {
    const list = selectReview.includes(item)
      ? selectReview?.filter((e) => e !== item)
      : [...selectReview, item];

    setSelectReview(list);
    setCheckAll(list.length === ReviewIdList.length);
  };

  /**
   * 항목전체선택
   * --
   */
  const onCheckAllChange = (e) => {
    setSelectReview(e.target.checked ? ReviewIdList : []);
    setCheckAll(e.target.checked);
  };

  /**
   * 리뷰 선택 삭제
   * --
   * @param {*} 선택한 리뷰 value : selectReview
   */
  const handleListDelete = () => {
    if (selectReview.length === 0) {
      MessageAlert.warning('리뷰를 선택해주세요.');
      return;
    } else if (window.confirm('선택한 리뷰를 삭제하시겠습니까?')) {
      try {
        reviews
          .filter((item) => selectReview.includes(item.review_id))
          .forEach((subitem) => {
            DeleteReview.mutate({
              review_id: subitem.review_id,
            });
          });
      } catch (e) {
        throw e;
      }
      setSelectReview([]);
      setCheckAll(false);
    } else {
      // console.log('삭제취소:');
    }
  };
  /**
   * 리뷰 삭제
   * --
   * @param {*} eventTargetValue : interest_list_id
   */
  const handleDeleteClick = (value) => {
    // console.log('value', value);
    if (window.confirm('해당리뷰를 삭제하시겠습니까?')) {
      // console.log('삭제:', value);
      try {
        DeleteReview.mutate({
          review_id: value,
        });
      } catch (e) {
        throw e;
      }
      setSelectReview([]);
      setCheckAll(false);
    } else {
      // console.log('삭제취소:', value);
    }
  };

  const handleEditReview = (review_id, lecture_id) => {
    if (window.confirm('리뷰수정 페이지로 이동하시겠습니까?')) {
      //TODO: 리뷰작성 기능
      navigate('/review', {
        state: { from: location, review_id: review_id, lecture_id: lecture_id },
      }); //이전 페이지 전달 : url 접근 제어
    }
  };

  /* ===== Render ===== */
  return (
    <>
      <Content maxWidth={1200} padding={0}>
        <Row>
          <Col x={24} padding={8}>
            <Title
              size={screenSize >= 768 ? 2 : 4}
              padding={8}
              style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
            >
              내가 작성한 리뷰
            </Title>
          </Col>
          <Col x={24}>
            <Row
              maxWidth={'100%'}
              padding={'10px'}
              style={{ alignItems: 'center' }}
            >
              <Col x={8} md={4}>
                <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                  전체 선택
                </Checkbox>
              </Col>
              <Col x={0} md={17}></Col>
              <Col x={16} md={3}>
                <Button
                  style={{ float: 'right' }}
                  onClick={() => handleListDelete()}
                >
                  선택 삭제
                </Button>
              </Col>
            </Row>
            <Content maxWidth={'100%'} padding={0}>
              <Col x={24}>
                {/* 리뷰 리스트 */}
                <Content maxWidth={'100%'} padding={'20px 10px 0 10px'}>
                  {NewReviewList?.length < 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '400px',
                        border: '1px solid #f0f0f0',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    NewReviewList?.map((item, index) => (
                      <Row key={`lecture_list_item_${index}`}>
                        <Col x={24} md={1}>
                          <Checkbox
                            style={{
                              fontWeight: 600,
                              fontSize: '20px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                            }}
                            onChange={() => onChange(item.review_id)}
                            checked={
                              selectReview.includes(item.review_id)
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col x={24} md={23}>
                          <Card
                            style={{ border: 'none', marginBottom: 25 }}
                            bodyStyle={{ padding: 0 }}
                            hoverable={false}
                          >
                            <div style={{ display: 'flex' }}>
                              {item?.lectureFiles?.length > 0 ? (
                                <div
                                  className="lecture_card_thumbnail02"
                                  style={{
                                    marginRight: 15,
                                    width: screenSize >= 768 ? '20%' : '40%',
                                    height: '80px',
                                    background: `url(${
                                      item?.lectureFiles?.length > 0 &&
                                      `${STORE_URL}/lecture/${
                                        item?.lectureFiles
                                          ?.filter((i) => i.type === 'T')
                                          ?.pop()?.file_url
                                      }`
                                    }) no-repeat center center/contain`,
                                  }}
                                ></div>
                              ) : (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: '50px',
                                    marginRight: 15,
                                    width: screenSize >= 768 ? '20%' : '40%',
                                    height: '80px',
                                  }}
                                  icon={faImage}
                                />
                              )}

                              {/*  */}
                              <div
                                style={{
                                  width: screenSize >= 768 ? '25%' : '60%',
                                }}
                              >
                                <Title size={4}>{item.title}</Title>
                                <p style={{ margin: 0, fontSize: '1em' }}>
                                  {item?.category}
                                </p>
                                <p style={{ margin: 0, fontSize: '0.95em' }}>
                                  {item.name}
                                  {item.nickname
                                    ? '/(' + item.nickname + ')'
                                    : ''}
                                </p>
                                {screenSize < 768 && (
                                  <div
                                    style={{
                                      width: screenSize >= 768 ? '20%' : '100%',
                                      display: 'flex',
                                      flexDirection:
                                        screenSize >= 768 ? 'column' : 'row',
                                      justifyContent: 'center',
                                      alignItems:
                                        screenSize >= 768
                                          ? 'flex-start'
                                          : 'center',
                                    }}
                                  >
                                    <Rate
                                      allowHalf={true}
                                      disabled={true}
                                      defaultValue={item.totalScore}
                                      value={item.totalScore}
                                      style={{ color: '#ab5576' }}
                                    />
                                    <Title size={5} style={{ paddingLeft: 5 }}>
                                      {item.totalScore} 점
                                    </Title>
                                  </div>
                                )}
                              </div>
                              <div
                                style={{
                                  width: screenSize >= 768 ? '20%' : 0,
                                  display: screenSize >= 768 ? 'flex' : 'none',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <Rate
                                  allowHalf={true}
                                  disabled={true}
                                  defaultValue={item.totalScore}
                                  value={item.totalScore}
                                  style={{ color: '#ab5576' }}
                                />
                                <Title size={5}>{item.totalScore} 점</Title>
                              </div>
                            </div>
                          </Card>
                        </Col>
                        <Col x={0} md={1}></Col>
                        <Col x={24} md={18}>
                          <Card
                            style={{ border: 'none', marginBottom: 25 }}
                            bodyStyle={{ padding: 0 }}
                            hoverable={false}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  padding: screenSize >= 768 ? 20 : 0,
                                }}
                              >
                                {item.new_review?.map((subitem) => (
                                  <div
                                    key={subitem.key}
                                    style={{
                                      width: '100%',
                                      padding: '5px',
                                      margin: '10px 5px',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                    }}
                                  >
                                    <Tag
                                      style={{
                                        background: '#f2d3db',
                                        border: '1px solid #ab5576',
                                        borderRadius: '8px',
                                        padding: '5px 10px',
                                        fontSize: '15px',
                                        fontWeight: 600,
                                        color: '#ab5576',
                                        width:
                                          screenSize >= 768 ? 'auto' : '100%',
                                      }}
                                    >
                                      {subitem.q}
                                    </Tag>
                                    <p
                                      style={{
                                        width:
                                          screenSize >= 768 ? 'auto' : '100%',
                                        marginTop: screenSize >= 768 ? 0 : 10,
                                      }}
                                    >
                                      {subitem.answer}
                                    </p>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width:
                                          screenSize >= 768 ? 'auto' : '100%',
                                      }}
                                    >
                                      <Rate
                                        allowHalf={true}
                                        disabled={true}
                                        defaultValue={subitem.score}
                                        value={subitem.score}
                                        style={{ color: '#ab5576' }}
                                      />
                                      <p style={{ paddingLeft: '10px' }}>
                                        {subitem.score.toFixed(1)} 점
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Card>
                        </Col>
                        <Col x={screenSize >= 768 ? 5 : 24}>
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'space-evenly',
                              alignItems: 'center',
                            }}
                          >
                            <div>
                              <Button
                                style={{
                                  color: '#ab5476',
                                  fontWeight: 'bold',
                                  border: '1px solid #ab5476',
                                }}
                                onClick={() =>
                                  handleEditReview(
                                    item.review_id,
                                    item.lecture_id
                                  )
                                }
                              >
                                리뷰 수정
                              </Button>
                            </div>
                            <div>
                              <Button
                                block
                                type="primary"
                                style={{
                                  background: '#AB5476',
                                  border: 'none',
                                }}
                                onClick={() =>
                                  handleDeleteClick(item.review_id)
                                }
                              >
                                리뷰 삭제
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <Divider />
                      </Row>
                    ))
                  )}
                </Content>
              </Col>
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
};

MyPostsPresenter.defualtProps = {};

export default MyPostsPresenter;
