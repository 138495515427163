/**
 * 2023-03-06
 *
 */
import React, { useState, useEffect } from 'react';

const Container = ({ background, width, height, style, children }) => {
  /* ===== Router ===== */
  /* ===== State ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);

  /* ===== Function ===== */

  /* ===== Hooks ===== */
  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);
  /* ===== Render ===== */
  return (
    <div
      style={{
        ...style,
        background: background,
        maxWidth: width,
        width: screenSize >= width ? '100%' : '90%',
        height: height,
        margin: '0 auto',
      }}
    >
      {children}
    </div>
  );
};

Container.defaultProps = {
  background: 'transparent',
  width: '1200px',
  height: '700px',
  style: {},
};

export default Container;
