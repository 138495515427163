/*
 * 버튼 컴포넌트
 * --
 * date: 2022-09-14
 * writer: Owen
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Button.style.css';

const ButtonComp = ({
  type,
  icon,
  htmlType,
  onClick,
  children,
  color,
  disabled,
  layoutStyle,
  style,
  hover,
}) => {
  /* ===== STATE ===== */
  const [buttonStyle, setButtonStyle] = useState({
    border: 'none',
    background: 'none',
    color: '#FFFFFF',
  });
  // HOVER
  const [isHover, setIsHover] = useState(false);

  /* ===== FUNCTION ===== */
  const handleMouse = (state) => {
    if (disabled) return false;
    setIsHover(!state);
  };

  /* ===== RENDER ===== */
  useEffect(() => {
    if (disabled) {
      if (type === 'text') {
        setButtonStyle({
          border: 'none',
          background: 'none',
          color: '#000000',
        });
      } else {
        if (type === 'primary') {
          setButtonStyle({
            border: '1px solid #9E9E9E',
            background: '#EEEEEE',
            color: '#9E9E9E',
          });
        } else {
          setButtonStyle({
            border: '1px solid #9E9E9E',
            background: 'none',
            color: '#9E9E9E',
          });
        }
      }
    } else {
      if (color === 'primary') {
        if (type === 'primary') {
          setButtonStyle({
            border: '1px solid #AB5476',
            background: '#AB5476',
            color: '#FFFFFF',
          });
        } else if (type === 'secondary') {
          setButtonStyle({
            border: '1px solid #AB5476',
            background: 'none',
            color: '#AB5476',
          });
        } else if (type === 'text') {
          setButtonStyle({
            border: 'none',
            background: 'none',
            color: '#AB5476',
          });
        }
      } else if (color === 'secondary') {
        if (type === 'primary') {
          setButtonStyle({
            border: '1px solid #E3A4AF',
            background: '#E3A4AF',
            color: '#FFFFFF',
          });
        } else if (type === 'secondary') {
          setButtonStyle({
            border: '1px solid #E3A4AF',
            background: 'none',
            color: '#E3A4AF',
          });
        } else if (type === 'text') {
          setButtonStyle({
            border: 'none',
            background: 'none',
            color: '#E3A4AF',
          });
        }
      } else if (color === 'default') {
        if (type === 'primary') {
          setButtonStyle({
            border: '1px solid #EEEEEE',
            background: '#EEEEEE',
            color: '#1C1B1F',
          });
        } else if (type === 'secondary') {
          setButtonStyle({
            border: '1px solid #9E9E9E',
            background: '#FFFFFF',
            color: '#000000',
          });
        } else if (type === 'text') {
          setButtonStyle({
            border: 'none',
            background: 'none',
            color: '#1C1B1F',
          });
        }
      } else if (color === 'shadow') {
        setButtonStyle({
          border: 'none',
          background: '#FFFFFF',
          color: '#000000',
        });
      }
    }
  }, [color, type, disabled]);

  return (
    <div className="button-comp-layout" style={{ ...layoutStyle }}>
      <button
        type={htmlType}
        style={{
          ...buttonStyle,
          ...style,
          boxShadow:
            color === 'shadow' && '0px 8px 15px rgba(149, 107, 132, 0.2)',
          cursor: disabled ? 'default' : 'pointer',
        }}
        onClick={disabled ? () => {} : onClick}
        onMouseEnter={hover ? () => handleMouse(isHover) : () => {}}
        onMouseLeave={hover ? () => handleMouse(isHover) : () => {}}
      >
        {children}
      </button>
    </div>
  );
};

ButtonComp.defaultProps = {
  type: 'primary',
  htmlType: 'button',
  onClick: () => {},
  children: '버튼',
  color: 'primary',
  disabled: false,
  layoutStyle: {},
  style: {},
  hover: false,
};

ButtonComp.propTypes = {
  type: PropTypes.string,
  htmlType: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ButtonComp;
