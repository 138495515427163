import React from 'react';
import LectureCartPresenter from './LectureCartPresenter';
import { useQueryClient } from 'react-query';
import { getCookie } from 'utils';
import { LectureApi, AgencyApi, UserApi } from 'api';

const LectureCartContainer = ({ loginWho }) => {
  /* ===== ROUTE ===== */
  /* ===== STATE ===== */
  /* ===== DATA ===== */
  const queryClient = useQueryClient();

  const idx = parseInt(getCookie('USER'));
  const user =
    loginWho === 'agency'
      ? AgencyApi.GetAgency(idx)?.data?.data
      : UserApi.GetUser(idx)?.data?.data;
  const lectures = queryClient.getQueryData('getLectures')?.data;
  const recommend = LectureApi.GetRecommendLectures()?.data;
  const lectureFiles = queryClient.getQueryData('getLectureFiles')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const interestLists = queryClient.getQueryData('getInterestLists')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  const lectureOfUsers = queryClient.getQueryData('getLectureOfUsers')?.data;

  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <LectureCartPresenter
      user={user}
      lectures={lectures}
      lectureFiles={lectureFiles}
      users={users}
      interestLists={interestLists}
      lectureOfUsers={lectureOfUsers}
      categories={categories}
      idx={idx}
      recommend={recommend?.data}
    />
  );
};

LectureCartContainer.defaultProps = {};

export default LectureCartContainer;
