import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox, Radio } from 'antd';
import {
  Title,
  Content,
  Col,
  Row,
  Card,
  LectureCard,
  BottomLayout,
} from 'components';
import { useNavigate } from 'react-router-dom';
import { Divider, Select, Input, Empty } from 'antd';
import online_bg_01 from 'assets/bg/online_bg_01.png';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { encrypt } from 'utils/Crypto';
import MessageAlert from 'utils/MessageAlert';
import { InterestListApi, LectureOfUserApi, UserApi } from 'api';
import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import {
  STORE_URL,
  DOMAIN_URL,
  TOSS_CLIENT_KEY,
  TOSS_CUSTOMER_KEY,
  getCookie,
} from 'utils';
import { nanoid } from 'nanoid';
import { useRecoilValue } from 'recoil';
import { userInfo, loginUser } from 'atoms/Atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

const styles = {
  cardButton: {
    height: 240,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentCardButton: {
    border: 'none',
    padding: 0,
    borderRadius: 0,
    background: 'none',
  },
};
const LectureCartPresenter = ({
  user,
  lectures,
  lectureFiles,
  users,
  interestLists,
  lectureOfUsers,
  categories,
  idx,
  recommend,
}) => {
  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== Props ===== */

  /* ===== Mutate ===== */
  /**
   * 포인트 업데이트
   * --
   */
  const updateUser = UserApi.UpdateUser({});

  /**
   * 장바구니 삭제
   * --
   */
  const deleteInterestList = InterestListApi.DeleteInterestList({
    onSuccess: (data) => {
      try {
        MessageAlert.error('삭제되었습니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /**
   * 수강 등록
   * --
   */
  const createLectureOfUser = LectureOfUserApi.CreateLectureOfUser({
    onSuccess: (data) => {
      try {
        // MessageAlert.error('등록되었습니다.');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('예기치 못한 오류가 발생되었습니다.');
        throw e;
      }
    },
  });

  /* ===== Router ===== */
  // const navigate = useNavigate();

  /* ===== State ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const loginWho = useRecoilValue(loginUser);
  const token = getCookie('BRAINHEAL_ACCESS_TOKEN');
  const uid = getCookie('USER');
  const userInfoData = useRecoilValue(userInfo);
  const today = moment(new Date()).format('YYYY-MM-DD');
  const NewLectures = lectures?.map((item) => ({
    ...item,
    lectureFiles:
      lectureFiles &&
      lectureFiles?.filter((subitem) => item.lecture_id === subitem.lecture_id),
    ...(
      users && users?.filter((subitem) => item.user_id === subitem.user_id)
    )?.pop(),
    thumbnail: lectureFiles?.filter(
      (subitem) =>
        item?.lecture_id === subitem?.lecture_id && subitem?.type === 'T'
    )[0]?.file_url,
    category: categories?.filter(
      (subitem) => item?.category_id === subitem?.category_id
    )[0]?.category_name,
  }));
  const [recommendList, setRecommendList] = useState([]);
  // console.log(NewLectures);

  const NewInterestList = interestLists
    ?.filter((item) => item.user_id === idx && item.type === 'C')
    .map((subitem) => ({
      ...subitem,
      ...(
        NewLectures &&
        NewLectures?.filter(
          (lowitem) => subitem.lecture_id === lowitem.lecture_id
        )
      )?.pop(),
      selectCheck: 'N',
    }));

  //TODO: 쿠폰, 포인트 어떻게 진행할 건지 확인 필요.
  //REVIEW: 쿠폰관련 요구사항 없었음. 해당 기능 필요시 확장 가능성만 인지.
  const coupon = [
    // {
    //   coupon_id: 1,
    //   value: 1,
    //   coupon_name: '10% 할인쿠폰',
    //   label: '10% 할인쿠폰',
    //   type: 'P',
    // },
    // {
    //   coupon_id: 2,
    //   value: 2,
    //   coupon_name: '10,000원 할인쿠폰',
    //   label: '10,000원 할인쿠폰',
    //   type: 'N',
    // },
  ];
  const userPoint = 0;

  const plainOptions = ['신용카드', '실시간 계좌이체', '무통장입금', '휴대폰'];

  const [paymentMethod, setPaymentMethod] = useState('무통장입금');

  const [couponValue, setCouponValue] = useState(1);
  const [payAgreeCheck, setPayAgreeCheck] = useState(false);

  const [usePoint, setUsePoint] = useState(0);

  // 강의 선택 리스트
  let lectureIdList = [];
  NewInterestList?.map(
    (item) =>
      moment(item.end_date).format('YYYY-MM-DD') >= today &&
      lectureIdList.push(item.lecture_id)
  );

  // 수강 리스트
  let lectureOFUserList = [];
  lectureOfUsers
    ?.filter((item) => item.user_id === idx)
    ?.forEach((item) => lectureOFUserList.push(item.lecture_id));

  lectureOFUserList = NewInterestList?.filter((item) =>
    lectureOFUserList?.includes(item.lecture_id)
  );

  // 체크박스 기능
  const defaultCheckedList = [];
  const [selectLecture, setSelectLecture] = useState(defaultCheckedList);
  const [isCash, setIsCash] = useState(null);
  // const [cashCheckAll, setCashCheckAll] = useState(false);
  const [pointCheckAll, setPointCheckAll] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTitle, setTotalTitle] = useState('강의를 선택해주세요.');

  /* ===== Hooks ===== */
  /**
   * 기관회원 접근 제한
   * --
   */
  useEffect(() => {
    if (!token || !uid) {
      MessageAlert.warning('잘못된 접근입니다');
      navigate('/');
    }

    if (token && uid) {
      if (loginWho === 'agency') {
        MessageAlert.warning('잘못된 접근입니다');
        navigate('/');
      }
    }
  }, [token, uid, loginWho, navigate]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };
    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  useEffect(() => {
    if (recommend) {
      const list = recommend?.map((item) => ({
        ...item,
        lectureFiles:
          lectureFiles &&
          lectureFiles?.filter(
            (subitem) => item.lecture_id === subitem.lecture_id
          ),
        ...(
          users && users?.filter((subitem) => item.user_id === subitem.user_id)
        )?.pop(),
        thumbnail: lectureFiles?.filter(
          (subitem) =>
            item?.lecture_id === subitem?.lecture_id && subitem?.type === 'T'
        )[0]?.file_url,
        category: categories?.filter(
          (subitem) => item?.category_id === subitem?.category_id
        )[0]?.category_name,
      }));

      setRecommendList(list);
    }
  }, [recommend, lectureFiles, users, categories]);

  /* ===== Functions ===== */
  /**
   * 항목개별선택
   * --
   */
  const onChange = (item, price, cash) => {
    if (!isCash) return setIsCash(cash);

    if (isCash && isCash !== cash)
      return MessageAlert.warning(
        '포인트 상품과 현금 상품을 같이 선택할 수 없습니다!'
      );

    const list = selectLecture.includes(item)
      ? selectLecture?.filter((e) => e !== item)
      : [...selectLecture, item];

    const items = NewInterestList.filter((l) => list.includes(l?.lecture_id));

    if (selectLecture.includes(item)) {
      setTotalPrice(totalPrice - price);
      selectLecture?.length === 0
        ? setTotalTitle('강의를 선택해주세요.')
        : setTotalTitle(
            list?.length === 1
              ? `${items[0]?.title}`
              : `${items[0]?.title} 외 ${items?.length - 1}강`
          );
    } else {
      setTotalPrice(totalPrice + price);
      list?.length === 0
        ? setTotalTitle('강의를 선택해주세요.')
        : setTotalTitle(
            list?.length === 1
              ? `${items[0]?.title}`
              : `${items[0]?.title} 외 ${items?.length - 1}강`
          );
    }

    const itemList = NewInterestList?.filter((item) => item.is_cash === isCash);
    if (isCash === 'Y') {
      // setCashCheckAll(list.length === itemList.length);
    } else {
      setPointCheckAll(list.length === itemList.length);
    }
    setSelectLecture(list);
  };

  /**
   * 항목전체선택
   * --
   */
  const onCheckAllChange = (e, t) => {
    if (!isCash) setIsCash(t);

    if (isCash && isCash !== t)
      return MessageAlert.warning(
        '포인트 상품과 현금 상품을 같이 선택할 수 없습니다!'
      );

    // 타입에 맞는 아이템 정제
    const itemList = NewInterestList?.filter((item) => item.is_cash === t);
    const itemIdList = itemList?.map((item) => item.lecture_id);

    //전체금액 계산
    const totalValue = itemList?.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue.price;
    }, 0);

    if (!itemList?.length) {
      setIsCash(null);
      return MessageAlert.warning(
        `${t === 'Y' ? '현금' : '포인트'} 상품이 존재하지 않습니다`
      );
    }

    if (t === 'Y') {
      // setCashCheckAll(e.target.checked);
    } else {
      setPointCheckAll(e.target.checked);
    }
    setSelectLecture(e.target.checked ? itemIdList : []);

    if (e.target.checked) {
      setTotalPrice(totalValue);
      setTotalTitle(
        itemList?.length === 1
          ? `${itemList[0]?.title}`
          : `${itemList[0]?.title} 외 ${itemList?.length - 1}강`
      );
    } else {
      setTotalPrice(0);
      setTotalTitle('강의를 선택해주세요.');
    }
  };

  //FIXME : 수강 바구니 결제시 장바구니 리스트에서 삭제 함수 구축

  /**
   * 수강중 or 수강완료 된 장바구니 리스트 삭제
   * --
   * @param {*} 중복된 강의 값 여부 : lectureOfUserList
   */
  const handleDoubleListDelete = () => {
    //NOTE: 삭제할지 숨김처리 할지?
    try {
      alert('이미 수강했던 혹은 수강중인 강의를 제거합니다.');
      lectureOFUserList?.forEach((subitem) => {
        deleteInterestList.mutate({
          interest_list_id: subitem.interest_list_id,
        });
      });
    } catch (e) {
      throw e;
    }
    setSelectLecture([]);
    // setCashCheckAll(false);
    setPointCheckAll(false);
    setTotalPrice(0);
    setTotalTitle('강의를 선택해주세요.');
  };

  /**
   * 강의 삭제
   * --
   * @param {*} eventTargetValue : interest_list_id
   */
  const handleDeleteClick = (value) => {
    // console.log('value', value);
    if (window.confirm('해당강의를 삭제하시겠습니까?')) {
      //좋아요 강의 삭제
      // console.log('삭제:', value);
      try {
        deleteInterestList.mutate({
          interest_list_id: value,
        });
      } catch (e) {
        throw e;
      }
      setSelectLecture([]);
      // setCashCheckAll(false);
      setPointCheckAll(false);
      setTotalPrice(0);
      setTotalTitle('강의를 선택해주세요.');
    } else {
      // console.log('삭제취소:', value);
    }
  };

  /**
   * 선택한 강의 리스트 결제
   * --
   * @param {*} selectLecture
   */
  const handleOrder = async () => {
    if (lectureOFUserList?.length > 0) {
      handleDoubleListDelete();
      return;
    } else if (selectLecture.length === 0) {
      MessageAlert.warning('강의를 선택해주세요.');
      return;
    } else if (!payAgreeCheck) {
      MessageAlert.warning('결제 정보에 동의해주세요.');
      return;
    } else if (window.confirm('선택한 강의를 결제하시겠습니까?')) {
      if (isCash === 'Y') {
        if (totalPrice === 0) {
          // 결제금액 0원일 경우
          try {
            NewInterestList.filter((item) =>
              selectLecture.includes(item.lecture_id)
            ).forEach((subitem) => {
              deleteInterestList.mutate({
                interest_list_id: subitem.interest_list_id,
              });
            });
          } catch (e) {
            throw e;
          }
          try {
            selectLecture.forEach((item) => {
              createLectureOfUser.mutate({
                user_id: idx,
                lecture_id: item,
                score: 0,
                purchase_state: 'P',
                price: 0,
              });
            });
          } catch (e) {
            throw e;
          }
          //결제 데이터 생성
          alert('결제가 완료되었습니다.');
          navigate('/mypage/my_learning');
        } else {
          // 결제금액 0원이 아닐 경우
          /**
           * 토스 페이먼츠 결제
           * --
           */
          const paymentWidget = paymentWidgetRef.current;

          const selectList = NewInterestList?.filter((item) =>
            selectLecture.includes(item.lecture_id)
          );
          localStorage.setItem('orderList', JSON.stringify(selectList));

          try {
            const orderName =
              selectList?.length > 1
                ? `${selectList[0].title} 외 ${selectList.length - 1}건`
                : `${selectList[0].title}`;

            await paymentWidget
              ?.requestPayment({
                orderId: nanoid(),
                orderName: `${orderName}`,
                customerName: `${userInfoData.name}`,
                customerEmail: `${userInfoData.email}`,
                successUrl: `${DOMAIN_URL}/#/success`,
                failUrl: `${DOMAIN_URL}/#/fail`,
              })
              .catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                  // 결제 고객이 결제창을 닫았을 때 에러 처리
                }
                if (error.code === 'INVALID_CARD_COMPANY') {
                  // 유효하지 않은 카드 코드에 대한 에러 처리
                }
              });
          } catch (error) {
            // 에러 처리하기
            console.error(error);
          }
        }
      } else {
        // 포인트가 부족할 경우 처리
        if (parseInt(totalPrice) > parseInt(user?.point))
          return MessageAlert.warning(
            '포인트가 부족합니다! 충전 후 다시 이용해주세요'
          );

        // 관심목록에서 제거
        try {
          NewInterestList.filter((item) =>
            selectLecture.includes(item.lecture_id)
          ).forEach((subitem) => {
            deleteInterestList.mutate({
              interest_list_id: subitem.interest_list_id,
            });
          });
        } catch (e) {
          throw e;
        }

        // LectureOfUser에 데이터 추가
        try {
          selectLecture.forEach((item) => {
            createLectureOfUser.mutate({
              user_id: idx,
              lecture_id: item,
              score: 0,
              purchase_state: 'P',
              price: 0,
            });
          });
        } catch (e) {
          throw e;
        }

        // 포인트 차감
        updateUser.mutate({
          user_id: idx,
          point: parseInt(user.point) - parseInt(totalPrice),
        });

        //결제 데이터 생성
        alert('결제가 완료되었습니다.');
        navigate('/mypage/my_learning');
      }
    }
  };

  /**
   * 원화 콤마
   * --
   * @param {*} inputValue
   */
  const handleComma = (v) => {
    return [v].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /**
   * 숫자만 입력
   * --
   * @param {*} inputValue
   */
  const OnlyNumber = (v) => {
    let str = v.replace(/[^0-9]/g, '');
    return str;
  };

  /**
   * 결제방법 선택
   * --
   * @param {*} paymentMethod
   */
  const onChangePayment = ({ target: { value } }) => {
    if (value !== 3) {
      MessageAlert.warning('현재 무통장 입금만 가능합니다.');
    } else {
      setPaymentMethod(value);
    }
  };

  /**
   * 수강바구니 선택 삭제
   * --
   * @param {*} 선택한 강의 value : selectLecture
   */
  const handleListDelete = () => {
    if (selectLecture.length === 0) {
      MessageAlert.warning('강의를 선택해주세요.');
      return;
    } else if (window.confirm('선택한 강의를 삭제하시겠습니까?')) {
      // 좋아요 깅의리스트 부분
      try {
        NewInterestList.filter((item) =>
          selectLecture.includes(item.lecture_id)
        ).forEach((subitem) => {
          deleteInterestList.mutate({
            interest_list_id: subitem.interest_list_id,
          });
        });
      } catch (e) {
        throw e;
      }
      setSelectLecture([]);
      // setCashCheckAll(false);
      setPointCheckAll(false);
      setTotalPrice(0);
      setTotalTitle('강의를 선택해주세요.');
    } else {
      // console.log('삭제취소:');
    }
  };

  /**
   * 포인트사용
   * --
   * @param {*} usePoint
   */
  const handleChangePoint = (e) => {
    let str = OnlyNumber(e);
    if (str === '') {
      MessageAlert.warning('숫자만 입력해 주세요!');
    } else if (usePoint - e > 0) {
      setUsePoint(str);
    } else {
      MessageAlert.warning('포인트가 부족합니다.');
    }
  };

  /**
   * NOTE: 토스 페이먼츠 테스트
   */
  const selector = '#payment-widget';
  // const customerKey = 'YbX2HuSlsC9uVJW6NMRMj';
  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  useEffect(() => {
    (async () => {
      const paymentWidget = await loadPaymentWidget(
        TOSS_CLIENT_KEY,
        TOSS_CUSTOMER_KEY + getCookie('USER')
      );

      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        selector,
        { value: parseInt(totalPrice) }
      );
      paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' });

      paymentWidgetRef.current = paymentWidget;
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(
      parseInt(totalPrice),
      paymentMethodsWidget.UPDATE_REASON.COUPON
    );
  }, [totalPrice]);

  useEffect(() => {}, [totalPrice]);

  useEffect(() => {
    if (!selectLecture.length) setIsCash(null);
  }, [selectLecture]);
  /**
   * NOTE: 토스 페이먼츠 테스트
   */

  /* ===== Render ===== */
  return (
    <>
      <Content maxWidth={1200} padding={'0 12px 0'}>
        <Row>
          <Col x={24} padding={8}>
            <Title
              size={screenSize >= 768 ? 2 : 4}
              padding={8}
              style={{ marginBottom: screenSize >= 768 ? 28 : 10 }}
            >
              수강바구니
            </Title>
          </Col>

          <Col x={24} md={16}>
            <Row
              maxWidth={'100%'}
              padding={0}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row-reverse',
                marginBottom: 10,
              }}
            >
              <Col x={24} md={3}>
                <Col x={24}>
                  <Button
                    onClick={() => handleListDelete()}
                    style={{ float: 'right' }}
                  >
                    선택 삭제
                  </Button>
                </Col>
              </Col>
              <Col x={24} md={21}>
                {/* <Checkbox
                  onChange={(e) => onCheckAllChange(e, 'Y')}
                  checked={cashCheckAll}
                >
                  현금 상품 전체 선택
                </Checkbox> */}
                <Checkbox
                  onChange={(e) => onCheckAllChange(e, 'N')}
                  checked={pointCheckAll}
                >
                  포인트 상품 전체 선택
                </Checkbox>
              </Col>
            </Row>
            <Content
              maxWidth={'100%'}
              padding={0}
              style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
            >
              <Col x={24}>
                {/* 장바구니 리스트 */}
                <Content
                  maxWidth={'100%'}
                  padding={'20px 10px 0 10px'}
                  maxHeight={'400px'}
                  minHeight={'400px'}
                  style={{ overflowY: 'scroll' }}
                >
                  {NewInterestList?.length < 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '400px',
                      }}
                    >
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    NewInterestList?.map((item, index) => (
                      <Row key={`lecture_list_item_${index}`}>
                        <Col
                          x={24}
                          md={1}
                          style={{
                            display: 'flex',
                            alignItems:
                              screenSize >= 768 ? 'stretch' : 'center',
                          }}
                        >
                          <Checkbox
                            style={{
                              fontWeight: 600,
                              fontSize: '20px',
                              lineHeight: '24px',
                              letterSpacing: '0.02em',
                            }}
                            onChange={() =>
                              onChange(
                                item.lecture_id,
                                item.price,
                                item?.is_cash
                              )
                            }
                            checked={
                              selectLecture.includes(item.lecture_id)
                                ? true
                                : false
                            }
                            disabled={
                              moment(item.end_date).format('YYYY-MM-DD') >=
                              today
                                ? false
                                : true
                            }
                          />
                          {screenSize < 768 && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Title
                                size={5}
                                style={{
                                  color:
                                    moment(item.end_date).format(
                                      'YYYY-MM-DD'
                                    ) >= today
                                      ? ''
                                      : '#d9d9d9',
                                  paddingLeft: 10,
                                }}
                              >
                                {item.title}
                              </Title>
                              <div
                                style={{
                                  width: '10%',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  type="link"
                                  shape="circle"
                                  icon={<CloseOutlined />}
                                  style={{
                                    color: '#333',
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                  }}
                                  onClick={() =>
                                    handleDeleteClick(item.interest_list_id)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col x={23}>
                          <Card
                            style={{
                              border: 'none',
                              marginBottom: 25,
                              color:
                                moment(item.end_date).format('YYYY-MM-DD') >=
                                today
                                  ? ''
                                  : '#d9d9d9',
                            }}
                            bodyStyle={{ padding: 0 }}
                            hoverable={false}
                          >
                            <div style={{ display: 'flex' }}>
                              {item?.lectureFiles?.length > 0 ? (
                                <div
                                  className="lecture_card_thumbnail02"
                                  style={{
                                    marginRight: 15,
                                    width: screenSize >= 768 ? '20%' : '40%',
                                    height: '80px',
                                    border: '1px solid #E0E0E0',
                                    background: `url(${
                                      item?.lectureFiles?.length > 0 &&
                                      `${STORE_URL}/lecture/${
                                        item?.lectureFiles
                                          ?.filter((i) => i.type === 'T')
                                          ?.pop()?.file_url
                                      }`
                                    }) no-repeat center center/contain`,
                                  }}
                                ></div>
                              ) : (
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: '50px',
                                    marginRight: 15,
                                    width: screenSize >= 768 ? '20%' : '40%',
                                    height: '80px',
                                    border: '1px solid #E0E0E0',
                                  }}
                                  icon={faImage}
                                />
                              )}

                              {/*  */}
                              <div
                                style={{
                                  width: screenSize >= 768 ? '50%' : '60%',
                                }}
                              >
                                {screenSize >= 768 && (
                                  <Title
                                    size={5}
                                    style={{
                                      color:
                                        moment(item.end_date).format(
                                          'YYYY-MM-DD'
                                        ) >= today
                                          ? ''
                                          : '#d9d9d9',
                                    }}
                                  >
                                    {item.title}
                                  </Title>
                                )}

                                <p style={{ margin: 0, fontSize: '1em' }}>
                                  {item?.category}
                                </p>
                                <p style={{ margin: 0, fontSize: '0.95em' }}>
                                  {item.name}
                                  {item.nickname
                                    ? '/(' + item.nickname + ')'
                                    : ''}
                                </p>
                                {screenSize < 768 && (
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: '1.3em',
                                        fontWeight: 700,
                                      }}
                                    >
                                      {handleComma(item.price)}
                                      {item?.is_cash === 'Y' ? '원' : 'P'}
                                    </p>
                                  </div>
                                )}
                              </div>
                              {/*  */}
                              {screenSize >= 768 && (
                                <div
                                  style={{
                                    width: '30%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: '1.3em',
                                      fontWeight: 700,
                                    }}
                                  >
                                    {handleComma(item.price)}
                                    {item?.is_cash === 'Y' ? '원' : 'P'}
                                  </p>
                                </div>
                              )}

                              {/*  */}
                              {screenSize >= 768 && (
                                <div
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Button
                                    type="link"
                                    shape="circle"
                                    icon={<CloseOutlined />}
                                    style={{
                                      color: '#333',
                                      fontWeight: 'bold',
                                    }}
                                    onClick={() =>
                                      handleDeleteClick(item.interest_list_id)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    ))
                  )}
                </Content>
              </Col>
            </Content>
            <Col x={24} style={{ display: isCash === 'Y' ? 'block' : 'none' }}>
              <div id="payment-widget" />
              <div id="agreement" />
            </Col>
          </Col>
          <Col x={0} md={1} />
          <Col x={0} md={7} style={{ paddingTop: '50px' }}>
            <Card
              style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
              hoverable={false}
            >
              <Title size={4} align="left" style={{ marginBottom: '10px' }}>
                {totalTitle}
              </Title>
              <div>
                <Title
                  style={{ fontSize: '16px' }}
                  subButton={
                    <>
                      <Title align="right">
                        <small>
                          {' '}
                          {handleComma(totalPrice)}
                          {isCash === 'Y' ? '원' : 'P'}
                        </small>
                      </Title>
                    </>
                  }
                >
                  주문 금액
                </Title>
                <Title
                  size={5}
                  subButton={
                    <>
                      <Title align="right">
                        <small> 0</small>
                      </Title>
                    </>
                  }
                >
                  할인 금액
                </Title>
                <Title
                  size={5}
                  subButton={
                    <>
                      <Title align="right">
                        <small> 0</small>
                      </Title>
                    </>
                  }
                >
                  쿠폰 금액
                </Title>
              </div>
              <Divider style={{ margin: '10px 0' }} />
              <div>
                <Title
                  size={5}
                  subButton={
                    <>
                      <Title align="right">
                        <small>
                          {handleComma(totalPrice)}
                          {isCash === 'Y' ? '원' : 'P'}
                        </small>
                      </Title>
                    </>
                  }
                >
                  총 결제금액
                </Title>
              </div>
              <Divider style={{ margin: '10px 0' }} />
              <div style={{ display: 'flex' }}>
                <Checkbox
                  style={{ fontSize: '12px' }}
                  checked={payAgreeCheck}
                  onChange={() => setPayAgreeCheck(!payAgreeCheck)}
                >
                  강의 및 결제 정보를 확인하였으며, 이에 동의합니다.(필수)
                </Checkbox>
              </div>
              <Button
                block
                size="large"
                type="primary"
                style={{ marginTop: 7, background: '#AB5476', border: 'none' }}
                onClick={() => handleOrder()}
              >
                결제하기
              </Button>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col x={24}>
            <Content maxWidth={'100%'} padding={0}>
              <Title size={3}>이런 강의는 어떠세요?</Title>
              <Row>
                {recommendList && recommendList?.length > 0 ? (
                  recommendList?.map((_item, _index) => (
                    <Col
                      x={screenSize >= 768 ? 6 : 12}
                      style={{ padding: 0 }}
                      key={`mn2_${_index}`}
                    >
                      <Card
                        style={styles.contentCardButton}
                        bodyStyle={{ padding: 10 }}
                        onClick={() =>
                          navigate(
                            `/lectures/${encodeURIComponent(
                              encrypt(_item?.lecture_id)
                            )}`
                          )
                        }
                      >
                        <LectureCard
                          thumbnail={
                            _item?.thumbnail &&
                            `${STORE_URL}/lecture/${_item?.thumbnail}`
                          }
                          title={_item?.title}
                          teacher={_item?.name}
                          category={_item?.category}
                          start={_item?.start_date}
                          end={_item?.end_date}
                        />
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col x={24}>
                    <Content
                      maxWidth={'100%'}
                      height={250}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: ' center',
                      }}
                    >
                      <Title>비슷한 카테고리의 강의가 없습니다.</Title>
                    </Content>
                  </Col>
                )}
              </Row>
            </Content>
          </Col>
        </Row>
        <Row style={{ display: 'none' }}>
          <Col x={24}>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Col x={16}>
                <Content
                  maxWidth={'100%'}
                  padding={'20px 10px 20px 10px'}
                  style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
                >
                  <Col x={24}>
                    <Title>쿠폰 및 포인트</Title>
                  </Col>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Col x={8}>
                      <Title size={5} align="left">
                        사용 가능한 쿠폰
                      </Title>
                    </Col>
                    <Col x={16}>
                      <Select
                        bordered={false}
                        style={{
                          width: '80%',
                          padding: '4px 0',
                          borderRadius: '4px',
                          border: '1px solid #BDBDBD',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '14px',
                          margin: '8px 0 10px',
                        }}
                        disabled={coupon?.length > 0 ? false : true}
                        value={
                          coupon?.length > 0
                            ? couponValue
                            : '적용가능한 쿠폰이 없습니다.'
                        }
                        onChange={(e) => setCouponValue(e)}
                        options={coupon}
                      />
                    </Col>
                    <Col x={8}>
                      <Title size={5} align="left">
                        포인트 사용
                      </Title>
                    </Col>
                    <Col x={16}>
                      <Input
                        bordered={false}
                        style={{
                          width: '40%',
                          padding: '10px 0',
                          borderRadius: '4px',
                          border: '1px solid #BDBDBD',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '19px',
                          marginRight: '10px',
                          textAlign: 'center',
                        }}
                        // disabled={userPoint <= 0 ? true : false}
                        value={usePoint}
                        onChange={(e) => handleChangePoint(e.target.value)}
                      />
                      <Button
                        style={{ height: '41px' }}
                        onClick={() => setUsePoint(userPoint)}
                      >
                        전액사용
                      </Button>
                      <small
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#ab5576',
                          marginLeft: '10px',
                        }}
                      >
                        포인트 : {userPoint}
                      </small>
                    </Col>
                  </Row>
                </Content>
              </Col>
              <Col x={1} />
              <Col x={7} />
              <Col x={16} style={{ marginTop: '20px' }}>
                <Content
                  maxWidth={'100%'}
                  padding={'20px 10px 20px 10px'}
                  style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
                >
                  <Col x={24}>
                    <Title>결제방법</Title>
                  </Col>
                  <Col x={24}>
                    <Radio.Group
                      options={plainOptions}
                      onChange={onChangePayment}
                      value={paymentMethod}
                    />
                  </Col>
                </Content>
              </Col>
              <Col x={1} />
              <Col x={7} />
            </Row>
          </Col>
        </Row>
      </Content>

      {screenSize >= 768 ? null : (
        <BottomLayout shadow={true}>
          <Content maxWidth={'100%'} padding={0}>
            <div>
              <Title>{totalTitle}</Title>
              <Title
                size={5}
                color={'#9E9E9E'}
                subButton={
                  <>
                    <Title align="right" color={'#9E9E9E'}>
                      <small>
                        {handleComma(totalPrice)}
                        {isCash === 'Y' ? '원' : 'P'}
                      </small>
                    </Title>
                  </>
                }
              >
                총 결제금액
              </Title>
              <div style={{ display: 'flex' }}>
                <Checkbox
                  style={{ fontSize: '12px' }}
                  checked={payAgreeCheck}
                  onChange={() => setPayAgreeCheck(!payAgreeCheck)}
                >
                  상품 및 결제 정보를 확인하였으며, 이에 동의합니다.(필수)
                </Checkbox>
              </div>
            </div>
            <Button
              block
              size="large"
              type="primary"
              style={{ marginTop: 7, background: '#AB5476', border: 'none' }}
              onClick={() => handleOrder()}
            >
              결제하기
            </Button>
          </Content>
        </BottomLayout>
      )}
    </>
  );
};

LectureCartPresenter.defualtProps = {
  thumbnail: online_bg_01,
};

export default LectureCartPresenter;
