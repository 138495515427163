import React from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  RadioCategory,
  Button,
  LectureCard,
} from 'components';
import { Card, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';

const styles = {
  cardButton: {
    height: 230,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentCardButton: {
    border: 'none',
    padding: 0,
    borderRadius: 0,
    background: 'none',
  },
};

const DiaryList = () => {
  /* ===== Props ===== */

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== State ===== */

  /* ===== Hooks ===== */

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <Content padding={0} maxWidth={'100%'} style={{ margin: '48px 0 84px' }}>
      <Content padding={0} maxWidth={1200}>
        <Row>
          <Col x={24}>
            <Title
              align={'center'}
              style={{
                fontSize: '35px',
                fontWeight: 700,
                lineHeight: '42px',
                letterSpacing: '0.02em',
                marginBottom: '37px',
              }}
            >
              치매예방다이어리
            </Title>
          </Col>
          <Col x={5} style={{ paddingTop: '15px' }}>
            <Card
              hoverable={false}
              bodyStyle={{
                padding: 15,
                border: '1px solid #9999A3',
                borderRadius: '4px',
              }}
            >
              <RadioCategory />
            </Card>
            <br />
            <Card
              hoverable={false}
              bodyStyle={{
                padding: 15,
                border: '1px solid #9999A3',
                borderRadius: '4px',
              }}
            >
              <RadioCategory />
            </Card>
            <br />
            <Card
              hoverable={false}
              bodyStyle={{
                padding: 15,
                border: '1px solid #9999A3',
                borderRadius: '4px',
              }}
            >
              <RadioCategory />
            </Card>
            <br />
            <Card
              hoverable={false}
              bodyStyle={{
                padding: 15,
                border: '1px solid #9999A3',
                borderRadius: '4px',
              }}
            >
              <RadioCategory />
            </Card>
            <br />
            <Button
              type="primary"
              size="large"
              style={{
                background: '#E3A4AF',
                border: 'none',
                width: '100%',
                height: '54px',
              }}
            >
              결과 검색
            </Button>
          </Col>
          <Col x={1}></Col>
          <Col x={18}>
            {/* ===== 온라인교육 ===== */}
            <div
              style={{
                width: '100%',
                padding: 0,
              }}
            >
              <div>
                <Row>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                    (_item, _index) => (
                      <Col
                        x={8}
                        style={{ padding: 0 }}
                        key={`diary_list_item_${_index}`}
                      >
                        <Card
                          hoverable
                          style={styles.contentCardButton}
                          bodyStyle={{ padding: 10 }}
                          onClick={() =>
                            navigate('/diary/detail/CUQYzDzgIJE9ev4mvnmV2C')
                          }
                        >
                          <LectureCard />
                        </Card>
                      </Col>
                    )
                  )}
                </Row>
                <br />
                <br />
                <Pagination
                  defaultCurrent={1}
                  total={50}
                  style={{ textAlign: 'center' }}
                />
              </div>
            </div>
            {/* ===== 온라인교육 ===== */}
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

DiaryList.defaultProps = {};

export default DiaryList;
