import React from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  Button,
  Line,
  TextEditor,
  FileUploadButton,
} from 'components';
import { Input, Select, TreeSelect, DatePicker, InputNumber } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import MessageAlert from 'utils/MessageAlert';
import Validation from 'utils/Validation';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import queryString from 'query-string';
// import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const { RangePicker } = DatePicker;

const LectureRegisterPresenter = (props) => {
  /* ===== Props ===== */
  const {
    type,
    inputData,
    setInputData,
    file,
    onFileChange,
    onFileDelete,
    onChange,
    htmlString,
    setHtmlString,
    category,
    question,
    setQuestion,
    questionnaire,
    setQuestionnaire,
    difficulty,
    screenSize,
    loginWho,
    teachers,
  } = props;

  /* ===== Router ===== */
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;

  /* ===== State ===== */
  const treeData = category?.map((item) => ({
    value: item.category_id,
    title: item.category_name,
    selectable: false,
    children: item.children.map((subitem) => ({
      value: subitem.category_id,
      title: subitem.category_name,
    })),
  }));

  /* ===== Hooks ===== */

  /* ===== Functions ===== */
  /**
   * 강의 입력값 유효성 검사'
   * --
   * @returns
   */
  const handleValueCheck = () => {
    const {
      user_id,
      title,
      major_name,
      category_id,
      difficulty_id,
      price,
      start_date,
      end_date,
    } = inputData;
    // 담당 강사 유효성 검사
    if (loginWho === 'admin' && !user_id) {
      MessageAlert.error('담당 강사를 선택해주세요');
      return false;
    }

    // 제목 유효성 검사
    if (!title || title === '') {
      MessageAlert.error('제목을 입력해주세요');
      return false;
    }

    // 자격증명 유효성 검사
    if (!major_name || major_name === '') {
      MessageAlert.error('자격증명을 입력해주세요');
      return false;
    }

    // 카테고리 유효성 검사
    if (!category_id) {
      MessageAlert.error('카테고리를 선택해주세요');
      return false;
    }

    // 난이도 유효성 검사
    if (!difficulty_id) {
      MessageAlert.error('난이도를 선택해주세요');
      return false;
    }

    // 가격 유효성 검사
    if (price === '' || price === null || price === undefined) {
      MessageAlert.error('가격을 입력해주세요');
      return false;
    } else if (!Validation('number', price)) {
      MessageAlert.error('가격은 숫자만 입력 가능합니다');
      return false;
    }

    if (parseInt(price) < 100) {
      if (parseInt(price) !== 0) {
        MessageAlert.error('가격은 0원(무료) 또는 100원 이상 입력가능합니다');
        return false;
      }
    }

    // 시작일 유효성 검사
    if (!start_date) {
      MessageAlert.error('강의 시작일을 선택해주세요');
      return false;
    }

    // 종료일 유효성 검사
    if (!end_date) {
      MessageAlert.error('강의 종료일을 선택해주세요');
      return false;
    }

    // 내용 유효성 검사
    if (
      !htmlString ||
      htmlString === null ||
      htmlString === undefined ||
      htmlString === '' ||
      htmlString === '<p></p>\n'
    ) {
      MessageAlert.error('강의 내용을 입력해주세요');
      return false;
    }

    // 강의 질문지 입력 유효성 검사
    if (!questionnaire || questionnaire?.length === 0) {
      MessageAlert.error('강의 질문지를 입력해주세요');
      return false;
    }

    return true;
  };

  /**
   * 강의 등록 필수 값 체크 및 navigate 함수
   * --
   */
  const handleNavigate = () => {
    if (handleValueCheck() && type === 'create') {
      navigate('/lectures/register/2');
    } else if (handleValueCheck() && type === 'update') {
      const qs = queryString.parse(search);
      const id = qs.lecture;
      navigate(`/lectures/register/2?lecture=${encodeURIComponent(id)}`);
    }
  };

  /**
   * 질문지 배열에 추가
   * --
   * @param {*} v
   * @returns
   */
  const handleList = (v) => {
    if (!question || question === '') {
      MessageAlert.warning('질문지를 입력해주세요');
      return;
    }
    const value = {
      key: questionnaire?.length + 1,
      q: v,
    };
    const arr = [...questionnaire, value];
    setQuestionnaire(arr);
    setQuestion('');
  };

  /**
   * 아이템 리스트 드래그앤드롭
   * --
   * @param {*} result
   * @returns
   */
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const newItems = Array.from(questionnaire);
    const [removed] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removed);

    setQuestionnaire(newItems);
  };

  /**
   * 질문지 리스트 제거
   * --
   * @param {*} key
   */
  const handleListRemove = (key) => {
    const list = [...questionnaire];
    const newList = list?.filter((item) => item.key !== key);

    setQuestionnaire(newList);
  };

  /**
   * RangeDate 데이터 바인딩 함수
   * --
   * @param {*} dates
   * @param {*} dateStrings
   */
  const dateChange = (dates, dateStrings) => {
    const d = { ...inputData };
    d['start_date'] = dateStrings[0];
    d['end_date'] = dateStrings[1];

    setInputData(d);
  };

  /* ===== Styles ===== */
  const styles = {
    listStyle: {
      borderRadius: '4px',
      background: '#F2D3DB',
      padding: '15px 20px',
      marginBottom: screenSize >= 768 ? 24 : 10,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    listText: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '19px',
      wordBreak: 'break-all',
    },
    labelStyle: {
      fontSize: screenSize >= 768 ? 18 : 16,
      fontWeight: 600,
      lineHeight: screenSize >= 768 ? '22px' : '18px',
      letterSpacing: '0.02em',
    },
  };

  /* ===== Render ===== */
  return (
    <Content maxWidth={'100%'} padding={screenSize >= 768 ? '0 12px' : 0}>
      <Row>
        <Col x={24} padding={8}>
          <Title size={screenSize >= 768 ? 2 : 4} padding={8}>
            강의등록
          </Title>
          <Line
            style={{ border: '1px solid #000000', margin: '16px 0 24px' }}
          />
          <Content maxWidth={'100%'} padding={'0 8px'}>
            {/* 담당 강사 */}
            {loginWho === 'admin' && (
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: screenSize >= 768 ? 24 : 10,
                }}
              >
                <Col x={24} xl={4} md={6}>
                  <label
                    htmlFor="user_id"
                    style={{
                      ...styles.labelStyle,
                    }}
                  >
                    담당강사
                  </label>
                </Col>
                <Col x={24} xl={20} md={18}>
                  <Select
                    size="large"
                    options={teachers?.map((item) => ({
                      value: item.user_id,
                      label: item.name,
                    }))}
                    placeholder="담당 강사를 선택해주세요"
                    style={{ width: '100%' }}
                    value={inputData.user_id}
                    onChange={(e) => onChange('user_id', e)}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').includes(input)
                    }
                  />
                </Col>
              </Row>
            )}

            {/* 강의 제목 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24} xl={4} md={6}>
                <label
                  htmlFor="lecture_title"
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  제목
                </label>
              </Col>
              <Col x={24} xl={20} md={18}>
                <Input
                  id="lecture_title"
                  placeholder="제목을 입력해주세요"
                  size="large"
                  style={{ marginBottom: 10 }}
                  name="title"
                  value={inputData?.title}
                  onChange={(e) => onChange('title', e.target.value)}
                  maxLength={100}
                />
              </Col>
            </Row>
            {/* 자격증명 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24} xl={4} md={6}>
                <label
                  htmlFor="lecture_mjor_name"
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  자격증명
                </label>
              </Col>
              <Col x={24} xl={20} md={18}>
                <Input
                  id="lecture_mjor_name"
                  placeholder="자격증명을 입력해주세요"
                  size="large"
                  style={{ marginBottom: 10 }}
                  name="major_name"
                  value={inputData?.major_name}
                  onChange={(e) => onChange('major_name', e.target.value)}
                  maxLength={100}
                />
              </Col>
            </Row>
            {/* 카테고리 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24} xl={4} md={6}>
                <label
                  htmlFor="lecture_category"
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  카테고리
                </label>
              </Col>
              <Col x={24} xl={20} md={18}>
                <TreeSelect
                  id="lecture_category"
                  treeData={treeData}
                  size="large"
                  placeholder="강의 카테고리를 선택해주세요"
                  style={{ width: '100%' }}
                  value={inputData.category_id}
                  onChange={(e) => onChange('category_id', e)}
                  treeExpandAction="click"
                />
              </Col>
            </Row>
            {/* 난이도 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24} xl={4} md={6}>
                <label
                  htmlFor="lecture_difficulty"
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  난이도
                </label>
              </Col>
              <Col x={24} xl={20} md={18}>
                <Select
                  size="large"
                  options={difficulty?.map((item) => ({
                    value: item.difficulty_id,
                    label: item.level,
                  }))}
                  placeholder="강의 난이도를 선택해주세요"
                  style={{ width: '100%' }}
                  value={inputData.difficulty_id}
                  onChange={(e) => onChange('difficulty_id', e)}
                />
              </Col>
            </Row>
            {/* 가격 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24} xl={4} md={6}>
                <label
                  htmlFor="lecture_price"
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  가격
                </label>
              </Col>
              <Col x={24} xl={20} md={18}>
                <InputNumber
                  id="lecture_price"
                  placeholder="가격을 입력해주세요"
                  size="large"
                  style={{ marginBottom: 10, width: '100%' }}
                  name="price"
                  value={inputData?.price}
                  onChange={(value) => onChange('price', value)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  controls={false}
                />
              </Col>
            </Row>
            {/* 강의기간 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24} xl={4} md={6}>
                <label
                  htmlFor="lecture_start_date"
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  강의 기간
                </label>
              </Col>
              <Col x={24} xl={20} md={18}>
                <RangePicker
                  size="large"
                  style={{ width: '100%' }}
                  popupStyle={{ zIndex: 3600 }}
                  format={'YYYY-MM-DD'}
                  minuteStep={10}
                  value={
                    inputData?.start_date &&
                    inputData?.end_date && [
                      moment(inputData?.start_date, 'YYYY-MM-DD'),
                      moment(inputData?.end_date, 'YYYY-MM-DD'),
                    ]
                  }
                  placeholder={['시작일', '종료일']}
                  // showTime={{
                  //   hideDisabledOptions: true,
                  //   defaultValue:
                  //     inputData?.start_date && inputData?.end_date
                  //       ? [
                  //           dayjs(inputData?.start_date, 'YYYY-MM-DD'),
                  //           dayjs(inputData?.end_date, 'YYYY-MM-DD'),
                  //         ]
                  //       : [null, null],
                  // }}
                  onChange={dateChange}
                />
              </Col>
            </Row>
            {/* 내용 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24}>
                <label
                  htmlFor="lecture_start_date"
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  내용
                </label>
                <TextEditor
                  data={inputData?.content}
                  setHtmlString={setHtmlString}
                  editorStyle={{
                    height: screenSize >= 768 ? 600 : 400,
                  }}
                />
              </Col>
            </Row>
            {/* 썸네일 이미지 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24} xl={4} md={6}>
                <p
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  썸네일
                </p>
              </Col>
              <Col x={24} xl={20} md={18}>
                {file?.thumbnail ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p>{file?.thumbnail?.name}</p>
                    <button
                      style={{
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'none',
                        border: '1px solid #9E9E9E',
                        cursor: 'pointer',
                        fontWeight: 700,
                      }}
                      onClick={() => onFileDelete('thumbnail')}
                    >
                      x
                    </button>
                  </div>
                ) : (
                  <FileUploadButton
                    variable="thumbnail"
                    onChange={onFileChange}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ marginRight: '10px' }}
                    />
                    파일 추가
                  </FileUploadButton>
                )}
              </Col>
            </Row>
            {/* 상단 이미지 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24} xl={4} md={6}>
                <p
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  상단 이미지
                </p>
              </Col>
              <Col x={24} xl={20} md={18}>
                {file?.background ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p>{file?.background.name}</p>
                    <button
                      style={{
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'none',
                        border: '1px solid #9E9E9E',
                        cursor: 'pointer',
                        fontWeight: 700,
                      }}
                      onClick={() => onFileDelete('background')}
                    >
                      x
                    </button>
                  </div>
                ) : (
                  <FileUploadButton
                    variable="background"
                    onChange={onFileChange}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ marginRight: '10px' }}
                    />
                    파일 추가
                  </FileUploadButton>
                )}
              </Col>
            </Row>
            {/* 질문지 */}
            <Row
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: screenSize >= 768 ? 24 : 10,
              }}
            >
              <Col x={24} sm={6}>
                <label
                  htmlFor="lecture_questionnaire"
                  style={{
                    ...styles.labelStyle,
                  }}
                >
                  질문지
                </label>
              </Col>
              <Col x={18} sm={15}>
                <Input
                  id="lecture_questionnaire"
                  placeholder="질문을 입력해주세요"
                  size="large"
                  name="question"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </Col>
              <Col x={6} sm={3}>
                <Button
                  color="secondary"
                  style={{ width: '100%', height: '40.14px', borderRadius: 0 }}
                  onClick={() => handleList(question)}
                >
                  추가
                </Button>
              </Col>
            </Row>
            <Row>
              <Col x={24}>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="items">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {questionnaire?.map((item, index) => (
                          <Draggable
                            key={`${item.key}`}
                            draggableId={`${item.key}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Col x={24}>
                                  <div style={{ ...styles.listStyle }}>
                                    <p style={{ ...styles.listText }}>
                                      Q. {item.q}
                                    </p>
                                    <button
                                      style={{
                                        cursor: 'pointer',
                                        background: 'none',
                                        border: 'none',
                                      }}
                                      onClick={() => handleListRemove(item.key)}
                                    >
                                      x
                                    </button>
                                  </div>
                                </Col>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Col>
            </Row>
            <Row>
              <Col x={24}>
                <Button
                  style={{
                    width: screenSize >= 768 ? 282 : '70%',
                    height: screenSize >= 768 ? 56 : 40,
                    fontSize: screenSize >= 768 ? 20 : 18,
                    fontWeight: 700,
                    padding: 0,
                    margin: screenSize >= 768 ? '120px auto 0' : '60px auto 0',
                  }}
                  onClick={() => handleNavigate()}
                >
                  다음
                </Button>
              </Col>
            </Row>
          </Content>
        </Col>
      </Row>
    </Content>
  );
};

LectureRegisterPresenter.defualtProps = {};

export default LectureRegisterPresenter;
