import React, { useState, useEffect } from 'react';
import BannerRegisterPresenter from './BannerRegisterPresenter';
import { BannerApi } from 'api';
import MessageAlert from 'utils/MessageAlert';
import { FileUploadManager } from 'utils/FileUploadManager';
import { LoadingPage } from 'components';
import { handleImageReader } from 'utils';

const BannerRegisterContainer = (props) => {
  /* ===== Route ===== */
  /* ===== Props ===== */
  const { data, setIsOpened } = props;

  /* ===== Mutate ===== */
  const createBanner = BannerApi.CreateBanner();
  const updateBanner = BannerApi.UpdateBanner();
  const deleteBanner = BannerApi.DeleteBanner();

  /* ===== State ===== */
  // 인풋값
  const [inputValue, setInputValue] = useState({
    title: '',
    pc_file_url: '',
    mobile_file_url: '',
    link_url: '',
    is_show: 'Y',
  });
  // 페이지 상태
  const [registType, setRegistType] = useState('CREATE');
  // 파일
  const [pcFile, setPcFile] = useState(null);
  const [mobileFile, setMobileFile] = useState(null);
  // 로딩
  const [isLoading, setIsLoading] = useState(false);
  // 렌더처리
  const [isRender, setIsRender] = useState(false);

  /* ===== Function ===== */
  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...inputValue };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setInputValue(d);

    if (callback) callback(v);
  };

  /**
   * Submit 에러 핸들러
   * --
   */
  const handleSubmitError = () => {
    const msg = registType === 'CREATE' ? '등록' : '수정';
    MessageAlert.error(`배너를 ${msg}하는데 실패하였습니다!`);
    return;
  };

  /**
   * 배너 등록/수정
   * --
   */
  const handleSubmit = async (type) => {
    // confirm
    const confirm = (txt) => {
      const res = window.confirm(`배너를 ${txt} 하시겠습니까?`);
      return res;
    };

    // submit
    const submit = (func, d) => {
      func.mutate(d);
    };

    // 유효성검사
    const validation = (data) => {
      const { title, pc_file_url, mobile_file_url } = data;
      if (!title || title === '') {
        MessageAlert.warning('타이틀을 입력해주세요!');
        return false;
      }

      if (!pc_file_url && !mobile_file_url) {
        if (!pcFile && !mobileFile) {
          MessageAlert.warning('파일이 등록되지 않았습니다!');
          return false;
        }
      }

      return true;
    };

    switch (type) {
      case 'CREATE':
        if (validation(inputValue)) {
          if (confirm('등록')) {
            try {
              setIsLoading(true);
              let data = {
                ...inputValue,
              };

              if (pcFile) {
                const res = await FileUploadManager('BANNER', pcFile);
                console.log('res', res);

                data = {
                  ...data,
                  pc_file_url: res.name,
                };
              }

              if (mobileFile) {
                const res = await FileUploadManager('BANNER', mobileFile);

                data = {
                  ...data,
                  mobile_file_url: res.name,
                };
              }

              submit(createBanner, data);

              MessageAlert.success('배너가 성공적으로 등록되었습니다');
            } catch (err) {
              MessageAlert.error('배너를 등록하는데 오류가 발생하였습니다!');
              console.log('[BANNER CREATE ERROR] ', err);
              throw err;
            } finally {
              setTimeout(() => setIsLoading(false), 500);
            }
          }
        } else {
          return;
        }
        break;
      case 'UPDATE':
        if (validation(inputValue)) {
          if (confirm('수정')) {
            try {
              setIsLoading(true);
              let data = {
                ...inputValue,
              };

              if (pcFile) {
                const res = await FileUploadManager('BANNER', pcFile);

                data = {
                  ...data,
                  pc_file_url: res.name,
                };
              }

              if (mobileFile) {
                const res = await FileUploadManager('BANNER', mobileFile);

                data = {
                  ...data,
                  mobile_file_url: res.name,
                };
              }

              submit(updateBanner, data);

              MessageAlert.success('배너가 성공적으로 등록되었습니다');
            } catch (err) {
              MessageAlert.error('배너를 수정하는데 오류가 발생하였습니다!');
              console.log('[BANNER UPDATE ERROR] ', err);
              throw err;
            } finally {
              setTimeout(() => setIsLoading(false), 500);
            }
          }
        } else {
          return;
        }
        break;
      default:
        break;
    }

    setInputValue({
      title: '',
      pc_file_url: '',
      mobile_file_url: '',
      link_url: '',
      is_show: 'Y',
    });
    setPcFile(null);
    setMobileFile(null);
    setIsOpened(false);
  };

  /**
   * 배너 삭제
   * --
   */
  const handleDeleteBanner = (id) => {
    if (!id) return MessageAlert.error('배너를 삭제하는데 실패하였습니다!');

    try {
      deleteBanner.mutate({
        banner_id: id,
      });

      MessageAlert.success('배너가 성공적으로 삭제되었습니다.');
      setInputValue({
        title: '',
        pc_file_url: '',
        mobile_file_url: '',
        link_url: '',
        is_show: 'Y',
      });
      setPcFile(null);
      setMobileFile(null);
      setIsOpened(true);
    } catch (err) {
      MessageAlert.error('배너를 삭제하는데 오류가 발생하였습니다!');
      console.log('[BANNER DELETE ERROR] ', err);
      throw err;
    }
  };

  /**
   * 파일 핸들러
   * --
   */
  const handleFile = async (type, file) => {
    const fileType = type.toLocaleUpperCase();
    if (file) {
      const result = await handleImageReader(file);
      const size = {
        PC: {
          width: 1920,
          height: 700,
        },
        MOBILE: {
          width: 760,
          height: 400,
        },
      };

      if (JSON.stringify(size[fileType]) !== JSON.stringify(result)) {
        const confirm = window.confirm(
          `${size[fileType].width}x${size[fileType].height} 규격의 이미지가 아닙니다.\n${size[fileType].width}x${size[fileType].height} 사이즈가 아닐 경우 이미지가 깨질수 있습니다.\n등록하시겠습니까?`,
          '등록'
        );
        if (!confirm) {
          return;
        }
      }
    }

    switch (fileType) {
      case 'PC':
        setPcFile(file);
        break;
      case 'MOBILE':
        setMobileFile(file);
        break;
      default:
        break;
    }
  };

  /* ===== Hooks ===== */
  /**
   * 배너 등록 상태값 렌더링
   * --
   */
  useEffect(() => {
    if (data) {
      setInputValue(data);
      setRegistType('UPDATE');
    } else {
      setRegistType('CREATE');
    }
    setIsRender(false);
  }, [data]);

  /* ===== Render ===== */
  return (
    <>
      {isLoading && <LoadingPage />}
      <BannerRegisterPresenter
        inputValue={inputValue}
        registType={registType}
        pcFile={pcFile}
        mobileFile={mobileFile}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onError={handleSubmitError}
        onDelete={handleDeleteBanner}
        onFile={handleFile}
        isRender={isRender}
        setIsRender={setIsRender}
      />
    </>
  );
};

export default BannerRegisterContainer;
