import React from 'react';
import LectureFavoritesPresenter from './LectureFavoritesPresenter';
import { useQueryClient } from 'react-query';
import { getCookie } from 'utils';

const LectureFavoritesContainer = () => {
  /* ===== ROUTE ===== */
  /* ===== DATA ===== */
  const queryClient = useQueryClient();

  const lectures = queryClient.getQueryData('getLectures')?.data;
  const lectureFiles = queryClient.getQueryData('getLectureFiles')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const interestLists = queryClient.getQueryData('getInterestLists')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;

  const idx = parseInt(getCookie('USER'));
  /* ===== STATE ===== */
  /* ===== FUNCTION ===== */
  /* ===== HOOKS ===== */
  /* ===== RENDER ===== */
  return (
    <LectureFavoritesPresenter
      lectures={lectures}
      lectureFiles={lectureFiles}
      users={users}
      interestLists={interestLists}
      categories={categories}
      idx={idx}
    />
  );
};

LectureFavoritesContainer.defaultProps = {};

export default LectureFavoritesContainer;
