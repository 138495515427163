import { useQuery, useMutation } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath, setCookie } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 강사 로그인
   * --
   * @method POST
   * @returns
   */
  LoginTeacher: async (data) => {
    try {
      let status;
      await $http.post(APIConstant.TEACHER_LOGIN, data).then((res) => {
        if (res.status !== 200) {
          status = 500;
        } else {
          setCookie('BRAINHEAL_ACCESS_TOKEN', res.data.token);
          setCookie('USER', res.data.teacher_id);
          status = 200;
        }
      });

      return status;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetTeachers: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getTeachers', params] : 'getTeachers',
        async () => await $http.get(APIConstant.GET_TEACHERS, params),
        options
      );

      return {
        data: res?.data,
        status: res?.status,
        message: res?.message,
      };
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetTeacher: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_TEACHER, { teacher_id: idx });
      const res = useQuery(
        params ? ['getTeacher', params] : 'getTeacher',
        async () => await $http.get(url, params),
        options
      );

      return {
        data: res?.data,
        status: res?.status,
        message: res?.message,
      };
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 생성
   * --
   * @method POST
   * @returns
   */
  CreateTeacher: (options = {}) => {
    try {
      const res = useMutation(
        async (data) => await $http.post(APIConstant.CREATE_TEACHER, data),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateTeacher: (options = {}) => {
    try {
      const res = useMutation(async (data) => {
        const url = parameterToPath(APIConstant.UPDATE_TEACHER, {
          teacher_id: data.teacher_id,
        });
        await $http.put(url, data);
      }, options);

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 강사 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteTeacher: (params = null, options = {}) => {
    try {
      const res = useMutation(async (data) => {
        const url = parameterToPath(APIConstant.UPDATE_TEACHER, {
          teacher_id: data.teacher_id,
        });
        await $http.delete(url, params);
      }, options);

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
