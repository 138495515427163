import React, { useState, useEffect } from 'react';
import ProductOrdersPresenter from './ProductOrdersPresenter';

const ProductOrdersContainer = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);
  const [productData, setProductData] = useState([]);
  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { users, teachers, agencies, products } = data;
      if (users && teachers && products) {
        const mergeUsers = [...users, ...teachers, ...agencies];
        const newUsers = mergeUsers?.map((item) => ({
          ...item,
          profile_img: item?.files?.filter((subitem) => subitem?.type === 'P'),
        }));
        setNewData(newUsers);
        setProductData(products);
      }
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <ProductOrdersPresenter userList={newData} productList={productData} />
  );
};

ProductOrdersContainer.defaultProps = {};

export default ProductOrdersContainer;
