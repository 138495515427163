import { useQuery, useMutation, useQueryClient } from 'react-query';
import APIConstant from '../APIConstant';
import APIManager from '../APIManager';
import { parameterToPath } from 'utils';

const $http = new APIManager();

const Api = {
  /**
   * 교안 전체 조회
   * --
   * @method GET
   * @returns
   */
  GetPrograms: (params = null, options = {}) => {
    try {
      const res = useQuery(
        params ? ['getPrograms', params] : 'getPrograms',
        async () => await $http.get(APIConstant.GET_PROGRAMS, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 교안 상세 조회
   * --
   * @method GET
   * @returns
   */
  GetProgram: (idx, params = null, options = {}) => {
    try {
      const url = parameterToPath(APIConstant.GET_PROGRAM, {
        program_id: idx,
      });
      const res = useQuery(
        params ? ['getProgram', params] : 'getProgram',
        async () => await $http.get(url, params),
        options
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 교안 생성
   * --
   * @method POST
   * @returns
   */
  CreateProgram: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const response = await $http.post(APIConstant.CREATE_PROGRAM, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getPrograms');
            queryClient.invalidateQueries('getProgram');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 교안 수정
   * --
   * @method PUT
   * @returns
   */
  UpdateProgram: (options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.UPDATE_PROGRAM, {
            program_id: data.program_id,
          });
          const response = await $http.put(url, data);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getPrograms');
            queryClient.invalidateQueries('getProgram');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },

  /**
   * 교안 삭제
   * --
   * @method DELETE
   * @returns
   */
  DeleteProgram: (params = null, options = {}) => {
    const queryClient = useQueryClient();
    try {
      const res = useMutation(
        async (data) => {
          const url = parameterToPath(APIConstant.DELETE_PROGRAM, {
            program_id: data.program_id,
          });
          const response = await $http.delete(url, params);
          return response;
        },
        {
          ...options,
          onSuccess: (data) => {
            if (options.onSuccess) {
              options.onSuccess(data);
            }
            queryClient.invalidateQueries('getPrograms');
            queryClient.invalidateQueries('getProgram');
          },
        }
      );

      return res;
    } catch (e) {
      throw e;
    }
  },
};

export default Api;
