/*
 * 검색창 컴포넌트
 * --
 * date: 2022-09-05
 * writer: Owen
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Search.style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
 * faMagnifyingGlass : 돋보기 모양 아이콘
 * faCircleXmark : x 모양 아이콘
 */
import {
  faMagnifyingGlass,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';

const Search = ({
  value,
  setValue,
  type,
  placeholder,
  maxLength,
  disabled,
  width,
  height,
  color,
  fontSize,
  fontWeight,
  onSearch,
  isFocus,
}) => {
  // input 박스 focus 상태 설정
  const [inputState, setInputState] = useState(false);

  return (
    <div
      className="search-layout-background"
      style={{
        width: `${width}`,
        height: `${height}`,
        background: disabled && '#F5F5F5',
        border: isFocus && inputState && '1px solid #03A9F4',
      }}
    >
      <div className="search-layout">
        <input
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onFocus={() => setInputState(true)}
          onBlur={() => setInputState(false)}
          onChange={(e) => setValue(e.target.value)}
          style={{
            color: disabled ? '#BDBDBD' : color,
            fontSize: `${fontSize}`,
            fontWeight: `${fontWeight}`,
          }}
        />
        {value !== '' && (
          <button
            onClick={() => setValue('')}
            style={{ pointerEvents: disabled && 'none' }}
          >
            <FontAwesomeIcon
              icon={faCircleXmark}
              style={{
                color: disabled ? '#BDBDBD' : color,
                cursor: disabled && 'default',
              }}
            />
          </button>
        )}
        <p className="search-icon">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ color: disabled ? '#BDBDBD' : color }}
            onClick={onSearch}
          />
        </p>
      </div>
    </div>
  );
};

Search.defaultProps = {
  value: '',
  type: 'text',
  placeholder: '검색어를 입력해주세요',
  maxLength: 50,
  disabled: false,
  width: '340px',
  height: '56px',
  color: '#000',
  fontSize: '16px',
  fontWeight: 400,
  isFocus: false,
};

Search.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Search;
