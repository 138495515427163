import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './JournalLayout.style.css';
import { decrypt } from 'utils/Crypto';
import moment from 'moment';
import { Content, Row, Col, Button, LoadingPage } from 'components';
import { STORE_URL } from 'utils';
import MessageAlert from 'utils/MessageAlert';
import { TemplateApi } from 'api';

const JournalLayout = () => {
  /* ===== ROUTE ===== */
  const [searchParams] = useSearchParams();

  /* ===== MUTATE ===== */
  const template = TemplateApi.GetTemplatePdf({
    onSuccess: async (d) => {
      const { status, data } = d;

      if (status === 200) {
        const uint8Array = new Uint8Array(data.pdf.data);
        const blob = new Blob([uint8Array], {
          type: 'application/pdf',
        });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${data.title ? data?.title : 'brainheal'}.pdf`;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      }
    },
  });

  /* ===== STATE ===== */
  const [decodeValue, setDecodeValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  /* ===== FUNCTION ===== */
  const handlePDFDownload = () => {
    try {
      setIsLoading(true);
      const div = document.getElementById('journal-layout');
      //   console.log(div.outerHTML);
      const title = `${decodeValue?.year}년 ${decodeValue?.month}월 ${decodeValue?.teacher_name}강사님 ${decodeValue?.program} 프로그램 강사 활동 계획안`;

      template.mutate({
        type: 'signature',
        template_value: {
          title: title,
          html_value: div.outerHTML,
        },
      });
    } catch (err) {
      MessageAlert.error('PDF를 다운로드하는데 실패하였습니다!');
      throw err;
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  /* ===== HOOKS ===== */
  useEffect(() => {
    const data = searchParams.get('data');
    const decodeData = JSON.parse(decrypt(data));

    if (decodeData) {
      setDecodeValue(decodeData);
    }
    // eslint-disable-next-line
  }, []);
  console.log('decodeValue', decodeValue);
  return (
    <>
      {isLoading && <LoadingPage text={'다운로드 준비중입니다.'} />}
      <Content maxWidth={'100%'} padding={30}>
        <Row>
          <Col x="24">
            <div id="journal-layout">
              <h1>브레인힐 강사 활동 계획안</h1>

              <table className="journal-table01">
                <tbody>
                  <tr>
                    <th>프로그램명</th>
                    <td>{decodeValue?.program}</td>
                    <th>요양기관명</th>
                    <td>{decodeValue?.agency_name}</td>
                  </tr>
                  <tr>
                    <th>활동월</th>
                    <td>{decodeValue?.month}월</td>
                    <th>시간</th>
                    <td>
                      {decodeValue?.start_time} - {decodeValue?.end_time}
                    </td>
                  </tr>
                  <tr>
                    <th>강사명</th>
                    <td>{decodeValue?.teacher_name}</td>
                    <th></th>
                    <td></td>
                    {/* <th>활동장소</th>
                  <td>{decodeValue?.address}</td> */}
                  </tr>
                </tbody>
              </table>

              <table className="journal-table02">
                <colgroup>
                  <col width={'10%'} />
                  <col width={'15%'} />
                  <col width={'40%'} />
                  <col width={'15%'} />
                  <col width={'5%'} />
                  <col width={'15%'} />
                </colgroup>
                <thead>
                  <tr>
                    <th>날짜</th>
                    <th>제목</th>
                    <th>프로그램 내용</th>
                    <th>사진</th>
                    <th colSpan={2}>서명</th>
                  </tr>
                </thead>
                <tbody>
                  {decodeValue?.schedules?.map((item, index) => (
                    <React.Fragment key={`item-${index}`}>
                      <tr>
                        <td rowSpan={2}>
                          {moment(item?.start_date).format('MM/DD')}
                        </td>
                        <td rowSpan={2}>{item?.content?.title}</td>
                        <td rowSpan={2} className="pre-container">
                          <pre>{item?.content?.goal}</pre>
                        </td>
                        <td rowSpan={2}>
                          <div>
                            {item?.content?.files?.length ? (
                              <img
                                src={`${item?.content?.files[0]}`}
                                alt="교안 이미지"
                              />
                            ) : null}
                          </div>
                        </td>
                        <td className="journal-sign">
                          <div>강사</div>
                        </td>
                        <td className="journal-sign-img">
                          <div
                            style={{
                              background:
                                item?.user_check && item?.signature
                                  ? `url(${STORE_URL}/users/${item?.user_id}/${item?.signature}) no-repeat center center/contain`
                                  : null,
                            }}
                          ></div>
                        </td>
                      </tr>
                      <tr>
                        <td className="journal-sign">
                          <div>담당</div>
                        </td>
                        <td className="journal-sign-img">
                          <div
                            style={{
                              background:
                                item?.agency_check && item?.agency_signature
                                  ? `url(${STORE_URL}/agency/${item?.agency_id}/${item?.agency_signature}) no-repeat center center/contain`
                                  : null,
                            }}
                          ></div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <Button
              onClick={() => handlePDFDownload()}
              style={{ width: '50%', margin: '50px auto', height: 54 }}
            >
              PDF 저장하기
            </Button>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default JournalLayout;
