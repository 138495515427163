/**
 *
 *
 *
 */
import React, { useState, useEffect } from 'react';
import { Pagination } from 'antd';
import {
  Title,
  Row,
  Col,
  Card,
  Content,
  Category,
  LectureCard,
  RadioCategory,
  LoadingPage,
  ModalLayout,
  Button,
} from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { decrypt, encrypt } from 'utils/Crypto';
import {
  STORE_URL,
  // getCookie
} from 'utils';
import { FilterOutlined } from '@ant-design/icons';

const styles = {
  cardButton: {
    height: 230,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentCardButton: {
    border: 'none',
    padding: 0,
    borderRadius: 0,
    background: 'none',
  },
};

/**
 * [Component] Main
 * --
 */
function Main(props) {
  /* ===== Props ===== */

  /* ===== Router ===== */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get('category_id');

  /* ===== Data ===== */
  const queryClient = useQueryClient();
  const lectures = queryClient.getQueryData('getLectures')?.data;
  const lectureFiles = queryClient.getQueryData('getLectureFiles')?.data;
  const users = queryClient.getQueryData('getUsers')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  const difficulties = queryClient.getQueryData('getDifficulties')?.data;

  const today = new Date();
  const NewLectures = lectures?.map((item) => ({
    ...item,
    ...(
      users && users?.filter((subitem) => item.user_id === subitem.user_id)
    )?.pop(),
    thumbnail: lectureFiles?.filter(
      (subitem) =>
        item?.lecture_id === subitem?.lecture_id && subitem?.type === 'T'
    )[0]?.file_url,
    category: categories?.filter(
      (subitem) => item?.category_id === subitem?.category_id
    )[0]?.category_name,
    parents_id: categories?.filter(
      (subitem) => item?.category_id === subitem?.category_id
    )[0]?.parents_id,
    status:
      new Date(item?.start_date) >= today
        ? '출시예정'
        : new Date(item?.end_date) <= today
        ? '종료'
        : new Date(item?.start_date) >=
            new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000) &&
          new Date(item?.start_date) <=
            new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)
        ? 'NEW'
        : '진행중',
    created_at: item?.created_at,
  }));
  // console.log('NewLectures', NewLectures);

  // const filterLectures = NewLectures?.filter(
  //   (item) =>
  //     new Date(item?.start_date) <= today && new Date(item?.end_date) >= today
  // );

  /* ===== State ===== */
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  const [filterItem01, setFilterItem01] = useState([]);
  const [filterOption01, setFilterOption01] = useState('all');
  const [filterItem02, setFilterItem02] = useState([]);
  const [filterOption02, setFilterOption02] = useState('all');
  const filterItem03 = [
    {
      label: '전체',
      value: '0~9999999999999',
    },
    {
      label: '1만원이하',
      value: '0~10000',
    },
    {
      label: '1만원 ~ 3만원',
      value: '10000~30000',
    },
    {
      label: '3만원 ~ 5만원',
      value: '30000~50000',
    },
    {
      label: '5만원이상',
      value: '50000~9999999999999',
    },
  ];
  const [filterOption03, setFilterOption03] = useState(filterItem03[0].value);
  const filterItem04 = [
    {
      label: '전체',
      value: 'all',
    },
    {
      label: '새로운 강의',
      value: 'NEW',
    },
    {
      label: '진행중인 강의',
      value: '진행중',
    },
    {
      label: '출시예정 강의',
      value: '출시예정',
    },
    {
      label: '종료된 강의',
      value: '종료',
    },
  ];
  const [filterOption04, setFilterOption04] = useState(filterItem04[0].value);
  // const filterItem05 = [
  //   {
  //     label: '전체',
  //     value: 'all',
  //   },
  //   {
  //     label: '포인트 결제',
  //     value: 'N',
  //   },
  //   {
  //     label: '현금 결제',
  //     value: 'Y',
  //   },
  // ];
  // const [filterOption05, setFilterOption05] = useState(filterItem05[0].value);
  const [lectureList, setLectureList] = useState(NewLectures);
  const [dependency, setDependency] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [subFilterList, setSubFilterList] = useState([]);
  const [category, setCategory] = useState('all');
  const [page, setPage] = useState(1);
  const [isOpened, setIsOpened] = useState(false);

  /* ===== Hooks ===== */
  /**
   * 카테고리, 난이도 아이템 정제
   * --
   */
  useEffect(() => {
    if (categories && difficulties) {
      const category = categories?.filter((item) => item?.type === 'P');
      const categoryoptions = [
        {
          label: '전체',
          value: 'all',
        },
        ...(category &&
          category?.map((item) => ({
            label: item?.category_name,
            value: item?.category_id,
          }))),
      ];
      setFilterItem01(categoryoptions);

      const difficultyOptions = [
        {
          label: '전체',
          value: 'all',
        },
        ...(difficulties &&
          difficulties?.map((item) => ({
            label: item?.level,
            value: item?.difficulty_id,
          }))),
      ];
      setFilterItem02(difficultyOptions);
    }
  }, [categories, difficulties]);

  /**
   * 쿼리스트링에 따른 카테고리 값 추출
   * --
   */
  useEffect(() => {
    if (categories) {
      if (categoryId) {
        const id = categories?.filter(
          (item) => item?.category_id === parseInt(decrypt(categoryId))
        )[0]?.category_id;

        setFilterOption01(id);

        const subFilter = categories
          ?.filter((item) => item?.parents_id === id)
          ?.map((item) => ({
            label: item?.category_name,
            value: `${item?.category_id}`,
          }));
        setSubFilterList([{ label: '전체', value: 'all' }, ...subFilter]);
      } else {
        setFilterOption01('all');

        const subFilter = categories
          ?.filter((item) => item?.type === 'C')
          ?.map((item) => ({
            label: item?.category_name,
            value: item?.category_id,
          }));
        setSubFilterList([{ label: '전체', value: 'all' }, ...subFilter]);
      }
    }
  }, [categoryId, categories]);

  /**
   * 카테고리별 강의 아이템 정제
   * --
   */
  useEffect(() => {
    const filterData = () => {
      if (categoryId) {
        const newLecture = NewLectures?.filter(
          (item) =>
            item?.category_id === parseInt(decrypt(categoryId)) ||
            item?.parents_id === parseInt(decrypt(categoryId))
        );
        setLectureList(newLecture);
      } else {
        setLectureList(NewLectures);
      }
    };

    if (NewLectures && !dependency && lectureFiles) {
      filterData();
      setDependency(true);
    }
  }, [categoryId, dependency, NewLectures, lectureFiles]);

  /**
   * 로딩 처리
   * --
   */
  useEffect(() => {
    if (isLoding) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [isLoding]);

  /**
   * TODO: eslint-disable-next-line 없이 경고창 제거
   * 서브 카테고리별 강의 검색
   * --
   */
  useEffect(() => {
    const filterData = NewLectures?.filter((item) =>
      category !== 'all' ? item?.category_id === parseInt(category) : item
    )?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));

    setLectureList(filterData);
    // eslint-disable-next-line
  }, [category]);

  /**
   * TODO: eslint-disable-next-line 없이 경고창 제거
   * 필터별 강의 검색
   * --
   */
  useEffect(() => {
    // setIsLoading(true);
    if (NewLectures && NewLectures?.length > 0) {
      const range = filterOption03?.split('~');

      const newLectures = NewLectures?.filter((item) =>
        filterOption01 !== 'all'
          ? item?.category_id === filterOption01 ||
            item?.parents_id === filterOption01
          : item
      )
        ?.filter((item) =>
          filterOption02 !== 'all'
            ? item?.difficulty_id === filterOption02
            : item
        )
        // ?.filter((item) =>
        //   filterOption05 !== 'all' ? item?.is_cash === filterOption05 : item
        // )
        ?.filter(
          (item) =>
            parseInt(range[0]) <= item?.price &&
            item?.price <= parseInt(range[1])
        )
        ?.filter((item) =>
          filterOption04 !== 'all' ? item?.status === filterOption04 : item
        )
        ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));

      setLectureList(newLectures);

      const subFilter = categories
        ?.filter((item) =>
          filterOption01 !== 'all'
            ? item?.parents_id === filterOption01
            : item?.type === 'C'
        )
        ?.map((item) => ({
          label: item?.category_name,
          value: `${item?.category_id}`,
        }));
      setSubFilterList([{ label: '전체', value: 'all' }, ...subFilter]);
    }
    // eslint-disable-next-line
  }, [
    filterOption01,
    filterOption02,
    filterOption03,
    filterOption04,
    // filterOption05,
    categories,
  ]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /* ===== Functions ===== */
  /**
   * 상세화면
   * --
   * @param {*} id
   */
  const handleNavigate = (id) => {
    navigate(`/lectures/${encodeURIComponent(encrypt(id))}`);
  };

  /**
   * 모달
   * --
   */
  const handleFilterModal = (open) => {
    setIsOpened(!open);
  };

  /* ===== Render ===== */
  return isLoding ? (
    <LoadingPage />
  ) : (
    <>
      {/* ===== 배너 ===== */}
      <Content padding={0} maxWidth={'100%'} style={{ margin: '48px 0 84px' }}>
        <Content padding={0} maxWidth={1200}>
          <Row>
            <Col x={0} xl={5}>
              <Card
                hoverable={false}
                bodyStyle={{
                  padding: 15,
                  border: '1px solid #9999A3',
                  borderRadius: '4px',
                }}
              >
                <RadioCategory
                  title={'카테고리별'}
                  items={filterItem01}
                  value={filterOption01}
                  onChange={setFilterOption01}
                />
              </Card>
              <br />
              <Card
                hoverable={false}
                bodyStyle={{
                  padding: 15,
                  border: '1px solid #9999A3',
                  borderRadius: '4px',
                }}
              >
                <RadioCategory
                  title={'난이도별'}
                  items={filterItem02}
                  value={filterOption02}
                  onChange={setFilterOption02}
                />
              </Card>
              {/* <br />
              <Card
                hoverable={false}
                bodyStyle={{
                  padding: 15,
                  border: '1px solid #9999A3',
                  borderRadius: '4px',
                }}
              >
                <RadioCategory
                  title={'결제수단별'}
                  items={filterItem05}
                  value={filterOption05}
                  onChange={setFilterOption05}
                />
              </Card> */}
              <br />
              <Card
                hoverable={false}
                bodyStyle={{
                  padding: 15,
                  border: '1px solid #9999A3',
                  borderRadius: '4px',
                }}
              >
                <RadioCategory
                  title={'금액별'}
                  items={filterItem03}
                  value={filterOption03}
                  onChange={setFilterOption03}
                />
              </Card>
              <br />
              <Card
                hoverable={false}
                bodyStyle={{
                  padding: 15,
                  border: '1px solid #9999A3',
                  borderRadius: '4px',
                }}
              >
                <RadioCategory
                  title={'상태별'}
                  items={filterItem04}
                  value={filterOption04}
                  onChange={setFilterOption04}
                />
              </Card>
            </Col>
            <Col x={0} xl={1}></Col>
            <Col x={24} xl={18}>
              {/* ===== 온라인교육 ===== */}
              <div
                style={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <div style={{ padding: '0 10px' }}>
                  <Content
                    maxWidth={'100%'}
                    padding={0}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '29px',
                    }}
                  >
                    <Title size={3}>온라인 교육</Title>
                    {screenSize >= 1200 ? null : (
                      <p
                        style={{
                          fontSize: '14px',
                          cursor: 'pointer',
                          color: '#9E9E9E',
                        }}
                        onClick={() => handleFilterModal(isOpened)}
                      >
                        필터
                        <span>
                          <FilterOutlined
                            style={{ fontSize: '18px', color: '#E3A4AF' }}
                          />
                        </span>
                      </p>
                    )}
                  </Content>

                  <Category
                    items={subFilterList}
                    value={category}
                    setValue={setCategory}
                    style={{ margin: '29px 0 23px' }}
                  />
                </div>

                <div>
                  <Row>
                    {lectureList && lectureList.length > 0 ? (
                      lectureList?.map(
                        (_item, _index) =>
                          _index >= 0 + 12 * (page - 1) &&
                          _index <= 11 + 12 * (page - 1) && (
                            <Col
                              x={12}
                              xl={8}
                              lg={6}
                              md={8}
                              style={{ padding: 0 }}
                              key={`lecture_list_item_${_index}`}
                            >
                              <Card
                                style={styles.contentCardButton}
                                bodyStyle={{ padding: 10 }}
                                onClick={() =>
                                  handleNavigate(_item?.lecture_id)
                                }
                              >
                                <LectureCard
                                  thumbnail={
                                    _item?.thumbnail &&
                                    `${STORE_URL}/lecture/${_item?.thumbnail}`
                                  }
                                  title={_item?.title}
                                  teacher={
                                    _item?.is_show === 'Y' ? _item?.name : ''
                                  }
                                  category={_item?.category}
                                  start={_item?.start_date}
                                  end={_item?.end_date}
                                  event={_item?.status}
                                  eventColor={{
                                    color:
                                      _item?.status === 'NEW'
                                        ? '#FAD200'
                                        : _item?.status === '진행중'
                                        ? '#2AC769'
                                        : _item?.status === '종료'
                                        ? '#FF6262'
                                        : '#757575',
                                    fontWeight: 700,
                                    border: '1px solid #EEEEEE',
                                  }}
                                />
                              </Card>
                            </Col>
                          )
                      )
                    ) : (
                      <Col x={24}>
                        <Content
                          maxWidth={'100%'}
                          height={800}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <h4 style={{ fontSize: '30px' }}>
                            해당 조건을 만족하는 강의가 없습니다
                          </h4>
                        </Content>
                      </Col>
                    )}
                  </Row>
                  <br />
                  <br />
                  {lectureList && lectureList?.length > 0 ? (
                    <Pagination
                      defaultCurrent={1}
                      current={page}
                      defaultPageSize={12}
                      total={lectureList?.length}
                      onChange={(e) => setPage(e)}
                      style={{ textAlign: 'center' }}
                    />
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </Content>
      </Content>

      {/* ===== 필터모달 ===== */}
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'right'}
        width={'70%'}
        height={'100%'}
        closable
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
        footerStyle={{ padding: 0 }}
        footer={
          <Content maxWidth={'100%'} padding={0}>
            <Button
              style={{
                width: '100%',
                height: '50px',
                borderRadius: 0,
                fontSize: '16px',
              }}
              onClick={() => handleFilterModal(isOpened)}
            >
              확인
            </Button>
          </Content>
        }
      >
        <Content maxWidth={'100%'}>
          <Card
            hoverable={false}
            bodyStyle={{
              padding: 15,
              border: '1px solid #9999A3',
              borderRadius: '4px',
            }}
          >
            <RadioCategory
              title={'카테고리별'}
              items={filterItem01}
              value={filterOption01}
              onChange={setFilterOption01}
            />
          </Card>
          <br />
          <Card
            hoverable={false}
            bodyStyle={{
              padding: 15,
              border: '1px solid #9999A3',
              borderRadius: '4px',
            }}
          >
            <RadioCategory
              title={'난이도별'}
              items={filterItem02}
              value={filterOption02}
              onChange={setFilterOption02}
            />
          </Card>
          <br />
          <Card
            hoverable={false}
            bodyStyle={{
              padding: 15,
              border: '1px solid #9999A3',
              borderRadius: '4px',
            }}
          >
            <RadioCategory
              title={'금액별'}
              items={filterItem03}
              value={filterOption03}
              onChange={setFilterOption03}
            />
          </Card>
          <br />
          <Card
            hoverable={false}
            bodyStyle={{
              padding: 15,
              border: '1px solid #9999A3',
              borderRadius: '4px',
            }}
          >
            <RadioCategory
              title={'상태별'}
              items={filterItem04}
              value={filterOption04}
              onChange={setFilterOption04}
            />
          </Card>
        </Content>
      </ModalLayout>
    </>
  );
}

Main.defaultProps = {};

export default Main;
