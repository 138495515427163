import React from 'react';
import './PaymentFail.style.css';
import { useNavigate } from 'react-router-dom';
import { Content, Row, Col, Title, Button } from 'components';
import { CloseCircleOutlined } from '@ant-design/icons';

const PaymentFail = () => {
  /* ===== ROUTE ===== */
  const navigate = useNavigate();

  /* ===== MUTATE ===== */

  /* ===== STATE ===== */

  /* ===== FUNCION ===== */

  return (
    <Content maxWidth={'100%'}>
      <Content maxWidth={500} padding={'80px 0'}>
        <Row>
          <Col x={24}>
            <Content maxWidth={'100%'}>
              <div
                style={{
                  width: '100%',
                  height: '150px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <CloseCircleOutlined
                  style={{ fontSize: '80px', color: '#FB4E4E' }}
                />
                <Title style={{ marginTop: '30px', fontSize: '30px' }}>
                  <span style={{ color: '#AB5476' }}>결제요청</span>에
                  <span style={{ color: '#AB5476', marginLeft: '10px' }}>
                    실패
                  </span>
                  하였습니다.
                </Title>
              </div>
              <Title
                align={'left'}
                style={{
                  marginTop: '100px',
                  paddingBottom: '10px',
                  borderBottom: '2px solid #9E9E9E',
                  fontSize: '22px',
                }}
              >
                결제정보
              </Title>
              <Content maxWidth={'100%'} padding={'15px 0'}></Content>
            </Content>
          </Col>
          <Col x={24}>
            <Button
              style={{ margin: '30px auto', width: '300px', height: '50px' }}
              onClick={() => navigate(-2)}
            >
              뒤로가기
            </Button>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default PaymentFail;
