import React, { useState, useEffect, useRef } from 'react';
import { Content, Row, Col, Title, Button } from 'components';
import MessageAlert from 'utils/MessageAlert';
import { Table, Space, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { encrypt } from 'utils/Crypto';
import moment from 'moment';
import { EventApi } from 'api';
import DOMPurify from 'dompurify';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const EventDetailPresenter = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== Mutate ===== */
  const deleteEvent = EventApi.DeleteEvent();

  /* ===== State ===== */
  const [newData, setNewData] = useState({});
  // console.log('newData', newData);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${title}을(를) 입력해주세요`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            검색
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            초기화
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', '이름'),
      render: (_, { name }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {name}
        </Title>
      ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone', '연락처'),
      render: (_, { phone }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {phone}
        </Title>
      ),
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', '이메일'),
      render: (_, { email }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {email}
        </Title>
      ),
    },
    {
      title: '이벤트 참여일',
      dataIndex: 'participation_date',
      key: 'participation_date',
      sorter: (a, b) =>
        new Date(a?.participation_date) - new Date(b?.participation_date),
      render: (_, { participation_date }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {moment(participation_date)?.format('YYYY-MM-DD')}
        </Title>
      ),
    },
    {
      title: '진행상태',
      dataIndex: 'event_state',
      key: 'event_state',
      filers: [
        {
          text: '진행중',
          value: 'P',
        },
        {
          text: '완료',
          value: 'C',
        },
      ],
      onFilter: (value, record) =>
        value ? record?.event_state === value : record?.event_state,
      render: (_, { event_state }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          <div
            style={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '19px',
              color: '#000000',
              width: '66px',
              height: '27px',
              marginLeft: '10px',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: event_state === 'C' ? '#A4E3AA' : '#A4D4E3',
            }}
          >
            {event_state === 'C' ? '완료' : '진행중'}
          </div>
        </Title>
      ),
    },
  ];

  /* ===== Hooks ===== */
  useEffect(() => {
    const d = {
      ...data,
    };

    setNewData({
      ...d,
    });
  }, [data]);

  /* ===== Functions ===== */
  const handleDeleteEvent = () => {
    const confirm = window.confirm(
      '해당 이벤트를 정말 삭제하시겠습니까?\n삭제 시 관련 데이터가 손상될 수 있습니다'
    );

    if (confirm) {
      try {
        deleteEvent.mutate({
          event_id: data?.event_id,
        });
        MessageAlert.success('이벤트가 정상적으로 삭제되었습니다');
        navigate('/admin/event-list');
      } catch (e) {
        console.log('Error !!', e);
        MessageAlert.error('이벤트 삭제에 실패하였습니다');
        throw e;
      }
    }
  };

  /* ===== Render ===== */
  return (
    <>
      <Content padding={24} maxWidth={1500} className={'admin_container'}>
        <Row>
          <Col x={24}>
            <Content maxWidth={'100%'} padding={0}>
              <Content
                maxWidth={'100%'}
                padding={0}
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Title style={{ fontSize: '25px', fontWeight: 700 }}>
                  정보
                </Title>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    layoutStyle={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: '0 12px 0 0',
                    }}
                    style={{ padding: '10px 11px' }}
                    onClick={() =>
                      navigate(
                        `/admin/event-regist?event=${encodeURIComponent(
                          encrypt(data?.event_id)
                        )}`
                      )
                    }
                  >
                    {'수정'}
                  </Button>
                  <Button
                    layoutStyle={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: 0,
                    }}
                    style={{ padding: '10px 11px' }}
                    onClick={() => handleDeleteEvent()}
                  >
                    {'삭제'}
                  </Button>
                </div>
              </Content>
              <Content maxWidth={'100%'} padding={0}>
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  backgroundColor={'#EEEEEE'}
                  style={{ borderRadius: '4px' }}
                >
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#EEEEEE',
                      padding: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Col
                      x={6}
                      style={{
                        background: '#D9D9D9',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        이벤트 타이틀
                      </Title>
                    </Col>
                    <Col x={1}></Col>
                    <Col
                      x={17}
                      style={{
                        background: '#FFFFFF',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '4px',
                        padding: '0 20px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        {data?.event_title}
                      </Title>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#EEEEEE',
                      padding: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Col
                      x={6}
                      style={{
                        background: '#D9D9D9',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        이벤트 시작일
                      </Title>
                    </Col>
                    <Col x={1}></Col>
                    <Col
                      x={17}
                      style={{
                        background: '#FFFFFF',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '4px',
                        padding: '0 20px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        {moment(data?.start_date).format('YYYY-MM-DD HH:mm')}
                      </Title>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#EEEEEE',
                      padding: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Col
                      x={6}
                      style={{
                        background: '#D9D9D9',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        이벤트 종료일
                      </Title>
                    </Col>
                    <Col x={1}></Col>
                    <Col
                      x={17}
                      style={{
                        background: '#FFFFFF',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '4px',
                        padding: '0 20px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        {moment(data?.end_date).format('YYYY-MM-DD HH:mm')}
                      </Title>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#EEEEEE',
                      padding: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Col
                      x={6}
                      style={{
                        background: '#D9D9D9',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        이벤트 생성일
                      </Title>
                    </Col>
                    <Col x={1}></Col>
                    <Col
                      x={17}
                      style={{
                        background: '#FFFFFF',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '4px',
                        padding: '0 20px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        {moment(data?.created_at).format('YYYY-MM-DD HH:mm')}
                      </Title>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#EEEEEE',
                      padding: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Col
                      x={6}
                      style={{
                        background: '#D9D9D9',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        노출여부
                      </Title>
                    </Col>
                    <Col x={1}></Col>
                    <Col
                      x={17}
                      style={{
                        background: '#FFFFFF',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '4px',
                        padding: '0 20px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        {data?.is_show}
                      </Title>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      // alignItems: 'center',
                      background: '#EEEEEE',
                      padding: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Col
                      x={6}
                      style={{
                        background: '#D9D9D9',
                        height: '56px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                      }}
                    >
                      <Title
                        style={{
                          fontSize: '17px',
                          lineHeight: '24px',
                          letterSpacing: '0.02em',
                          fontWeight: 700,
                        }}
                      >
                        이벤트 내용
                      </Title>
                    </Col>
                    <Col x={1}></Col>
                    <Col
                      x={17}
                      style={{
                        background: '#FFFFFF',
                        // display: 'flex',
                        // alignItems: 'center',
                        borderRadius: '4px',
                        padding: '0 20px',
                        maxHeight: 500,
                        height: '100%',
                        overflowY: 'scroll',
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data?.event_content),
                        }}
                      ></div>
                    </Col>
                  </Row>
                </Content>
              </Content>
            </Content>
          </Col>
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              padding={0}
              style={{ marginTop: '30px' }}
            >
              <Title style={{ marginBottom: '20px' }}>참여인원</Title>
              <Table
                columns={columns}
                dataSource={newData?.users}
                showSorterTooltip={false}
                pagination={{
                  position: ['bottomCenter'],
                }}
              />
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
};

EventDetailPresenter.defaultProps = {};

export default EventDetailPresenter;
