import React, { useState, useEffect, useRef } from 'react';
import { Content, Row, Col, Title, Button } from 'components';
import { Table, Input, Space } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { encrypt } from 'utils/Crypto';
import { SettingOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const EventListPresenter = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== Mutate ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${title}을(를) 입력해주세요`}
          value={`${selectedKeys[0] || ''}`}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            검색
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            초기화
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: '이벤트',
      dataIndex: 'event_title',
      key: 'event_title',
      ...getColumnSearchProps('event_title', '이벤트'),
      render: (_, { event_title, event_id }) => (
        <Content
          maxWidth={'100%'}
          padding={0}
          backgroundColor={'none'}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Title
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '19px',
            }}
          >
            <p
              style={{
                margin: 0,
                color: '#008ED2',
                cursor: 'pointer',
              }}
              onClick={() =>
                navigate(`/events/${encodeURIComponent(encrypt(event_id))}`)
              }
            >
              {event_title}
            </p>
          </Title>
        </Content>
      ),
    },
    {
      title: '참여인원',
      dataIndex: 'users',
      key: 'users',
      sorter: (a, b) => b?.users?.length - a?.users?.length,
      render: (_, { users }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {users?.length}
        </Title>
      ),
    },
    {
      title: '시작일',
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: (a, b) => new Date(a?.start_date) - new Date(b?.start_date),
      render: (_, { start_date }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {moment(start_date)?.format('YYYY-MM-DD HH:mm')}
        </Title>
      ),
    },
    {
      title: '종료일',
      dataIndex: 'end_date',
      key: 'end_date',
      sorter: (a, b) => new Date(a?.end_date) - new Date(b?.end_date),
      render: (_, { end_date }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            marginLeft: '5px',
          }}
        >
          {moment(end_date)?.format('YYYY-MM-DD HH:mm')}
        </Title>
      ),
    },
    {
      title: '등록일',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(a?.created_at) - new Date(b?.created_at),
      render: (_, { created_at }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          {moment(created_at)?.format('YYYY-MM-DD')}
        </Title>
      ),
    },

    {
      title: '',
      dataIndex: 'butotn',
      key: 'button',
      render: (_, { event_id }) => (
        <Title
          style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
          }}
        >
          <SettingOutlined
            onClick={() =>
              navigate(
                `/admin/event-list/${encodeURIComponent(encrypt(event_id))}`
              )
            }
          />
        </Title>
      ),
    },
  ];

  /* ===== Hooks ===== */
  useEffect(() => {
    const d = data
      ?.map((item) => ({
        ...item,
      }))
      ?.slice()
      .sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

    setNewData(d);
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return (
    <>
      <Content padding={'0 24px'} maxWidth={'100%'} backgroundColor={'none'}>
        <Row>
          <Col x={24}>
            <Content
              maxWidth={'100%'}
              padding={0}
              backgroundColor={'none'}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <Title size={2}>이벤트 관리</Title>
              <Button
                layoutStyle={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: 0,
                }}
                style={{ padding: '10px 11px' }}
                onClick={() => navigate('/admin/event-regist')}
              >
                {'추가'}
              </Button>
            </Content>
            {/* <Content
              maxWidth={'100%'}
              padding={0}
              style={{ textAlign: 'right', marginBottom: '10px' }}
            >
              <Button
                layoutStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                style={{ padding: '10px 11px' }}
                onClick={() => navigate('/admin/event-regist')}
              >
                {'추가'}
              </Button>
            </Content> */}
            <Content
              maxWidth={'100%'}
              padding={24}
              className={'admin_container'}
            >
              <Table
                columns={columns}
                dataSource={newData}
                showSorterTooltip={false}
                pagination={{
                  position: ['bottomCenter'],
                }}
              />
            </Content>
          </Col>
        </Row>
      </Content>
    </>
  );
};

EventListPresenter.defaultProps = {};

export default EventListPresenter;
