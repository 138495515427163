/**
 *
 *
 *
 */
import React, { useState, useEffect } from 'react';
import { Title, Content, Container, Col, Row } from 'components';
import LOGO from 'assets/brainheal_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faFacebookF,
  faInstagram,
  // faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { Link, useLocation } from 'react-router-dom';

/**
 * [Component]
 * --
 */
const Footer = (props) => {
  /* ===== PROPS ===== */
  const { screenSize } = props;

  /* ===== ROUTER ===== */
  const location = useLocation();
  const { pathname } = location;

  /* ===== STATE ===== */
  const [isBorder, setIsBorder] = useState(false);

  /* ===== HOOKS ===== */
  useEffect(() => {
    const borderPath = ['/profile'];

    const b = borderPath.map((item) => {
      if (item === pathname) {
        return true;
      } else {
        return false;
      }
    });

    if (b.includes(true)) {
      setIsBorder(true);
    } else {
      setIsBorder(false);
    }
  }, [pathname]);

  /* ===== FUNCITON ===== */

  /* ===== Styles ===== */
  const styles = {
    link: {
      fontSize: screenSize >= 768 ? 14 : 11,
      fontWeight: 700,
      cursor: 'pointer',
    },
    copyright: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#000000',
      lineHeight: '21px',
    },
    sns: {
      color: '#000000',
      fontSize: '25px',
      marginRight: '27px',
    },
  };

  /* ===== RENDER ===== */
  return (
    <>
      <Content
        maxWidth={'100%'}
        padding={0}
        style={{ borderTop: isBorder ? '1px solid #9E9E9E' : 'none' }}
      >
        <Container
          height={'100%'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: screenSize >= 1200 ? '48px 0' : '48px 15px',
          }}
        >
          <Content maxWidth={'100%'} padding={0}>
            <Col x={24}>
              <Content maxWidth={'100%'} padding={0}>
                <div>
                  <img src={LOGO} alt="" style={{ width: 144 }} />
                </div>
              </Content>
            </Col>
            <Row style={{ marginTop: '26px' }}>
              <Col x={24} xl={18}>
                <Content maxWidth={'100%'} padding={0}>
                  <ul
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#000000',
                    }}
                  >
                    <li style={{ ...styles.link }}>
                      <Link
                        to={'/terms/introduce'}
                        style={{ color: '#000000' }}
                      >
                        교육원소개
                      </Link>
                    </li>
                    <li style={{ margin: '0px 5px' }}>|</li>
                    <li style={{ ...styles.link }}>
                      <Link to={'/terms/service'} style={{ color: '#000000' }}>
                        이용약관
                      </Link>
                    </li>
                    <li style={{ margin: '0px 5px' }}>|</li>
                    <li style={{ ...styles.link }}>
                      <Link to={'/terms/privacy'} style={{ color: '#000000' }}>
                        개인정보처리방침
                      </Link>
                    </li>
                    <li style={{ margin: '0px 5px' }}>|</li>
                    <li style={{ ...styles.link }}>
                      <Link to={'/terms/refund'} style={{ color: '#000000' }}>
                        환불규졍
                      </Link>
                    </li>
                    <li style={{ margin: '0px 5px' }}>|</li>
                    <li style={{ ...styles.link }}>
                      <Link to={'/location'} style={{ color: '#000000' }}>
                        오시는길
                      </Link>
                    </li>
                  </ul>
                  <div>
                    <p style={{ ...styles.copyright }}>
                      {/* 부산광역시 남구 신선로 365, 10공학관 515호(감만동,
                      부경대학교 용당캠퍼스) | 지사 :  */}
                      경기도 김포시 태장로 741, 경동미르웰시티 540호
                    </p>
                    <p style={{ ...styles.copyright }}>
                      사업자등록번호 : 625-88-02197 | Tel : 070-7701-7077 | Fax
                      : 070-4082-7077 | 대표자 : 정상원
                    </p>
                    <p style={{ ...styles.copyright }}>
                      BrainHeal © 2023.All Rights Reserved.
                    </p>
                  </div>
                </Content>
              </Col>
              <Col x={0} xl={6}>
                <Content maxWidth={'100%'} padding={0}>
                  <Title
                    style={{
                      marginBottom: '4px',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}
                  >
                    고객센터
                  </Title>
                  <a
                    href="tel:070-7701-7077"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '282px',
                      height: '49px',
                      border: '1px solid #9E9E9E',
                      borderRadius: '4px',
                      fontSize: '16px',
                      fontWeight: 700,
                      color: '#000000',
                    }}
                  >
                    문의하기
                  </a>
                  {/* <Button
                    size="large"
                    type="default"
                    block
                    style={{
                      width: '282px',
                      height: '49px',
                      border: '1px solid #9E9E9E',
                      borderRadius: '4px',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                    onClick={() => window.open('https://939.co.kr/brainheal')}
                  >
                    원격지원
                  </Button> */}
                  <p style={{ marginTop: '2px' }}>
                    *평일 09:00~18:00, 주말•공휴일 휴무
                  </p>
                </Content>
              </Col>
            </Row>
            <Col x={24}>
              <Content
                maxWidth={'100%'}
                padding={0}
                style={{ borderTop: '1px solid #000000', marginTop: '43px' }}
              >
                <ul
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '24px',
                  }}
                >
                  {/* <li>
                    <Link to={'/'} style={{ ...styles.sns }}>
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Link>
                  </li> */}
                  <li>
                    <a
                      href={'https://www.instagram.com/brainheal_contents/'}
                      style={{ ...styles.sns }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  {/* <li>
                    <Link to={'/'} style={{ ...styles.sns }}>
                      <FontAwesomeIcon icon={faTwitter} />
                    </Link>
                  </li> */}
                  <li>
                    <a
                      href={'https://www.youtube.com/@TV-brainheal'}
                      style={{ ...styles.sns }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </Content>
            </Col>
          </Content>
        </Container>
      </Content>
      {/* <ModalLayout
        title={'문의하기'}
        type={screenSize >= 768 ? 'modal' : 'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={screenSize > 768 ? 'right' : 'bottom'}
        width={screenSize > 768 ? 500 : '100%'}
        height={'90%'}
        closable
        bodyStyle={{ padding: 20 }}
        zIndex={5500}
      >
        <Content maxWidth={'100%'} padding={0}>
          <Row>
            <Col x={24}>
              <div>
                <Button size="large" style={{ width: '100%' }}>
                  채팅문의
                </Button>
                <p>* 주중 10~18시 / 주말 및 공휴일 제외</p>
              </div>
            </Col>
            <Col x={24} style={{ marginTop: 20 }}>
              <div>
                <Button size="large" style={{ width: '100%' }}>
                  원격지원
                </Button>
                <p>* 주중 10~18시 / 주말 및 공휴일 제외</p>
              </div>
            </Col>
          </Row>
        </Content>
      </ModalLayout> */}
    </>
  );
};

export default Footer;
