/*
 * 인풋 컴포넌트
 * --
 * date: 2022-09-13
 * writer: Owen
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './InputForm.style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
 * faEyeSlash : password 타입 password 일 경우 아이콘
 * faEye : password 타입 text 일 경우 아이콘
 * faCalendar : 캘린더 아이콘
 * faTriangleExclamation : 경고표시 아이콘
 * faCircleCheck : 성공표시 아이콘
 * faCircleXmark : 에러표시 아이콘
 * faLoader : 로딩표시 아이콘
 * faFolderBlank : 폴더 모양 아이콘
 */
import {
  faEyeSlash,
  faEye,
  faTriangleExclamation,
  faCircleCheck,
  faCircleXmark,
  faSpinner,
  faCalendar,
  // faFolder,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';

const InputForm = ({
  label,
  placeholder,
  value,
  setValue,
  onChange,
  type,
  setType,
  readOnly,
  maxLength,
  status,
  spanText,
  disabled,
  name,
  width,
  height,
  icon,
  inputId,
  fontSize,
  fontWeight,
  essential,
  padding,
  labelStyle,
}) => {
  /* ===== STATE ===== */
  // input 아이콘 설정
  const [inputIcon, setInputIcon] = useState('');
  // input 박스 focus 상태 설정
  const [inputState, setInputState] = useState(false);
  // 상태에 따른 border, background 설정
  const [borderColor, setBorderColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  // 아이콘 hover 설정
  const [mouseHover, setMouseHover] = useState(false);

  /* ===== RENDER ===== */
  useEffect(() => {
    // 아이콘 props에 따른 아이콘 설정
    if ((icon === 'Password' || icon === 'password') && type === 'password') {
      setInputIcon(faEyeSlash);
    } else if (
      (icon === 'Password' || icon === 'password') &&
      type === 'text'
    ) {
      setInputIcon(faEye);
    } else if (icon === 'Calendar' || icon === 'calendar') {
      setInputIcon(faCalendar);
    } else if (icon === 'File' || icon === 'file') {
      // setInputIcon(faFolderBlank);
      setInputIcon(faPaperclip);
    }
  }, [icon, type]);

  useEffect(() => {
    // 상태에 따른 border 색, background 색 설정
    if (status === 'Default') {
      setBorderColor('1px solid #BDBDBD');
      setBackgroundColor('#FFFFFF');

      // setSpanText('');
    } else if (status === 'Validation') {
      setBorderColor('1px solid #FFE146');
      setBackgroundColor('#FFFBF4');

      // setSpanText('Warning');
    } else if (status === 'Error') {
      setBorderColor('1px solid #FF6262');
      setBackgroundColor('#FFF5F5');

      // setSpanText('Error');
    } else if (status === 'Loading') {
      setBorderColor('1px solid #BDBDBD');
      setBackgroundColor('#FFFFFF');

      // setSpanText('Loading');
    } else if (status === 'Success') {
      setBorderColor('1px solid #40DD7F');
      setBackgroundColor('#F5FFF6');

      // setSpanText('Success');
    }
  }, [status]);

  /* ===== FUNCTION ===== */
  const handleIcon = () => {
    if (type === 'text' && icon === 'password') {
      setType('password');
    } else if (type === 'password' && icon === 'password') {
      setType('text');
    }
  };

  /* ===== RENDER ===== */
  return (
    <div
      className="input-layout-background"
      style={{
        width: `${width}`,
      }}
    >
      {label !== '' && (
        <label htmlFor={inputId} style={labelStyle}>
          {label}
          {essential && (
            <span style={{ color: '#FB4E4E', padding: '0 5px' }}>*</span>
          )}
        </label>
      )}

      <div
        className="input-layout"
        style={{
          height: `${height}`,
          border: inputState
            ? '1px solid #03A9F4'
            : !disabled
            ? `${borderColor}`
            : 'none',
          background: !disabled ? `${backgroundColor}` : '#F5F5F5',
          padding: `${padding}`,
        }}
      >
        <input
          type={type}
          defaultValue={value}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          name={name}
          maxLength={maxLength}
          id={inputId}
          onChange={onChange}
          onFocus={() => setInputState(true)}
          onBlur={() => setInputState(false)}
          style={{
            padding: icon !== 'Default' && '0 10px 0 0',
            fontSize: `${fontSize}`,
            fontWeight: `${fontWeight}`,
          }}
        />
        {icon !== 'Default' && (
          <div>
            <FontAwesomeIcon
              className="input-icon"
              icon={inputIcon}
              style={{
                color: mouseHover
                  ? '#03A9F4'
                  : inputState
                  ? '#03A9F4'
                  : !disabled
                  ? '#000000'
                  : '#BDBDBD',
                cursor: 'pointer',
              }}
              onMouseEnter={() => setMouseHover(true)}
              onMouseLeave={() => setMouseHover(false)}
              onClick={() => handleIcon()}
            />
          </div>
        )}
      </div>

      {spanText !== '' && (
        <div className="span-text">
          <p>
            <FontAwesomeIcon
              icon={
                status === 'Default'
                  ? ''
                  : status === 'Validation'
                  ? faTriangleExclamation
                  : status === 'Error'
                  ? faCircleXmark
                  : status === 'Loading'
                  ? faSpinner
                  : faCircleCheck
              }
              style={{
                color:
                  status === 'Validation'
                    ? 'FFE146'
                    : status === 'Error'
                    ? '#FF6262'
                    : status === 'Loading'
                    ? '#03A9F4'
                    : status === 'Success' && '#40DD7F',
                animation: status === 'Loading' && 'rotate 2s linear infinite',
                transformOrigin: status === 'Loading' && '50% 50%',
              }}
            />
          </p>
          <span>{spanText}</span>
        </div>
      )}
    </div>
  );
};

InputForm.defaultProps = {
  label: '',
  placeholder: '내용을 입력해주세요',
  value: '',
  setValue: '',
  type: 'text',
  setType: '',
  readOnly: false,
  className: '',
  maxLength: 50,
  status: 'Default',
  spanText: '',
  disabled: false,
  name: '',
  width: '340px',
  height: '56px',
  icon: 'Default',
  inputId: 'file',
  fontSize: '18px',
  fontWeight: 400,
  essential: false,
  padding: '20px 10px',
};

InputForm.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  setType: PropTypes.string,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  status: PropTypes.string,
  spanText: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string,
  inputId: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
  essential: PropTypes.bool,
};

export default InputForm;
