import React, { useState, useEffect } from 'react';
import {
  Content,
  Row,
  Col,
  Title,
  Button,
  Line,
  BottomLayout,
  Navbar,
  Footer,
} from 'components';
import { Input, Radio, Select, Affix, InputNumber } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { decrypt } from 'utils/Crypto';
import MessageAlert from 'utils/MessageAlert';
import { ExamApi } from 'api';
import '../styles/andRadio.css';

const ExamRegister = (props) => {
  /* ===== PROPS ===== */
  const { navigation, screenSize } = props;

  /* ===== ROUTE ===== */
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('lecture');
  const examId = searchParams.get('exam');

  /* ===== STATE ===== */
  const [title, setTitle] = useState('');
  const [examList, setExamList] = useState([]);
  // 문제 총 배점
  const [sumScore, setSumScore] = useState(0);

  /* ===== DATA ===== */
  const { data: exam, refetch: examRefetch } = ExamApi.GetExam(
    parseInt(decrypt(examId))
  );

  /* ===== MUTATE ===== */
  const create = ExamApi.CreateExam();
  const update = ExamApi.UpdateExam();

  /* ===== FUNCTION ===== */
  /**
   * 문제 추가 함수
   * --
   * @param {*} type
   * @param {*} index
   */
  const handleExamList = (type, index = null) => {
    if (type === 'add') {
      const NewExam = {
        key: examList?.length + 1,
        q: '',
        type: 'C',
        correct_answer: '',
        score: 1,
        examples: [{ key: 1, example: '' }],
      };

      setExamList([...examList, NewExam]);
    } else {
      const exam = [...examList];
      const newArr = exam?.filter((item) => item.key !== index + 1);

      setExamList(newArr);
    }
  };

  /**
   * 문제 정보 바인딩 함수
   * --
   * @param {*} index
   * @param {*} type
   * @param {*} value
   */
  const handleExamChange = (index, type, value) => {
    const list = [...examList];
    const d = list[index];
    d[type] = value;

    list[index] = d;

    setExamList(list);
  };

  /**
   * 객관식 예제 추가 함수
   * --
   * @param {*} index
   */
  const handleExampleList = (type, index, subIndex = null) => {
    const list = [...examList];
    const d = list[index];
    if (type === 'add') {
      const value = {
        key: d.examples.length + 1,
        example: '',
      };
      const ex = [...d.examples, value];
      d['examples'] = ex;
      list[index] = d;

      setExamList(list);
    } else {
      const item = d.examples?.filter((ex, index) => index !== subIndex);
      d['examples'] = item;
      list[index] = d;

      setExamList(list);
    }
  };

  /**
   * 객관식 예제 정보 바인딩 함수
   * --
   * @param {*} index
   * @param {*} subIndex
   * @param {*} value
   */
  const handleExampleChange = (index, subIndex, value) => {
    const list = [...examList];
    const d = list[index];
    d.examples[subIndex].example = value;

    list[index] = d;

    setExamList(list);
  };
  // console.log(examList);

  /**
   * 강의 등록 함수
   * --
   * @returns
   */
  const handleSubmit = () => {
    // 문제 유효성 검사
    const check = checkEmptyValue();
    if (!check.hasEmptyValue) {
      MessageAlert.warning(`${check.index + 1} 문제에 빈 값이 있습니다`);
      return;
    }

    // 추가
    if (!examId) {
      if (window.confirm('해당 강의에 시험문제를 추가하시겠습니까?')) {
        // 타이틀 유효성검사
        if (!title || title === '') {
          MessageAlert.warning('타이틀을 입력해주세요');
          return;
        }
        // 배점 유효성 검사
        if (sumScore !== 100) {
          MessageAlert.warning('배점을 확인해주세요');
          return;
        }

        try {
          const newList = examList?.map((item, index) => {
            if (item.type === 'C') {
              return {
                ...item,
                order: index + 1,
              };
            } else {
              return {
                ...item,
                examples: null,
              };
            }
          });
          create.mutate({
            lecture_id: parseInt(decrypt(lectureId)),
            title: title,
            exam: newList,
          });
          MessageAlert.success('문제 등록 성공');
          navigation(`/exam/list?lecture=${encodeURIComponent(lectureId)}`);
        } catch (e) {
          MessageAlert.error('시험 등록 실패');
          console.log('Error !!', e);
          throw e;
        }
      } else {
        return;
      }
    }
    // 수정
    else {
      if (window.confirm('해당 강의에 시험문제를 수정하시겠습니까?')) {
        // 타이틀 유효성검사
        if (!title || title === '') {
          MessageAlert.warning('타이틀을 입력해주세요');
          return;
        }
        // 배점 유효성 검사
        if (sumScore !== 100) {
          MessageAlert.warning('배점을 확인해주세요');
          return;
        }
        // 문제 유효성 검사
        const check = checkEmptyValue();
        if (!check.hasEmptyValue) {
          MessageAlert.warning(`${check.index + 1} 문제에 빈 값이 있습니다`);
          return;
        }

        try {
          const newList = examList?.map((item, index) => {
            if (item.type === 'C') {
              return {
                ...item,
                order: index + 1,
              };
            } else {
              return {
                ...item,
                examples: null,
              };
            }
          });
          update.mutate({
            exam_id: parseInt(decrypt(examId)),
            title: title,
            exam: newList,
          });
          MessageAlert.success('문제 등록 성공');
          navigation(`/exam/list?lecture=${encodeURIComponent(lectureId)}`);
        } catch (e) {
          MessageAlert.error('시험 등록 실패');
          console.log('Error !!', e);
          throw e;
        }
      } else {
        return;
      }
    }
  };

  /**
   * 빈값 체크
   * --
   * @returns
   */
  const checkEmptyValue = () => {
    for (let i = 0; i < examList.length; i++) {
      let item = examList[i];

      if (!item.q || item.q === '') {
        return { hasEmptyValue: false, index: i };
      }
      if (!item.correct_answer || item.correct_answer === '') {
        return { hasEmptyValue: false, index: i };
      }

      if (item.type === 'C') {
        for (let j = 0; j < item.examples.length; j++) {
          let subitem = item.examples[j];

          if (!subitem.example || subitem.example === '') {
            return { hasEmptyValue: false, index: i };
          }
        }
      }
    }

    // 모든 항목에 빈 값이 없는 경우
    return { hasEmptyValue: true, index: -1 };
  };

  /* ===== HOOKS ===== */
  /**
   * 총 배점 계산
   */
  useEffect(() => {
    const sum = () => {
      let s = 0;

      examList?.map((item) => {
        if (!item || item === '') {
          return (s += 0);
        }
        return (s += item?.score);
      });

      setSumScore(s);
    };
    sum();
  }, [examList]);

  /**
   * 시험 상세조회 Refetch
   * --
   */
  useEffect(() => {
    examRefetch();
  }, [examId, examRefetch]);

  /**
   * 시험 수정 데이터 정제
   * --
   */
  useEffect(() => {
    if (examId) {
      setTitle(exam?.data?.title);
      setExamList(exam?.data?.exam);
    } else {
      setTitle('');
      setExamList([]);
    }
  }, [exam, examId]);

  /* ===== STYLES ===== */
  const styles = {
    exampleTitle: {
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.02em',
    },
  };

  /* ===== RENDER ===== */
  return (
    <>
      <Navbar subPath={'MAIN'} />
      <Content maxWidth={'100%'} padding={0} style={{ margin: '48px 0 84px' }}>
        <Content padding={15} maxWidth={1200}>
          <Row>
            <Col x={24} xl={19} md={18}>
              <Content maxWidth={'100%'} padding={0}>
                <Title
                  style={{
                    padding: 8,
                    fontSize: '30px',
                    fontWeight: 700,
                    lineHeight: '36px',
                  }}
                >
                  문제 등록
                </Title>
                <Line
                  style={{
                    margin: '8px 0 24px',
                    borderBottom: '1px solid #000000',
                  }}
                />
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '24px',
                  }}
                >
                  <Col x={24} sm={3}>
                    <Title
                      style={{ padding: 8, fontSize: '18px', fontWeight: 600 }}
                    >
                      제목
                    </Title>
                  </Col>
                  <Col x={24} sm={21}>
                    <Input
                      size="large"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      maxLength={20}
                      placeholder="제목을 입력해주세요"
                    />
                  </Col>
                </Row>
                <Row>
                  {examList?.map((item, index) => (
                    <Content
                      key={`exam-${index}`}
                      maxWidth={'100%'}
                      style={{
                        border: '1px solid #9E9E9E',
                        marginBottom: '24px',
                        borderRadius: '4px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: '24px',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Radio.Group
                            onChange={(e) =>
                              handleExamChange(index, 'type', e.target.value)
                            }
                            value={item?.type}
                          >
                            <Radio value={'C'}>객관식</Radio>
                            <Radio value={'D'} style={{ marginLeft: '8px' }}>
                              주관식
                            </Radio>
                          </Radio.Group>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: '8px',
                            }}
                          >
                            <p
                              style={{
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '19px',
                                letterSpacing: '0.02em',
                              }}
                            >
                              배점
                            </p>
                            <InputNumber
                              size="large"
                              value={item?.score}
                              style={{ marginLeft: '8px', width: '64px' }}
                              onChange={(value) =>
                                handleExamChange(index, 'score', value)
                              }
                              controls={false}
                            />
                          </div>
                        </div>
                        <div>
                          <button
                            style={{
                              border: 'none',
                              background: 'none',
                              cursor: 'pointer',
                              fontSize: '18px',
                              fontWeight: 500,
                            }}
                            onClick={() => handleExamList('delete', index)}
                          >
                            x
                          </button>
                        </div>
                      </div>
                      <div>
                        <Row
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                          }}
                        >
                          <Col x={24} sm={3}>
                            <Title style={{ ...styles.exampleTitle }}>
                              {index + 1}번 문제
                            </Title>
                          </Col>
                          <Col x={24} sm={20}>
                            <Input
                              size="large"
                              value={item?.q}
                              onChange={(e) =>
                                handleExamChange(index, 'q', e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                        <Row
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '24px',
                          }}
                        >
                          <Col x={24} sm={3}>
                            <Title style={{ ...styles.exampleTitle }}>답</Title>
                          </Col>
                          <Col x={24} sm={20}>
                            {item?.type === 'C' ? (
                              <Select
                                size="large"
                                defaultValue={item?.correct_answer}
                                options={item?.examples?.map((ex) => ({
                                  value: ex.key,
                                  label: ex.key,
                                }))}
                                onChange={(e) =>
                                  handleExamChange(index, 'correct_answer', e)
                                }
                                style={{ width: '100%' }}
                              />
                            ) : (
                              <Input
                                size="large"
                                value={item?.correct_answer}
                                onChange={(e) =>
                                  handleExamChange(
                                    index,
                                    'correct_answer',
                                    e.target.value
                                  )
                                }
                              />
                            )}
                          </Col>
                        </Row>
                        {item?.type === 'C' ? (
                          <Row>
                            <Col x={24}>
                              <Row style={{ marginBottom: '10px' }}>
                                <Col x={3}></Col>
                                <Col x={21}>
                                  <Button
                                    color="secondary"
                                    onClick={() =>
                                      handleExampleList('add', index)
                                    }
                                    style={{ width: '160px', height: '45px' }}
                                  >
                                    보기 추가
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                            {item?.examples?.map((ex, exIndex) => (
                              <Col
                                x={24}
                                key={`exam-${index}-${exIndex}`}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginBottom: '10px',
                                }}
                              >
                                <Col x={3}>
                                  <p style={{ ...styles.exampleTitle }}>
                                    {exIndex + 1}.
                                  </p>
                                </Col>
                                <Col x={20}>
                                  <Input
                                    size="large"
                                    value={ex?.example}
                                    onChange={(e) =>
                                      handleExampleChange(
                                        index,
                                        exIndex,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                                <Col
                                  x={1}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {exIndex > 0 ? (
                                    <button
                                      style={{
                                        border: 'none',
                                        background: 'none',
                                        cursor: 'pointer',
                                        fontSize: '18px',
                                        fontWeight: 500,
                                      }}
                                      onClick={() =>
                                        handleExampleList(
                                          'delete',
                                          index,
                                          exIndex
                                        )
                                      }
                                    >
                                      x
                                    </button>
                                  ) : null}
                                </Col>
                              </Col>
                            ))}
                          </Row>
                        ) : null}
                      </div>
                    </Content>
                  ))}
                </Row>
              </Content>
            </Col>
            <Col x={0} md={1}></Col>
            <Col x={0} xl={4} md={5}>
              <Affix offsetTop={150}>
                <Content
                  maxWidth={'100%'}
                  style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
                  padding={10}
                >
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineeHeight: '22px',
                      letterSpacing: '0.02em',
                      textAlign: 'center',
                    }}
                  >
                    <span
                      style={{
                        color: sumScore !== 100 ? 'red' : 'green',
                      }}
                    >
                      {sumScore}
                    </span>{' '}
                    / 100
                  </p>

                  <Button
                    color="secondary"
                    onClick={() => handleExamList('add')}
                    style={{
                      width: '100%',
                      height: '50px',
                      fontSize: '18px',
                      fontWeight: 600,
                      letterSpacing: '0.02em',
                      marginTop: '38px',
                    }}
                  >
                    문제 추가
                  </Button>
                  <Button
                    onClick={() => handleSubmit()}
                    style={{
                      width: '100%',
                      height: '50px',
                      fontSize: '18px',
                      fontWeight: 600,
                      letterSpacing: '0.02em',
                      marginTop: '10px',
                    }}
                  >
                    {!examId ? '등록 완료' : '수정 완료'}
                  </Button>
                </Content>
              </Affix>
            </Col>
            {screenSize >= 768 ? null : (
              <BottomLayout shadow={true} style={{ padding: 0 }}>
                <Col x={24}>
                  <Content
                    maxWidth={'100%'}
                    style={{ border: '1px solid #9E9E9E', borderRadius: '4px' }}
                    padding={10}
                  >
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: 600,
                        lineeHeight: '22px',
                        letterSpacing: '0.02em',
                        textAlign: 'center',
                      }}
                    >
                      배점
                      <span
                        style={{
                          color: sumScore !== 100 ? 'red' : 'green',
                          marginLeft: '10px',
                        }}
                      >
                        {sumScore}
                      </span>{' '}
                      / 100
                    </p>

                    <Content
                      maxWidth={'100%'}
                      padding={0}
                      style={{ display: 'flex', marginTop: '10px' }}
                    >
                      <Button
                        color="secondary"
                        onClick={() => handleExamList('add')}
                        layoutStyle={{
                          margin: 0,
                          width: '50%',
                          height: '50px',
                          borderRadius: 0,
                        }}
                        style={{
                          width: '100%',
                          height: '100%',
                          fontSize: '18px',
                          fontWeight: 600,
                          letterSpacing: '0.02em',
                          borderRadius: 0,
                        }}
                      >
                        문제 추가
                      </Button>
                      <Button
                        onClick={() => handleSubmit()}
                        layoutStyle={{
                          margin: 0,
                          width: '50%',
                          height: '50px',
                          borderRadius: 0,
                        }}
                        style={{
                          width: '100%',
                          height: '100%',
                          fontSize: '18px',
                          fontWeight: 600,
                          letterSpacing: '0.02em',
                          borderRadius: 0,
                        }}
                      >
                        {!examId ? '등록 완료' : '수정 완료'}
                      </Button>
                    </Content>
                  </Content>
                </Col>
              </BottomLayout>
            )}
          </Row>
        </Content>
      </Content>
      <Footer />
    </>
  );
};

ExamRegister.defaultProps = {};

export default ExamRegister;
