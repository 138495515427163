/**
 *
 *
 *
 */
import React, { useState, useEffect, useMemo } from 'react';
import { Pagination, Select, Input } from 'antd';
import {
  Title,
  Row,
  Col,
  Card,
  Content,
  ModalLayout,
  Button,
} from 'components';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { encrypt } from 'utils/Crypto';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import useDebounce from 'utils/useDebounce';
import moment from 'moment';
import { calculateAge, STORE_URL, getCookie } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationDot,
  faTag,
  faGraduationCap,
  faImage,
} from '@fortawesome/free-solid-svg-icons';

const genderList = [
  {
    label: '전체',
    value: 'ALL',
  },
  {
    label: '남',
    value: 'M',
  },
  {
    label: '여',
    value: 'F',
  },
];

const styles = {
  dot: {
    display: 'inline-block',
    margin: '0px 5px',
  },
  subText: {
    fontWeight: 400,
  },
  subInfo: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#000000',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  icon: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#9E9E9E',
    display: 'inline-block',
    marginRight: '4px',
  },
};

/**
 * [Component] Main
 * --
 */
function TeacherProfileList(props) {
  /* ===== Props ===== */

  /* ===== Router ===== */
  const navigate = useNavigate();

  /* ===== Data ===== */
  const queryClient = useQueryClient();
  const users = queryClient.getQueryData('getUsers')?.data;
  const categories = queryClient.getQueryData('getCategories')?.data;
  const address = queryClient.getQueryData('getAddressSplit')?.data;
  const profiles = queryClient.getQueryData('getUserProfiles')?.data;
  const teacherFiles = queryClient.getQueryData('getTeacherFiles')?.data;
  const preferenceArea = queryClient.getQueryData(
    'getPreferenceAreaUsers'
  )?.data;
  const majors = queryClient.getQueryData('getUserMajors')?.data;

  /* ===== State ===== */
  const [teacherList, setTeacherList] = useState([]);
  // const [categoryList, setCategoryList] = useState([]);
  const [majorList, setMajorList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [areaGuList, setAreaGuList] = useState([]);
  // const [category, setCategory] = useState('0');
  const [major, setMajor] = useState(undefined);
  const [area, setArea] = useState(undefined);
  const [areaGu, setAreaGu] = useState(undefined);
  const [gender, setGender] = useState('ALL');
  const [teacherFilterList, setTeacherFilterList] = useState(teacherList);
  const [dependency, setDependency] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, 300);
  const [page, setPage] = useState(1);
  const [screenSize, setScreenSize] = useState(document.body.clientWidth);
  // 필터모달
  const [isOpened, setIsOpened] = useState(false);

  /**
   * 최종학력 데이터 추출
   * --
   * @param {*} data
   * @returns
   */
  const splitContent = (data) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(data, 'text/html');

    // 클래스 이름으로 요소 가져오기
    const educationElement = htmlDoc
      ?.querySelector('.education')
      ?.querySelector('p.on')?.innerText;

    if (educationElement) {
      const d = educationElement?.split('∙');
      const [school_name, major_name, , ,] = d;
      const name = school_name?.split('-')[1].trim();
      const major = major_name.trim();

      return `${name} ${major}`;
    } else {
      return null;
    }
  };

  const items = useMemo(() => {
    if (
      users &&
      profiles &&
      teacherFiles &&
      preferenceArea &&
      categories &&
      address &&
      majors
    ) {
      const teachers = users?.filter(
        (item) => item.teacher_check === 'Y' && item?.is_show === 'Y'
      );
      const newProfiles = profiles?.map((item) => ({
        ...item,
        highest_education: splitContent(item.content),
      }));

      const newTeachers = teachers?.map((item) => ({
        ...item,
        profile: newProfiles?.filter(
          (subitem) => item?.user_id === subitem?.user_id
        )[0],
        profileImage: teacherFiles
          ?.filter(
            (subitem) =>
              item?.user_id === subitem?.user_id && subitem?.type === 'P'
          )
          ?.pop(),
        preferenceArea: preferenceArea
          ?.filter((subitem) => item?.user_id === subitem?.user_id)
          ?.map((subitem) => ({
            ...subitem,
            ...(address &&
              address?.local?.filter(
                (thirditem) => subitem?.local_id === thirditem?.local_id
              )[0]),
          })),
        major: majors
          ?.filter((subitem) => item?.user_id === subitem?.user_id)
          ?.map((subitem) => ({
            ...subitem,
            major_name: categories?.filter(
              (thirditem) => subitem?.major === thirditem?.category_id
            )[0]?.category_name,
            major_detail_name: categories?.filter(
              (thirditem) => subitem?.major_detail === thirditem?.category_id
            )[0]?.category_name,
          })),
      }));
      const profileTeachers = newTeachers?.filter((item) => item?.profile);

      // const newCategories = categories
      //   ?.filter((item) => item?.type === 'M')
      //   ?.map((item) => ({
      //     label: item?.category_name,
      //     value: `${item?.category_id}`,
      //   }));
      const m = categories?.filter((item) => item?.type === 'M');
      const d = categories?.filter((item) => item?.type === 'D');
      const newMajor = m?.map((item) => ({
        // label: item?.category_name,
        // options:
        // d?.filter((subitem) => subitem?.parents_id === item?.category_id)
        //   ?.map((subitem) => ({
        //     label: subitem?.category_name,
        //     value: subitem?.category_id,
        //   })),
        label: d
          ?.filter((subitem) => subitem?.parents_id === item?.category_id)
          ?.map((subitem) => subitem?.category_name)[0],
        value: d
          ?.filter((subitem) => subitem?.parents_id === item?.category_id)
          ?.map((subitem) => subitem?.category_id)[0],
      }));
      const newAddress = address?.local?.map((item) => ({
        label: item?.local_name,
        value: item?.local_id,
      }));

      return {
        teachers: profileTeachers,
        // categories: newCategories,
        major: newMajor,
        address: newAddress,
      };
    }
  }, [
    users,
    categories,
    address,
    profiles,
    teacherFiles,
    preferenceArea,
    majors,
  ]);

  /* ===== Hooks ===== */
  /**
   * 데이터 정제
   * --
   */
  useEffect(() => {
    if (items) {
      const {
        teachers,
        // categories,
        major,
        address,
      } = items;
      setTeacherList(teachers);
      // setCategoryList([{ label: '전체보기', value: `0` }, ...categories]);
      setMajorList([{ label: '전체보기', value: 0 }, ...major]);
      setAreaList([{ label: '전체보기', value: 0 }, ...address]);
    }
  }, [items]);

  /**
   * 카테고리별 강사리스트 정제
   * --
   */

  useEffect(() => {
    if (teacherList && teacherList?.length > 0 && dependency) {
      const filterData = teacherList
        ?.filter((item) =>
          major && major !== 0
            ? item?.major?.some((majorItem) => majorItem.major_detail === major)
            : item
        )
        ?.filter((item) =>
          area && area !== 0
            ? item?.preferenceArea?.some((item) => item.local_id === area)
            : item
        )
        ?.filter((item) =>
          areaGu && areaGu !== 0
            ? item?.preferenceArea?.some((item) => item.city_id === areaGu)
            : item
        )
        ?.filter((item) => (gender !== 'ALL' ? item?.gender === gender : item))
        ?.filter((item) =>
          debouncedValue && debouncedValue !== ''
            ? item?.name
                ?.toLocaleLowerCase()
                ?.includes(debouncedValue?.toLocaleLowerCase())
            : item
        );

      setTeacherFilterList(filterData);
      setDependency(false);
    }
  }, [
    // category,
    major,
    area,
    areaGu,
    gender,
    teacherList,
    dependency,
    debouncedValue,
  ]);

  /**
   * 강사 리스트 의존성 처리
   * --
   */
  useEffect(() => {
    setDependency(true);
  }, [
    // category,
    major,
    area,
    areaGu,
    gender,
    debouncedValue,
  ]);

  /**
   * 스크린 사이즈 변화 감지
   * --
   */
  useEffect(() => {
    const call = (e) => {
      setScreenSize(document.body.clientWidth);
    };

    window.addEventListener('resize', call);

    return () => {
      window.removeEventListener('resize', call);
    };
  }, []);

  /**
   * 구/동 아이템 정제
   */
  useEffect(() => {
    if (area) {
      const gu = address?.city?.filter((item) => item?.local_id === area);
      const newAddressGu = gu?.map((item) => ({
        label: item?.city_name,
        value: item?.city_id,
      }));
      setAreaGuList([{ label: '전체보기', value: 0 }, ...newAddressGu]);
      setAreaGu(0);
    } else {
      setAreaGuList([]);
    }
  }, [area, address?.city]);

  /* ===== Functions ===== */
  /**
   * 선호지역 Local 추출
   * --
   * @param {*} areaList
   * @returns
   */
  const handleAreaText = (areaList) => {
    let area = [];
    let text = '';
    areaList?.map(
      (item) => !area.includes(item?.local_name) && area.push(item?.local_name)
    );

    area?.forEach((subitem, subindex) =>
      subindex === 0 ? (text += subitem) : (text += `, ${subitem}`)
    );

    return text;
  };

  /**
   * 상세화면
   * --
   * @param {*} id
   */
  const handleNavigate = (id) => {
    if (getCookie('BRAINHEAL_ACCESS_TOKEN')) {
      navigate(`/teachers/${encodeURIComponent(encrypt(id))}`);
    } else {
      const currentPath = window.location.hash.split('#');
      window.alert('로그인 후 이용해주세요');
      navigate(`/login`, { state: { prev: `${currentPath[1]}` } });
    }
  };

  /**
   * 모달
   * --
   */
  const handleFilterModal = (open) => {
    setIsOpened(!open);
  };

  /* ===== Render ===== */
  return (
    <>
      {/* ===== 배너 ===== */}
      <Content maxWidth={'100%'} padding={0} style={{ margin: '48px 0 84px' }}>
        <Content maxWidth={1200} padding={screenSize >= 1200 ? 0 : '0 30px'}>
          <Content
            maxWidth={'100%'}
            padding={0}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title style={{ fontSize: '35px', fontWeight: 700 }}>
              강사검색
            </Title>
            {screenSize >= 1200 ? null : (
              <p
                style={{
                  fontSize: '14px',
                  cursor: 'pointer',
                  color: '#9E9E9E',
                }}
                onClick={() => handleFilterModal(isOpened)}
              >
                필터
                <span>
                  <FilterOutlined
                    style={{ fontSize: '18px', color: '#E3A4AF' }}
                  />
                </span>
              </p>
            )}
          </Content>
          {/* 강사 검색 */}
          <Content maxWidth={'100%'} padding={0}>
            <Row>
              <Col x={0} md={24} style={{ marginTop: '48px' }}>
                <Content
                  maxWidth={'100%'}
                  padding={0}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Select
                    size="large"
                    placeholder="전문분야"
                    style={{ width: '156px' }}
                    options={majorList}
                    onChange={(e) => setMajor(e)}
                  />
                  <Select
                    size="large"
                    placeholder="활동지역 (시)"
                    style={{ width: '156px', marginLeft: '24px' }}
                    options={areaList}
                    onChange={(e) => setArea(e)}
                  />
                  <Select
                    size="large"
                    placeholder="활동지역 (구/동)"
                    style={{ width: '156px', marginLeft: '24px' }}
                    options={areaGuList}
                    value={areaGu}
                    onChange={(e) => setAreaGu(e)}
                  />
                  <Select
                    size="large"
                    placeholder="성별"
                    style={{ width: '78px', marginLeft: '24px' }}
                    options={genderList}
                    onChange={(e) => setGender(e)}
                  />
                  <Input.Group
                    style={{
                      borderBottom: '1px solid #dbdbdb',
                      width: '312px',
                      marginLeft: '24px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Input
                      placeholder="성함을 입력해주세요"
                      size="large"
                      style={{
                        border: 'none',
                      }}
                      suffix={<SearchOutlined />}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Input.Group>
                </Content>
              </Col>
            </Row>
          </Content>

          {/* 강사 리스트 */}
          <Content maxWidth={'100%'} padding={'48px 0'}>
            <Row>
              {teacherFilterList && teacherFilterList.length > 0 ? (
                teacherFilterList?.map(
                  (_item, _index) =>
                    _index >= 0 + 12 * (page - 1) &&
                    _index <= 11 + 12 * (page - 1) && (
                      <Col
                        x={24}
                        xl={6}
                        lg={8}
                        sm={12}
                        key={`teacher_${_item.user_id}`}
                        style={{ padding: 12 }}
                      >
                        <Card
                          key={`lecture_list_item_${_index}`}
                          style={{
                            marginBottom: 10,
                            border: '1px solid #9E9E9E',
                            borderRadius: '4px',
                          }}
                          bodyStyle={{ padding: 0 }}
                          onClick={() => handleNavigate(_item?.user_id)}
                        >
                          <div>
                            <div
                              style={{
                                width: '100%',
                                height: '180px',
                                background: _item?.profileImage
                                  ? `url(${STORE_URL}/users/${_item?.profileImage?.user_id}/${_item?.profileImage?.file_url}) no-repeat center center`
                                  : '#9E9E9E',
                                backgroundSize: 'contain',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#1C1B1F',
                                fontSize: '55px',
                                borderBottom: '1px solid #9E9E9E',
                              }}
                            >
                              {_item?.profileImage ? null : (
                                <FontAwesomeIcon
                                  style={{ fontSize: '50px' }}
                                  icon={faImage}
                                />
                              )}
                            </div>

                            <div style={{ padding: '16px' }}>
                              <Title
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  color: '#AB5476',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  display: 'block',
                                }}
                              >
                                {_item?.profile?.profile_title
                                  ? _item?.profile?.profile_title
                                  : '등록된 프로필이 없습니다'}
                              </Title>
                              <Title
                                style={{
                                  justifyContent: 'flex-start',
                                  marginTop: '4px',
                                  fontSize: '16px',
                                }}
                              >
                                {_item?.name}
                                <span style={{ ...styles.dot }}>∙</span>
                                <span style={{ ...styles.subText }}>
                                  {_item?.gender === 'M' ? '남' : '여'}
                                </span>
                                <span style={{ ...styles.dot }}>∙</span>
                                <span style={{ ...styles.subText }}>
                                  {`${moment(_item?.birthday).format(
                                    'YYYY'
                                  )}년생 (만 ${
                                    calculateAge(_item?.birthday)?.age
                                  }세)`}
                                </span>
                              </Title>
                              <div style={{ marginTop: '10px' }}>
                                {_item?.profile?.highest_education ? (
                                  <p style={{ ...styles.subInfo }}>
                                    <span style={{ ...styles.icon }}>
                                      <FontAwesomeIcon icon={faGraduationCap} />
                                    </span>
                                    {_item?.profile?.highest_education}
                                  </p>
                                ) : (
                                  <p style={{ ...styles.subInfo }}>
                                    <span style={{ ...styles.icon }}>
                                      <FontAwesomeIcon icon={faGraduationCap} />
                                    </span>
                                    {' - '}
                                  </p>
                                )}
                                {_item?.major?.length ? (
                                  <p
                                    style={{
                                      ...styles.subInfo,
                                    }}
                                  >
                                    <span style={{ ...styles.icon }}>
                                      <FontAwesomeIcon icon={faTag} />
                                    </span>
                                    {_item?.major?.map((major, majorIndex) =>
                                      majorIndex !== 0
                                        ? `, ${major?.major_detail_name}`
                                        : major?.major_detail_name
                                    )}
                                  </p>
                                ) : null}
                                {_item?.preferenceArea &&
                                _item?.preferenceArea?.length > 0 ? (
                                  <p
                                    style={{
                                      ...styles.subInfo,
                                      display: 'flex',
                                    }}
                                  >
                                    <span style={{ ...styles.icon }}>
                                      <FontAwesomeIcon icon={faLocationDot} />
                                    </span>
                                    {`${handleAreaText(_item?.preferenceArea)}`}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    )
                )
              ) : (
                <Col x={24}>
                  <Content
                    maxWidth={'100%'}
                    height={screenSize >= 768 ? 500 : 350}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <h4 style={{ fontSize: screenSize >= 768 ? '30px' : 18 }}>
                      해당 조건을 만족하는 강사가 없습니다
                    </h4>
                  </Content>
                </Col>
              )}
            </Row>
          </Content>
          {teacherFilterList && teacherFilterList?.length > 0 ? (
            <Pagination
              defaultCurrent={1}
              current={page}
              defaultPageSize={12}
              total={teacherFilterList?.length}
              onChange={(e) => setPage(e)}
              style={{ textAlign: 'center' }}
            />
          ) : null}
        </Content>
      </Content>

      {/* ===== 필터모달 ===== */}
      <ModalLayout
        type={'drawer'}
        open={isOpened}
        onCancel={setIsOpened}
        placement={'right'}
        width={'70%'}
        height={'100%'}
        closable
        style={{ padding: 0 }}
        bodyStyle={{ padding: 0 }}
        footerStyle={{ padding: 0 }}
        footer={
          <Content maxWidth={'100%'} padding={0}>
            <Button
              style={{
                width: '100%',
                height: '50px',
                borderRadius: 0,
                fontSize: '16px',
              }}
              onClick={() => handleFilterModal(isOpened)}
            >
              확인
            </Button>
          </Content>
        }
      >
        <Content
          maxWidth={'100%'}
          padding={0}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* 전문분야 */}
          <div style={{ width: '90%', margin: '10px auto' }}>
            <p style={{ color: '#9E9E9E' }}>전문분야</p>
            <Select
              size="large"
              placeholder="전문분야"
              style={{ width: '100%' }}
              options={majorList}
              onChange={(e) => setMajor(e)}
              dropdownStyle={{ zIndex: 3800 }}
            />
          </div>
          {/* 활동지역(시) */}
          <div style={{ width: '90%', margin: '10px auto' }}>
            <p style={{ color: '#9E9E9E' }}>활동지역 (시)</p>
            <Select
              size="large"
              placeholder="활동지역 (시)"
              style={{ width: '100%' }}
              options={areaList}
              onChange={(e) => setArea(e)}
              dropdownStyle={{ zIndex: 3800 }}
            />
          </div>
          {/* 활동지역(구/동) */}
          <div style={{ width: '90%', margin: '10px auto' }}>
            <p style={{ color: '#9E9E9E' }}>활동지역 (구/동)</p>
            <Select
              size="large"
              placeholder="활동지역 (구/동)"
              style={{ width: '100%' }}
              options={areaGuList}
              value={areaGu}
              onChange={(e) => setAreaGu(e)}
              dropdownStyle={{ zIndex: 3800 }}
            />
          </div>
          {/* 성별 */}
          <div style={{ width: '90%', margin: '10px auto' }}>
            <p style={{ color: '#9E9E9E' }}>성별</p>
            <Select
              size="large"
              placeholder="성별"
              style={{ width: '100%' }}
              options={genderList}
              onChange={(e) => setGender(e)}
              dropdownStyle={{ zIndex: 3800 }}
            />
          </div>
          {/* 성함 */}
          <div style={{ width: '90%', margin: '10px auto' }}>
            <p style={{ color: '#9E9E9E' }}>성함</p>
            <Input
              placeholder="성함을 입력해주세요"
              size="large"
              suffix={<SearchOutlined />}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </Content>
      </ModalLayout>
    </>
  );
}

TeacherProfileList.defaultProps = {};

export default TeacherProfileList;
