/*
 * 선택창 컴포넌트
 * --
 * date: 2022-09-05
 * writer: Owen
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Select.style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
 * faAngleUp : 위쪽 화살표 아이콘
 * faAngleDown : 아래쪽 화살표 아이콘
 * faTriangleExclamation : 경고표시 아이콘
 * faCircleCheck : 성공표시 아이콘
 * faCircleXmark : 에러표시 아이콘
 * faLoader : 로딩표시 아이콘
 */
import {
  faAngleUp,
  faAngleDown,
  faTriangleExclamation,
  faCircleCheck,
  faCircleXmark,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

const Select = ({
  label,
  className,
  status,
  disabled,
  items,
  unit,
  spanText,
  width,
  height,
  fontSize,
  fontWeight,
  labelStyle,
  padding,
  setValue,
  essential,
}) => {
  /* State */
  // select 박스의 focus 상태 체크
  const [selectState, setSelectState] = useState(false);
  // 상태에 따른 border, background 설정
  const [borderColor, setBorderColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  // 아이콘 hover 설정
  const [mouseHover, setMouseHover] = useState(false);
  const [text, setText] = useState(items[0] + unit);

  /* Functions */
  /*
   * select 박스 focus 상태 설정 함수
   * --
   * @param {*} option
   * @returns
   */
  const handleListClick = (option) => {
    setValue(option);
    setSelectState(false);
    setText(option + unit);
  };

  /*
   * 높이를 숫자 타입으로 바꿔주는 함수
   */
  const [h, setH] = useState();
  const [unitH, setUnitH] = useState('');
  useEffect(() => {
    let str = height;
    let length = str.length;
    if (str.slice(-2) === 'px') {
      setH(parseInt(str.substring(0, length - 2)));
      setUnitH('px');
    } else if (str.slice(-1) === '%') {
      setH(parseInt(str.substring(0, length - 1)));
      setUnitH('%');
    }
  }, [height]);

  /*
   * 상태에 따른 border, background 상태 설정 함수
   * --
   */
  useEffect(() => {
    if (status === 'Default') {
      setBorderColor('1px solid #BDBDBD');
      setBackgroundColor('#FFFFFF');

      // setSpanText('');
    } else if (status === 'Validation') {
      setBorderColor('1px solid #FFE146');
      setBackgroundColor('#FFFBF4');

      // setSpanText('Warning');
    } else if (status === 'Error') {
      setBorderColor('1px solid #FF6262');
      setBackgroundColor('#FFF5F5');

      // setSpanText('Error');
    } else if (status === 'Loading') {
      setBorderColor('1px solid #BDBDBD');
      setBackgroundColor('#FFFFFF');

      // setSpanText('Loading');
    } else if (status === 'Success') {
      setBorderColor('1px solid #40DD7F');
      setBackgroundColor('#F5FFF6');

      // setSpanText('Success');
    }
  }, [status]);

  return (
    <div className={className} style={{ width: `${width}` }}>
      {label !== '' && (
        <label style={labelStyle}>
          {label}
          {essential && (
            <span style={{ color: '#FB4E4E', padding: '0 5px' }}>*</span>
          )}
        </label>
      )}

      <ul style={{ width: `${width}` }}>
        <div
          className="ulCon"
          style={{
            height: `${height}`,
            border:
              selectState && className === 'selectBox01'
                ? '1px solid #03A9F4'
                : className === 'selectBox02'
                ? '1px solid #03A9F4'
                : !disabled
                ? `${borderColor}`
                : 'none',
            background: disabled ? '#F5F5F5' : `${backgroundColor}`,
            pointerEvents: disabled && 'none',
            padding: `${padding}`,
          }}
          onClick={() => setSelectState(!selectState)}
        >
          <p
            style={{
              color: disabled && '#BDBDBD',
              fontSize: `${fontSize}`,
              fontWeight: `${fontWeight}`,
            }}
          >
            {text}
          </p>
          <div
            style={{ color: disabled ? '#BDBDBD' : selectState && '#03A9F4' }}
          >
            <FontAwesomeIcon
              icon={selectState ? faAngleUp : faAngleDown}
              style={{
                color: mouseHover
                  ? '#03A9F4'
                  : className === 'selectBox02' && '#03A9F4',
              }}
              onMouseEnter={() => setMouseHover(true)}
              onMouseLeave={() => setMouseHover(false)}
            />
          </div>
        </div>

        <div
          className="liCon"
          style={{
            display: selectState ? 'block' : 'none',
            maxHeight: `${h * 5}${unitH}`,
            top: `${height}`,
          }}
        >
          {items.map((item, index) => (
            <li
              key={`${item}_${index}`}
              onClick={() => handleListClick(item)}
              style={{ height: `${height}px` }}
            >
              <p
                style={{
                  padding: `${padding}`,
                  margin: 0,
                  fontSize: `${fontSize}`,
                  fontWeight: `${fontWeight}`,
                }}
              >
                {item + unit}
              </p>
            </li>
          ))}
        </div>
      </ul>

      {spanText !== '' && (
        <div className="spanText">
          <p>
            <FontAwesomeIcon
              icon={
                status === 'Default'
                  ? ''
                  : status === 'Validation'
                  ? faTriangleExclamation
                  : status === 'Error'
                  ? faCircleXmark
                  : status === 'Loading'
                  ? faSpinner
                  : faCircleCheck
              }
              style={{
                color:
                  status === 'Validation'
                    ? 'FFE146'
                    : status === 'Error'
                    ? '#FF6262'
                    : status === 'Loading'
                    ? '#03A9F4'
                    : status === 'Success' && '#40DD7F',
                animation: status === 'Loading' && 'rotate 2s linear infinite',
                transformOrigin: status === 'Loading' && '50% 50%',
              }}
            />
          </p>
          <span>{spanText}</span>
        </div>
      )}
    </div>
  );
};

Select.defaultProps = {
  label: '',
  className: 'selectBox01',
  status: 'Default',
  disabled: false,
  items: [],
  unit: '',
  fontSize: '16px',
  fontWeight: 500,
  spanText: '',
  name: '',
  width: '340px',
  height: '56px',
  value: '',
  padding: '20px 10px',
};

Select.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.array,
  spanText: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  value: PropTypes.string,
};

export default Select;
