import React, { useState, useEffect } from 'react';
import LecturesPresenter from './LecturesPresenter';

const LecturesContainer = (props) => {
  /* ===== Props ===== */
  const { data } = props;

  /* ===== Router ===== */

  /* ===== State ===== */
  const [newData, setNewData] = useState([]);

  /* ===== Hooks ===== */
  useEffect(() => {
    if (data) {
      const { lectures, users, lectureOfUsers, reviews, exams, teachers } =
        data;
      const newUsers = lectureOfUsers?.map((item) => ({
        ...item,
        ...(users &&
          users?.filter((subitem) => item.user_id === subitem.user_id)[0]),
      }));

      const newTeacherList = users && teachers && users?.concat(teachers);

      const n = lectures?.map((item) => ({
        ...item,
        key: item.lecture_id,
        register_name: newTeacherList
          ?.filter((subitem) => item?.user_id === subitem?.user_id)
          ?.pop()?.name,
        users: newUsers?.filter(
          (subitem) => item.lecture_id === subitem.lecture_id
        ),
        reviews: reviews?.filter(
          (subitem) => subitem?.lecture_id === item.lecture_id
        ),
        exams: exams?.filter(
          (subitem) => subitem?.lecture_id === item.lecture_id
        ),
        thumbnail: item?.files?.filter((subitem) => subitem?.type === 'T')[0],
      }));

      setNewData(n);
    }
  }, [data]);

  /* ===== Functions ===== */

  /* ===== Render ===== */
  return <LecturesPresenter data={newData} />;
};

LecturesContainer.defaultProps = {};

export default LecturesContainer;
